import decode from 'jwt-decode';

import { APIResponse, APIError } from '../models';
import { tokenManager } from '../tokenManager';
import { RefreshTokenResponse } from '../users/models/RefreshTokenResponse';

import { apiFetch } from './';

import { refreshToken } from 'common/api/users/login/refresh';
import { noop } from 'common/utils';

export type RefreshTokenError = APIError<RefreshTokenResponse>;

export type RefreshTokenErrorHandler = (error: RefreshTokenError) => void;

export const createWithTokensDecorator = () => {
    let requests = 0;
    let shouldRefreshToken = false;
    let refreshTokenRequest: Promise<
        APIResponse<RefreshTokenResponse, RefreshTokenResponse>
    > | null = null;
    let handleRefreshTokenError: RefreshTokenErrorHandler = noop;
    const setRefreshTokenErrorHandler = (newHandler: RefreshTokenErrorHandler) => {
        handleRefreshTokenError = newHandler;
    };

    const withTokens = async <T, E = null>(
        url: string,
        config: RequestInit = {}
    ): Promise<APIResponse<T, E>> => {
        const api = (token: string) => {
            const promise = apiFetch<T, E>(url, {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                },
            });
            promise.then((res) => {
                const refreshAuthHeaderValue = res.response?.headers.get('rr-Should-Refresh-Auth');
                if (refreshAuthHeaderValue?.toLowerCase() === 'true') {
                    shouldRefreshToken = true;
                }
            });
            return promise;
        };

        let value = await tokenManager.getTokens();
        const { exp } = decode<{ exp: number }>(value.user.token);
        const secondsSinceEpoch = Math.round(Date.now() / 1000);

        if (!refreshTokenRequest && (secondsSinceEpoch >= exp || shouldRefreshToken)) {
            shouldRefreshToken = false;
            refreshTokenRequest = refreshToken();
        }

        if (refreshTokenRequest) {
            requests++;
            const { error } = await refreshTokenRequest;
            value = await tokenManager.getTokens();
            requests--;

            if (error && requests === 0) {
                handleRefreshTokenError(error);
            }
        }

        if (requests === 0) {
            refreshTokenRequest = null;
        }

        return api(value.user.token);
    };

    return { withTokens, setRefreshTokenErrorHandler };
};
