import React from 'react';
import { useHistory } from 'react-router';

import { EASE_OUT_QUART, fadeIn, fadeOut } from '../../styles/easing';
import { ReactComponent as EmptyCartImg } from '../../media/images/empty-cart-img.svg';
import { useRootSelector } from 'rootStore';

import { createStyles } from 'utils/createStyle';
import { Btn } from 'components/btn/Btn';
import { CartLineItems } from './NavMenuCartLineItems';
import { Routes } from 'routes';
import { monetizeWithDecimal } from 'common/utils/strings';
import { useNavMenu } from './context';

const duration = '150ms';

const styles = createStyles({
    container: [
        'fixed bg-white w-full p-2.5 duration-900 w-full z-50',
        {
            right: '0rem',
            maxWidth: '20rem',
            transform: 'translateY(0%)',
            transitionDuration: '.2s',
            transitionTimingFunction: 'ease-in-out',
            top: '0',
        },
    ],
    content: [
        'w-full h-full flex flex-col justify-center',
        {
            gridTemplateRows: 'max-content auto 6rem',
        },
    ],
    overlay: [
        'fixed bg-brand bg-opacity-90 inset-0',
        {
            '&[data-state="open"]': { animation: `${fadeIn} ${duration} ${EASE_OUT_QUART}` },
            '&[data-state="closed"]': { animation: `${fadeOut} ${duration} ${EASE_OUT_QUART}` },
        },
    ],
    lineItemSubtotal: [
        'bg-grey-7 p-2 text-sm pl-24 text-brand-dark font-bold -left-0 -right-0 absolute rounded-b bottom-0',
        {
            transform: 'translateY(100%)',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 6px -1px rgb(0 0 0 / 10%)',
            height: '40px',
        },
    ],
    lineItemSubtotalMobile: [
        'bg-white p-2 text-sm pl-24 text-brand-dark font-bold -left-0 -right-0 absolute rounded-b bottom-auto',
    ],
    lineItemSubtotalContent: [
        'text-right absolute right-0 top-0 align-middle',
        { lineHeight: '40px' },
    ],
});

export interface NavMenuCartContentProps {
    isDialogMode: boolean;
}

const EmptyCartContent = () => {
    const { closeNavMenu } = useNavMenu();
    const history = useHistory();
    const goShopping = () => closeNavMenu(() => history.push(Routes.Brands));

    return (
        <div className={styles.content}>
            <div className="px-4 text-center h-full">
                <div className="relative w-full h-full flex flex-col">
                    <div className="md:mt-2">
                        <div className="w-48 inline-block m-4 relative">
                            <div className="text-2xl text-brand font-semibold">Empty cart?</div>
                            <div className="text-xl text-grey-1 font-light">
                                No problem, lets go shopping.
                            </div>
                            <div className="absolute" style={{ top: '-20px', right: '-40px' }}>
                                <EmptyCartImg />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Btn className="mt-3 w-full bg-brand" onClick={goShopping}>
                            Take Me Shopping
                        </Btn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const NavMenuCartContent = ({ isDialogMode }: NavMenuCartContentProps) => {
    const { cart } = useRootSelector((state) => state.store);
    const loading = cart.cartAsyncState.loading;
    const items = cart.cart ? cart.cart.lineItems : [];
    const history = useHistory();
    const { closeNavMenu } = useNavMenu();

    const redirectToOrderSummary = () => closeNavMenu(() => history.push(Routes.ShopOrderSummary));

    const earnings = cart && cart.cart ? cart.cart.earnings : 0;
    const youreEarningMsg = `You're earning ${monetizeWithDecimal(earnings)}`;
    const itemsInCart = items.length > 0;

    if (!loading && !itemsInCart) {
        return <EmptyCartContent />;
    }

    const onKeepShopping = () => closeNavMenu(() => history.push(Routes.Brands));

    return (
        <div className={styles.content}>
            {itemsInCart && (
                <div className="text-center border-b border-solid border-grey-4 pb-4 mb-4">
                    <div className="text-success mb-1">{youreEarningMsg}</div>
                    <Btn
                        className="header_view_cart_button w-full"
                        onClick={redirectToOrderSummary}
                    >
                        View Cart
                    </Btn>
                    <Btn
                        isSecondary
                        className="header_keep_shopping_button w-full mt-2"
                        onClick={onKeepShopping}
                    >
                        Keep Shopping
                    </Btn>
                </div>
            )}
            <div className="h-full">
                <div>
                    <CartLineItems lineItems={items} loading={loading} />
                    {!loading && itemsInCart && (
                        <div
                            className={
                                isDialogMode
                                    ? styles.lineItemSubtotalMobile
                                    : styles.lineItemSubtotal
                            }
                        >
                            <div className={styles.lineItemSubtotalContent}>
                                Subtotal{' '}
                                <span className="font-bold float-right ml-4 mr-8">
                                    {monetizeWithDecimal(cart.cart?.subtotal || 0)}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
