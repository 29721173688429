import React, { useContext } from 'react';

import { BrandCardSlim } from 'components/brand-card/BrandCardSlim';
import { CardContext } from './contexts';

import { CardPropsEarns } from 'components/bonus/CardProps';
import { createStyles } from 'utils/createStyle';

export const TopPicksCard = (props: CardPropsEarns) => {
    const { width } = useContext(CardContext);

    const styles = createStyles({
        cardContainer: ['', { width }],
    });

    return <BrandCardSlim styles={styles} data={props} />;
};
