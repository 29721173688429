import React, { useCallback, useContext, useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
    verificationCodeError,
    verificationCodeValidator,
} from 'common/modules/form-validation/validators';
import { Btn } from 'components/btn/Btn';
import { useValidation } from 'modules/form-validation/useValidation';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { createStyles } from 'utils/createStyle';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { ContentfulSubheading } from '../../components/ContentfulSubheading';
import { LinkBtn } from 'components/link-btn';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { FormikCodeField } from 'components/fields/FormikCodeField';
import { colors } from 'styles/settings';
import { noop, delay } from 'common/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    submitTwoFactorVerificationCodeThunk,
    selectTwoFactorPhoneVerifiedAsyncState,
    selectProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { FeaturesState } from 'common/features/featuresReducer';
import { useAlert } from 'modules/alerts';
import { useDidUpdateEffect } from 'utils';
import { useHistory } from 'react-router-dom';
import { selectHomeDetails, selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { Routes } from 'routes';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { scrollTo } from '../../utils/ScrollTo';

const styles = createStyles({
    container: `text-brand md:mb-2 border-b-2 border-pale-blue md:border-none rounded-none md:rounded-lg 
    bg-white px-4 md:px-10 pb-8 py-4 px-4 md:px-10 block w-full text-left focus:outline-none max-w-xl`,
    title: 'flex text-lg md:text-3xl font-bold text-brand-dark',
    codeInput: {
        '> input:first-child': {
            marginLeft: 0,
        },
        '> input': {
            background: '#d7d7d7',
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: 'bold',
            color: colors.brand,
            width: '40px',
            height: '40px',
            marginLeft: '10px',
            borderRadius: '2px',
        },
        '> input[data-hasvalue="true"]': {
            background: '#f7f7f7',
        },
    },
});

export interface ValidateCodeInnerStepProps {}

interface VerifyCodeRequest {
    code: string;
    blackBoxValue: string;
}

type FormValues = Omit<VerifyCodeRequest, 'blackBoxValue'>;

export const ValidateCodeInnerStep = () => {
    const validateAndSubmit = useValidation<FormValues>();
    const { retrieveBlackBox } = useBlackBoxValues();
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const twoFactorPhone = useSelector(
        (s: FeaturesState) => s.store.ui.orgEnrollmentV2.twoFactorPhone
    );
    const orgId = useSelector(selectSelectedOrgId);
    const homeDetails = useSelector(selectHomeDetails);
    const alert = useAlert();
    const progressBarRef = useSelector(selectProgressBarRef);

    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const title = contentfulEntry
        ? (contentfulEntry as OrganizationEnrollmentV2).twoFactorVerifyTitle
        : undefined;

    useEffect(() => {
        scrollTo(progressBarRef);
    }, [progressBarRef]);

    const onSubmitVerifyCode = async (
        values: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => {
        const { blackBoxValue } = await retrieveBlackBox();

        try {
            await dispatch(
                submitTwoFactorVerificationCodeThunk(
                    values.code,
                    twoFactorPhone.id,
                    blackBoxValue ?? ''
                )
            );
        } catch {
            await delay(1000);
            formikHelpers.setSubmitting(false);
        }
    };

    const twoFactorPhoneVerifiedAsyncState = useSelector(selectTwoFactorPhoneVerifiedAsyncState);
    useDidUpdateEffect(() => {
        if (twoFactorPhoneVerifiedAsyncState.error) {
            alert({ message: verificationCodeError });
            return;
        }

        // NOTE: when lastUpdate > 0 it successfully verified
        if (twoFactorPhoneVerifiedAsyncState.lastUpdate) {
            history.replace(Routes.EnrollV2ChooseProgram);
        }
    }, [twoFactorPhoneVerifiedAsyncState]);

    const onBack = useCallback(() => {
        scrollTo(progressBarRef);
        history.replace(Routes.EnrollV2SecureAccount);
    }, [history, progressBarRef]);

    return (
        <FadeIn when={!!orgId && !!homeDetails}>
            <div className={styles.container}>
                <Formik initialValues={{ code: '' }} onSubmit={onSubmitVerifyCode}>
                    {(formikProps) => (
                        <form noValidate onSubmit={validateAndSubmit(formikProps)}>
                            <div className={styles.title}>{title}</div>
                            <ContentfulSubheading
                                attribute="twoFactorVerifySubtitle"
                                data={{ 'Phone Number': twoFactorPhone.phoneNumber }}
                            />
                            <FieldCode />
                            <div className="flex justify-center sm:justify-start gap-2 mt-5">
                                <Btn type="button" isSecondary={true} onClick={onBack}>
                                    Back
                                </Btn>
                                <Btn
                                    type="submit"
                                    isSecondary={false}
                                    disabled={
                                        formikProps.values.code.length < 6 ||
                                        formikProps.isSubmitting
                                    }
                                    data-testid="verifyButton"
                                >
                                    <span className="px-2">Verify</span>
                                </Btn>
                            </div>
                            <div className="mt-10">
                                <span className="text-grey-1">Didn't receive the code?</span>&nbsp;
                                <LinkBtn onClick={onBack}>Request a new one</LinkBtn>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </FadeIn>
    );
};

const FieldCode = () => {
    return (
        <FormikCodeField
            id="code"
            name="code"
            label={'Enter Verification Code'}
            validate={verificationCodeValidator}
            onTryRequestingNewOne={noop}
            hideRequestNewCode={true}
            className={styles.codeInput}
            data-testid="verificationCode"
        />
    );
};
