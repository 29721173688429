import React from 'react';

import { SlideComponent, SlideComponentProps } from './SlideInBase';

import { createStyles } from 'utils/createStyle';

const slideStyles = createStyles({
    contentStyles: [
        '',
        {
            position: 'fixed',
            top: 0,
            width: '85vw',
            height: '85vh',
            border: '1px solid lightgrey',
            background: '#FFF',
            zIndex: 99999,
        },
    ],
    backDropStyles: [
        'bg-brand',
        {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: 9999,
            top: 0,
            left: 0,
        },
    ],
});

export const SlideFromRightToLeft = ({
    backDropSpringConfig = {},
    contentSpringConfig = {},
    backDropClasses = '',
    contentClasses = '',
    ...props
}: Omit<SlideComponentProps, 'direction'>) => {
    const contentConfig = {
        opacity: props.open ? 1 : 0,
        x: props.open ? 0 : 100,
        right: 0,
        delay: 150,
        ...contentSpringConfig,
    };
    const backdropConfig = {
        opacity: props.open ? '0.8' : '0',
        delay: 150,
        ...backDropSpringConfig,
    };

    const backdropClasses = `${slideStyles.backDropStyles} ${backDropClasses}`;
    const contentClasses_ = `${slideStyles.contentStyles} ${contentClasses}`;

    return (
        <SlideComponent
            {...props}
            direction="right"
            backDropSpringConfig={backdropConfig}
            contentSpringConfig={contentConfig}
            contentClasses={contentClasses_}
            backDropClasses={backdropClasses}
        />
    );
};
