import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IRenderers {
    LoadingComponent: React.ElementType;
}

interface RenderProps<T> {
    when: boolean;
    loadingComponentProps?: T;
    renderers?: { [key: string]: React.ElementType };
    children: React.ReactNode;
}

const builtInRenderers: IRenderers = {
    LoadingComponent: Skeleton,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Render = <T extends { [key: string]: any }>({
    children,
    when,
    renderers = {},
    loadingComponentProps,
}: RenderProps<T>) => {
    const conditionIsFalse = !when;
    const loadingProps = (loadingComponentProps || {}) as T;
    const defaultRenderers: IRenderers = { ...builtInRenderers, ...renderers };

    // renderers
    const LoadingComponent = defaultRenderers.LoadingComponent;

    if (conditionIsFalse) {
        return <LoadingComponent {...loadingProps} />;
    }

    return <>{children}</>;
};
