import ConstructorIO from '@constructor-io/constructorio-client-javascript';
import { ConstructorClientOptions } from '@constructor-io/constructorio-client-javascript/lib/types';
import { isReactNative } from 'common/utils/isReactNative';

// declare process as a variable in global
declare const process: { env: { [key: string]: string } };

const apiKey = process.env.REACT_APP_CONSTRUCTOR_KEY || 'none';

function createConstructorInstance() {
    try {
        return new ConstructorIO({
            apiKey,
        } as unknown as ConstructorClientOptions);
    } catch (error) {
        return new ConstructorIO({
            apiKey,
            sessionId: 1 as unknown as string,
            clientId: '0',
        } as unknown as ConstructorClientOptions);
    }
}

export const constructorInstance = createConstructorInstance();
