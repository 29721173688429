import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import * as Progress from '@radix-ui/react-progress';

import { createStyles } from 'utils/createStyle';
import { useEnrollmentV2Steps } from './EnrollStepWrapperV2';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectCurrentStep,
    setProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { useDispatch } from 'react-redux';

export interface EnrollmentProgressBarProps {
    className?: string;
}

const styles = createStyles({
    stepsList: 'w-full flex flex-row items-center py-1 gap-2',
    stepStyle: [
        'bg-bonus-pink h-1 md:h-2 w-auto border-r border-white',
        {
            width: `${(1 / 6) * 100}%`,
            borderRight: '2px solid white',
        },
    ],
    currentStep: 'text-sm font-bold text-grey-1 md:pb-1 md:w-1/2 w-full',
    nextStep:
        'text-sm md:font-normal font-semibold md:text-grey-1 text-grey-3 md:pb-1 md:w-1/2 w-full md:text-right',
    container:
        'w-full h-14 md:h-12 bg-white mt-0.5 flex flex-col md:flex-row md:justify-between justify-center items-center md:px-4 px-2 py-0.5', // border-t border-b border-brand
    progressRoot: 'flex bg-grey-7 w-full md:h-2 h-1 p-0',
});

export const EnrollmentProgressBar = ({ className }: EnrollmentProgressBarProps) => {
    const currentStep = useRootSelector(selectCurrentStep);
    const allSteps = useEnrollmentV2Steps();
    const progressBarRef = useRef(null);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(setProgressBarRef(progressBarRef));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FadeIn when={Boolean(allSteps[currentStep].title)}>
            <>
                <div ref={progressBarRef} className={classNames(styles.container, className)}>
                    <p className={styles.currentStep}>
                        <span className="hidden md:inline">Step {currentStep + 1}:</span>{' '}
                        {allSteps[currentStep].title}
                    </p>
                    <p className={styles.nextStep}>
                        {currentStep + 1 < allSteps.length
                            ? `Next: ${allSteps[currentStep].next}`
                            : allSteps[currentStep].next}
                    </p>
                </div>
                <div className="mt-0">
                    <Progress.Root
                        className={styles.progressRoot}
                        value={currentStep + 1}
                        max={allSteps.length}
                    >
                        {Array.from({ length: currentStep + 1 }).map((_, i) => (
                            <Progress.Indicator
                                key={i}
                                className={styles.stepStyle}
                                style={i + 1 === allSteps.length ? { borderRight: 0 } : undefined}
                            />
                        ))}
                    </Progress.Root>
                </div>
            </>
        </FadeIn>
    );
};
