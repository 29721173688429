import React, { useState, useContext, useCallback } from 'react';
import { useHistory, generatePath } from 'react-router-dom';

import { RoundedBox, RoundBoxStyles } from './RoundedBox';

import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { setBillingSelection, BillingSelection } from 'common/api/e-comm/payment-types';
import { Btn } from 'components/btn/Btn';
import { PlaidWrapper } from 'features/coordinator/enrollment/steps/PlaidButton/PlaidButton';
import useQueryParams from 'hooks/useQueryParams';
import { Organization } from 'common/api/crm/models/Organization';
import { EnrollmentContext } from 'features/coordinator/enroll/context';
import { AchVerificationStatus, OrgAchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { withRetries } from 'common/utils/withRetries';
import { Routes, getPath } from 'routes';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import { useAlert } from 'modules/alerts';
import { ENROLLMENT_VERSION_2 } from 'features/coordinator/constants';
import useFeatureFlags, { FeatureFlagTypes } from 'common/hooks/useFeatureFlags';

interface BtnProps {
    loader?: React.RefObject<LoadingOverlay>;
    organization: Organization;
    children: React.ReactNode;
    version: number;
}

const PlaidButton = ({ organization, version }: BtnProps) => {
    const history = useHistory();
    const params = useQueryParams();
    const [isOAuth, setIsOAuth] = useState(Boolean(params.get('oauth_state_id')));

    const onAchAdded = useCallback(
        async <T,>(payload: T) => {
            withRetries(updateEnrollmentProgress)({
                state: ProgressState.AccountLinkedWithPlaid,
                organizationId: organization.id,
                version,
            });

            const { verificationStatus } = payload as unknown as OrgAchAccount;

            if (verificationStatus === AchVerificationStatus.PendingManualVerification) {
                history.replace(generatePath(Routes.EnrollCompleted, { status: 'deposits' }));
                return;
            }

            history.replace(getPath(Routes.EnrollCompleted, {}));
        },
        [history, organization.id, version]
    );

    return (
        <PlaidWrapper
            orgId={organization.id}
            onSuccess={onAchAdded}
            isOAuth={isOAuth}
            onExit={() => {
                setIsOAuth(false);
            }}
        >
            {(openPlaid, isPlaidReady) => (
                <Btn
                    disabled={!isPlaidReady}
                    className="m-auto block w-full sm:w-11/12 mb-4"
                    onClick={openPlaid}
                >
                    I Can Link a Bank Account With Plaid Now
                </Btn>
            )}
        </PlaidWrapper>
    );
};

const VoidedCheckBtn = ({ loader, organization, children, version }: BtnProps) => {
    const history = useHistory();
    const orgId = organization.id as string;
    const alert = useAlert();

    const onVoidedCheckClick = useCallback(async () => {
        openLoader(loader?.current as LoadingOverlay);
        const result = await withRetries(setBillingSelection)(orgId, BillingSelection.VoidedCheck);
        await closeLoader(loader?.current as LoadingOverlay);
        if (result.error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }
        withRetries(updateEnrollmentProgress)({
            state: ProgressState.VoidedCheckSelected,
            organizationId: orgId,
            version,
        });
        history.replace(Routes.EnrollSendVoidedCheck);
    }, [alert, history, orgId, loader, version]);

    return (
        <Btn isSecondary className="m-auto block w-full sm:w-11/12" onClick={onVoidedCheckClick}>
            {children}
        </Btn>
    );
};

export const LinkABankSection = ({ loader }: { loader: React.RefObject<LoadingOverlay> }) => {
    const { organization } = useContext(EnrollmentContext);
    const { isEnabled } = useFeatureFlags();

    const heading = (
        <p className="font-semibold text-white text-2xl">Linking a bank account provides:</p>
    );
    const styles: RoundBoxStyles = {
        container: 'border-brand shadow-md',
        header: 'bg-brand',
        background: 'bg-pale-blue',
    };

    return (
        <RoundedBox styles={styles} heading={heading}>
            <ul className="px-5 sm:px-10 list-disc text-sm text-grey-1 ml-5 mb-5">
                <li>95% of organizations choose to link a bank account</li>
                <li>Receive findraising earning via direct deposit</li>
                <li>
                    Participants can pay with their bank account,
                    {isEnabled(FeatureFlagTypes.enable_debit_web) ? ' debit card,' : ''} credit
                    card, or they can pay coordinator directly with cash or check
                </li>
                <li>Physical cards can be shipped directly to participants</li>
                <li>
                    You’ll need your organization’s bank login information to instantly link an
                    account or an image of a check with clear account & routing numbers
                </li>
            </ul>
            <div className="px-2">
                <PlaidButton organization={organization} version={ENROLLMENT_VERSION_2}>
                    I Can Link a Bank Account With Plaid Now
                </PlaidButton>
                <VoidedCheckBtn
                    loader={loader}
                    organization={organization}
                    version={ENROLLMENT_VERSION_2}
                >
                    I’ll Send a Voided Check Later
                </VoidedCheckBtn>
            </div>
        </RoundedBox>
    );
};
