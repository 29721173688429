import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { HomepageContext, Hero } from '../contexts/contentful';
import { HeroSectionImg } from './components/HeroSectionImg';
import { HeroSectionTextBlock, heroColors } from './components/HeroSectionTextBlock';
import { BaseSection } from './types';
import { createStyles } from 'utils/createStyle';
import { useIsInViewport } from 'hooks/useIsInViewport';
import { logViewAd, PromotionSources } from 'config/analytics';
import { CreativeSlotTypes } from 'common/types/CreativeSlotTypes';

interface HeroSectionProps extends BaseSection {
    creativeSlot?: CreativeSlotTypes;
}

export const HeroSection = ({ id, creativeSlot }: HeroSectionProps) => {
    const { heroes } = useContext(HomepageContext);
    const hero = useMemo(() => heroes[id] as Hero, [heroes, id]);
    const styles = useWithStyles(hero);

    const adRef = useRef(null);
    const isAdRefInViewPort = useIsInViewport(adRef);

    useEffect(() => {
        if (isAdRefInViewPort) {
            logViewAd({
                promotionName: hero.title || hero.id,
                index: 0,
                source: PromotionSources.Contentful,
                creativeSlot: creativeSlot as string,
            });
        }
    }, [isAdRefInViewPort, hero.title, hero.id, creativeSlot]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <div className={styles.layout} ref={adRef}>
                    <HeroSectionImg id={id} />
                    <HeroSectionTextBlock id={id} creativeSlot={creativeSlot} />
                </div>
            </div>
        </div>
    );
};

const useWithStyles = (hero: Hero) => {
    const heroColor = heroColors(hero);

    const styles = createStyles({
        mainContainer: [
            `${heroColor?.text}`,
            {
                background: heroColor?.background,
                height: 'fit-content',
            },
        ],
        container: ['flex', { height: 'fit-content' }],
        layout: [
            `flex gap-4 justify-between w-full flex-col ${
                hero.heroLayout === 'ImageRight' ? 'md:flex-row-reverse' : 'md:flex-row'
            }`,
            {},
        ],
    });

    return styles;
};
