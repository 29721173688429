import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { SECURED_E_COMM_URL } from 'common/api/config';

export interface ECommFeatureFlags {
    enable3DSecure: boolean;
    enableDashboard: boolean;
}

export const getFeatureFlags = () =>
    withTokensIfNeeded<ECommFeatureFlags>(`${SECURED_E_COMM_URL}/FeatureFlags`);
