import { PartialRecord } from 'common/definitions/PartialRecord';
import { fromEntries } from 'common/utils';

const matcherRegExp = /{{([a-z0-9 ]+)}}/gi;
const normalizeParam = (param: string) => param.replace(/\s/g, '').toLowerCase();

export const formatString = (str: string, data: PartialRecord<string, string>) => {
    const dataNormalized = fromEntries(
        Object.entries(data).map(([k, v]) => [normalizeParam(k), v])
    );
    return str.replace(matcherRegExp, (_match, p) => {
        return dataNormalized[normalizeParam(p)] || '';
    });
};
