import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { FEATURE_FLAGS_URL } from 'common/api/config';

export type ApiResponseFeatureFlags = {
    featureFlags: Array<{
        key: string;
        enabled: boolean;
    }>;
};

export const getCrmFeatureFlags = async () => {
    return await withTokensIfNeeded<ApiResponseFeatureFlags>(`${FEATURE_FLAGS_URL}?api-version=1`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
