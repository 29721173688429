import { generatePath } from 'react-router-dom';

import { OrgEnrollmentCompletedParams } from 'features/coordinator/enrollment/OrgEnrollmentCompleted';
import { ProductDetailsScreenParams } from 'features/product/components/utils/types';
import { LocalProductDetailsParams } from 'features/product/LocalProductScreen';
import { OrderPaymentScreenParams } from 'features/order-summary/screens/OrderPaymentScreen';
import { OrderPaymentSuccessParams } from 'features/order-summary/screens/OrderSuccessScreen';
import { OrgEnrollCompletedParams } from 'features/coordinator/enroll/steps/OrgEnrollCompleted';
import { FamilyLandingParams } from 'features/family-enrollment/FamilyLanding';

export enum Routes {
    Home = '/',
    Login = '/bff/login',
    Support = '/support',
    Brands = '/brands',
    BrandsDetails = '/brands/:id',
    BrandsGiftCardDetails = '/brands/:id/gift-cards',
    BrandsShopOnlineDetails = '/brands/:id/shop-online',
    BrandBulkOrder = '/brands/:id/gift-cards/gifting',
    OnBonus = '/on-bonus',
    ShopOnlineOnBonus = '/shop-online-bonus',
    Favorites = '/favorites',
    Featured = '/featured-brands',
    HowItWorks = '/how-it-works',
    HowItWorksShopOnline = '/how-it-works/shop-online',
    HowItWorksBrowserExtension = '/m/extension',
    WhoWeBenefit = '/nonprofits-we-help',
    WhoWeHelp = '/who-we-help',
    WhoWeHelpSchools = '/who-we-help/schools',
    WhoWeHelpChurchers = '/who-we-help/churches',
    WhoWeHelpSportsTeams = '/who-we-help/sports-teams',
    WhoWeHelpBands = '/who-we-help/bands',
    WhoWeHelpBoosterClubs = '/who-we-help/booster-clubs',
    WhoWeHelpNonProfit = '/who-we-help/nonprofit-fundraising',
    Resources = '/resources',
    ImpactStories = '/about-us/impact-stories',
    Blog = '/blog',
    AboutUs = '/about-us',
    Contact = '/about-us/contact',
    Careers = '/about-us/careers',
    PrivacyPolicy = '/about-us/privacy-policy',
    TermsOfUse = '/about-us/terms-of-use',
    SupportFaq = '/resources/faq',
    SupportOrganization = '/resources/organization',
    SupportParticipant = '/resources/participant',
    FundraisingIdeas = '/m/fundraising-ideas',
    SupportSchoolChoice = '/resources/school-choice',
    Scripware = '/resources/organization/scripware-inventory-management',
    Sitemap = '/m/sitemap',

    StartProgram = '/enroll/start-program',
    StartProgramAccount = '/enroll/start-program/account',
    StartProgramSecurity = '/enroll/start-program/security',
    StartProgramTwoFactor = '/enroll/start-program/security/two-factor',
    StartProgramTwoFactorVerify = '/enroll/start-program/security/two-factor-verify',
    StartProgramOrganization = '/enroll/start-program/organization',
    StartProgramOrganization2 = '/enroll/start-program/organization/2',
    StartProgramEarnings = '/enroll/start-program/earnings',
    StartProgramVoidedCheck = '/enroll/start-program/voided-check',
    StartProgramCompleted = '/enroll/start-program/completed/:status?',
    StartProgramContinue = '/enroll/start-program/continue',

    StyleGuide = '/styleguide',
    DeepLinkGenerator = '/internal-tools/deep-link-generator',

    Shop = '/shop',
    ShopLocalProductDetails = '/local-products/:productId',
    ShopOrderScreen = '/shop/order',
    ShopOrderSummary = '/shop/order/summary',
    ShopOrderPayment = '/shop/order/payment/:shipToHome',
    ShopOrderShipping = '/shop/order/shipping',
    ShopOrderReview = '/shop/order/review',
    ShopOrderSuccess = '/shop/order/success/:orderId',
    ShopAccountSettings = '/shop/account',
    ShopAccountSettingsDashboardTab = '/shop/account/personal-dashboard',
    ShopAccountSettingsOrderHistoryTab = '/shop/account/order-history',
    ShopAccountSettingsTab = '/shop/account/settings/:tab',
    ShopAccountSettingsPersonalInformationTab = '/shop/account/settings/personal-information',
    ShopAccountSettingsSecurityTab = '/shop/account/settings/security',
    ShopAccountSettingsPaymentOptionsTab = '/shop/account/settings/payment-options',
    ShopAccountSettingsNotificationsTab = '/shop/account/settings/notifications',
    ShopAccountSettingsInviteFriendsFamilyOptionsTab = '/shop/account/settings/invite-friends-family',
    ShopAccountSettingsCoordinatorTab = '/shop/account/coordinator-dashboard',
    ShopAccountSettingsCoordinatorOrganizationSettings = '/shop/account/coordinator-dashboard/organization/settings',
    WalletMyCards = '/shop/wallet',
    WalletArchivedCards = '/shop/wallet?section=archived',
    WalletMyCardDetails = '/shop/wallet/:cardId',
    ShopByCategory = '/shop/category/:categoryId',
    ShopOnline = '/shop-online',
    ShopGiftCards = '/gift-cards',

    Enroll = '/enroll/:enrollCode?',
    EnrollWithoutEnrollCode = '/enroll/',
    EnrollStartProgram = '/enroll/start-program',
    EnrollContinue = '/enroll/start-program/continue',
    EnrollCreateAccount = '/enroll/start-program/create-account',
    EnrollSetupRecoveryPhone = '/enroll/start-program/setup-recovery-phone',
    EnrollVerifyRecoveryPhone = '/enroll/start-program/verify-recovery-phone',
    EnrollUserCreateLoadingPage = '/enroll/start-program/account-interstitial',
    EnrollOrgName = '/enroll/start-program/organization-name',
    EnrollOrgType = '/enroll/start-program/organization-type',
    EnrollOrgQuestion1 = '/enroll/start-program/organization-question-1',
    EnrollOrgPeople = '/enroll/start-program/organization-people',
    EnrollOrgQuestion2 = '/enroll/start-program/organization-question-2',
    EnrollOrgFundraisingReason = '/enroll/start-program/organization-fundraising-reason',
    EnrollOrgProfile = '/enroll/start-program/organization-profile',
    EnrollOrgCreateLoadingPage = '/enroll/start-program/organization-interstitial',
    EnrollChooseYourPlan = '/enroll/start-program/choose-your-plan',
    EnrollChooseEarningTypePlus = '/enroll/start-program/choose-earning-type-plus',
    EnrollChooseEarningTypeLite = '/enroll/start-program/choose-earning-type-lite',
    EnrollLiteProgramInfo = '/enroll/start-program/lite-program-info',
    EnrollSendVoidedCheck = '/enroll/start-program/send-voided-check',
    EnrollCompleted = '/enroll/start-program/enrollment-completed/:status?',

    // new enroll V2
    EnrollV2 = '/enroll/start-program/v2',
    EnrollV2SecureAccount = '/enroll/start-program/v2/security/two-factor',
    EnrollV2SecureAccountVerify = '/enroll/start-program/v2/security/two-factor/verify',
    EnrollV2ChooseProgram = '/enroll/start-program/v2/choose-program',
    EnrollV2Submitted = '/enroll/start-program/v2/submitted',
    EnrollV2Completed = '/enroll/start-program/v2/completed',
    EnrollV2CompletedInstant = '/enroll/start-program/v2/completed/instant',
    EnrollV2CompletedMicroDeposits = '/enroll/start-program/v2/completed/micro-deposits',
    EnrollV2CompletedAutomatedDeposits = '/enroll/start-program/v2/completed/automated-deposits',
    EnrollV2CompletedPaperOnly = '/enroll/start-program/v2/completed/paper-only',

    // family enrollment
    EnrollFamily = '/enroll/join-program',
    EnrollFamilyCreateAccount = '/enroll/join-program/create-account',
    EnrollFamilySignInInfo = '/enroll/join-program/sign-in-information',
    EnrollFamilySecurityQuestions = '/enroll/join-program/security-questions',
    EnrollFamilySetupRecoveryPhone = '/enroll/join-program/setup-recovery-phone',
    EnrollFamilyVerifyRecoveryPhone = '/enroll/join-program/verify-recovery-phone',

    // proxy routes
    FamilyEarningsSummary = '/c/Family/Report/Selection?rpt=FamilyEarningsSummary',
    FamilyOrderHistory = '/c/Family/Report/Selection?rpt=FamilyOrderHistory',
    FamilyOrderDetail = '/c/Family/Report/Selection?rpt=FamilyOrderDetail',

    // error routes
    FailedDefaultOrg = '/shop/faileddefaultorg?status_code=Oops',

    // New Coordinator Experience
    CoordinatorDashboardRoot = '/shop/account/coordinator-dashboard-new',
    CoordinatorDashboardHome = '/shop/account/coordinator-dashboard-new/home',

    CoordinatorDashboardEnterOrders = '/shop/account/coordinator-dashboard-new/enter-orders',
    CoordinatorDashboardOrderQueue = '/shop/account/coordinator-dashboard-new/order-queue',
    CoordinatorDashboardPOManager = '/shop/account/coordinator-dashboard-new/po-manager',
    CoordinatorDashboardParticipantOrderForm = '/shop/account/coordinator-dashboard-new/participant-order-form',

    CoordinatorDashboardPackingSlips = '/shop/account/coordinator-dashboard-new/packing-slips',
    CoordinatorDashboardAllReports = '/shop/account/coordinator-dashboard-new/all-reports',

    CoordinatorDashboardParticipantAccounts = '/shop/account/coordinator-dashboard-new/participant-accounts',
    CoordinatorDashboardInviteParticipants = '/shop/account/coordinator-dashboard-new/invite-participants',

    CoordinatorDashboardOrganizationProfile = '/shop/account/coordinator-dashboard-new/organization-profile',
    CoordinatorDashboardOrganizationSettings = '/shop/account/coordinator-dashboard-new/settings',
    CoordinatorDashboardCoordinatorRoles = '/shop/account/coordinator-dashboard-new/coordinator-roles',
    CoordinatorDashboardOrganizationPINReset = '/shop/account/coordinator-dashboard-new/organization-pin-reset',
    CoordinatorDashboardLocalProducts = '/shop/account/coordinator-dashboard-new/local-products',
    CoordinatorDashboardLocalSuppliers = '/shop/account/coordinator-dashboard-new/local-suppliers',
    CoordinatorDashboardNotifications = '/shop/account/coordinator-dashboard-new/notifications',
    CoordinatorDashboardSCRIPWARE = '/shop/account/coordinator-dashboard-new/scripware',
    CoordinatorDashboardOrderHistory = '/shop/account/coordinator-dashboard-new/order-history',

    CoordinatorDashboardPaymentChangeForm = '/shop/account/coordinator-dashboard-new/payment-change-form',
    CoordinatorDashboardPaymentTypes = '/shop/account/coordinator-dashboard-new/payment-types',

    CoordinatorDashboardResourceCenter = '/shop/account/coordinator-dashboard-new/resource-center',
    CoordinatorDashboardCustomerSupport = '/shop/account/coordinator-dashboard-new/customer-support',
}

export const ProtectedRoutes = [
    Routes.ShopAccountSettings,
    Routes.ShopAccountSettings,
    Routes.ShopAccountSettingsDashboardTab,
    Routes.ShopAccountSettingsOrderHistoryTab,
    Routes.ShopAccountSettingsTab,
    Routes.ShopAccountSettingsPersonalInformationTab,
    Routes.ShopAccountSettingsSecurityTab,
    Routes.ShopAccountSettingsPaymentOptionsTab,
    Routes.ShopAccountSettingsCoordinatorTab,
    Routes.ShopAccountSettingsCoordinatorOrganizationSettings,
    Routes.WalletMyCards,
    Routes.WalletArchivedCards,
    Routes.WalletMyCardDetails,
    Routes.ShopOrderScreen,
    Routes.ShopOrderSummary,
    Routes.ShopOrderPayment,
    Routes.ShopOrderShipping,
    Routes.ShopOrderReview,
    Routes.ShopOrderSuccess,
    Routes.ShopOnline,
    Routes.ShopGiftCards,

    Routes.EnrollV2SecureAccount,
    Routes.EnrollV2ChooseProgram,
    Routes.EnrollV2CompletedInstant,
    Routes.EnrollV2CompletedMicroDeposits,
    Routes.EnrollV2CompletedAutomatedDeposits,
    Routes.EnrollV2CompletedPaperOnly,

    Routes.CoordinatorDashboardRoot,
    Routes.CoordinatorDashboardHome,

    Routes.CoordinatorDashboardEnterOrders,
    Routes.CoordinatorDashboardOrderQueue,
    Routes.CoordinatorDashboardPOManager,
    Routes.CoordinatorDashboardParticipantOrderForm,

    Routes.CoordinatorDashboardPackingSlips,
    Routes.CoordinatorDashboardAllReports,

    Routes.CoordinatorDashboardParticipantAccounts,
    Routes.CoordinatorDashboardInviteParticipants,

    Routes.CoordinatorDashboardOrganizationProfile,
    Routes.CoordinatorDashboardOrganizationSettings,
    Routes.CoordinatorDashboardOrganizationPINReset,
    Routes.CoordinatorDashboardOrderHistory,
    Routes.CoordinatorDashboardCoordinatorRoles,
    Routes.CoordinatorDashboardLocalProducts,
    Routes.CoordinatorDashboardLocalSuppliers,
    Routes.CoordinatorDashboardNotifications,
    Routes.CoordinatorDashboardSCRIPWARE,
    Routes.CoordinatorDashboardPaymentChangeForm,
    Routes.CoordinatorDashboardPaymentTypes,
    Routes.CoordinatorDashboardResourceCenter,
    Routes.CoordinatorDashboardCustomerSupport,
];

export interface RoutePathParams {
    [Routes.StartProgramCompleted]: OrgEnrollmentCompletedParams;
    [Routes.BrandsDetails]: ProductDetailsScreenParams;
    [Routes.BrandsGiftCardDetails]: ProductDetailsScreenParams;
    [Routes.BrandsShopOnlineDetails]: ProductDetailsScreenParams;
    [Routes.ShopLocalProductDetails]: LocalProductDetailsParams;
    [Routes.ShopOrderPayment]: OrderPaymentScreenParams;
    [Routes.ShopOrderSuccess]: OrderPaymentSuccessParams;
    [Routes.EnrollCompleted]: OrgEnrollCompletedParams;
    [Routes.Enroll]: FamilyLandingParams;
}

export const getPath = <R extends keyof RoutePathParams>(route: R, params: RoutePathParams[R]) => {
    return generatePath(route, params as any);
};
