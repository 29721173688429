import React, { useState } from 'react';
import { HomepageContext, Hero } from 'features/homepage/contexts/contentful';
import { useContext, useMemo } from 'react';
import { createStyle } from 'utils/createStyle';
import useMediaQueries from 'hooks/useMediaQueries';

export const HeroSectionImg = ({ id }: { id: string }) => {
    const { heroes } = useContext(HomepageContext);
    const hero = useMemo(() => heroes[id] as Hero, [heroes, id]);
    const styles = useWithStyles();

    if (hero.heroLayout === 'CenteredHeadline') {
        return null;
    }

    return hero.heroImage ? (
        <div className={`flex flex-1 md:items-center`}>
            <div className="xl:flex xl:flex-1">
                <img src={hero?.heroImage} className={styles} />
            </div>
        </div>
    ) : null;
};

const useWithStyles = () => {
    const [styles, setStylesTo] = useState<string>(
        createStyle('flex-auto', { height: 540, maxHeight: 540 })
    );

    const queries = useMemo(
        () => ({
            '(max-width: 768px), (max-width: 1279px)': () => {
                setStylesTo(createStyle('flex-auto'));
            },
            '(min-width: 1280px)': () => {
                setStylesTo(createStyle('flex-auto', { height: 540, maxHeight: 540 }));
            },
        }),
        []
    );

    useMediaQueries(queries);

    return styles;
};
