export const noop = Function.prototype as () => void;

export const withProtocol = (protocol: string) => (url: string) => `${protocol}${url}`;

export const withHTTPS = withProtocol('https:');

export const identity = <T>(t: T): T => t;

export const fromEntries = <T>(strMap: [string, T][]) => {
    const obj = {} as { [key: string]: T };
    for (const [k, v] of strMap) {
        obj[k] = v;
    }
    return obj;
};

export const removeFirst = <T>(array: T[], item: T, replacement?: T): T[] => {
    const index = array.indexOf(item);
    if (index === -1) {
        return array;
    }
    const clone = [...array];
    if (replacement) {
        clone.splice(index, 1, replacement);
    } else {
        clone.splice(index, 1);
    }
    return clone;
};

export const toggleElement = <T>(array: T[], item: T): T[] => {
    const index = array.indexOf(item);
    const clone = [...array];
    if (index === -1) {
        clone.push(item);
    } else {
        clone.splice(index, 1);
    }
    return clone;
};

export const times = <T>(n: number, callback: (i: number) => T): T[] => {
    return Array.from(Array(n)).map((_, i) => callback(i));
};

export const sortBy =
    <T>(sortFn: (a: T, b: T) => number) =>
    (data: T[]) =>
        [...data].sort(sortFn);

export const sumBy = <T>(arr: T[], iteratee: (input: T) => number): number => {
    return arr.reduce((acc, curr) => acc + iteratee(curr), 0);
};

export const isDefined = <T>(t: T | null | undefined): t is T => t != null;

export const uniq = <T>(array: T[]) => Array.from(new Set(array));

export const hasAllKeys =
    <T>(...keys: Array<keyof T>) =>
    (maybeT: Partial<T>): boolean => {
        return keys.every((key) => maybeT[key] != null);
    };

export const last = <T>(arr?: T[]) => (arr ? arr[arr.length - 1] : undefined);

export const interleave = <T, U>(arr: T[], inserter: (index: number) => U): (T | U)[] => {
    if (arr.length <= 1) {
        return arr;
    }

    const interleaved: (T | U)[] = [];
    arr.forEach((item, i) => {
        if (i !== 0) {
            interleaved.push(inserter(i - 1));
        }
        interleaved.push(item);
    });

    return interleaved;
};

export interface CircularCache<T> {
    internalCache: { [id: string]: T };
    internalCacheKeys: string[];
}

// a simple circular cache function with a maxSize that discards older results and adds the new ones.
export const addItemsToCacheByKey =
    <T>(fnKey: (item: T) => string, maxSize = 1000) =>
    (cacheObj: CircularCache<T>, items: T[]): CircularCache<T> => {
        const internalCache: { [id: string]: T } = {
            ...(cacheObj.internalCache || (cacheObj.internalCache = {})),
        };
        const internalCacheKeys: string[] = [
            ...((cacheObj.internalCacheKeys as []) || (cacheObj.internalCacheKeys = [])),
        ];
        let size = internalCacheKeys.length;

        const addItem = (item: T) => {
            const key = fnKey(item);

            // if it is a new item, increment the size and add the key to the list.
            if (!internalCache[key]) {
                internalCacheKeys.push(key);
                size++;
            }

            // drops the old values if the list reached (maxSize + 1).
            while (size > maxSize) {
                const itemKeyToBeRemoved = internalCacheKeys.shift() as string;
                delete internalCache[itemKeyToBeRemoved];
                size--;
            }

            internalCache[key] = item;
        };

        items.map(addItem);

        return {
            internalCache,
            internalCacheKeys,
        };
    };

export const getItemsFromCache = <T>(cacheObj: CircularCache<T>) => {
    return Object.values(cacheObj.internalCache);
};

export const trimAndLowerCase = (str: string) => (str || '').toString().toLowerCase().trim();

export const delay = (duration = 250) => new Promise((resolve) => setTimeout(resolve, duration));

export const stringSplitByIndex = (str: string, index: number) => {
    // case where index is greater than the length of the string
    if (str.length <= index) {
        return [str, ''];
    }

    const [, first, second] = str.match(new RegExp(`(.{${index}})(.*)`, 'i')) || ['', ''];

    return [first.trim(), second.trim()];
};
