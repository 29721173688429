import { useState, useMemo } from 'react';

import useMediaQueries from 'hooks/useMediaQueries';
import { createStyle } from 'utils/createStyle';
import { sortOptions } from './variables';
import { SortByOption } from './SortBy';

export const useSearchGridStyles = <mobile, desktop>(
    mobileWidth: mobile,
    desktopWidth: desktop
) => {
    const [width, setWidth] = useState<mobile | desktop>(desktopWidth);

    useMediaQueries({
        '(max-width: 640px)': () => {
            setWidth(mobileWidth);
        },
        '(min-width: 640px)': () => {
            setWidth(desktopWidth);
        },
    });

    const searchGridStyles = useMemo(
        () =>
            createStyle('', {
                gridTemplateColumns: `repeat(auto-fit, minmax(${width}px, 2fr))`,
            }),
        [width]
    );

    return [searchGridStyles, width];
};

interface ISearchSortOptionsHook {
    sortedBy: SortByOption;
    setSortedBy: React.Dispatch<SortByOption>;
    sortOptions: SortByOption[];
}

export const useSearchSortOptions = (): ISearchSortOptionsHook => {
    const [sortedBy, setSortedBy] = useState<SortByOption>(sortOptions[1]);
    return { sortedBy, setSortedBy, sortOptions };
};
