import React, { useRef } from 'react';
import { useRootSelector } from 'common/features/featuresReducer';

import {
    IAutocompleteParameters,
    SearchParameters,
    IBrowseParameters,
} from '@constructor-io/constructorio-client-javascript/lib/types';
import { createSelector } from 'reselect';
import { selectDefaultOrganization } from 'common/features/store/duck/home/duck';
import { SelectedOrganization } from '../crm/home/models/HomeDetailsResponse';

export const selectOrgIdAndState = createSelector(
    selectDefaultOrganization,
    (defaultOrganization: SelectedOrganization | null) => {
        if (defaultOrganization) {
            return [defaultOrganization.id, defaultOrganization.address?.state];
        }
        return [];
    }
);

export const useConstructorDefaultParameters = <
    T extends SearchParameters | IBrowseParameters | IAutocompleteParameters
>(
    extraParams: T
) => {
    const [orgId, state] = useRootSelector(selectOrgIdAndState);
    const isShipToCoordinatorEnabled = useRootSelector(
        (s) => s.store.organization.options?.isShipToCoordinatorEnabled
    );

    const defaultFilters = useRef<T>({} as T);
    defaultFilters.current = {
        ...extraParams,
        filters: {
            OrganizationId: ['0', orgId],
            IsAvailableForVirtualOnly: !isShipToCoordinatorEnabled ? ['True'] : undefined,
            AllowedStates: [state],
            ...extraParams.filters,
        },
    } as T;
    return defaultFilters;
};
