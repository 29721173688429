/* eslint-disable camelcase */
import { useCallback, useEffect } from 'react';

// Iovation web docs
// https://glscrip.atlassian.net/wiki/spaces/GLSC/pages/518029360/Iovation
// https://glscrip.atlassian.net/wiki/spaces/GLSC/pages/740458501/Iovation+File

/*
 * Iovation integration implementation
 *
 * Requirements:
 *
 * static_wdp.js, dyn_wdp.js and snare.js need to be loaded on the dom in order
 * to retrieve the `blackbox-values` used for some API requests.
 * `loadFirstPartyBB` and `loadSecondPartyBB` are responsible for the loading the scripts
 * into the dom. We have a little helper `addScripts` function to call both functions.
 *
 * We initialize global functions for Iovation to utilize:
 * - fp_bb_callback :: used to retrieve the blackbox value from the First-Party
 * - io_bb_callback :: used to retrieve the blackbox value from the 3rd-Party
 *
 * These two functions get called multiple times with different black-box values and a `complete?`
 * value(true/false). True for when the blackbox value is ready to go and False for when blackbox
 * value is not ready.  We ignore the `complete?` variable and just assign the blackbox values to
 * variables which are to be used on the `getBlackBoxValue` function.
 *
 * The reason we ignore the `complete?` variable is that there are times when `complete? === true`
 * but the callback gets called multiple times with different blackbox values which made checking
 * for `complete?` wasteful. The Iovation manual ignores the `complete?` variable as well in their
 * sample implementations.
 *
 * Retry logic is implemented and triggered IFF blackBoxValue is `null`. It retries at most 3 times
 * then returns to the caller.
 */

interface IovationGlobals {
    fp_bb_callback?: (bb: string, complete: boolean) => void;
    io_bb_callback?: (bb: string, complete: boolean) => void;
    fpGetBlackbox?: () => { blackbox: string; finished: boolean };
    ioGetBlackbox?: () => { blackbox: string; finished: boolean };
}

interface BlackboxValues {
    blackBoxValue: string | null;
    secondaryBlackBoxValue: string | null;
}

let firstPartyBB: string | null = null;
let secondPartyBB: string | null = null;

const FIRST_PARTY_STATIC_ID = 'FIRST_PARTY_STATIC_ID';
const FIRST_PARTY_DYN_ID = 'FIRST_PARTY_DYN_ID';
const THIRD_PARTY_ID = 'THIRD_PARTY_ID';

const loadFirstPartyBB = () => {
    if ((window as IovationGlobals).fp_bb_callback) {
        return;
    }

    const firstPartyDynamicScript = document.createElement('script');
    firstPartyDynamicScript.src = process.env.REACT_APP_FIRST_PARTY_IOVATION_JS_URL!;
    firstPartyDynamicScript.id = FIRST_PARTY_DYN_ID;

    const firstPartyStaticScript = document.createElement('script');
    firstPartyStaticScript.src = '/scripts/static_wdp.js';
    firstPartyStaticScript.id = FIRST_PARTY_STATIC_ID;

    // error handling incase scripts don't load
    firstPartyDynamicScript.onerror = (e) => {
        console.error('First Party Dynamic Script: ', e);
    };
    firstPartyStaticScript.onerror = (e) => {
        console.error('Second Party Static Script: ', e);
    };

    // configure iovation
    (window as IovationGlobals).fp_bb_callback = (bb) => {
        firstPartyBB = bb;
    };

    // append script into the page
    document.body.appendChild(firstPartyStaticScript);
    document.body.appendChild(firstPartyDynamicScript);
};

const loadSecondPartyBB = () => {
    // only insert script once, we check the io_bb_callback definition
    if ((window as IovationGlobals).io_bb_callback) {
        return;
    }

    const thirdPartyScript = document.createElement('script');
    thirdPartyScript.id = THIRD_PARTY_ID;
    thirdPartyScript.src = process.env.REACT_APP_THIRD_PARTY_IOVATION_JS_URL!;

    thirdPartyScript.onerror = (e) => {
        console.error('Third Party Script: ', e);
    };

    (window as IovationGlobals).io_bb_callback = (bb) => {
        secondPartyBB = bb;
    };

    document.body.appendChild(thirdPartyScript);
};

const addScripts = () => {
    loadSecondPartyBB();
    loadFirstPartyBB();
};

const removeScripts = () => {
    const firstDynScript = document.getElementById(FIRST_PARTY_DYN_ID);
    const firstStaticScript = document.getElementById(FIRST_PARTY_STATIC_ID);
    const thirdScript = document.getElementById(THIRD_PARTY_ID);

    firstDynScript?.remove();
    firstStaticScript?.remove();
    thirdScript?.remove();

    (window as IovationGlobals).io_bb_callback = undefined;
    (window as IovationGlobals).fp_bb_callback = undefined;
};

const getBlackBoxValue = () => {
    const win = window as IovationGlobals;
    const blackBoxValue = (win.fpGetBlackbox && win.fpGetBlackbox().blackbox) || firstPartyBB;
    const secondaryBlackBoxValue =
        (win.ioGetBlackbox && win.ioGetBlackbox().blackbox) || secondPartyBB;

    return { blackBoxValue, secondaryBlackBoxValue };
};

const blackBoxValueIsNotDefined = (bbValues: BlackboxValues) => {
    return bbValues.blackBoxValue === null;
};

const delay = (duration = 250) => new Promise((resolve) => setTimeout(resolve, duration));

export const useBlackBoxValues = () => {
    useEffect(() => {
        addScripts();
    }, []);

    /*
     * Retry logic to reload the {dyn|static}_wdp and snare.js files in order
     * to retrieve the blackbox values
     */
    const retry = async () => {
        const retry_ = () => {
            removeScripts();
            addScripts();

            return getBlackBoxValue();
        };

        // we retry at least 3 times
        let blackBoxValues: BlackboxValues = { blackBoxValue: null, secondaryBlackBoxValue: null };
        const max_retry_count = 3;
        for (let tries = 0; tries < max_retry_count; tries++) {
            blackBoxValues = retry_();
            if (blackBoxValueIsNotDefined(blackBoxValues)) {
                await delay(1000);
                continue;
            }

            // blackBoxValues are defined lets break out of the loop and return
            break;
        }
        return blackBoxValues;
    };

    const retrieveBlackBox = useCallback(async (): Promise<BlackboxValues> => {
        const blackBoxValues = getBlackBoxValue();

        // retry fetching the files incase there were issues loading the dyn_wdp.js, static_wdp.js, snare.js
        if (!blackBoxValues.blackBoxValue) {
            return await retry();
        }

        return blackBoxValues;
    }, []);

    return { retrieveBlackBox };
};
