import React from 'react';
import classNames from 'classnames';

import { createStyles } from 'utils/createStyle';
import { getFocusOutline } from 'styles/accessibility';

const TiledButtonClass = createStyles({
    button: [
        'w-40 sm:w-44 h-40 sm:h-44 mr-2.5 sm:mr-6 mb-4 p-4 flex flex-col justify-center items-center relative rounded-xl',
        getFocusOutline({ borderRadius: 16, offset: 6 }),
        {
            border: '1px solid grey',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
        },
    ],
    bannerText: [
        'font-semibold absolute top-0 left-0 w-full bg-brand-dark text-white uppercase',
        {
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
            fontSize: '0.625rem',
        },
    ],
    secondaryBannerText: [
        'font-semibold border-b absolute top-0 left-0 w-full text-brand-dark uppercase',
        {
            borderTopLeftRadius: '9px',
            borderTopRightRadius: '9px',
            fontSize: '0.625rem',
        },
    ],
    buttonText: 'block text-brand-dark text-sm',
    description: ['w-40 sm:w-44 text-xs mb-6', { color: '#333' }],
});

export interface TiledButtonProps {
    bannerText?: string;
    buttonText: string;
    buttonClassName?: string;
    buttonTextClassName?: string;
    descriptionClassName?: string;
    onClick: () => void;
    icon?: React.ReactChild;
    trailingDescription?: string;
    isSecondary?: boolean;
    disabled?: boolean;
}

export const TiledButton = (props: TiledButtonProps) => {
    const {
        bannerText,
        buttonText,
        buttonClassName,
        buttonTextClassName,
        onClick,
        icon,
        trailingDescription,
        descriptionClassName,
        isSecondary,
    } = props;

    return (
        <div className={'flex flex-col'}>
            <button
                disabled={props.disabled}
                onClick={onClick}
                className={classNames(TiledButtonClass.button, buttonClassName)}
            >
                {bannerText && (
                    <p
                        className={
                            isSecondary
                                ? TiledButtonClass.secondaryBannerText
                                : TiledButtonClass.bannerText
                        }
                    >
                        {bannerText}
                    </p>
                )}
                <span className={classNames(TiledButtonClass.buttonText, buttonTextClassName)}>
                    {buttonText}
                </span>
                {icon && icon}
            </button>
            {trailingDescription && (
                <p className={classNames(TiledButtonClass.description, descriptionClassName)}>
                    {trailingDescription}
                </p>
            )}
        </div>
    );
};
