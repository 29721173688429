import React, { useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDown, ChevronUp } from 'react-feather';

import { createStyle, createStyles } from '../../../utils/createStyle';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Switch } from '../../../components/switch/Switch';

import { SearchFacet } from 'common/api/search/models/facets/SearchFacet';
import { includesEq, includesLt, includesGt, includesGeLt } from 'common/utils/searchFilters';
import { toPascalCase } from 'common/utils/strings';
import { PriceSearchFacet } from 'common/api/search/models/facets/PriceSearchFacet';

const createFilterItem = (propertyName: MenuTabNames, model: SearchFacet): FilterByOption => ({
    group: propertyName,
    label: model.value,
    value: includesEq(toPascalCase(propertyName), model.value),
});

export enum MenuTabNames {
    category = 'Categories',
    cardType = 'SupportedTypes',
    price = 'Denomination',
    earningsType = 'EarningsType',
}

export const convertFacetIntoGroupItem = (
    key: MenuTabNames,
    title: string,
    facet: SearchFacet[] = []
): FilterByGroupOption => {
    return {
        key,
        title,
        options: facet.map<FilterByOption>((f: SearchFacet) => createFilterItem(key, f)),
    };
};

const createFilterItemFromPriceFilter = (
    propertyName: string,
    model: PriceSearchFacet
): FilterByOption => {
    const facetName = toPascalCase(propertyName);

    if (!model.from) {
        return {
            group: propertyName,
            label: `$0 - $${model.to}`,
            value: includesLt(facetName, model.to),
        };
    } else if (!model.to) {
        return {
            group: propertyName,
            label: `Over $${model.from}`,
            value: includesGt(facetName, model.from),
        };
    }

    return {
        group: propertyName,
        label: `$${model.from} - $${model.to}`,
        value: includesGeLt(facetName, model.from, model.to),
    };
};

export const convertFacetIntoRangeGroupItem = (
    key: MenuTabNames,
    title: string,
    ranges: PriceSearchFacet[]
): FilterByGroupOption => {
    const options = ranges.map((range: PriceSearchFacet) =>
        createFilterItemFromPriceFilter(key, range)
    );
    return {
        key,
        title,
        options,
    };
};

const styles = createStyles({
    container: createStyle('border-brand border border-solid p-2 rounded text-brand inline-block', {
        gridTemplateColumns: 'min-content min-content',
    }),
    sortedByFieldTitle: 'inline-block',
    sortedByFieldValue: 'inline-block font-bold',
    sortedByFieldIcon: 'grid items-center',
    filterTitle: 'text-brand-dark text-2xl font-black mb-6',
});

const generalStyles = createStyles({
    container: 'bg-white',
    header: '',
    headerFilterCmds: 'flex items-center mb-2',
    headerFilterCmdsClearFilter: createStyle('', { marginLeft: 'auto' }),
});

export interface FilterByOption {
    label: string;
    value: string;
    group: string;
}

export interface FilterByGroupOption {
    key: MenuTabNames;
    title: string;
    options: FilterByOption[];
}

export interface SelectedOption {
    key: string;
    groupKey: FilterByGroupOption;
    optionKey: FilterByOption;
}

export interface FilterData {
    selectedOptions: FilterByOption[];
    onBonusOnly: boolean;
}

export interface FilterByProps {
    groups: FilterByGroupOption[];
    data: FilterData;
    onChange: (filterData: FilterData) => void;
    showAZFilter?: boolean;
}

const stylesMenu = createStyles({
    menuItems: createStyle('ml-4 overflow-auto'),
    menuItem: createStyle('my-2'),
});

const styleHeader = createStyles({
    accordionGroupHeader: createStyle('text-brand'),
    accordionGroupButton: createStyle('p-4 block w-full text-left', {
        borderBottom: '1px solid gray',
    }),
});

export const getOptionKey = (option: string, group: string) => `${group}_${option}`;

export const FilterByAccordion = ({ groups, data, onChange }: FilterByProps) => {
    const [value, setValue] = useState('');

    const getChecked = (key: string) => {
        return Boolean(data.selectedOptions.find((opt: FilterByOption) => opt.value === key));
    };

    const toggleChecked = (option: FilterByOption) => () => {
        const selectedOption = data.selectedOptions.find(
            (opt: FilterByOption) => opt.value === option.value
        );
        let newState: FilterData;

        if (selectedOption) {
            newState = {
                ...data,
                selectedOptions: data.selectedOptions.filter(
                    (opt: FilterByOption) => opt.value !== option.value
                ),
            };
        } else {
            newState = {
                ...data,
                selectedOptions: [...data.selectedOptions, option],
            };
        }

        onChange(newState);
    };

    const filtersCounter = data.selectedOptions.length;

    const clearFilter = (e: React.MouseEvent) => {
        onChange({
            ...data,
            selectedOptions: [],
            onBonusOnly: false,
        });
        e.preventDefault();
        return false;
    };

    const setOnBonusOnly = (onBonusOnly: boolean) => {
        const newState = { ...data, onBonusOnly };
        onChange(newState);
    };

    return (
        <div className={generalStyles.container}>
            <div className={generalStyles.header}>
                <div className={styles.filterTitle}>Filters • {filtersCounter}</div>
                <div className={generalStyles.headerFilterCmds}>
                    <span>
                        On bonus only{' '}
                        <Switch checked={data.onBonusOnly} onChange={setOnBonusOnly} />
                    </span>
                    <a
                        href="#"
                        onClick={clearFilter}
                        className={generalStyles.headerFilterCmdsClearFilter}
                    >
                        Clear Filter
                    </a>
                </div>
            </div>
            <div>
                <Accordion.Root type="single" value={value} onValueChange={(e) => setValue(e)}>
                    {groups.map((group) => (
                        <Accordion.Item key={group.key} value={group.key}>
                            <Accordion.Header className={styleHeader.accordionGroupHeader}>
                                <Accordion.Button className={styleHeader.accordionGroupButton}>
                                    <div>
                                        {group.title}
                                        <span style={{ float: 'right' }}>
                                            {value === group.key ? <ChevronUp /> : <ChevronDown />}
                                        </span>
                                    </div>
                                </Accordion.Button>
                                <Accordion.Panel>
                                    <ul className={stylesMenu.menuItems}>
                                        {group.options.map((subItem) => (
                                            <li key={subItem.value} className={stylesMenu.menuItem}>
                                                <Checkbox
                                                    id={subItem.value}
                                                    checked={getChecked(subItem.value)}
                                                    onChange={toggleChecked(subItem)}
                                                    text={subItem.label}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Accordion.Panel>
                            </Accordion.Header>
                        </Accordion.Item>
                    ))}
                </Accordion.Root>
            </div>
        </div>
    );
};
