import { APIResponse, SuccessfulResponse } from '../models';

type ResponseMapper<T, R, E> = (r: SuccessfulResponse<T>) => APIResponse<R, E>;

export const mapApiResponse =
    <T, R, E = null>(mapFn: ResponseMapper<T, R, E>) =>
    async (response: APIResponse<T, E>) => {
        if (response.error) {
            return response;
        }

        return mapFn(response);
    };
