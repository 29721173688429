import { APIResponse } from '../api/models';

const getTimeoutFromRetryAfterHeader = (response: Response): number | null => {
    const headerValue = response.headers.get('Retry-After');
    if (!headerValue) {
        return null;
    }
    if (/^[0-9]*$/.test(headerValue)) {
        const seconds = parseInt(headerValue, 10);
        return seconds * 1000;
    }

    const dateInMS = new Date(headerValue).getTime();
    if (!Number.isNaN(dateInMS)) {
        return dateInMS - Date.now();
    }

    return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withRetries = <T, E, F extends (...args: any[]) => ReturnType<F>>(apiFn: F) => {
    const apnFnWithRetries = async (...args: Parameters<F>): Promise<ReturnType<F>> => {
        const res = (await apiFn(...args)) as APIResponse<T, E>;
        const retryTimeout = res.response && getTimeoutFromRetryAfterHeader(res.response);
        if (retryTimeout) {
            await new Promise((resolve) => setTimeout(resolve, retryTimeout));
            return apnFnWithRetries(...args);
        }

        return res as ReturnType<F>;
    };
    return apnFnWithRetries;
};
