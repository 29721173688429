import React, { useCallback, useMemo, useState, useRef, Fragment } from 'react';

import { Modal, ModalFooter, ModalPrimaryBtn, ModalSecondaryBtn } from 'components/modal/Modal';
import { ImageUploader, ImageUploaderProps } from 'components/image-uploader/ImageUploader';
import { uploadVoidedCheckImage } from 'common/api/e-comm/payment-types';
import { useAlert } from 'modules/alerts';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';

interface UploadCheckImageProps {
    orgId: string;
    isOpen?: boolean;
    onClose: () => void;
    onSuccess: (file: File) => void;
}

interface UploadCheckImageState {
    file: File | null;
    error: string | null;
}

const initialState: UploadCheckImageState = {
    file: null,
    error: null,
};
const invalidFileErrorMessage =
    'Image must be one of the following file types: .jpeg, .bmp, .gif, .png, .tiff, or .pdf and smaller than 15mb.';

export const UploadCheckImageModal = (props: UploadCheckImageProps) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} title="Upload Voided Check Photo">
            <UploadCheckImage {...props} />
        </Modal>
    );
};

const UploadCheckImage = (props: UploadCheckImageProps) => {
    const { onSuccess, orgId = '' } = props;
    const [state, setState] = useState(initialState);
    const loader = useRef<LoadingOverlay>(null);

    const onDrop = useMemo(
        (): ImageUploaderProps['onDrop'] => (acceptedFiles, fileRejections) => {
            const [acceptedFile] = acceptedFiles;
            if (acceptedFile) {
                setState({ file: acceptedFile, error: null });
                return;
            }
            if (fileRejections.length) {
                setState((s) => ({ ...s, error: invalidFileErrorMessage }));
            }
        },
        []
    );

    const alert = useAlert();
    const onSubmit = useCallback(async () => {
        if (!state.file) {
            setState((s) => ({ ...s, error: 'Please select an image' }));
            return;
        }
        openLoader(loader.current);
        const res = await uploadVoidedCheckImage(orgId, state.file);
        await closeLoader(loader.current);
        if (res.error) {
            if (hasErrorWithStatus(res, StatusCode.PayloadTooLarge)) {
                const error = res.error.body || 'File is too large. Please upload a smaller image.';
                setState((s) => ({ ...s, error }));
                return;
            }
            alert({
                message:
                    'There was a problem uploading your photo. Please try again or use a different image. If you would like to speak to our Customer Support team, please call 800-727-4715 Option 4.',
            });
            return;
        }
        onSuccess(state.file);
    }, [state.file, alert, onSuccess, orgId]);

    return (
        <Fragment>
            <p className="mb-5">
                Please upload a voided check from your bank. Make sure the account and routing
                numbers are visible.
            </p>
            <ImageUploader
                file={state.file}
                accept="image/*, .pdf"
                multiple={false}
                onDrop={onDrop}
                error={state.error}
            />
            <ModalFooter>
                <ModalPrimaryBtn onClick={onSubmit}>Upload Image</ModalPrimaryBtn>
                <ModalSecondaryBtn onClick={props.onClose}>Cancel</ModalSecondaryBtn>
            </ModalFooter>
            <LoadingOverlay ref={loader} />
        </Fragment>
    );
};
