import React, { useLayoutEffect } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Btn } from 'components/btn/Btn';
import { createStyles } from 'utils/createStyle';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { useDispatch } from 'react-redux';
import { resetCreateAccountAsyncState } from 'common/features/store/duck/local/duck';

interface IProps {
    showLoadingScreen: Function;
}

export const OrgEnrollmentErrorTransition = ({ showLoadingScreen }: IProps) => {
    const classes = genClasses();

    useLayoutEffect(() => {
        document.location.hash = 'account-error';
    }, []);

    return (
        <FadeIn when={true}>
            <div data-testid="org-enrollment-v2-error-screen">
                <FontAwesomeIcon icon={faExclamationCircle} className={classes.icon} />
                <ContentfulArea showLoadingScreen={showLoadingScreen} />
            </div>
        </FadeIn>
    );
};

const genClasses = () => {
    return createStyles({
        icon: ['text-error mx-auto block mb-3', { fontSize: 38.75 }],
    });
};

const ContentfulArea = ({ showLoadingScreen }: IProps) => {
    const dispatch = useDispatch<any>();

    const goToPreviousStep = () => {
        showLoadingScreen(false);
        dispatch(resetCreateAccountAsyncState(null));
    };

    return (
        <div className="flex flex-col items-center">
            <p className="text-brand-dark text-4xl font-light text-center">Something went wrong</p>
            <div className="my-6 text-center">
                <p className="text-grey-1">
                    Unfortunately, there’s been an error. For assistance, please contact our
                    Customer Support team at <span className="font-semibold">1-800-727-4715</span>,
                    Option 4.
                </p>
            </div>
            <Btn isSecondary onClick={goToPreviousStep}>
                Go Back
            </Btn>
        </div>
    );
};
