import React from 'react';
import * as RuiCheckbox from '@radix-ui/react-checkbox';
import * as Label from '@radix-ui/react-label';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createStyle } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

export interface CheckboxProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    id?: string;
    text: string;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
}

const StyledCheckbox = createStyle('inline-block align-middle flex', {
    appearance: 'none',
    border: `2px solid ${colors.brand}`,
    padding: 0,
    width: 20,
    height: 20,
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
        outline: 'none',
    },
    '&:disabled': {
        borderColor: colors.lightGrey,
    },
});

interface CheckIconProps {
    'data-state': string;
}

const CheckIcon = (props: CheckIconProps) => {
    return (
        <FontAwesomeIcon
            color={props['data-state'] === 'checked' ? colors.white : colors.brandDark}
            icon={faCheck}
            size="xs"
        />
    );
};

export const Checkbox = ({
    checked,
    onChange,
    id,
    text,
    className,
    labelClassName,
    disabled = false,
}: CheckboxProps) => {
    return (
        <div className={`flex flex-row items-center ${className}`}>
            <RuiCheckbox.Root
                defaultChecked
                id={id}
                className={StyledCheckbox}
                style={{ background: checked ? colors.brand : '' }}
                checked={checked}
                onCheckedChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            >
                <RuiCheckbox.Indicator
                    as={CheckIcon as any}
                    className="w-5 h-5 border-2 text-brand-dark border-brand rounded-sm"
                ></RuiCheckbox.Indicator>
            </RuiCheckbox.Root>
            &nbsp;
            <Label.Root
                className={classNames(
                    'cursor-pointer align-middle',
                    labelClassName,
                    disabled ? 'text-grey-2' : ''
                )}
                htmlFor={id}
                onClick={() => onChange(checked)}
            >
                {text}
            </Label.Root>
        </div>
    );
};
