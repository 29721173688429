import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { SortByOption } from './SortBy';

// components
import { DropdownMenu } from 'components/dropdown-menu/DropdownMenu';
import { Radio, RadioOption } from 'components/radio/Radio';
import { createStyles } from 'utils/createStyle';

interface SortProps {
    sortOptions: SortByOption[];
    sortedBy: SortByOption;
    sortedByDirection: 'asc' | 'desc';
    setSortedBy: (data: SortByOption) => void;
    setSortedByDirection: (data: 'asc' | 'desc') => void;
}

const DropdownContext = React.createContext({
    sortedBy: { text: '', value: '' },
});

const styles = createStyles({
    sortTypeStyles: ['underline block text-brand font-bold text-left z-10', { width: 140 }],
    iconStyles: ['mx-2', { fontSize: 7 }],
});

const RadioItemsRenderer = ({
    items,
    onSelect,
}: {
    items: RadioOption[];
    onSelect: (itemClicked: RadioOption) => void;
    renderers: { [key: string]: React.ElementType };
}) => {
    const { sortedBy } = useContext(DropdownContext);
    return (
        <div className="p-5">
            <Radio options={items} onChange={onSelect} value={sortedBy.value} />
        </div>
    );
};

const renderers = {
    ItemsRenderer: RadioItemsRenderer,
};

export const SortByDropdownMenu = ({
    sortOptions,
    sortedBy = { text: '', value: '' },
    setSortedBy = () => null,
}: Partial<SortProps>) => {
    return (
        <div className="flex justify-end items-center z-10">
            Sort
            <FontAwesomeIcon className={styles.iconStyles} icon={faCircle} size="xs" />
            <DropdownContext.Provider value={{ sortedBy }}>
                <DropdownMenu<SortByOption>
                    renderers={renderers}
                    items={sortOptions || []}
                    onSelect={setSortedBy}
                >
                    <span className={`brands_sort_button ${styles.sortTypeStyles}`}>
                        {sortedBy?.text}
                    </span>
                </DropdownMenu>
            </DropdownContext.Provider>
        </div>
    );
};
