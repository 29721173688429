import React, { useCallback, useState, useEffect, Fragment, useMemo } from 'react';
import { ZXCVBNResult } from 'zxcvbn';
import debounce from 'lodash.debounce';

import { TextFieldSecuredProps } from './TextField';
import { FormikTextFieldSecured } from './FormikTextField';
import { FormikFieldProps } from './utils/createFormikField';

import { noop } from 'common/utils';
import { PasswordStrengthMeter } from 'components/password-strength-meter/PasswordStrengthMeter';
import { loadPasswordScorer, getPasswordScore } from 'modules/password-strength';

type FormikSetPasswordFieldProps<T> = TextFieldSecuredProps &
    FormikFieldProps<T> & {
        minAcceptableScore: number | null;
    };

export const FormikSetPasswordField = function <T>(props: FormikSetPasswordFieldProps<T>) {
    const { validate = noop, minAcceptableScore, ...rest } = props;
    const [scoreResult, setScoreResult] = useState<ZXCVBNResult | null>(null);

    useEffect(() => {
        loadPasswordScorer();
    }, []);
    const _validate = useCallback(
        async (value: string) => {
            const error = validate(value);
            const scorePromise = getPasswordScore(value);

            if (!value) {
                setScoreResult(null);
            } else {
                scorePromise.then(setScoreResult);
            }
            if (error) {
                return error;
            }
            if (minAcceptableScore) {
                const _scoreResult = await scorePromise;
                if (_scoreResult.score < minAcceptableScore) {
                    return 'Try a stronger password';
                }
            }
        },
        [validate, minAcceptableScore]
    );

    const debounceValidate = useMemo(() => {
        let resolvers: Array<(value: string | void) => void> = [];
        const resolveAll = (value: string | void) => {
            resolvers.forEach((resolve) => resolve(value));
            resolvers = [];
        };
        const d = debounce(
            async (value: string) => {
                resolveAll(await _validate(value));
            },
            500,
            { leading: true }
        );
        return (value: string) =>
            new Promise<string | void>((resolve) => {
                resolvers.push(resolve);
                d(value);
            });
    }, [_validate]);

    return (
        <Fragment>
            <FormikTextFieldSecured<T> {...rest} validate={debounceValidate} />
            <div className="mb-2" />
            <PasswordStrengthMeter score={scoreResult?.score ?? null} />
        </Fragment>
    );
};
