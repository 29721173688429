import { colors } from 'styles/settings';
import { createStyles } from 'utils/createStyle';

export const FieldStyles = createStyles({
    inputWrapper: 'relative',
    showHide: [
        'text-brand-dark cursor-pointer h-full pr-2',
        {
            outline: 'none',
            ':focus': {
                outline: 'none',
            },
        },
    ],
    label: 'text-grey-1 font-bold mb-1 block text-xs',
    field: [
        'rounded-sm w-full text-grey-1',
        {
            outline: 'none',
            appearance: 'none',
            padding: '6px 8px',
            fontSize: 18,
            borderWidth: 1,
            borderColor: colors.grey3,
            boxShadow: `inset 0 0 0 transparent, 0 0 0 0 ${colors.brandDark}`,
            transition: '100ms box-shadow ease-out, 100ms border-color ease-out',
            ':focus': {
                borderColor: colors.brandHighlight,
                boxShadow: `inset 0 0 5px 0 #ADC5DB, 0 0 0 1px ${colors.brandHighlight}`,
            },
            '&.error': {
                borderColor: colors.error,
                boxShadow: `inset 0 0 0 transparent, 0 0 0 1px ${colors.error}`,
            },
            '&.error:focus': {
                boxShadow: `inset 0 0 5px 0 rgba(214, 0, 0, 0.4), 0 0 0 1px ${colors.error}`,
            },
            '&.toggle-space': {
                paddingRight: '60px',
            },
            // Removes MS Edge's password input clear and reveal buttons.
            '::-ms-reveal': { display: 'none' },
            '::-ms-clear': { display: 'none' },
        },
    ],
});
