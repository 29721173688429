import { useSelector } from 'react-redux';
import { selectedOrganization } from 'common/features/store/duck/home/duck';

export const useIsScripware = () => {
    const organization = useSelector(selectedOrganization);

    if (organization) {
        return organization.organization.isScripWareOrg;
    }

    return false;
};
