import { useEffect, useRef } from 'react';

export function useOutsideClick<T extends HTMLElement>(
    /* ref: React.RefObject<HTMLElement>, */
    callback: Function
) {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        function handleClickOutside(this: Document, event: MouseEvent) {
            if (ref?.current && !ref?.current?.contains(event.target as Node)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);

    return ref;
}
