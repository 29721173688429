import { ProductType } from './Product';

export type CardType = ProductType.EGiftCard | ProductType.GiftCard;

export enum RestrictionType {
    PrintedCardRequired = 'PrintedCardRequired',
    PhysicalCardRequired = 'PhysicalCardRequired',
    PinRequired = 'PinRequired',
    Unknown = 'Unknown',
    HomeDepotDelayed = 'HomeDepotDelayed',
}

export enum BarcodeType {
    AztecCode = 'AztecCode',
    Code39 = 'Code39',
    Pdf417 = 'Pdf417',
    Code128 = 'Code128',
    Code93 = 'Code93',
    I2OF5 = 'I2OF5',
    CompactPdf417 = 'CompactPdf417',
    QRCode = 'QRCode',
    Code11 = 'Code11',
    Code16k = 'Code16k',
    Code32 = 'Code32',
}

export interface CardSettings {
    canCheckBalance: boolean;
    barcodeType: BarcodeType | null;
    restrictionType: RestrictionType | null;
    useRemoteCardStorage: boolean;
    cardNumber: {
        isBarcodeVisible: boolean;
        label: string | null;
        isVisible: boolean;
    } | null;
    pin: {
        isBarcodeVisible: boolean;
        label: string | null;
        isVisible: boolean;
    } | null;
    extraField: {
        label: string | null;
        isVisible: boolean;
    } | null;
}

export interface CardBase {
    loading?: boolean;
    id: string;
    brandId: number;
    name: string | null;
    cardNumber: string | null;
    pin: string | null;
    cardBalance: number | null;
    balanceUpdated: string | null;
    type: CardType;
    settings: CardSettings | null;
    extraFieldValue: string | null;
    formattedCardNumber: string | null;
    lastViewedDate: string | null;
    isThirdPartyViewerFlow: boolean;
    thirdPartyViewerUrl: string | null;
}

export interface EGiftCard extends CardBase {
    type: ProductType.EGiftCard;
    activationDate: string;
    viewerUrl?: string | null;
}

export interface GiftCard extends CardBase {
    type: ProductType.GiftCard;
    registeredDate: string;
}

export type Card = GiftCard | EGiftCard;

export type CardEligibility = {
    isEligible: boolean;
    activationNumber: string | null;
    cardEligibilityType: 'InstantActivate' | 'Reload';
};

export type CardThirdPartyViewerUrl = {
    url: string | null;
};

export const emptyCardSettings: CardSettings = {
    barcodeType: null,
    restrictionType: null,
    useRemoteCardStorage: false,
    canCheckBalance: false,
    cardNumber: null,
    pin: null,
    extraField: null,
};

export const emptyCard: GiftCard = {
    id: '',
    brandId: 0,
    name: null,
    cardNumber: null,
    pin: null,
    cardBalance: 0,
    balanceUpdated: null,
    type: ProductType.GiftCard,
    extraFieldValue: null,
    registeredDate: '',
    lastViewedDate: null,
    formattedCardNumber: null,
    settings: emptyCardSettings,
    isThirdPartyViewerFlow: false,
    thirdPartyViewerUrl: null,
};

export const emptyCards: { [K in CardType]: Extract<Card, { type: K }> } = {
    [ProductType.GiftCard]: emptyCard,
    [ProductType.EGiftCard]: {
        ...emptyCard,
        type: ProductType.EGiftCard,
        activationDate: '',
    },
};

export interface CardBalance {
    cardBalance: number | null;
    balanceUpdated: string | null;
}

export const isCardArchivable = (card: Card): boolean => {
    return card.type === ProductType.EGiftCard;
};

export const isCardReloadable = (card: Card): card is GiftCard =>
    card.type === ProductType.GiftCard;

export const getCreationDate = (card: Card) => {
    return new Date(
        card.type === ProductType.EGiftCard ? card.activationDate : card.registeredDate
    );
};

export const getLastBalanceAddedDate = (card: Card) => {
    if (card.balanceUpdated) {
        return new Date(card.balanceUpdated);
    }

    return getCreationDate(card);
};
