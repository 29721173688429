import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import { StepperStyles } from './StepperStyles';

interface StepProps {
    title: string;
    icon: React.ElementType;
    isLast: boolean;
    routes: string[];
    exact: boolean;
    stepId: number;
    setCurrent: Function;
    steps: Step[];
}

export interface Step {
    title: string;
    icon: React.ElementType;
    routes: string[];
    exact: boolean;
}

const Step = (props: StepProps) => {
    const isMatched = useRouteMatch({
        path: props.routes,
        sensitive: false,
        exact: props.exact,
    });

    const { stepId, title, setCurrent } = props;
    const stepRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (isMatched) {
            setCurrent(stepId);
        }
    }, [stepId, isMatched, setCurrent]);

    useEffect(() => document.querySelector('input')?.focus(), [stepId]);

    return (
        <>
            <li
                tabIndex={isMatched ? 0 : -1}
                aria-current={isMatched ? 'step' : undefined}
                ref={stepRef}
                aria-label={`${title}. Step ${stepId} of ${props.steps.length}`}
                className={classNames(
                    StepperStyles.stepWrapper,
                    !isMatched && StepperStyles.hiddenMobile
                )}
            >
                <div
                    className={classNames(StepperStyles.iconWrapper, {
                        [StepperStyles.iconSelected]: isMatched,
                    })}
                >
                    <props.icon
                        className={classNames('duration-200 transition-colors text-brand-dark', {
                            'sm:text-brand-accent': isMatched,
                        })}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="flex flex-col">
                    <div
                        className={StepperStyles.stepMobileHeader}
                    >{`Step ${stepId} of ${props.steps.length}`}</div>
                    <div
                        className={classNames(StepperStyles.stepTitle, {
                            'sm:font-semibold': isMatched,
                        })}
                    >
                        {title}
                    </div>
                    <div className={StepperStyles.stepNext}>
                        {' '}
                        {!props.isLast ? `Next: ${props.steps[stepId].title}` : 'Next: Done!'}
                    </div>
                </div>
            </li>
            {!props.isLast && <li aria-hidden className={StepperStyles.separator} />}
        </>
    );
};

export const Stepper = ({ steps }: { steps: Step[] }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);

    return (
        <div role="group" aria-label="progress">
            <span className="sr-only">{`${steps[currentStep - 1].title}, step ${currentStep} of ${
                steps.length
            }`}</span>
            <ol
                className={StepperStyles.wrapper}
                data-current-step={currentStep}
                data-steps={steps.length}
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        stepId={index + 1}
                        {...step}
                        isLast={steps.length === index + 1}
                        setCurrent={setCurrentStep}
                        steps={steps}
                    />
                ))}
            </ol>
        </div>
    );
};
