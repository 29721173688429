import React, { memo } from 'react';

import { times, isDefined } from 'common/utils';
import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { hexToRGB } from 'common/utils/colors';
import { PasswordScore, POSSIBLE_SCORES } from 'modules/password-strength';

const strengthDescriptor: Record<PasswordScore, string> = {
    0: 'Weak',
    1: 'Weak',
    2: 'Average',
    3: 'Average',
    4: 'Strong',
};

const barColor: Record<PasswordScore, string> = {
    0: colors.error,
    1: colors.error,
    2: `rgba(${hexToRGB(colors.success)}, 0.5)`,
    3: `rgba(${hexToRGB(colors.success)}, 0.75)`,
    4: colors.success,
};

const styles = createStyles({
    meter: ['flex', { marginRight: -5 }],
    bar: ['w-9 bg-grey-7 transition transition-colors', { height: 5, marginRight: 5 }],
});

interface PasswordStrengthMeterProps {
    score: PasswordScore | null;
}

export const PasswordStrengthMeter = memo(({ score }: PasswordStrengthMeterProps) => {
    return (
        <div>
            <div className={styles.meter}>
                {times(POSSIBLE_SCORES.length - 1, (i) => (
                    <div
                        key={i}
                        className={styles.bar}
                        style={{
                            backgroundColor:
                                isDefined(score) && (i === 0 || i < score)
                                    ? barColor[score || 0]
                                    : undefined,
                        }}
                    />
                ))}
            </div>
            <div className="text-grey-1 text-xs mt-1" aria-live="polite">
                {isDefined(score) ? (
                    `Password strength: ${strengthDescriptor[score]}`
                ) : (
                    <span aria-hidden="true">Password strength</span>
                )}
            </div>
        </div>
    );
});
