import { createStorageService, StorageStrategy } from './createStorageService';

const LocalStorageStrategy: StorageStrategy = {
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
    clear: (key) => localStorage.removeItem(key),
};

const TemporaryStorageStrategy: StorageStrategy = {
    get: (key) => sessionStorage.getItem(key),
    set: (key, value) => sessionStorage.setItem(key, value),
    clear: (key) => sessionStorage.removeItem(key),
};

export const temporaryStorage = createStorageService(TemporaryStorageStrategy);
export const persistentStorage = createStorageService(LocalStorageStrategy);
