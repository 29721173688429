import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHomeDetails,
    selectOrgSwitchAsyncState,
    selectOrgSwitchState,
    updateDefaultOrgThunk,
} from 'common/features/store/duck/home/duck';
import { useRootSelector } from 'common/features/featuresReducer';
import { SuccessMessage } from './SuccessMessage';
import { ErrorMessage } from './ErrorMessage';
import { OrgChangeLoader } from './OrgChangeLoader';
import { CartClearConfirmation } from './CartClearConfirmation';

export const useFullScreenOverlay = () => {
    const dispatch = useDispatch<any>();
    const { newOrgId } = useSelector(selectOrgSwitchState);
    const [showCartConfirmation, setShowCartConfirmation] = useState<boolean>(false);
    const [updateComplete, setUpdateComplete] = useState<boolean>(false);
    const { cart } = useRootSelector((s) => s.store.cart);
    const { loading, lastUpdate, error } = useSelector(selectOrgSwitchAsyncState);
    const currentOrgId = useSelector(selectHomeDetails)?.defaultOrganization?.id || '';

    /**
     * This effect is checking to see if the user has any items in their cart.
     * If they do, we display the cart clear confirmation.
     * If they don't, we dispatch the action to change their org.
     *
     * If items are in the cart and a user confirms to remove them, we will fall into this effect
     * once the cart is done being emptied.
     */
    useEffect(() => {
        if (!cart?.productCount || cart.productCount === 0) {
            dispatch(updateDefaultOrgThunk(newOrgId, currentOrgId));
            return;
        }

        setShowCartConfirmation(true);
    }, [cart, newOrgId, dispatch, currentOrgId]);

    /** This effect is checking to see if the org switch action
     * has been dispatched (loading will be true in that case).
     * This will hide the cart confirmation screen after a user clicks Proceed.
     */
    useEffect(() => {
        if (loading) {
            setShowCartConfirmation(false);
        }
    }, [loading]);

    /**
     * This effect checks to see if loading is false (we are not waiting on the org
     * switch action to complete) and if lastUpdate is populated (to indicate that the
     * org switch action happened and loading === false because it has completed. If lastUpdate
     * were === to zero we know we haven't started the request yet).
     *
     * When these conditions are met, we know that the org switch is complete and we can redirect
     * to bff/login to get a new JWT/auth session.
     */
    useEffect(() => {
        if (!loading && lastUpdate > 0 && !error) {
            setUpdateComplete(true);
        }
    }, [loading, lastUpdate, error]);

    return {
        showCartConfirmation,
        loading,
        error,
        updateComplete,
    };
};

export const FullScreenOverlay = () => {
    const { showCartConfirmation, loading, error, updateComplete } = useFullScreenOverlay();

    return (
        <>
            {showCartConfirmation && !loading && <CartClearConfirmation />}
            {loading && <OrgChangeLoader />}
            {error && <ErrorMessage />}
            {updateComplete && <SuccessMessage />}
        </>
    );
};
