import React, { useContext, useEffect } from 'react';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { ContentfulSubheading } from '../../components/ContentfulSubheading';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'styles/settings';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { selectOneTimeCode } from 'common/features/store/duck/local/duck';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { Routes } from 'routes';
import { updateAccount, UserAuthState } from 'common/features/store/duck/account/duck';

interface IProps {
    showLoadingScreen: Function;
}

export const OrgEnrollmentSuccessTransition = () => {
    return (
        <FadeIn when={true}>
            <div data-testid="org-enrollment-v2-success-screen">
                <SuccessCheckMark />
                <ContentfulArea />
            </div>
        </FadeIn>
    );
};

export const useTransitionToNextStep = () => {
    const otac = useSelector(selectOneTimeCode);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (otac) {
            dispatch(updateAccount({ userAuthState: UserAuthState.INITIAL }));
            setTimeout(() => sendToLoginPage(Routes.EnrollV2SecureAccount, otac), 1000);
        }
    }, [otac, dispatch]);
};

const ContentfulArea = () => {
    useTransitionToNextStep();
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const title = (contentfulEntry as OrganizationEnrollmentV2)?.loaderSuccessTitle ?? '';

    return (
        <div className="flex flex-col items-center">
            <p className="text-brand-dark text-4xl font-light">{title}</p>
            <div className="text-center">
                <ContentfulSubheading attribute="loaderSuccessSubtitle" />
            </div>
        </div>
    );
};

const SuccessCheckMark = () => (
    <svg
        className="block m-auto mb-3"
        width="38.75"
        height="38.75"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 0.375C5.57967 0.375 0.375 5.57967 0.375 12C0.375 18.4203 5.57967 23.625 12 23.625C18.4203 23.625 23.625 18.4203 23.625 12C23.625 5.57967 18.4203 0.375 12 0.375ZM12 22.125C6.43762 22.125 1.875 17.6224 1.875 12C1.875 6.43767 6.37758 1.875 12 1.875C17.5624 1.875 22.125 6.37758 22.125 12C22.125 17.5623 17.6224 22.125 12 22.125ZM18.6389 9.2362L10.1789 17.6283C9.95836 17.8471 9.6022 17.8457 9.38344 17.6251L5.35791 13.5671C5.13914 13.3465 5.14055 12.9904 5.36109 12.7716L5.76047 12.3755C5.98102 12.1567 6.33717 12.1581 6.55594 12.3787L9.78919 15.638L17.4505 8.03817C17.671 7.81941 18.0272 7.82081 18.246 8.04136L18.6421 8.44073C18.8609 8.66128 18.8595 9.01744 18.6389 9.2362Z"
            fill={colors.success}
        />
    </svg>
);
