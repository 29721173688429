import React, { useEffect, useState, useRef } from 'react';
import { OrgEnrollmentLoadingTransition } from './OrgEnrollmentLoadingTransition';
import { OrgEnrollmentSuccessTransition } from './OrgEnrollmentSuccessTransition';
import { OrgEnrollmentErrorTransition } from './OrgEnrollmentErrorTransition';
import { APICallState } from '../types';
import { useDispatch } from 'react-redux';
import { showAccountCreationLoader } from 'common/features/store/duck/ui/org-enrollment-v2';
import { useRootSelector } from 'common/features/featuresReducer';
import { selectRegisterAsyncState, selectOneTimeCode } from 'common/features/store/duck/local/duck';
import { AsyncActionState } from 'common/modules/async-actions/core';
import { useScrollToTop } from 'hooks/useScrollToTop';

interface TransitionsMap {
    loading: React.ElementType;
    error: React.ElementType;
    success: React.ElementType;
}

const Transitions: TransitionsMap = {
    loading: OrgEnrollmentLoadingTransition,
    error: OrgEnrollmentErrorTransition,
    success: OrgEnrollmentSuccessTransition,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getComponentToRender = (requestState: AsyncActionState<any[], unknown>, otac?: string) => {
    const { loading, error, lastUpdate } = requestState;
    if (loading) {
        return APICallState.LOADING;
    } else if (error) {
        return APICallState.ERROR;
    } else if (lastUpdate && otac) {
        // NOTE: We check `otac` as well in the case where we get a `200`
        // response from /register endpoint but the oneTimeCode(otac) is _missing_.
        // Avoids having the user sit on success screen.
        return APICallState.SUCCESS;
    } else {
        // NOTE: we default to error in case anything else occurs
        // it'd mean we're at an invalid data state.
        return APICallState.ERROR;
    }
};

export const LoadingStep = () => {
    useScrollToTop();

    const requestState = useRootSelector(selectRegisterAsyncState);
    const otac = useRootSelector(selectOneTimeCode);
    const [element, setElement] = useState<APICallState>(getComponentToRender(requestState, otac));
    const timedout = useRef<boolean>(false);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        setTimeout(() => {
            timedout.current = true;
            setElement((el) =>
                el === APICallState.SUCCESS ? APICallState.SUCCESS : APICallState.ERROR
            );
        }, 20000);
    }, []);

    useEffect(() => {
        if (!timedout.current) {
            const componentToRender = getComponentToRender(requestState, otac);
            setElement(componentToRender);
        }
    }, [requestState, otac]);

    const setShow = () => {
        dispatch(showAccountCreationLoader(false));
    };

    const Component = Transitions[element];

    return (
        <div className="bg-white w-auto max-w-xl">
            <div className="p-6">
                <Component showLoadingScreen={setShow} />
            </div>
        </div>
    );
};
