import { apiFetch } from 'common/api/utils';
import { LOGIN_URL } from 'common/api/config';
import { RefreshTokenResponse } from 'common/api/users/models/RefreshTokenResponse';
import { tokenManager } from 'common/api/tokenManager';

export const refreshToken = async () => {
    const tokens = await tokenManager.getTokens();
    const response = await apiFetch<RefreshTokenResponse, RefreshTokenResponse>(
        `${LOGIN_URL}/refresh`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tokens.user),
        }
    );

    if (response.error) {
        return response;
    }

    await tokenManager.setTokens({
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        inactivityTimeoutMinutes: response.data.inactivityTimeoutMinutes,
    });

    return response;
};
