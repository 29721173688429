import React, { useContext, useMemo } from 'react';
import { EnrollmentProgressBar } from './EnrollmentProgressBar';
import { LeadCaptureInfo } from './steps/LeadCaptureInfo';
import { AccountInfo } from './steps/AccountInfo';
import { EnrollOrganizationDetails } from './steps/EnrollOrganizationDetails';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';

export interface IStepsV2 {
    value: number;
    title: string;
    next: string;
    content: React.FC | JSX.Element | String;
}
interface EnrollStepWrapperV2Props {
    children: React.ReactNode;
}

export const useEnrollmentV2Steps = () => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const orgEnrollmentV2 = contentfulEntry as OrganizationEnrollmentV2;

    // TODO: We need to get the default values for these titles
    return useMemo(
        () => [
            {
                value: 0,
                title: orgEnrollmentV2?.step1Title || '',
                next: orgEnrollmentV2?.step2Title || '',
                content: <LeadCaptureInfo />,
            },
            {
                value: 1,
                title: orgEnrollmentV2?.step2Title || '',
                next: orgEnrollmentV2?.step3Title || '',
                content: <EnrollOrganizationDetails />,
            },
            {
                value: 2,
                title: orgEnrollmentV2?.step3Title || '',
                next: orgEnrollmentV2?.twoFactorSetupTitle || '',
                content: <AccountInfo />,
            },
            {
                value: 3,
                title: orgEnrollmentV2?.twoFactorSetupTitle || '',
                next: orgEnrollmentV2?.programSelectionTitle || '',
                content: null,
            },
            {
                value: 4,
                title: orgEnrollmentV2?.programSelectionTitle || '',
                next: 'Enrollment Submitted',
                content: null,
            },
            {
                value: 5,
                title: 'Enrollment Submitted',
                next: 'Status: Pending Approval',
                content: null,
            },
        ],
        [orgEnrollmentV2]
    );
};

export const EnrollStepWrapperV2 = (props: EnrollStepWrapperV2Props) => {
    return (
        <div className="w-full">
            <EnrollmentProgressBar />

            <div className="md:my-20 w-full flex items-center justify-center">{props.children}</div>
        </div>
    );
};
