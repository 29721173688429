import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useHistory } from 'react-router-dom';
import groupBy from 'lodash.groupby';

import { StepWrapperProps, StepWrapper } from './StepWrapper';
import {
    orgTypeValidator,
    orgFundraisingPurposeValidator,
    orgNumberOfFamiliesValidator,
} from './validators';

import { Organization } from 'common/api/crm/models/Organization';
import { APIResponse } from 'common/api/models';
import { OrganizationType } from 'common/api/crm/models/OrganizationType';
import { AsyncActionState } from 'common/modules/async-actions/core';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Btn } from 'components/btn/Btn';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { FormikSelectField } from 'components/fields/FormikSelectField';
import { FormikTextField } from 'components/fields/FormikTextField';
import { FormikTextareaField } from 'components/fields/FormikTextareaField';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { updateOrganization, createOrganization } from 'common/api/crm/organization/service';
import { Routes } from 'routes';
import { fromLocaleString } from 'common/utils/strings';
import { useAlert } from 'modules/alerts';
import { OrganizationDetail } from 'common/api/crm/models/OrganizationDetail';
import { withRetries } from 'common/utils/withRetries';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { useValidation } from 'modules/form-validation/useValidation';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { OrganizationWebsiteField } from 'features/coordinator/components/OrganizationWebsiteField';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';

const numberOfFamiliesMask = createNumberMask({ prefix: '' });

interface FormValues extends Omit<Organization, 'numberOfFamilies'> {
    // `string` is used here because we mask the number to be more human-readable, e.g. "10,000".
    // It's converted back to a number on submit.
    numberOfFamilies: string;
}

interface Organization2StepProps extends StepWrapperProps {
    organization: Organization;
    loadOrgTypes: () => void;
    loadOrgTypesState: AsyncActionState;
    orgTypes: OrganizationType[] | null;
    onSubmit: (org: Organization) => void;
    orgCreationPromise?: Promise<APIResponse<OrganizationDetail>>;
}

export const Organization2Step = (props: Organization2StepProps) => {
    const { loadOrgTypes, onSubmit } = props;
    const { name } = props.organization;
    const headingData = useMemo(() => ({ orgName: name }), [name]);
    const validateAndSubmit = useValidation<FormValues>();
    const homeDetails = useHomeDetails();
    const alert = useAlert();
    const history = useHistory();
    const loader = useRef<LoadingOverlay>(null);
    const orgCreationPromise = useRef(props.orgCreationPromise);
    const { retrieveBlackBox } = useBlackBoxValues();
    const firstName = homeDetails?.profile.firstName;
    const subheadingData = useMemo(() => ({ firstName }), [firstName]);
    const _onSubmit = useCallback(
        async (values: FormValues) => {
            openLoader(loader.current);
            let updateOrgRes: APIResponse<OrganizationDetail> | null = null;
            let orgCreationRes = await orgCreationPromise.current;
            const org: Organization = {
                ...values,
                id: values.id || orgCreationRes?.data?.id,
                numberOfFamilies: fromLocaleString(values.numberOfFamilies),
            };
            if (orgCreationRes?.error) {
                const blackBoxValues = await retrieveBlackBox();
                orgCreationPromise.current = createOrganization({ ...org, ...blackBoxValues });
                orgCreationRes = await orgCreationPromise.current;
                await closeLoader(loader.current);
                if (orgCreationRes.error) {
                    alert({
                        message:
                            'Unable to create your organization at this time. Please try again.',
                    });
                    return;
                }
            } else {
                updateOrgRes = await withRetries(updateOrganization)(org);
                await closeLoader(loader.current);
                if (updateOrgRes.error) {
                    alert({
                        message: 'Unable to save your organization at this time. Please try again.',
                    });
                    return;
                }
            }

            const orgId = updateOrgRes?.data.id || orgCreationRes?.data.id;
            onSubmit({ ...org, id: orgId });
            withRetries(updateEnrollmentProgress)({
                state: ProgressState.OrgUpdated,
                organizationId: orgId,
            });
            history.replace(Routes.StartProgramEarnings);
        },
        [retrieveBlackBox, alert, onSubmit, history]
    );

    useEffect(() => {
        loadOrgTypes();
    }, [loadOrgTypes]);
    const filteredSortedOrgTypes = useMemo(() => {
        const types = props.orgTypes
            ?.filter((x) => x.isEnabled)
            .sort((a, b) => {
                return a.description.localeCompare(b.description);
            });
        const moveToEnd = ['Miscellaneous', 'Other'];
        const { start = [], end = [] } = groupBy(types, (x) =>
            moveToEnd.includes(x.description) ? 'end' : 'start'
        );
        return [...start, ...end];
    }, [props.orgTypes]);

    const initialValues = useMemo(
        () => ({
            ...props.organization,
            numberOfFamilies: String(props.organization.numberOfFamilies ?? ''),
        }),
        [props.organization]
    );

    if (props.loadOrgTypesState.loading) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <StepWrapper {...props} headingData={headingData} subheadingData={subheadingData}>
            <Formik initialValues={initialValues} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-sm mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <FieldSpacer />
                        <FormikSelectField<Organization>
                            name="organizationTypeId"
                            label={`What type of organization is ${name}?`}
                            validate={orgTypeValidator}
                        >
                            <option value="" />
                            {filteredSortedOrgTypes?.map((orgType) => (
                                <option key={orgType.id} value={orgType.id}>
                                    {orgType.description}
                                </option>
                            ))}
                        </FormikSelectField>
                        <FieldSpacer />
                        <MaskedInput
                            mask={numberOfFamiliesMask}
                            render={(ref, _props) => (
                                <FormikTextField<Organization>
                                    {..._props}
                                    inputRef={ref}
                                    name="numberOfFamilies"
                                    label="How many people are eligible to fundraise in your organization?"
                                    inputMode="numeric"
                                    validate={orgNumberOfFamiliesValidator}
                                />
                            )}
                        />
                        <FieldSpacer />
                        <FormikTextareaField<Organization>
                            name="fundraisingPurpose"
                            label="What are you raising money for?"
                            validate={orgFundraisingPurposeValidator}
                            rows={3}
                        />
                        <FieldSpacer />
                        <span>
                            <OrganizationWebsiteField
                                label={
                                    <>
                                        <span className="block">
                                            What is your organization's website?
                                        </span>
                                        <span className="text-grey-1 text-xs block">
                                            If you don't have a website, your organization's
                                            Facebook page is a good option.
                                        </span>
                                    </>
                                }
                            />
                        </span>
                        <FieldSpacer />

                        <div className="text-center">
                            <Btn type="submit">Next: Earnings</Btn>
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
