import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import MaskedInput from 'react-text-mask';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { EnrollmentContext } from '../../context';

import { SecuritySubStep } from './SecuritySubStep';

import { h5 } from 'styles/typography';
import { Btn } from 'components/btn/Btn';
import { FormikTextField } from 'components/fields/FormikTextField';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import {
    orgEnrollEmailValidator,
    firstNameValidator,
    lastNameValidator,
    twoFactorPhoneNumberValidator,
} from 'common/modules/form-validation/validators';
import { TermsAndConditions } from 'components/terms-and-conditions/TermsAndConditions';
import {
    RegistrationRequestWithoutOrg,
    emptyRegistrationRequestWithoutOrg,
    EnrollSourceApplicationIds,
} from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { validateEmail } from 'common/api/users/email/validate';
import { openLoader, closeLoader, LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { useContentfulGlobalContent } from 'common/api/contentful/global';
import { DISABLE_CAPTCHA_VALIDATION } from 'config';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { registerWithoutOrg } from 'common/api/users/service';
import { doLogOut } from 'common/features/store/duck';
import { logIn } from 'common/features/store/duck/account/duck';
import { tokenManager } from 'common/api/tokenManager';
import { withRetries } from 'common/utils/withRetries';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { addOrgEnrollmentReferralInfo } from 'common/api/crm/family/service';
import { getCookieAsObject } from 'utils/cookies';
import { useAlert } from 'modules/alerts';
import { ProgressIndicator } from 'features/coordinator/components/ProgressIndicator';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { noop } from 'common/utils';

const RegistrationRequestField = typedFormikField<RegistrationRequestWithoutOrg>()(FormikTextField);

const SubmitButton = ({ step }: { step: number }) => {
    const btnText = step === 0 ? 'Set up Account Security' : 'Set up Recovery Phone';
    return (
        <div className="text-center">
            <Btn type="submit">{btnText}</Btn>
        </div>
    );
};

export interface CreateAccountProps {
    loader: React.RefObject<LoadingOverlay>;
    toNextStep: () => void;
    step: number;
}

export const CreateAccount = (props: CreateAccountProps) => {
    const { loader, step, toNextStep } = props;
    const [shouldRenderNextStep, setShouldRenderNextStep] = useState(false);
    const { setUserInformation, goToNextStep } = useContext(EnrollmentContext);

    // 2nd step validation dependencies
    const refCaptcha = useRef<ReCAPTCHA>(null);
    const { retrieveBlackBox } = useBlackBoxValues();
    const dispatch = useDispatch<any>();
    const alert = useAlert();

    const stepOneValidateEmail = useCallback(
        async (
            values: RegistrationRequestWithoutOrg,
            form: FormikHelpers<RegistrationRequestWithoutOrg>
        ) => {
            const res = await validateEmail(values.email);

            if (hasErrorWithStatus(res, StatusCode.Conflict)) {
                alert({
                    message: 'Looks like this email is already associated with an account',
                    title: 'Would you like to log in?',
                    buttons: [
                        {
                            text: 'Yes!',
                            onClick: () => sendToLoginPage(),
                        },
                        {
                            text: 'No',
                            onClick: noop,
                        },
                    ],
                });
                return false;
            }

            // reset errors and any fields touched to avoid having the security questions
            // render errors initially.
            form.setErrors({});
            form.setTouched({});

            setShouldRenderNextStep(true);
            return true;
        },
        [alert]
    );

    const stepTwoCreateAccountWithoutOrg = useCallback(
        async (values: RegistrationRequestWithoutOrg) => {
            const captchaResponse = DISABLE_CAPTCHA_VALIDATION
                ? 'no_captcha_validation'
                : await refCaptcha.current?.executeAsync();

            openLoader(loader.current);

            const blackboxValues = await retrieveBlackBox();
            const { question1, answer1, question2, answer2, ...registrationData } = values;
            const result = await registerWithoutOrg({
                ...registrationData,
                ...blackboxValues,
                username: registrationData.email,
                securityQuestionAnswers: { [question1]: answer1, [question2]: answer2 },
                captchaResponse,
                enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
            });

            await closeLoader(loader.current);

            if (result.error) {
                refCaptcha.current?.reset();
                alert({
                    message: 'Unable to save security questions at this time. Please try again.',
                });
                return false;
            }

            // In case there's an authenticated => log them out and log-in with the new
            // registration.
            dispatch(batchActions([doLogOut(), logIn(result.data.userId)]));
            await tokenManager.setTokens({
                inactivityTimeoutMinutes: result.data.inactivityTimeoutMinutes,
                token: result.data.token,
                refreshToken: result.data.refreshToken,
            });

            // These calls use withRetries because the newly created user's ID
            // might not've propagated to all the services yet.
            withRetries(updateEnrollmentProgress)({ state: ProgressState.UserCreated, version: 2 });
            withRetries(addOrgEnrollmentReferralInfo)(result.data.userId, {
                trackingCookie: getCookieAsObject(document.cookie),
            });

            return true;
        },
        [dispatch, retrieveBlackBox, alert, loader]
    );

    const _onSubmit = useCallback(
        async (
            values: RegistrationRequestWithoutOrg,
            form: FormikHelpers<RegistrationRequestWithoutOrg>
        ) => {
            let continueToNextStep = false;

            if (step === 0) {
                // step 1: validate email address
                continueToNextStep = await stepOneValidateEmail(values, form);
            } else if (step === 1) {
                // step 2: create the account without an org.
                continueToNextStep = await stepTwoCreateAccountWithoutOrg(values);
                setUserInformation(values);
                if (continueToNextStep) {
                    goToNextStep();
                }

                return;
            }

            if (continueToNextStep) {
                toNextStep();
            }
        },
        [
            toNextStep,
            step,
            stepTwoCreateAccountWithoutOrg,
            stepOneValidateEmail,
            goToNextStep,
            setUserInformation,
        ]
    );

    const globalContent = useContentfulGlobalContent();

    // focus first form field
    useEffect(() => document.querySelector('input')?.focus(), []);

    return (
        <div>
            <Formik initialValues={emptyRegistrationRequestWithoutOrg} onSubmit={_onSubmit}>
                {() => (
                    <Form>
                        <h1 className={`${h5} mt-8`}>Tell us a little about yourself</h1>
                        <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-5 md:gap-y-5 my-4">
                            <RegistrationRequestField
                                name="firstName"
                                label="First Name"
                                validate={firstNameValidator}
                            />
                            <RegistrationRequestField
                                name="lastName"
                                label="Last Name"
                                validate={lastNameValidator}
                            />
                            <MaskedInput
                                mask={MASK_PHONE_US}
                                guide={false}
                                render={(ref, _props) => (
                                    <RegistrationRequestField
                                        {..._props}
                                        inputRef={ref}
                                        type="tel"
                                        name="phoneNumber"
                                        label="Phone Number"
                                        validate={twoFactorPhoneNumberValidator}
                                    />
                                )}
                            />
                            <RegistrationRequestField
                                type="email"
                                name="email"
                                label="Email"
                                validate={orgEnrollEmailValidator}
                            />
                        </div>
                        <SecuritySubStep
                            shouldRender={shouldRenderNextStep}
                            globalContent={globalContent}
                            refCaptcha={refCaptcha}
                        />
                        <SubmitButton step={step} />
                    </Form>
                )}
            </Formik>
            <div className="w-48 m-auto mt-8">
                <ProgressIndicator totalSteps={4} currentStep={step + 1} />
            </div>
            <TermsAndConditions {...globalContent} />
        </div>
    );
};
