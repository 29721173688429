import React, { useContext, useMemo } from 'react';
import { BaseStep } from '../BaseStep';
import { Formik, Form } from 'formik';
import { FamilyEnrollmentContext } from 'features/family-enrollment/context';
import { Document } from '@contentful/rich-text-types';
import {
    verificationCodeError,
    verificationCodeValidator,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { confirmPhone } from 'common/api/users/twofactor/phone';
import { Btn } from 'components/btn/Btn';
import { useAlert } from 'modules/alerts';
import { useBaseStyles } from 'features/family-enrollment/steps/utils';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { FormikCodeField } from 'components/fields/FormikCodeField';
import { Routes } from 'routes';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { updateAccount } from 'common/features/store/duck/account/duck';
import { useDispatch } from 'react-redux';

interface VerifyPhoneFormValues {
    code: string;
}

const VerifyPhoneForm = () => {
    const { loader, phoneId, goToPreviousStep } = useContext(FamilyEnrollmentContext);
    const initialValues: VerifyPhoneFormValues = {
        code: '',
    };

    const alert = useAlert();
    const baseStyles = useBaseStyles();
    const { retrieveBlackBox } = useBlackBoxValues();
    const dispatch = useDispatch<any>();

    const onSubmit = async (values: VerifyPhoneFormValues) => {
        openLoader(loader?.current as LoadingOverlay);

        const blackBoxValue = (await retrieveBlackBox()).blackBoxValue || '';
        const { code } = values;
        const response = await confirmPhone(phoneId, code, blackBoxValue);

        closeLoader(loader?.current as LoadingOverlay);

        if (response.error) {
            alert({ message: verificationCodeError });
            return;
        }
        dispatch(updateAccount({ hasSetupTwoFactor: true }));
        sendToLoginPage(Routes.Shop);
    };

    return (
        <div className={baseStyles.formContainer}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Form>
                    <FormikCodeField
                        id="code"
                        name="code"
                        label={'6-Digit Verification Code'}
                        validate={verificationCodeValidator}
                        onTryRequestingNewOne={goToPreviousStep}
                    />
                    <FieldSpacer />
                    <div className="text-center">
                        <Btn type="submit">Finish</Btn>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export const VerifyPhone = () => {
    const { stepHeaders, phoneNumber } = useContext(FamilyEnrollmentContext);

    const phoneNumberLastFourDigits = useMemo(() => (phoneNumber || '').slice(-4), [phoneNumber]);

    return (
        <BaseStep
            contentClassName="text-md sm:text-lg font-thin"
            heading={stepHeaders?.twoFactorPhoneVerifyHeading as string}
            subheading={stepHeaders?.twoFactorPhoneVerifyBody as Document}
            subheadingData={{ phoneNumber: `(***) ***-${phoneNumberLastFourDigits}` }}
        >
            <VerifyPhoneForm />
        </BaseStep>
    );
};
