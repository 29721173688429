import { CartResponse } from './models/CartItems';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { SECURED_E_COMM_URL } from 'common/api/config';
import { BulkOrderCartItem, CartItem } from 'common/api/e-comm/models/CartItem';

export const addItemToCart = (item: CartItem) =>
    withTokensIfNeeded<CartResponse>(`${SECURED_E_COMM_URL}/Cart/lineItem`, {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
            'Content-Type': 'application/json',
        },
    });

type ErrorCodes = {
    error?: string;
    errorMessage?: string;
};

export const addItemsToCart = (items: BulkOrderCartItem) =>
    withTokensIfNeeded<CartResponse & ErrorCodes>(`${SECURED_E_COMM_URL}/Cart/lineItems`, {
        method: 'POST',
        body: JSON.stringify(items),
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const removeItemFromCart = (lineItemId: string) =>
    withTokensIfNeeded<CartResponse | null>(`${SECURED_E_COMM_URL}/Cart/lineItem/${lineItemId}`, {
        method: 'DELETE',
    });

export const removeUnavailableCartItems = () =>
    withTokensIfNeeded<CartResponse | null>(`${SECURED_E_COMM_URL}/Cart/lineItem/unavailable`, {
        method: 'DELETE',
    });
