import React, { useState, useEffect, useContext } from 'react';
import { getApp } from 'firebase/app';

import { BottomContainer } from '../../../components/BottomContainer';
import { EnrollmentContext } from '../../context';

import createdAccount from 'media/images/createdAccount.png';
import { RemoteConfigWeb } from 'config/RemoteConfigWeb';

export const AccountCreated = () => {
    const [transitionTime, setTransitionTime] = useState<number>(3000);
    const { goToNextStep } = useContext(EnrollmentContext);

    useEffect(() => {
        const remoteConfigCall = async () => {
            if (getApp()) {
                await RemoteConfigWeb.refresh();
                const transition = RemoteConfigWeb.getRemoteValue(
                    'enrollment_transition_time'
                ).asNumber();
                setTransitionTime(transition);
            }
        };
        remoteConfigCall();

        const timer = setTimeout(() => {
            goToNextStep();
        }, transitionTime);

        return () => clearTimeout(timer);
    }, [transitionTime, goToNextStep]);

    return (
        <>
            <div className="mt-8 sm:mt-10 px-6 sm:px-10 max-w-2xl ml-auto mr-auto items-center flex flex-col max-h-full mb-56 overflow-auto">
                <h2 className="text-2xl sm:text-4xl text-center leading-tight font-normal text-brand-dark">
                    Your personal account is created. You can use it to log in and manage your
                    program.
                </h2>
                <img
                    src={createdAccount}
                    alt="Personal Account Created"
                    className="my-8 w-40 sm:w-auto"
                />
                <p className="text-lg sm:text-xl text-center font-semibold text-grey-2">
                    Let's learn a little about your organization.
                </p>
            </div>
            <BottomContainer />
        </>
    );
};
