import { apiFetch } from '../../utils';
import { E_COMM_BRANDS_URL } from '../../config';
import { ECommBrand } from '../models/ECommBrand';
import { Product } from '../models/Product';
import { APIResponse } from '../../models';
import { createAPIError } from '../../utils/createAPIError';

import { batch } from 'common/utils/batch';

export const getBrand = async (brandId: number): Promise<APIResponse<ECommBrand>> => {
    const result = await apiFetch<ECommBrand[]>(`${E_COMM_BRANDS_URL}?Ids=${brandId}`);
    if (result.data) {
        const [firstResult] = result.data;
        if (firstResult) {
            return { ...result, data: firstResult };
        }
        return { error: createAPIError(null, new Error('Not found')) };
    }
    return result;
};

export const getBrands = async (brandIds: number[]): Promise<APIResponse<ECommBrand[]>> => {
    if (!brandIds.length) {
        return { data: [] };
    }
    const ids = brandIds.map((id) => `Ids=${id}`).join('&');
    return apiFetch<ECommBrand[]>(`${E_COMM_BRANDS_URL}?${ids}`);
};

/**
 * NOTE: It's possible to exceed the maximum string length
 * when using many hundred brandIds, so, when dealing with that
 * many brands, it's necessary to batch them.
 *
 * @param {Array<number>} brandIds
 */
export const getBrandsBatched = async (brandIds: number[]) => {
    const batchedBrandIds = batch(brandIds, 100);
    const response = await Promise.all(batchedBrandIds.map(getBrands));
    return response;
};

export const getProductsByBrand = (brandId: number) =>
    apiFetch<Product[]>(`${E_COMM_BRANDS_URL}/${brandId}/products`);

export const getRelatedBrands = (brandId: number) =>
    apiFetch<ECommBrand[]>(`${E_COMM_BRANDS_URL}/${brandId}/related`);
