import React, { useState, useMemo, useContext, useEffect } from 'react';
import { createStyles } from 'utils/createStyle';
import { Routes } from 'routes';
import { h2, link } from 'styles/typography';
import { Btn } from 'components/btn/Btn';
import { Modal, ModalSize } from 'components/modal/Modal';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { EnrollmentLanding } from 'common/api/contentful/models/EnrollmentLanding';
import { useFromContentful } from 'common/api/contentful/utils';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { withMessage, combineValidationFns, required } from 'common/modules/form-validation';
import { ShopScreen } from 'features/shop-base/ShopScreen';
import ResponsiveContainer, {
    ContainerType,
} from 'components/responsive-containers/ResponsiveContainer';
import { Formik, FormikHelpers, FormikProps, Form } from 'formik';
import { FormikTextField } from 'components/fields/FormikTextField';
import { getOrganizationByEnrollmentCode } from 'common/api/crm/organization/enrollment';
import { FamilyEnrollmentContext } from './context';
import {
    OrganizationDetail,
    emptyOrganizationDetail,
} from 'common/api/crm/models/OrganizationDetail';
import { useRootSelector } from 'rootStore';
import { selectLoginState } from 'common/features/store/duck/account/duck';
import { RegistrationRequest } from 'common/api/auth/models/RegistrationRequest';
import { LinkBtn } from 'components/link-btn';
import { useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';
import { Value } from 'firebase/remote-config';

const invalidEnrollmentCodeMessage = 'Invalid Enrollment Code';
const enrollmentCodeValidator = withMessage(invalidEnrollmentCodeMessage)(
    combineValidationFns(required, (value: string) => Boolean(value.match(/^[0-9A-Za-z-]+$/g)))
);

export interface EnrollmentForm {
    enrollmentCode: string;
}

const styles = createStyles({
    orContainer: 'flex flex-row md:flex-col mx-16 h-full items-center',
    orContent: 'text-brand-dark font-semibold text-2xl px-2 sm:px-1',
    orSeparator: 'h-0.5 sm:h-36 w-full sm:w-0.5 bg-bonus-pink opacity-50',
    selectedIndicator: 'bg-bonus-pink h-0.5 mt-1 -mb-2 pb-1.5 w-8 rounded',
    selectedOption:
        'border border-grey-3 py-4 w-full flex flex-col items-center text-md text-brand-dark',
    buttonOption: `${link} border border-grey-3 pt-4 pb-5 w-full`,
    optionWrapper:
        'flex flex-col justify-between text-sm leading-5 py-4 mt-4 sm:mt-0 w-full max-w-full sm:max-w-xs',
    optionHeading: 'text-brand-dark text-xl',
    optionBody: 'text-grey-1 text-sm mt-3',
    ctaButton: 'w-full md:w-auto max-w-xs md:px-8 md:py-1 mb-4 sm:mt-10 self-center',
});

export interface FamilyLandingParams {
    enrollCode?: '';
}

export enum MobileSelectedOption {
    JoinProgram,
    StartProgram,
}

const useEnrollmentCodeFromUrl = () => {
    const { enrollCode } = useParams<FamilyLandingParams>();

    const params = new URLSearchParams(useLocation().search);
    return params.get('enrollCode') || enrollCode;
};

export const FamilyLanding = () => {
    const history = useHistory();
    const isUserLoggedIn = useRootSelector(selectLoginState);
    const [selectedOption, setSelectedOption] = useState<MobileSelectedOption>(
        MobileSelectedOption.JoinProgram
    );
    const enrollCode = useEnrollmentCodeFromUrl();

    useEffect(() => {
        isUserLoggedIn && history.push(Routes.ShopAccountSettingsPersonalInformationTab);
    }, [history, isUserLoggedIn]);

    const { setOrganizationTo, updateUserAccountInfo } = useContext(FamilyEnrollmentContext);

    const enrollmentLanding = useFromContentful<EnrollmentLanding>('enrollmentLanding');

    const [showWhatEnrollCode, setShowWhatEnrollCode] = useState(false);

    const texts = useMemo(() => enrollmentLanding?.content?.items[0].fields, [enrollmentLanding]);

    const submitJoinProgram = async (
        { enrollmentCode }: EnrollmentForm,
        formikHelpers: FormikHelpers<EnrollmentForm>
    ) => {
        const response = await getOrganizationByEnrollmentCode(enrollmentCode);
        if (response.error) {
            formikHelpers.setFieldError('enrollmentCode', invalidEnrollmentCodeMessage);
            return;
        }

        localStorage.setItem('RR_enrollmentCode', enrollmentCode);

        updateUserAccountInfo((currentState: RegistrationRequest) => ({
            ...currentState,
            organizationId: response.data.id,
        }));

        setOrganizationTo(response.data);

        history.push(Routes.EnrollFamilyCreateAccount);
    };

    const MobileOptions = () => {
        if (enrollCode) {
            return null;
        }
        return (
            <div className="w-full flex">
                {selectedOption === MobileSelectedOption.JoinProgram ? (
                    <>
                        <div className={styles.selectedOption}>
                            Join a program
                            <div className={styles.selectedIndicator} />
                        </div>
                        <button
                            type="button"
                            onClick={() => setSelectedOption(MobileSelectedOption.StartProgram)}
                            className={`${styles.buttonOption} border-l-0`}
                        >
                            Start a program
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            type="button"
                            onClick={() => setSelectedOption(MobileSelectedOption.JoinProgram)}
                            className={styles.buttonOption}
                        >
                            Join a program
                        </button>
                        <div className={`${styles.selectedOption} border-l-0`}>
                            Start a program
                            <div className={styles.selectedIndicator} />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const OrForDesktop = () => {
        if (enrollCode) {
            return null;
        }
        return (
            <div className={styles.orContainer}>
                <span className={styles.orSeparator} />
                <span className={styles.orContent}>or</span>
                <span className={styles.orSeparator} />
            </div>
        );
    };

    const MainContentWrapper = ({ children }: React.PropsWithChildren) => (
        <div className="w-full flex justify-center bg-brand-light px-6 sm:px-0">
            <div className="flex flex-col sm:flex-row max-w-full md:max-w-3xl py-4 sm:py-10">
                {children}
            </div>
        </div>
    );

    const JoinProgramContent = () => {
        useEffect(() => {
            enrollCode && localStorage.setItem('RR_enrollmentCode', enrollCode);
        }, []);

        return (
            <div className={styles.optionWrapper}>
                <h5 className={styles.optionHeading}>{texts?.landingPageFamilyHeading}</h5>
                <div className={styles.optionBody}>
                    {texts?.landingPageFamilyBody &&
                        documentToReactComponents(texts?.landingPageFamilyBody)}
                </div>
                <Formik
                    initialValues={{
                        enrollmentCode: enrollCode || '',
                    }}
                    onSubmit={submitJoinProgram}
                >
                    {(formikProps: FormikProps<EnrollmentForm>) => (
                        <Form>
                            <div className="px-0 mt-4">
                                <FormikTextField
                                    name="enrollmentCode"
                                    className="w-full"
                                    validate={enrollmentCodeValidator}
                                    label={
                                        <div className="flex justify-between">
                                            <span className="text-grey-1 font-semibold ">
                                                Enrollment Code
                                            </span>
                                            <LinkBtn onClick={() => setShowWhatEnrollCode(true)}>
                                                What's an enrollment code?
                                            </LinkBtn>
                                        </div>
                                    }
                                />
                            </div>
                            <div className="text-center mt-4">
                                <Btn
                                    type="button"
                                    onClick={formikProps.submitForm}
                                    className={styles.ctaButton}
                                >
                                    {texts?.landingPageFamilyCtaButton}
                                </Btn>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    };

    return !isUserLoggedIn ? (
        <ShopScreen>
            <Modal
                isOpen={showWhatEnrollCode}
                title="What's an enrollment code?"
                onClose={() => setShowWhatEnrollCode(false)}
                titleStyles="w-80"
                size={[ModalSize.CONTENT_WIDTH, ModalSize.CONTENT_WIDTH]}
            >
                <p className="text-grey-1 w-full sm:w-96 pr-4 py-5">
                    A unique code assigned to each organization that you need to join a program. If
                    you don’t have one, contact your organization.
                </p>
            </Modal>
            <div className="flex items-center flex-col mt-10 ">
                <div className="flex items-center flex-col max-w-3xl px-6 sm:px-0">
                    <h2 className={h2}>{texts?.landingPageMainHeader}</h2>
                    <div className="text-grey-1 mt-6 mb-8">
                        {texts?.landingPageMainBody &&
                            documentToReactComponents(texts?.landingPageMainBody)}
                    </div>
                </div>
                <ResponsiveContainer For={ContainerType.mobile}>
                    <MobileOptions />
                    <MainContentWrapper>
                        {selectedOption === MobileSelectedOption.JoinProgram ? (
                            <JoinProgramContent />
                        ) : null}

                        {selectedOption === MobileSelectedOption.StartProgram ? (
                            <StartNewProgramIfNeeded texts={texts} />
                        ) : null}
                    </MainContentWrapper>
                </ResponsiveContainer>

                <ResponsiveContainer For={ContainerType.desktop}>
                    <MainContentWrapper>
                        <JoinProgramContent />
                        <OrForDesktop />
                        <StartNewProgramIfNeeded texts={texts} />
                    </MainContentWrapper>
                </ResponsiveContainer>
            </div>
        </ShopScreen>
    ) : null;
};

export const StartNewProgramIfNeeded = ({ texts }: { texts: EnrollmentLanding | undefined }) => {
    const enrollmentVersion = useFirebaseConfigValues('org_enrollment_version', 1, (v: Value) =>
        v.asNumber()
    );
    const history = useHistory();
    const enrollCode = useEnrollmentCodeFromUrl();

    const enrollmentV2Enabled = useFirebaseConfigValues(
        'enrollment_v2_enabled',
        false,
        (v: Value) => v.asBoolean()
    );

    const options: Options = {
        renderText: (text) => {
            return text
                .split('\\n')
                .flatMap((currentText, i) => [i > 0 && <br key={i} />, currentText]);
        },
    };

    const onClickStartProgram = () => {
        if (enrollmentV2Enabled && enrollmentVersion === 2) {
            history.push(Routes.EnrollV2);
            return;
        }

        history.push(Routes.StartProgram);
    };

    if (enrollCode) {
        return null;
    }

    return (
        <div className={styles.optionWrapper}>
            <div>
                <h5 className={styles.optionHeading}>{texts?.landingPageOrgHeading}</h5>
                <div className={styles.optionBody}>
                    {texts?.landingPageOrgBody &&
                        documentToReactComponents(texts?.landingPageOrgBody, options)}
                </div>
            </div>
            <div className="text-center mt-4">
                <Btn onClick={onClickStartProgram} className={styles.ctaButton}>
                    {texts?.landingPageOrgCtaButton}
                </Btn>
            </div>
        </div>
    );
};
