import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';

import { faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons';

import { ShopScreen } from 'features/shop-base/ShopScreen';
import { ContentfulContext } from '../search/components/ContentfulView';
import { useSearchGridStyles } from '../search/components/hooks';
import { CardContext } from '../search/components/contexts';
import { createStyles } from 'utils/createStyle';
import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { LoadMoreSection } from 'features/search/components/TopPicksSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LandingPageHeader } from './components/LandingPageHeader';

const styles = createStyles({
    container: ['flex w-full'],
    headerContainer: [
        'flex w-full',
        {
            flexDirection: 'column',
        },
    ],
    headerLink: [
        'flex, mx-8 lg:mx-16 2xl:mx-28',
        {
            marginTop: '20px',
        },
    ],
    searchGrid: ['mb-12 mx-4 lg:mx-16 2xl:mx-28'],
    footerLink: [
        'flex justify-center text-brand',
        {
            marginBottom: '56px',
            marginTop: '30px',
        },
    ],
    linkText: [
        'text-brand ',
        {
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: 600,
            textDecorationLine: 'underline',
        },
    ],
    arrowIcon: [
        {
            marginRight: '6px',
        },
    ],
    scrollToTop: [
        'cursor-pointer flex flex-col items-center justify-evenly bg-grey-accent text-brand font-semibold text-center',
        {
            width: '42px',
            height: '60px',
            borderRadius: '5px 0px 0px 5px',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 6px -1px rgb(0 0 0 / 10%)',
            position: 'fixed',
            bottom: '10%',
            fontSize: 12,
            right: 0,
        },
    ],
});

const scrollToTop = () =>
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });

export interface LandingPageProps {
    returnRoute: Routes;
    landingPageType: keyof typeof defaultConfigKeys;
    renderBonusSwitch?: boolean;
    renderers?: LandingPageRenderers;
    fixedFilter?: string;
    isFilterVisible?: boolean;
    showEmptyResultsView?: boolean; // Deprecated
}

export interface LandingPageRenderers {
    header?: React.ElementType<LandingPageProps>;
    footer?: React.ElementType<LandingPageProps>;
}

const contentfulContext = {
    data: {},
    isLoading: false,
    scope: '',
    filterList: [],
};

export const LandingPage = ({ ...props }: React.PropsWithChildren<LandingPageProps>) => {
    const {
        children,
        renderers: renderers_,
        isFilterVisible = true,
        showEmptyResultsView = true,
    } = props;
    const [, width] = useSearchGridStyles<'150', '180'>('150', '180');
    const renderers = {
        header: LandingPageHeader,
        footer: LandingPageFooter,
        ...renderers_,
    };

    return (
        <ShopScreen>
            <ContentfulContext.Provider value={contentfulContext}>
                <renderers.header
                    {...props}
                    isFilterVisible={isFilterVisible}
                    showEmptyResultsView={showEmptyResultsView}
                />
                <section className={styles.searchGrid}>
                    <CardContext.Provider value={{ width }}>{children}</CardContext.Provider>
                </section>
                <div onClick={scrollToTop} className={styles.scrollToTop}>
                    <FontAwesomeIcon size="2x" icon={faArrowCircleUp} /> Top
                </div>
                <LoadMoreSection />
                <renderers.footer {...props} />
            </ContentfulContext.Provider>
        </ShopScreen>
    );
};

export default LandingPage;

const LandingPageFooter = ({ returnRoute }: LandingPageProps) => (
    <div className={`${styles.footerLink} ${styles.linkText}`}>
        <Link to={returnRoute}>Back to Shop All Brands</Link>
    </div>
);
