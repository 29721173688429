import React, { Fragment, useCallback, useMemo, useEffect, useState } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';

import { StepWrapperProps, StepWrapper } from './StepWrapper';
import { orgNameValidator, orgAddressLine1Validator } from './validators';

import { flattenObject } from 'common/utils/flattenObject';
import { emptyOrganization, Organization } from 'common/api/crm/models/Organization';
import { Btn } from 'components/btn/Btn';
import { FormikTextField } from 'components/fields/FormikTextField';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import {
    cityValidator,
    addressLine2Validator,
    stateValidator,
    zipCodeValidator,
    phoneNumberValidator,
} from 'common/modules/form-validation/validators';
import { FormikSelectField } from 'components/fields/FormikSelectField';
import { stateOptions } from 'common/modules/form-validation/utils/stateOptions';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { Routes } from 'routes';
import { createOrganization } from 'common/api/crm/organization/service';
import { APIResponse } from 'common/api/models';
import { OrganizationDetail } from 'common/api/crm/models/OrganizationDetail';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { useValidation } from 'modules/form-validation/useValidation';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import { useRootSelector } from 'rootStore';
import { LoadingScreen } from 'components/loading-screen/LoadingScreen';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { sendToLoginPage } from 'features/auth/components/withAuth';

export interface OrganizationStepProps extends StepWrapperProps {
    firstName: string;
    loadDataForNextScreen: () => void;
    onSubmit: (
        values: Organization,
        orgCreationPromise: Promise<APIResponse<OrganizationDetail>>
    ) => void;
}

const initialValues = emptyOrganization;

export const OrganizationStep = (props: OrganizationStepProps) => {
    const { onSubmit, loadDataForNextScreen } = props;
    const validateAndSubmit = useValidation<Organization>((errors) =>
        Object.keys(flattenObject(errors))
    );
    const [submitting, setSubmittingTo] = useState<boolean>(false);
    const { retrieveBlackBox } = useBlackBoxValues();
    const _onSubmit = useCallback(
        async (values: Organization) => {
            setSubmittingTo(true);
            const blackBoxValues = await retrieveBlackBox();
            const orgCreationPromise = createOrganization({ ...values, ...blackBoxValues });
            onSubmit(values, orgCreationPromise);
            await orgCreationPromise
                .then((res) => {
                    return withRetries(updateEnrollmentProgress)({
                        state: ProgressState.OrgCreated,
                        organizationId: res.data?.id,
                    });
                })
                .then(() => {
                    sendToLoginPage(Routes.StartProgramContinue);
                });
        },
        [retrieveBlackBox, onSubmit]
    );

    useEffect(() => {
        loadDataForNextScreen();
    }, [loadDataForNextScreen]);

    const homeDetails = useHomeDetails();
    const firstName = props.firstName || homeDetails?.profile.firstName;
    const subheadingData = useMemo(() => ({ firstName }), [firstName]);

    const homeDetailsAsyncState = useRootSelector((s) => s.store.home.homeDetailsAsyncState);
    if (!firstName && (homeDetailsAsyncState.loading || !homeDetailsAsyncState.lastUpdate)) {
        return <LoadingScreen />;
    }

    return (
        <StepWrapper {...props} subheadingData={subheadingData}>
            <Formik initialValues={initialValues} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-sm mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <FieldSpacer />
                        <FormikTextField
                            label="Organization Name"
                            name="name"
                            validate={orgNameValidator}
                        />
                        <FieldSpacer />
                        <div className="sm:flex">
                            <div className="flex-1">
                                <FormikTextField
                                    label={
                                        <Fragment>
                                            <span className="block">Organization Address</span>
                                            <span className="text-grey-1 text-xs block">
                                                Use your physical address rather than a PO Box
                                            </span>
                                        </Fragment>
                                    }
                                    name="address.address1"
                                    validate={orgAddressLine1Validator}
                                />
                                <FieldSpacer />
                            </div>
                            <div className="hidden sm:block w-4" />
                            <div className="flex-0 w-1/2">
                                <FormikTextField
                                    label={
                                        <Fragment>
                                            {/* This &nbsp; is so the fields stay aligned
                                            whether or not validation errors are present */}
                                            <span className="text-grey-1 text-xs none sm:block">
                                                &nbsp;
                                            </span>
                                            <span aria-label="Suite/Apartment/Attention">
                                                Ste/Apt/Attn
                                            </span>
                                        </Fragment>
                                    }
                                    name="address.address2"
                                    validate={addressLine2Validator}
                                />
                                <FieldSpacer />
                            </div>
                        </div>
                        <FormikTextField
                            label="City"
                            name="address.city"
                            validate={cityValidator}
                        />
                        <FieldSpacer />
                        <div className="flex">
                            <div className="flex-1">
                                <FormikSelectField
                                    label="State"
                                    name="address.state"
                                    validate={stateValidator}
                                >
                                    <option value="" />
                                    {stateOptions.map((o) => (
                                        <option key={o.value}>{o.value}</option>
                                    ))}
                                </FormikSelectField>
                                <FieldSpacer />
                            </div>
                            <div className="w-4" />
                            <div className="flex-1">
                                <FormikTextField
                                    label="Zip"
                                    name="address.zipCode"
                                    validate={zipCodeValidator}
                                />
                                <FieldSpacer />
                            </div>
                        </div>
                        <MaskedInput
                            mask={MASK_PHONE_US}
                            guide={false}
                            render={(ref, _props) => (
                                <FormikTextField
                                    {..._props}
                                    inputRef={ref}
                                    type="tel"
                                    name="phoneNumber"
                                    label={
                                        <Fragment>
                                            <span className="block">Organization Phone Number</span>
                                            <span className="text-grey-1 text-xs block">
                                                We use this in case we need to follow up about your
                                                enrollment.
                                            </span>
                                        </Fragment>
                                    }
                                    validate={phoneNumberValidator}
                                />
                            )}
                        />
                        <FieldSpacer />

                        <div className="text-center">
                            <Btn disabled={submitting} type="submit" className="w-44">
                                Next
                            </Btn>
                        </div>
                    </form>
                )}
            </Formik>
        </StepWrapper>
    );
};
