import React, { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { getUrlToLoginPage, sendToLogoutPage } from './withAuth';
import { useSelector } from 'react-redux';
import {
    getSessionInfo,
    selectUserAuthState,
    UserAuthState,
} from 'common/features/store/duck/account/duck';
import { SessionExpiredModal } from './SessionExpiredModal';
import { useBrowserTabFocused } from 'hooks/useBrowserTabFocused';
import { selectHomeDetailsProfileEmail } from 'common/features/store/duck/home/duck';
import { useLocation } from 'react-router';
import { Routes } from 'routes';
import { RunNewCoordinatorDashboardWelcomeMessageLogic } from 'utils';

// the amount of seconds we want to wait between polling requests.
const SESSION_EXPIRED_CHECK_INTERVAL = new Number(
    process.env.REACT_APP_SESSION_EXPIRED_CHECK_INTERVAL || 5 * 60
) as number;

const GO_TO_SHOP = 'rr_go_to_shop';
const BROADCAST_CHANNEL_IDENTIFIER = 'RaiseRight';

export const useBroadcastChannel = () => {
    const broadcastChannel = useRef(new BroadcastChannel(BROADCAST_CHANNEL_IDENTIFIER));

    useEffect(() => {
        broadcastChannel.current.onmessage = (ev) => {
            if (ev.data === GO_TO_SHOP) {
                goToShop();
            }
        };
    }, []);

    return broadcastChannel.current;
};

// Generates a unique id to identify the browser tab
export const useBrowserTabId = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const browserTabId = (window as any)['RaiseRight_TabId'] as number;

    if (!browserTabId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['RaiseRight_TabId'] = Math.trunc(Math.random() * 1000000);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)['RaiseRight_TabId'];
};

const ignoredRoutes = [Routes.StartProgram, Routes.Enroll, Routes.EnrollV2, Routes.EnrollFamily];

const goToShop = () => {
    // hard redirects to shop page (refreshes the entire page)
    window.location.pathname = Routes.Shop;
};

export const LoginWhenSessionExpires = ({ children }: { children: ReactNode }) => {
    const browserTabId = useBrowserTabId();
    const userAuthState = useSelector(selectUserAuthState);
    const redirectFlag = useRef(false);
    const [sessionExpiredIsOpen, setSessionExpiredIsOpen] = useState(false);
    const broadcastChannel = useBroadcastChannel();
    const browserTabFocused = useBrowserTabFocused();

    const location = useLocation();

    const ignoreCurrentRoute = useMemo(
        () =>
            ignoredRoutes.some((route) =>
                location.pathname.toLowerCase().startsWith(route.toLowerCase())
            ),
        [location.pathname]
    );

    const onSignInClicked = async () => {
        const loginUrl = getUrlToLoginPage(`/close-window?${browserTabId}`);
        window.open(loginUrl, '_blank')!;
    };

    const onSignOutClicked = () => {
        sendToLogoutPage();
    };

    const email = useSelector(selectHomeDetailsProfileEmail);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fn = async (e: MessageEvent<any>) => {
            const { message, payload } = e.data;
            const { tabId } = payload || {};

            if (message === 'login-success' && tabId == browserTabId) {
                const isTheSameUser = payload.email === email;

                broadcastChannel.postMessage({
                    message: 'login-success-close-window',
                });

                if (!isTheSameUser) {
                    broadcastChannel.postMessage(GO_TO_SHOP);
                    goToShop();
                }

                // closes the session expired dialog
                setSessionExpiredIsOpen(false);
            }
        };

        broadcastChannel.addEventListener('message', fn);

        return () => broadcastChannel.removeEventListener('message', fn);
    }, [email, broadcastChannel, browserTabId]);

    useEffect(() => {
        if (
            !browserTabFocused ||
            userAuthState !== UserAuthState.AUTHENTICATED ||
            ignoreCurrentRoute
        ) {
            return;
        }

        redirectFlag.current = false;

        let timeoutHandle: NodeJS.Timeout;
        let canceled = false;

        const checkSessionState = async () => {
            const { hasSessionExpired, userId } = await getSessionInfo();

            // NOTE: This hook might get canceled while the above async call
            // is waiting, so we need to check here for the canceled flag.
            if (canceled) {
                return;
            }

            if (hasSessionExpired && !document.hidden && !redirectFlag.current) {
                redirectFlag.current = true;
                setSessionExpiredIsOpen(true);

                // NOTE: FEC-1221 & FEC-1174
                // special case we need to run some logic for new-coordinator dashboard when session expires
                RunNewCoordinatorDashboardWelcomeMessageLogic(userId);
            }

            if (!hasSessionExpired) {
                redirectFlag.current = false;
                setSessionExpiredIsOpen(false);
            }

            if (!canceled) {
                timeoutHandle = setTimeout(
                    checkSessionState,
                    SESSION_EXPIRED_CHECK_INTERVAL * 1000
                );
            }
        };

        checkSessionState();

        return () => {
            canceled = true;
            clearTimeout(timeoutHandle);
            setSessionExpiredIsOpen(false);
        };
    }, [userAuthState, browserTabFocused, ignoreCurrentRoute]);

    return (
        <Fragment>
            <SessionExpiredModal
                isOpen={sessionExpiredIsOpen}
                setIsOpen={setSessionExpiredIsOpen}
                onSignInClicked={onSignInClicked}
                onSignOutClicked={onSignOutClicked}
            />
            {children}
        </Fragment>
    );
};
