import { BreadcrumbLink } from '../components/Breadcrumb';
import { CartInfoState, OrderSteps } from '../CartInfoState';

import { CartInfoDetails } from './useCartDetails';
import { PaymentInfoDescription } from './useCartPayment';

import { PaymentSubType, PaymentType } from 'common/api/e-comm/payment-types';
import { getPath, Routes } from 'routes';
import { CartLineItem } from 'common/api/e-comm/models/CartLineItem';
import { ProductType } from 'common/api/e-comm/models/Product';
import { EGiftCard, GiftCard } from 'common/api/e-comm/models/Card';

export const getBreadcrumbPath = (
    state: CartInfoState,
    stateDetails: CartInfoDetails,
    enableRules: {
        [key in OrderSteps]: (
            cartInfoState: CartInfoState,
            cartInfoDetails: CartInfoDetails
        ) => boolean;
    }
) => {
    const { shipToHome } = state;

    const stepsCompleted = Object.fromEntries(
        (Object.keys(enableRules) as OrderSteps[]).map((k) => [
            k,
            enableRules[k](state, stateDetails),
        ])
    ) as { [key in OrderSteps]: boolean };

    const breadcrumbPath: (BreadcrumbLink | null)[] = [
        {
            text: 'Cart',
            url: Routes.ShopOrderSummary,
            state,
            disabled: stepsCompleted && !stepsCompleted.summary,
        },
        {
            text: 'Payment',
            url: getPath(Routes.ShopOrderPayment, {
                shipToHome: shipToHome ? 'ship-to-home' : 'ship-to-coordinator',
            }),
            state,
            disabled: stepsCompleted && !stepsCompleted.payment,
        },
        {
            text: 'Shipping',
            url: Routes.ShopOrderShipping,
            state,
            invisible: !stateDetails.shipToHomeIfPossible,
            disabled: stepsCompleted && !stepsCompleted.shipping,
        },
        {
            text: 'Review Order',
            url: Routes.ShopOrderReview,
            state,
            disabled: stepsCompleted && !stepsCompleted.review,
        },
    ];

    return breadcrumbPath.filter((link) => link !== null) as BreadcrumbLink[];
};

export const getPaymentDisplayName = (paymentType?: PaymentType) => {
    switch (paymentType) {
        case PaymentType.Check:
            return 'Check';
        case PaymentType.BankAccount:
        case PaymentType.CreditCard:
            return 'Convenience Fee';
        default:
            return '';
    }
};

export const getPaymentInfo = (paymentAccount?: PaymentInfoDescription) => {
    if (!paymentAccount) {
        return '-';
    }

    if (paymentAccount.paymentType === PaymentType.BankAccount) {
        return `Bank Account (${paymentAccount.description})`;
    }

    if (paymentAccount.paymentSubType === PaymentSubType.Credit) {
        return `Credit Card ${paymentAccount.description ? `(${paymentAccount.description})` : ''}`;
    }

    if (paymentAccount.paymentSubType === PaymentSubType.Debit) {
        return `Debit Card ${paymentAccount.description ? `(${paymentAccount.description})` : ''}`;
    }

    if (paymentAccount.paymentType === PaymentType.Check) {
        return 'Check';
    }

    return '-';
};

export const getItemsByDeliveryType = (items: CartLineItem[], shipToHome: boolean) => {
    const shipToHomeItems = items.filter(
        (item) =>
            item.product.type === ProductType.GiftCard &&
            shipToHome &&
            item.product.isShipToHomeEligible
    );
    const shipToCoordinator = items.filter(
        (item) =>
            item.product.type === ProductType.GiftCard &&
            (!shipToHome || !item.product.isShipToHomeEligible)
    );
    const electronicDeliveryItems = items.filter(
        (li) => li.product.type === ProductType.EGiftCard || li.product.type === ProductType.Reload
    );

    return { shipToHomeItems, shipToCoordinator, electronicDeliveryItems };
};

export const getProductName = (item: CartLineItem) => {
    return item.product.isLocalProduct ? item.product.name : item.brand?.name;
};

export const getWalletCardDisplayName = (
    item: CartLineItem,
    userWalletCards: (GiftCard | EGiftCard)[]
) => {
    if (!item?.selectedOptions[0]) {
        return '';
    }
    return userWalletCards.find((card) => card.id === item.selectedOptions[0].value)?.name || '';
};
