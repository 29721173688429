import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIn } from 'formik';
import { Entry } from 'contentful';

import { APIResponse } from 'common/api/models';
import { Promotion } from 'common/api/store/models/Promotion';
import { contentfulApi } from 'common/api/utils/contentful';
import useQueryParams from 'hooks/useQueryParams';
import { RemoteConfigWeb } from 'config/RemoteConfigWeb';

export interface ContentfulViewProps {
    data: unknown;
    isLoading: boolean;
    scope: string;
    filterList?: string[];
}

export const ContentfulContext = React.createContext<ContentfulViewProps>({
    data: {},
    isLoading: true,
    scope: '',
});

export interface ContentfulData {
    title: string;
    subTitle: string;
    brandList?: string;
}

// Helper Function: walks down the path of the contentful data-structures
const basePath = ['data', 'fields'];
const getContentValue = (data: APIResponse<Entry<Promotion>>) =>
    getIn(data, [...basePath, 'content', 'content', '0', 'content', '0', 'value']);
const getTitle = (data: APIResponse<Entry<Promotion>>) => getIn(data, [...basePath, 'title']);
const getSubTitle = getContentValue;
const getBrandsListId = (data: APIResponse<Entry<Promotion>>) =>
    getIn(data, [...basePath, 'brandList', 'sys', 'id']);

const unableToFetchEntry = (result: APIResponse<Entry<Promotion>>) => {
    const errorEntry = getIn(result, ['error']);

    return errorEntry !== undefined;
};

const useLoadContentfulWithFallback = (entryId: string | null, fallbackEntryId: string) => {
    // state
    const dispatch = useDispatch<any>();
    const [scope, setScope] = useState<string | null>(entryId);
    const [loading, setLoading] = useState<boolean>(true); // assume we're loading the content by default

    // outgoing data
    const [contentfulData, setContentfulData] = useState<APIResponse<Entry<Promotion>>>();

    useEffect(() => {
        // only fetch IFF we've loaded the fallbackEnryId from firebase
        // AND
        // we haven't fetched the fallbackEnryId from contentful
        // NOTE: avoids 2 API calls to contentful
        if (fallbackEntryId && ((scope !== fallbackEntryId && loading) || loading)) {
            let contentfulEntryId: string;

            if (scope) {
                contentfulEntryId = scope;
            } else {
                contentfulEntryId = fallbackEntryId;
                /* setScope(fallbackEntryId); */
            }
            contentfulApi.getEntry<Promotion>(contentfulEntryId as string).then((result) => {
                // fallback logic in case the `promotion` was not able to fetch the contentful entry
                if (unableToFetchEntry(result)) {
                    setScope(fallbackEntryId);
                } else {
                    setContentfulData(result);
                    setLoading(false);
                    setScope(scope);
                }
            });
        }
    }, [dispatch, scope, fallbackEntryId, loading]);

    return { data: contentfulData, isLoading: loading, scope };
};

const useLoadSearchHeaderFromContentful = () => {
    const urlSearchParams = useQueryParams();
    const promotion = urlSearchParams.get('promotion');
    const [defaultScope, setDefaultScope] = useState('');

    // lets load the correct header and have the `default-scope` as a backup
    const { data, isLoading, scope } = useLoadContentfulWithFallback(promotion, defaultScope);

    // defines a transformation function for us to use to parse out the contentful data
    const contentfulResponseToData = (results: APIResponse<Entry<Promotion>>) => {
        return {
            title: getTitle(results),
            subTitle: getSubTitle(results),
            brandList: getBrandsListId(results),
        } as ContentfulData;
    };

    // SideEffect(Once):
    // get the `default content entry id` from firebase to be used in the following scnearios
    // - if NO promotion query parameter is present
    // - if promotion query parameter is present BUT is unable to load
    // - if promotion query parameter is not a correct contentful-entry-id
    useEffect(() => {
        (async () => {
            await RemoteConfigWeb.initialize();
            setDefaultScope(
                RemoteConfigWeb.getRemoteValue('default_promotion_landing_id').asString()
            );
        })();
    }, []);

    return {
        data: contentfulResponseToData(data as APIResponse<Entry<Promotion>>),
        isLoading,
        scope: scope || '',
    };
};

export const ContentfulView = (props: React.PropsWithChildren) => {
    const value = useLoadSearchHeaderFromContentful();
    return (
        <ContentfulContext.Provider value={value}> {props.children} </ContentfulContext.Provider>
    );
};
