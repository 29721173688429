import React from 'react';
import { Modal, ModalSize } from 'components/modal/Modal';
import { ReactComponent as WarningTriangle } from '../../../media/images/warning-triangle.svg';
import { Btn } from 'components/btn/Btn';

export const SessionExpiredModal = ({
    isOpen,
    setIsOpen,
    onSignInClicked,
    onSignOutClicked,
}: {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSignInClicked: () => void;
    onSignOutClicked: () => void;
}) => {
    return (
        <Modal
            titleStyles="text-center"
            isOpen={isOpen}
            title={''}
            onClose={() => setIsOpen(false)}
            size={[ModalSize.MEDIUM, ModalSize.SMALL]}
            showCloseButton={false}
        >
            <div className="mx-auto mt-3 text-center">
                <div className="flex flex-col items-center mx-auto">
                    <p className="font-light">
                        <WarningTriangle />
                    </p>
                    <p className="mt-6 mb-1 sm:mb-3 inline text-brand-dark text-22px sm:text-29px font-semibold">
                        Session Expired
                    </p>
                </div>
                <p className="mt-3 text-base text-grey-1 sm:text-lg">
                    Your session has expired. Do you want to sign in again?
                </p>
                <Btn className="mt-12 mb-5 px-10 bg-brand" onClick={onSignInClicked}>
                    Sign In
                </Btn>
                <button
                    className="mb-5 text-lg underline text-brand font-semibold mx-auto block"
                    onClick={onSignOutClicked}
                >
                    Sign Out
                </button>
            </div>
        </Modal>
    );
};
