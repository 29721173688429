import { CSSProperties } from 'react';

import { colors } from './settings';

import { createStyle } from 'utils/createStyle';

export const hBase = 'text-brand-dark outline-none';

export const h1 = `${hBase} font-light text-4xl sm:text-5xl`;

export const h2 = `${hBase} font-light text-3xl sm:text-4.5xl`;

export const h3 = `${hBase} font-normal text-2xl sm:text-4xl`;

export const h4 = `${hBase} font-semibold text-xl sm:text-3xl`;

export const h5 = `${hBase} font-semibold text-lg sm:text-2xl`;

export const h6 = `${hBase} font-semibold text-base sm:text-xl`;

export const linkStyles: CSSProperties = {
    color: colors.brand,
    textDecoration: 'underline',
};

export const link = createStyle({
    ...linkStyles,
    '&:not(.focus-visible)': { outline: 'none' },
});
