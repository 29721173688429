import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import {
    setStateRebateAdjustment,
    defaultStateRebateAdjustment,
} from 'common/features/store/duck/home/duck';
import { emptyHomeDetailsResponse } from 'common/api/crm/home/models/HomeDetailsResponse';
import { emptyAddress } from 'common/api/models/Address';
import { useRootSelector } from 'common/features/featuresReducer';

const localProductStateRebateAdjustment = {
    adjustment: 0,
};

export const useStateRebateAdjustment = (isLocalProduct?: boolean) => {
    const dispatch = useDispatch<any>();
    const homeDetails = useHomeDetails() || emptyHomeDetailsResponse;
    const { state } =
        (homeDetails.defaultOrganization && homeDetails.defaultOrganization.address) ||
        emptyAddress;
    const homeDetailsAsyncState = useRootSelector((s) => s.store.home.homeDetailsAsyncState);
    const stateRebateAdjustmentByState = useRootSelector(
        (s) => s.store.home.rebateAdjustmentByState
    );
    const stateRebateAdjustmentAsyncState = useRootSelector(
        (s) => s.store.home.stateRebateAdjustmentAsyncState
    );
    const stateRebateAdjustment =
        (state && stateRebateAdjustmentByState[state]) || defaultStateRebateAdjustment;
    const loading =
        stateRebateAdjustment === defaultStateRebateAdjustment &&
        stateRebateAdjustmentAsyncState.loading &&
        homeDetailsAsyncState.loading;

    const getStateRebateAdjustment = useCallback(() => {
        if (!state) {
            return;
        }
        dispatch(setStateRebateAdjustment(state));
    }, [dispatch, state]);

    return {
        loading,
        stateRebateAdjustment: isLocalProduct
            ? localProductStateRebateAdjustment
            : stateRebateAdjustment,
        getStateRebateAdjustment,
    };
};
