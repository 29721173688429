export enum EnrollSourceApplicationIds {
    V3Mobile = 'V3Mobile',
    V3Web = 'V3Web',
}

export interface RegistrationRequestWithoutOrg {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    username: string;
    password: string;
    securityQuestionAnswers: { [questionId: string]: string };
    blackBoxValue: string | null;
    secondaryBlackBoxValue: string | null;
    captchaResponse?: string | null;
    question1: string;
    question2: string;
    answer1: string;
    answer2: string;
    createOneTimeCode?: boolean;
    enrollSourceApplicationId: EnrollSourceApplicationIds;
}

export const emptyRegistrationRequestWithoutOrg: RegistrationRequestWithoutOrg = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    username: '',
    password: '',
    securityQuestionAnswers: {},
    blackBoxValue: null,
    secondaryBlackBoxValue: null,
    captchaResponse: null,
    question1: '',
    question2: '',
    answer1: '',
    answer2: '',
    createOneTimeCode: false,
    enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
};
