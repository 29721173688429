import React from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'react-feather';
import classNames from 'classnames';
import { Btn } from 'components/btn/Btn';
import { ModalStyles } from 'styles/modals';
import { colors } from 'styles/settings';
import { AlertProvider, AlertOutput } from 'modules/alerts';
import { createStyles } from 'utils/createStyle';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = createStyles({
    header: ['flex justify-end'],
    title: ['flex justify-center text-4xl mb-4'],
    mark: ['flex justify-center mb-4'],
    content: ['mx-16 text-center mb-8'],
    button: ['w-32'],
    buttonContainer: ['flex justify-center'],
});

export const ErrorModal = (props: { isOpen: boolean; onClose: () => void }) => {
    return (
        <AlertProvider>
            {/* @ts-ignore */}
            <Dialog.Root open={props.isOpen}>
                <Dialog.Overlay className={ModalStyles.overlay} />
                <Dialog.Content className={ModalStyles.contentWrapper}>
                    <div className={ModalStyles.content}>
                        <div className={styles.header}>
                            <button
                                onClick={props.onClose}
                                className={ModalStyles.closeButton}
                                aria-label="Close"
                            >
                                <X color={colors.brand} size={28} />
                            </button>
                        </div>
                        <div className={styles.mark}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size="2x"
                                color={colors.error}
                                className="mr-2"
                            />
                        </div>
                        <div className={styles.title}>
                            <h2>Whoops!</h2>
                        </div>
                        <div className={styles.content}>
                            <p>Unfortunately there's been an error.</p>
                            <p>
                                Please give our Customer Support team a call at: 1-800-727-4715,
                                Option 4.
                            </p>
                        </div>
                        <div className={styles.buttonContainer}>
                            <Btn
                                type="button"
                                onClick={props.onClose}
                                isSecondary
                                className={classNames(styles.button)}
                            >
                                Close
                            </Btn>
                        </div>
                    </div>
                    <AlertOutput />
                </Dialog.Content>
            </Dialog.Root>
        </AlertProvider>
    );
};
