import React, { useContext, useState } from 'react';

import { EnrollmentContext } from '../../context';
import { OrgProfileQuestionWrapper } from '../OrgProfileQuestionWrapper';
import { Question } from '../../components/Question';

import { OutlinedButton } from 'components/outlined-btn/OutlinedButton';
import { OrgPeople } from 'common/api/crm/models/Organization';
import { InvalidFeedback } from 'components/invalid-feedback/InvalidFeedback';

export const OrgPeopleStep = () => {
    const { goToNextStep, setOrganization, organization } = useContext(EnrollmentContext);
    const [answered, setAnswered] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const question = 'How many people are a part of your organization?';

    const setOrgPeople = (pplValue: OrgPeople) => {
        setOrganization({
            ...organization,
            people: pplValue,
        });
        setAnswered(true);
    };

    const submit = () => {
        setSubmitted(true);
        if (answered) {
            goToNextStep();
        }
    };

    const peopleButton = (pplValue: OrgPeople, label: string) => (
        <OutlinedButton
            className="w-2/5 md:w-1/4 m-1"
            active={organization.people === pplValue}
            onClick={() => setOrgPeople(pplValue)}
        >
            {label}
        </OutlinedButton>
    );

    return (
        <OrgProfileQuestionWrapper stepProgress={[2, 5]}>
            <Question className="mt-36" question={question}>
                <div className="mt-8 text-lg sm:text-xl text-center font-semibold text-grey-2 flex flex-wrap justify-center w-full">
                    {peopleButton(10, '10 or less')}
                    {peopleButton(50, '11 - 50')}
                    {peopleButton(200, '51 - 200')}
                    {peopleButton(500, '201 - 500')}
                    {peopleButton(1000, '501 - 1000')}
                    {peopleButton(1500, '1000+')}
                </div>
                {!answered && submitted && (
                    <InvalidFeedback className="text-error text-sm pt-1 text-center" id={question}>
                        Please choose one of the options
                    </InvalidFeedback>
                )}
                <button
                    onClick={submit}
                    className="my-9 m-auto block font-semibold text-lg underline text-brand"
                >
                    Next
                </button>
            </Question>
        </OrgProfileQuestionWrapper>
    );
};
