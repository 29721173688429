export interface SortConfig<T extends string> {
    defaultSortKey: T;
    sortOptions: T[];
    orderByLabelMapper: Record<T, string>;
}

export enum BrandOrderByType {
    NameAsc = 'NormalizedName asc',
    PopularityRank = 'PopularityRank,NormalizedName',
    HighestEarnings = 'MaxRebate desc',
}

export const brandOrderByLabelMapper: Record<BrandOrderByType, string> = {
    [BrandOrderByType.NameAsc]: 'Name (a-z)',
    [BrandOrderByType.PopularityRank]: 'Most Popular',
    [BrandOrderByType.HighestEarnings]: 'Highest Earnings',
};

export const brandSortByOrder = [
    BrandOrderByType.PopularityRank,
    BrandOrderByType.NameAsc,
    BrandOrderByType.HighestEarnings,
];

export const brandDefaultSortConfig: SortConfig<BrandOrderByType> = {
    defaultSortKey: BrandOrderByType.PopularityRank,
    sortOptions: brandSortByOrder,
    orderByLabelMapper: brandOrderByLabelMapper,
};

export enum CardOrderByType {
    NameAsc = 'NameAsc',
    RecentlyAdded = 'RecentlyAdded',
    RecentlyOpened = 'RecentlyOpened',
}

export const cardOrderByLabelMapper: Record<CardOrderByType, string> = {
    [CardOrderByType.NameAsc]: 'Name (a-z)',
    [CardOrderByType.RecentlyAdded]: 'Recently Added',
    [CardOrderByType.RecentlyOpened]: 'Recently Opened',
};

export const cardSortByOrder = [
    CardOrderByType.RecentlyAdded,
    CardOrderByType.NameAsc,
    CardOrderByType.RecentlyOpened,
];

export const cardDefaultSortConfig: SortConfig<CardOrderByType> = {
    defaultSortKey: CardOrderByType.RecentlyAdded,
    sortOptions: cardSortByOrder,
    orderByLabelMapper: cardOrderByLabelMapper,
};
