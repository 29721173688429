import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { isAffiliateOnlyBrand } from 'common/utils/affiliate';
import { LinkProps } from 'react-router-dom';
import { getPath, Routes } from 'routes';
import {
    EarnTypeConstructorFilter,
    checkAffiliateBonusExpiration,
} from 'common/api/constructor/util';
import { max } from 'lodash';

export interface CardPropsBase {
    logoImage: string;
    brandName: string;

    link: LinkProps['to'];
    isLoading: boolean;
    supportedTypes?: string[];
    id: string;
    categories: string[];
    bonusExpiration?: string;
    hasMultipleRebates?: boolean;
    containsVariableRebate?: boolean;
    islocalProduct: boolean;
    isNew?: boolean;
    isSponsored?: boolean;
    isFavoritable?: boolean;
    singleTypeView?: boolean;
    showMarker?: boolean;
}

export interface CardPropsEarn extends CardPropsBase {
    earn: {
        type: EarnTypeConstructorFilter;
        isOnBonus: boolean;
        previous: number | undefined;
        actual: number | undefined;
    };
}

export interface CardPropsEarns extends CardPropsBase {
    earn: {
        type: EarnTypeConstructorFilter;
        isOnBonus: boolean;
        previous: number | undefined;
        actual: number | undefined;
    }[];
}

const slugify = (name: string) =>
    name
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

const backRoutes = {
    [EarnTypeConstructorFilter.ShopOnline]: Routes.ShopOnline,
    [EarnTypeConstructorFilter.GiftCards]: Routes.ShopGiftCards,
};
const backRouteToUse = (earnType?: EarnTypeConstructorFilter) => {
    if (earnType) {
        return backRoutes[earnType];
    }
    return Routes.Brands;
};

// NOTE: adding this sanitizer to address corrupt data in the DEV environment.
const sanitizeRebate = (numbers: number[] | number | null | undefined, depth = 0, maxDepth = 3): number => {
    if (maxDepth <= depth) {
        return 0;
    }

    if (Array.isArray(numbers)) {
        return sanitizeRebate(max(numbers) || 0, depth + 1);
    }

    return numbers as number;
}

export const convertBrandToCardProps = (
    item: Brand,
    earnType?: EarnTypeConstructorFilter,
    singleTypeView?: boolean
): CardPropsEarns => {
    const onlyAffiliate = isAffiliateOnlyBrand(item.id);
    const state = { brandName: item.name, backRoute: backRouteToUse(earnType) } as Record<
        string,
        unknown
    >;
    let idWithSlug = item.urlSlug ? `${item.id}-${item.urlSlug.replace(/\//g, '')}` : item.id;

    let route = getPath(Routes.BrandsDetails, { id: idWithSlug });

    const hasKebabPathInRoute = idWithSlug.includes('-');
    if (!hasKebabPathInRoute || isAffiliateOnlyBrand(item.id)) {
        const kebabCaseName = slugify(item.name);
        if (kebabCaseName) {
            idWithSlug = `${idWithSlug}-${kebabCaseName}`;
        }
    }

    if (item.isLocalProduct) {
        const [, , id] = item.id.split('-');
        const routeParams = { productId: id };
        route = getPath(Routes.ShopLocalProductDetails, routeParams);
    } else {
        route =
            earnType === EarnTypeConstructorFilter.ShopOnline || onlyAffiliate
                ? getPath(Routes.BrandsShopOnlineDetails, { id: idWithSlug })
                : getPath(Routes.BrandsGiftCardDetails, { id: idWithSlug });
    }

    const link = {
        pathname: route,
        state,
    };

    const isShopOnline = earnType === EarnTypeConstructorFilter.ShopOnline;

    const isAffiliateOfferOnBonus = checkAffiliateBonusExpiration(
        item.affiliateBonusStart,
        item.affiliateBonusEnd
    );

    if (!isAffiliateOfferOnBonus) {
        item.affiliateBonus = undefined;
    }

    const earnOnline = {
        type: EarnTypeConstructorFilter.ShopOnline,
        isOnBonus: isAffiliateOfferOnBonus,
        previous: item.affiliateBonus ? item.affiliateRebate : 0,
        actual: sanitizeRebate([item.affiliateBonus || item.affiliateRebate || 0]),
    };
    const earnGiftCard = {
        type: EarnTypeConstructorFilter.GiftCards,
        isOnBonus: item.isOnBonus,
        previous: item.bonusRebate ? item.rebate : 0,
        actual: sanitizeRebate([item.bonusRebate || item.rebate || 0]),
    };

    const earn = [];
    if (singleTypeView) {
        isShopOnline || onlyAffiliate ? earn.push(earnOnline) : earn.push(earnGiftCard);
    } else {
        earnGiftCard.actual && !onlyAffiliate && earn.push(earnGiftCard);
        earnOnline.actual && earn.push(earnOnline);
    }

    return {
        id: item.id,
        logoImage: item.imageUrl,
        brandName: item.name,
        earn: earn,
        link: link,
        isLoading: false,
        supportedTypes: isShopOnline ? [] : item.supportedTypes || [],
        categories: item.categories || [],
        bonusExpiration: isShopOnline ? item?.affiliateBonusEnd : item?.expiration,
        hasMultipleRebates: item.hasMultipleRebates,
        containsVariableRebate:
            !!item.containsVariableRebate || !!item.affiliateVariablePricingText,
        islocalProduct: item.isLocalProduct,
        isNew: !!item.newBannerExpiration && new Date(item.newBannerExpiration) > new Date(),
        isSponsored: item.isSponsored,
        isFavoritable: !onlyAffiliate && !item.isLocalProduct,
        singleTypeView,
    };
};
