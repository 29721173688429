import { MaskedPhoneNumber } from '../../models/MaskedPhoneNumber';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { SECURED_TWO_FACTOR_PHONE_URL, TWO_FACTOR_PHONE_URL } from 'common/api/config';
import { apiFetch } from 'common/api/utils';

export enum DeliveryMethod {
    Text = 'Text',
    Voice = 'Voice',
}

export interface AddPhoneRequest {
    phoneNumber: string;
    phoneExtension?: string;
    deliveryMethod: DeliveryMethod;
}

const isBrowserEnvironment = typeof document !== 'undefined';
const source = isBrowserEnvironment ? 'Web' : undefined;

export const addPhone = (addPhoneRequest: AddPhoneRequest) =>
    withTokensIfNeeded<{ phoneId: string }>(`${SECURED_TWO_FACTOR_PHONE_URL}?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...addPhoneRequest, source }),
    });

export const getCode = (phoneId: string, deliveryMethod: DeliveryMethod) =>
    apiFetch<{ deliveryMethod: DeliveryMethod }>(
        `${TWO_FACTOR_PHONE_URL}/${phoneId}?api-version=1`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ deliveryMethod, source }),
        }
    );

export const getPhonesByUser = (userId: string) =>
    apiFetch<MaskedPhoneNumber[]>(`${TWO_FACTOR_PHONE_URL}/user/${userId}?api-version=1`, {
        method: 'GET',
    });

export const confirmPhone = (phoneId: string, code: string, blackBoxValue: string) =>
    withTokensIfNeeded<void>(`${SECURED_TWO_FACTOR_PHONE_URL}/${phoneId}/confirm?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, blackBoxValue }),
    });

export const removePhone = (phoneId: string) =>
    withTokensIfNeeded<void>(`${SECURED_TWO_FACTOR_PHONE_URL}/${phoneId}?api-version=1`, {
        method: 'DELETE',
    });
