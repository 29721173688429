import React, { useCallback, useEffect } from 'react';

import { useRootSelector, FeaturesState } from 'common/features/featuresReducer';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { applyTerm } from 'common/features/store/duck/search/duck';
import { useHistory } from 'react-router-dom';

export interface IBox {
    active: boolean;
    prefix: string;
    applyFilter: (_: string) => void;
}

export const Box = ({ active, children, prefix, applyFilter }: React.PropsWithChildren<IBox>) => {
    const classes = classnames(
        'rounded border border-grey-1 py-1 px-2 text-sm text-center w-min',
        active ? 'bg-brand-dark text-white font-semibold cursor-default' : 'cursor-pointer'
    );

    const onClick = async () => {
        applyFilter(prefix);
    };

    return (
        <div onClick={active ? undefined : onClick} className={classes}>
            {children}
        </div>
    );
};

export interface IAlphabetFilter {
    currentFilter: string;
    applyFilter: (_: string) => void;
    showBorder?: boolean;
}

export const AlphabetFilter = ({
    currentFilter,
    applyFilter,
    showBorder = true,
}: IAlphabetFilter) => {
    const filters = 'All,#,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z';

    return (
        <div className="brands_alpha_filter_bar mx-8 lg:mx-16 2xl:mx-28 hidden sm:block items-center">
            <div
                className={`${
                    showBorder ? 'border-t border-b' : ''
                } flex mx-auto gap-3 xl:justify-center flex-wrap py-5`}
            >
                {filters.split(',').map((filter: string) => (
                    <Box
                        key={filter}
                        active={currentFilter === filter}
                        prefix={filter}
                        applyFilter={applyFilter}
                    >
                        {filter}
                    </Box>
                ))}
            </div>
        </div>
    );
};

export const AThroughZFilter = () => {
    const currentFilter = useRootSelector((s: FeaturesState) => s.store.search.appliedTerm);
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const setCurrentFilterTo = useCallback(
        (filter: string) => dispatch(applyTerm(filter)),
        [dispatch]
    );
    // Effect: reset search term on every page change
    useEffect(() => {
        const unsubscribe = history.listen(() => {
            setCurrentFilterTo('');
        });
        return unsubscribe;
    }, [history, setCurrentFilterTo]);

    return (
        <div>
            <AlphabetFilter
                showBorder={false}
                currentFilter={currentFilter}
                applyFilter={setCurrentFilterTo}
            />
        </div>
    );
};

// type aliasing for more readability
type CognitiveSearchRegexp = string;
type StringRegexp = string;

/* Returns two regexp based on what the prefix is
 * Implementation Detail:
 *  We need to generate a CognitiveSearch regular expression to do a prefix-search.
 *  The basic case is handled by the `default` statement, that is the letters A through Z.
 *  The other two cases are to generate specific regular expressions for non-alpha search.
 *
 * Returns:
 *  - The first string returned is a valid regexp that cognitive-search understands to
 *  to do a prefix search.
 *  - The second string returned is a valid regexp that can be read by javascript's RegExp
 *  class. Utilized by the `filterByPrefix` function to further filter search results that
 *  comeback from the `getBrands` call.
 */
export const getSearchTerm = (prefix: string): [CognitiveSearchRegexp, StringRegexp] => {
    // CASE: prefix happens to be the search-term from search-bar
    if (prefix.length > 1 && prefix !== 'All') {
        return [prefix, '.*'];
    }

    // CASE: user is filtering through A-Z filter
    switch (prefix) {
        case '#':
            return ['/[^a-z]/', '^[^a-zA-Z]'];
        case 'All':
            return ['', '.*'];
        default:
            return [`${prefix.toUpperCase()}*`, `^${prefix.toUpperCase()}`];
    }
};

export const AThroughZMobileFilterCategory = ({
    currentFilter,
    onFilterClicked,
}: {
    currentFilter: string;
    onFilterClicked: (_: string) => void;
}) => {
    const filters = '#,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,All';
    const history = useHistory();

    // Effect: reset search term on every page change
    useEffect(() => {
        const unsubscribe = history.listen(() => {
            onFilterClicked('');
        });
        return unsubscribe;
    }, [history, onFilterClicked]);

    return (
        <div className="mb-4">
            <p className="font-semibold text-center text-grey-1 text-xl">Recommended For You</p>
            <div className="grid grid-cols-6 gap-4 mt-5">
                {filters.split(',').map((filter: string) => (
                    <Box
                        key={filter}
                        active={filter === currentFilter}
                        prefix={filter}
                        applyFilter={onFilterClicked}
                    >
                        {filter}
                    </Box>
                ))}
            </div>
        </div>
    );
};
