export const and = (...filters: string[]) => (filters.length ? `(${filters.join(' and ')})` : '');

export const or = (...filters: string[]) => (filters.length ? `(${filters.join(' or ')})` : '');

export const eq = <T = string>(facet: string, value: T) => `(${facet} eq ${value})`;

export const ne = <T = string>(facet: string, value: T) => `(${facet} ne ${value})`;

export const gt = <T = string>(facet: string, value: T) => `(${facet} gt ${value})`;

export const lt = <T = string>(facet: string, value: T) => `(${facet} lt ${value})`;

export const ge = <T = string>(facet: string, value: T) => `(${facet} ge ${value})`;

export const le = <T = string>(facet: string, value: T) => `(${facet} le ${value})`;

export const not = (expression: string) => `not ${expression}`;

export const includesEq = <T = string>(facet: string, value: T) =>
    `(${facet}/any(t: t eq '${value}'))`;

export const includesGeLt = <T = string>(facet: string, geValue: T, ltValue: T) =>
    `(${facet}/any(t: t ge ${geValue} and t lt ${ltValue}))`;

export const includesGt = <T = string>(facet: string, value: T) =>
    `(${facet}/any(t: t gt ${value}))`;

export const includesLt = <T = string>(facet: string, value: T) =>
    `(${facet}/any(t: t lt ${value}))`;

export const facetPOST = (facet: string, values?: string) =>
    values ? `${facet},values:${values}` : `${facet}`;

export const joinQuery = (...items: string[]) => items.join('&');
