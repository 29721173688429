import React from 'react';

import { ReactComponent as BarCodeIcon } from '../../media/images/custom-icons/barcode.svg';
import { ReactComponent as CheckIcon } from '../../media/images/custom-icons/check.svg';
import { ReactComponent as ClothingAndAccessoriesIcon } from '../../media/images/custom-icons/clothingAndAccessories.svg';
import { ReactComponent as FastFoodIcon } from '../../media/images/custom-icons/fast-food.svg';
import { ReactComponent as FoodAndBeverageIcon } from '../../media/images/custom-icons/food-and-beverage.svg';
import { ReactComponent as EntertainmentIcon } from '../../media/images/custom-icons/entertainment.svg';
import { ReactComponent as GasAndAutoIcon } from '../../media/images/custom-icons/gasAndAuto.svg';
import { ReactComponent as GiftsAndFlowersIcon } from '../../media/images/custom-icons/giftsAndFlowers.svg';
import { ReactComponent as GroceryIcon } from '../../media/images/custom-icons/grocery.svg';
import { ReactComponent as HealthAndBeautyIcon } from '../../media/images/custom-icons/healthAndBeauty.svg';
import { ReactComponent as HomeIcon } from '../../media/images/custom-icons/home-decor.svg';
import { ReactComponent as PetsIcon } from '../../media/images/custom-icons/pets.svg';
import { ReactComponent as PhysicalCardIcon } from '../../media/images/custom-icons/physical-card.svg';
import { ReactComponent as PrepaidCreditCardsIcon } from '../../media/images/custom-icons/universal-prepaid-cards.svg';
import { ReactComponent as ReloadIcon } from '../../media/images/custom-icons/reload.svg';
import { ReactComponent as RetailIcon } from '../../media/images/custom-icons/retail.svg';
import { ReactComponent as ServicesAndUtilitiesIcon } from '../../media/images/custom-icons/servicesAndUtilities.svg';
import { ReactComponent as ShipToHomeIcon } from '../../media/images/custom-icons/ship-to-home.svg';
import { ReactComponent as SportingGoodsAndOutdoorsIcon } from '../../media/images/custom-icons/sportingGoodsAndOutdoors.svg';
import { ReactComponent as TechAndOfficeIcon } from '../../media/images/custom-icons/tech-and-electronics.svg';
import { ReactComponent as ToysAndHobbiesIcon } from '../../media/images/custom-icons/toys-and-hobbies.svg';
import { ReactComponent as TravelIcon } from '../../media/images/custom-icons/travel.svg';
import { ReactComponent as BooksIcon } from '../../media/images/custom-icons/books.svg';
import { ReactComponent as RestaurantsIcon } from '../../media/images/custom-icons/restaurants.svg';
import { ReactComponent as HomeImprovementIcon } from '../../media/images/custom-icons/home improvement.svg';
import { ReactComponent as DepartmentStoreIcon } from '../../media/images/custom-icons/department store.svg';
import { ReactComponent as ChildrenAndTeensIcon } from '../../media/images/custom-icons/childrenAndTeens.svg';
import { ReactComponent as OnlineShoppingIcon } from '../../media/images/custom-icons/online-shopping.svg';
import { ReactComponent as OfficeAndSchoolSuppliesIcon } from '../../media/images/custom-icons/office-and-school-supplies.svg';
import { ReactComponent as TenAndUnderIcon } from '../../media/images/custom-icons/ten-dollars-and-under.svg';
import { ReactComponent as SchoolUniformsIcon } from '../../media/images/custom-icons/school-uniforms.svg';
import { ReactComponent as DiscountStoreAndOutletsIcon } from '../../media/images/custom-icons/discount-store-and-outlets.svg';
import { ReactComponent as StreamingAndGamingIcon } from '../../media/images/custom-icons/streaming-and-gaming.svg';
import { ReactComponent as MusicIcon } from '../../media/images/custom-icons/music.svg';
import { ReactComponent as MoviesIcon } from '../../media/images/custom-icons/movies.svg';
import { ReactComponent as CustomLocalProductIcon } from '../../media/images/custom-icons/CustomLocalProduct.svg';
import { ReactComponent as BankAccountIcon } from '../../media/images/custom-icons/bankAccount.svg';
import { ReactComponent as CreditCardIcon } from '../../media/images/custom-icons/creditCard.svg';
import { ReactComponent as PayCoordinatorIcon } from '../../media/images/custom-icons/payCoordinator.svg';

export const Books = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <BooksIcon className="inline-block" {...props} />
);

export const Music = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <MusicIcon className="inline-block" {...props} />
);

export const Movies = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <MoviesIcon className="inline-block" {...props} />
);

export const StreamingAndGaming = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <StreamingAndGamingIcon className="inline-block" {...props} />
);

export const DiscountStoreAndOutlets = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <DiscountStoreAndOutletsIcon className="inline-block" {...props} />
);

export const Restaurants = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <RestaurantsIcon className="inline-block" {...props} />
);

export const HomeImprovement = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <HomeImprovementIcon className="inline-block" {...props} />
);

export const DepartmentStore = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <DepartmentStoreIcon className="inline-block" {...props} />
);

export const ChildrenAndTeens = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ChildrenAndTeensIcon className="inline-block" {...props} />
);

export const OnlineShopping = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <OnlineShoppingIcon className="inline-block" {...props} />
);

export const OfficeAndSchoolSupplies = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <OfficeAndSchoolSuppliesIcon className="inline-block" {...props} />
);

export const TenAndUnder = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <TenAndUnderIcon className="inline-block" {...props} />
);

export const SchoolUniforms = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <SchoolUniformsIcon className="inline-block" {...props} />
);

export const BarCode = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <BarCodeIcon className="inline-block" {...props} />
);
export const Check = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <CheckIcon className="inline-block" {...props} />
);
export const ClothingAndAccessories = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ClothingAndAccessoriesIcon className="inline-block" {...props} />
);
export const FastFood = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <FastFoodIcon className="inline-block" {...props} />
);
export const FoodAndBeverage = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <FoodAndBeverageIcon className="inline-block" {...props} />
);
export const Entertainment = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <EntertainmentIcon className="inline-block" {...props} />
);
export const GasAndAuto = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <GasAndAutoIcon className="inline-block" {...props} />
);
export const GiftsAndFlowers = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <GiftsAndFlowersIcon className="inline-block" {...props} />
);
export const Grocery = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <GroceryIcon className="inline-block" {...props} />
);
export const HealthAndBeauty = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <HealthAndBeautyIcon className="inline-block" {...props} />
);
export const Home = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <HomeIcon className="inline-block" {...props} />
);
export const Pets = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <PetsIcon className="inline-block" {...props} />
);
export const PhysicalCard = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <PhysicalCardIcon className="inline-block" {...props} />
);
export const PrepaidCreditCards = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <PrepaidCreditCardsIcon className="inline-block" {...props} />
);
export const Reload = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ReloadIcon className="inline-block" {...props} />
);
export const Retail = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <RetailIcon className="inline-block" {...props} />
);
export const ServicesAndUtilities = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ServicesAndUtilitiesIcon className="inline-block" {...props} />
);
export const ShipToHome = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ShipToHomeIcon className="inline-block" {...props} />
);
export const SportingGoodsAndOutdoors = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <SportingGoodsAndOutdoorsIcon className="inline-block" {...props} />
);
export const TechAndOffice = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <TechAndOfficeIcon className="inline-block" {...props} />
);
export const ToysAndHobbies = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <ToysAndHobbiesIcon className="inline-block" {...props} />
);
export const Travel = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <TravelIcon className="inline-block" {...props} />
);

export const CustomLocalProduct = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <CustomLocalProductIcon className="inline-block" {...props} />
);

export const BankAccount = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <BankAccountIcon className="inline-block" {...props} />
);
export const CreditCard = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <CreditCardIcon className="inline-block" {...props} />
);
export const PayCoordinator = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <PayCoordinatorIcon className="inline-block" {...props} />
);
