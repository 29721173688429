import React from 'react';
import classnames from 'classnames';

import { createStyles } from 'utils/createStyle';

interface ButtonProps {
    active: boolean;
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    hasIcon?: boolean;
    className?: string;
}

export const OutlinedButton = React.memo(
    ({ active = false, children, onClick, hasIcon = false, className }: ButtonProps) => {
        const btnStyles = createStyles({
            btnStyles: [
                `w-36 md:w-56 ${
                    active ? 'bg-pale-blue font-semibold text-brand-dark' : 'bg-white text-brand'
                } px-2 ${hasIcon ? 'text-left' : ''}`,
                {
                    border: '1px solid #005E9D',
                    borderRadius: '5px',
                    height: '50px',
                    ':focus': {
                        outline: 'none',
                        boxShadow: 'none',
                    },
                    fontSize: 12,
                },
            ],
        });

        return (
            <button
                className={classnames(className, btnStyles.btnStyles)}
                onClick={onClick}
                data-testid="outlined-button"
            >
                {children}
            </button>
        );
    }
);
