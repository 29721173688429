export const getEndpointsForAppType = (apiBase: string, appType: 'mobile' | 'web') => {
    const IS_MOBILE = appType === 'mobile';

    const API_URL = IS_MOBILE ? apiBase : '/Api'; // Anonymous
    const SECURED_API_URL = IS_MOBILE ? apiBase : '/SecuredApi';

    const USERS_URL = `${API_URL}/users`;
    const SECURED_USERS_URL = `${SECURED_API_URL}/users`;

    const LOGIN_URL = `${USERS_URL}/login`; // ANONYMOUS
    const SECURED_LOGIN_URL = `${SECURED_USERS_URL}/login`; // SECURED
    const EMAIL_URL = `${USERS_URL}/email`; // Not used???
    const TWO_FACTOR_URL = `${USERS_URL}/twofactor`; // SECURED
    const SECURED_TWO_FACTOR_URL = `${SECURED_USERS_URL}/twofactor`; // SECURED
    const TWO_FACTOR_PHONE_URL = `${TWO_FACTOR_URL}/phone`; // ANONYMOUS
    const SECURED_TWO_FACTOR_PHONE_URL = `${SECURED_TWO_FACTOR_URL}/phone`; // SECURED
    const ACCOUNT_URL = `${USERS_URL}/account`; // ANONYMOUS
    const SECURED_ACCOUNT_URL = `${SECURED_USERS_URL}/account`; // SECURED

    // CRM
    const CRM_URL = `${API_URL}/crm`;
    const SECURED_CRM_URL = `${SECURED_API_URL}/crm`;

    const HOME_DETAILS_URL = `${CRM_URL}/home`;
    const ORGANIZATION_URL = `${CRM_URL}/organization`;
    const SECURED_ORGANIZATION_URL = `${SECURED_CRM_URL}/organization`;
    const CRM_ORGS_URL = `${SECURED_CRM_URL}/organizations`; // SECURED
    const FAMILY_URL = `${SECURED_CRM_URL}/family`; // SECURED
    const FAMILY_ITERABLE_TOKEN_URL = `${FAMILY_URL}/iterable/token?api-version=1`;
    const WEB_ITERABLE_TOKEN_URL = `${FAMILY_URL}/iterable/token?api-version=1&clientType=Web`;
    const FAMILY_ADDRESS_URL = `${SECURED_CRM_URL}/Family/address`; // SECURED
    const FEATURE_FLAGS_URL = `${SECURED_CRM_URL}/FeatureFlags`; // SECURED
    const CRM_AFFILIATES_URL = `${SECURED_CRM_URL}/affiliates`; // SECURED

    // E-COMM
    const E_COMM_URL = `${API_URL}/e-comm`;
    const SECURED_E_COMM_URL = `${SECURED_API_URL}/e-comm`;

    const E_COMM_ORGS_URL = `${SECURED_E_COMM_URL}/organizations`; // SECURED
    const E_COMM_BRANDS_URL = `${E_COMM_URL}/brands`; // ANONYMOUS
    const E_COMM_PRODUCTS_URL = `${SECURED_E_COMM_URL}/products`; // SECURED
    const ALL_CATEGORIES_URL = `${E_COMM_URL}/products/categories`; // ANONYMOUS
    const PAYMENT_TYPES_URL = `${SECURED_E_COMM_URL}/PaymentTypes`; // SECURED
    const ACH_URL = `${SECURED_E_COMM_URL}/Ach`; // SECURED
    const ORDERS_URL = `${SECURED_E_COMM_URL}/Orders`; // SECURED
    const CREDIT_CARDS_URL = `${SECURED_E_COMM_URL}/CreditCards`; // SECURED
    const EARNINGS_URL = `${SECURED_E_COMM_URL}/earnings`; // SECURED

    // WALLET
    const WALLET_URL = `${API_URL}/wallet`;
    const SECURED_WALLET_URL = `${SECURED_API_URL}/wallet`;

    const WALLET_CARDS_URL = `${SECURED_WALLET_URL}/Cards`; // SECURED
    const WALLET_CARDS_ARCHIVED_URL = `${WALLET_CARDS_URL}/archived`; // SECURED

    // FULFILLMENT
    const FULFILLMENT_URL = `${API_URL}/fulfillment`;
    const SECURED_FULFILLMENT_URL = `${SECURED_API_URL}/fulfillment`;

    const FULFILLMENT_ADDRESS_VALIDATION_URL = `${SECURED_FULFILLMENT_URL}/AddressValidation`; // SECURED
    const FULFILLMENT_SHIPPING_URL = `${SECURED_FULFILLMENT_URL}/Shipping`; // SECURED

    const SEARCH_URL = `${API_URL}/search`;

    // USERS
    return {
        API_URL,
        SECURED_API_URL,
        USERS_URL,
        SECURED_USERS_URL,
        LOGIN_URL,
        SECURED_LOGIN_URL,
        EMAIL_URL,
        TWO_FACTOR_URL,
        SECURED_TWO_FACTOR_URL,
        TWO_FACTOR_PHONE_URL,
        SECURED_TWO_FACTOR_PHONE_URL,
        ACCOUNT_URL,
        SECURED_ACCOUNT_URL,
        CRM_URL,
        SECURED_CRM_URL,
        HOME_DETAILS_URL,
        ORGANIZATION_URL,
        SECURED_ORGANIZATION_URL,
        CRM_ORGS_URL,
        FAMILY_URL,
        FAMILY_ADDRESS_URL,
        FAMILY_ITERABLE_TOKEN_URL,
        WEB_ITERABLE_TOKEN_URL,
        FEATURE_FLAGS_URL,
        CRM_AFFILIATES_URL,
        E_COMM_URL,
        SECURED_E_COMM_URL,
        E_COMM_ORGS_URL,
        E_COMM_BRANDS_URL,
        E_COMM_PRODUCTS_URL,
        ALL_CATEGORIES_URL,
        PAYMENT_TYPES_URL,
        ACH_URL,
        ORDERS_URL,
        CREDIT_CARDS_URL,
        EARNINGS_URL,
        WALLET_URL,
        SECURED_WALLET_URL,
        WALLET_CARDS_URL,
        WALLET_CARDS_ARCHIVED_URL,
        FULFILLMENT_URL,
        SECURED_FULFILLMENT_URL,
        FULFILLMENT_ADDRESS_VALIDATION_URL,
        FULFILLMENT_SHIPPING_URL,
        SEARCH_URL,
    };
};
