import { ConstructorFacet, EarnTypeConstructorFilter } from './types';

export function getCategoriesFromFilters(filters = ''): string[] {
    const categoryStatements = filters.match(/Categories\/any\((t|c): (t|c) eq '(.+?)'\)/g);
    const categoryStrings =
        categoryStatements?.map((category) =>
            category.replace(/Categories\/any\((t|c): (t|c) eq |'\)/g, '')
        ) || [];
    const cleanedUpCategoryStrings = categoryStrings.map((category) => category.replace(/'/g, ''));
    return cleanedUpCategoryStrings;
}

export function getOrganizationIds(filters = ''): string[] {
    // get the organization id from the filter
    const organizationStatements = filters.match(/OrganizationId eq '(.+?)'/g) || [];
    const cleanedUpOrganizationStrings = organizationStatements.map((organization) => {
        return organization.replace('OrganizationId eq ', '').replace(/'/g, '');
    });
    return ['0', ...cleanedUpOrganizationStrings];
}

export function getOnBonus(filters = ''): boolean {
    return filters.includes('IsOnBonus eq true');
}

export const getOnBonusFilterByEarnType = (earnType?: string[] | null) => {
    if (!earnType || (earnType && earnType.length > 1)) {
        return { IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus: ['True'] };
    }
    if (earnType && earnType[0] === EarnTypeConstructorFilter.ShopOnline) {
        return { IsAffiliateOfferOnBonus: ['True'] };
    }

    return { IsOnBonus: ['True'] };
};

export function getAllowedStates(filters = ''): string[] | null {
    // find states in the string like this: (BlacklistedStates/any(t: t eq 'IL')
    const blacklistedStates = filters.match(/BlacklistedStates\/any\(t: t eq '(.+?)'\)/g);

    // if no blacklisted states are found, return null
    if (!blacklistedStates) {
        return null;
    }

    // extract the state from the string like this: IL
    // [ "BlacklistedStates/any(t: t eq 'IL')" ]
    const blacklistedStateStrings = blacklistedStates?.map((result) => {
        // get the two character state from between the ' chars
        // end result is the string like this: IL
        return result.split("'")[1];
    });

    return blacklistedStateStrings;
}

const availableForVirtualOnlyTests = [
    "((IsShipToHomeEligible eq true) or (SupportedTypes/any(t: t eq 'eGift Card'))",
    "(SupportedTypes/any(t: t eq 'eGift Card') or (IsShipToHomeEligible eq true)",
];
export function isVirtualOnlyRequestEnabled(filters = ''): boolean {
    // return whether the string includes any availableForVirtualOnlyTests
    return availableForVirtualOnlyTests.some((test) => {
        return filters.includes(test);
    });
}

function cleanUpFilters(filters: string): string {
    return filters
        .replace(
            /(\(IsShipToHomeEligible eq true\) or \(SupportedTypes\/any\(t: t eq 'eGift Card'\)\))/g,
            ''
        )
        .replace(
            /(\(SupportedTypes\/any\(t: t eq 'eGift Card'\) or \(IsShipToHomeEligible eq true\)\))/g,
            ''
        );
}

export function getShipToHomeFilters(filters = ''): string[] {
    const cleanedUpFilters = cleanUpFilters(filters);

    return [
        cleanedUpFilters.includes('IsShipToHomeEligible eq true') ? 'True' : '',
        cleanedUpFilters.includes('IsShipToHomeEligible eq false') ? 'False' : '',
    ].filter(Boolean);
}

export function getSupportedTypesFilters(filters = ''): string[] {
    const cleanedUpFilters = cleanUpFilters(filters);

    const supportedTypes = cleanedUpFilters.match(/SupportedTypes\/any\(t: t eq '(.+?)'\)/g);
    return supportedTypes?.map((type) => type.split("'")[1]) || [];
}

// example strings:
// one denomination "(((Denomination/any(t: t ge 20 and t lt 50))) and (not (BlacklistedStates/any(t: t eq 'IL')) and ((OrganizationId eq null) or (OrganizationId eq 'bbe02627-d723-4123-2934-08da0da75b86'))))"
// all denominations: "(((Denomination/any(t: t lt 20)) or (Denomination/any(t: t ge 250 and t lt 500)) or (Denomination/any(t: t ge 20 and t lt 50)) or (Denomination/any(t: t gt 500)) or (Denomination/any(t: t ge 50 and t lt 200)) or (Denomination/any(t: t ge 200 and t lt 250))) and (not (BlacklistedStates/any(t: t eq 'IL')) and ((OrganizationId eq null) or (OrganizationId eq 'bbe02627-d723-4123-2934-08da0da75b86'))))"
export function getPriceFilters(filters = ''): string[] | null {
    const denominationRanges = filters.match(
        /Denomination\/any\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/g
    );

    if (!denominationRanges) {
        return null;
    }

    return denominationRanges.map((range) => {
        // use regex number lookup to get the low value and the high numerical value from the string
        const [firstNumber, secondNumber] = range.match(/\d+/g) || [];
        if (firstNumber && !secondNumber) {
            // if the expression is 'lt' then we need a less than range, otherwise a gt range.
            // that range is expressed with inf or -inf in constructor
            // we need to do a regex lookup to get the gt or lt:
            const [ltOrGt] = range.match(/(lt|gt)/g) || [];
            if (ltOrGt === 'lt') {
                return `0-${firstNumber}`;
            } else {
                return `${firstNumber}-inf`;
            }
        } else {
            return `${firstNumber}-${secondNumber}`;
        }
    });
}

export function getIsFeaturedFilters(filters = ''): string[] | null {
    return filters.match(/IsFeatured eq '(.+?)'/g) || null;
}

export function getFirstLetterFilter(searchTerm: string): string[] | null {
    if (searchTerm === '/[^a-z]/') {
        return ['#'];
    } else if (/^([a-zA-Z#])\*$/.test(searchTerm)) {
        return [`${searchTerm[0].toUpperCase()}`];
    } else {
        return null;
    }
}

export function getFacetsFromConfigObject(config: any) {
    return {
        SupportedTypes: config.response.facets
            .find((facet: any) => facet.name === 'SupportedTypes')
            .options.map((option: any) => ({ count: option.count, value: option.value })),
        Categories: config.response.groups[0].children.map((option: any) => ({
            count: option.count,
            value: option.group_id,
        })),
        Price: config.response.facets
            .find((facet: any) => facet.name === 'Price')
            .options.map((option: any) => ({
                count: option.count,
                value: option.value,
                ...(!`{${option.range[0]}`.includes('inf') ? { from: option.range[0] } : {}),
                ...(!`{${option.range[1]}`.includes('inf') ? { to: option.range[1] } : {}),
            }))
            // remove ugly menu option that doesn't do much
            .filter((option: any) => option.value !== '"-inf"-"0"'),
    };
}

export const getSupportedTypes = (facets: ConstructorFacet[] = []) => {
    const supportedTypes = facets.find((f) => f.name === 'SupportedTypes');
    if (supportedTypes) {
        return supportedTypes.values;
    }
    return [];
};

export function getIsFeatured(filters = '') {
    return filters.includes('IsFeatured eq true');
}

export function getIdsFromFilters(filters = ''): string[] | null {
    const regex = /Id, '([^']+)'/;
    const matches = filters.match(regex);

    if (!matches) {
        return null;
    }

    const ids = matches[1].split(',').map((id) => id?.trim() || id);
    return ids;
}

export function getEarningTypesFromFilters(filters = ''): string[] | null {
    if (!filters) {
        return null;
    }

    const earningTypes = [];
    const regex = /EarningsType\/any\(t: t eq '(.+?)'\)/g;
    let match;

    while ((match = regex.exec(filters)) !== null) {
        earningTypes.push(match[1]);
    }

    if (earningTypes.length === 0) {
        return null;
    }

    return earningTypes.map((t) => t.replace(/Shop /g, ''));
}
