import React, { ReactNode, useEffect } from 'react';
import { useRootSelector } from 'common/features/featuresReducer';
import { useDispatch } from 'react-redux';
import {
    getOptionsThunk,
    resetOrganizationOptions,
} from 'common/features/store/duck/organization/duck';
import { useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';
import { Value } from 'firebase/remote-config';
import { UserAuthState, selectUserAuthState } from 'common/features/store/duck/account/duck';
import { LoadingScreen } from 'components/loading-screen/LoadingScreen';
import { useFavorites } from 'common/hooks/useFavorites';

export const AppInit = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch<any>();
    const userId = useRootSelector((r) => r.store.home.homeDetails?.profile?.userId);
    const userAuthState = useRootSelector(selectUserAuthState);
    const optionsLastUpdated = useRootSelector(
        (s) => s.store.organization.optionsAsyncState.lastUpdate
    );
    useFavorites();
    const homeDetailsLastUpdated = useRootSelector(
        (s) => s.store.home.homeDetailsAsyncState.lastUpdate
    );

    const isShipToCoordinatorFeatureEnabled = useFirebaseConfigValues(
        'enable_ship_to_coordinator',
        false,
        (v: Value) => v.asBoolean()
    );

    useEffect(() => {
        if (userId) {
            // NOTE: We need to expose the userId to constructor
            (window as any).userId = userId;

            if (isShipToCoordinatorFeatureEnabled) {
                dispatch(getOptionsThunk());
            } else {
                dispatch(resetOrganizationOptions());
            }
        }
    }, [userId, isShipToCoordinatorFeatureEnabled, dispatch]);

    const hasFetchedOrgOptions = optionsLastUpdated > 0;
    const hasFetchedHomeDetails = homeDetailsLastUpdated > 0;

    if (
        userAuthState === UserAuthState.NOTAUTHENTICATED ||
        (userAuthState === UserAuthState.AUTHENTICATED &&
            hasFetchedOrgOptions &&
            hasFetchedHomeDetails)
    ) {
        return <>{children}</>;
    }

    return <LoadingScreen />;
};
