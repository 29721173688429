import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Mask } from './Mask';

export const MASK_PHONE_US: Mask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
export const MASK_PHONE_US_LAST_4: Mask = [...'(***)***-'.split(''), /\d/, /\d/, /\d/, /\d/];
export const MASK_SHORT_DATE: Mask = [/\d/, /\d/, '/', /\d/, /\d/];
export const MASK_DATE: Mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const VERIFICATION_CODE_MASK: Mask = createNumberMask({
    allowLeadingZeroes: true,
    prefix: '',
    integerLimit: 6,
    includeThousandsSeparator: false,
});

export const CREDIT_CARD_MASK: Mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export const MASK_CVC: Mask = [/\d/, /\d/, /\d/];

export const MASK_ZIP_CODE_SHORT: Mask = [/\d/, /\d/, /\d/, /\d/, /\d/];
