import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ImageUploader, ImageUploaderProps } from '../../components/VoidedCheckUploader';
import { Modal } from 'components/modal/Modal';
import { FormBtn } from 'components/form-btn';
import {
    selectCheckUploadAsyncState,
    uploadCheck,
    CheckUploadErrorState,
    setCheckUploadAsyncState,
    updateEnrollmentLog,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { UploadCheckImageState } from 'common/api/crm/organization/enrollment/models';
import { Alert, AlertType } from 'components/alerts/sticky/Alert';
import { FeaturesState } from 'common/features/featuresReducer';
import { useHistory } from 'react-router-dom';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { Routes } from 'routes';

const useCheckUploadAsyncState = (): [boolean, CheckUploadErrorState | null] => {
    const { loading, error } = useSelector(selectCheckUploadAsyncState);
    return [loading, error];
};

const useHandleErrors = (
    errors: CheckUploadErrorState | null,
    setErrorMessage: React.Dispatch<React.SetStateAction<UploadCheckImageState>>
) => {
    useEffect(() => {
        // NOTE: how many times would this get called?
        if (errors) {
            const { message } = errors;
            setErrorMessage({ file: null, error: message || '' });
        }
    }, [errors, setErrorMessage]);
};

export const UploadCheckModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const [state, setState] = useFileUploadState(isOpen);
    const [isUploading, errorUploading] = useCheckUploadAsyncState();
    useHandleErrors(errorUploading, setState);

    const id = useSelector((s: FeaturesState) => s.store.home.homeDetails?.defaultOrganization?.id);
    const orgId = id || '';
    const dispatch = useDispatch<any>();
    const history = useHistory();

    const onDrop = useMemo(
        (): ImageUploaderProps['onDrop'] => (acceptedFiles, fileRejections) => {
            const [acceptedFile] = acceptedFiles;
            if (acceptedFile) {
                setState({ file: acceptedFile, error: null });
                return;
            }
            if (fileRejections.length) {
                setState((s) => ({
                    ...s,
                    error: 'Image must be one of the following file types: .jpeg, .bmp, .gif, .png, .tiff, or .pdf and smaller than 15mb.',
                }));
            }
        },
        [setState]
    );

    const onRemoveFile = () => {
        setState(initialState);
    };

    const uploadFile = async () => {
        if (!state.file) {
            setState((s) => ({ ...s, error: 'Please select an image' }));
            return;
        }

        const noErrorsOccured = await uploadCheck(orgId ?? '', state.file)(dispatch);
        if (!noErrorsOccured) {
            dispatch(
                updateEnrollmentLog({
                    state: ProgressState.CheckImageUploaded,
                    version: 2,
                    organizationId: orgId,
                })
            );
            history.replace(Routes.EnrollV2CompletedInstant);
        }
    };

    return (
        <Modal title="Upload Check" isOpen={isOpen} onClose={onClose}>
            <ImageUploader
                file={state.file}
                accept="image/*, .pdf"
                multiple={false}
                onDrop={onDrop}
            />
            <RenderHelperTextOrError error={state.error} />
            <div className="flex flex-col items-center gap-3">
                <FormBtn isSubmitting={isUploading} onClick={uploadFile} disabled={!state.file}>
                    Upload File
                </FormBtn>
                {state.file && (
                    <button
                        onClick={onRemoveFile}
                        className="underline text-brand font-semibold text-lg"
                    >
                        Remove File{' '}
                    </button>
                )}
            </div>
        </Modal>
    );
};

const RenderHelperTextOrError = ({ error }: { error: string | null }) => {
    if (error) {
        return (
            <Alert type={AlertType.ERROR} className="my-5 sm:mt-0" title="Image Not Supported">
                <p>{error}</p>
            </Alert>
        );
    }

    return (
        <p className="text-grey-1 pb-4 pt-2 text-base">
            Upload a clear image of a check or direct deposit form from your bank.
        </p>
    );
};

const initialState: UploadCheckImageState = {
    file: null,
    error: null,
};

const useFileUploadState = (
    isOpen: boolean
): [UploadCheckImageState, React.Dispatch<React.SetStateAction<UploadCheckImageState>>] => {
    const [state, setState] = useState(initialState);
    const dispatch = useDispatch<any>();

    // NOTE: if user closes the modal we should clear any files that the user might have selected.
    useEffect(() => {
        if (!isOpen) {
            setState(initialState);
            dispatch(setCheckUploadAsyncState({ error: null }));
        }
    }, [isOpen, dispatch]);

    return [state, setState];
};
