import {
    isEmail,
    required,
    isWithoutEmojis,
    minLength,
    maxLength,
    hasLowercase,
    hasUppercase,
    hasNumber,
    containsSpecialChar,
    isNot,
    isSameAs,
    isPhoneNumber,
    isEmpty,
    isZipCode,
    length,
    numbersOnly,
    hasNoSpecialChars,
    doesNotContainSlash,
    doesNotHave2aDomain,
    isZipCodeShort,
} from './validation';

import { withMessage, combineValidators, combineValidationFns, anyOfValidationFns } from './';

export const serverErrorMessage = 'The server could not be reached';
export const generalErrorMessage =
    'Please contact customer service if you are unable to access your account.';
export const verificationCodeError =
    'The verification code you entered was incorrect. ' + 'Please re-enter the code you received.';
export const passwordRequiredMessage = 'The Password field is required';
export const emojiMessage = 'Invalid character(s).';
export const forgotCredentialsRequestError =
    'Unable to send email at this time. Please ensure you have an Internet connection.';
export const twoFactorLockoutError =
    'For security purposes, we’ve temporarily locked ' +
    'your account from requesting another verification code. Please try again later.';

export const usernameRequiredValidator = withMessage('The Username field is required.')(required);
export const passwordRequiredValidator = withMessage(passwordRequiredMessage)(required);
export const emojiValidator = withMessage(emojiMessage)(isWithoutEmojis);

export const isEmailValid = combineValidators(
    withMessage('Please enter a valid email address.')(combineValidationFns(required, isEmail)),
    emojiValidator
);

export const emailValidator = combineValidators(
    withMessage('The Email Address field is required.')(combineValidationFns(required, isEmail)),
    emojiValidator
);

export const sameEmailValidator = (currentEmail: string) =>
    combineValidators(
        emailValidator,
        withMessage('This email is already in use.')(
            (formEmail: string) => currentEmail !== formEmail
        )
    );

export const securityQuestion1Validator = withMessage('The Security Question 1 field is required.')(
    required
);
export const securityQuestion2Validator = withMessage('The Security Question 2 field is required.')(
    required
);

export const securityQuestionAnswerValidator = combineValidators(
    withMessage('The Answer field is required.')(required),
    withMessage('Please limit your answer to 100 characters.')(maxLength(100)),
    emojiValidator
);

export const verificationCodeValidator = combineValidators(
    withMessage('The Verification Code field is required.')(required),
    withMessage('The Verification Code must have 6 characters.')(length(6)),
    withMessage('The Verification Code must contain numbers only.')(numbersOnly),
    emojiValidator
);

export const phoneNumberValidator = combineValidators(
    withMessage('The Phone Number must be valid.')(anyOfValidationFns(isEmpty, isPhoneNumber)),
    emojiValidator
);

export const twoFactorPhoneNumberValidator = combineValidators(
    withMessage('The Phone Number field is required.')(required),
    withMessage('A valid Phone Number is required.')(isPhoneNumber),
    emojiValidator
);

export const orgNameValidator = combineValidators(
    withMessage('The Organization Name field is required.')(required),
    withMessage('The Organization Name field must be at most 75 characters long.')(maxLength(75)),
    emojiValidator
);

export const orgEnrollEmailValidator = combineValidators(
    withMessage('The Email Address field is required.')(required),
    withMessage('A valid Email Address is required.')(isEmail),
    withMessage('Email address cannot have a /.')(doesNotContainSlash),
    withMessage('A valid Email Address is required.')(doesNotHave2aDomain),
    emojiValidator
);

export const orgTypeValidator = withMessage('The Organization Type field is required.')(required);

export const orgEnrollPasswordValidator = combineValidators(
    withMessage('Password must be at least 8 characters.')(minLength(8)),
    withMessage('Password must be at most 200 characters.')(maxLength(200))
);

export const enrollmentCodeValidator = combineValidators(
    withMessage('The Enrollment Code field is required.')(required),
    withMessage('Please enter a valid Enrollment Code.')((value: string) =>
        Boolean(value.match(/^[0-9A-Za-z-]+$/g))
    )
);

export const passwordValidator = (email: string, username: string) =>
    combineValidators(
        passwordRequiredValidator,
        withMessage(passwordRequiredMessage)(
            combineValidationFns(
                minLength(8),
                hasLowercase,
                hasUppercase,
                hasNumber,
                containsSpecialChar
            )
        ),
        withMessage('Password cannot be your email.')(isNot(email)),
        withMessage('Password cannot be your username.')(isNot(username)),
        emojiValidator
    );

export const changePasswordValidator = (email: string, username: string, currentPassword: string) =>
    combineValidators(
        passwordValidator(email, username),
        withMessage('New password cannot be the same as the current password.')(
            isNot(currentPassword)
        )
    );

export const confirmPasswordValidator = (password: string) =>
    combineValidators(
        withMessage('The Confirm Password field is required.')(required),
        withMessage('The password and confirmation password do not match.')(isSameAs(password)),
        emojiValidator
    );

export const firstNameValidator = combineValidators(
    withMessage('The First Name field is required.')(required),
    withMessage('The First Name must be at least 2 characters long.')(minLength(2)),
    withMessage('The First Name must be at most 25 characters long.')(maxLength(25)),
    emojiValidator
);

export const lastNameValidator = combineValidators(
    withMessage('The Last Name field is required.')(required),
    withMessage('The Last Name must be at least 2 characters long.')(minLength(2)),
    withMessage('The Last Name must be at most 50 characters long.')(maxLength(50)),
    emojiValidator
);

export const studentNameValidator = combineValidators<string | null>(
    withMessage('The Student Name must be at most 255 characters long.')(maxLength(255)),
    emojiValidator
);

export const studentNameValidatorRequired = combineValidators<string | null>(
    withMessage("Please enter the Student's Name; this could be an athlete, musician, etc.")(
        required
    ),
    studentNameValidator
);

export const fundraisingGroupValidator = emojiValidator;

export const addressLine1Validator = combineValidators(
    withMessage('The Street field is required.')(required),
    withMessage('The Street field must be at most 200 characters long.')(maxLength(200)),
    emojiValidator
);

export const organizationZipCodeValidator = combineValidators(
    withMessage('The Zip field is required.')(required),
    withMessage('The Zip field must be a valid Zip code.')(isZipCodeShort)
);

export const addressLine2Validator = combineValidators(
    withMessage('The Apt. Suite, etc. field must be at most 255 characters long.')(maxLength(255)),
    emojiValidator
);

export const cityValidator = combineValidators(
    withMessage('The City field is required.')(required),
    withMessage('The City field must be at most 50 characters long.')(maxLength(50)),
    emojiValidator
);

export const stateValidator = combineValidators(
    withMessage('The State field is required.')(required)
);

export const zipCodeValidator = combineValidators(
    withMessage('Zip Code is required.')(required),
    withMessage('Please enter a valid Zip Code.')(isZipCode)
);

export const walletCardBrandValidator = withMessage('You must select a brand.')(required);
export const walletCardNumberValidator = withMessage('You must enter a card number.')(required);
export const walletCardPinValidator = combineValidators(
    withMessage('The PIN number is required.')(required),
    withMessage('The PIN number must be a number.')(numbersOnly)
);
export const walletCardNameValidator = combineValidators(
    withMessage('Card name must be at most 100 characters long.')(maxLength(100)),
    withMessage('Card name can only contain alpha, numeric, and some special characters.')(
        hasNoSpecialChars
    )
);
