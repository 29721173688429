import { useState, useCallback, useRef, useEffect } from 'react';

import { createAsyncTracker, AsyncActionState, ResolveType, initialAsyncActionState } from './core';
import { APIFn, APIResponseType, APIErrorType } from './thunk';

import { APIResponse, APIError } from 'common/api/models';
import { noop } from 'common/utils';
import { asTuple } from 'common/utils/asTuple';

interface State<F extends APIFn> {
    requestState: AsyncActionState<Parameters<F>, APIError<APIErrorType<F>>>;
    result?: APIResponse<APIResponseType<F>, APIErrorType<F>>;
}

export const useApi = <F extends APIFn>(
    asyncFn: F,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialAsyncStateConfig?: Partial<AsyncActionState<any, any>>
) => {
    const isMounted = useRef(true);
    const [state, setState] = useState<State<F>>({
        requestState: { ...initialAsyncActionState, ...initialAsyncStateConfig },
    });
    const handleStateChange = useCallback(() => noop, []);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const requestAction = useCallback(
        async (...args: Parameters<F>) => {
            type T = APIResponseType<F>;
            type E = APIErrorType<F>;
            const { start, done, fail, getState } = createAsyncTracker<F, T, APIError<E>>(
                handleStateChange,
                args
            );
            start();
            if (isMounted.current) {
                setState((previous) => ({ ...previous, requestState: getState() }));
            }
            const result = await asyncFn(...args);
            if (!result.error) {
                done(result.data);
            } else {
                fail(result.error);
            }
            if (isMounted.current) {
                setState({ requestState: getState(), result });
            }
            return result as ResolveType<ReturnType<F>>;
        },
        [asyncFn, handleStateChange]
    );

    return asTuple(requestAction, state.requestState, state.result);
};
