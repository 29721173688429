import { and, or, eq, includesEq } from 'common/utils/searchFilters';
import { toPascalCase } from 'common/utils/strings';
import { BrandsSearchFacet } from 'common/api/search/models/filters/BrandsSearchFacets';

// NOTE: part of the ship to coordinator enable/disable experiment
export const editFilter = (isShipToCoordinatorEnabled?: boolean, filter?: string) => {
    // CASE: ship to coordinator is disabled we should:
    // - hide brands that only have Gift Card as their
    //   supported type and they're not eligible for ship-to-home
    let newFilter = filter;
    if (isShipToCoordinatorEnabled === false) {
        const shipToHomeAndEGiftFilter = or(
            eq(toPascalCase(BrandsSearchFacet.IsShipToHomeEligible), true),
            includesEq('SupportedTypes', 'eGift Card')
        );

        if (filter) {
            newFilter = and(filter, shipToHomeAndEGiftFilter);
        } else {
            newFilter = shipToHomeAndEGiftFilter;
        }
    }
    return newFilter;
};
