import React, { useEffect } from 'react';
import { styles } from './styles';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { Routes } from 'routes';

export const ErrorMessage = () => {
    useEffect(() => {
        setTimeout(() => {
            sendToLoginPage(Routes.Shop);
        }, 2000);
    });

    return (
        <div className="h-full flex justify-center flex-col text-center p-2">
            <div className={styles.header}>Well, it looks like something happened on our end.</div>
            <div className={styles.subHeader}>
                Need Support? We're ready to help if you need any assistance. (800) 727-4715 |
                Option 4
            </div>
        </div>
    );
};
