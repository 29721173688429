import { OrganizationMembership } from '../../models/OrganizationMembership';

import { OrganizationType } from 'common/api/store/models/OrganizationType';
import { Address } from 'common/api/models/Address';
import { Family, emptyFamily } from 'common/api/crm/models/Family';

export interface SelectedOrganization {
    id: string;
    name: string;
    type: OrganizationType;
    address?: Address;
    isActive: boolean;
    isApproved: boolean;
    isCoordinator: boolean;
    approvalDate: string;
    isScripWareOrg?: boolean;
    isP2PInviteEnabled?: boolean;
}

export const emptyOrg = {
    id: '',
    name: '',
    type: OrganizationType.Miscellaneous,
    isActive: false,
    isApproved: true,
    isCoordinator: false,
    approvalDate: '',
};
export interface HomeDetailsResponse {
    profile: Family;
    selectedOrgId: string | null;
    defaultOrganization: SelectedOrganization | null;
    organizations: Record<string, OrganizationMembership>;
}

export const emptyHomeDetailsResponse: HomeDetailsResponse = {
    profile: emptyFamily,
    selectedOrgId: null,
    defaultOrganization: emptyOrg,
    organizations: {},
};
