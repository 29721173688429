import React, { useEffect, useState, Fragment } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import classNames from 'classnames';

import { createStyles } from 'utils/createStyle';
import ImageSvg from 'media/images/Image.svg';
import { colors } from 'styles/settings';
import { getFocusOutline } from 'styles/accessibility';

export interface ImageUploaderProps extends DropzoneOptions {
    file?: File | null;
    error?: string | null;
}

const styles = createStyles({
    root: [
        'rounded-md cursor-pointer border border-brand-dark h-28 p-2 flex items-center justify-center',
        getFocusOutline({ borderRadius: 8, offset: 6 }),
    ],
    rootActive: {
        borderColor: colors.brandHighlight,
        boxShadow: `0 0 0 1px ${colors.brandHighlight}, inset 0 0 0 1px ${colors.brandHighlight}`,
    },
    preview: 'bg-contain bg-no-repeat bg-center w-full h-full',
    cta: 'ml-2 text-grey-2 text-sm w-44',
});

const cta = 'Drag image here or click to open upload browser.';

export const ImageUploader = (props: ImageUploaderProps) => {
    const { file } = props;
    const { getRootProps, getInputProps, isDragActive } = useDropzone(props);
    const [filePreview, setFilePreview] = useState<string>();

    useEffect(() => {
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => e.target && setFilePreview(e.target.result as string);
        reader.readAsDataURL(file);
    }, [file]);

    const renderPreview = () => {
        if (!file) {
            return;
        }
        if (file.type.startsWith('application/pdf')) {
            return <iframe src={filePreview} className={styles.preview} />;
        }
        return (
            <div className={styles.preview} style={{ backgroundImage: `url(${filePreview})` }} />
        );
    };

    return (
        <Fragment>
            <div
                {...getRootProps()}
                className={classNames(styles.root, isDragActive && styles.rootActive)}
                aria-label={`${file ? 'Image preview' : cta} ${props.error || ''}`}
            >
                <input id="imageInput" {...getInputProps()} />
                {!file ? (
                    <Fragment>
                        <img src={ImageSvg} alt="" />
                        <div className={styles.cta}>{cta}</div>
                    </Fragment>
                ) : (
                    renderPreview()
                )}
            </div>
            {props.error && (
                <span className="text-error" aria-live="polite">
                    {props.error}
                </span>
            )}
        </Fragment>
    );
};
