import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { getApp } from '@firebase/app';

import { useApi } from 'common/modules/async-actions/useApi';
import { initFirebase } from 'config/firebase';
import { RemoteConfigWeb } from 'config/RemoteConfigWeb';
import { APIFn } from 'common/modules/async-actions/thunk';
import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { EnrollmentContentType } from '../types';
import { FamilyEnrollment } from 'common/api/contentful/models/Familyenrollment';
import {
    OrganizationEnrollment,
    OrganizationEnrollmentV2,
} from 'common/api/contentful/models/OrganizationEnrollment';

const useContentfulEnrollment = <ContentfulEntry,>(
    version: string,
    firebaseKey: keyof typeof defaultConfigKeys,
    fetchContentfulContent: APIFn<ContentfulEntry>,
    contentType: string
): [ContentfulEntry | undefined, boolean, boolean] => {
    const [dictEnrollmentVersionContentful, setDictEnrollmentVersionContentful] = useState<{
        [key: string]: string;
    }>({});
    const [isLoadingFromFirebase, setIsLoadingFromFirebase] = useState(true);

    useEffect(() => {
        const remoteConfigCall = async () => {
            try {
                if (getApp()) {
                    initFirebase();
                    await RemoteConfigWeb.refresh();
                    const configs = RemoteConfigWeb.getRemoteValue(firebaseKey).asString();
                    setDictEnrollmentVersionContentful(configs ? JSON.parse(configs) : {});
                }
            } catch {
                setIsLoadingFromFirebase(false);
            }
        };

        remoteConfigCall();
    }, [firebaseKey]);

    const [loadContent, loadContentState, loadContentResult] = useApi(fetchContentfulContent);

    useEffect(() => {
        if (
            version &&
            dictEnrollmentVersionContentful &&
            version in dictEnrollmentVersionContentful
        ) {
            const versionId = dictEnrollmentVersionContentful[version];
            loadContent(contentType, versionId).finally(() => {
                setIsLoadingFromFirebase(false);
            });
        }
    }, [dictEnrollmentVersionContentful, version, loadContent, contentType]);

    return [
        loadContentResult?.data || undefined,
        isLoadingFromFirebase || loadContentState.loading,
        Boolean(loadContentState.error),
    ];
};

const emptyOrgEnrollment: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentfulEntry?: FamilyEnrollment | OrganizationEnrollment | OrganizationEnrollmentV2;
    isLoading: boolean;
    hasFailedLoading: boolean;
} = {
    contentfulEntry: undefined,
    isLoading: false,
    hasFailedLoading: false,
};

type EnrollmentsType = FamilyEnrollment | OrganizationEnrollment | OrganizationEnrollmentV2;
export const ContentFullEnrollmentContext = createContext(emptyOrgEnrollment);

export const ContentFullEnrollmentProvider = <ContentfulEntry extends EnrollmentsType>({
    enrollmentVersion,
    children,
    firebaseKey,
    fetchContentfulContent,
    contentType = EnrollmentContentType.ORG_ENROLLMENT,
}: {
    enrollmentVersion: string;
    children: ReactNode;
    firebaseKey: keyof typeof defaultConfigKeys;
    fetchContentfulContent: APIFn;
    contentType?: EnrollmentContentType;
}) => {
    const [contentfulEntry, isLoading, hasFailedLoading] = useContentfulEnrollment<ContentfulEntry>(
        enrollmentVersion,
        firebaseKey,
        fetchContentfulContent,
        contentType
    );

    return (
        <ContentFullEnrollmentContext.Provider
            value={{ contentfulEntry, isLoading, hasFailedLoading }}
        >
            {children}
        </ContentFullEnrollmentContext.Provider>
    );
};
