import { PasswordPolicy } from '../../models/PasswordPolicy';

import { apiFetch } from 'common/api/utils';
import { USERS_URL } from 'common/api/config';

export const getPasswordPolicy = async () => {
    const res = await apiFetch<PasswordPolicy>(
        `${USERS_URL}/Account/password/policy?api-version=1`
    );
    return res;
};
