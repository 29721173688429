import React, { useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import MaskedInput from 'react-text-mask';

import { StepWrapper, StepWrapperProps } from './StepWrapper';

import { Btn } from 'components/btn/Btn';
import { FormikTextField } from 'components/fields/FormikTextField';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import {
    orgEnrollEmailValidator,
    firstNameValidator,
    lastNameValidator,
    orgEnrollPasswordValidator,
    twoFactorPhoneNumberValidator,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { TermsAndConditions } from 'components/terms-and-conditions/TermsAndConditions';
import { RegistrationRequestWithoutOrg } from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { Routes } from 'routes';
import { useValidation } from 'modules/form-validation/useValidation';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { validateEmail } from 'common/api/users/email/validate';
import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { FormikSetPasswordField } from 'components/fields/FormikSetPasswordField';
import { useContentfulGlobalContent } from 'common/api/contentful/global';
import { useAppConfig } from 'common/features/app-config/utils/useAppConfig';
import { UserContent } from 'components/user-content/UserContent';
import { noop } from 'common/utils';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { useAlert } from 'modules/alerts';

const RegistrationRequestField = typedFormikField<RegistrationRequestWithoutOrg>()(FormikTextField);
const RegistrationRequestSetPasswordField =
    typedFormikField<RegistrationRequestWithoutOrg>()(FormikSetPasswordField);

interface YourAccountStepProps extends StepWrapperProps {
    registrationRequest: RegistrationRequestWithoutOrg;
    loadDataForNextScreen: () => void;
    onSubmit: (values: RegistrationRequestWithoutOrg) => void;
}

export const YourAccountStep = (props: YourAccountStepProps) => {
    const { onSubmit, loadDataForNextScreen } = props;
    const loader = useRef<LoadingOverlay>(null);
    const history = useHistory();
    const alert = useAlert();

    const _onSubmit = useCallback(
        async (values: RegistrationRequestWithoutOrg) => {
            openLoader(loader.current);
            const res = await validateEmail(values.email);

            await closeLoader(loader.current);

            if (hasErrorWithStatus(res, StatusCode.Conflict)) {
                alert({
                    message: 'Looks like this email is already associated with an account',
                    title: 'Would you like to log in?',
                    buttons: [
                        {
                            text: 'Yes!',
                            onClick: () => sendToLoginPage('/start-program/continue'),
                        },
                        {
                            text: 'No',
                            onClick: noop,
                        },
                    ],
                });
                return;
            }
            onSubmit(values);

            // NOTE: we must __save__ the user phonenumber in session-storage
            // in order for us to pre-fill the 2FA phone number
            sessionStorage.setItem('phone', values.phoneNumber);

            history.replace(Routes.StartProgramSecurity);
        },
        [alert, onSubmit, history]
    );

    useEffect(() => {
        loadDataForNextScreen();
    }, [loadDataForNextScreen]);

    const validateAndSubmit = useValidation<RegistrationRequestWithoutOrg>();
    const globalContent = useContentfulGlobalContent();
    const { appConfig } = useAppConfig();

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <Formik initialValues={props.registrationRequest} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-sm mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <FieldSpacer />
                        <RegistrationRequestField
                            name="firstName"
                            label="First Name"
                            validate={firstNameValidator}
                        />
                        <FieldSpacer />
                        <RegistrationRequestField
                            name="lastName"
                            label="Last Name"
                            validate={lastNameValidator}
                        />
                        <FieldSpacer />
                        <MaskedInput
                            mask={MASK_PHONE_US}
                            guide={false}
                            render={(ref, _props) => (
                                <RegistrationRequestField
                                    {..._props}
                                    inputRef={ref}
                                    type="tel"
                                    name="phoneNumber"
                                    label="Phone Number"
                                    validate={twoFactorPhoneNumberValidator}
                                />
                            )}
                        />
                        <FieldSpacer />
                        <RegistrationRequestField
                            type="email"
                            name="email"
                            label="Email"
                            validate={orgEnrollEmailValidator}
                        />
                        <FieldSpacer />
                        <RegistrationRequestSetPasswordField
                            name="password"
                            label="Password (8+ characters)"
                            maxLength={200}
                            validate={orgEnrollPasswordValidator}
                            minAcceptableScore={appConfig.passwordPolicy.minimumZxcvbnScore}
                        />
                        {globalContent && (
                            <div className="text-xs text-grey-1 mt-3">
                                <UserContent content={globalContent.setPasswordTooltip} />
                            </div>
                        )}
                        <FieldSpacer />

                        <div className="text-center">
                            <Btn type="submit">Next: Account Security</Btn>
                        </div>
                    </form>
                )}
            </Formik>
            <TermsAndConditions {...globalContent} />
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
