import { css } from 'glamor';

import { linkStyles } from './typography';

css.global('a', linkStyles);

// Radix dialogs use portals with absurdly high z-indexes which cover up ChromeVox's focus indicator.
css.global('[data-radix-portal]', {
    zIndex: '100000 !important',
});
