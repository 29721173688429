import React from 'react';
import classnames from 'classnames';

export interface BaseAlertProps {
    title: React.ReactNode;
    children: React.ReactNode;
    className?: string;
}

export const BaseAlert = ({ title, children, className = '' }: BaseAlertProps) => {
    return (
        <div className={classnames('border rounded-xl border-l-4 p-5 shadow-md', className)}>
            <Title>{title}</Title>
            <Content>{children}</Content>
        </div>
    );
};

const Title = ({ children }: React.PropsWithChildren) => {
    return <div className="mb-2 font-semibold text-base sm:text-lg">{children}</div>;
};

const Content = ({ children }: React.PropsWithChildren) => {
    return <div className="text-xs text-grey-2 font-semibold">{children}</div>;
};
