import React, { useCallback } from 'react';

import { GroupBase } from 'react-select/dist/declarations/src';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';

export const hasOnlySuggestions = (term: string, options: Brand[]) => {
    const termLowerCase = (term ?? '').toString().trim().toLowerCase();

    return (
        options.length > 0 &&
        options.every(
            (opt) =>
                !opt.id ||
                (opt.suggestions || []).some((s) => s.toLowerCase().includes(termLowerCase))
        )
    );
};

export const useFormatGroupLabel = (term: string) => {
    const formatGroupLabel = useCallback(
        ({ label }: GroupBase<Brand>) => {
            if (label === 'suggestions') {
                return (
                    <div className="text-left text-xs text-grey-1 mb-2 normal-case -ml-2.5">
                        {`Suggested Alternatives for "${(term || '').toString().toLowerCase()}"`}
                    </div>
                );
            }

            return null;
        },
        [term]
    );

    return formatGroupLabel;
};
