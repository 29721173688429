import React, { useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import { ModalStyles } from 'styles/modals';
import { colors } from 'styles/settings';
import { createStyles } from 'utils/createStyle';
import { unAuthMenuItems } from '../SiteHeader';
import { useHistory } from 'react-router-dom';

export interface UnAuthMobileMenuProps {
    mobileMenuOpen: boolean;
    setMobileMenuOpen: (show: boolean) => void;
}

const styles = createStyles({
    menu: [
        {
            transform: 'translateX(100%)', // Initially off-screen to the right
            transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
            maxWidth: '86%',
        },
        'absolute w-0 h-screen bg-white z-10 overflow-hidden',
    ],
    menuOpen: {
        transform: 'translateX(16%)', // Slide in to occupy 86% of the screen
        transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
        width: '86%',
    },
    menuClose: {
        transform: 'translateX(100%)', // Slide out to the right
        transition: 'transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)',
        width: '0%',
    },
    menuTitle: [
        {
            fontSize: '1.5rem',
            lineHeight: '2.18rem',
            fontWeight: '600',
            color: colors.darkBlue,
        },
    ],
    menuBackground: [
        'absolute w-full left-0 right-0 top-0 h-screen bg-black opacity-60',
        { zIndex: 9 },
    ],
    backOption: [
        'block mb-4 no-underline capitalize text-bonus-pink font-semibold hover:underline focus:underline cursor-pointer',
        {
            fontSize: '13px',
            lineHeight: '25px',
        },
    ],
});

type menuItem = {
    id: string;
    label: string;
    url: string;
};

export const UnAuthMobileMenu = ({ mobileMenuOpen, setMobileMenuOpen }: UnAuthMobileMenuProps) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const mainHeaderRef = useRef<HTMLElement | null>(null);

    const history = useHistory();
    const [innerMenu, setInnerMenu] = useState<menuItem[] | []>([]);

    useEffect(() => {
        mainHeaderRef.current = document.getElementById('main-header');
        if (mainHeaderRef.current && menuRef.current) {
            const headerRect = mainHeaderRef.current.getBoundingClientRect();
            menuRef.current.style.top = `${headerRect.top}px`;
        }

        // CASE: Reset the innerMenu when the main menu closes
        // this ensures that if you were to close the main menu while you were showing an inner menu
        // that it resets back to the main menu.
        if (!mobileMenuOpen) {
            setInnerMenu([]);
        }
    }, [mobileMenuOpen]);

    return (
        <>
            <div
                ref={menuRef}
                className={`${styles.menu} ${mobileMenuOpen ? styles.menuOpen : styles.menuClose}`}
            >
                <div className="left-0 bg-white pt-5 px-7 sm:max-w-sm relative">
                    <div className="flex justify-between mb-5">
                        <span className={styles.menuTitle}>Menu</span>
                        <button
                            className={`${ModalStyles.closeButton} "absolute top-[1.25rem] right-[1.25rem]`}
                            onClick={() => setMobileMenuOpen(false)}
                            aria-label="Close"
                        >
                            <X color={colors.brand} size={28} />
                        </button>
                    </div>
                    <div className="mt-[3.125rem] flex flex-col">
                        {innerMenu.length > 0 && (
                            <div key={'back'} className="py-[0.6875rem]">
                                <a className={styles.backOption} onClick={() => setInnerMenu([])}>
                                    <div className="flex items-center">
                                        <div className="mr-2.5">Back</div>
                                    </div>
                                </a>
                            </div>
                        )}
                        {innerMenu.length > 0
                            ? innerMenu.map((item) => (
                                  <div key={item.id} className="py-[0.6875rem]">
                                      <a
                                          className={`block mb-4 no-underline capitalize text-brand ${'font-semibold'} text-base hover:underline focus:underline cursor-pointer`}
                                          href={item.url}
                                      >
                                          <div className="flex items-center">
                                              <div className="mr-2.5">{item.label}</div>
                                          </div>
                                      </a>
                                  </div>
                              ))
                            : unAuthMenuItems.map((item) => (
                                  <div key={item.id} className="py-[0.6875rem]">
                                      {item.menuItems && item.menuItems.length > 0 ? (
                                          <a
                                              className={`block mb-4 no-underline capitalize text-brand ${'font-semibold'} text-base hover:underline focus:underline cursor-pointer`}
                                              onClick={() => setInnerMenu(item.menuItems)}
                                          >
                                              <div className="flex items-center">
                                                  <div className="mr-2.5">{item.label}</div>
                                                  <svg
                                                      width="9"
                                                      height="15"
                                                      viewBox="0 0 9 15"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                      <path
                                                          d="M1.5 14.5C1.21875 14.5 0.96875 14.4062 0.78125 14.2188C0.375 13.8438 0.375 13.1875 0.78125 12.8125L6.0625 7.5L0.78125 2.21875C0.375 1.84375 0.375 1.1875 0.78125 0.8125C1.15625 0.40625 1.8125 0.40625 2.1875 0.8125L8.1875 6.8125C8.59375 7.1875 8.59375 7.84375 8.1875 8.21875L2.1875 14.2188C2 14.4062 1.75 14.5 1.5 14.5Z"
                                                          fill="#E6126A"
                                                      />
                                                  </svg>
                                              </div>
                                          </a>
                                      ) : (
                                          <a
                                              className={`block mb-4 no-underline capitalize text-brand ${'font-semibold'} text-base hover:underline focus:underline cursor-pointer`}
                                              href={item.url}
                                          >
                                              <div className="flex items-center">
                                                  <div className="mr-2.5">{item.label}</div>
                                              </div>
                                          </a>
                                      )}
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
            <div
                className={`${mobileMenuOpen ? 'flex' : 'hidden'} ${styles.menuBackground}`}
                onClick={() => setMobileMenuOpen(false)}
            ></div>
        </>
    );
};
