import React, { useMemo, useRef, useState } from 'react';
import * as RuiDropdownMenu from '@radix-ui/react-dropdown-menu';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createStyles } from 'utils/createStyle';

interface Props<T> {
    items: T[];
    onChange: (itemClicked: T) => void;
    value: string;
    maxItems?: number;
    placeholder?: string;
    className?: string;
    dataRecordingSensitive?: boolean;
}

const styles = createStyles({
    listItemStyle: [
        {
            lineHeight: '40px',
        },
        'block px-4 no-underline capitalize text-brand-dark text-sm hover:bg-pale-blue focus:bg-pale-blue focus:outline-none cursor-pointer',
    ],
    listItemsStyle: [
        {
            background: '#F7F7F7',
        },
    ],
    itemStyle: [
        {
            lineHeight: '27px',
            minWidth: '10rem',
        },
        'flex text-left border border-1 px-2 text-grey-3',
    ],
});

const ITEM_HEIGHT = 40;

export const Select = <T extends { text: string; id: string }>({
    items,
    onChange,
    value,
    maxItems = 10,
    placeholder,
    className,
    dataRecordingSensitive,
}: Props<T>) => {
    const selectedItem = useMemo(() => items.find((it) => it.id === value), [items, value]);

    const [isOpen, setIsOpen] = useState(false);
    const [width, setWidth] = useState(0);

    const ref = useRef<HTMLButtonElement>(null);

    const adjustWidth = () => {
        if (!ref.current) {
            return;
        }
        setWidth(Math.round(ref.current.getBoundingClientRect().width));
    };

    const onOpenChange = (state: boolean) => {
        adjustWidth();
        setIsOpen(state);
    };

    const scrollMaxHeight = useMemo(() => `${Math.max(maxItems, 1) * ITEM_HEIGHT}px`, [maxItems]);

    return (
        <div>
            <RuiDropdownMenu.Root modal={false} open={isOpen} onOpenChange={onOpenChange}>
                <RuiDropdownMenu.Trigger className="inline-block w-full" ref={ref}>
                    <div className={`${styles.itemStyle} ${className}`}>
                        <span className="mr-8">
                            {selectedItem && (
                                <span
                                    className="text-black"
                                    data-recording-sensitive={dataRecordingSensitive}
                                >
                                    {selectedItem.text}
                                </span>
                            )}
                            {!selectedItem && <span>{placeholder}</span>}
                        </span>
                        <span className="ml-auto">
                            {!isOpen && <FontAwesomeIcon icon={faChevronDown} />}
                            {isOpen && <FontAwesomeIcon icon={faChevronUp} />}
                        </span>
                    </div>
                </RuiDropdownMenu.Trigger>
                <RuiDropdownMenu.Portal>
                    <RuiDropdownMenu.Content className="bg-white shadow-xl w-full" align="start">
                        <div
                            className={styles.listItemsStyle}
                            style={{
                                minWidth: `${width}px`,
                                maxHeight: scrollMaxHeight,
                                overflow: 'auto',
                            }}
                        >
                            {items.map((item: T, idx: number) => (
                                <RuiDropdownMenu.Item
                                    key={idx}
                                    className={styles.listItemStyle}
                                    onSelect={() => onChange(item)}
                                >
                                    {item.text}
                                </RuiDropdownMenu.Item>
                            ))}
                        </div>
                    </RuiDropdownMenu.Content>
                </RuiDropdownMenu.Portal>
            </RuiDropdownMenu.Root>
        </div>
    );
};
