import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';

import { Routes } from 'routes';
import { getUserOrganizationMemberships } from 'common/api/crm/family/service';
import { getOrganizationDetail } from 'common/api/crm/organization/service';
import { OrganizationDetail } from 'common/api/crm/models/OrganizationDetail';
import { last, isDefined } from 'common/utils';
import { getOrganizationEnrollmentLog } from 'common/api/crm/organization/enrollment';
import { AchVerificationStatus, OrgAchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { getOrgAchAccount } from 'common/api/e-comm/ach';
import { useAlert } from 'modules/alerts';
import { OrganizationMembership } from 'common/api/crm/models/OrganizationMembership';
import { OrganizationEnrollmentLogEntry } from 'common/api/crm/models/OrganizationEnrollmentLogEntry';
import { ENROLLMENT_VERSION_1 } from '../constants';
import { getPhonesByUser } from 'common/api/users/twofactor/phone';
import { getUrlToLoginPage } from 'features/auth/components/withAuth';

export interface OrganizationEnrollmentProgress {
    hasExistingEnrollment: boolean;
    pendingOrg: OrganizationDetail | null;
    memberships: OrganizationMembership[];
    achAccount: OrgAchAccount | null;
    route: string;
    version: number;
}

const sortByDateAsc = <T>(arr: T[], getDate: (t: T) => string | null): T[] => {
    const getDateValue = (d: string | null) => (d ? Date.parse(d) : -Infinity);
    return [...arr].sort((a, b) => getDateValue(getDate(a)) - getDateValue(getDate(b)));
};

interface TransitionFnReturnType {
    route: string;
    params: { [key: string]: string | number | boolean | undefined };
}

interface TransitionStepFnArgs {
    version: string;
    currentStep: OrganizationEnrollmentLogEntry;
    memberships: OrganizationMembership[];
    achAccount: OrgAchAccount | null;
}

interface Transitions {
    [key: string]:
        | ((_: TransitionStepFnArgs) => Promise<TransitionFnReturnType>)
        | TransitionFnReturnType;
}

const accountLinkedWithPlaidTransitionFn =
    (route: string) =>
    async ({ achAccount }: TransitionStepFnArgs) => {
        // We need to check the ach status, we set the the `deposits`
        // path param when the user needs to manually verify their
        // plaid account. This will expose a plaid button on the
        // `completed` screen in order for the user to complete their plaid integration
        const status =
            achAccount?.verificationStatus === AchVerificationStatus.PendingManualVerification
                ? 'deposits'
                : undefined;
        return {
            route,
            params: { status },
        };
    };

const transitionFromUserCreated =
    () =>
    async ({ currentStep }: TransitionStepFnArgs) => {
        /* Case: User has bailed from org-enrollment flow and came back to enrollment.
         *
         * Pre-Conditions:
         * - User started org-enrollment and bailed after account creation(after security-questions)
         * - User was forced by Identity Server to setup their 2FA when they
         *   log in(Enrollment Log won't be updated)
         * - User gets authenticated after setting up their 2FA in Identity Server and redirected
         *   back to the site.
         * - The UserAuthBff will catch that the User isn't related to an organization and will
         *   redirect that user back to the org-enrollment-flow(start-program/continue)
         *
         * As Result: We cannot trust our org-enrollment-log and therefore __must__ check if user
         * has a 2FA phone setup. If they do then we must intercept the continue logic and redirect
         * them to Org creation step(StartProgramOrganization).
         */
        const userAlreadyHasSetup2FA = await checkIfUserHas2FASetup(currentStep.userId);
        if (userAlreadyHasSetup2FA) {
            return {
                route: Routes.StartProgramOrganization,
                params: {},
            };
        }
        return { route: Routes.StartProgramTwoFactor, params: {} };
    };

const transitionsV1: Transitions = {
    UserCreated: transitionFromUserCreated(),
    TwoFactorSetup: { route: Routes.StartProgramOrganization, params: {} },
    OrgCreated: { route: Routes.StartProgramOrganization2, params: {} },
    OrgUpdated: { route: Routes.StartProgramEarnings, params: {} },
    VoidedCheckSelected: { route: Routes.StartProgramVoidedCheck, params: {} },
    CheckImageUploaded: { route: Routes.StartProgramCompleted, params: {} },
    PaperOnlySelected: { route: Routes.StartProgramCompleted, params: { status: 'paper-only' } },
    AccountLinkedWithPlaid: accountLinkedWithPlaidTransitionFn(Routes.StartProgramCompleted),
    EnrollmentStartedExistingAccount: { route: Routes.StartProgramOrganization, params: {} },
};

const transitionsV2: Transitions = {
    UserCreated: { route: Routes.EnrollSetupRecoveryPhone, params: {} },
    TwoFactorSetup: { route: Routes.EnrollOrgName, params: {} },
    OrgCreated: { route: Routes.EnrollChooseYourPlan, params: {} },
    LiteProgramSelected: { route: Routes.EnrollChooseEarningTypeLite, params: {} },
    PlusProgramSelected: { route: Routes.EnrollChooseEarningTypePlus, params: {} },
    SwitchToLiteProgram: { route: Routes.EnrollLiteProgramInfo, params: {} },
    PayByCheckSelected: { route: Routes.EnrollCompleted, params: {} },
    PaperOnlySelected: { route: Routes.EnrollCompleted, params: { status: 'paper-only' } },
    VoidedCheckSelected: { route: Routes.EnrollSendVoidedCheck, params: {} },
    CheckImageUploaded: { route: Routes.EnrollCompleted, params: {} },
    AccountLinkedWithPlaid: accountLinkedWithPlaidTransitionFn(Routes.EnrollCompleted),
    EnrollmentStartedExistingAccount: { route: Routes.EnrollOrgName, params: {} },
};

const transitionVersions: { [key: string]: Transitions } = {
    v1: transitionsV1,
    v2: transitionsV2,
};

// NOTE: extend parameters if transitions need more data
const transition = async (
    version: string,
    currentStep: OrganizationEnrollmentLogEntry,
    memberships: OrganizationMembership[],
    achAccount: OrgAchAccount | null
) => {
    const transitions = transitionVersions[version];
    const nextStepData = transitions[currentStep.state];
    let nextStep;
    // check if we're dealing w/ a function
    if (typeof nextStepData === 'function') {
        nextStep = await nextStepData({ memberships, achAccount, currentStep, version });
    } else {
        nextStep = nextStepData;
    }

    if (nextStep) {
        const { route, params } = nextStep;
        return generatePath(route, params);
    }
};

const checkIfUserHas2FASetup = async (userId: string) => {
    const phones = (await getPhonesByUser(userId)).data;
    return phones && phones?.length > 0;
};

export const getEnrollmentProgressFunction = async (): Promise<OrganizationEnrollmentProgress> => {
    const [enrollmentLog, memberships] = await Promise.all([
        getOrganizationEnrollmentLog().then((res) =>
            sortByDateAsc(res.data || [], (x) => x.updateDate)
        ),
        getUserOrganizationMemberships().then((res) => res.data || []),
    ]);
    const latestLogEntry = last(enrollmentLog);

    const getPendingOrgData = async () => {
        // Enrollment V2, we want to stop code execution here for V2 enrollment
        // continue logic
        if (latestLogEntry?.version === 2) {
            return { pendingOrg: null, achAccount: null };
        }
        const pendingOrgId =
            latestLogEntry?.organizationId ||
            memberships.find((m) => m.isCoordinator && !m.organization.isApproved)?.organization.id;
        const organizationDetail = pendingOrgId
            ? (await getOrganizationDetail(pendingOrgId)).data
            : null;
        if (organizationDetail) {
            const achAccount = (await getOrgAchAccount(organizationDetail.id)).data || null;
            const isPreviousEnrollmentComplete =
                organizationDetail.isApproved &&
                (!achAccount || achAccount.verificationStatus === AchVerificationStatus.Verified);
            if (!isPreviousEnrollmentComplete) {
                return { pendingOrg: organizationDetail, achAccount };
            }
        }
        return { pendingOrg: null, achAccount: null };
    };

    const { pendingOrg, achAccount } = await getPendingOrgData();

    const getEnrollmentRoute = async () => {
        // when enrollment is empty then re-route back to start-program
        if (enrollmentLog.length === 0) {
            return {
                // NOTE: we set to version 1 as we only support a single version of the enrollment
                // flow for now. If we ever have more then this will need to change.
                version: ENROLLMENT_VERSION_1,
                route: Routes.StartProgramContinue,
            };
        }

        const lastStep = enrollmentLog[enrollmentLog.length - 1];
        const versionString = `v${lastStep.version}`;

        return {
            route: await transition(versionString, lastStep, memberships, achAccount),
            version: lastStep.version,
        };
    };

    const { route, version } = await getEnrollmentRoute();
    return {
        hasExistingEnrollment:
            isDefined(pendingOrg) || (latestLogEntry != null && !latestLogEntry.organizationId),
        route: route || '',
        pendingOrg,
        achAccount,
        memberships,
        version,
    };
};

export const useContinueOrgEnrollment = () => {
    const alert = useAlert();

    const getEnrollmentProgress = useCallback(async () => {
        const enrollmentProgress = await getEnrollmentProgressFunction();
        return enrollmentProgress;
    }, []);

    const getContinueAlert = useCallback(
        () =>
            new Promise((resolve) =>
                alert({
                    title: 'You are almost done creating your program',
                    message:
                        'Let’s put you back where you left off so you can finish and start fundraising.',
                    buttons: [{ text: 'Continue' }],
                    onClose: resolve as () => void,
                })
            ),
        [alert]
    );

    const getMemberToShopAlert = useCallback(
        () =>
            new Promise((resolve) =>
                alert({
                    message: 'Looks like you already have an account.  Are you looking to shop?',
                    buttons: [
                        {
                            text: 'Log in to RaiseRight',
                            className: 'w-full',
                            onClick: async () => {
                                location.assign(getUrlToLoginPage(Routes.Shop));
                            },
                        },
                        {
                            text: 'Continue to create a new organization',
                            onClick: resolve,
                        },
                    ],
                })
            ),
        [alert]
    );

    const getOrgApprovedAlertIfNeeded = useCallback(
        async (progress: OrganizationEnrollmentProgress) => {
            const { memberships, pendingOrg, hasExistingEnrollment } = progress;
            const membershipsDesc = sortByDateAsc(
                memberships,
                (m) => m.organization.approvalDate
            ).reverse();
            const isOrgMember = Boolean(
                membershipsDesc.find((m) => m.isActive && m.organization.isApproved)
            );
            const lastApprovedOrg = membershipsDesc.find((m) => {
                return m.isActive && m.isCoordinator && m.organization.isApproved;
            })?.organization;

            const newOrgEnrollment = window.location.search.includes('newOrgEnrollment');

            if (!hasExistingEnrollment && isOrgMember && !newOrgEnrollment) {
                return getMemberToShopAlert();
            }

            if (!lastApprovedOrg || pendingOrg || newOrgEnrollment) {
                return null;
            }

            return new Promise((resolve) =>
                alert({
                    message: `Your Organization ${lastApprovedOrg.name} is approved.`,
                    buttons: [
                        {
                            text: 'View it',
                            className: 'w-full',
                            onClick: async () => {
                                location.assign(getUrlToLoginPage(Routes.Shop));
                            },
                        },
                        {
                            text: 'or start a new program instead',
                            onClick: resolve,
                        },
                    ],
                })
            );
        },
        [alert, getMemberToShopAlert]
    );

    return { getEnrollmentProgress, getContinueAlert, getOrgApprovedAlertIfNeeded };
};
