import React from 'react';
import { useHistory } from 'react-router-dom';

import { StepWrapper, StepWrapperProps } from './StepWrapper';

import { Btn } from 'components/btn/Btn';
import { Routes } from 'routes';

interface GetStartedStepProps extends StepWrapperProps {}

export const GetStartedStep = (props: GetStartedStepProps) => {
    const history = useHistory();

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <div className="mb-6" />
            <div className="mb-8" />
            <div className="flex items-center flex-col">
                <div className="flex items-center flex-col">
                    <Btn onClick={() => history.replace(Routes.StartProgramAccount)}>
                        Start a Free Program
                    </Btn>
                </div>
                <div className="mt-6 text-brand-dark py-6 space-y-3">
                    <h3 className="text-brand-dark outline-none font-light text-2xl sm:text-2xl">
                        After your program is set up, we'll help you to get started:
                    </h3>
                    <ul className="list-disc pl-12">
                        <li>
                            Receive a unique organization enrollment code for your participants to
                            join your program.
                        </li>
                        <li>
                            Have access to several customizable resources (and an email invite tool)
                            to get the word out in your organization.
                        </li>
                    </ul>
                </div>
            </div>
        </StepWrapper>
    );
};
