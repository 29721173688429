import { FeaturesState } from 'common/features/featuresReducer';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const trackPageViewInHubspot = debounce((email: string, location: string) => {
    if (!email) {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

    _hsq.push(['identify', { email }]);
    _hsq.push(['setPath', location]);
    _hsq.push(['trackPageView']);
}, 100);

export const useHubspotTracking = () => {
    const location = useLocation();
    const email1 = useSelector(
        (s: FeaturesState) => s.store.ui.orgEnrollmentV2.userInformation.email
    );
    const email2 = useSelector((s: FeaturesState) => s.store.home.homeDetails?.profile.email) || '';
    const email = email1 || email2;

    useEffect(() => {
        const locationStr = location.pathname + (location.hash || '');
        trackPageViewInHubspot(email, locationStr);
    }, [location, email]);
};
