import { Entry, EntryCollection } from 'contentful';
import { useEffect, useState } from 'react';

import { APIResponse } from '../models';
import { contentfulApi } from '../utils/contentful';

import { QuickTip, QuickTipImage } from './models/Dashboard';
import { InternalLink } from './models/InternalLink';
import { ExternalLink } from './../store/models/ExternalLink';

export interface ContentTypeMap {
    quickTip: QuickTip;
    quickTipImage: QuickTipImage;
    internalLink: InternalLink;
    externalLink: ExternalLink;
}

export const isContentType = <K extends keyof ContentTypeMap>(
    key: K,
    entry: Entry<unknown>
): entry is Entry<ContentTypeMap[K]> => entry.sys.contentType.sys.id === key;

export const loadFromContentful = async <T>(
    contentType: string
): Promise<APIResponse<EntryCollection<T>>> => {
    const result = await contentfulApi.getEntries<T, unknown>({
        content_type: contentType, // eslint-disable-line camelcase
        include: 10,
    });

    return result;
};

export const useFromContentful = <T>(contentType: string) => {
    const [content, setContent] = useState<EntryCollection<T> | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await loadFromContentful<T>(contentType);

                if (response && response.data) {
                    setContent(response.data);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetch();
    }, [contentType]);

    return { content, isLoading };
};
