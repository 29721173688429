import { eq, includesEq, or } from 'common/utils/searchFilters';
import { toPascalCase } from 'common/utils/strings';
import { BrandsSearchFacet } from 'common/api/search/models/filters/BrandsSearchFacets';
import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import { useRootSelector } from 'common/features/featuresReducer';

export const useShipToCoordinatorDisabledFilter = () => {
    const isShipToCoordinatorEnabled = useRootSelector(selectIsShipToCoordinatorEnabled);

    if (isShipToCoordinatorEnabled === false) {
        return or(
            eq(toPascalCase(BrandsSearchFacet.IsShipToHomeEligible), true),
            includesEq('SupportedTypes', 'eGift Card')
        );
    }

    return '';
};
