import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { OrganizationEnrollmentLogEntry } from 'common/api/crm/models/OrganizationEnrollmentLogEntry';
import { AchVerificationStatus, OrgAchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { Routes } from 'routes';

type V2States = Exclude<
    ProgressState,
    | ProgressState.LiteProgramSelected
    | ProgressState.PlusProgramSelected
    | ProgressState.SwitchToLiteProgram
    | ProgressState.AccountLinkedWithPlaid
>;

type EnrollmentV2RouteMap = Record<V2States, Routes>;

const enrollmentV2RouteMap: EnrollmentV2RouteMap = {
    PayByCheckSelected: Routes.EnrollV2, // not handled in V2, default to start
    PaperOnlySelected: Routes.EnrollV2CompletedPaperOnly,
    CheckImageUploaded: Routes.EnrollV2CompletedInstant,
    UserCreated: Routes.EnrollV2, // not handled in V2, default to start
    EnrollmentStartedExistingAccount: Routes.StartProgramOrganization,
    OrgCreated: Routes.EnrollV2SecureAccount,
    TwoFactorSetup: Routes.EnrollV2ChooseProgram,
    VoidedCheckSelected: Routes.EnrollV2ChooseProgram,
    OrgUpdated: Routes.EnrollV2, // not handled in V2, default to start
    SavedForLater: Routes.EnrollV2ChooseProgram,
    PlaidStarted: Routes.EnrollV2ChooseProgram,
    PlaidFailure: Routes.EnrollV2ChooseProgram,
    PlaidSuccess: Routes.EnrollV2CompletedInstant,
    ResetProgramType: Routes.EnrollV2ChooseProgram,
    AccountSetupComplete: Routes.EnrollV2SecureAccount,
    'Organization Created': Routes.EnrollV2SecureAccount,
};

/**
 * The plaid success state requires additional logic depending on the users
 * plaid status. Otherwise we can simply look up the correct route in the
 * route map.
 * @param progress state from the enrollment log
 * @param achAccount
 * @returns Route a default route of Routes.EnrollV2 will be returned if
 * a valid state is not provided
 */
export const getV2ContinueRoute = (
    progress: OrganizationEnrollmentLogEntry,
    achAccount: OrgAchAccount | null
) => {
    if (progress?.state === ProgressState.PlaidSuccess) {
        switch (achAccount?.verificationStatus) {
            case AchVerificationStatus.PendingAutomatedVerification:
                return Routes.EnrollV2CompletedAutomatedDeposits;
                break;

            case AchVerificationStatus.PendingManualVerification:
                return Routes.EnrollV2CompletedMicroDeposits;
                break;

            case AchVerificationStatus.Verified:
                return Routes.EnrollV2CompletedInstant;
                break;

            default:
                return Routes.EnrollV2ChooseProgram;
                break;
        }
    }
    const route = enrollmentV2RouteMap[progress?.state as V2States];

    if (!route) {
        return Routes.EnrollV2;
    }

    return route;
};
