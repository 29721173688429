import { createSelector } from 'reselect';

import { createSlice } from 'common/modules/create-slice';
import {
    AsyncActionState,
    createApiThunk,
    initialAsyncActionState,
} from 'common/modules/async-actions/thunk';
import { CartResponse } from 'common/api/e-comm/cart/lineitem/models/CartItems';
import { getCartItems, editCartLineItem } from 'common/api/e-comm/cart';
import { getIsAvailable, CartLineItem } from 'common/api/e-comm/models/CartLineItem';
import { FeaturesState } from 'common/features/featuresReducer';
import { CategoryData } from 'common/api/categories/models/CategoryData';

export interface CartState {
    cart: CartResponse | null;
    cartAsyncState: AsyncActionState;
    cartTotal: number | null;
    orderReference: string | null;
}

const initialCartState: CartState = {
    cart: null,
    cartAsyncState: initialAsyncActionState,
    cartTotal: null,
    orderReference: null,
};

const { update, reducer } = createSlice(initialCartState, 'CART');
export const cartReducer = reducer;
export const updateCart = update;

export const requestCartItems = createApiThunk(getCartItems, () => (state, result) => {
    return result
        ? update({ cart: result, cartAsyncState: state })
        : update({ cartAsyncState: state });
});

export const editCartLineItemThunk = createApiThunk(editCartLineItem, () => (_, result) => {
    if (result) {
        return update({ cart: result });
    }
    return update({});
});

export const resetCart = () => update(initialCartState);

export const selectUnavailableItems = createSelector(
    (cartState: CartState) => cartState.cart && cartState.cart.lineItems,
    (lineItems) => {
        if (!lineItems) {
            return [];
        }
        return lineItems.filter((lineItem) => {
            return !getIsAvailable(lineItem);
        });
    }
);

export const selectCartNumberGiftLineItems = createSelector(
    (state: FeaturesState) => state.store.cart.cart?.lineItems,
    (lineItems?: CartLineItem[]) => {
        let n = 0;
        if (lineItems) {
            for (let i = 0; i < lineItems.length; i++) {
                if (lineItems[i].giftDetail) {
                    n++;
                }
            }
        }
        return n;
    }
);

export const selectCartHasGiftingItems = createSelector(
    (state: FeaturesState) => state.store.cart.cart?.lineItems,
    (lineItems?: CartLineItem[]) => {
        if (lineItems) {
            for (let i = 0; i < lineItems.length; i++) {
                if (lineItems[i].giftDetail) {
                    return true;
                }
            }
        }
        return false;
    }
);

export const selectCartItems = createSelector(
    (state: FeaturesState) => state.store.cart.cart?.lineItems,
    (lineItems?: CartLineItem[]) => lineItems || []
);

export const selectOrderTotal = createSelector(
    (state: FeaturesState) => state.store.cart.cartTotal,
    (cartTotal) => cartTotal
);

export const selectOrderReference = createSelector(
    (state: FeaturesState) => state.store.cart.orderReference,
    (orderReference) => orderReference
);
