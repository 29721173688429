import React from 'react';

import { NavMenuItem, useNavMenu } from './context';

export interface NavMenuItemsProps {
    options: NavMenuItem[];
}

export const NavMenuItems = ({ options }: NavMenuItemsProps) => {
    const { closeNavMenu } = useNavMenu();

    return (
        <React.Fragment>
            {options.map((item: NavMenuItem) => {
                if (item.type === 'separator') {
                    return <hr key={item.type} className="border-grey-line mb-4" />;
                } else {
                    const onItemClick = () => closeNavMenu(() => item.onSelect && item.onSelect());
                    return (
                        <a
                            key={item.text}
                            className={`block mb-4 no-underline capitalize text-brand ${
                                item.type === 'item' ? 'font-semibold' : ''
                            } text-base hover:underline focus:underline cursor-pointer`}
                            onClick={onItemClick}
                        >
                            <div className="flex">
                                <div className="mr-2.5">{item.text}</div>
                                <div>{item.icon}</div>
                            </div>
                        </a>
                    );
                }
            })}
        </React.Fragment>
    );
};
