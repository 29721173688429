import * as icons from 'components/custom-icons';

export const categoryIcons: { [key: string]: React.ElementType } = {
    'Gifts & Flowers': icons.GiftsAndFlowers,
    Restaurants: icons.Restaurants,
    Grocery: icons.Grocery,
    'Gas & Auto': icons.GasAndAuto,
    'Health & Beauty': icons.HealthAndBeauty,
    'Services & Utilities': icons.ServicesAndUtilities,
    'Home Improvement': icons.HomeImprovement,
    'Clothing & Accessories': icons.ClothingAndAccessories,
    'Department Store': icons.DepartmentStore,
    Entertainment: icons.Entertainment,
    'Sporting Goods & Outdoors': icons.SportingGoodsAndOutdoors,
    'Home Decor': icons.Home,
    Books: icons.Books,
    'Children & Teens': icons.ChildrenAndTeens,
    'Discount Store & Outlets': icons.DiscountStoreAndOutlets,
    Travel: icons.Travel,
    'Online Shopping': icons.OnlineShopping,
    'Tech & Electronics': icons.TechAndOffice,
    'Food & Beverage': icons.FoodAndBeverage,
    'Fast Food': icons.FastFood,
    'Toys & Hobbies': icons.ToysAndHobbies,
    Movies: icons.Movies,
    'Office & School Supplies': icons.OfficeAndSchoolSupplies,
    'School Uniforms': icons.SchoolUniforms,
    'Streaming & Gaming': icons.StreamingAndGaming,
    Music: icons.Music,
    Pets: icons.Pets,
    'Universal Prepaid Cards': icons.PrepaidCreditCards,
    '*Back to School*': icons.TenAndUnder,
    'Specialty Retailer': icons.DepartmentStore,
    '$10 and Under Gift Cards': icons.TenAndUnder,
};
