import { IApplicationInsights } from '@microsoft/applicationinsights-web';

export class ErrorLogger {
    static logError(error: Error, appInsights: IApplicationInsights) {
        appInsights.trackException({ exception: error });
        appInsights.flush();
    }

    static setUserContext(userId: string | null, appInsights: IApplicationInsights) {
        if (!userId) {
            appInsights.clearAuthenticatedUserContext();
        } else {
            appInsights.setAuthenticatedUserContext(userId);
        }
    }
}
