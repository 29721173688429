import keyBy from 'lodash.keyby';
import { Dispatch } from 'redux';
import { createSlice } from 'common/modules/create-slice';
import { PartialRecord } from 'common/definitions/PartialRecord';
import {
    PaymentSubType,
    PaymentType,
    PaymentTypeFee,
    getPaymentTypeFees,
    getPaymentTypesForOrganization,
} from 'common/api/e-comm/payment-types';
import {
    createApiThunk,
    AsyncActionState,
    initialAsyncActionState,
} from 'common/modules/async-actions/thunk';
import { APIError } from 'common/api/models';
import { FeaturesState } from 'common/features/featuresReducer';
import {
    getOptions,
    OrgOptions,
    setOrgVacationModeOptions,
    VacationModeRequestOptions,
} from 'common/api/e-comm/options';
import { OrganizationType } from 'common/api/crm/models/OrganizationType';
import { getOrganizationTypes } from 'common/api/crm/organization/service';
import { deleteOrgAchAccount } from 'common/api/e-comm/ach';
import { OrgAchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { getOrgAchAccount } from 'common/api/e-comm/ach';
import { createSelector } from 'reselect';

export interface OrganizationState {
    paymentTypeFees: PartialRecord<PaymentSubType, PaymentTypeFee> | null;
    paymentTypeFeesAsyncState: AsyncActionState<[], APIError>;
    paymentTypes: PaymentType[] | null;
    paymentTypesAsyncState: AsyncActionState<[string], APIError>;
    options: OrgOptions | null;
    optionsAsyncState: AsyncActionState<[], APIError>;
    orgTypes: OrganizationType[];
    orgTypesAsyncState: AsyncActionState<[], APIError>;
    orgAchAccount: OrgAchAccount | null;
    orgAchAccountAsyncState: AsyncActionState<[string], APIError>;
}

export const initialOrganizationState: OrganizationState = {
    paymentTypeFees: null,
    paymentTypeFeesAsyncState: initialAsyncActionState,
    paymentTypes: null,
    paymentTypesAsyncState: initialAsyncActionState,
    options: {
        // TODO: Will this property be used in the future?
        isPayCoordinatorEnabled: false,
        // NOTE: Ship to Coordinator should be true by default
        // (if the enable_ship_to_coordinator feature flag is disabled)
        isShipToCoordinatorEnabled: true,
        isBuyNowEnabled: false,
        isVacationModeActive: false,
        vacationModeEndDate: null,
        vacationModeStartDate: null,
        originalIsPayCoordinatorEnabled: false,
        originalIsShipToCoordinatorEnabled: false,
    },
    optionsAsyncState: initialAsyncActionState,
    orgTypes: [],
    orgTypesAsyncState: { ...initialAsyncActionState, loading: true },
    orgAchAccount: null,
    orgAchAccountAsyncState: initialAsyncActionState,
};

const { reducer, update } = createSlice(initialOrganizationState, 'ORGANIZATION');
export const organizationReducer = reducer;
export const organizationUpdate = update;

export const getPaymentTypeFeesThunk = createApiThunk(
    getPaymentTypeFees,
    () => (paymentTypeFeesAsyncState, data) =>
        data
            ? update({
                  paymentTypeFeesAsyncState,
                  paymentTypeFees: keyBy(data, (f) => {
                      if (f.paymentSubType === null) {
                          return f.paymentType;
                      }
                      return f.paymentSubType;
                  }),
              })
            : update({ paymentTypeFeesAsyncState })
);

export const getPaymentTypesForOrganizationThunk = createApiThunk(
    getPaymentTypesForOrganization,
    () => (paymentTypesAsyncState, paymentTypes) =>
        paymentTypes
            ? update({ paymentTypesAsyncState, paymentTypes })
            : update({ paymentTypesAsyncState })
);

export const getOptionsThunk = createApiThunk(getOptions, () => (optionsAsyncState, options) => {
    const asyncState = {
        ...optionsAsyncState,
        lastUpdate: !optionsAsyncState.loading ? Date.now() : 0,
    };
    return options
        ? update({
              options: {
                  isShipToCoordinatorEnabled: options.isShipToCoordinatorEnabled,
                  isPayCoordinatorEnabled: options.isPayCoordinatorEnabled,
                  isBuyNowEnabled: options.isBuyNowEnabled,
                  isVacationModeActive: options.isVacationModeActive,
                  vacationModeEndDate: options.vacationModeEndDate,
                  vacationModeStartDate: options.vacationModeStartDate,
                  originalIsPayCoordinatorEnabled: options.originalIsPayCoordinatorEnabled,
                  originalIsShipToCoordinatorEnabled: options.originalIsShipToCoordinatorEnabled,
              },
              optionsAsyncState,
          })
        : update({ optionsAsyncState: asyncState });
});

export const setOrgVacationModeOptionsThunk =
    (payload: VacationModeRequestOptions) =>
    async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const state = getState();
        const orgId = state.store.home.homeDetails?.defaultOrganization?.id;

        if (orgId) {
            const result = await setOrgVacationModeOptions(payload, orgId);
            if (!result.error) {
                dispatch(
                    update({
                        options: {
                            isBuyNowEnabled: result.data.isBuyNowEnabled,
                            isPayCoordinatorEnabled: result.data.isPayCoordinatorEnabled,
                            isShipToCoordinatorEnabled: result.data.isShipToCoordinatorEnabled,
                            isVacationModeActive: result.data.isVacationModeActive,
                            vacationModeEndDate: result.data.vacationModeEndDate,
                            vacationModeStartDate: result.data.vacationModeStartDate,
                            originalIsPayCoordinatorEnabled:
                                result.data.originalIsPayCoordinatorEnabled,
                            originalIsShipToCoordinatorEnabled:
                                result.data.originalIsShipToCoordinatorEnabled,
                        },
                    })
                );
            }
            return result;
        }
        return null;
    };

export const getOrgTypesThunk = createApiThunk(
    getOrganizationTypes,
    () => (orgTypesAsyncState, orgTypes) => {
        if (orgTypes) {
            return update({ orgTypes, orgTypesAsyncState });
        } else {
            return update({ orgTypesAsyncState });
        }
    }
);

export const deleteOrgAchAccountThunk = createApiThunk(
    deleteOrgAchAccount,
    () => (orgAchAccountAsyncState) => {
        orgAchAccountAsyncState.error
            ? update({ orgAchAccountAsyncState })
            : update({ orgAchAccountAsyncState, orgAchAccount: null });
    }
);

export const getOrgAchAccountThunk = createApiThunk(
    getOrgAchAccount,
    () => (orgAchAccountAsyncState, orgAchAccount) => {
        if (orgAchAccount) {
            return update({ orgAchAccount, orgAchAccountAsyncState });
        } else {
            return update({ orgAchAccountAsyncState });
        }
    }
);
export const resetOrganization = () => update(initialOrganizationState);
export const resetOrganizationOptions = () =>
    update({
        options: initialOrganizationState.options,
        optionsAsyncState: initialOrganizationState.optionsAsyncState,
    });
export const selectPaymentTypeFeesAsyncState = (s: FeaturesState) =>
    s.store.organization.paymentTypeFeesAsyncState;
export const selectPaymentTypesAsyncState = (s: FeaturesState) =>
    s.store.organization.paymentTypesAsyncState;
export const selectIsShipToCoordinatorEnabled = (s: FeaturesState) =>
    s.store.organization.options?.isShipToCoordinatorEnabled;
export const selectOrganizationOptionsFinishedLoading = (s: FeaturesState) =>
    s.store.organization.options !== null &&
    s.store.organization.optionsAsyncState.loading === false;

export const selectOrganizationTypes = (s: FeaturesState) => s.store.organization.orgTypes;
export const selectIsOrganizationTypesLoading = (s: FeaturesState) =>
    s.store.organization.orgTypesAsyncState.loading;
export const selectOrgAchAccount = (s: FeaturesState) => s.store.organization.orgAchAccount;
export const selectOrgAchAccountAsyncState = (s: FeaturesState) =>
    s.store.organization.orgAchAccountAsyncState;
export const selectIsBuyNowEnabled = (s: FeaturesState) =>
    s.store.organization.options?.isBuyNowEnabled === true;

export const selectIsPayCoordinatorEnabled = (s: FeaturesState) =>
    s.store.organization.options?.isPayCoordinatorEnabled;
export const selectUserHasAchLinked = createSelector(
    (s: FeaturesState) => s.store.account.achAccount,
    (s: FeaturesState) => s.store.organization.paymentTypes,
    (achAccount, paymentTypes) => {
        return paymentTypes?.includes(PaymentType.BankAccount) && !achAccount;
    }
);
