import { PartialRecord } from 'common/definitions/PartialRecord';
import { createSlice } from 'common/modules/create-slice';
import { makeLens } from 'common/modules/lens/makeLens';
import { monthDiff } from 'common/utils/time';

export interface UserData {
    hasSeenFirstPurchaseScreen: boolean;
    lastReviewRequestDate: string | null;
    hasRespondedToReviewRequest: boolean;
    isEligibleForGroceryOnboarding: boolean;
    hasSeenGroceryOnboarding: boolean;
}

export interface UserDataState {
    userDataById: PartialRecord<string, UserData>;
    shouldPersistUsername: boolean;
    persistedUsername: string | null;
    hasLoggedIn: boolean;
}

const initialUserDataState: UserDataState = {
    userDataById: {},
    shouldPersistUsername: false,
    persistedUsername: null,
    hasLoggedIn: false,
};

export const emptyUserData: UserData = {
    hasSeenFirstPurchaseScreen: false,
    lastReviewRequestDate: null,
    hasRespondedToReviewRequest: false,
    isEligibleForGroceryOnboarding: false,
    hasSeenGroceryOnboarding: false,
};

const { reducer, configureAction } = createSlice(initialUserDataState, 'USER_DATA');
export const userDataReducer = reducer;

const lens = makeLens<UserDataState>();

export const updateUserData = configureAction<{ userId: string; updates: Partial<UserData> }>(
    'UPDATE_USER_DATA',
    ({ userId, updates }) =>
        lens
            .k('userDataById')
            .k(userId)
            .set((existingData = emptyUserData) => ({ ...existingData, ...updates }))
);

export const updateShouldPersistUsername = configureAction<{ shouldPersistUsername: boolean }>(
    'UPDATE_SHOULD_PERSIST_USERNAME',
    ({ shouldPersistUsername }) =>
        (state) => ({
            ...state,
            shouldPersistUsername,
        })
);

export const updatePersistedUsername = configureAction<{ persistedUsername: string | null }>(
    'UPDATE_PERSISTED_USERNAME',
    ({ persistedUsername }) =>
        (state) => ({
            ...state,
            persistedUsername,
        })
);

export const updateHasLoggedIn = configureAction<{ hasLoggedIn: boolean }>(
    'UPDATE_PERSIST_HAS_LOGGED_IN',
    ({ hasLoggedIn }) =>
        (state) => ({
            ...state,
            hasLoggedIn,
        })
);

export const isEligibleForReviewRequest = (userData: UserData) => {
    if (userData.hasRespondedToReviewRequest) {
        return false;
    }
    if (!userData.lastReviewRequestDate) {
        return true;
    }

    // Prompting every 4 months ensures we don't exceed ios or android's request-review quotas
    const monthsSinceLastRequest = monthDiff(Date.now(), userData.lastReviewRequestDate);
    return monthsSinceLastRequest >= 4;
};
