import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    faUserAlt,
    faClipboardListCheck,
    faHeart,
    faDollarSign,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StepWrapper, StepWrapperProps } from '../../enrollment/steps/StepWrapper';

import { Btn } from 'components/btn/Btn';
import { Routes } from 'routes';
import { BottomContainer } from 'features/coordinator/components/BottomContainer';

interface LitePlusGetStartedStepProps extends StepWrapperProps {}

export const LitePlusGetStartedStep = (props: LitePlusGetStartedStepProps) => {
    const history = useHistory();

    const ButtonContainer = () => (
        <>
            <div className="flex items-center flex-col -mt-4">
                <Btn className="px-10" onClick={() => history.replace(Routes.EnrollCreateAccount)}>
                    Start a Free Program
                </Btn>
                <Btn
                    isSecondary
                    className="px-10 mt-4"
                    onClick={() => history.replace(Routes.EnrollContinue)}
                >
                    Continue Enrollment
                </Btn>
            </div>
        </>
    );

    return (
        <StepWrapper
            contentClassName="text-md sm:text-lg font-thin"
            heading={props.heading}
            subheading={props.subheading}
        >
            <div className="mb-5" />
            <div className="flex justify-center">
                <ul className="text-grey-1 font-thin sm:font-normal text-md sm:text-lg">
                    <li className="flex mb-3">
                        <div className="w-10 flex justify-center mr-2 -mt-1" aria-hidden="true">
                            <FontAwesomeIcon icon={faUserAlt} className="text-brand" size="2x" />
                        </div>
                        Creating your personal profile
                    </li>
                    <li className="flex mb-3">
                        <div className="w-10 flex justify-center mr-2 -mt-1" aria-hidden="true">
                            <FontAwesomeIcon
                                icon={faClipboardListCheck}
                                className="text-brand"
                                size="2x"
                            />
                        </div>
                        Setting account security
                    </li>
                    <li className="flex mb-3">
                        <div className="w-10 flex justify-center mr-2 -mt-1" aria-hidden="true">
                            <FontAwesomeIcon icon={faHeart} className="text-brand" size="2x" />
                        </div>
                        Creating your organization profile
                    </li>
                    <li className="flex mb-6">
                        <div className=" w-10 flex justify-center mr-2 -mt-1" aria-hidden="true">
                            <FontAwesomeIcon
                                icon={faDollarSign}
                                className="text-brand "
                                size="2x"
                            />
                        </div>
                        Choosing how to get paid (woohoo!)
                    </li>
                </ul>
            </div>
            <div className="mb-3" />
            <div className="hidden sm:flex items-center flex-col pb-48 sm:pb-10">
                <ButtonContainer />
            </div>
            <BottomContainer>
                <ButtonContainer />
            </BottomContainer>
        </StepWrapper>
    );
};
