import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    usePlaidLink,
    PlaidLinkOnExit,
    PlaidLinkOnSuccessMetadata,
    PlaidLinkOnSuccess,
} from 'react-plaid-link';

import { APIError, APIResponse } from 'common/api/models';
import {
    makeStateful,
    StatefulPromise,
    PromiseState,
} from 'common/modules/stateful-promise/StatefulPromise';
import {
    AchUserLinkTokenResponse,
    AchVerificationStatus,
    OAuthRedirectType,
    OrgAchAccount,
} from 'common/api/e-comm/ach/models/AchAccount';
import {
    getOrgAchLinkToken,
    getUserAchLinkToken,
    AchAccountBody,
    addPlaidAchToOrg,
    verifyPlaidOrg,
} from 'common/api/e-comm/ach';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { closeLoader, openLoader, LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { PlaidVerificationMapper, PlaidVerificationStatus } from 'common/utils/plaidVerification';
import { useAlert } from 'modules/alerts';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import { API_KEY } from 'common/api/config';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface PlaidWrapperProps {
    userAch: boolean;
    orgId?: string;
    isResuming?: boolean;
    children: (openPlaid: () => void, ready: boolean) => React.ReactNode;
    onSuccess: <T>(
        payload: T,
        publicToken: string,
        metadata: PlaidLinkOnSuccessMetadata
    ) => Promise<void>;
    onExit?: PlaidLinkOnExit;
    isOAuth?: boolean;
    loadToken: () => StatefulPromise<APIResponse<AchUserLinkTokenResponse, null>>;
    // BackEnd error handler
    onApiError?: (error?: APIError<null>) => void;
}

interface PlaidButtonProps extends PlaidWrapperProps {
    loader: React.RefObject<LoadingOverlay>;
    linkToken: string;
    loadTokenRef: React.MutableRefObject<
        StatefulPromise<APIResponse<AchUserLinkTokenResponse>> | undefined
    >;
}

const PlaidButton = (props: PlaidButtonProps) => {
    const { loader, isOAuth, onSuccess, linkToken, children, loadTokenRef } = props;
    const { retrieveBlackBox } = useBlackBoxValues();
    const alert = useAlert();
    const _onSuccess = useCallback<PlaidLinkOnSuccess>(
        async (publicToken, metadata) => {
            openLoader(loader.current);
            const bbValues = await retrieveBlackBox();
            const plaidVerificationStatus: PlaidVerificationStatus = metadata.accounts[0]
                .verification_status as PlaidVerificationStatus;

            const verificationStatus = plaidVerificationStatus
                ? PlaidVerificationMapper[plaidVerificationStatus]
                : AchVerificationStatus.Verified;
            const requestBody: AchAccountBody = {
                blackBoxValue: bbValues.blackBoxValue || '',
                secondaryBlackBoxValue: bbValues.secondaryBlackBoxValue || '',
                publicToken,
                // TODO: check if this account_id is still being used and why the interface doesn't match.
                accountId: (metadata as any).account_id,
                verificationStatus,
            };

            await closeLoader(loader.current);
            onSuccess<AchAccountBody>(requestBody, publicToken, metadata);
        },
        [onSuccess, retrieveBlackBox, loader]
    );

    const [shouldOpen, setShouldOpen] = useState(false);
    const openPlaid = useCallback(async () => {
        if (loadTokenRef.current?.state === PromiseState.Pending) {
            openLoader(loader.current);
            await loadTokenRef.current.promise;
            await closeLoader(loader.current);
        }
        // Ideally, we'd just call `open()` here, but if plaid or the link token isn't loaded yet,
        // it's just a no-op, so we trigger it in a useEffect below only when it's guaranteed to actually open.
        setShouldOpen(true);
    }, [loadTokenRef, loader]);

    const { open, ready, error } = usePlaidLink({
        token: linkToken,
        onSuccess: _onSuccess,
        onExit: props.onExit,
        onEvent: (eventName: string) => {
            if (eventName === 'OPEN') {
                setShouldOpen(false);
            }
        },
        receivedRedirectUri: isOAuth ? window.location.href : undefined,
    });

    useEffect(() => {
        if (error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }
        // open Plaid when user clicks the button OR automatically open it IFF we're getting redirected back
        // from an oauth-flow.
        if ((shouldOpen || isOAuth) && ready) {
            open();
        }
    }, [shouldOpen, ready, open, isOAuth, error, alert]);

    return <>{children(openPlaid, linkToken !== '')}</>;
};

export const PlaidWrapperBase = (props: PlaidWrapperProps) => {
    const { isOAuth, loadToken } = props;
    const loadTokenRef = useRef<StatefulPromise<APIResponse<AchUserLinkTokenResponse>>>();
    const [linkToken, setLinkToken] = useState('');

    // Side Effect: Load the `link-token` from the backend IFF we're not in `oauth flow`.
    // If we are in `oauth flow` then we need to read the token from localStorage
    useEffect(() => {
        // CASE: We're in oauth flow and need to read the `link-token`
        // that was previously generated. Otherwise, Plaid will
        // complain that it is not the same `link-token`
        if (isOAuth) {
            const token = localStorage.getItem('link-token') || '';
            setLinkToken(token);
            localStorage.removeItem('link-token');
        } else {
            localStorage.removeItem('link-token');

            loadTokenRef.current = loadToken();
            loadTokenRef.current.promise.then(({ data }) => {
                // Load token from server and store it in local-storage. Used later in the `oauth-flow`
                // after being redirected back.
                if (data) {
                    setLinkToken(data.linkToken);
                    localStorage.setItem('link-token', data.linkToken);
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const loader = useRef<LoadingOverlay>(null);

    useEffect(() => {
        // HACK: we need to prevent Plaid from calling `scrollTo(0,0)` to avoid
        // having our page in mobile_view from jumping to the top of the page
        // when mounting/unmounting the PlaidButton.
        const scrollToEventFn = window.scrollTo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).scrollTo = () => {};

        return () => {
            // HACK: we have to restore the `scrollTo` function back to window.scrollTo
            setTimeout(() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).scrollTo = scrollToEventFn;
            }, 1000);
        };
    }, []);

    return (
        <>
            {linkToken ? (
                <PlaidButton
                    {...props}
                    linkToken={linkToken}
                    loadTokenRef={loadTokenRef}
                    loader={loader}
                    isOAuth={isOAuth}
                >
                    {props.children}
                </PlaidButton>
            ) : (
                <>{props.children(() => null, false)}</>
            )}
            <LoadingOverlay ref={loader} />
        </>
    );
};

export const PlaidWrapper = (props: Omit<PlaidWrapperProps, 'loadToken' | 'userAch'>) => {
    const alert = useAlert();
    const appInsights = useAppInsightsContext();

    const defaultApiErrorHandler: (error?: APIError<null> | undefined) => void = useCallback(() => {
        alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
    }, [alert]);

    const loadToken = () => {
        return makeStateful(
            getOrgAchLinkToken(props.orgId as string, {
                webhook: `${process.env.REACT_APP_PLAID_ORG_WEBHOOK}?subscription-key=${API_KEY}`,
                redirectType: OAuthRedirectType.OrganizationEnrollment,
            })
        );
    };

    const onSuccess = async <T,>(
        payload: T,
        publicToken: string,
        metadata: PlaidLinkOnSuccessMetadata
    ) => {
        let response: APIResponse<OrgAchAccount, null> | null = null;
        if (
            // CASE: user is in a pending-manual-verification
            (payload as unknown as AchAccountBody).verificationStatus ===
                PlaidVerificationMapper.pending_manual_verification ||
            (payload as unknown as AchAccountBody).verificationStatus ===
                PlaidVerificationMapper.pending_automatic_verification ||
            // CASE: user logged in through their institution(auto verify)
            metadata.accounts[0].verification_status === null ||
            // CASE: Instant Micro-Deposits
            (!props.isResuming &&
                metadata.accounts[0].verification_status ===
                    PlaidVerificationStatus.manually_verified)
        ) {
            response = await addPlaidAchToOrg(
                props.orgId as string,
                payload as unknown as AchAccountBody
            );
        } else if (
            (payload as unknown as AchAccountBody).verificationStatus ===
            PlaidVerificationMapper.manually_verified
        ) {
            // CASE: Micro-deposits have been verified
            response = await verifyPlaidOrg(props.orgId as string);
        }

        if (!response || !response?.data || response?.error) {
            const error = response?.error?.error;
            if (error) {
                appInsights.trackException({
                    exception: error,
                    severityLevel: SeverityLevel.Error,
                });
            }

            if (props.onApiError) {
                props.onApiError(response?.error);
            } else {
                defaultApiErrorHandler(response?.error);
            }
            return;
        }

        return props.onSuccess<OrgAchAccount>(response.data, publicToken, metadata);
    };

    return (
        <PlaidWrapperBase {...props} userAch={false} loadToken={loadToken} onSuccess={onSuccess} />
    );
};

interface Bam {
    addressId: string;
}

interface PWP extends Bam, Omit<PlaidWrapperProps, 'loadToken' | 'userAch'> {}

export const UserPlaidWrapper = (props: PWP) => {
    const loadToken = useCallback(() => {
        return makeStateful(
            getUserAchLinkToken({
                webhook: `${process.env.REACT_APP_PLAID_USER_WEBHOOK}?subscription-key=${API_KEY}`,
                redirectType: OAuthRedirectType.AccountSettingsPaymentOption,
            })
        );
    }, []);

    return <PlaidWrapperBase userAch {...props} loadToken={loadToken} />;
};
