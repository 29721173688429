import { FeaturesState } from 'common/features/featuresReducer';
import { createSlice } from 'common/modules/create-slice';

export interface IterableState {
    initialized: boolean;
    inboxVisits: number;
    hasUnreadMessages: boolean;
}

const initialIterableState: IterableState = {
    initialized: false,
    inboxVisits: 0,
    hasUnreadMessages: false
};

const { reducer, configureAction } = createSlice(initialIterableState, 'ITERABLE');

export const setInitialized = configureAction('SET_INITIALIZED', () => (state: IterableState) => ({
    ...state,
    initialized: true,
}));

export const resetInitialized = configureAction(
    'RESET_INITIALIZED',
    () => (state: IterableState) => ({
        ...state,
        initialized: false,
        hasUnreadMessages: false,
    })
);

export const incrementInboxVisits = configureAction(
    'INCREMENT_INBOX_VISITS',
    () => (state: IterableState) => ({
        ...state,
        inboxVisits: state.inboxVisits + 1,
    })
);

export const setInboxHasUnreadMessages = configureAction(
    'SET_INBOX_HAS_UNREAD_MESSAGES',
    (hasUnreadMessages: boolean) => (state: IterableState) => {
        return ({
        ...state,
        hasUnreadMessages: hasUnreadMessages
    })}
);

export const iterableReducer = reducer;

export const selectInboxVisits = (state: FeaturesState) => state.store.iterable.inboxVisits;

export const selectIsIterableInitialized = (state: FeaturesState) =>
    state.store.iterable.initialized;

export const selectInboxHasUnreadMessages = (state: FeaturesState) =>
    state.store.iterable.hasUnreadMessages;
