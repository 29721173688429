import { logEvent, Item, Currency, setUserId, setUserProperties } from 'firebase/analytics';
import { Product } from 'common/api/e-comm/models/Product';
import { UniversalBrand } from 'common/api/models/UniversalBrand';
import { firebaseAnalytics } from './firebase';
import { ProductLabel, PurchaseData } from './types';
import { monetize } from 'common/utils/strings';
import { OrderSource } from 'common/api/e-comm/models/OrderSource';
import { CartResponse } from 'common/api/e-comm/cart/lineitem/models/CartItems';

interface RawItem {
    product: Product;
    brand?: UniversalBrand | null;
    price?: Currency;
    quantity: number;
}

interface GAItem extends Item {
    price?: Currency;
}

export enum PromotionSources {
    Contentful = 'Contentful',
    TodaysBonus = "Today's Bonus",
    RaiseRight = 'RaiseRight',
}

export interface PromotionData {
    creativeSlot: string;
    index: number;
    promotionName: string;
    source: PromotionSources;
}

interface AffiliatePurchaseData {
    affiliateChannel: string;
    affiliateMobilePlatform: 'ios' | 'android' | '';
    version: string;
    location: 'wallet' | 'order summary' | 'PDP';
    brandId: number | null;
    brandName: string;
    orgId: string | null;
}

const CURRENCY = 'USD';

const rawItemToGAItem = ({ product, brand, price, quantity }: RawItem): GAItem => {
    const itemPrefix = product.isLocalProduct ? product.name : brand?.name;
    const itemName = `${itemPrefix} ${ProductLabel[product.type]}`;
    return {
        item_brand: brand?.name,
        item_id: String(product.id),
        item_name: itemName,
        item_category: ProductLabel[product.type],
        item_variant: price ? monetize(Number(price)) : undefined,
        quantity,
        price,
    };
};

const getItemValue = (rawItem: RawItem): number | undefined => {
    if (rawItem.price) {
        return Number(rawItem.price) * rawItem.quantity;
    }
};

export const gaLogAddToCart = (rawItem: RawItem) => {
    const value = getItemValue(rawItem) || 0;
    const gaItem = rawItemToGAItem(rawItem);
    logEvent(firebaseAnalytics, 'add_to_cart', {
        items: [gaItem],
        currency: CURRENCY,
        value,
    });
};

export const gaLogClickBuyNow = (productId: number, price: number, qt: number) => {
    logEvent(firebaseAnalytics, 'click_buy_now', {
        params: {
            product: productId,
            value: price * qt,
            qty: qt,
        },
    });
};

export const gaSetUserId = (userId: string | null) => {
    if (userId) {
        setUserId(firebaseAnalytics, userId);
    }
};

export const gaLogPurchase = ({ cart, total, shippingCost, orderId, isBuyNow }: PurchaseData) => {
    logEvent(firebaseAnalytics, 'purchase', {
        affiliation: OrderSource.Web,
        currency: CURRENCY,
        items: cart.lineItems.map(rawItemToGAItem),
        shipping: shippingCost,
        tax: 0,
        value: total,
        transaction_id: orderId,
        isBuyNow,
    });
};

export const gaLogBeginCheckout = (cart: CartResponse) => {
    logEvent(firebaseAnalytics, 'begin_checkout', {
        items: cart.lineItems.map(rawItemToGAItem),
        currency: CURRENCY,
        value: cart.subtotal,
    });
};

export const logClickAd = ({ promotionName, index, source, creativeSlot }: PromotionData) => {
    logEvent(firebaseAnalytics, 'click_ad', {
        creative_name: promotionName,
        creative_slot: creativeSlot || `${source} (${index + 1})`,
    });
};

export const logViewAd = ({ promotionName, index, source, creativeSlot }: PromotionData) => {
    logEvent(firebaseAnalytics, 'view_ad', {
        creative_name: promotionName,
        creative_slot: creativeSlot || `${source} (${index + 1})`,
    });
};

export const gaSetUserProperties = (properties: { [key: string]: unknown }) => {
    setUserProperties(firebaseAnalytics, properties);
};

export const logGetCoordinatorInvite = (type: 'code' | 'qr') => {
    logEvent(firebaseAnalytics, `click_get_coordinator_invite_${type}`);
};

export const logAffiliateClick = ({
    affiliateChannel,
    affiliateMobilePlatform,
    version,
    location,
    brandId,
    brandName,
    orgId,
}: AffiliatePurchaseData) => {
    logEvent(firebaseAnalytics, 'affiliate_click', {
        affiliate_channel: affiliateChannel,
        affiliate_mobile_platform: affiliateMobilePlatform,
        version,
        location,
        brand_id: brandId,
        brand_name: brandName,
        org_id: orgId,
    });
};

export const logP2PInviteFamily = (type: 'email' | 'text' | 'copy' | 'share') => {
    logEvent(firebaseAnalytics, `click_invite_family_${type}_icon`);
};
