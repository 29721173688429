import { SavedCreditCard, CreditCardType } from '../models/CreditCard';
import { PaymentProviderResponse } from '../orders/service';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { CREDIT_CARDS_URL } from 'common/api/config';
import { ThreeDSResponse, ThreeDSTransactionError } from 'common/modules/3dsecure';
import { UserCartType } from '../cart';
import { APIResponse } from 'common/api/models';

export const getSavedCreditCards = () => withTokensIfNeeded<SavedCreditCard[]>(CREDIT_CARDS_URL);

export interface CreditCardTransactionTokenResponse {
    token: string | null;
    clientIpAddress: string | null;
    customerId: number;
    cartReferenceId: number;
}

export const getCreditCardTransactionToken = (amount: number, cartType?: UserCartType) =>
    withTokensIfNeeded<CreditCardTransactionTokenResponse>(`${CREDIT_CARDS_URL}/transactionToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount, userCartType: cartType || UserCartType.DEFAULT }),
    });

export const deleteCreditCard = (id: number) =>
    withTokensIfNeeded<null>(`${CREDIT_CARDS_URL}/${id}`, {
        method: 'DELETE',
    });

export interface ThreeDSecureTokenResponse {
    token: string;
    expireDateTime: string;
}

export const get3DSecureToken = () =>
    withTokensIfNeeded<ThreeDSecureTokenResponse>(`${CREDIT_CARDS_URL}/3DSecure`);

export interface CreditCardProviderResponseLogEntry {
    payment3DSecureResponse: ThreeDSTransactionError | ThreeDSResponse | null;
    paymentProviderResponse: PaymentProviderResponse;
    cartId: string;
    cardType: CreditCardType;
}

export const logCreditCardFailure = (logEntry: CreditCardProviderResponseLogEntry) =>
    withTokensIfNeeded<CreditCardTransactionTokenResponse>(
        `${CREDIT_CARDS_URL}/CreditCardPaymentProviderResponse?api-version=2`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logEntry),
        }
    );

export const voidPayment = (transactionId: string) =>
    withTokensIfNeeded(`${CREDIT_CARDS_URL}/payment/void`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transactionId }),
    });

export type ValidateCreditCardBinResponse = {
    bin: string;
    isValid: boolean;
    isCredit: boolean;
    isDebit: boolean;
};

export const validateCardBin = (bin: string) => {
    // remove all spaces and only include the first 8 digits of the bin
    const binPayload = bin.replace(/ /g, '').substring(0, 8);

    if (binPayload?.length !== 8) {
        return {
            data: {
                bin: bin,
                isValid: false,
                isDebit: false,
                isCredit: false,
            },
        } as unknown as APIResponse<ValidateCreditCardBinResponse>;
    }

    return withTokensIfNeeded<ValidateCreditCardBinResponse>(`${CREDIT_CARDS_URL}/validate-bin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bin: binPayload }),
    });
};
