import { createSelector } from 'reselect';
import { createSlice } from 'common/modules/create-slice';
import { CategoryData } from 'common/api/categories/models/CategoryData';
import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { RecommendedCategoryResponse } from 'common/api/categories/models/RecommendedCategoryResponse';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { getAllCategories, getRecommendedCategories } from 'common/api/categories';
import { sortBy } from 'common/utils';
import { FeaturesState } from 'common/features/featuresReducer';
import { CartLineItem } from 'common/api/e-comm/models/CartLineItem';

interface CategoryByBrandIdState {
    [brandId: string]: {
        categories: string[];
        mainCategory: string | null;
    };
}

export interface CategoriesState {
    allCategories: CategoryData[];
    allCategoriesAsyncState: AsyncActionState;
    recommendedCategory: RecommendedCategoryResponse | null;
    recommendedCategoryAsyncState: AsyncActionState;
    categoriesByBrand: CategoryByBrandIdState;
    mainCategoryMapping: Record<string, string>;
}

export const initialCategoriesState: CategoriesState = {
    allCategories: [],
    allCategoriesAsyncState: initialAsyncActionState,
    recommendedCategory: null,
    recommendedCategoryAsyncState: initialAsyncActionState,
    categoriesByBrand: {},
    mainCategoryMapping: {},
};

const { update, reducer, configureAction } = createSlice(initialCategoriesState, 'CATEGORIES');
export const categoriesReducer = reducer;
export const updateCategories = update;

export const requestAllCategories = createApiThunk(getAllCategories, () => (state, result) => {
    return result
        ? update({
              allCategories: sortBy<CategoryData>((a, b) => a.name.localeCompare(b.name))(result),
              allCategoriesAsyncState: state,
          })
        : update({ allCategoriesAsyncState: state });
});

export const requestRecommendedCategories = createApiThunk(
    getRecommendedCategories,
    () => (state, result) => {
        return result
            ? update({
                  recommendedCategory: result.items[0] ? result.items[0].fields : null,
                  recommendedCategoryAsyncState: state,
              })
            : update({ recommendedCategoryAsyncState: state });
    }
);

export const updateCategoriesByBrandId = configureAction<{
    brandId: number | string;
    categories: string[];
    mainCategory?: string;
}>('UPDATE_CATEGORIES_BY_BRAND_ID', ({ brandId, categories, mainCategory }) => (state) => {
    return {
        ...state,
        categoriesByBrand: {
            ...state.categoriesByBrand,
            [brandId]: {
                categories,
                mainCategory: mainCategory || categories[0],
            },
        },
    };
});

export const selectRecommendedCategories = createSelector(
    (state: FeaturesState) => state.store.categories.recommendedCategory,
    (recommendedCategory: RecommendedCategoryResponse | null) => {
        return recommendedCategory
            ? [...recommendedCategory.categories, { id: 0, name: 'All Categories', iconUrl: '' }]
            : [];
    }
);

export const selectAllCategories = createSelector(
    (state: FeaturesState) => state.store.categories.allCategories,
    (allCategories) => allCategories
);

export const selectCategoriesByBrand = (brandId: number | string) =>
    createSelector(
        [
            (state: FeaturesState) => state.store.categories.categoriesByBrand,
            (state: FeaturesState) => state.store.categories.allCategories,
        ],
        (categoriesByBrand, allCategories: CategoryData[]) => {
            const categoryIds = categoriesByBrand[brandId]?.categories || [];
            return categoryIds.map((categoryId) => {
                const category = allCategories.find((c) => String(c.id) == String(categoryId));
                return category ? category.name : '';
            });
        }
    );

export const getMainCategoryByBrandId = (brandId: number | string | null | undefined) =>
    createSelector(
        [
            (state: FeaturesState) => state.store.categories.categoriesByBrand,
            (state: FeaturesState) => state.store.categories.allCategories,
        ],
        (categoriesByBrand, allCategories) => {
            if (brandId === null || brandId === undefined) {
                return '';
            }

            const categoryId = categoriesByBrand[brandId]?.mainCategory || '';
            const category = allCategories.find((c) => String(c.id) == String(categoryId));
            return category ? category.name : '';
        }
    );

export const cartItemsWithCategories = createSelector(
    [
        (state: FeaturesState) => state.store.cart.cart?.lineItems,
        (state: FeaturesState) => state.store.categories.allCategories,
        (state: FeaturesState) => state.store.categories.mainCategoryMapping,
    ],
    (cartItems, allCategories, mappings) => {
        if (!cartItems) {
            return [];
        }

        const result = cartItems.map((entry: CartLineItem) => {
            const brandId = entry.brand?.id;
            const categories = entry.product.categories || [];

            // use dedupe if its set
            if (brandId && mappings && mappings[brandId]) {
                return { ...entry, mainCategory: mappings[brandId] };
            }

            // otherwise use the first entry in category
            if (categories && categories.length > 0) {
                const categoryString =
                    allCategories.find((c) => String(c.id) == String(categories[0]))?.name || '';
                return { ...entry, mainCategory: categoryString };
            }

            return entry;
        });

        return result;
    }
);
