import React from 'react';
import { Clock } from 'react-feather';

import { formatDuration, msUntil, msToDays } from 'common/utils/time';
import { colors } from '../../styles/settings';
import { createStyles } from '../../utils/createStyle';
import './BrandCardSlim.scss';

export const style = createStyles({
    brandText: ['ml-2', { fontSize: '10px', lineHeight: '12px' }],
    clockIcon: ['w-3 h-3', { marginRight: '5px' }],
    footerContainer: ['flex  text-white'],
});

export interface BonusInfoProps {
    bonusExpiration?: string;
    isOnBonus?: boolean;
}

export const BonusInfo = ({ bonusExpiration, isOnBonus }: BonusInfoProps) => {
    if (!isOnBonus || !bonusExpiration) {
        return null;
    }

    const timeRemaining = msUntil(bonusExpiration);

    if (msToDays(timeRemaining) > 7) {
        return null;
    }

    return (
        <div className={`${style.footerContainer} ${style.brandText}`}>
            <div className="flex">
                <div>
                    <Clock className={`${style.clockIcon}`} color={colors.white} />
                </div>
                <div>{formatDuration(timeRemaining)} left</div>
            </div>
        </div>
    );
};
