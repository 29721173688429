import React from 'react';

export const reactRecursiveMap = <T, C>(
    children: C | C[],
    fn: (child: C, index: number) => React.ReactNode
) => {
    return React.Children.map(children, (child, i) => {
        if (!React.isValidElement(child)) {
            return React.Children.map(child, fn);
        }

        let newChild = child as React.ReactElement;
        if (newChild.props.children) {
            newChild = React.cloneElement(
                child,
                {},
                reactRecursiveMap(newChild.props.children, fn)
            );
        }

        return fn(newChild as unknown as C, i);
    });
};
