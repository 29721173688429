import React, { useContext, useState, useEffect } from 'react';
import { useSprings, config, animated } from 'react-spring';
import { ReactComponent as OrgEnrollmentV2LoadingImage } from 'media/images/OrgEnrollmentV2LoadingImage.svg';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { useWarnLeavingPage } from 'hooks/useWarnLeavingPage';
import { Prompt } from 'react-router-dom';
import { FadeIn } from 'components/loading-screen/FadeIn';

interface IProps {
    showLoadingScreen?: Function;
}

export const OrgEnrollmentLoadingTransition = () => {
    useWarnLeavingPage();
    return (
        <FadeIn when={true}>
            <AnimatedText />
            <OrgEnrollmentV2LoadingImage className="mx-auto w-80 sm:w-max" />
        </FadeIn>
    );
};

const AnimatedText = () => {
    const [trail] = useSprings(3, (item) => {
        return {
            from: { opacity: 0 },
            to: { opacity: 1 },
            loop: { reverse: true, delay: 180 },
            config: { ...config, friction: 22 },
            delay: 0 + item * 100,
        };
    });
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const [title, setTitle] = useState<string>(
        (contentfulEntry as OrganizationEnrollmentV2)?.loaderScreen1Title ?? ''
    );

    useEffect(() => {
        setTimeout(() => {
            setTitle((contentfulEntry as OrganizationEnrollmentV2)?.loaderScreen2Title);
        }, 5000);
    }, [contentfulEntry]);

    return (
        <div>
            <Prompt
                when={true}
                message="Are you sure you want to leave? Changes you made may not be saved."
            />
            <div className="flex justify-center mb-4">
                <p className="mr-1 text-brand-dark text-center text-4xl font-light">
                    <span data-testid="org-enrollment-v2-loading-screen">{title}</span>
                    {trail.map((props, index) => (
                        <animated.span
                            key={index}
                            className="inline-block text-brand-dark text-4xl"
                            style={props}
                        >
                            .
                        </animated.span>
                    ))}
                </p>
            </div>
        </div>
    );
};
