import React from 'react';
import imgBBB from '../../../media/images/bbbDark.png';
import { Routes } from 'routes';

export const Footer = () => {
    return (
        <footer>
            <div className="flex sm:flex-row flex-col gap-6 pb-3 pt-1">
                <div className="self-start" style={{ minWidth: '141px', maxWidth: '141px' }}>
                    <a
                        target="_blank"
                        href="https://www.bbb.org/us/mi/grand-rapids/profile/fundraising-counselors-and-organizations/raiseright-llc-0372-13002078"
                    >
                        <img src={imgBBB} />
                    </a>
                </div>
                <div className="text-xs text-grey-medium gap-2 flex flex-col pr-4">
                    <div className="flex gap-2 text-grey-2">
                        <p>© 1999 - {new Date().getFullYear()} RaiseRight. All Rights Reserved.</p>
                        <a target="_blank" className="font-semibold" href={Routes.PrivacyPolicy}>
                            RaiseRight Privacy Policy
                        </a>{' '}
                        <span className="text-grey-light">|</span>
                        <a target="_blank" className="font-semibold" href={Routes.TermsOfUse}>
                            Terms of Use{' '}
                        </a>
                    </div>

                    <p>
                        <strong>Need help?</strong> Call our Customer Support Team at{' '}
                        <a className="font-semibold" href="tel:800-727-4715">
                            800-727-4715
                        </a>{' '}
                        (Option 3)
                    </p>
                    <p>
                        The merchants represented are not sponsors of RaiseRight or otherwise
                        affiliated with RaiseRight. The Logos and other identifying marks on this
                        website are trademarks of and owned by each represented company and/or its
                        affiliates. Please visit each company’s website for additional terms and
                        conditions.
                    </p>
                </div>
            </div>
        </footer>
    );
};
