// sometimes this file needs editing to cachbuste our local settings .env file
// my cachebusting phrase: "throttle therapy"
import { SEARCH_API_URL, SUGGEST_API_URL } from './config';

import { APIResponse } from 'common/api/models';
import { apiFetch } from 'common/api/utils';
import { constructorBrowse, constructorSearch, constructorSearchFacets } from '../constructor';
import { getProceduralFeatureFlags, FeatureFlagTypes } from 'common/hooks/useFeatureFlags';
import { isReactNative } from 'common/utils/isReactNative';
import { searchTermIsAOneLetterFilter } from '../constructor/util/search-term-is-a-one-letter-filter';
import { EarnTypeConstructorFilter } from '../constructor/util';

// The search/suggest service uses Azure Cognitive Search. Its documentation explains
// how to use these params and how the filter syntax works.
// https://docs.microsoft.com/en-us/azure/search/search-filters
// https://docs.microsoft.com/en-us/azure/search/query-simple-syntax
// https://docs.microsoft.com/en-us/azure/search/query-lucene-syntax

export interface SearchApiQueryParams {
    search: string;
    filter: string;
    searchMode: 'any' | 'all';
    searchFields: string;
    queryType: 'simple' | 'full';
    skip: number;
    top: number;
    count: boolean;
    orderby: string;
    select: string;
    facets: string[];
    highlight: string;
    highlightPreTag: string;
    highlightPostTag: string;
    scoringProfile: string;
    scoringParameter: string;
    minimumCoverage: number;
    earnType?: EarnTypeConstructorFilter;
}

async function waitForFlagsToLoad() {
    while (getProceduralFeatureFlags().loadedCount === 0) {
        await new Promise((resolve) => setTimeout(resolve, 100));
    }
}

async function waitForFeatureFlagsToDetermineLoginStatus() {
    while (!getProceduralFeatureFlags().userLoginStatusUpdated) {
        await new Promise((resolve) => setTimeout(resolve, 100));
    }
}

// string with a "browse by id"
// example: search.in(Id
function paramsIsBrowseById(params: Partial<SearchApiQueryParams>): boolean {
    return Boolean(params.filter?.includes('search.in(Id'));
}

function userIsLoggedIn(): boolean {
    return getProceduralFeatureFlags()?.userIsLoggedIn;
}

export const searchApiFetch = async <T, E = null>(
    params: Partial<SearchApiQueryParams>,
    getFacetsRequest?: boolean
): Promise<APIResponse<T, E>> => {
    if (!isReactNative()) {
        await waitForFeatureFlagsToDetermineLoginStatus();
    }

    if (!isReactNative() && userIsLoggedIn()) {
        const searchTerm = params.search;
        const searchTermAsFilter = searchTermIsAOneLetterFilter(searchTerm);
        await waitForFlagsToLoad();

        if (
            getProceduralFeatureFlags().isEnabled(
                FeatureFlagTypes.enable_constructor_search_with_affiliate_web
            )
        ) {
            if (getFacetsRequest) {
                const request = constructorSearchFacets(params) as unknown as APIResponse<T, E>;
                return await enqueueRequest(async () => {
                    return await request;
                });
            } else if (
                searchTerm &&
                searchTerm !== '*' &&
                searchTerm !== '^' &&
                searchTerm !== undefined &&
                !searchTermAsFilter &&
                !paramsIsBrowseById(params)
            ) {
                const request = constructorSearch(params) as unknown as APIResponse<T, E>;
                return await enqueueRequest(async () => {
                    return await request;
                });
            }
        }

        if (
            getProceduralFeatureFlags().isEnabled(
                FeatureFlagTypes.enable_constructor_browse_with_affiliate_web
            ) &&
            (searchTerm === '*' ||
                searchTerm === undefined ||
                searchTermAsFilter ||
                searchTerm === '')
        ) {
            const request = constructorBrowse(params) as unknown as APIResponse<T, E>;
            return await enqueueRequest(async () => {
                return await request;
            });
        }
    }

    if (params.search === 'T*') {
        // adding some hard-coded exceptions as per ticket #FEC-1551
        params.search += " OR 'the container store' OR 'the buckle' OR 'the palm'";
    }

    // fall through to azure search
    return await apiFetch<T, E>(SEARCH_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
};

const requestQueue: Array<() => Promise<unknown>> = [];
let processingQueue = false;

async function enqueueRequest<T>(request: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        requestQueue.push(async () => {
            try {
                const result = await request();
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });

        processQueue();
    });
}

async function processQueue() {
    if (processingQueue) {
        return;
    }

    processingQueue = true;

    while (requestQueue.length > 0) {
        const request = requestQueue.shift();
        try {
            await request?.();
        } catch (error) {
            // Handle error
        }
    }

    processingQueue = false;

    if (requestQueue.length > 0) {
        processQueue();
    }
}

// https://docs.microsoft.com/en-us/rest/api/searchservice/suggestions#query-parameters
export interface SuggestApiParams {
    search: string;
    suggesterName: string;
    filter?: string;
    top?: number;
    searchFields?: string;
    select?: string;
}

const defaultSuggestApiParams = {
    fuzzy: true,
};

export const suggestApiFetch = <T, E = null>(params: Partial<SuggestApiParams>) => {
    return apiFetch<T, E>(SUGGEST_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...defaultSuggestApiParams,
            ...params,
        }),
    });
};
