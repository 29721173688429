export interface Address {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipCode: string;
}

export const emptyAddress: Address = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
};
