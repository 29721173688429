import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import Skeleton from 'react-loading-skeleton';

import { createStyles } from 'utils/createStyle';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { ReactComponent as TodayBonusStars } from '../../../media/images/today-bonus-stars.svg';
import { useBrandList } from 'common/hooks/useBrandList';
import useMediaQueries from 'hooks/useMediaQueries';
import {
    Brand,
    searchResultBrandsToBrands,
} from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { getBrandsOnBonus } from 'common/api/search/getBrandsOnBonus';
import { BrandOrderByType } from 'common/api/search/models/OrderByType';
import { BrandCardSlim } from '../../../components/brand-card/BrandCardSlim';
import { convertBrandToCardProps } from '../../../components/bonus/CardProps';
import { CarouselControl } from '../../../components/carousel/CarouselControl';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Btn } from 'components/btn/Btn';
import { useRootSelector } from 'common/features/featuresReducer';
import { editFilter } from 'common/utils/shipToCoordinatorUtils';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { UserAuthState, selectUserAuthState } from 'common/features/store/duck/account/duck';
import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import { useSelector } from 'react-redux';
import { selectIsConstructorBrowseEnabledWeb } from 'common/features/store/duck/ffs';
import { and } from 'common/utils/searchFilters';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';

const style = createStyles({
    container: [
        'flex flex-col justify-center py-10 md:py-14',
    ],
    header: [
        'text-brand-dark flex text-2xl md:text-3xl',
        {
            justifyContent: 'center',
            fontWeight: 600,
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            lineHeight: '2.2rem',
            marginBottom: '30px',
        },
    ],
    headerText: [
        'flex',
        {
            marginLeft: '12px',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
        },
    ],
    carouselContainer: [
        'flex flex-wrap',
        {
            justifyContent: 'center',
            alignContent: 'center',
        },
    ],
    carouselItem: [
        'flex',
        {
            padding: '10px',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
        },
    ],
    footerLink: [
        'flex justify-center text-brand',
        {
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: 600,
            textDecorationLine: 'underline',
        },
    ],
});

export const BonusSectionByEarnType = ({
    earnType,
    title,
}: {
    earnType: EarnTypeConstructorFilter;
    title: string;
}) => {
    const history = useHistory();
    const padding = 20;
    const desktopCardWidth = 180;
    const mobileCardWidth = 150;
    const [slider, setSlider] = useState<Slider>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { data: brands, loadData } = useBrandList<Brand[]>(searchResultBrandsToBrands);
    const [selectedSort] = useState<BrandOrderByType>(BrandOrderByType.PopularityRank);
    const [cardWidth, setCardWidth] = useState(desktopCardWidth);
    const [width, setWidth] = useState((desktopCardWidth + padding) * 4);
    const globalFilter = useGlobalBrandFilters();
    const isShipToCoordinatorEnabled = useSelector(selectIsShipToCoordinatorEnabled);
    const notAuthenticated = useRootSelector(
        (s) => s.store.account.userAuthState === UserAuthState.NOTAUTHENTICATED
    );
    const constructorBrowseEnabled = useRootSelector(selectIsConstructorBrowseEnabledWeb);

    const filter = editFilter(isShipToCoordinatorEnabled, globalFilter) as string;

    const cardStyle = createStyles({
        cardContainer: [
            {
                width: `${cardWidth}px`,
            },
        ],
        brandName: [
            {
                '@media (min-width: 768px)': {
                    paddingRight: 20,
                },
            },
        ],
    });

    const setSliderNode = useCallback((node: Slider) => {
        if (node !== null) {
            setSlider(node);
        }
    }, []);

    useEffect(() => {
        // NOTE: fetch once we know that the `isShipToCoordinatorEnabled` has been loaded
        // - avoid calling loadData multiple times by checking brands length
        if (
            loading &&
            (notAuthenticated || isShipToCoordinatorEnabled !== undefined) &&
            !brands?.length
        ) {
            const earnTypeFilter = `EarningsType/any(t: t eq '${earnType}')`;
            loadData(getBrandsOnBonus)({
                top: 1000,
                orderby: selectedSort,
                filter:
                    notAuthenticated || !constructorBrowseEnabled
                        ? filter
                        : and(filter, earnTypeFilter),
            })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [
        loading,
        loadData,
        selectedSort,
        isShipToCoordinatorEnabled,
        brands,
        notAuthenticated,
        filter,
        constructorBrowseEnabled,
    ]);

    const queries = useMemo(
        () => ({
            '(min-width: 1200px)': () => {
                setCardWidth(desktopCardWidth);
                setWidth((desktopCardWidth + padding) * 6);
            },
            '(min-width: 900px) and (max-width: 1199px)': () => {
                setCardWidth(desktopCardWidth);
                setWidth((desktopCardWidth + padding) * 4);
            },
            '(min-width: 600px) and (max-width: 899px)': () => {
                setCardWidth(desktopCardWidth);
                setWidth((desktopCardWidth + padding) * 3);
            },
            '(max-width: 599px)': () => {
                setCardWidth(mobileCardWidth);
                setWidth(mobileCardWidth * 2.1);
            },
        }),
        []
    );

    useMediaQueries(queries);
    const topTwelve = useMemo(
        () => brands?.slice(0, 12).map((brand) => convertBrandToCardProps(brand, earnType, true)),
        [brands]
    );

    if (loading) {
        return <Skeleton className="h-80" />;
    }

    if (!brands || brands?.length === 0 || error) {
        return null;
    }

    const settings = {
        dots: true,
        infinite: false,
        draggable: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesPerRow: 1,
                    centerPadding: '20px',
                    rows: 2,
                },
            },
        ],
        appendDots: (dots: React.ReactNode) => (
            <CarouselControl
                prev={slider?.slickPrev}
                next={slider?.slickNext}
                dots={dots}
                showDots={true}
            />
        ),
    };

    const navToAllBonus = () => {
        if (earnType === EarnTypeConstructorFilter.ShopOnline) {
            history.push(Routes.ShopOnlineOnBonus);
        } else {
            history.push(Routes.OnBonus);
        }
    };

    return (
        <div className={`shop_todays_bonuses ${style.container}`}>
            <div className={`${style.header}`}>
                <TodayBonusStars />
                <div className={`${style.headerText}`}>{title}</div>
            </div>
            <div className="flex flex-wrap justify-center">
                <div style={{ width }}>
                    <Slider ref={setSliderNode} {...settings}>
                        {topTwelve?.map((item) => (
                            <div key={`card_${item.id}`}>
                                <BrandCardSlim styles={cardStyle} data={item} />
                                <br />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className={`${style.footerLink}`}>
                <Btn onClick={navToAllBonus} className="my-3">{`Shop All ${brands.length}`}</Btn>
            </div>
        </div>
    );
};

export const GiftCardsOnBonusSection = () => (
    <BonusSectionByEarnType
        earnType={EarnTypeConstructorFilter.GiftCards}
        title="Today's Gift Card Bonuses"
    />
);

export const ShopOnlineOnBonusSection = () => {
    const userAuthState = useSelector(selectUserAuthState);
    const isConstructorEnabled = useSelector(selectIsConstructorBrowseEnabledWeb);
    const isAuthenticated = userAuthState === UserAuthState.AUTHENTICATED;

    return isAuthenticated && isConstructorEnabled ? (
        <BonusSectionByEarnType
            earnType={EarnTypeConstructorFilter.ShopOnline}
            title="Today's Shop Online Bonuses"
        />
    ) : null;
};
