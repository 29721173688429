import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';

import { getFocusOutline } from '../../styles/accessibility';
import { createStyles } from '../../utils/createStyle';

export interface PillOption {
    text: string;
    value: string;
}

export interface PillsProps {
    options: PillOption[];
    onRemove: (option: PillOption) => void;
}

const styles = createStyles({
    container: [
        'flex items-center relative h-full inline-block w-full overflow-auto select-none',
        {
            scrollBehavior: 'smooth',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
                display: 'none',
            },
        },
    ],
    pillsList: ['pr-10'],
    pillOption: [
        'rounded-full bg-pale-blue text-brand inline-block truncate mr-1 align-middle py-1 px-2 cursor-pointer',
        { fontSize: 12 },
    ],
    chevron: [
        'absolute bg-white border border-solid text-brand h-full flex items-center rounded-lg text-center overscroll-contain cursor-pointer select-none',
        {
            width: '2.5rem',
            maxHeight: '2.5rem',
            top: 'calc(50% - 1.25rem)',
        },
    ],
    removePillBtn: [
        'relative rounded-full ml-2',
        'transition-colors transition-shadow',
        'active:bg-white',
        getFocusOutline({ borderRadius: 9999, offset: 4 }),
        {
            '&:hover': {
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.33)',
            },
            '&:active': {
                transition: 'none',
                boxShadow: 'none',
            },
            width: '16px',
            height: '16px',
        },
    ],
});

export const Pills = ({ options, onRemove }: PillsProps) => {
    const scrollAreaRef = useRef<HTMLDivElement>(null);

    const [showChevronLeft, setShowChevronLeft] = useState(false);
    const [showChevronRight, setShowChevronRight] = useState(false);

    const updateChevonsState = useCallback(() => {
        const scrollEl = scrollAreaRef.current;
        if (!scrollEl) {
            return;
        }
        setShowChevronLeft(scrollEl.scrollLeft > 0);
        setShowChevronRight(scrollEl.scrollWidth > scrollEl.offsetWidth);
    }, [scrollAreaRef, setShowChevronLeft, setShowChevronRight]);

    // if updating the options, update chevrons visibility.
    useEffect(updateChevonsState, [options, updateChevonsState]);

    // if the window is resized, update chevrons visibility.
    useEffect(() => {
        window.addEventListener('resize', updateChevonsState);
        return () => window.removeEventListener('resize', updateChevonsState);
    }, [updateChevonsState]);

    const doScroll = useCallback(
        (direction: string) => () => {
            const scrollEl = scrollAreaRef.current;
            if (!scrollEl) {
                return;
            }
            const delta = scrollEl.offsetWidth / 2;
            scrollEl.scrollLeft += direction === 'next' ? delta : -delta;
        },
        [scrollAreaRef]
    );

    return (
        <div className="flex items-center h-full">
            <div className={styles.container} ref={scrollAreaRef} onScroll={updateChevonsState}>
                <ul className={styles.pillsList}>
                    {options.map((option, i) => (
                        <li key={i} className={styles.pillOption} data-testid="pill-item">
                            <div className="flex items-center">
                                <span className="font-semibold">{option.text}</span>
                                <button
                                    className={styles.removePillBtn}
                                    aria-label={`Remove: ${option.text}`}
                                    onClick={() => onRemove(option)}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="absolute text-brand-dark inset-0 m-auto"
                                    />
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div
                className={styles.chevron}
                style={{
                    left: '0',
                    display: showChevronLeft ? '' : 'none',
                }}
                onClick={doScroll('prev')}
            >
                <span className="w-full text-center">
                    <ArrowLeft className="inline-block" style={{ width: '18px' }} />
                </span>
            </div>
            <div
                className={styles.chevron}
                style={{
                    right: '0',
                    display: showChevronRight ? '' : 'none',
                }}
                onClick={doScroll('next')}
            >
                <span className="w-full">
                    <ArrowRight className="inline-block" style={{ width: '18px' }} />
                </span>
            </div>
        </div>
    );
};
