import classnames from 'classnames';
import React from 'react';
import { createStyles } from 'utils/createStyle';
import { NavItem } from '../nav-items';
import { CollapsibleNavItem } from './CollapsibleNavItem';

export const sectionHeaderStyles = createStyles({
    sectionHeader: [
        'pl-6',
        'pb-0',
        'pt-4',
        'font-sans',
        'text-xs',
        'tracking-wider',
        'leading-4',
        'uppercase',
        'text-grey-medium',
    ],
    sectionHeaderExternalLink: [],
    listStyle: {
        marginTop: '0 !important',
        paddingTop: '13px',
    },
});

interface NavigationSectionProps {
    title: string;
    items: NavItem[];
    expandedItems: Record<string, boolean>;
    setExpandedItems: (items: Record<string, boolean>) => void;
    className?: string;
}

export const NavigationSection = ({
    title,
    items,
    expandedItems,
    setExpandedItems,
    className,
}: NavigationSectionProps) => {
    return (
        <>
            <h3 className={classnames(sectionHeaderStyles.sectionHeader)}>{title}</h3>

            <ul className={classnames([sectionHeaderStyles.listStyle, className])}>
                {items.map((navItem, index) => (
                    <CollapsibleNavItem
                        key={index}
                        {...navItem}
                        expandedItems={expandedItems}
                        setExpandedItems={setExpandedItems}
                    />
                ))}
            </ul>
        </>
    );
};
