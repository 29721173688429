import { createStyles } from 'utils/createStyle';

export const TermsAndConditionsStyles = createStyles({
    content: 'pt-11 pb-1 text-xs text-center max-w-sm mx-auto',
    termsContent: [
        'border border-grey-6 text-sm overflow-auto p-1.5',
        {
            '> p': {
                whiteSpace: 'pre-line',
            },
            '> h2': {
                marginTop: '1rem',
            },
        },
    ],
});
