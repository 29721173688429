import React, { Fragment, useState, useEffect } from 'react';
import { SectionConfig } from '../nav-items';
import { NavigationSection } from './NavigationSection';
import { createStyles } from 'utils/createStyle';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { useNewCoordInitialMessage } from 'components/nav/context';
import { updateAccount, UserAuthState } from 'common/features/store/duck/account/duck';
import { sendToLogoutPage } from 'features/auth/components/withAuth';
import { colors } from 'styles/settings';

export const navigationStyles = createStyles({
    container: [
        'space-y-4',
        {
            borderColor: colors.lineGrey,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    ],
    divider: [
        'border-t mx-4',
        {
            borderColor: colors.lineGrey,
            width: '210px',
        },
    ],
    bottomDivider: [
        'm-0',
        {
            width: 'auto',
            borderTop: `1px solid ${colors.lineGrey}`,
        },
    ],
    flexGrow: {
        flexGrow: 1,
    },
    bottomNav: [
        {
            background: '#fff',
            paddingBottom: '16px',
            position: 'sticky',
            bottom: '0',
            left: '0',
            height: '61px',
        },
    ],
    bottomNavItem: ['pb-3 background-color-white'],
    bottomNavItemButton: [
        'mx-4',
        {
            alignItems: 'center',
            background: '#fff',
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '16px',
            width: '210px',
        },
    ],
    bottomNavIcon: [
        'mr-0',
        {
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: colors.brand,
            fontSize: '18px',
        },
    ],
    bottomNavLabel: [
        'pb-0.5',
        {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '21px',
            textDecoration: 'none',
            color: colors.brand,
        },
    ],
    spacer: [
        {
            paddingBottom: '8px',
        },
    ],
    bottomNavList: [{ backgroundColor: 'white' }],
});

export interface NavigationProps {
    sections: SectionConfig[];
}

export const Navigation = ({ sections }: NavigationProps) => {
    const location = useLocation();
    const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

    const dispatch = useDispatch<any>();
    // NOTE: Required by FEC-1221 & FEC-1174
    const maybeMarkInitialMessageAsSeen = useNewCoordInitialMessage();

    const onSignOut = () => {
        dispatch(updateAccount({ userAuthState: UserAuthState.INITIAL }));
        sendToLogoutPage();
        maybeMarkInitialMessageAsSeen();
    };

    // Expand any sections that contain the current route or have startOpen set to true
    useEffect(() => {
        const updatedExpandedItems = { ...expandedItems };

        sections.forEach((section) => {
            section.items.forEach((item) => {
                if (
                    item.startOpen ||
                    (item.subItems &&
                        item.subItems.some((subItem) => location.pathname === subItem.route))
                ) {
                    updatedExpandedItems[item.title] = true;
                }
            });
        });

        setExpandedItems(updatedExpandedItems);
    }, [location.pathname, sections]);

    return (
        <nav className={navigationStyles.container}>
            {sections.map((section, index, sections) => (
                <Fragment key={section.title}>
                    <NavigationSection
                        key={index + section.title}
                        title={section.title}
                        items={section.items}
                        expandedItems={expandedItems}
                        setExpandedItems={setExpandedItems}
                        className={index === sections.length - 1 ? navigationStyles.flexGrow : ''}
                    />

                    {index < sections.length - 1 && <div className={navigationStyles.divider} />}
                </Fragment>
            ))}

            <div className={navigationStyles.spacer} />

            <div className={navigationStyles.bottomNav}>
                <div className={navigationStyles.bottomDivider} />

                <ul className={navigationStyles.bottomNavList}>
                    <li className={navigationStyles.bottomNavItem}>
                        <button
                            className={navigationStyles.bottomNavItemButton}
                            onClick={() => onSignOut()}
                        >
                            <div className={navigationStyles.bottomNavIcon}>
                                <FontAwesomeIcon icon={faSignOut} />
                            </div>

                            <div className={navigationStyles.bottomNavLabel}>Sign Out</div>
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
