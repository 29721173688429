import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';
import {
    PaymentMethod,
    PaymentType,
    getPaymentTypesForBuyNow,
} from 'common/api/e-comm/payment-types';
import { CreditCardType, SavedCreditCard } from 'common/api/e-comm/models/CreditCard';
import { CheckoutRequest, emptyCheckoutRequest } from 'common/types/CheckoutRequest';
import { createSelector } from 'reselect';
import { AccountState } from '../../account/duck';
import { BuyNowError } from './errors';
import { Dispatch } from 'redux';

export type BuyNowDrawerVisibilityState =
    | 'Error' // As of now only used on web to transition states
    | 'Details'
    | 'Loading'
    | 'Success'
    | 'Success-RemoteActivation-Physical' // used only for remote activations
    | 'Success-RemoteActivation-Reload'; // used only for remote activations

type PurchaseSuccessData = {
    subtotal: number;
    earnings: number;
};

export type BuyNowFlow = {
    paymentMethod?: PaymentMethod;
    newCreditCard: SavedCreditCard | null;
    buyNowDrawerView: BuyNowDrawerVisibilityState;
    isBuyNowDrawerVisible: boolean;
    isAddMoneyNowDrawerVisible: boolean;
    newPaymentRequest: CheckoutRequest;
    errorData: BuyNowError | null; // used in web buy-now
    purchaseSuccessData: PurchaseSuccessData | null; // used in web buy-now
};
const initialState: BuyNowFlow = {
    paymentMethod: undefined,
    newCreditCard: null,
    buyNowDrawerView: 'Details',
    isBuyNowDrawerVisible: false,
    isAddMoneyNowDrawerVisible: false,
    newPaymentRequest: emptyCheckoutRequest,
    errorData: null,
    purchaseSuccessData: null,
};

const slice = createSlice(initialState, 'UI/BuyNow');

export const buyNowFlow = {
    ...slice,
    resetState: slice.configureAction('Reset', () => (state) => ({
        ...initialState,
        paymentMethod: state.paymentMethod?.creditCardId === -1 ? undefined : state.paymentMethod,
    })),
    setNewPayment: slice.configureAction(
        'SetNewPayment',
        (newCheckoutRequest: CheckoutRequest) => (state) => {
            const card = newCheckoutRequest.useNewDebitCard
                ? newCheckoutRequest.newDebitCard
                : newCheckoutRequest.newCreditCard;

            if (newCheckoutRequest.paymentMethod) {
                newCheckoutRequest.paymentMethod!.creditCardId = -1;
            }
            const newCreditCard = {
                id: -1,
                authToken: '',
                maskedCardNumber:
                    '************' + card.cardNumber.substring(card.cardNumber.length - 4),
                creditCardType: CreditCardType.Visa,
                cardSubType: newCheckoutRequest.paymentMethod!.paymentSubType!,
            };
            return {
                ...state,
                newPaymentRequest: newCheckoutRequest,
                newCreditCard,
                paymentMethod: newCheckoutRequest.paymentMethod!,
            };
        }
    ),
};

// thunks
export const fetchBuyNowPaymentMethod = () => async (dispatch: Dispatch) => {
    const result = await getPaymentTypesForBuyNow();
    if (result.data?.length) {
        dispatch(buyNowFlow.update({ paymentMethod: result.data[0] }));
    }
};

// TODO(ALL): remove `drawer` from variable names that are shared on web
export const selectBuyNowDrawerView = (s: FeaturesState) => s.store.ui.buyNow.buyNowDrawerView;
export const selectBuyNowPaymentMethod = (s: FeaturesState) => s.store.ui.buyNow.paymentMethod;
export const selectIsBuyNowDrawerVisible = (s: FeaturesState) =>
    s.store.ui.buyNow.isBuyNowDrawerVisible;
export const selectIsAddMoneyNowDrawerVisible = (s: FeaturesState) =>
    s.store.ui.buyNow.isAddMoneyNowDrawerVisible;
export const selectBuyNowNewPaymentRequest = (s: FeaturesState) =>
    s.store.ui.buyNow.newPaymentRequest;
export const selectHasAddedPaymentMethod = (s: FeaturesState) =>
    s.store.ui.buyNow.newPaymentRequest.useNewCreditCard ||
    s.store.ui.buyNow.newPaymentRequest.useNewDebitCard;
export const selectBuyNowErrorData = (s: FeaturesState) => s.store.ui.buyNow.errorData;
export const selectBuyNowPurchaseSuccessData = (s: FeaturesState) =>
    s.store.ui.buyNow.purchaseSuccessData;

export default buyNowFlow.reducer;
export const selectPaymentType = createSelector(
    (_: FeaturesState, paymentMethod?: PaymentMethod | null) => paymentMethod,
    (s: FeaturesState, _?: PaymentMethod | null) => s.store.account,
    (s: FeaturesState, _?: PaymentMethod | null) => s.store.ui.buyNow,
    (
        paymentMethod: PaymentMethod | undefined | null,
        { achAccount, savedCreditCards }: AccountState,
        buyNow: BuyNowFlow
    ): [string, string] => {
        if (paymentMethod) {
            const isNewPaymentMethod = Boolean(buyNow.newCreditCard);
            if (!isNewPaymentMethod) {
                switch (paymentMethod?.paymentType) {
                    case PaymentType.BankAccount: {
                        return [
                            'Bank Account',
                            achAccount?.accountNumber?.substring(
                                achAccount.accountNumber.length - 4
                            ) || '',
                        ];
                    }
                    case PaymentType.CreditCard: {
                        if (savedCreditCards && savedCreditCards.length > 0) {
                            const creditCard = savedCreditCards.find(
                                (card) => card.id === paymentMethod.creditCardId
                            );
                            if (creditCard) {
                                return [
                                    `${creditCard.cardSubType} Card`,
                                    creditCard.maskedCardNumber?.substring(
                                        creditCard.maskedCardNumber.length - 4
                                    ) || '',
                                ];
                            }
                        }
                        return ['Credit Card', ''];
                    }
                    default:
                        return ['No Payment Found', ''];
                }
            } else {
                // CASE: The payment method is an unsaved credit/debit-card,
                // we should render the information that the user input
                const creditCard = buyNow.newCreditCard;
                if (creditCard) {
                    if (creditCard.cardSubType && creditCard.maskedCardNumber) {
                        return [
                            `${creditCard.cardSubType} Card`,
                            creditCard.maskedCardNumber!.substring(
                                creditCard.maskedCardNumber!.length - 4
                            ),
                        ];
                    }
                }
                return ['', ''];
            }
        }
        return ['', ''];
    }
);
