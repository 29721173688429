import React, { useContext, useCallback } from 'react';
import { Formik, Form } from 'formik';

import { EnrollmentContext } from '../context';

import { OrgProfileQuestionWrapper } from './OrgProfileQuestionWrapper';

import { Question } from 'features/coordinator/enroll/components/Question';
import { required } from 'common/modules/form-validation/validation';
import { withMessage, combineValidators } from 'common/modules/form-validation';
import { FormikTextareaFieldWithCharCount } from 'components/fields/FormikTextareaField';
import { Organization } from 'common/api/crm/models/Organization';

const MAX_LENGTH = 140;
const fieldValidator = combineValidators(
    withMessage('The Fundraising Purpose field is required.')(required)
);

export const OrgProfileQuestion6 = () => {
    const { organization, setOrganization, goToNextStep } = useContext(EnrollmentContext);
    const question = "What's your main reason for fundraising?";

    const _onSubmit = useCallback(
        async (values: Organization) => {
            setOrganization({
                ...values,
            });
            goToNextStep();
        },
        [setOrganization, goToNextStep]
    );

    return (
        <OrgProfileQuestionWrapper stepProgress={[4, 5]}>
            <Question className="mt-32 sm:mt-36" question={question}>
                <Formik validateOnChange initialValues={organization} onSubmit={_onSubmit}>
                    {() => (
                        <Form noValidate className="max-w-2xl mx-auto mt-8">
                            <FormikTextareaFieldWithCharCount<Organization>
                                name="fundraisingPurpose"
                                label=""
                                validate={fieldValidator}
                                rows={4}
                                maxLength={MAX_LENGTH}
                            />
                            <button className="my-5 md:my-7 font-semibold text-lg underline text-brand">
                                Next
                            </button>
                        </Form>
                    )}
                </Formik>
            </Question>
        </OrgProfileQuestionWrapper>
    );
};
