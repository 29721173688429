import { mapApiResponse } from '../mapApiResponse';
import { BrandSearchResponse } from '../models/BrandSearchResponse';
import { SearchResultBrand } from '../models/SearchResultBrand';

import { propertyNormalizer } from 'common/utils/propertyNormalizer';

interface SearchResult {
    brands: SearchResultBrand[];
    facets?: { [key: string]: [{ count: number; value: string }] };
}

export const toSearchResult = mapApiResponse<BrandSearchResponse, SearchResult>((response) => {
    const { data } = response;

    return {
        data: {
            count: data['@odata.count'],
            brands: propertyNormalizer(data.value),
            facets: propertyNormalizer(data['@search.facets']),
        },
    };
});
