import { ZXCVBNResult } from 'zxcvbn';
// ^ Make sure to only import zxcvbn *types* here.
// The library itself should be dynamically loaded with loadPasswordScorer since it's quite large.

export const POSSIBLE_SCORES = [0, 1, 2, 3, 4] as const;
export type PasswordScore = typeof POSSIBLE_SCORES[number];

export const loadPasswordScorer = async () => {
    const zxcvbn = (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')).default;
    return (value: string) => zxcvbn(value);
};

export const getPasswordScore = async (value: string): Promise<ZXCVBNResult> => {
    const scorePassword = await loadPasswordScorer();
    return scorePassword(value);
};
