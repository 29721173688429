import { requestWallet, selectUserCards } from 'common/features/store/duck/wallet/duck';
import { delay, isDefined } from 'common/utils';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'rootStore';

export const useCardsFromWallet = () => {
    const [hasFetchedCards, setHasFetchedCards] = useState(false);
    const { userId } = useRootSelector((s) => s.store.account);
    const myCardsState = useRootSelector(selectUserCards);

    const loadingRef = useRef<boolean>(false);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (Object.keys(myCardsState).length > 0) {
            setHasFetchedCards(true);
        }
    }, [myCardsState]);

    useEffect(() => {
        if (userId && !loadingRef.current && !(Object.keys(myCardsState).length > 0)) {
            loadingRef.current = true;
            const prom = new Promise((resolve) => resolve(dispatch(requestWallet(userId))));

            prom.finally(async () => {
                await delay(1000);
                setHasFetchedCards(true);
            });
        }
    }, [dispatch, userId, myCardsState]);

    return (
        (hasFetchedCards && myCardsState && Object.values(myCardsState || {}).filter(isDefined)) ||
        []
    );
};
