import React from 'react';

interface ComponentWithChildren {
    children: React.ReactNode;
    className?: string;
}

interface QuestionProps extends ComponentWithChildren {
    question: React.ReactNode;
}

const QuestionContainer = ({ className, children }: ComponentWithChildren) => {
    return <div className={`text-center ${className ?? ''}`}>{children}</div>;
};

const QuestionHeading = ({ className = '', children }: ComponentWithChildren) => {
    return (
        <div
            className={`text-xl md:text-2xl text-brand-dark text-left font-semibold mb-5 ${className}`}
        >
            {children}
        </div>
    );
};

export const Question = ({ className = '', question, children }: QuestionProps) => {
    return (
        <QuestionContainer className={className}>
            <QuestionHeading>{question}</QuestionHeading>
            {children}
        </QuestionContainer>
    );
};
