import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FavoriteEmptyCard } from 'components/brand-card/FavoriteEmptyCard';
import Skeleton from 'react-loading-skeleton';
import { Btn } from 'components/btn/Btn';
import { getBrands } from 'common/api/search/getBrands';
import { useBrandList } from 'common/hooks/useBrandList';
import { searchResultBrandsToBrands } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { BrandCardSlim } from 'components/brand-card/BrandCardSlim';
import { useRootSelector } from 'rootStore';
import { createStyle, createStyles } from 'utils/createStyle';
import { convertBrandToCardProps } from 'components/bonus/CardProps';
import { selectLoginState } from 'common/features/store/duck/account/duck';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import useMediaQueries from 'hooks/useMediaQueries';
import { editFilter } from 'common/utils/shipToCoordinatorUtils';
import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import { delay } from 'common/utils';
import { selectFavorites } from 'common/features/store/duck/brands/duck';

export const FavoritesSection = () => {
    const history = useHistory();
    const favorites = useRootSelector(selectFavorites);
    const isLoggedIn = useRootSelector(selectLoginState);
    const dispatch = useDispatch<any>();
    const { data, loadData, isLoading } = useBrandList(searchResultBrandsToBrands);
    const [cardWidth, setCardWidth] = useState<number>(180);
    const isShipToCoordinatorEnabled = useSelector(selectIsShipToCoordinatorEnabled);
    const filter = editFilter(
        isShipToCoordinatorEnabled,
        `search.in(Id, '${favorites.join()}')`
    ) as string;

    const queries = useMemo(
        () => ({
            '(max-width: 570px)': () => {
                setCardWidth(150);
            },
            '(min-width: 571px)': () => {
                setCardWidth(180);
            },
        }),
        []
    );

    useMediaQueries(queries);

    // compute the layout
    const styles = createStyles({
        cardContainer: [
            '',
            {
                width: `${cardWidth}px`,
                height: 210,
            },
        ],
        resultsContainer: createStyle('grid justify-center gap-3 mb-5', {
            '@media (min-width: 780px)': {
                gridTemplateColumns: `repeat(4, ${cardWidth}px)`,
            },
            '@media (max-width: 840px)': {
                gridTemplateColumns: `repeat(2, ${cardWidth}px)`,
            },
            '@media (max-width: 570px)': {
                gridTemplateColumns: `repeat(2, ${cardWidth}px)`,
            },
        }),
        favoritesOverrides: [
            '',
            {
                width: `${cardWidth}px`,
            },
        ],
    });

    const renderLoading = () =>
        [...Array(4)].map((_, i) => (
            <Skeleton key={i} className={styles.cardContainer} width={cardWidth} height={210} />
        ));

    const renderFavorites = () => {
        const favoriteBrands = data && data?.length > 4 ? data.slice(0, 4) : data;
        return (
            <>
                {favoriteBrands?.map((brd) => {
                    return (
                        <BrandCardSlim
                            key={brd.id}
                            styles={styles}
                            data={convertBrandToCardProps(brd)}
                        />
                    );
                })}
                {[...Array(4 - (favoriteBrands?.length || 0))].map((_, i) => (
                    <FavoriteEmptyCard key={i} styles={styles.favoritesOverrides} />
                ))}
            </>
        );
    };

    const refLoadComplete = useRef(false);

    useEffect(() => {
        if (favorites.length > 0 && !isLoading && !data?.length && !refLoadComplete.current) {
            refLoadComplete.current = true;
            loadData(getBrands)({
                filter,
                orderby: 'Name',
                search: '',
                searchMode: 'any',
                queryType: 'full',
                top: 4,
            }).finally(() => {
                delay(1000).then(() => {
                    refLoadComplete.current = false;
                });
            });
        }
    }, [loadData, dispatch, favorites, isLoading, data, filter]);

    if (!isLoggedIn && !isLoading) {
        return null;
    }

    const navToFavorites = () => {
        history.push(`${Routes.Favorites}`);
    };

    return (
        <div className="w-full p-6 flex items-center flex-col max-w-full sm:max-w-7xl ml-auto mr-auto">
            <h4 className="text-brand-dark text-3xl font-semibold mb-7">My Favorites</h4>
            <div className={`${styles.resultsContainer}`}>
                {isLoading ? renderLoading() : renderFavorites()}
            </div>
            <Btn onClick={navToFavorites}>See All My Favorites</Btn>
        </div>
    );
};
