import React, { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SlideFromRightToLeft } from 'components/slide-in/SlideFromRight';
import { createStyles } from 'utils/createStyle';

const classes = createStyles({
    container: ['border-grey-1 border border-solid text-grey-1 w-full text-left'],
    header: ['text-center underline flex items-center justify-center', { height: 60 }],
});

interface MobileDialogProps {
    header: React.ReactNode;
    btnName: string;
    footer: (onClick: React.MouseEventHandler) => React.ReactNode;
    children: React.ReactNode;
    styles: { [key: string]: string };
    onApply: Function;
    onToggle?: (_: boolean) => void;
}

export const MobileDialog = ({
    header,
    btnName,
    footer,
    children,
    styles,
    onApply,
    onToggle,
}: MobileDialogProps) => {
    const [open, setOpen] = useState(false);

    const setVisibilityState = (value: boolean) => {
        if (onToggle) {
            onToggle(value);
        }
        setOpen(value);
    };

    const onClick = () => {
        // apply the filters then close the dialog
        onApply();
        setVisibilityState(false);
    };

    useEffect(() => {
        if (!open) {
            document.body.style.overflowY = '';
        } else {
            document.body.style.overflowY = 'hidden';
        }
    }, [open]);

    return (
        <div className={`${classes.container} ${styles.container}`}>
            <div
                className={`brands_${btnName.toLowerCase()}_button ${classes.header} ${
                    styles.header
                }`}
                onClick={() => setVisibilityState(!open)}
            >
                {btnName}
            </div>
            <SlideFromRightToLeft open={open} setOpen={setVisibilityState} contentClasses="p-2">
                <div className="h-full w-full flex flex-col">
                    <div className="flex justify-between mb-2">
                        <div className="text-brand-dark text-xl font-semibold">{header}</div>
                        <FontAwesomeIcon
                            onClick={() => setVisibilityState(!open)}
                            className="cursor-pointer text-brand"
                            icon={faTimes}
                            size="lg"
                        />
                    </div>
                    <div className="text-md overflow-auto h-auto">{children}</div>
                    <div className="flex justify-center">{footer(onClick)}</div>
                </div>
            </SlideFromRightToLeft>
        </div>
    );
};
