import { Family } from '../models/Family';
import { ReviewResponse } from '../models/ReviewResponse';
import { OrganizationMembership } from '../models/OrganizationMembership';
import { OrgEnrollmentReferralInfo } from '../models/OrgEnrollmentReferralInfo';

import { FAMILY_URL, FAMILY_ITERABLE_TOKEN_URL, WEB_ITERABLE_TOKEN_URL } from 'common/api/config';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import type {
    FamilySubscriptionResponse,
    FamilySubscriptionUpdateRequest,
    IterableTokenResponse,
    MessageMedium,
    Channels,
    MessageTypes,
} from './types';
import { APIResponse } from 'common/api/models/';
import { mapSubscriptionsToUpdateRequest } from './map-subscriptions-to-push-enroll-request';

export const updateFamily = async (family: Family) => {
    const result = await withTokensIfNeeded(`${FAMILY_URL}?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(family),
    });
    return result;
};

export const logReviewResponse = async (reviewResponse: ReviewResponse) => {
    const result = await withTokensIfNeeded(`${FAMILY_URL}/review?api-version=1`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reviewResponse),
    });
    return result;
};

export const getUserOrganizationMemberships = async () => {
    const result = await withTokensIfNeeded<OrganizationMembership[]>(
        `${FAMILY_URL}/organizations?api-version=1`
    );
    return result;
};

export const setUserDefaultOrganization = async (orgId: string) => {
    const result = await withTokensIfNeeded(
        `${FAMILY_URL}/organization/${orgId}/default?api-version=1`,
        {
            method: 'PUT',
        }
    );
    return result;
};

export const addOrgEnrollmentReferralInfo = async (
    familyId: string,
    info: OrgEnrollmentReferralInfo
) => {
    const result = await withTokensIfNeeded(
        `${FAMILY_URL}/${familyId}/org-enrollment-referral-info?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(info),
        }
    );
    return result;
};

export const addUserToOrg = async (orgId: string) => {
    const res = await withTokensIfNeeded(`${FAMILY_URL}/organization/${orgId}?api-version=1`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};

export const removeUserFromOrg = async (orgId: string) => {
    const res = await withTokensIfNeeded(
        `${FAMILY_URL}/organization/${orgId}/deactivate?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
    return res;
};

export const deactivateAccount = async () => {
    const res = await withTokensIfNeeded(`${FAMILY_URL}/deactivate?api-version=1`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res;
};

export interface CreateEnrollmentCodeResponse {
    code: null | string;
    errorCode: null | string;
    errorMessage: null | string;
}

export const createEnrollmentCodeFromOrg = async (orgId: string) => {
    return await withTokensIfNeeded<CreateEnrollmentCodeResponse>(
        `${FAMILY_URL}/enrollmentcode/${orgId}?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getIterableToken = async (): Promise<string | undefined> => {
    try {
        const result = await withTokensIfNeeded<IterableTokenResponse>(FAMILY_ITERABLE_TOKEN_URL);
        return result?.data?.token || undefined;
    } catch (error) {
        return undefined;
    }
};

export const getWebIterableToken = async (): Promise<string | undefined> => {
    try {
        const result = await withTokensIfNeeded<IterableTokenResponse>(WEB_ITERABLE_TOKEN_URL);
        return result?.data?.token || undefined;
    } catch (error) {
        return undefined;
    }
};

export const getFamilySubscriptions = async (): Promise<
    APIResponse<FamilySubscriptionResponse, null>
> => {
    const result = await withTokensIfNeeded<FamilySubscriptionResponse>(
        `${FAMILY_URL}/subscriptions?api-version=1`
    );

    return result;
};

export const updateFamilySubscriptions = async (payload: FamilySubscriptionUpdateRequest) => {
    return await withTokensIfNeeded(`${FAMILY_URL}/subscriptions?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
};

export const subscribeToDefaultPushNotifications = async () => {
    // Fetch all current subscriptions
    const subscriptionsResponse = await getFamilySubscriptions();

    if (!subscriptionsResponse?.response?.ok) {
        console.error(`Error fetching subscriptions: ${subscriptionsResponse.error}`);
        return;
    }

    // Call the mapping function to generate the update request
    const updateRequest = mapSubscriptionsToUpdateRequest(subscriptionsResponse.data);

    // Call the method to update the subscriptions
    await updateFamilySubscriptions(updateRequest);
};

export const getChannelByName = (
    mediumName: string,
    channelName: string,
    mediums: MessageMedium[]
) => {
    for (let i = 0; i < mediums.length; ++i) {
        const medium = mediums[i];
        if (medium.messageMedium === mediumName) {
            for (let j = 0; j < medium.channels.length; ++j) {
                const channel = medium.channels[j];
                if (channel.channelName === channelName) {
                    return channel;
                }
            }
        }
    }
    return undefined;
};

export const getMessageTypeByName = (messageTypeName: string, messageTypes: MessageTypes[]) => {
    for (let k = 0; k < messageTypes.length; ++k) {
        if (messageTypes[k].messageTypeName === messageTypeName) {
            return messageTypes[k];
        }
    }
    return undefined;
};

export const groupBySubscribedAndUnSubscribed = (
    mediums: MessageMedium[]
): FamilySubscriptionUpdateRequest => {
    const subscribedMessageTypeIds: number[] = [];
    const unsubscribedMessageTypeIds: number[] = [];
    const unsubscribedChannelIds: number[] = [];

    for (let i = 0; i < mediums.length; ++i) {
        const medium = mediums[i];

        for (let j = 0; j < medium.channels.length; ++j) {
            const channel = medium.channels[j];
            let messageTypesSubscribedCount = 0;

            for (let k = 0; k < channel.messageTypes.length; ++k) {
                if (channel.messageTypes[k].isSubscribed) {
                    ++messageTypesSubscribedCount;
                    subscribedMessageTypeIds.push(channel.messageTypes[k].messageTypeId);
                } else {
                    unsubscribedMessageTypeIds.push(channel.messageTypes[k].messageTypeId);
                }
            }

            // If channel has no subscribed message types then we automatically unsubscribe from the channel
            if (messageTypesSubscribedCount === 0) {
                unsubscribedChannelIds.push(channel.channelId);
            }
        }
    }
    return { subscribedMessageTypeIds, unsubscribedMessageTypeIds, unsubscribedChannelIds };
};

export const normalizePreferences = (data: FamilySubscriptionResponse | undefined | null) => {
    if (data) {
        const mediums = [...data.messageMediums];
        for (let i = 0; i < mediums.length; i++) {
            const medium = data.messageMediums[i];
            for (let j = 0; j < medium.channels.length; j++) {
                const channel = medium.channels[j];
                channel.messageTypes = channel.messageTypes.map((m) => ({
                    ...m,
                    isSubscribed: m.isSubscribed && channel.isSubscribed,
                }));
            }
        }
        return mediums;
    }
    return [];
};
