export const patchFetch = () => {
    const _fetch = window.fetch;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-explicit-any
    // @ts-ignore
    (window as unknown)['fetch'] = async (...args) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await _fetch(...args);
        const url = args[0];

        if (url.toString().toLowerCase().endsWith('/c/shop/product/getproductgiftimages')) {
            const results = [
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_B.jpg',
                    ImageName: 'AMAZ002_Small_B.jpg',
                    ImageId: 5043,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_C.jpg',
                    ImageName: 'AMAZ002_Small_C.jpg',
                    ImageId: 5044,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_D.jpg',
                    ImageName: 'AMAZ002_Small_D.jpg',
                    ImageId: 5045,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_E.jpg',
                    ImageName: 'AMAZ002_Small_E.jpg',
                    ImageId: 5046,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_F.jpg',
                    ImageName: 'AMAZ002_Small_F.jpg',
                    ImageId: 5047,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_G.jpg',
                    ImageName: 'AMAZ002_Small_G.jpg',
                    ImageId: 5048,
                    FaceplateId: '',
                },
                {
                    ImageUrl:
                        'https://ne1.wac.edgecastcdn.net/8026C7/ShopWithScrip.com/images/dynamic/vcert/AMAZ002_Small_H.jpg',
                    ImageName: 'AMAZ002_Small_H.jpg',
                    ImageId: 5049,
                    FaceplateId: '',
                },
            ];

            const blob = new Blob([JSON.stringify(results)], {
                type: 'application/json',
            });

            return new Response(blob, {
                status: 200,
                statusText: 'OK',
            });
        }

        return response;
    };
};
