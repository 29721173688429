import React, { useContext } from 'react';

import { Routes } from 'routes';
import { VoidedCheckStep as VoidedCheckComponent } from 'features/coordinator/enrollment/steps/VoidedCheckStep';
import { EnrollmentContext } from 'features/coordinator/enroll/context';

export const VoidedCheckStep = () => {
    const { organization } = useContext(EnrollmentContext);
    return (
        <VoidedCheckComponent
            ProgramCompletedRoute={Routes.EnrollCompleted}
            organization={organization}
        />
    );
};
