import React, { useEffect, useContext } from 'react';

import { useContinueOrgEnrollment } from '../components/useContinueOrgEnrollment';
import { EnrollmentContext } from '../enroll/context';

import { LoadingScreen } from 'components/loading-screen/LoadingScreen';
import { withAuth } from 'features/auth/components/withAuth';

export const OrgEnrollmentContinue = withAuth()(() => {
    const { onProgressLoaded } = useContext(EnrollmentContext);
    const { getEnrollmentProgress, getOrgApprovedAlertIfNeeded } = useContinueOrgEnrollment();

    useEffect(() => {
        const loadProgress = async () => {
            const progress = await getEnrollmentProgress();
            await getOrgApprovedAlertIfNeeded(progress);
            onProgressLoaded(progress);
        };
        loadProgress();
    }, [getEnrollmentProgress, onProgressLoaded, getOrgApprovedAlertIfNeeded]);

    return <LoadingScreen />;
});
