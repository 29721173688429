import { OrganizationEnrollment } from '../models/OrganizationEnrollment';

import { contentfulApi } from 'common/api/utils/contentful';
import { createAPIError } from 'common/api/utils/createAPIError';
import { APIResponse } from 'common/api/models';

export const getOrganizationEnrollment = async (
    id?: string
): Promise<APIResponse<OrganizationEnrollment>> => {
    const result = await contentfulApi.getEntries<OrganizationEnrollment, unknown>({
        content_type: 'orgEnrollment', // eslint-disable-line camelcase
        // Important to leave the include below so it will include the Assets (e.g. Linked Images)
        // The max value is 10 (which is the number of levels of Linked Items)
        // # https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links
        include: 10,
        ...(id ? { 'sys.id': id } : {}),
    });
    if (!result.data) {
        return result;
    }
    const firstResult = result.data.items[0];
    if (!firstResult) {
        return { error: createAPIError(null, new Error('No matching entries')) };
    }
    return { data: firstResult.fields };
};

export const getEnrollmentEntryById = async <T>(
    contentType: string,
    id?: string
): Promise<APIResponse<T>> => {
    const result = await contentfulApi.getEntries<T, unknown>({
        content_type: contentType, // eslint-disable-line camelcase
        // Important to leave the include below so it will include the Assets (e.g. Linked Images)
        // The max value is 10 (which is the number of levels of Linked Items)
        // # https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links
        include: 10,
        ...(id ? { 'sys.id': id } : {}),
    });
    if (!result.data) {
        return result;
    }
    const firstResult = result.data.items[0];
    if (!firstResult) {
        return { error: createAPIError(null, new Error('No matching entries')) };
    }
    return { data: firstResult.fields };
};
