import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { metadataMap, MetadataPage } from './constants';

interface MetadataProps {
    children: ReactNode;
    page: MetadataPage;
    brandName?: string;
}

export const Metadata = ({ children, page, brandName }: MetadataProps) => {
    const values = metadataMap[page];

    return (
        <>
            <Helmet>
                <title>{values?.title(brandName)}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta charSet={values?.charset} />
                <meta name="author" content={values?.author} />
                <meta name="description" content={values?.metaDescription(brandName)} />
                <meta name="title" content={values?.title(brandName)} />
                <meta name="og:description" content={values?.ogDescription(brandName)} />
                <meta name="og:title" content={values?.ogTitle(brandName)} />
                <meta name="twitter:description" content={values?.twitterDescription(brandName)} />
                <meta name="twitter:title" content={values?.twitterTitle(brandName)} />
                <link rel="icon" href={values.shortcutIcon} />
                {values.robots !== '' && <meta name="robots" content={values.robots} />}
            </Helmet>
            {children}
        </>
    );
};
