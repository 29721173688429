import { createStyle, createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';

export const chooseProgramStyles = createStyles({
    container: ['bg-white', { width: '580px' }],
    stepTitle: [
        'ml-4 mr-4 mt-8 text-xl font-semibold sm:ml-10 sm:mr-10 sm:mt-5 sm:text-3xl',
        {
            color: colors.darkBlue,
        },
    ],
    stepSubtitle: [
        'ml-4 mr-4 mt-2.5 sm:ml-10 sm:mr-10 sm:mt-5 sm:font-normal sm:text-base',
        {
            color: colors.grey2,
        },
    ],
    typeSelector: [
        'border w-1/2 ml-0 mr-0 pt-5 pb-5 text-center sm:border-none sm:ml-6 sm:mr-6 sm:pt-0 sm:pb-0 mt-9 mb-4 font-semibold text-base',
        {
            borderColor: colors.grey3,
            color: colors.darkBlue,
        },
    ],
    StyledRadio: createStyle('flex flex-col align-middle', {
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        borderRadius: '50%',
        boxShadow: 'inset 0 0 0 1px gainsboro',
        width: 15,
        height: 15,

        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        marginTop: '5px',
        '&:focus': {
            outline: 'none',
            boxShadow: 'inset 0 0 0 1px dodgerblue, 0 0 0 1px dodgerblue',
        },
    }),
    StyledIndicator: createStyle('bg-black text-brand', {
        width: 7,
        height: 7,
        borderRadius: '50%',
        backgroundColor: 'dodgerblue',
    }),
    radioContainer: ['ml-5 sm:ml-10 mt-5'],
    radioContainerTag: [
        'font-semibold text-xs mb-1',
        {
            color: colors.brand,
        },
    ],
    radioIcon: ['hidden sm:mt-1 sm:mr-8 sm:block'],
    radioContainerTitle: ['font-normal text-sm', { color: colors.brandDark }],
    radioContainerSubtitle: [
        'font-normal text-xs mt-1',
        {
            maxWidth: '305px',
        },
    ],
    button: ['ml-0 mt-5 sm:ml-10'],
    buttonContainer: ['flex justify-center sm:flex-none sm:justify-start'],
    helperText: [
        'text-center font-normal text-sm mt-5 mb-8 sm:ml-10 sm:text-left',
        {
            color: colors.grey2,
        },
    ],
    helperLink: [
        'font-semibold',
        {
            color: colors.brand,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    ],
    programTypeMarker: [
        'w-7 h-1 mt-1',
        {
            borderRadius: '5px',
            background: colors.bonusPink,
        },
    ],
    recommendedTag: [
        'font-semibold text-xs',
        {
            color: colors.bonusPink,
            marginTop: '-16px',
        },
    ],
    saveForLaterHelper: [
        'invisible sm:font-semibold sm:text-sm sm:visible',
        {
            float: 'right',
            marginTop: '-20px',
            color: colors.brand,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    ],
    nonSelectedProgramType: [
        'font-normal text-base',
        {
            color: colors.brand,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    ],
});
