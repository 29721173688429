import React, { useContext, useEffect, useState } from 'react';

import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { UserContent } from 'components/user-content/UserContent';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { OnlineProgramBody } from '../components/OnlineProgramBody';
import { OfflineProgramBody } from '../components/OfflineProgramBody';
import { chooseProgramStyles as styles } from '../styles/ChooseProgramStyles';
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, setMaxStep } from 'common/features/store/duck/ui/org-enrollment-v2';
import { selectHomeDetails } from 'common/features/store/duck/home/duck';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Render } from 'components/loading-screen/Render';

enum ProgramType {
    Online,
    Offline,
}

export const ChooseProgramStep = () => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext) as unknown as {
        contentfulEntry: OrganizationEnrollmentV2;
    };
    const dispatch = useDispatch<any>();
    const homeDetails = useSelector(selectHomeDetails);

    const [programType, setProgramType] = useState<ProgramType>(ProgramType.Online);

    const tab1 = useSpring({
        from: { opacity: 1 },
        to: { opacity: programType === ProgramType.Online ? 1 : 0 },
    });
    const tab2 = useSpring({
        from: { opacity: 1 },
        to: { opacity: programType === ProgramType.Offline ? 1 : 0 },
    });

    useEffect(() => {
        dispatch(setMaxStep(4));
        dispatch(setCurrentStep(4));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Render<React.SVGProps<SVGElement>>
            when={Boolean(contentfulEntry) && Boolean(homeDetails)}
            loadingComponentProps={{ className: 'm-auto mt-40 mb-40' }}
            renderers={{ LoadingComponent: ActivityIndicator }}
        >
            <div className={styles.stepTitle}>{contentfulEntry?.programSelectionTitle}</div>
            <UserContent
                className={styles.stepSubtitle}
                content={contentfulEntry?.programSelectionSubtitle}
            />
            <div className="flex flex-row justify-center">
                <div
                    onClick={() => {
                        setProgramType(ProgramType.Online);
                    }}
                    className={`${styles.typeSelector} border-l-0`}
                >
                    <div className="flex flex-col items-center">
                        <div className={styles.recommendedTag}>
                            {contentfulEntry?.onlineProgramTag}
                        </div>
                        <div
                            className={
                                programType !== ProgramType.Online
                                    ? styles.nonSelectedProgramType
                                    : ''
                            }
                        >
                            <span>{contentfulEntry?.onlineProgramTitle}</span>
                        </div>
                        <animated.div
                            style={tab1}
                            className={styles.programTypeMarker}
                        ></animated.div>
                    </div>
                </div>
                <div
                    data-testid="offline_button"
                    onClick={() => {
                        setProgramType(ProgramType.Offline);
                    }}
                    className={`${styles.typeSelector} border-r-0 border-l-0`}
                >
                    <div className="flex flex-col items-center">
                        <div
                            className={
                                programType !== ProgramType.Offline
                                    ? styles.nonSelectedProgramType
                                    : ''
                            }
                        >
                            <span>{contentfulEntry?.offlineProgramTitle}</span>
                        </div>
                        <animated.div
                            style={tab2}
                            className={styles.programTypeMarker}
                        ></animated.div>
                    </div>
                </div>
            </div>
            <FadeIn when={programType === ProgramType.Online}>
                <OnlineProgramBody />
            </FadeIn>
            <FadeIn when={programType === ProgramType.Offline}>
                <OfflineProgramBody />
            </FadeIn>
        </Render>
    );
};
