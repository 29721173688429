import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { enableBatching } from 'redux-batched-actions';

import { makeLens } from 'common/modules/lens/makeLens';
import { persist } from 'common/modules/persisted-state/persist';
import { persistentStorage } from 'common/utils/persistentStorage';
import { featuresReducer, FeaturesState } from 'common/features/featuresReducer';
import { debounceMiddleware } from 'common/features/store/duck/middleware/debounceMiddleware';

export const rootReducer = featuresReducer;

export type RootState = FeaturesState;

export const rootL = makeLens<RootState>();

export const createRootStore = async () => {
    const store = createStore(
        enableBatching(rootReducer),
        composeWithDevTools(applyMiddleware(debounceMiddleware, thunk))
    );

    return store;
};

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
