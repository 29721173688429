export enum PromiseState {
    Pending = 'Pending',
    Fulfilled = 'Fulfilled',
    Rejected = 'Rejected',
}

export interface StatefulPromise<T> {
    promise: Promise<T>;
    state: PromiseState;
}

export const makeStateful = <T>(promise: Promise<T>): StatefulPromise<T> => {
    const statefulPromise: StatefulPromise<T> = { promise, state: PromiseState.Pending };
    promise
        .then(() => {
            statefulPromise.state = PromiseState.Fulfilled;
        })
        .catch(() => {
            statefulPromise.state = PromiseState.Rejected;
        });
    return statefulPromise;
};
