import { FieldProps, getIn } from 'formik';

export const hasError = <Values>(props: FieldProps<Values>): boolean =>
    // NOTE: The first one returns a boolean, the second returns a string or undefined
    getIn(props.form.touched, props.field.name) &&
    Boolean(getIn(props.form.errors, props.field.name));

export const getError = <Values>(props: FieldProps<Values>): string => {
    if (hasError(props)) {
        return getIn(props.form.errors, props.field.name);
    }

    return '';
};
