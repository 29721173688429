import { CartResponse } from 'common/api/e-comm/cart/lineitem/models/CartItems';
import { ProductType } from 'common/api/e-comm/models/Product';

export const ProductLabel: Record<ProductType, string> = {
    [ProductType.EGiftCard]: 'eGift Card',
    [ProductType.GiftCard]: 'Gift Card',
    [ProductType.Reload]: 'Card Reload',
};

export interface PurchaseData {
    cart: CartResponse;
    total: number;
    shippingCost?: number;
    orderId: string;
    isBuyNow: boolean;
}
