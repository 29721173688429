import { Dispatch } from 'redux';

import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';
import { AchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { APIError, APIResponse } from 'common/api/models';
import {
    getUserAchAccount,
    cancelUserAchAccount,
    deleteUserAchAccount,
} from 'common/api/e-comm/ach';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { SavedCreditCard } from 'common/api/e-comm/models/CreditCard';
import { getSavedCreditCards, deleteCreditCard } from 'common/api/e-comm/credit-cards/service';
import { removeFirst } from 'common/utils';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import {
    PaymentMethod,
    getDefaultPaymentMethod,
    setDefaultPaymentMethod,
} from 'common/api/e-comm/payment-types';
import { getPhonesByUser } from 'common/api/users/twofactor/phone';
import { AccountRoles, getAccount } from 'common/api/users/service';
import { getUserClaimsFromBff } from 'common/api/bff';

export enum UserAuthState {
    INITIAL = 'initial',
    AUTHENTICATED = 'authenticated',
    NOTAUTHENTICATED = 'not-authenticated',
}

export interface AccountState {
    userId: string | null;
    userAuthState: UserAuthState;
    hasSetupTwoFactor: boolean | null;
    isCoordinator: boolean;
    achAccount: AchAccount | null;
    achAccountAsyncState: AsyncActionState<[], APIError>;
    savedCreditCards: SavedCreditCard[] | null;
    savedCreditCardsAsyncState: AsyncActionState<[], APIError>;
    defaultPayment: PaymentMethod | null;
    defaultPaymentAsyncState: AsyncActionState;
    sessionMaxInactivityMinutes: number | null;
    role: AccountRoles;
    legacyId: string | undefined;
    accountAsyncState: AsyncActionState;
}

export const initialAccountState: AccountState = {
    userId: null,
    userAuthState: UserAuthState.INITIAL,
    hasSetupTwoFactor: null,
    isCoordinator: false,
    achAccount: null,
    achAccountAsyncState: initialAsyncActionState,
    savedCreditCards: null,
    savedCreditCardsAsyncState: initialAsyncActionState,
    defaultPayment: null,
    defaultPaymentAsyncState: initialAsyncActionState,
    sessionMaxInactivityMinutes: null,
    role: AccountRoles.FAMILY,
    legacyId: undefined,
    accountAsyncState: { ...initialAsyncActionState, loading: true },
};

const { update, reducer, configureAction } = createSlice(initialAccountState, 'ACCOUNT');
export const accountReducer = reducer;
export const updateAccount = update;

export const getUserAchAccountThunk = createApiThunk(
    getUserAchAccount,
    () => (achAccountAsyncState, achAccount, error) => {
        if (achAccount) {
            return update({ achAccountAsyncState, achAccount });
        } else if (error && hasErrorWithStatus({ error }, StatusCode.NotFound)) {
            return update({ achAccountAsyncState, achAccount: null });
        }
        return update({ achAccountAsyncState });
    }
);

export const getSavedCreditCardsThunk = createApiThunk(
    getSavedCreditCards,
    () => (savedCreditCardsAsyncState, savedCreditCards) =>
        savedCreditCards
            ? update({ savedCreditCardsAsyncState, savedCreditCards })
            : update({ savedCreditCardsAsyncState })
);

export const logIn = (
    userId: string,
    sessionMaxInactivityMinutes: number | null = null,
    userAuthState: UserAuthState = UserAuthState.INITIAL,
    isCoordinator = false,
    role: AccountRoles = AccountRoles.FAMILY,
    legacyId: string | undefined = undefined
) => update({ userId, sessionMaxInactivityMinutes, userAuthState, isCoordinator, role, legacyId });
export const logOut = (partialInitialState?: Partial<AccountState>) =>
    update({ ...initialAccountState, ...partialInitialState });
export const updateUserAchAccount = (achAccount: AchAccount | null) => update({ achAccount });

export const removeCreditCard = configureAction<number>('REMOVE_CREDIT_CARD', (id) => (s) => {
    if (s.savedCreditCards) {
        const removedCreditCard = s.savedCreditCards.find((creditCard) => creditCard.id === id);
        if (removedCreditCard) {
            return {
                ...s,
                savedCreditCards: removeFirst(s.savedCreditCards || [], removedCreditCard),
            };
        }
    }
    return s;
});

const getRemoveUserAchAccountThunk =
    (api: () => Promise<APIResponse<null, null>>) => async (dispatch: Dispatch) => {
        const response = await api();

        if (!response.error) {
            dispatch(updateUserAchAccount(null));
        }

        return response;
    };

export const cancelUserAchAccountThunk = getRemoveUserAchAccountThunk(cancelUserAchAccount);
export const deleteUserAchAccountThunk = getRemoveUserAchAccountThunk(deleteUserAchAccount);

export const deleteCreditCardThunk = (id: number) => async (dispatch: Dispatch) => {
    const response = await deleteCreditCard(id);

    if (!response.error) {
        dispatch(removeCreditCard(id));
    }

    return response;
};

export const getDefaultPaymentMethodThunk = createApiThunk(
    getDefaultPaymentMethod,
    () => (defaultPaymentAsyncState, defaultPayment, error) => {
        if (error) {
            return update({ defaultPaymentAsyncState, defaultPayment: null });
        }
        return defaultPayment
            ? update({ defaultPaymentAsyncState, defaultPayment })
            : update({ defaultPaymentAsyncState });
    }
);

export const setDefaultPaymentMethodThunk =
    (paymentMethod: PaymentMethod) => (dispatch: Dispatch) => {
        dispatch(update({ defaultPayment: paymentMethod }));
        return setDefaultPaymentMethod(paymentMethod);
    };

export const hasSetupTwoFactorThunk =
    (userId: string | null) => async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const state = getState();
        const hasSetupTwoFactor = selectHasSetUpTwoFactor(state);

        if (userId && !hasSetupTwoFactor) {
            const { data: twoFactorPhones, error: twoFactorError } = await getPhonesByUser(userId);

            if (twoFactorError) {
                dispatch(updateAccount({ hasSetupTwoFactor: null }));
                return { hasSetupTwoFactor: null, error: twoFactorError };
            }
            if (twoFactorPhones) {
                const hasSetupTwoFactor = twoFactorPhones.length > 0;
                dispatch(updateAccount({ hasSetupTwoFactor }));
                return { hasSetupTwoFactor, error: null };
            }
        }
        return { hasSetupTwoFactor: null, error: null };
    };

export const refreshAuthenticationStatusThunk = async (
    dispatch: Dispatch,
    getState: () => FeaturesState
) => {
    const asyncState = getState().store.account.accountAsyncState;

    try {
        dispatch(
            updateAccount({
                accountAsyncState: {
                    ...asyncState,
                    loading: true,
                },
            })
        );

        const sessionInfo = await getSessionInfo();
        const { isSessionValid, userId, roleId } = sessionInfo;

        if (!isSessionValid) {
            dispatch(
                logOut({
                    ...initialAccountState,
                    userAuthState: UserAuthState.NOTAUTHENTICATED,
                })
            );
        } else {
            dispatch(
                updateAccount({
                    userAuthState: UserAuthState.AUTHENTICATED,
                    userId,
                    isCoordinator:
                        roleId === AccountRoles.ASSISTANT_COORDINATOR ||
                        roleId === AccountRoles.COORDINATOR,
                    role: roleId || AccountRoles.FAMILY,
                })
            );
        }

        return sessionInfo;
    } finally {
        dispatch(
            updateAccount({
                accountAsyncState: {
                    ...asyncState,
                    loading: false,
                    lastUpdate: Date.now(),
                },
            })
        );
    }
};

export interface SessionInfo {
    userId: string | null;
    roleId: AccountRoles | undefined;
    legacyId: string | undefined;
    isSessionValid: boolean;
    hasSessionExpired: boolean;
}

export const getSessionInfo = async (): Promise<SessionInfo> => {
    const [userResponse, accountResponse] = await Promise.all([
        getUserClaimsFromBff(),
        getAccount(),
    ]);

    const userId = (userResponse.data || []).find((claim) => claim.type === 'sub')?.value || null;
    const roleId = accountResponse.data?.organization?.roleId;
    const legacyId = (userResponse.data || []).find(
        (claim) => claim.type === 'sws_family_id'
    )?.value;

    // FEC-1081 = This is the only way that I found to check if the session has expired.
    // Ideally, we could get the remaining time from the getUserResponse (bff:session_expires_in)
    // https://glscrip.atlassian.net/wiki/spaces/GLSC/pages/2951708673/Handling+session+timeouts+on+Web+App
    const hasSessionExpired =
        userResponse.response?.status === 200 && accountResponse.response?.status === 401;

    const isSessionValid =
        userResponse.response?.status === 200 && accountResponse.response?.status === 200;

    return {
        isSessionValid,
        hasSessionExpired,
        userId,
        roleId,
        legacyId,
    };
};

export const selectDefaultPaymentType = (state: FeaturesState) =>
    state.store.account.defaultPayment;
export const selectLoginState = (state: FeaturesState) => state.store.account.userId != null;
export const selectUserId = (state: FeaturesState) => state.store.account.userId || '';
export const selectLegacyId = (state: FeaturesState) => state.store.account.legacyId || '';
export const selectUserRole = (state: FeaturesState) => state.store.account.role;
export const selectUserAuthState = (state: FeaturesState) => state.store.account.userAuthState;
export const selectAchAccountAsyncState = (s: FeaturesState) =>
    s.store.account.achAccountAsyncState;
export const selectAchAccount = (s: FeaturesState) => s.store.account.achAccount;
export const selectSavedCreditCardsAsyncState = (s: FeaturesState) =>
    s.store.account.savedCreditCardsAsyncState;
export const selectHasSetUpTwoFactor = (s: FeaturesState) => s.store.account.hasSetupTwoFactor;
