import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { X } from 'react-feather';
import classNames from 'classnames';

import { createEmitter, Emitter } from 'common/modules/event-emitter';
import { ModalStyles, AlertStyles } from 'styles/modals';
import { Btn } from 'components/btn/Btn';
import { usePreviousDifferent } from 'common/utils/usePreviousDifferent';
import { spin } from 'components/loading-overlay/LoadingOverlay';
import { firstFocusableChild } from 'utils/dom';
import { colors } from 'styles/settings';

export interface AlertButton {
    text: string;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
    secondary?: boolean;
}

export interface AlertConfig {
    title?: React.ReactNode;
    message: React.ReactNode;
    buttons?: AlertButton[];
    onClose?: () => void;
    showCloseButton?: boolean | null;
    largeAlert?: boolean | null;
}

export type AlertContextValue = Emitter<{ alert: AlertConfig }>;

const AlertContext = React.createContext<AlertContextValue>(createEmitter());

export const AlertProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const emitter = useRef<AlertContextValue>(createEmitter());

    return <AlertContext.Provider value={emitter.current}>{children}</AlertContext.Provider>;
};

const defaultButtons: AlertButton[] = [{ text: 'Ok' }];

export const AlertOutput = () => {
    const emitter = useContext(AlertContext);
    const [alerts, setAlerts] = useState<AlertConfig[]>([]);
    useEffect(() => {
        const unsubscribe = emitter.on('alert', (config) =>
            setAlerts((configs) => [...configs, config])
        );
        return () => unsubscribe();
    }, [emitter]);

    const [firstAlert] = alerts;
    const previousFirstAlert = usePreviousDifferent(firstAlert);
    const lastKnownFocus = useRef<HTMLOrSVGElement | null>(null);
    if (firstAlert && !previousFirstAlert) {
        lastKnownFocus.current = document.activeElement as HTMLOrSVGElement | null;
    }
    useEffect(() => {
        if (!firstAlert && previousFirstAlert) {
            lastKnownFocus.current?.focus();
        }
    }, [firstAlert, previousFirstAlert]);

    const buttonContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (firstAlert && buttonContainerRef.current) {
            firstFocusableChild(buttonContainerRef.current)?.focus();
        }
    }, [firstAlert]);

    const onOpenChange = useCallback(
        async (open: boolean) => {
            if (!open) {
                await setAlerts((configs) => configs.slice(1));

                // Without the spin(0), if focus is moved in the onClose callback,
                // radix thinks focus is moving outside the focus trap and resets it
                await spin(0);
                if (firstAlert.onClose) {
                    firstAlert.onClose();
                }
            }
        },
        [firstAlert]
    );

    const dismiss = useCallback(() => onOpenChange(false), [onOpenChange]);
    const preventEscape = useCallback((e: KeyboardEvent) => e.preventDefault(), []);

    if (!firstAlert) {
        return null;
    }

    return (
        <AlertDialog.Root open onOpenChange={onOpenChange}>
            <AlertDialog.Overlay className={ModalStyles.overlay} />
            <AlertDialog.Content
                className={ModalStyles.contentWrapper}
                onEscapeKeyDown={preventEscape}
            >
                <div
                    className={`${AlertStyles.content} ${
                        firstAlert.showCloseButton || firstAlert.largeAlert
                            ? 'max-w-md'
                            : 'max-w-sm'
                    }`}
                >
                    <div className={ModalStyles.header}>
                        {Boolean(firstAlert.title) && (
                            <AlertDialog.Title className={AlertStyles.title}>
                                {firstAlert.title}
                            </AlertDialog.Title>
                        )}
                        {firstAlert.showCloseButton && (
                            <button
                                onClick={dismiss}
                                className={ModalStyles.closeButton}
                                aria-label="Close"
                            >
                                <X color={colors.brand} size={24} />
                            </button>
                        )}
                    </div>
                    <AlertDialog.Description as="div" className={AlertStyles.body}>
                        {firstAlert.message}
                    </AlertDialog.Description>
                    <div ref={buttonContainerRef} className={AlertStyles.buttonContainer}>
                        {(firstAlert.buttons || defaultButtons).map((button, i) => {
                            const Component = i === 0 && !button.secondary ? Btn : 'button';
                            return (
                                <Component
                                    key={i}
                                    className={classNames(button.className, {
                                        [AlertStyles.secondaryButton]: i > 0 || button.secondary,
                                    })}
                                    onClick={async (e) => {
                                        await dismiss();
                                        if (button.onClick) {
                                            button.onClick(e);
                                        }
                                    }}
                                >
                                    {button.text}
                                </Component>
                            );
                        })}
                    </div>
                </div>
            </AlertDialog.Content>
        </AlertDialog.Root>
    );
};

export const useAlert = () => {
    const emitter = useContext(AlertContext);
    const alert = useCallback((config: AlertConfig) => emitter.emit('alert', config), [emitter]);
    return alert;
};
