import React from 'react';
import { useSpring, animated, UseSpringProps } from 'react-spring';

interface FadeInProps {
    when: boolean;
    springConfig?: UseSpringProps;
    className?: string;
}

export const FadeIn = ({
    when,
    children,
    className = '',
    springConfig = {},
}: React.PropsWithChildren<FadeInProps>) => {
    const styles = useSpring({
        from: { opacity: 0 },
        to: { opacity: when ? 1 : 0 },
        delay: 150,
        ...springConfig,
    });

    return when ? (
        <animated.div className={className} style={{ ...styles }}>
            {children}
        </animated.div>
    ) : null;
};
