import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { createAsyncTracker, HandleStateChange, ResolveType, AsyncFunction } from './core';

import { APIResponse, APIError } from 'common/api/models';

export * from './core'; // eslint-disable-line no-duplicate-imports

export const createThunk = <F extends AsyncFunction>(
    asyncFn: F,
    handleStateChange: HandleStateChange<F, ResolveType<ReturnType<F>>>
) => {
    return (...args: Parameters<F>) =>
        async (dispatch: Dispatch) => {
            const { start, done, fail, getState } = createAsyncTracker(handleStateChange, args);
            const startAction = start();
            if (startAction) {
                dispatch(startAction);
            }
            try {
                const result = await asyncFn(...args);
                const doneAction = done(result);
                if (doneAction) {
                    dispatch(doneAction);
                }
            } catch (e: Error | any) {
                const failAction = fail(e);
                if (failAction) {
                    dispatch(failAction);
                }
            }
            return getState();
        };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export type APIFn<T = any, E = any> = (...args: any[]) => Promise<APIResponse<T, E>>;
export type APIResponseType<TAPIFn> = TAPIFn extends APIFn<infer T> ? T : never;
export type APIErrorType<TAPIFn> = TAPIFn extends APIFn<any, infer E> ? E : never;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const createApiThunk = <F extends APIFn>(
    asyncFn: F,
    handleStateChange: HandleStateChange<F, APIResponseType<F>, APIError<APIErrorType<F>>>,
    { joinParallelCalls } = { joinParallelCalls: false }
) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let promise: Promise<APIResponse<any, any>> | undefined;

    return (...args: Parameters<F>) =>
        async (dispatch: Dispatch) => {
            const { start, done, fail } = createAsyncTracker(handleStateChange, args);
            /* eslint-disable @typescript-eslint/no-explicit-any */
            let result: APIResponse<any, any>;

            const startAction = start();
            if (startAction) {
                dispatch(startAction);
            }

            try {
                // NOTE: it is recommended to use joinParallelCalls only for requests (GET).
                // NOTE: joinParallelCalls only joins the calls if there are no args
                // (to avoid having to compare the args for equality and other issues)
                if (joinParallelCalls && args.length <= 0) {
                    promise = promise || asyncFn(...args);
                    result = await promise;
                } else {
                    result = await asyncFn(...args);
                }

                if (!result.error) {
                    const doneAction = done(result.data);
                    if (doneAction) {
                        dispatch(doneAction);
                    }
                } else {
                    const failAction = fail(result.error);
                    if (failAction) {
                        dispatch(failAction);
                    }
                }
            } finally {
                promise = undefined;
            }
            return result as ResolveType<ReturnType<F>>;
        };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ThunkActionCreator<R, S, E, A extends Action> = (...args: any[]) => ThunkAction<R, S, E, A>;

export const callIfNeeded = <R, S, E, A extends Action, F extends ThunkActionCreator<R, S, E, A>>(
    thunk: F,
    shouldCall: (...args: Parameters<F>) => boolean
) => {
    return (...args: Parameters<F>): ThunkAction<R | void, S, E, A> =>
        (dispatch) => {
            if (shouldCall(...args)) {
                return dispatch(thunk(...args));
            }
        };
};
