import React from 'react';
import { useConstructorDefaultParameters } from 'common/api/constructor/useConstructorDefaultParameters';
import {
    ConstructorReturnObject,
    constructorToBrand,
    processOnBonusBrands,
} from 'common/api/constructor/util';
import { APIResponse } from 'common/api/models';
import { getFeaturedBrands } from 'common/api/search/getFeaturedBrands';
import { SearchResult } from 'common/api/search/models/SearchResult';

import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import {
    Brand,
    searchResultBrandsToBrands,
} from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { editFilter } from 'common/utils/shipToCoordinatorUtils';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { constructorClient } from 'third-party-integrations/constructor';
import { selectIsConstructorBrowseEnabledWeb } from 'common/features/store/duck/ffs';

const CARDS_TO_SHOW = 12;

export const useFeaturedBrands = () => {
    const [featuredBrands, setFeaturedBrands] = useState<Brand[]>([]);
    const [totalResults, setTotalResults] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const baseFilters = useConstructorDefaultParameters({
        resultsPerPage: CARDS_TO_SHOW,
    });
    const isShipToCoordinatorEnabled = useSelector(selectIsShipToCoordinatorEnabled);
    const globalFilter = useGlobalBrandFilters();
    const filter = editFilter(isShipToCoordinatorEnabled, globalFilter) as string;
    const constructorEnabled = useSelector(selectIsConstructorBrowseEnabledWeb);

    useEffect(() => {
        if (!constructorEnabled) {
            getFeaturedBrands({ filter, top: CARDS_TO_SHOW, count: true })
                .then((response: APIResponse<SearchResult, null>) => {
                    const brands = response.data ?? ({ brands: [] } as SearchResult);
                    setFeaturedBrands(searchResultBrandsToBrands(brands));
                    setTotalResults(response.data?.count || brands.brands.length);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            constructorClient.browse
                .getBrowseResults('group_id', 'Filter-AllFeatured', baseFilters.current)
                .then(async (constructorResponse) => {
                    const constructorBrands = constructorResponse.response?.results || [];
                    const validBrands = processOnBonusBrands(
                        constructorBrands as ConstructorReturnObject[],
                        false
                    );
                    const brands = constructorToBrand(validBrands);
                    setFeaturedBrands(brands);
                    setTotalResults(constructorResponse.response?.total_num_results || 0);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [filter]);

    return {
        featuredBrands,
        totalResults,
        loading,
        error,
    };
};
