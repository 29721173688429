import React, { useRef, useMemo } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { useDispatch } from 'react-redux';

import { StepWrapperProps, StepWrapper } from './StepWrapper';

import { Btn } from 'components/btn/Btn';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { FormikTextField } from 'components/fields/FormikTextField';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import { confirmPhone } from 'common/api/users/twofactor/phone';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { useAlert } from 'modules/alerts';
import {
    verificationCodeError,
    verificationCodeValidator,
} from 'common/modules/form-validation/validators';
import { Routes } from 'routes';
import { LoadingOverlay, closeLoader, openLoader } from 'components/loading-overlay/LoadingOverlay';
import { UnmaskedPhoneNumber } from 'common/api/users/models/UnmaskedPhoneNumber';
import { MASK_PHONE_US_LAST_4, VERIFICATION_CODE_MASK } from 'common/modules/mask-text/masks';
import { link } from 'styles/typography';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { useValidation } from 'modules/form-validation/useValidation';
import { updateAccount } from 'common/features/store/duck/account/duck';

interface TwoFactorVerifyStepProps extends StepWrapperProps {
    phone: UnmaskedPhoneNumber;
}
interface VerifyCodeRequest {
    phoneId: string;
    code: string;
    blackBoxValue: string;
}

const VerificationCodeField = typedFormikField<VerifyCodeRequest>()(FormikTextField);

export const TwoFactorVerifyStep = (props: TwoFactorVerifyStepProps) => {
    const { retrieveBlackBox } = useBlackBoxValues();
    const phoneId = props.phone.id;
    const alert = useAlert();
    const history = useHistory();

    const initialValues: VerifyCodeRequest = {
        phoneId,
        code: '',
        blackBoxValue: '',
    };

    const validateAndSubmit = useValidation<VerifyCodeRequest>();

    const loader = useRef<LoadingOverlay>(null);
    const dispatch = useDispatch<any>();
    const onSubmit = async (values: VerifyCodeRequest) => {
        openLoader(loader.current);
        const blackBoxValue = (await retrieveBlackBox()).blackBoxValue || '';
        const { code } = values;
        const result = await confirmPhone(phoneId, code, blackBoxValue);
        await closeLoader(loader.current);
        if (result.error) {
            alert({ message: verificationCodeError });
            return;
        }
        dispatch(updateAccount({ hasSetupTwoFactor: true }));
        withRetries(updateEnrollmentProgress)({ state: ProgressState.TwoFactorSetup });
        history.replace(Routes.StartProgramOrganization);
    };

    const subheadingData = useMemo(
        () => ({
            phoneNumber: conformToMask(props.phone.phoneNumber.slice(-4), MASK_PHONE_US_LAST_4, {})
                .conformedValue,
        }),
        [props.phone]
    );

    return (
        <StepWrapper
            heading={props.heading}
            subheading={props.subheading}
            subheadingData={subheadingData}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-sm mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <FieldSpacer />
                        <MaskedInput
                            mask={VERIFICATION_CODE_MASK}
                            guide={false}
                            render={(ref, _props) => (
                                <VerificationCodeField
                                    {..._props}
                                    inputRef={ref}
                                    type="text"
                                    name="code"
                                    label="Verification Code"
                                    validate={verificationCodeValidator}
                                    autoComplete="off"
                                    inputMode="numeric"
                                />
                            )}
                        />
                        <FieldSpacer />

                        <div className="text-grey-2 text-xs">
                            Code not working or didn't receive one?{' '}
                            <button
                                type="button"
                                className={link}
                                onClick={() => history.replace(Routes.StartProgramTwoFactor)}
                            >
                                Try requesting a new one.
                            </button>
                        </div>
                        <FieldSpacer />
                        <FieldSpacer />

                        <div className="flex justify-center">
                            <Btn type="submit">Next: Your Organization</Btn>
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
