import React, { useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactComponent as AvatarSvg } from '../../../media/images/avatar.svg';
import { faShoppingBag, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Routes } from 'routes';
import { getLogoutUrl } from 'features/auth/components/withAuth';
import { Notifications } from './Notifications';
import styles from './StickyHeaderMenuStyles';
import { useRootSelector } from 'common/features/featuresReducer';

export const StickyHeader = () => {
    const divHeaderRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const LEFT_NAV_WIDTH = 260;
        const HEADER_MIN_WIDTH = 450;
        const updateHeaderWidth = () => {
            if (divHeaderRef.current) {
                const headerWidth = document.body.getBoundingClientRect().width - LEFT_NAV_WIDTH;
                divHeaderRef.current.style['width'] = `${
                    headerWidth <= HEADER_MIN_WIDTH ? HEADER_MIN_WIDTH : headerWidth
                }px`;
            }
        };

        updateHeaderWidth();
        window.addEventListener('resize', updateHeaderWidth);

        return () => {
            window.removeEventListener('resize', updateHeaderWidth);
        };
    }, []);

    return (
        <>
            <header ref={divHeaderRef} className={styles.container}>
                <div className={styles.logo}>
                    <Link
                        to={Routes.Brands}
                        className="flex items-center focus:outline-none no-underline text-brand-dark py-2.5 px-5 rounded-full focus:bg-pale-blue hover:bg-pale-blue hover:bg-opacity-40 focus:bg-opacity-40"
                    >
                        <FontAwesomeIcon
                            icon={faShoppingBag}
                            style={{ width: '20px', height: '20px' }}
                        />
                        <span className="text-sm ml-2 py-0.5">Personal Shopping</span>
                    </Link>
                </div>

                <div>
                    <div className={styles.menuIcon} style={{ width: 1, height: 44 }}></div>
                </div>
                <Notifications />
                <Menu />
            </header>
        </>
    );
};

const Menu = () => {
    const [logoutUrl, setLogoutUrl] = useState('');
    const storageId = useRootSelector((s) => s.store.account.userId?.substr(0, 8));

    useEffect(() => {
        (async () => {
            const _logoutUrl = await getLogoutUrl();
            setLogoutUrl(_logoutUrl || '');
        })();
    }, []);

    // NOTE: The welcome message rendering is controlled via local storage
    // we want to mark it as _seen_ so it doesn't render next time the user logs in.
    const disableWelcomeMessage = () => {
        window.localStorage.setItem(`${storageId}_has_seen_welcome_message`, 'true');
        if (window.localStorage.getItem(`${storageId}_tempidx`) === '0') {
            window.localStorage.setItem(`${storageId}_tempidx`, '1');
        }
        window.location.href = logoutUrl;
    };

    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger className={styles.avatarContainer}>
                <div style={{ width: '24px', height: '24px' }} className={styles.avatar}>
                    <AvatarSvg />
                </div>
                <div className={styles.menuBtn}>Account</div>
                <FontAwesomeIcon icon={faChevronDown} />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal className={styles.menuDropdown}>
                <DropdownMenu.Content className={styles.menuDropdown}>
                    <DropdownMenu.Item className="focus:outline-none focus-visible:outline-none">
                        <Link to={Routes.CoordinatorDashboardHome} className={styles.menuItemsBold}>
                            Coordinator Dashboard
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item>
                        <Link
                            to={Routes.ShopAccountSettingsDashboardTab}
                            className={styles.menuItemsBold}
                        >
                            Personal Dashboard
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item>
                        <Link to={Routes.Brands} className={styles.menuItemsBold}>
                            Personal Shopping
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator className={styles.menuSeparator} />

                    <DropdownMenu.Item>
                        <Link
                            to={Routes.SupportOrganization}
                            target="_blank"
                            className={styles.menuItems}
                        >
                            Coordinator Resources
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item>
                        <Link
                            to={Routes.SupportParticipant}
                            target="_blank"
                            className={styles.menuItems}
                        >
                            Participant Resources
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item>
                        <Link to={Routes.SupportFaq} target="_blank" className={styles.menuItems}>
                            FAQs
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Item>
                        <Link to={Routes.Contact} target="_blank" className={styles.menuItems}>
                            Contact Us
                        </Link>
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator className={styles.signOutSeparator} />

                    <span onClick={disableWelcomeMessage} className={styles.menuLogout}>
                        <FontAwesomeIcon icon={faSignOut} /> Sign Out
                    </span>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
