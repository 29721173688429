import { useEffect } from 'react';
import { selectSearchAppliedTerm } from 'common/features/store/duck/search/duck';
import { useRootSelector } from 'rootStore';
import { tempStorage } from 'common/api/constructor';
import { constructorInstance } from 'common/api/constructor/constructor-instance';

if (!process.env.REACT_APP_CONSTRUCTOR_KEY) {
    console.error('CONSTRUCTOR_KEY is missing', process.env.REACT_APP_CONSTRUCTOR_KEY);
}

export const constructorClient = constructorInstance;

// FEC-1622:
export const removeConstructorRefinedContent = () => {
    tempStorage.set(undefined);
};

export const useManageConstructorRefinedContent = () => {
    const searchTerm = useRootSelector(selectSearchAppliedTerm);

    useEffect(() => {
        if (searchTerm === '') {
            removeConstructorRefinedContent();
        }
    }, [searchTerm]);
};
// FEC-1622
