import React from 'react';
import * as RuiSwitch from '@radix-ui/react-switch';

import { createStyle } from 'utils/createStyle';

export interface SwitchProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const StyledSwitch = createStyle('rounded-full', {
    appearance: 'none',
    border: 'none',
    padding: 0,
    width: '2rem',
    height: '1rem',
    marginLeft: '2px',
    marginRight: '5px',
    backgroundColor: '#C4C4C4',
    verticalAlign: 'middle',
    // borderRadius: 25,
    position: 'relative',
    transitionDuration: '.4s',
    '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 2px royalblue',
    },
    '&[data-state="checked"]': {
        backgroundColor: 'dodgerblue',
    },
});

const StyledThumb = createStyle('rounded-full', {
    top: 0,
    left: 0,
    transitionDuration: '.3s',
    position: 'absolute',
    transform: 'translate(-2px, -0.1rem)',
    margin: 0,
    padding: 0,
    height: '1.2rem',
    width: '1.2rem',
    background: 'white',
    boxShadow: '-1px 1px 1px rgba(147, 149, 152, 0.5)',
    border: '1px solid #ccddee',
    '&[data-state="checked"]': {
        transform: 'translate(1rem, -0.1rem)',
    },
});

export const Switch = ({ checked, onChange }: SwitchProps) => {
    return (
        <div style={{ display: 'flex' }}>
            <RuiSwitch.Root
                className={StyledSwitch}
                checked={checked}
                onCheckedChange={(e) => onChange(e.target.checked)}
            >
                <RuiSwitch.Thumb className={StyledThumb} />
            </RuiSwitch.Root>
        </div>
    );
};
