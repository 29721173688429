import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { hexToRGB } from 'common/utils/colors';

const menuItemsCommon = `block hover:bg-pale-blue hover:bg-opacity-40 no-underline outline-none hover:outline-none focus:outline-none focus:bg-pale-blue focus:bg-opacity-40`;
const paleBlueRgb = hexToRGB(colors.paleBlue);

const styles = createStyles({
    container: [
        'w-full z-50 bg-white text-brand px-8 py-2 flex border-b border-grey-line',
        {
            gridArea: 'stickyHeader', // Added gridArea property
            justifyContent: 'flex-end',
        },
    ],
    logo: ['flex-shrink-0 font-semibold px-2'],
    menu: ['mx-2 flex-shrink-0 text-brand-dark text-2xl'],
    menuIcon: ['bg-grey-line'],
    menuTrigger: [
        'focus:outline-none',
        {
            borderColor: '#EAECF0',
            '&:hover': {
                borderColor: '#D0D5DD',
            },
            '&:focus': {
                borderColor: '#D0D5DD',
            },
            '&[data-state="open"]': {
                borderColor: '#D0D5DD',
            },
        },
    ],
    avatarContainer: [
        'text-brand-dark focus:outline-none flex items-center border rounded-full pl-3 h-11 pr-4',
        {
            borderColor: colors.lineGrey,
            '&:hover': {
                borderColor: colors.hoverGrey,
            },
            '&[data-state="open"]': {
                borderColor: colors.darkBlue,
            },
        },
    ],
    avatar: ['rounded-full'],
    menuDropdown: [
        'pt-2 mt-1 cursor-pointer text-brand-dark bg-white rounded-md shadow',
        {
            minWidth: '260px',
            borderColor: '#D0D5DD',

            '>div': {
                '&:focus-visible': {
                    outline: 'none',
                    backgroundColor: `rgba(${[...paleBlueRgb, 0.4].join(', ')})`,
                },
            },
        },
    ],
    menuSeparator: ['w-full bg-grey-line border-none h-px my-2'],
    signOutSeparator: ['w-full bg-grey-line border-none h-px mt-2'],
    menuItemsBold: [
        `text-brand-dark text-base font-semibold pl-9 py-3 ${menuItemsCommon}`,
        {
            '>a': {
                textDecoration: 'none',
            },
        },
    ],
    menuItems: [
        `text-base pl-9 py-3 ${menuItemsCommon}`,
        {
            color: colors.dashboardText,
            '>a': {
                textDecoration: 'none',
            },
        },
    ],
    menuLogout: [
        `text-brand font-semibold pl-9 py-4 ${menuItemsCommon}`,
        {
            '>a': {
                textDecoration: 'none',
            },
        },
    ],
    menuBtn: ['text-sm ml-2 mr-4 font-semibold'],
    notificationMenuContainer: ['px-5', { paddingTop: 24, paddingBottom: 18, minWidth: 380 }],
    notificationEmptyMenuContainer: [{ padding: '46px 70px' }],
    notificationTrigger: [
        'h-11 w-11 focus:outline-none focus:bg-pale-blue hover:bg-pale-blue hover:bg-opacity-40 focus:bg-opacity-40 rounded-full',
    ],
    notificationTriggerDot: [
        'relative inline-block',
        {
            '&::after': {
                content: ' ',
                position: 'absolute',
                top: '23%',
                left: '56%',
                width: 10,
                height: 10,
                backgroundColor: colors.bonusPink,
                borderRadius: '50%',
            },
        },
    ],
});

export default styles;
