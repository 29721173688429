import React, { useRef, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';

import { EnrollmentContext } from '../../context';

import { LinkABankSection } from './LinkABankSection';

import { Routes, getPath } from 'routes';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { StepWrapper, StepWrapperProps } from 'features/coordinator/enrollment/steps/StepWrapper';
import { withRetries } from 'common/utils/withRetries';
import { Btn } from 'components/btn/Btn';
import { PaperOnlyModal } from 'features/coordinator/components/PaperOnlyModal';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';

const Heading = ({ children }: { children: React.ReactNode }) => {
    return <p className="text-2xl text-brand-dark font-semibold my-5">{children}</p>;
};

const ECheckSection = ({ loader }: { loader: React.RefObject<LoadingOverlay> }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { organization } = useContext(EnrollmentContext);
    const history = useHistory();

    // paper-only modal controls
    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);
    const chooseLiteOption = async () => {
        openLoader(loader.current);
        await withRetries(updateEnrollmentProgress)({
            state: ProgressState.SwitchToLiteProgram,
            version: 2,
        });
        await closeLoader(loader.current);

        onClose();
        history.replace(Routes.EnrollLiteProgramInfo);
    };

    const onPrimaryBtnText = () => {
        history.replace(getPath(Routes.EnrollCompleted, { status: 'paper-only' }));
    };

    return (
        <div className="absolute left-0 w-full mt-28 sm:mt-24">
            <hr className="border-grey-6" />
            <p className="px-5 sm:px-0 mt-10 text-center text-xl block text-grey-2">
                <FontAwesomeIcon className="mr-3" icon={faQuestionCircle} />
                Is your organization unable to receive earnings through direct deposit?
            </p>
            <div className="mx-7 sm:mx-0">
                <Btn isSecondary className="block mx-auto my-7 leading-5" onClick={onOpen}>
                    Get Information About Another Option
                </Btn>
            </div>
            <PaperOnlyModal
                orgId={organization.id as string}
                isOpen={isOpen}
                onClose={onClose}
                primaryBtnText="I want a Paper Only Program"
                onBtnPrimaryClick={onPrimaryBtnText}
                secondaryBtnText="No, I'll Try Lite Program with eCheck"
                onBtnSecondaryClick={chooseLiteOption}
            />
        </div>
    );
};

interface LiteEarningsStep1Props extends StepWrapperProps {}

export const PlusEarningsStep1 = (props: LiteEarningsStep1Props) => {
    const loader = useRef<LoadingOverlay>(null);
    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <Heading>How will your organization receive funds?</Heading>
            <LinkABankSection loader={loader} />
            <ECheckSection loader={loader} />
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
