import React from 'react';

import { Pills, PillsProps } from '../../../components/pills/Pills';

import { createStyle } from 'utils/createStyle';

interface PillSectionProps extends PillsProps {
    clearFilters: React.MouseEventHandler<HTMLButtonElement>;
}

const clearAllStyle = createStyle('flex-none underline text-brand font-semibold mr-4', {
    fontSize: 12,
});

const PillSection = ({ options, onRemove, clearFilters }: PillSectionProps) => {
    return (
        <div className="mx-8 lg:mx-16 2xl:mx-28 hidden sm:flex items-center w-full">
            {options.length > 0 && (
                <button className={clearAllStyle} onClick={clearFilters}>
                    Clear All
                </button>
            )}
            <Pills options={options} onRemove={onRemove} />
        </div>
    );
};

export default PillSection;
