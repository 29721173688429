import React from 'react';
import { Document } from '@contentful/rich-text-types';

import { UserContent } from 'components/user-content/UserContent';
import { h2 } from 'styles/typography';
import { PartialRecord } from 'common/definitions/PartialRecord';
import { formatString } from 'common/utils/formatString';

export interface StepWrapperProps {
    heading: string;
    headingData?: PartialRecord<string, string>;
    subheading: Document | null;
    subheadingData?: PartialRecord<string, string>;
    contentClassName?: string;
}

export const StepWrapper = (props: React.PropsWithChildren<StepWrapperProps>) => {
    return (
        <div>
            <div className="max-w-2xl mx-auto px-4 sm:px-10">
                <div className="mb-4 sm:mb-8" />

                <h1 tabIndex={0} className={h2}>
                    {props.headingData
                        ? formatString(props.heading, props.headingData)
                        : props.heading}
                </h1>

                <div className="mb-5" />

                {props.subheading ? (
                    <div className="text-brand-dark text-lg">
                        <UserContent
                            className="space-y-3"
                            content={props.subheading}
                            data={props.subheadingData}
                        />
                    </div>
                ) : null}

                {props.children}
            </div>

            {!props.contentClassName ? <div className="mb-10" /> : null}
        </div>
    );
};
