import React, { Fragment, useContext } from 'react';

import { Earnings } from './Earnings';
import { BonusInfo } from './BonusInfo';
import { StylesContext } from '../../features/search/components/contexts';
import { CardPropsEarns } from '../bonus/CardProps';
import BaseCard from '../bonus/BaseCard';
import { createStyles } from '../../utils/createStyle';
import './BrandCardSlim.scss';
import { BarCode, PhysicalCard, Reload } from 'components/custom-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/pro-light-svg-icons';
import { Favorite } from './Favorite';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';

export const innerStyles = createStyles({
    bonusPadding: [{ marginLeft: '22px' }],
    bold: [{ fontWeight: 600 }],
    brandName: [
        'text-brand-dark font-semibold',
        {
            fontSize: 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            // wordWrap: 'break-word',
            // wordBreak: 'break-all',
            paddingTop: 5,
            textAlign: 'center',
            width: 150,
            '@media (min-width: 768px)': {
                width: 180,
                height: 'auto',
                maxHeight: 28,
            },
        },
    ],
    brandText: [{ fontSize: '12px', lineHeight: '16px' }],
    brandHeight: [{ height: '35%' }],
    cardContainer: [
        'relative cursor-pointer flex flex-col justify-between items-center',

        {
            width: 150,
            height: 210,
            '@media (min-width: 768px)': {
                width: 180,
            },
        },
    ],
    imgContainer: ['w-full flex justify-center items-center', { height: '55%' }],
    verticalHeaderContainer: [
        'px-1 sm:px-3 flex justify-center items-center w-full ',
        { height: 30 },
    ],
    isOnBonus: 'bg-bonus-pink',
    isNew: 'bg-brand-accent-3',
    verticalHeaderText: ['h-4 bonus-header', { left: '2px' }],
    rightBarText: ['font-semibold', { fontSize: 10 }],
    favoriteContainer: [
        'w-5 h-5 flex justify-between cursor-pointer mt-1.5',
        {
            position: 'absolute',
            zIndex: 1,
            marginLeft: 6,
            '@media (min-width: 768px)': {
                marginLeft: 8,
            },
        },
    ],
    isSponsored: ['text-grey-3 h-5 pt-0', { fontSize: 10, lineHeight: 1.5 }],
});

export interface CardMainContentProps {
    logoImage: string;
    brandName: string;
    earn: {
        type: EarnTypeConstructorFilter;
        isOnBonus: boolean;
        previous: number | undefined;
        actual: number | undefined;
    }[];
    types?: string[];
    hasMultipleRebates?: boolean;
    containsVariableRebate?: boolean;
    isLocalProduct: boolean;
    singleTypeView: boolean;
}

const CardMainContent = ({
    logoImage,
    earn,
    types,
    hasMultipleRebates,
    containsVariableRebate,
    isLocalProduct,
    singleTypeView,
}: CardMainContentProps) => {
    const { styles } = useContext(StylesContext);
    return (
        <div
            className={`h-5/6 w-full flex flex-col p-2 ${innerStyles.bold} ${innerStyles.brandText}`}
        >
            <div className="flex h-full">
                <div className={`w-full flex flex-col items-center mb-0`}>
                    <div className={innerStyles.imgContainer}>
                        {' '}
                        {!isLocalProduct && logoImage && (
                            <img
                                src={logoImage}
                                className={`h-14 object-contain ${styles.logoImage}`}
                            />
                        )}
                        {isLocalProduct && (
                            <FontAwesomeIcon className="text-brand -mr-2" icon={faTag} size="4x" />
                        )}
                    </div>
                    <div className={`${innerStyles.brandText} ${innerStyles.brandHeight}`}>
                        {earn.map((earnType) => {
                            return (
                                <Earnings
                                    key={earnType.type}
                                    hasMultipleRebates={
                                        earnType.type === EarnTypeConstructorFilter.ShopOnline
                                            ? containsVariableRebate
                                            : hasMultipleRebates
                                    }
                                    earn={{ previous: earnType.previous, actual: earnType.actual }}
                                    isBonus={earnType.isOnBonus}
                                    rebateMessage={earnType.type}
                                    singleTypeView={singleTypeView}
                                />
                            );
                        })}
                    </div>
                    {singleTypeView && <ProductType types={types} />}
                </div>
            </div>
        </div>
    );
};

interface CardFooterProps {
    isOnBonus: boolean;
    bonusExpiration?: string;
    isNew?: boolean;
}

const CardFooter = ({ isNew, isOnBonus, bonusExpiration }: CardFooterProps) => {
    if (!isOnBonus && !isNew) {
        return null;
    }

    return (
        <div
            className={`${innerStyles.verticalHeaderContainer} ${
                isOnBonus ? innerStyles.isOnBonus : innerStyles.isNew
            } `}
        >
            <div
                className={`${innerStyles.verticalHeaderText} ${innerStyles.brandText} ${innerStyles.bold} text-white`}
            >
                {isOnBonus ? 'BONUS' : 'NEW'}
            </div>
            {isOnBonus ? (
                <BonusInfo isOnBonus={isOnBonus} bonusExpiration={bonusExpiration} />
            ) : null}
        </div>
    );
};

interface ProductTypeProps {
    types?: string[];
}

const constants = {
    eGiftCard: 'eGift Card',
    GiftCard: 'Gift Card',
    Reloadable: 'Reloadable',
};

export const ProductType = ({ types }: ProductTypeProps) => {
    const sortedTypes: string[] | undefined =
        types?.length === 3
            ? [constants.GiftCard, constants.eGiftCard, constants.Reloadable]
            : types?.sort();

    const renderType = (type: string) => {
        switch (type) {
            case constants.eGiftCard:
                return (
                    <>
                        <BarCode className="h-4 my-0.5" />
                    </>
                );
            case constants.GiftCard:
                return (
                    <>
                        <PhysicalCard className="h-4 my-0.5" />
                    </>
                );
            case constants.Reloadable:
                return (
                    <>
                        <Reload className="h-4  my-0.5" />
                    </>
                );
        }
    };

    return (
        <div className="w-full flex -mt-1 text-grey-2 justify-center ">
            {sortedTypes ? (
                sortedTypes.map((type, idx) => (
                    <Fragment key={idx}>
                        <div
                            className={
                                idx === sortedTypes.length - 1
                                    ? 'px-2.5'
                                    : 'border-r border-r-1 border-grey-5 px-2.5'
                            }
                        >
                            <div className="flex flex-col items-center">{renderType(type)}</div>
                        </div>
                    </Fragment>
                ))
            ) : (
                <div />
            )}
        </div>
    );
};

interface BrandCardProps {
    data: CardPropsEarns;
    styles: ReturnType<typeof createStyles>;
    renderers?: { [key: string]: React.ElementType };
}

const defaultRenderers = {
    CardMainContent,
    CardFooter,
};

export const BrandCardSlim = ({ data, styles, renderers = {} }: BrandCardProps) => {
    const {
        logoImage,
        brandName,
        earn,
        link,
        isLoading,
        bonusExpiration,
        supportedTypes,
        hasMultipleRebates,
        containsVariableRebate,
        isNew = false,
        isSponsored = false,
        isFavoritable = true,
        singleTypeView = false,
    } = data;
    const components = { ...defaultRenderers, ...renderers };
    const showMarker = earn.some((item) => item.isOnBonus);
    const getBorderClass = () => {
        if (showMarker) {
            return 'border-bonus-pink';
        }

        return isNew ? 'border-brand-accent-3' : 'border-grey-6';
    };

    return (
        <div>
            <StylesContext.Provider value={{ styles, data }}>
                <div className={innerStyles.favoriteContainer}>
                    {data.brandName && isFavoritable ? <Favorite /> : null}
                </div>
                <BaseCard
                    containerClass={`${styles.cardContainer} ${
                        innerStyles.cardContainer
                    } ${getBorderClass()}`}
                    link={link}
                    isLoading={isLoading}
                >
                    <components.CardMainContent
                        logoImage={logoImage}
                        brandName={brandName}
                        earn={earn}
                        types={supportedTypes}
                        hasMultipleRebates={hasMultipleRebates}
                        containsVariableRebate={containsVariableRebate}
                        isLocalProduct={data.islocalProduct}
                        singleTypeView={singleTypeView}
                    />
                    <components.CardFooter
                        isNew={isNew}
                        isOnBonus={showMarker}
                        bonusExpiration={bonusExpiration}
                    />
                </BaseCard>
            </StylesContext.Provider>

            <div className="flex p-0 m-0 flex-col items-center justify-center">
                <p className={`${innerStyles.brandName} ${styles.brandName}`}>{brandName}</p>
                {isSponsored ? <i className={`${innerStyles.isSponsored}`}>Sponsored</i> : null}
            </div>
        </div>
    );
};
