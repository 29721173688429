import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    refreshEnrollmentLog,
    selectOrganizationEnrollmentLogEntries,
    selectShowAccountLoader,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationEnrollmentLogEntry } from 'common/api/crm/models/OrganizationEnrollmentLogEntry';
import {
    getOrgAchAccountThunk,
    selectOrgAchAccount,
} from 'common/features/store/duck/organization/duck';
import {
    selectDefaultOrganization,
    selectHomeDetailsAsyncState,
    selectSelectedOrgId,
} from 'common/features/store/duck/home/duck';
import { getV2ContinueRoute } from './GetV2ContinueRoute';
import { useHistory, useLocation } from 'react-router';
import { selectUserAuthState, UserAuthState } from 'common/features/store/duck/account/duck';
import { Routes } from 'routes';

export const useV2ContinueRoutes = () => {
    const orgId = useSelector(selectSelectedOrgId);
    const dispatch = useDispatch<any>();

    const entries = useSelector(selectOrganizationEnrollmentLogEntries);
    const orgAchAccount = useSelector(selectOrgAchAccount);
    const [ready, setReady] = useState(false);

    const lastEntry = useMemo(() => {
        if (!entries) {
            return;
        }

        const sortedLogs = Object.values(entries).sort(
            (a: OrganizationEnrollmentLogEntry, b: OrganizationEnrollmentLogEntry) => {
                const aDate = Date.parse(a.updateDate);
                const bDate = Date.parse(b.updateDate);
                return bDate - aDate;
            }
        );

        if (sortedLogs[0]?.version === 2) {
            return sortedLogs[0];
        }
    }, [entries]);

    useEffect(() => {
        if (!orgId) {
            return;
        }
    }, [dispatch, orgId]);

    const continueRoute = useMemo(() => {
        if (!ready) {
            return;
        }

        return getV2ContinueRoute(lastEntry!, orgAchAccount || null);
    }, [ready, lastEntry, orgAchAccount]);

    useEffect(() => {
        if (!orgId) {
            return;
        }

        (async () => {
            try {
                const refreshEnrollmentLogPromise = dispatch(refreshEnrollmentLog());
                const getOrgAchAccountThunkPromise = getOrgAchAccountThunk(orgId)(dispatch);

                await Promise.all([refreshEnrollmentLogPromise, getOrgAchAccountThunkPromise]);
            } finally {
                setReady(true);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, orgId, setReady]);

    return continueRoute;
};

export const useV2RouteChecking = () => {
    const continueRoute = useV2ContinueRoutes();

    const location = useLocation();
    const history = useHistory();
    const showLoaderScreen = useSelector(selectShowAccountLoader);
    const userAuthState = useSelector(selectUserAuthState);
    const defaultOrg = useSelector(selectDefaultOrganization);
    const homeDetailsAsyncState = useSelector(selectHomeDetailsAsyncState);

    const readyToCheckRoute =
        (homeDetailsAsyncState.error || homeDetailsAsyncState.lastUpdate > 0) &&
        Boolean(continueRoute);

    const checkIfRouteIsCorrect = useCallback(async () => {
        if (defaultOrg?.isApproved) {
            history.replace(`${Routes.ShopAccountSettingsPersonalInformationTab}#organizations`);
            return;
        }

        // user shouldn't be here, send to /continue
        if (continueRoute && continueRoute !== location.pathname) {
            history.replace(continueRoute);
        }
    }, [history, location, continueRoute, defaultOrg?.isApproved]);

    const checkRoute = useCallback(() => {
        // finished loading and there is a user logged in.
        if (!showLoaderScreen && userAuthState === UserAuthState.AUTHENTICATED) {
            checkIfRouteIsCorrect();
            return true;
        }
        return false;
    }, [showLoaderScreen, userAuthState, checkIfRouteIsCorrect]);

    return { checkRoute, readyToCheckRoute };
};
