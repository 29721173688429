import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChooseProgramStep } from '../steps/ChooseProgramStep';
import { VoidedCheck } from '../steps/voided-check/VoidedCheck';

import { chooseProgramStyles as styles } from '../styles/ChooseProgramStyles';

import {
    setCurrentStep,
    setMaxStep,
    selectShowVoidedCheckScreen,
    selectShowChooseProgramScreen,
    saveForLater,
    selectProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { scrollTo } from '../utils/ScrollTo';

// We may not end up needing this top level component in the future, but I'm
// leaving it here for now in case we need any high level continue or routing logic here.
export const ChooseProgram = () => {
    const dispatch = useDispatch<any>();
    const showChooseProgramScreen = useSelector(selectShowChooseProgramScreen);
    const showVoidedCheckScreen = useSelector(selectShowVoidedCheckScreen);
    const orgId = useSelector(selectSelectedOrgId);
    const progressBarRef = useSelector(selectProgressBarRef);

    useEffect(() => {
        scrollTo(progressBarRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setMaxStep(4));
        dispatch(setCurrentStep(4));
    }, [dispatch]);

    const saveForLaterClicked = () => {
        if (!orgId) {
            throw new Error('invalid organizationId');
        }
        saveForLater(orgId)(dispatch);
        window.open(
            'https://www.raiseright.com/resources/organization/enroll-save-for-later/',
            '_blank',
            'noopener, noreferrer'
        );
    };

    return (
        <div className={styles.container}>
            <div
                onClick={() => saveForLaterClicked()}
                className={`choose_program_save_for_later ${styles.saveForLaterHelper}`}
            >
                <div className="flex items-center justify-center">
                    <span>Save For Later</span>
                </div>
            </div>
            {showChooseProgramScreen && <ChooseProgramStep />}
            {showVoidedCheckScreen && <VoidedCheck />}
            <div className={styles.helperText}>
                Need more time? No problem.{' '}
                <span
                    onClick={() => saveForLaterClicked()}
                    className={`choose_program_save_for_later ${styles.helperLink}`}
                >
                    Save for later
                </span>
            </div>
        </div>
    );
};
