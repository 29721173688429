import React, { useContext, useRef, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormikTextField } from 'components/fields/FormikTextField';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { FormBtn } from 'components/form-btn';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectUserInformation,
    setUserData,
    updateCurrentStepBy,
    showAccountCreationLoader,
    selectProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { Btn } from 'components/btn/Btn';
import { useAppConfig } from 'common/features/app-config/utils/useAppConfig';
import { useContentfulGlobalContent } from 'common/api/contentful/global';
import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { DISABLE_CAPTCHA_VALIDATION } from 'config';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import { RegistrationRequestWithoutOrg } from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { FormikSetPasswordField } from 'components/fields/FormikSetPasswordField';
import { UserContent } from 'components/user-content/UserContent';
import {
    firstNameValidator,
    lastNameValidator,
    orgEnrollPasswordValidator,
} from 'common/modules/form-validation/validators';
import { ContentfulSubheading } from '../components/ContentfulSubheading';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { enrollmentV2RegisterThunk } from 'common/features/store/duck/local/duck';
import { scrollTo } from '../utils/ScrollTo';

const AccountCreationPasswordField =
    typedFormikField<RegistrationRequestWithoutOrg>()(FormikSetPasswordField);

const styles = createStyles({
    nameContainer: ['flex flex-col sm:flex-row justify-between'],
    backButton: ['sm:w-1/3 w-5/12 mr-3'],
    firstName: ['w-full mb-5 sm:w-60 sm:mb-0'],
    lastName: ['w-full sm:w-60'],
    emailHelperText: [
        '',
        {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: colors.grey1,
            marginTop: '5px',
        },
    ],
});

export const AccountInfo = () => {
    const dispatch = useDispatch<any>();
    const { appConfig } = useAppConfig();
    const globalContent = useContentfulGlobalContent();
    const userInformation = useRootSelector(selectUserInformation);
    const { retrieveBlackBox } = useBlackBoxValues();
    const refCaptcha = useRef<ReCAPTCHA>(null);
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const createAccountCtaText = (contentfulEntry as OrganizationEnrollmentV2).createAccountCtaText;
    const progressBarRef = useSelector(selectProgressBarRef);

    const goBack = useCallback(() => {
        scrollTo(progressBarRef);
        dispatch(updateCurrentStepBy(-1));
    }, [dispatch, progressBarRef]);

    const saveFirstName = useCallback(
        (values: Partial<RegistrationRequestWithoutOrg>) => {
            dispatch(
                setUserData({
                    firstName: values.firstName,
                })
            );
        },
        [dispatch]
    );

    const saveLastName = useCallback(
        (values: Partial<RegistrationRequestWithoutOrg>) => {
            dispatch(
                setUserData({
                    lastName: values.lastName,
                })
            );
        },
        [dispatch]
    );

    const onSubmit = useCallback(
        async (formData: Partial<RegistrationRequestWithoutOrg>) => {
            dispatch(
                setUserData({
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                })
            );
            const blackboxValues = await retrieveBlackBox();
            const captchaResponse = DISABLE_CAPTCHA_VALIDATION
                ? 'no_captcha_validation'
                : await refCaptcha.current?.executeAsync();

            const password = formData.password || '';
            dispatch(
                enrollmentV2RegisterThunk(password, blackboxValues, captchaResponse as string)
            );
            dispatch(showAccountCreationLoader(true));
        },
        [dispatch, retrieveBlackBox]
    );

    const initialValues = {
        emailAddress: userInformation.email,
        firstName: userInformation.firstName,
        lastName: userInformation.lastName,
        password: '',
    };

    return (
        <div>
            <ContentfulSubheading attribute="step3Subtitle" />
            <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={false}>
                {(formikProps) => (
                    <Form>
                        <FieldSpacer className="pb-5" />
                        <div className={styles.nameContainer}>
                            <div className={styles.firstName}>
                                <FormikTextField
                                    label="First Name"
                                    name="firstName"
                                    validate={firstNameValidator}
                                    onBlur={() => {
                                        saveFirstName(formikProps.values);
                                    }}
                                />
                            </div>
                            <div className={styles.lastName}>
                                <FormikTextField
                                    label="Last Name"
                                    name="lastName"
                                    validate={lastNameValidator}
                                    onBlur={() => {
                                        saveLastName(formikProps.values);
                                    }}
                                />
                            </div>
                        </div>
                        <FieldSpacer className="pb-5" />
                        <FormikTextField disabled name="emailAddress" type="email" label="Email" />
                        <div className={styles.emailHelperText}>
                            This is the email address that you will use to log in. To change or
                            edit, please return to the Email Address field at the beginning of the
                            form.
                        </div>
                        <FieldSpacer className="pb-5" />
                        <div>
                            <AccountCreationPasswordField
                                name="password"
                                label="Password (8+ characters)"
                                maxLength={200}
                                validate={orgEnrollPasswordValidator}
                                minAcceptableScore={appConfig.passwordPolicy.minimumZxcvbnScore}
                            />
                            {globalContent && (
                                <div className="text-xs text-grey-1 mt-3">
                                    <UserContent content={globalContent.setPasswordTooltip} />
                                </div>
                            )}
                        </div>
                        <FieldSpacer className="pb-5" />
                        <div className="flex sm:place-content-start place-content-center">
                            <Btn
                                type="button"
                                className={styles.backButton}
                                onClick={() => {
                                    goBack();
                                }}
                                isSecondary
                            >
                                Back
                            </Btn>
                            <FormBtn
                                disabled={!(formikProps.isValid && formikProps.dirty)}
                                type="submit"
                                isSubmitting={formikProps.isSubmitting}
                            >
                                {createAccountCtaText}
                            </FormBtn>
                        </div>
                        <ReCAPTCHA
                            ref={refCaptcha}
                            size="invisible"
                            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};
