import { ECommBrand } from './ECommBrand';
import { Product, emptyProduct } from './Product';

export interface CartLineItemOption {
    optionId: number;
    optionName: string;
    valueId: number;
    value: string;
}

export interface CartLineItemGiftDetails {
    fromName: string;
    fromPhone: string | null;
    toName: string;
    toEmail: string;
    deliveryDate: string;
    message: string;
    productImageName: string;
}

export interface CartLineItem {
    id: string;
    quantity: number;
    price: number;
    subtotal: number;
    earnings: number;
    productId: number;
    product: Product;
    brand: ECommBrand | null;
    variantId: number;
    selectedOptions: CartLineItemOption[];
    rebate: number;
    giftDetail?: CartLineItemGiftDetails;
}

export const emptyLineItem: CartLineItem = {
    id: '',
    quantity: 0,
    price: 0,
    subtotal: 0,
    earnings: 0,
    productId: 0,
    product: emptyProduct,
    brand: null,
    variantId: 0,
    selectedOptions: [],
    rebate: 0,
    giftDetail: undefined,
};

export const getIsAvailable = (lineItem: CartLineItem) => {
    const variant = lineItem.product.variants.find((v) => v.id === lineItem.variantId);

    if (variant) {
        return (
            variant.isAvailable &&
            variant.isVisible &&
            lineItem.product.isAvailable &&
            lineItem.product.isVisible
        );
    }

    return lineItem.product.isAvailable && lineItem.product.isVisible;
};
