import { noop } from 'common/utils';
import { LinkBtn } from 'components/link-btn';
import React from 'react';

import { createStyles } from '../../utils/createStyle';

const style = createStyles({
    btn: 'text-center text-grey-darkest md:mx-3 lg:mx-1',
    btnIcon: ['text-brand inline-block', { minHeight: '35px' }],
    btnLinkContainer: 'block no-underline hover:underline focus:underline text-center',
    btnLabel: ['text-brand text-xs text-center mt-1 font-semibold', { textWrap: 'nowrap' }],
});

export interface LabelledIconButtonProps {
    icon: React.ReactNode;
    label: string;
    onClick?: () => void;
}

export const LabelledIconButton = (props: LabelledIconButtonProps) => {
    const { icon, label, onClick = noop } = props;

    return (
        <div className={style.btn}>
            <LinkBtn className={style.btnLinkContainer} onClick={onClick}>
                <div className={style.btnIcon}>{icon}</div>
                <div className={style.btnLabel}>{label}</div>
            </LinkBtn>
        </div>
    );
};
