import { USERS_URL } from 'common/api/config';
import { SecurityQuestion } from 'common/api/users/models/SecurityQuestion';
import { SecurityQuestionAnswers } from 'common/api/users/models/SecurityQuestionAnswers';
import { apiFetch } from 'common/api/utils';

export const getQuestions = () => apiFetch<SecurityQuestion[]>(`${USERS_URL}/questions`);

export const getUserQuestions = (userId: string) =>
    apiFetch<SecurityQuestion[]>(`${USERS_URL}/questions/${userId}`);

export const validateUserAnswers = (
    userId: string,
    securityQuestionAnswers: SecurityQuestionAnswers
) =>
    apiFetch<SecurityQuestion[]>(`${USERS_URL}/questions/${userId}/validate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ securityQuestionAnswers }),
    });
