import { APIResponse } from '../models';
import { API_KEY } from '../config';

import { parseResponse } from './parseResponse';

import { propertyNormalizer } from 'common/utils/propertyNormalizer';
import { createAPIError } from 'common/api/utils/createAPIError';
import { platformInfo } from 'common/utils/platformInfo';

type PlatformHeaders = {
    'x-raiseright-platform'?: string;
    'x-raiseright-build-number'?: string;
    'x-raiseright-app-version'?: string;
};

const platformHeaders: HeadersInit = {};
if (platformInfo.platform) {
    platformHeaders['x-raiseright-platform'] = platformInfo.platform;
}
if (platformInfo.buildNumber) {
    platformHeaders['x-raiseright-build-number'] = platformInfo.buildNumber!;
}
if (platformInfo.appVersion) {
    platformHeaders['x-raiseright-app-version'] = platformInfo.appVersion!;
}

export const apiFetch = async <T, E = null>(
    url: string,
    config: RequestInit = {}
): Promise<APIResponse<T, E>> => {
    try {
        const response = await fetch(url, {
            mode: 'cors',
            ...config,
            headers: {
                ...(config.headers || {}),
                ...platformHeaders,
                'Ocp-Apim-Subscription-Key': API_KEY,
                'X-CSRF': '1',
            },
        });
        const data = await parseResponse(response);

        if (response.ok) {
            return { data, response };
        }

        // Notify the page that an 401 happened.
        // So the listeners can handle as necessary.
        if (response.status == 401) {
            postMessage('401_signal', location.origin);
        }

        return { error: createAPIError<E>(response, null, data), response };
    } catch (e: Error | any) {
        return { error: createAPIError(null, e) };
    }
};

export const normalizeResponse = <T, E = null>(response: APIResponse<T, E>): APIResponse<T, E> => {
    return response.error ? response : { ...response, data: propertyNormalizer(response.data) };
};
