import { colors } from './settings';

import { createStyle } from 'utils/createStyle';

export const getFocusOutline = ({
    borderRadius,
    offset,
}: {
    borderRadius: number;
    offset: number;
}) => {
    return createStyle('focus:outline-none relative', {
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: -offset,
            left: -offset,
            right: -offset,
            bottom: -offset,
            borderRadius,
            border: `2px solid ${colors.brandHighlight}`,
            transition: 'opacity 70ms ease-out, transform 70ms ease-out',
            opacity: 0,
            transform: 'scale(0.92)',
        },
        '&.focus-visible:after': {
            opacity: 1,
            transform: 'scale(1)',
        },
    });
};
