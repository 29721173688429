import { useState, useEffect } from 'react';

import { contentfulApi } from 'common/api/utils/contentful';
import { AdEntry, Ad } from 'common/api/contentful/models/AdEntry';
import { CategoryEntry, CategoryEntryQuery } from 'common/api/contentful/models/CategoryEntry';
import { getBrandThunk } from 'common/features/store/duck/brands/duck';
import { useDispatch } from 'react-redux';
import { Entry } from 'contentful';
import { AdLinkFields } from 'common/api/contentful/models/AdLinkFields';

interface CategoryAds {
    advertisement?: AdEntry;
    shopOnlineAdvertisement?: AdEntry;
}

export const useCategoryAd = (id: string | number): CategoryAds => {
    const [ad, setAd] = useState<CategoryAds>({});
    const dispatch = useDispatch();

    useEffect(() => {
        const load = async () => {
            const category = await contentfulApi.getEntries<CategoryEntry, CategoryEntryQuery>({
                'fields.category': id,
                // eslint-disable-next-line camelcase
                content_type: 'category',
            });

            if (!category.data || !category.data.items.length) {
                return;
            }

            const ads = {} as CategoryAds;
            if (category.data.items[0].fields?.advertisement?.sys?.id) {
                const { data: giftCardsAd } = await contentfulApi.getEntry<Ad>(
                    category.data.items[0].fields?.advertisement?.sys?.id
                );

                ads.advertisement = giftCardsAd as AdEntry;

                // QUESTION: Why do we fetch the brand??
                const brandId = (giftCardsAd?.fields?.link as Entry<AdLinkFields> | undefined)
                    ?.fields?.brandId;
                if (brandId) {
                    await getBrandThunk(brandId)(dispatch);
                }
            }

            if (category.data.items[0].fields?.shopOnlineAdvertisement?.sys?.id) {
                const { data: shopOnlineAd } = await contentfulApi.getEntry<Ad>(
                    category.data.items[0].fields?.shopOnlineAdvertisement?.sys?.id
                );
                ads.shopOnlineAdvertisement = shopOnlineAd as AdEntry;
            }
            setAd(ads);
        };

        load();
    }, [id, dispatch]);

    return ad;
};
