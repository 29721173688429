export function searchTermIsAOneLetterFilter(searchTerm?: string | null): boolean {
    if (!searchTerm) {
        return false;
    } else if (searchTerm === '/[^a-z]/') {
        return true;
    } else if (/^([a-zA-Z#])\*$/.test(searchTerm)) {
        return true;
    } else {
        return false;
    }
}
