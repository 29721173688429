import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNavMenu } from './context';
import { requestCartItems } from 'common/features/store/duck/cart/duck';
import { createStyles } from 'utils/createStyle';
import { useRootSelector } from 'common/features/featuresReducer';
import { NavMenu } from './NavMenu';
import { NavMenuCartContent } from './NavMenuCartContent';
import { LabelledIconButton } from 'components/labelled-icon-button/LabelledIconButton';
import { determineButtonIcon } from './icons';
import { NavMenuItems } from './NavMenuItems';
import { noop } from 'common/utils';
import { faBars } from '@fortawesome/pro-light-svg-icons';

const styles = createStyles({
    container: [' text-center flex gap-4'],
    cartItemsCounter: [
        `absolute border border-brand rounded-full text-brand-accent
w-4 h-4 bg-white font-bold align-middle text-xs`,
        {
            top: '-0.1rem',
            right: '0.7rem',
        },
    ],
    menuItemContainer: ['block', { '@media (min-width: 1140px)': { display: 'none' } }],
});

export interface NaVButtonsProps {
    setMobileMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavButtons = ({ setMobileMenuOpen }: NaVButtonsProps) => {
    const dispatch = useDispatch();
    const { setOpenMenu, navButtons, currentOpenMenu } = useNavMenu();
    const { userId } = useRootSelector((s) => s.store.account);

    useEffect(() => {
        if (userId) {
            requestCartItems()(dispatch);
        }
    }, [userId, dispatch]);

    return (
        <nav className={styles.container}>
            {navButtons.map((navButton) => {
                const isCartButton = navButton.name === 'cart';
                const isOpen = currentOpenMenu === navButton.name;
                const toggleMenu = (name?: string) =>
                    setOpenMenu(name === '' ? name : navButton.name);
                const buttonIcon = determineButtonIcon(navButton.name, navButton.icon || undefined);

                // Cart is handled specifically, so it should not fall into this block
                // even though it does not have menu options
                if (!navButton.menuOptions.length && navButton.name !== 'cart') {
                    return (
                        <div className={`${navButton.id ?? ''} relative`} key={navButton.name}>
                            <LabelledIconButton
                                label={navButton.iconLabel}
                                icon={buttonIcon}
                                onClick={navButton.onSelect || noop}
                            />
                        </div>
                    );
                }

                return (
                    <NavMenu
                        id={navButton.id}
                        key={navButton.name}
                        toggleMenu={toggleMenu}
                        menuTitle={isCartButton ? 'My Cart' : navButton.iconLabel}
                        footer={navButton.footer}
                        open={isOpen}
                        trigger={
                            <LabelledIconButton
                                label={navButton.iconLabel}
                                icon={buttonIcon}
                                onClick={noop}
                            />
                        }
                    >
                        {isCartButton ? (
                            <NavMenuCartContent isDialogMode={false} />
                        ) : (
                            <NavMenuItems options={navButton.menuOptions} />
                        )}
                    </NavMenu>
                );
            })}
            {!userId && (
                <div className={styles.menuItemContainer}>
                    <LabelledIconButton
                        label={'Menu'}
                        icon={determineButtonIcon('bars', faBars)}
                        onClick={() => setMobileMenuOpen?.(true)}
                    />
                </div>
            )}
        </nav>
    );
};

export default NavButtons;
