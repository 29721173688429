import { combineReducers } from 'redux';
import { batchActions } from 'redux-batched-actions';

import { BrandsState, brandsReducer, resetBrands } from './brands/duck';
import { CatalogState, catalogReducer } from './catalog/duck';
import { CategoriesState, categoriesReducer } from './categories/duck';
import { HomeState, homeReducer, resetHomeDetails } from './home/duck';
import { AccountState, accountReducer, logOut } from './account/duck';
import { SearchState, searchReducer } from './search/duck';
import { CartState, cartReducer, resetCart } from './cart/duck';
import { WalletState, walletReducer, resetWallet } from './wallet/duck';
import { OrganizationState, organizationReducer, resetOrganization } from './organization/duck';
import { UserDataState, userDataReducer } from './user-data/duck';
import { FeatureFlagsState, featureFlagsReducer } from './feature-flags/duck';
import { BrandsSearchResultsState, brandSearch, brandsOnBonus } from './search-generic/duck';
import { CoordinatorState, coordinatorReducer } from './coordinator/duck';
import { UIState, uiStateReducer } from './ui';
import { LocalEndpointState, localReducer } from './local/duck';
import { dataReducer, DataState } from './data';
import { roktReducer, RoktIntegrationState } from './rokt/duck';
import { ExperimentalFeatureFlagsState, experimentalFeatureFlagsReducer } from './ffs';
import { AppsFlyerState, appsFlyerReducer } from './appsflyer/duck';
import { iterableReducer, IterableState } from './iterable/duck';

export interface StoreState {
    brands: BrandsState;
    catalog: CatalogState;
    categories: CategoriesState;
    home: HomeState;
    account: AccountState;
    search: SearchState;
    searchOnBonus: BrandsSearchResultsState;
    searchGrid: BrandsSearchResultsState;
    cart: CartState;
    wallet: WalletState;
    organization: OrganizationState;
    userData: UserDataState;
    featureFlags: FeatureFlagsState;
    coordinator: CoordinatorState;
    ui: UIState;
    local: LocalEndpointState;
    data: DataState;
    rokt: RoktIntegrationState;
    experimentalFeatureFlags: ExperimentalFeatureFlagsState;
    appsFlyer: AppsFlyerState;
    iterable: IterableState;
}

export const storeReducer = combineReducers<StoreState>({
    brands: brandsReducer,
    catalog: catalogReducer,
    categories: categoriesReducer,
    home: homeReducer,
    account: accountReducer,
    search: searchReducer,
    searchOnBonus: brandsOnBonus.reducer,
    searchGrid: brandSearch.reducer,
    cart: cartReducer,
    wallet: walletReducer,
    organization: organizationReducer,
    userData: userDataReducer,
    featureFlags: featureFlagsReducer,
    coordinator: coordinatorReducer,
    ui: uiStateReducer,
    local: localReducer,
    data: dataReducer,
    rokt: roktReducer,
    experimentalFeatureFlags: experimentalFeatureFlagsReducer,
    appsFlyer: appsFlyerReducer,
    iterable: iterableReducer,
});

export const doLogOut = () =>
    batchActions([
        logOut(),
        resetHomeDetails(),
        resetCart(),
        resetBrands(),
        resetOrganization(),
        resetWallet(),
    ]);
