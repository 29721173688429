import React, { useContext } from 'react';
import { createStyles } from 'utils/createStyle';
import { FamilyEnrollmentContext } from 'features/family-enrollment/context';
import { Btn } from 'components/btn/Btn';
import classnames from 'classnames';

export interface IBaseStyles {
    formContainer: string;
    buttonContainerStyles: string;
    buttonStyles: string;
    backButtonStyles: string;
}

// NOTE: Update the interface when adding new base-styles
export const useBaseStyles = (): IBaseStyles => {
    return createStyles({
        formContainer: ['', { marginTop: 30 }],
        buttonContainerStyles: ['flex flex-col items-center gap-4', { marginTop: 60 }],
        buttonStyles: ['', { width: 'fit-content' }],
        backButtonStyles: ['border-0 underline text-brand font-semibold text-lg'],
    });
};

export const ButtonControls = () => {
    const baseStyles = useBaseStyles();
    const { goToPreviousStep } = useContext(FamilyEnrollmentContext);

    return (
        <div className={baseStyles.buttonContainerStyles}>
            <Btn className={baseStyles.buttonStyles} type="submit">
                Next
            </Btn>
            <button
                className={classnames(baseStyles.buttonStyles, baseStyles.backButtonStyles)}
                onClick={(e) => {
                    e.preventDefault();
                    goToPreviousStep();
                }}
            >
                Back
            </button>
        </div>
    );
};
