import { APIResponse } from '../models';

import { createWithTokensDecorator } from './createWithTokensDecorator';

import { apiFetch } from './';

import { DO_NOT_ADD_BEARER_TOKENS } from 'common/env';
import { noop } from 'common/utils';

export const addTokensIfNeededDecorator = () => {
    if (DO_NOT_ADD_BEARER_TOKENS) {
        return {
            withTokensIfNeeded: async <T, E = null>(
                url: string,
                config: RequestInit = {}
            ): Promise<APIResponse<T, E>> => {
                const promise = apiFetch<T, E>(url, config);
                return promise;
            },
            setRefreshTokenErrorHandler: noop,
        };
    }

    const { withTokens, setRefreshTokenErrorHandler } = createWithTokensDecorator();
    return { withTokensIfNeeded: withTokens, setRefreshTokenErrorHandler };
};
