import { createSlice } from 'common/modules/create-slice';
import { createSelector } from 'reselect';
import { StoreState } from '..';
import { FeaturesState } from 'common/features/featuresReducer';

export interface RoktIntegrationState {
    beforePurchaseWalletCardIds: string[];
    showRoktPlacementOnWallet: boolean;
    productToExpect: any | null;
    buyNowSnapshot: any | null;
    cartCheckoutAttributes: any | null;
    homeScreenShowingCounter: number;
}

const initialState = {
    beforePurchaseWalletCardIds: [],
    showRoktPlacementOnWallet: false,
    productToExpect: null,
    buyNowSnapshot: null,
    cartCheckoutAttributes: null,
    homeScreenShowingCounter: 0,
};

export const roktSlice = createSlice<RoktIntegrationState>(
    {
        ...initialState,
    },
    'Rokt'
);

export const roktFlow = {
    ...roktSlice,
    trackWalletCardsBeforePurchase: roktSlice.configureAction(
        'TrackWalletCardsBeforePurchase',
        (walletCards: any) => (state) => {
            const beforePurchaseWalletCardIds = Object.keys(walletCards);
            return { ...state, beforePurchaseWalletCardIds };
        }
    ),
    showHomeScreenAds: roktSlice.configureAction('ShowHomeScreenAds', () => (state) => ({
        ...state,
        homeScreenShowingCounter: state.homeScreenShowingCounter + 1,
    })),
};

export const roktReducer = roktFlow.reducer;

export const selectShowRoktPlacementOnWallet = (state: FeaturesState) =>
    state.store?.rokt?.showRoktPlacementOnWallet;

export const selectJustPurchasedCard = createSelector(
    // given the latest cards object, we will determine if the card that we have just purchased is here
    // we are expecting the id to not be in the beforePurchaseWalletCardIds and for the card product to match the productToExpect
    [
        (s: FeaturesState) => s.store?.rokt?.beforePurchaseWalletCardIds,
        (s: FeaturesState) => s.store?.rokt?.productToExpect,
        (s: FeaturesState) =>
            s.store?.account?.userId ? s.store.wallet.cardsByUser[s.store.account.userId] : null,
    ],
    (beforePurchaseWalletCardIds: string[], productToExpect: any, latestCards) => {
        // check the latestCards ids to see if we have a new one
        const newCardId = Object.keys(latestCards || {}).find(
            (id) => !beforePurchaseWalletCardIds.includes(id)
        );
        if (newCardId) {
            const newCard = latestCards && latestCards[newCardId as string];
            return newCard;
        }

        return null;
    }
);

export const selectJustPurchasedCardFields = createSelector(
    [
        (s: FeaturesState) => s.store?.rokt?.beforePurchaseWalletCardIds,
        (s: FeaturesState) => s.store?.rokt?.productToExpect,
        (s: FeaturesState) =>
            s.store?.account?.userId ? s.store.wallet.cardsByUser[s.store.account.userId] : null,
    ],
    (beforePurchaseWalletCardIds: string[], productToExpect: any, latestCards) => {
        return { beforePurchaseWalletCardIds, productToExpect, latestCards };
    }
);

export const selectBuyNowAnalytics = createSelector(
    (s: FeaturesState) => s.store?.rokt?.buyNowSnapshot,
    (buyNowAnalytics) => buyNowAnalytics
);

export const selectCartCheckoutAttributes = createSelector(
    (s: FeaturesState) => s.store?.rokt?.cartCheckoutAttributes,
    (cartCheckoutAttributes) => cartCheckoutAttributes
);

export const selectHomeScreenShowingCounter = createSelector(
    (s: FeaturesState) => s.store?.rokt?.homeScreenShowingCounter,
    (homeScreenShowingCounter) => homeScreenShowingCounter
);
