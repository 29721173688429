import React, { useEffect } from 'react';

import { useHomeDetailsFromBff } from 'common/features/store/duck/home/utils/useHomeDetailsFromBff';

import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { HomeDetailsResponse } from 'common/api/crm/home/models/HomeDetailsResponse';
import { useRootSelector } from 'rootStore';
import {
    selectLegacyId,
    selectUserId,
    selectHasSetUpTwoFactor,
    hasSetupTwoFactorThunk,
    updateAccount,
} from 'common/features/store/duck/account/duck';
import { gaSetUserId, gaSetUserProperties } from 'config/analytics';
import { firebaseAnalytics } from 'config/firebase';
import { useDispatch } from 'react-redux';
import { useAlert } from 'modules/alerts';
import { useSelector } from 'react-redux';
import {
    selectHomeDetailsAsyncState,
    selectHomeDetails,
} from 'common/features/store/duck/home/duck';
import { AsyncActionState } from 'common/modules/async-actions/core';
import { AccountRoles } from 'common/api/users/service';

const userHasOrg = (homeDetails: HomeDetailsResponse) => {
    return !!homeDetails.defaultOrganization;
};

const inEnrollmentRoute = (path: string) => {
    return path.startsWith(Routes.StartProgram);
};

const inFamilyEnrollmentRoute = (path: string) => {
    return path.startsWith(Routes.EnrollFamily);
};

const defaultOrgApproved = (homeDetails: HomeDetailsResponse) => {
    return !!homeDetails?.defaultOrganization?.isApproved;
};

const homeDetailsHasNon401Error = (homeDetailsAsyncState: AsyncActionState) => {
    return (
        homeDetailsAsyncState.error &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (homeDetailsAsyncState.error as any)?.response?.status !== 401
    );
};

export const useAuthHook = () => {
    const homeDetails = useHomeDetailsFromBff();
    const history = useHistory();
    const path = history.location.pathname;
    const [userId, userRole] = useRootSelector((s) => [
        s.store.account.userId,
        s.store.account.role,
    ]);

    const dispatch = useDispatch<any>();
    const alert = useAlert();
    const homeDetailsAsyncState = useSelector(selectHomeDetailsAsyncState);
    const hasSetUpTwoFactor = useSelector(selectHasSetUpTwoFactor);

    // Effect: fire the API to retrieve if the user has setup 2FA.
    useEffect(() => {
        (async () => {
            if (userId && homeDetails) {
                await dispatch(hasSetupTwoFactorThunk(userId));
            }
        })();
    }, [userId, homeDetails, dispatch]);

    // Effect: check two factor phone status of user
    useEffect(() => {
        (async () => {
            const notInEnrollmentRoute = !inEnrollmentRoute(path) && !inFamilyEnrollmentRoute(path);
            const userIsFamily = userRole === AccountRoles.FAMILY;

            if (
                homeDetails &&
                !homeDetailsAsyncState.loading &&
                !homeDetailsAsyncState.error &&
                notInEnrollmentRoute &&
                hasSetUpTwoFactor === false &&
                userIsFamily
            ) {
                alert({
                    title: 'Finish Setting up your account',
                    message: 'Keep your account safe by setting up two factor authentication.',
                    largeAlert: true,
                    buttons: [
                        {
                            text: `Setup Two Factor Authentication`,
                            onClick: () => {
                                dispatch(updateAccount({ hasSetupTwoFactor: null }));
                                location.assign(
                                    `${process.env.REACT_APP_MANAGE_2FA_URL}?returnUrl=${window.location.href}`
                                );
                            },
                            className: 'o:whitespace-nowrap o:text-sm o:sm:text-lg',
                        },
                    ],
                });
            }
        })();
    }, [
        userId,
        path,
        userRole,
        hasSetUpTwoFactor,
        homeDetails,
        homeDetailsAsyncState.loading,
        homeDetailsAsyncState.error,
        dispatch,
        alert,
    ]);

    useEffect(() => {
        // NOTE: This does not run on app mount therefore we need the useEffect below
        // to run at least once.
        const unsubscribe = history.listen(() => {
            if (!homeDetailsAsyncState.loading && homeDetails) {
                if (
                    (!defaultOrgApproved(homeDetails) || !userHasOrg(homeDetails)) &&
                    !inEnrollmentRoute(history.location.pathname)
                ) {
                    history.push(Routes.StartProgramContinue);
                }
            }
        });
        return unsubscribe;
    }, [history, homeDetails, homeDetailsAsyncState]);

    // (FEC-453) If the user has no org or their default org is not approved
    // and is trying to visit a route other than the
    // org enrollment routes, redirect them to continue their org enrollment
    useEffect(() => {
        (async () => {
            //TODO: We need to update our 401 handling, we're only intercepting 401s if the
            // user is on a protected route, but they could be on a non-protected route
            // and still get a 401 from an expired cookie and need to be redirected to login.

            // Effect: if home details are done loading and we have a non 401 error, this
            // indicates some kind of network/API failure. Redirect to ooops page.
            if (
                !inEnrollmentRoute(path) &&
                !homeDetailsAsyncState.loading &&
                homeDetailsHasNon401Error(homeDetailsAsyncState)
            ) {
                history.push(Routes.FailedDefaultOrg);
                return;
            }

            // Effect: if home details are done loading and we have valid home details data
            // AND there wasn't an error fetching home details, we know that the user legitimately
            // doesn't have a default org and should continue enrollment
            if (!homeDetailsAsyncState.loading && homeDetails && !homeDetailsAsyncState.error) {
                if (
                    (!defaultOrgApproved(homeDetails) || !userHasOrg(homeDetails)) &&
                    !inEnrollmentRoute(path)
                ) {
                    history.push(Routes.StartProgramContinue);
                }
            }
        })();
    }, [
        homeDetails,
        history,
        path,
        homeDetailsAsyncState,
        alert,
        dispatch,
        userId,
        userRole,
        hasSetUpTwoFactor,
    ]);
};

const authenticateZendesk = (userId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).$zopim.livechat?.setName(userId);
};

export const UserAuthBff = ({ children }: { children: React.ReactNode }) => {
    const userId = useRootSelector(selectUserId);
    const homeDetails = useRootSelector(selectHomeDetails);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const zopim = (window as any).$zopim;

    useEffect(() => {
        if (homeDetails?.profile && zopim) {
            const userName = `${homeDetails?.profile.firstName} (${homeDetails?.profile.legacyId})`;
            zopim(function () {
                authenticateZendesk(userName);
            });
        }
    }, [homeDetails, zopim]);

    useEffect(() => {
        if (firebaseAnalytics) {
            gaSetUserId(userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseAnalytics, userId]);

    useEffect(() => {
        if (homeDetails?.profile.legacyId && firebaseAnalytics) {
            gaSetUserProperties({ user_set_value: homeDetails.profile.legacyId % 100 });
        }
    }, [homeDetails]);

    useAuthHook();
    return <>{children}</>;
};
