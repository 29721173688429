import React, { useContext } from 'react';
import { Document } from '@contentful/rich-text-types';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { UserContent } from 'components/user-content/UserContent';
import { PartialRecord } from 'common/definitions/PartialRecord';

interface ContentfulSubheadingProps {
    attribute: keyof OrganizationEnrollmentV2;
    data?: PartialRecord<string, string>;
}

export const ContentfulSubheading = ({ attribute, data }: ContentfulSubheadingProps) => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const content = contentfulEntry
        ? (contentfulEntry as OrganizationEnrollmentV2)[attribute]
        : undefined;

    return (
        <div className="mt-2 mb-5 text-grey-1">
            <FadeIn when={Boolean(content)}>
                <UserContent content={content as Document} data={data} />
            </FadeIn>
        </div>
    );
};
