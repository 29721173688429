export enum OrganizationType {
    AthleticGroup = 'AthleticGroup',
    BandMusic = 'BandMusic',
    Church = 'Church',
    Employee = 'Employee',
    Hospital = 'Hospital',
    Miscellaneous = 'Miscellaneous',
    PublicSchool = 'PublicSchool',
    ParochialSchool = 'ParochialSchool',
    Scouts = 'Scouts',
}
