import { Brand, searchResultBrandsToBrands } from './utils/searchResultBrandsToBrands';

import { getBrandsOnBonus } from 'common/api/search/getBrandsOnBonus';
import { APIFn, createApiThunk } from 'common/modules/async-actions/thunk';
import { createSlice } from 'common/modules/create-slice';
import { SearchResult } from 'common/api/search/models/SearchResult';
import { getBrands } from 'common/api/search/getBrands';
import { getBrandsInList } from 'common/api/search/getBrandsInList';
import { SearchApiQueryParams } from 'common/api/search';
import { APIResponse } from 'common/api/models';
import { SearchResultBrand } from 'common/api/search/models/SearchResultBrand';

export interface BrandsSearchResultsState {
    resultsAreVisible: boolean;
    brands: Brand[];
    facets?: { [key: string]: [{ count: number; value: string }] };
    pageBrands: Brand[];
    page: number;
    pageSize: number;
    from: number;
    to: number;
    total: number | null;
    loading: boolean;
    isLastPage: boolean;
    hasFetched: boolean;
}

export interface Settings {
    pageSize: number;
}

export const genericSearchBrands = <T extends APIFn<SearchResult>>(name: string, api: T) => {
    const initialSearchState: BrandsSearchResultsState = {
        resultsAreVisible: false,
        brands: [],
        pageBrands: [],
        page: 0,
        pageSize: 100,
        from: 0,
        to: 0,
        total: null,
        loading: false,
        isLastPage: false,
        hasFetched: false,
    };

    const { reducer, configureAction, update } = createSlice(initialSearchState, name);

    const updatePageInfo = (state: BrandsSearchResultsState): BrandsSearchResultsState => {
        const { page, pageSize } = state;
        const from = 0;
        const to = Math.min(pageSize * (page + 1), state.brands.length);
        const isLastPage = to + pageSize >= state.brands.length;

        // if the current page record position is greater than the number of records, resets back to first page.
        if (page > 0 && pageSize > 0 && from >= state.brands.length) {
            return updatePageInfo({
                ...state,
                page: 0,
            });
        }

        return {
            ...state,
            page,
            isLastPage,
            from,
            to,
            pageBrands: state.brands.slice(from, to),
        };
    };

    const setSearchResults = configureAction<SearchResult>(
        'SET_SEARCH_RESULTS',
        (results) => (state) => {
            const brands = searchResultBrandsToBrands(results);
            const facets = results.facets;

            return updatePageInfo({
                ...state,
                loading: false,
                brands,
                facets,
                total: brands.length,
                hasFetched: true,
            });
        }
    );

    const requestData = createApiThunk(api, () => (state, result) => {
        if (!state.loading && result) {
            return setSearchResults(result);
        }

        return update({ loading: state.loading });
    });

    const navigatePage = configureAction<string>('NAVIGATE_PAGE', (direction) => (state) => {
        let { page } = state;

        page += direction === 'next' ? 1 : -1;

        if (page < 0) {
            page = 0;
        }

        return updatePageInfo({
            ...state,
            page,
        });
    });

    const resetState = configureAction('RESET', () => () => initialSearchState);

    return {
        reducer,
        configureAction,
        update,
        requestData,
        navigatePage,
        resetState,
    };
};

export interface IFilterByPrefix extends Partial<SearchApiQueryParams> {
    prefix: string | RegExp;
}

/* Function to search brand name by prefix. We need to do further filtering w/ the
 * `prefix` passed in order to get the correct data-set. This is needed because the
 * cognitive search that is being used is currently __not__ using the `keyword-analyzer`
 * and therefore cannot do proper `prefix-search`.
 *
 * note: `prefix` should be a valid regex-string that is understood by `RegExp`.
 *
 * overrideFilterByPrefixSettings allows configuration of hand-selected unintuitive exception
 * matches to flow through for customer service reasons
 */
const overrideFilterByPrefixSettings: { [key: string]: string[] } = {
    '^T': ['Container Store', 'Buckle', 'Palm'],
};
export const filterByPrefix = async (
    opts: IFilterByPrefix,
    overrides = overrideFilterByPrefixSettings
) => {
    const { prefix, ...searchOptions } = opts;

    return getBrands({ ...searchOptions }).then((data: APIResponse<SearchResult, null>) => {
        const brands = data.data?.brands.filter(
            (brand: SearchResultBrand) =>
                (overrides[prefix as string] && overrides[prefix as string].includes(brand.name)) ||
                brand.name.match(new RegExp(prefix, 'i'))
        );

        return {
            ...data,
            data: {
                ...data.data,
                brands,
            },
        } as APIResponse<SearchResult, null>;
    });
};

export const brandsOnBonus = genericSearchBrands('SEARCH_BRANDS_ON_BONUS', getBrandsOnBonus);
export const brandSearch = genericSearchBrands('SEARCH_BRANDS', getBrands);

export const brandsSearchByPrefix = genericSearchBrands('SEARCH_BRANDS', filterByPrefix);

// NOTE: reuse same action name so the brandSearch reducer handles this
// - brandSearchInList's reducer is not `registered` no need to as we're reusing the `brandSearch` reducer
export const brandSearchInList = genericSearchBrands('SEARCH_BRANDS', getBrandsInList);
