import { ProductBonus } from 'common/api/models/ProductBonus';
import { uniq } from 'common/utils';

export interface BonusRebate {
    bonus: ProductBonus[];
    rebate: number[];
}

export const hasMultipleRebates = (item: BonusRebate) => {
    const bonuses = item.bonus?.map((bonus) => bonus.rebate);
    return uniq(bonuses).length > 1 || uniq(item.rebate).length > 1;
};

export const findMaxRebate = (rebates: number[]) => Math.max(...rebates);

export const findBonusRebate = (bonus: ProductBonus[]) => {
    if (!bonus || !bonus.length) {
        return;
    }

    return bonus.reduce((highest, next) => {
        if (highest.rebate !== next.rebate) {
            return highest.rebate > next.rebate ? highest : next;
        }

        return new Date(highest.expiration) > new Date(next.expiration) ? highest : next;
    });
};
