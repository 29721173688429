export enum CreativeSlotTypes {
    CategoryBanner = 'Category Banner',
    P1 = 'P1',
    P1Hero = 'P1 Hero',
    P2 = 'P2',
    P3 = 'P3',
    CIB1 = 'CIB1',
    CIB2 = 'CIB2',
    CIB3 = 'CIB3',
    CIB4 = 'CIB4',
    CIB5 = 'CIB5',
    CIB6 = 'CIB6',
    CIB7 = 'CIB7',
    CIB8 = 'CIB8',
    CIB9 = 'CIB9',
}
