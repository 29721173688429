import { EntryCollection } from 'contentful';

import { RecommendedCategoryResponse } from 'common/api/categories/models/RecommendedCategoryResponse';
import { apiFetch, normalizeResponse } from 'common/api/utils';
import { ALL_CATEGORIES_URL } from 'common/api/config';
import { contentfulApi } from 'common/api/utils/contentful';
import { CategoryData } from 'common/api/categories/models/CategoryData';
import { APIResponse } from 'common/api/models';

export const getAllCategories = async (): Promise<APIResponse<CategoryData[]>> => {
    const response = await apiFetch<CategoryData[]>(ALL_CATEGORIES_URL);

    return normalizeResponse(response);
};

export const getRecommendedCategories = async (): Promise<
    APIResponse<EntryCollection<RecommendedCategoryResponse>>
> =>
    // eslint-disable-next-line camelcase
    contentfulApi.getEntries<RecommendedCategoryResponse, { content_type: string }>({
        content_type: 'recommendedCategories', // eslint-disable-line camelcase
    });
