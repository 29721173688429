import { AchVerificationStatus } from 'common/api/e-comm/ach/models/AchAccount';

export enum PlaidVerificationStatus {
    /* eslint-disable camelcase */
    pending_automatic_verification = 'pending_automatic_verification',
    pending_manual_verification = 'pending_manual_verification',
    manually_verified = 'manually_verified',
    /* eslint-enable camelcase */
}

export const PlaidVerificationMapper: Record<PlaidVerificationStatus, AchVerificationStatus> = {
    [PlaidVerificationStatus.manually_verified]: AchVerificationStatus.Verified,
    [PlaidVerificationStatus.pending_automatic_verification]:
        AchVerificationStatus.PendingAutomatedVerification,
    [PlaidVerificationStatus.pending_manual_verification]:
        AchVerificationStatus.PendingManualVerification,
};

export enum PlaidErrorCode {
    TooManyVerificationAttempts = 'TOO_MANY_VERIFICATION_ATTEMPTS',
}
