import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { E_COMM_ORGS_URL } from 'common/api/config';

export interface VacationModeOptions {
    isVacationModeActive: boolean;
    vacationModeEndDate: string | null;
    vacationModeStartDate: string | null;
}

export interface OrgOptions extends VacationModeOptions {
    isShipToCoordinatorEnabled: boolean;
    isPayCoordinatorEnabled: boolean;
    originalIsPayCoordinatorEnabled: boolean;
    originalIsShipToCoordinatorEnabled: boolean;
    isBuyNowEnabled: boolean;
}

export interface VacationModeRequestOptions
    extends Omit<VacationModeOptions, 'isVacationModeActive'> {
    removeVacationMode?: boolean;
}

interface VacationModeResponse extends OrgOptions {
    organizationId: string;
    errorCode: string | null;
    errorMessage: string | null;
}

export interface OrgOptionsResponse extends OrgOptions {
    errorCode: string | null;
    error: string | null;
    organizationId: string;
}

interface OrgOptionsEditable
    extends Omit<
        OrgOptions,
        | 'isBuyNowEnabled'
        | 'isVacationModeActive'
        | 'vacationModeEndDate'
        | 'vacationModeStartDate'
        | 'originalIsPayCoordinatorEnabled'
        | 'originalIsShipToCoordinatorEnabled'
    > {}

export const getOptions = async () => {
    const res = await withTokensIfNeeded<OrgOptionsResponse>(
        `${E_COMM_ORGS_URL}/options?api-version=1`
    );

    return res;
};

export const setOptions = (orgOptions: OrgOptionsEditable, orgId: string) =>
    withTokensIfNeeded(`${E_COMM_ORGS_URL}/${orgId}/options?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orgOptions),
    });
export const setOrgVacationModeOptions = (data: VacationModeRequestOptions, orgId: string) =>
    withTokensIfNeeded<VacationModeResponse>(`${E_COMM_ORGS_URL}/${orgId}/options?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
