import { FormikErrors } from 'formik';

export * from './validation';

export type ValidationFn<T> = (value: T) => boolean;
export type ValidatorWithMessage<T> = (value: T) => string | undefined;
export type FormLevelValidator<Values> = (values: Values) => FormikErrors<Values>;

export const withMessage =
    (message: string) =>
    <T>(validator: ValidationFn<T>) =>
    (value: T) => {
        const isValid = validator(value);

        if (!isValid) {
            return message;
        }
    };

export const combineValidationFns =
    <T>(...fns: ValidationFn<T>[]) =>
    (value: T) => {
        for (const validator of fns) {
            const isValid = validator(value);

            if (!isValid) {
                return false;
            }
        }

        return true;
    };

export const anyOfValidationFns =
    <T>(...args: ValidationFn<T>[]) =>
    (value: T) => {
        return args.some((arg) => arg(value));
    };

export const combineValidators =
    <T>(...validators: ValidatorWithMessage<T>[]) =>
    (value: T) => {
        let message;

        for (const validator of validators) {
            message = validator(value);

            if (message) {
                return message;
            }
        }
    };
