import { useEffect } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';

import { getPasswordPolicyThunk } from '../duck';

import { FeaturesState } from 'common/features/featuresReducer';
import { needsData } from 'common/modules/async-actions/core';
import { callIfNeeded } from 'common/modules/async-actions/thunk';

export const useAppConfig = () => {
    const store = useStore<FeaturesState>();
    const dispatch = useDispatch<any>();
    const appConfig = useSelector((state: FeaturesState) => state.appConfig);

    useEffect(() => {
        const getPasswordPolicyIfNeeded = callIfNeeded(
            getPasswordPolicyThunk,
            needsData({ state: store.getState().appConfig.passwordPolicyAsyncState })
        );
        dispatch(getPasswordPolicyIfNeeded());
    }, [store, dispatch]);

    return { appConfig };
};
