import { isObject } from 'formik';
import camelCase from 'lodash.camelcase';
import startCase from 'lodash.startcase';
import matchAll from 'string.prototype.matchall';

export const toTitleCase = (str: string) => startCase(camelCase(str));
export const toCamelCase = (str: string) => camelCase(str);
export const toPascalCase = (str: string) => startCase(camelCase(str)).replace(/ /g, '');

export const pluralize = (singular: string, count: number, plural = `${singular}s`): string => {
    if (count === 1) {
        return singular;
    }
    return plural;
};

export const conjunct = (
    list: string[],
    conjunction = 'and',
    { oxfordComma = true } = {}
): string => {
    const useOxfordComma = list.length > 2 && oxfordComma;
    return [
        ...list.slice(0, -2),
        list.slice(-2).join(`${useOxfordComma ? ',' : ''} ${conjunction} `),
    ].join(', ');
};

export const monetize = (amount: number, options: Intl.NumberFormatOptions = {}): string => {
    const { minimumFractionDigits = Number.isInteger(amount) ? 0 : 2 } = options;
    const numberFormatOptions: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits,
        ...options,
    };
    return amount.toLocaleString('en-US', numberFormatOptions);
};

export const monetizeWithDecimal = (amount: number, options?: Intl.NumberFormatOptions) => {
    return monetize(amount, { minimumFractionDigits: 2, ...options });
};

export const demonetize = (str: string): number | null => {
    const parsedValue = parseFloat(str.replace(/,|\$|\s/g, ''));
    return Number.isNaN(parsedValue) ? null : parsedValue;
};

export const fromLocaleString = (str: string): number => {
    // HACK: For speed and simplicity, this assumes the locale will always be en-US (e.g. 10,000.00)
    return parseFloat(str.replace(/,/g, ''));
};

// https://github.com/sindresorhus/escape-string-regexp/blob/master/index.js
export const escapeStringRegExp = (str: string): string => {
    const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;
    return str.replace(matchOperatorsRegex, '\\$&');
};

// e.g. `highlightString('great Green', /gre/gi)`
// yields: [{ 0: 'gre' }, 'at ', { 0: 'Gre'}, 'en']
export const highlightString = (
    str: string,
    highlight: RegExp
): Array<string | RegExpMatchArray> => {
    const matches = Array.from(matchAll(str, highlight));
    const [firstMatch] = matches;
    if (!firstMatch) {
        return [str];
    }
    const head = str.slice(0, firstMatch.index);
    const initialValue: Array<string | RegExpMatchArray> = head ? [head] : [];
    const stringParts = matches.reduce((running, match, i) => {
        const matchEndIndex = (match.index || 0) + match[0].length;
        const nextMatch = matches[i + 1];
        const tail = str.slice(matchEndIndex, nextMatch ? nextMatch.index : undefined);
        return [...running, match, ...(tail ? [tail] : [])];
    }, initialValue);

    return stringParts;
};

export const replacePlaceholderValues = (
    content: string,
    placeholderValues: { [key: string]: string }
) => {
    // matches a to z upper and lower case, numbers and dots.
    const re = /\{([a-zA-Z0-9.]{1,30})\}/gi;
    return content.replace(re, (_, key) =>
        key in placeholderValues ? placeholderValues[key] : ''
    );
};

export const getFlatValues = (
    obj: { [key: string]: unknown },
    parentPath = '',
    depth = 1,
    maxDepth = 3
): { [key: string]: string } => {
    if (!isObject(obj) || (depth > -1 && depth > maxDepth)) {
        return {};
    }

    const keys = Object.keys(obj);
    const fullParentPath = parentPath + (parentPath ? '.' : '');

    return keys.reduce((acc, key) => {
        const value = obj[key];

        if (isObject(value)) {
            return {
                ...acc,
                ...getFlatValues(value as {}, fullParentPath + key, depth + 1, maxDepth),
            };
        }

        return {
            ...acc,
            [fullParentPath + key]: value,
        };
    }, {});
};
