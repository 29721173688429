import { FormikErrors, FormikProps } from 'formik';

import { focusOnFirstError } from './utils/focusOnFirstError';

export const useValidation = <T>(
    errorsToKeys: (errors: FormikErrors<T>) => string[] = Object.keys
) => {
    return (formikProps: FormikProps<T>) => async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const result = await formikProps.validateForm();
        const keys = errorsToKeys(result);

        focusOnFirstError(form, keys);

        formikProps.submitForm();
    };
};
