import React from 'react';

import { convertBrandToCardProps } from '../bonus/CardProps';

import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { createStyle, createStyles } from 'utils/createStyle';
import { FeaturesState, useRootSelector } from 'common/features/featuresReducer';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';

export interface SearchGridProps {
    items: Brand[];
    isLoading: boolean;
    ItemComponent: React.ElementType;
    containerStyles?: string;
    categoryName?: string;
    earnType?: EarnTypeConstructorFilter;
    singleTypeView?: boolean;
}

const style = createStyles({
    resultsContainer: createStyle('flex flex-wrap gap-4 justify-center'),
    loader: ['absolute', { zIndex: 100, left: '50%', top: '10%' }],
});

export const SearchGrid = ({
    categoryName,
    containerStyles,
    items,
    isLoading,
    ItemComponent,
    earnType,
    singleTypeView,
}: SearchGridProps) => {
    const totalResult = useRootSelector((s: FeaturesState) => s.store.searchGrid.total);
    const searchTerm = useRootSelector((s: FeaturesState) => s.store.search.term);

    const emptyBrand: Brand = {
        id: '0',
        name: '',
        rebate: 0,
        isOnBonus: false,
        isFeatured: false,
        imageUrl: '',
        keyWords: [],
        isLocalProduct: false,
        productIds: [],
        categories: [],
        newBannerExpiration: null,
        canDoubleDip: false,
        containsAffiliateOffer: false,
        isAffiliateOfferOnBonus: false,
        isRaiseRightGiftCardOnBonusOrAffiliateOnBonus: false,
        isRaiseRightBrand: false,
        supportedTypes: [],
        suggestions: [],
        urlSlug: '',
        isNew: false,
    };

    // if it is loading, add some empty items to show the loading animation.
    const gridItems =
        isLoading && items.length === 0
            ? new Array(12).fill(0).map((_, index) => ({
                  ...emptyBrand,
                  id: index.toString(),
              }))
            : items;

    const resultListContainerAttributes = {
        'data-cnstrc-search': searchTerm ? true : undefined,
        'data-cnstrc-browse': !searchTerm ? true : undefined,
        'data-cnstrc-filter-name': !searchTerm ? 'group_id' : undefined,
        'data-cnstrc-filter-value': categoryName ? categoryName : 'customerCategories',
    };

    return (
        <div className="relative">
            {isLoading ? <ActivityIndicator className={style.loader} /> : null}
            <div
                {...resultListContainerAttributes}
                data-cnstrc-num-results={totalResult}
                className={`${style.resultsContainer} ${containerStyles} ${
                    isLoading ? 'opacity-30' : ''
                }`}
            >
                {gridItems.map((item) => (
                    <div
                        data-cnstrc-item-id={item.id}
                        data-cnstrc-item-name={item.name}
                        key={`card_${item.id}`}
                        className="sm:inline-block"
                    >
                        <ItemComponent
                            {...convertBrandToCardProps(item, earnType, singleTypeView)}
                            earnType={earnType}
                            singleTypeView={singleTypeView}
                            isLoading={isLoading}
                        />
                    </div>
                ))}
                {!isLoading && gridItems.length <= 0 && <span>No gift cards were found.</span>}
            </div>
        </div>
    );
};
