import React, { useContext, useEffect } from 'react';

import { Btn } from 'components/btn/Btn';
import { ContentfulSubheading } from '../../components/ContentfulSubheading';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectProgressBarRef,
    setProgramType,
    updateEnrollmentLog,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { NumberedStep } from './NumberedStep';
import { UploadVoidedCheck } from './UploadVoidedCheck';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { scrollTo } from '../../utils/ScrollTo';

export const VoidedCheck = () => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const data = contentfulEntry as OrganizationEnrollmentV2;
    const title = data?.voidedCheckScreenTitle ?? '';
    const step1Text = data?.voidedCheckStep1Text ?? '';
    const step3Text = data?.voidedCheckStep3Text ?? '';
    const dispatch = useDispatch<any>();
    const orgId = useSelector(selectSelectedOrgId) || '';
    const progressBarRef = useSelector(selectProgressBarRef);

    useEffect(() => {
        scrollTo(progressBarRef);
        dispatch(
            updateEnrollmentLog({
                state: ProgressState.VoidedCheckSelected,
                version: 2,
                organizationId: orgId,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBack = () => {
        scrollTo(progressBarRef);
        dispatch(setProgramType(null));
    };

    return (
        <div className="py-8 sm:py-5 px-4 sm:px-10">
            <Heading title={title} />
            <Content step1Text={step1Text} step3Text={step3Text} />
            <Btn className="mx-auto sm:m-0 block" isSecondary onClick={onBack}>
                Back
            </Btn>
        </div>
    );
};

const Heading = ({ title }: { title: string }) => {
    return (
        <div>
            <p className="text-xl font-semibold sm:text-3xl text-brand-dark">{title}</p>
            <ContentfulSubheading attribute="voidedCheckScreenSubtitle" />
        </div>
    );
};

const Content = ({ step1Text, step3Text }: { step1Text: string; step3Text: string }) => {
    return (
        <ul>
            <NumberedStep number={1}>{step1Text}</NumberedStep>
            <UploadVoidedCheck />
            <NumberedStep number={3}>{step3Text}</NumberedStep>
        </ul>
    );
};
