import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';
import { RegisterCardRequest } from 'common/api/wallet/cards';
import { SuggestionBrand } from 'common/api/search/models/SuggestionBrand';

export type AddGiftCardState = {
    brand: SuggestionBrand | null;
    brands: SuggestionBrand[];
    isCardNumberInvalid: boolean;
    query: string;
    registerCardRequest: RegisterCardRequest;
    step: number;
    isPinRequired: boolean;
};

const initialCardRequestValues: RegisterCardRequest = {
    brandId: 0,
    cardNumber: '',
    name: null,
    pin: null,
    productSku: null,
    supplierCode: null,
};

const initialState: AddGiftCardState = {
    brand: null,
    brands: [],
    isCardNumberInvalid: false,
    query: '',
    registerCardRequest: initialCardRequestValues,
    step: 0,
    isPinRequired: false,
};

const slice = createSlice(initialState, 'UI/AddGiftCard');

export const addGiftCardFlow = {
    ...slice,
    resetState: slice.configureAction('ResetState', () => () => {
        return { ...initialState };
    }),
    resetRegisterCardRequest: slice.configureAction('ResetRegisterCardRequest', () => (state) => {
        return {
            ...state,
            registerCardRequest: initialCardRequestValues,
        };
    }),
    setStep: slice.configureAction('SetStep', (step: number) => (state) => {
        return { ...state, step };
    }),
    setQuery: slice.configureAction('SetQuery', (query: string) => (state) => {
        return { ...state, query };
    }),
    setSelectedBrand: slice.configureAction(
        'SetSelectedBrand',
        (brand: SuggestionBrand | null) => (state) => {
            return { ...state, brand };
        }
    ),
    setSuggestedBrands: slice.configureAction(
        'SetSuggestedBrands',
        (brands: SuggestionBrand[]) => (state) => {
            return { ...state, brands };
        }
    ),
    setRegisterCardRequest: slice.configureAction(
        'SetRegisterCardRequest',
        (request: RegisterCardRequest) => (state) => {
            return { ...state, registerCardRequest: request };
        }
    ),
    updateRegisterCardRequest: slice.configureAction(
        'UpdateRegisterCardRequest',
        (request: Partial<RegisterCardRequest>) => (state) => {
            return {
                ...state,
                registerCardRequest: {
                    ...state.registerCardRequest,
                    ...request,
                },
            };
        }
    ),
    setIsCardNumberInvalid: slice.configureAction(
        'SetIsCardNumberInvalid',
        (isCardNumberInvalid: boolean) => (state) => {
            return { ...state, isCardNumberInvalid };
        },
        { debounced: 500 }
    ),
    setIsPinRequired: slice.configureAction(
        'SetIsPinRequired',
        (isPinRequired: boolean) => (state) => {
            return {
                ...state,
                isPinRequired,
            };
        }
    ),
};
export default addGiftCardFlow.reducer;

// Selectors
export const selectStep = (s: FeaturesState) => s.store.ui.addGiftCard.step;

export const selectRegisterCardRequest = (s: FeaturesState) =>
    s.store.ui.addGiftCard.registerCardRequest;

export const selectQuery = (s: FeaturesState) => s.store.ui.addGiftCard.query;

export const selectSuggestedBrands = (s: FeaturesState): SuggestionBrand[] =>
    s.store.ui.addGiftCard.brands;

export const selectSelectedBrand = (s: FeaturesState) => s.store.ui.addGiftCard.brand;

export const selectIsCardNumberInvalid = (s: FeaturesState) =>
    s.store.ui.addGiftCard.isCardNumberInvalid;

export const selectCardDetails = (s: FeaturesState) => s.store.ui.addGiftCard.registerCardRequest;

export const selectCardNumber = (s: FeaturesState) =>
    s.store.ui.addGiftCard.registerCardRequest.cardNumber;

export const selectSupplierCode = (s: FeaturesState) =>
    s.store.ui.addGiftCard.registerCardRequest.supplierCode;

export const selectIsPinRequired = (s: FeaturesState) => s.store.ui.addGiftCard.isPinRequired;
