import {
    withMessage,
    required,
    maxLength,
    combineValidators,
    isURL,
    max,
    isValidURL,
} from 'common/modules/form-validation';
import { emojiValidator } from 'common/modules/form-validation/validators';

export const orgNameValidator = combineValidators(
    withMessage('The Organization Name field is required.')(required),
    withMessage('The Organization Name field must be at most 75 characters long.')(maxLength(75)),
    emojiValidator
);

export const orgAddressLine1Validator = combineValidators(
    withMessage('The Organization Address field is required.')(required),
    withMessage('The Organization Address field must be at most 200 characters long.')(
        maxLength(200)
    ),
    emojiValidator
);

export const orgTypeValidator = withMessage('The Organization Type field is required.')(required);

export const orgNumberOfFamiliesValidator = combineValidators(
    withMessage('The Number of People field is required.')(required),
    withMessage('Number of people must be less than 1,000,000.')(max(999_999))
);

export const orgFundraisingPurposeValidator = combineValidators(
    withMessage('The Fundraising Purpose field is required.')(required),
    withMessage('The Fundraising Purpose field must be at most 1,024 characters long.')(
        maxLength(1024)
    )
);

export const orgWebsiteValidator = combineValidators(
    withMessage('The Organization Website field is required.')(required),
    withMessage('Organization Website must be a valid URL.')(isURL)
);

export const orgWebsiteValidatorV2 = combineValidators(
    withMessage('The Organization Website field is required.')(required),
    withMessage('Organization Website must be a valid URL.')(isValidURL)
);
