import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import React from 'react';
import { AzureSearchBar } from './AzureSearchBar';
import { ConstructorSearchBar } from './ConstructorSearchBar';
import { useRootSelector } from 'rootStore';
import { selectIsConstructorSearchEnabledWeb } from 'common/features/store/duck/ffs';
import { selectLoginState } from 'common/features/store/duck/account/duck';

export interface SearchBarProps {
    onSearch: (filter: string) => void;
    onItemSelected: (item: Brand) => void;
    maxAutocompleteItems?: number;
    className?: string;
}

export const SearchBar = ({ ...props }: SearchBarProps) => {
    const isUserLoggedIn = useRootSelector(selectLoginState);
    const isConstructorEnabled = useRootSelector(selectIsConstructorSearchEnabledWeb);

    return isUserLoggedIn && isConstructorEnabled ? (
        <ConstructorSearchBar {...props} />
    ) : (
        <AzureSearchBar {...props} />
    );
};
