type Handler<TData> = (data: TData) => void;
type Unsubscribe = () => void;

export interface Emitter<THandlerMap> {
    on: <K extends keyof THandlerMap>(type: K, handler: Handler<THandlerMap[K]>) => Unsubscribe;
    emit: <K extends keyof THandlerMap>(type: K, data: THandlerMap[K]) => void;
    lastValue: <K extends keyof THandlerMap>(type: K) => THandlerMap[K] | undefined;
}

export const createEmitter = <THandlerMap>(): Emitter<THandlerMap> => {
    const allHandlers: { [K in keyof THandlerMap]?: Handler<THandlerMap[K]>[] } = {};
    const lastValues: { [K in keyof THandlerMap]?: THandlerMap[K] } = {};

    return {
        on: (type, handler) => {
            const handlers = (allHandlers[type] || (allHandlers[type] = []))!;
            handlers.push(handler);
            return () => {
                const index = handlers!.indexOf(handler);
                if (index > -1) {
                    handlers!.splice(index, 1);
                }
            };
        },
        emit: (type, data) => {
            if (allHandlers[type]) {
                allHandlers[type]!.forEach((handler) => handler(data));
            }
            lastValues[type] = data;
        },
        lastValue: (type) => lastValues[type],
    };
};
