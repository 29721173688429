import React, { useRef, useContext } from 'react';
import { CheckCircle } from 'react-feather';
import classnames from 'classnames';

import { EnrollmentContext } from '../../context';
import { gotStoreQuestion } from '../OrgProfileQuestion1';
import { inPersonQuestion } from '../OrgProfileQuestion2';

import { colors } from 'styles/settings';
import { Btn } from 'components/btn/Btn';
import { LoadingOverlay, closeLoader, openLoader } from 'components/loading-overlay/LoadingOverlay';
import { createStyles } from 'utils/createStyle';
import { PlanType } from 'common/api/crm/models/Organization';
import { withRetries } from 'common/utils/withRetries';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { updateOrganization } from 'common/api/crm/organization/service';

const renderLite = (isLite: boolean) => (
    <div
        className={`flex justify-center py-1 sm:py-3 px-3 ${
            isLite ? 'bg-brand-light' : 'bg-grey-7'
        }`}
    >
        <CheckCircle
            className="w-5 sm:w-10"
            aria-hidden
            color={isLite ? colors.brand : colors.grey3}
        />
        <span className="sr-only">Yes</span>
    </div>
);

const renderPlus = (isLite: boolean) => (
    <div
        className={`flex justify-center py-1 sm:py-3 px-3 ${
            !isLite ? 'bg-brand-light' : 'bg-grey-7'
        }`}
    >
        <CheckCircle
            className="w-5 sm:w-10"
            aria-hidden
            color={isLite ? colors.brand : colors.grey3}
        />
        <span className="sr-only">No</span>
    </div>
);

const renderEmpty = () => (
    <div className="py-1 sm:py-3 px-3  bg-white flex justify-center">
        <span className="sr-only">Disabled</span>
    </div>
);

const tableData = [
    {
        description: 'Free to setup and administer',
        lite: (isLite: boolean) => renderLite(isLite),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: 'Cards delivered directly to participants',
        lite: (isLite: boolean) => renderLite(isLite),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: '250+ brands available for eGift Cards and direct delivery',
        lite: (isLite: boolean) => renderLite(isLite),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: 'Participants order and pay using mobile app (iOS and Android)',
        lite: (isLite: boolean) => renderLite(isLite),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: 'Order eGift or physical cards on our website',
        lite: (isLite: boolean) => renderLite(isLite),
        plus: (isLite: boolean) => renderPlus(isLite),
    },

    {
        description: '450+ additional brands available to ship to coordinator',
        lite: () => renderEmpty(),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: 'Place orders as a coordinator, on behalf of a participant',
        lite: () => renderEmpty(),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
    {
        description: 'Purchase gift card inventory for on-site sales',
        lite: () => renderEmpty(),
        plus: (isLite: boolean) => renderPlus(isLite),
    },
];

const styles = createStyles({
    table: [
        'sm:grid flex flex-wrap w-full',
        {
            gridTemplateColumns: '1fr 90px 90px',
        },
    ],
    recommendHeaderColumn:
        'text-white bg-brand p-2 sm:p-1 text-center rounded-t-md w-1/2 sm:w-full border-b sm:border-0 border-solid border-grey-3',
    notRecommendHeaderColumn:
        'text-grey-1 bg-grey-6 p-2 sm:p-1 text-center rounded-t-md w-1/2 sm:w-full border-b sm:border-0 border-solid border-grey-3',
    text: 'sm:border-t border-solid border-grey-3 py-2 sm:py-3 text-sm font-light text-grey-1 w-full',
    checkContainer:
        'border-b sm:border-b-0 sm:border-t border-solid border-grey-3 text-center w-1/2 sm:w-full',
    buttonContainer: 'mb-4 flex justify-center align-center',
});

export const ChoosePlanStep = () => {
    const { goToNextStep, organization, setOrganization } = useContext(EnrollmentContext);
    const loader = useRef<LoadingOverlay>(null);

    const isLite = Boolean(
        organization.answers?.[gotStoreQuestion] && organization.answers?.[inPersonQuestion]
    );

    const onClickProgramButton = async (plan: PlanType) => {
        /* Side Effects:
         * Update the orgnization with the selected plan type &&
         * Update the enrollment log with LiteProgramSelected | PlusProgramSelected in order to
           set a `return point`
         */
        const orgWithPlan = {
            ...organization,
            planType: plan,
        };
        openLoader(loader.current);

        // update the organization w/ the chosen plan
        await withRetries(updateOrganization)(orgWithPlan);

        // update the enrollment flow context
        setOrganization(orgWithPlan);

        // update org-enrollment log state
        const nextOrgEnrollLogState =
            plan === PlanType.Lite
                ? ProgressState.LiteProgramSelected
                : ProgressState.PlusProgramSelected;
        await withRetries(updateEnrollmentProgress)({
            state: nextOrgEnrollLogState,
            organizationId: organization.id,
            version: 2,
        });
        await closeLoader(loader.current);

        // transition to next step
        goToNextStep(plan);
    };

    return (
        <div className="mt-8 max-w-2xl ml-auto px-7 sm:px-10 mr-auto items-center flex flex-col">
            <h2 className="text-xl sm:text-5xl text-left font-light text-brand-dark">
                {`We think the ${isLite ? 'Lite' : 'Plus'} Program suits your needs`}
            </h2>
            <p className="font-light mt-4 text-grey-1">
                We picked it for your athletic organization because you do not sell or meet in
                person regularly.
            </p>
            <br />
            <div className={styles.table}>
                <div />
                <div
                    className={
                        isLite ? styles.recommendHeaderColumn : styles.notRecommendHeaderColumn
                    }
                >
                    Lite
                </div>
                <div
                    className={
                        isLite ? styles.notRecommendHeaderColumn : styles.recommendHeaderColumn
                    }
                >
                    Plus
                </div>
                {tableData.map((it) => (
                    <React.Fragment key={it.description}>
                        <div className={styles.text}>{it.description}</div>
                        <div
                            className={classnames(styles.checkContainer, 'sm:border-l sm:border-r')}
                        >
                            {it.lite(isLite)}
                        </div>
                        <div className={styles.checkContainer}>{it.plus(isLite)}</div>
                    </React.Fragment>
                ))}
            </div>
            <div className="w-full">
                <h2 className="mt-8 text-lg sm:text-xl text-left font-semibold text-brand-dark">
                    Why choose this program
                </h2>
                <div className="w-full flex mt-2 flex-wrap">
                    <div className="w-full sm:w-1/2 pr-0 sm:pr-2 ">
                        <h2
                            className={`text-center text-sm sm:text-md py-2 ${
                                isLite ? 'bg-brand text-white' : 'bg-grey-6 text-grey-2'
                            }`}
                        >
                            Lite
                        </h2>
                        <ul className="text-grey-2 font-light text-xs sm:text-sm mt-3 ">
                            <li className="list-disc ml-5 ">
                                Never handle a gift card. All your people get their gift cards
                                delivered electronically or shipped directly to them.
                            </li>
                        </ul>
                    </div>
                    <div className="w-full sm:w-1/2 pl-0 sm:pl-2 mt-5 sm:mt-0">
                        <h2
                            className={`text-center text-sm sm:text-md py-2 ${
                                !isLite ? 'bg-brand text-white' : 'bg-grey-6 text-grey-2'
                            }`}
                        >
                            Plus
                        </h2>
                        <ul className="text-grey-2 font-light text-xs sm:text-sm mt-3">
                            <li className="list-disc ml-5">
                                Maximum fundraising opportunity. Programs that sell gift cards
                                online and in person earn 2x more per year than those that only sell
                                online.
                            </li>
                            <li className="list-disc ml-5 mt-3">
                                More options for gift card delivery. With Plus you can pool orders
                                together to save on shipping and distribute the cards at in-person
                                meetings.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex justify-between mt-6 sm:mt-2 mb-10">
                    <div className="w-1/2 mr-0 sm:mr-2 ">
                        <hr className="border-grey-5" />
                    </div>
                    <div className="w-1/2 ml-0 sm:ml-2 ">
                        <hr className="border-grey-5" />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Btn
                        className="sm:px-12"
                        onClick={() => onClickProgramButton(isLite ? PlanType.Lite : PlanType.Plus)}
                    >
                        {`Select ${isLite ? 'Lite' : 'Plus'} Program`}
                    </Btn>
                </div>
                <div className={styles.buttonContainer}>
                    <button
                        className="text-grey-2 text-sm underline"
                        onClick={() => onClickProgramButton(isLite ? PlanType.Plus : PlanType.Lite)}
                    >
                        {`I Prefer The ${isLite ? 'Plus' : 'Lite'} Program`}
                    </button>
                </div>
            </div>
        </div>
    );
};
