import { keyframes } from 'glamor';
import { CSSProperties } from 'react';

export const EASE_OUT_QUART = 'cubic-bezier(0.165, 0.84, 0.44, 1)';

// animation with bounce effect (from Figma)
export const EASE_IN_OUT_BACK = 'cubic-bezier(0.7, -0.4, 0.4, 1.4)';

export const defaultEaseInOutBackAnimation: CSSProperties = {
    transitionDuration: '.5s',
    transitionTimingFunction: EASE_IN_OUT_BACK,
};

export const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

export const fadeOut = keyframes({
    from: { opacity: 1 },
    to: { opacity: 0 },
});
