import { useEffect } from 'react';

interface Queries {
    [key: string]: Function;
}

type MediaQueryListener = (this: MediaQueryList) => void;

const useMediaQueries = (queries: Queries) => {
    useEffect(() => {
        const mqls = Object.entries(queries).map(([media, callback]: [string, Function]) => {
            const mql = window.matchMedia(media);

            // re-adjust the default card size in case user views page on mobile
            if (mql.matches) {
                callback();
            }

            function listener(this: MediaQueryList) {
                if (this?.matches) {
                    callback();
                }
            }
            /**
             * Safari versions < 14 don't support addEventListener on the MediaQueryList
             * object, this is a backwards compat check.
             */
            if (mql.addEventListener) {
                mql.addEventListener<'change'>('change', listener);
            } else {
                mql.addListener(listener);
            }

            return [mql, listener];
        });

        return () => {
            mqls.forEach((value: (MediaQueryList | MediaQueryListener)[]) => {
                const [mql, listener] = value;
                /**
                 * Safari versions < 14 don't support addEventListener on the MediaQueryList
                 * object, this is a backwards compat check.
                 */
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((mql as any).removeEventListener) {
                    (mql as MediaQueryList).removeEventListener<'change'>(
                        'change',
                        listener as MediaQueryListener
                    );
                } else {
                    (mql as MediaQueryList).removeListener(listener as MediaQueryListener);
                }
            });
        };
    }, [queries]);
};

export default useMediaQueries;
