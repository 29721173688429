import React, { useCallback, useRef, useMemo } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import { useHistory } from 'react-router-dom';

import { StepWrapperProps, StepWrapper } from './StepWrapper';

import { AddPhoneRequest, addPhone, DeliveryMethod } from 'common/api/users/twofactor/phone';
import { Btn } from 'components/btn/Btn';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { FormikTextField } from 'components/fields/FormikTextField';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import {
    twoFactorPhoneNumberValidator,
    twoFactorLockoutError,
} from 'common/modules/form-validation/validators';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { useAlert } from 'modules/alerts';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { Routes } from 'routes';
import { UnmaskedPhoneNumber } from 'common/api/users/models/UnmaskedPhoneNumber';
import { useValidation } from 'modules/form-validation/useValidation';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';

interface TwoFactorStepProps extends StepWrapperProps {
    initialPhoneNumber: string;
    onPhoneAdded: (phone: UnmaskedPhoneNumber) => void;
}

type FormValues = Omit<AddPhoneRequest, 'deliveryMethod'>;

const AddPhoneRequestField = typedFormikField<FormValues>()(FormikTextField);

export const TwoFactorStep = (props: TwoFactorStepProps) => {
    useHomeDetails();

    const { onPhoneAdded } = props;
    const validateAndSubmit = useValidation<FormValues>();
    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();
    const history = useHistory();
    const deliveryMethodRef = useRef(DeliveryMethod.Text);

    const onSubmit = useCallback(
        async (values: FormValues) => {
            openLoader(loader.current);
            const result = await addPhone({ ...values, deliveryMethod: deliveryMethodRef.current });
            await closeLoader(loader.current);
            if (result.error) {
                alert({
                    message: hasErrorWithStatus(result, StatusCode.TooManyRequests)
                        ? twoFactorLockoutError
                        : `Failed to send verification code to ${values.phoneNumber}. Please try again.`,
                });
                return;
            }
            onPhoneAdded({ id: result.data.phoneId, phoneNumber: values.phoneNumber });
            history.replace(Routes.StartProgramTwoFactorVerify);
        },
        [alert, onPhoneAdded, history]
    );

    const initialValues: FormValues = useMemo(
        () => ({ phoneNumber: sessionStorage.getItem('phone') || '' }),
        []
    );

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-sm mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <FieldSpacer />
                        <MaskedInput
                            mask={MASK_PHONE_US}
                            guide={false}
                            render={(ref, _props) => (
                                <AddPhoneRequestField
                                    {..._props}
                                    inputRef={ref}
                                    type="tel"
                                    name="phoneNumber"
                                    label="Phone Number (Mobile preferred)"
                                    validate={twoFactorPhoneNumberValidator}
                                    onKeyUp={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                        if (e.key === 'Enter') {
                                            if (await formikProps.validateForm()) {
                                                formikProps.submitForm();
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                        <FieldSpacer />
                        <div className="flex justify-center items-center">
                            <Btn
                                isSecondary
                                type="button"
                                className="whitespace-nowrap"
                                onClick={() => {
                                    deliveryMethodRef.current = DeliveryMethod.Voice;
                                    formikProps.submitForm();
                                }}
                            >
                                Call Me
                            </Btn>
                            <span className="text-brand-dark text-lg mx-3">Or</span>
                            <Btn
                                type="submit"
                                className="whitespace-nowrap"
                                onClick={() => {
                                    deliveryMethodRef.current = DeliveryMethod.Text;
                                }}
                            >
                                Text Me
                            </Btn>
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
