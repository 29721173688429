import React, { useEffect, useState, Fragment } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import classNames from 'classnames';

import { createStyles } from 'utils/createStyle';
import ImageSvg from 'media/images/CheckPlaceholerImage.svg';
import { colors } from 'styles/settings';

export interface ImageUploaderProps extends DropzoneOptions {
    file?: File | null;
    error?: string | null;
}

const styles = createStyles({
    root: ['h-28 p-2 flex items-center justify-center mb-0 sm:mb-8'],
    rootNoFile: [
        'rounded-md cursor-pointer border bg-pale-blue border-brand border-dashed',
        {
            '&:hover': { background: '#aedaea' },
        },
    ],
    rootActive: {
        borderColor: colors.brandHighlight,
        boxShadow: `0 0 0 1px ${colors.brandHighlight}, inset 0 0 0 1px ${colors.brandHighlight}`,
    },
    preview: 'bg-contain bg-no-repeat bg-center w-full h-full',
    cta: 'text-base font-semibold',
});

const cta = 'Select a File';

export const ImageUploader = (props: ImageUploaderProps) => {
    const { file } = props;
    const { getRootProps, getInputProps, isDragActive } = useDropzone(props);
    const [filePreview, setFilePreview] = useState<string>();

    useEffect(() => {
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => e.target && setFilePreview(e.target.result as string);
        reader.readAsDataURL(file);
    }, [file]);

    const renderPreview = () => {
        if (!file) {
            return;
        }
        if (file.type.startsWith('application/pdf')) {
            return <iframe src={filePreview} className={styles.preview} />;
        }
        return (
            <div className={styles.preview} style={{ backgroundImage: `url(${filePreview})` }} />
        );
    };

    return (
        <Fragment>
            <div
                data-testid="voided-check-uploader"
                {...getRootProps()}
                className={classNames(
                    styles.root,
                    isDragActive && styles.rootActive,
                    !file ? styles.rootNoFile : ''
                )}
                aria-label={`${file ? 'Image preview' : cta} ${props.error || ''}`}
            >
                <input id="imageInput" {...getInputProps()} />
                {!file ? (
                    <div className="flex flex-col items-center text-brand gap-3">
                        <img src={ImageSvg} alt="" />
                        <div className={styles.cta}>{cta}</div>
                    </div>
                ) : (
                    renderPreview()
                )}
            </div>
            {props.error && (
                <span className="text-error" aria-live="polite">
                    {props.error}
                </span>
            )}
        </Fragment>
    );
};
