import React from 'react';
import { css } from 'glamor';

import { colors } from 'styles/settings';
import { createStyles } from 'utils/createStyle';
import cartImg from 'media/images/cart.png';
import cellCardImg from 'media/images/cellCard.png';
import travelImg from 'media/images/travel.png';
import onHandImg from 'media/images/onHand.png';
import cellHandImg from 'media/images/cellHand.png';
import cellCardsImg from 'media/images/cellCards.png';

interface BottomContainerProps {
    children?: React.ReactNode;
}

const bottomContainerStyle = css({
    background: colors.paleBlue,
    ':before': {
        marginBottom: 10,
        content: ' ',
        position: 'relative',
        background: '#ffffff',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
        top: '-1.85rem',
        height: '3.2rem',
        width: '100%',
        borderRadius: '0 0 100% 100%',
    },
});

const styles = createStyles({
    bottomContainer: bottomContainerStyle,
});

const imgContainerClass = (hiddenOnMobile: boolean) => (hiddenOnMobile ? 'hidden sm:flex' : 'flex');

export const BottomContainer = ({ children }: BottomContainerProps) => (
    <div
        className={`fixed sm:absolute bottom-0 sm:bottom-auto left-0 h-54 sm:h-52 w-full flex flex-col justify-center items-center ${styles.bottomContainer}`}
    >
        <div
            className={`${imgContainerClass(
                Boolean(children)
            )} pb-12 sm:pb-3 pt-3 sm:pt-0 w-full lg:w-3/4 px-4 flex-row items-center justify-between`}
        >
            <img src={cartImg} className="h-20 sm:h-32" />
            <img src={cellCardImg} className="h-20 sm:h-32 -mb-4" />
            <img src={travelImg} className="h-20 sm:h-32" />
            <img src={onHandImg} className="hidden md:flex h-20 sm:h-32" />
            <img src={cellHandImg} className="hidden md:flex h-20 sm:h-32" />
            <img src={cellCardsImg} className=" h-20 sm:h-32" />
        </div>
        {children ? <div className="sm:hidden flex items-center flex-col">{children}</div> : null}
    </div>
);
