import { getQuestions } from 'common/api/users/questions';
import { useApi } from 'common/modules/async-actions/useApi';
import {
    SecurityQuestions,
    SecurityQuestionsProps,
} from 'features/coordinator/enrollment/steps/SecurityQuestions';
import { Form, Formik, FormikState, useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { createStyles } from 'utils/createStyle';
import { Document } from '@contentful/rich-text-types';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { BaseStep } from '../BaseStep';
import { FamilyEnrollmentContext } from '../../context';
import { register } from 'common/api/users/service';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { APIResponse } from 'common/api/models';
import { SecurityQuestion } from 'common/api/users/models/SecurityQuestion';
import { useAlert } from 'modules/alerts';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import { ButtonControls } from '../utils';
import { closeLoader, LoadingOverlay, openLoader } from 'components/loading-overlay/LoadingOverlay';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { Routes } from 'routes';
import { EnrollSourceApplicationIds } from 'common/api/auth/models/RegistrationRequestWithoutOrg';

const styles = createStyles({
    formContainer: ['flex flex-col m-6 mt-8'],
    buttonContainer: ['flex flex-row justify-center mt-14'],
});

const initialFormValues = { answer1: '', answer2: '', question1: '', question2: '' };

export const useSecurityQuestionsStep = () => {
    const [loadQuestions, { loading }, questions] = useApi(getQuestions);
    const { stepHeaders, userAccountInfo, loader } = useContext(FamilyEnrollmentContext);
    const { retrieveBlackBox } = useBlackBoxValues();
    const alert = useAlert();

    useEffect(() => {
        if (!questions && !loading) {
            loadQuestions();
        }
    }, [loadQuestions, loading, questions]);

    const onSubmit = useCallback(
        async (formValue: typeof initialFormValues) => {
            const blackboxValues = await retrieveBlackBox();
            openLoader(loader?.current as LoadingOverlay);

            const enrollmentCode = localStorage.getItem('RR_enrollmentCode') || '';

            const response = await register({
                ...userAccountInfo,
                enrollmentCode,
                blackBoxValue: blackboxValues.blackBoxValue || '',
                createOneTimeCode: true,
                securityQuestionAnswers: {
                    [formValue.question1]: formValue.answer1,
                    [formValue.question2]: formValue.answer2,
                },
                enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
            });

            if (response.error) {
                closeLoader(loader?.current as LoadingOverlay);
                alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
                return;
            }

            localStorage.removeItem('RR_enrollmentCode');

            sendToLoginPage(
                Routes.EnrollFamilySetupRecoveryPhone,
                `${response.data.oneTimeCode ?? ''}`
            );
        },
        [retrieveBlackBox, userAccountInfo, alert, loader]
    );

    return {
        onSubmit,
        questions,
        loading,
        stepHeaders,
    };
};

export const SecurityQuestionStep = () => {
    const { onSubmit, questions, loading, stepHeaders } = useSecurityQuestionsStep();

    if (loading) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <BaseStep
            contentClassName="text-md sm:text-lg font-thin"
            heading={stepHeaders?.securityQuestionsHeading || ''}
            subheading={stepHeaders?.securityQuestionsBody as Document}
        >
            <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
                <Form>
                    <SecurityQuestionForm questions={questions} />
                </Form>
            </Formik>
        </BaseStep>
    );
};

const SecurityQuestionForm = ({
    questions,
}: {
    questions: APIResponse<SecurityQuestion[], null> | undefined;
}) => {
    const { values } = useFormikContext<FormikState<SecurityQuestionsProps['selections']>>();

    return (
        <div className={styles.formContainer}>
            <SecurityQuestions
                questions={questions?.data || null}
                selections={values as unknown as SecurityQuestionsProps['selections']}
            />
            <ButtonControls />
        </div>
    );
};
