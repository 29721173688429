import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { CheckCircle } from 'react-feather';

import { copyTextToClipboard } from 'utils';

export const EmailCheckSection = () => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const emailButtonCls =
        'flex justify-center items-center bg-brand-light text-brand text-xs font-semibold mt-2 w-28 py-2';
    const emailButtonCopiedCls = 'bg-grey-5 text-brand-dark px-1';

    const onCopyEmail = useCallback((text: string) => {
        copyTextToClipboard(text);
        setIsCopied(true);
    }, []);

    return (
        <div className="mt-6">
            <p className="inline">Want to send a check instead? </p>
            <span>Email us a check to</span>{' '}
            <a className="text-sm mb-4" href="mailto:fundraising@raiseright.com">
                fundraising@raiseright.com
            </a>
            <button
                onClick={() => onCopyEmail('fundraising@raiseright.com')}
                className={
                    isCopied ? classNames(emailButtonCls, emailButtonCopiedCls) : emailButtonCls
                }
            >
                {isCopied ? (
                    <>
                        <CheckCircle className="mr-2" width={16} height={16} />
                        Email Copied
                    </>
                ) : (
                    'Copy Email'
                )}
            </button>
        </div>
    );
};
