import React, { useEffect, useState } from 'react';
import { getInAppMessages, initialize } from '@iterable/web-sdk';
import { getWebIterableToken } from 'common/api/crm/family/service';
import { useLocation } from 'react-router-dom';
import { useRootSelector } from 'rootStore';
import { colors } from 'styles/settings';

const tokenFetch = async ({ userID }: { userID?: any }) => {
    return (await getWebIterableToken()) as string;
};

const PACKAGE_NAME = process.env.REACT_APP_ITERABLE_WEB_PACKAGE_NAME || '';
const { setUserID, logout } = initialize(process.env.REACT_APP_ITERABLE_API_KEY || '', tokenFetch);

export const IterableInBrowserMessaging = () => {
    const userId = useRootSelector((r) => r.store.home.homeDetails?.profile?.userId);
    const location = useLocation();
    const [userIsSet, setUserIsSet] = useState(false);

    const { request, pauseMessageStream, resumeMessageStream, triggerDisplayMessages } =
        getInAppMessages(
            {
                // Here, configure the SDK. For more information, check out the
                // web SDK's GitHub repository: https://github.com/iterable/iterable-web-sdk
                bottomOffset: '20px',
                count: 20,
                closeButton: {
                    color: colors.paleBlue,
                    position: 'top-right',
                    sideOffset: '35px',
                    size: '20px',
                    topOffset: '20px',
                },
                displayInterval: 1000,
                onOpenNodeToTakeFocus: 'input',
                onOpenScreenReaderMessage: '...',
                packageName: PACKAGE_NAME,
                rightOffset: '20px',
                topOffset: '20px',
            } as any,
            { display: 'immediate' } as any
        );

    useEffect(() => {
        if (!userId) {
            if (userIsSet) {
                try {
                    setUserIsSet(false);
                    logout?.();
                } catch (e) {
                    console.error(e);
                }
            }
            return;
        }

        setUserID(userId)
            .catch()
            .then(() => {
                setUserIsSet(true);
                request().then().catch();
            });

        return () => {
            try {
                if (userIsSet) {
                    setUserIsSet(false);
                    logout?.();
                }
            } catch (e: any) {
                console.error(e);
            }
        };
    }, [userId]);

    useEffect(() => {
        if (userIsSet) {
            request().then().catch();
        }
    }, [location]);

    return null;
};
