export type AchPublicToken = string;

export enum AchVerificationStatus {
    PendingManualVerification = 'PendingManualVerification',
    PendingAutomatedVerification = 'PendingAutomatedVerification',
    VerificationExpired = 'VerificationExpired',
    VerificationFailed = 'VerificationFailed',
    Verified = 'Verified',
}

export enum AchAccountType {
    Legacy = 'Legacy',
    Plaid = 'Plaid',
}

export interface AchAccount {
    id: string;
    accountNumber: string;
    type: AchAccountType;
    verificationStatus: AchVerificationStatus;
    addressId: string;
    isOnHold: boolean;
    isLocked: boolean;
    availableForPayment: boolean;
}

export interface OrgAchAccount {
    id: string;
    organizationId: string;
    accountNumber: string;
    verificationStatus: AchVerificationStatus;
}

export enum OAuthRedirectType {
    iOS = 'iOS',
    OrganizationEnrollment = 'OrganizationEnrollment',
    LocalOrganizationEnrollment = 'LocalOrganizationEnrollment',
    AccountSettingsPaymentOption = 'AccountSettingsPaymentOption',
    LocalAccountSettingsPaymentOption = 'LocalAccountSettingsPaymentOption',
}

export interface AchUserLinkTokenRequest {
    androidPackageName?: string;
    webhook?: string;
    redirectType?: OAuthRedirectType;
}

export interface AchUserLinkTokenResponse {
    linkToken: string;
    expiration: string;
}

export const isVerified = (account: AchAccount) =>
    account.verificationStatus === AchVerificationStatus.Verified;
export const verficationExpiredOrFailed = (account: AchAccount) =>
    account.verificationStatus === AchVerificationStatus.VerificationExpired ||
    account.verificationStatus === AchVerificationStatus.VerificationFailed;
export const isAvailableForPayment = (account: AchAccount) =>
    isVerified(account) && !account.isOnHold && !account.isLocked;

export const isAccountBlocked = (account: AchAccount) => account.isOnHold || account.isLocked;
