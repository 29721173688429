import { keyframes } from 'glamor';

import { h3, link } from './typography';
import { EASE_OUT_QUART, fadeIn, fadeOut } from './easing';
import { getFocusOutline } from './accessibility';
import { breakpoints } from './settings';

import { createStyles } from 'utils/createStyle';

const duration = '150ms';

const zoomIn = keyframes({
    from: { opacity: 0, transform: 'scale(0.75)' },
    to: { opacity: 1, transform: 'scale(1)' },
});

const zoomOut = keyframes({
    from: { opacity: 1, transform: 'scale(1)' },
    to: { opacity: 0, transform: 'scale(0.75)' },
});

export const ModalStyles = createStyles({
    overlay: [
        'fixed bg-white bg-opacity-80 inset-0',
        {
            '&[data-state="open"]': { animation: `${fadeIn} ${duration} ${EASE_OUT_QUART}` },
            '&[data-state="closed"]': { animation: `${fadeOut} ${duration} ${EASE_OUT_QUART}` },
        },
    ],
    contentWrapper: [
        'fixed w-full h-full p-3 flex justify-center items-center overflow-hidden',
        {
            '&[data-state="open"]': { animation: `${zoomIn} ${duration} ${EASE_OUT_QUART}` },
            '&[data-state="closed"]': { animation: `${zoomOut} ${duration} ${EASE_OUT_QUART}` },
        },
    ],
    content: [
        'rounded-2xl bg-white p-5 sm:p-7 flex flex-col text-brand-dark text-lg overflow-auto',
        {
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
            maxHeight: '90vh',
        },
    ],
    header: 'flex justify-between w-full flex-shrink-0',
    title: `${h3} mb-5 lg:mr-8`,
    closeButton: [
        'h-8 w-8 relative flex justify-center items-center ml-auto',
        getFocusOutline({ borderRadius: 9999, offset: 4 }),
        {
            '&:hover > svg': {
                transform: 'scale(1.1)',
            },
            '&:focus > svg': {
                transform: 'scale(1.1)',
            },
        },
    ],
    buttonContainer: [
        'mt-7 sm:mt-14 flex flex-col items-center',
        {
            '> * + *': {
                marginTop: '1rem',
            },
        },
    ],
    primaryButton: {
        width: '100%',
        [breakpoints.sm]: {
            width: 'auto',
            minWidth: '17.5rem',
        },
    },
    secondaryButton: link,
});

export const AlertStyles = createStyles({
    content: ['rounded-2xl bg-white p-7', { boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)' }],
    title: `${h3} mb-3`,
    body: 'text-brand-dark text-lg',
    buttonContainer: [
        'mt-8 mx-auto w-full flex flex-wrap justify-center',
        {
            maxWidth: '17.5rem',
            '> * + *': {
                marginTop: '1rem',
            },
        },
    ],
    secondaryButton: [
        link,
        {
            flexBasis: '100%',
        },
    ],
});
