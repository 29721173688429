import React, { useContext, useRef } from 'react';
import { BaseStep } from '../BaseStep';
import { Formik, Form, useFormikContext } from 'formik';
import { FamilyEnrollmentContext } from 'features/family-enrollment/context';
import { Document } from '@contentful/rich-text-types';
import { FormikTextField } from 'components/fields/FormikTextField';
import {
    twoFactorPhoneNumberValidator,
    twoFactorLockoutError,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { AddPhoneRequest, addPhone, DeliveryMethod } from 'common/api/users/twofactor/phone';
import MaskedInput from 'react-text-mask';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import { Btn } from 'components/btn/Btn';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { useAlert } from 'modules/alerts';
import { useBaseStyles } from 'features/family-enrollment/steps/utils';

interface Setup2FaFormProps extends AddPhoneRequest {}

const AddPhoneRequestField = typedFormikField<AddPhoneRequest>()(FormikTextField);

const Setup2FAFormFields = ({
    deliveryMethod,
}: {
    deliveryMethod: React.MutableRefObject<DeliveryMethod>;
}) => {
    const { submitForm, validateForm } = useFormikContext();

    // NOTE: requested <enter> key should submit form.
    const onEnterKeyPressed = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (await validateForm()) {
                submitForm();
            }
        }
    };

    // NOTE: `submit` attribute in a button isn't enough to submit the form having pressed <enter>.
    return (
        <Form>
            <MaskedInput
                mask={MASK_PHONE_US}
                guide={true}
                render={(ref, _props) => (
                    <AddPhoneRequestField
                        {..._props}
                        inputRef={ref}
                        type="tel"
                        name="phoneNumber"
                        label="Phone Number (Mobile preferred)"
                        validate={twoFactorPhoneNumberValidator}
                        onKeyUp={onEnterKeyPressed}
                    />
                )}
            />
            <FieldSpacer />
            <div className="flex justify-center items-center">
                <Btn
                    isSecondary
                    type="button"
                    className="whitespace-nowrap"
                    onClick={() => {
                        deliveryMethod.current = DeliveryMethod.Voice;
                        submitForm();
                    }}
                >
                    Call Me
                </Btn>
                <span className="text-brand-dark text-lg mx-3">Or</span>
                <Btn type="submit" className="whitespace-nowrap">
                    Text Me
                </Btn>
            </div>
        </Form>
    );
};

const TwoFactorForm = () => {
    const { goToNextStep, loader, setPhoneId, setPhoneNumber } =
        useContext(FamilyEnrollmentContext);
    const deliveryMethod = useRef<DeliveryMethod>(DeliveryMethod.Text);
    const initialValues = {
        phoneNumber: '',
        deliveryMethod: DeliveryMethod.Text,
    };
    const history = useHistory();
    const alert = useAlert();
    const baseStyles = useBaseStyles();

    const onSubmit = async (values: Setup2FaFormProps) => {
        openLoader(loader?.current as LoadingOverlay);
        const payload = { ...values, deliveryMethod: deliveryMethod.current };
        const response = await addPhone(payload);
        closeLoader(loader?.current as LoadingOverlay);

        // CASE: if user is not authorized -- we kicked them out to /enroll
        if (hasErrorWithStatus(response, StatusCode.Unauthorized)) {
            history.replace(Routes.Enroll);
            return;
        }

        if (response.error) {
            alert({
                message: hasErrorWithStatus(response, StatusCode.TooManyRequests)
                    ? twoFactorLockoutError
                    : `Failed to send verification code to ${values.phoneNumber}. Please try again.`,
            });
            return;
        }

        // save phone info on context to use on the verify step.
        setPhoneId(response.data.phoneId);
        setPhoneNumber(values.phoneNumber);

        // BASE CASE: we continue with enrollment
        goToNextStep();
    };

    return (
        <div className={baseStyles.formContainer}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Setup2FAFormFields deliveryMethod={deliveryMethod} />
            </Formik>
        </div>
    );
};

export const Setup2FaStep = () => {
    const { stepHeaders } = useContext(FamilyEnrollmentContext);
    return (
        <BaseStep
            contentClassName="text-md sm:text-lg font-thin"
            heading={stepHeaders?.twoFactorPhoneHeading as string}
            subheading={stepHeaders?.twoFactorPhoneBody as Document}
        >
            <TwoFactorForm />
        </BaseStep>
    );
};
