import { combineReducers, Reducer } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

import { storeReducer } from './store/duck';
import { appConfigReducer } from './app-config/duck';

import { makeLens } from 'common/modules/lens/makeLens';

export const featuresReducer = combineReducers({
    store: storeReducer,
    appConfig: appConfigReducer,
});

type ExtractState<TReducer> = TReducer extends Reducer<infer S> ? S : never;
export type FeaturesState = ExtractState<typeof featuresReducer>;

export const useRootSelector: TypedUseSelectorHook<FeaturesState> = useSelector;
export const rootL = makeLens<FeaturesState>();
