import React, { useContext, useState } from 'react';
import { Heart } from 'react-feather';
import { useSpring, animated, config } from 'react-spring';

import { colors } from '../../styles/settings';
import { StylesContext } from '../../features/search/components/contexts';
import { createStyles } from '../../utils/createStyle';
import { useRootSelector } from 'common/features/featuresReducer';
import { selectLoginState } from 'common/features/store/duck/account/duck';
import { useFavoriteControls } from './utils';

export const style = createStyles({
    favorite: ['flex w-5 h-5'],
});

export const Favorite = () => {
    const { data } = useContext(StylesContext);
    const { onClick, isFavorite } = useFavoriteControls(Number(data.id));
    const isLoggedIn = useRootSelector(selectLoginState);

    const [animateHeart, setAnimateHeart] = useState(false);

    const { x } = useSpring({
        from: { x: 0 },
        to: { x: animateHeart ? 1 : 0 },
        config: { duration: 300, ...config.gentle },
    });

    if (!isLoggedIn) {
        return null;
    }

    const onHeartClick = (e: React.SyntheticEvent) => {
        if (isLoggedIn) {
            setAnimateHeart((value) => !value);
            onClick(e);
        }
        e.stopPropagation();
    };

    return (
        <span className={`favorites_icon ${style.favorite}`}>
            <animated.div
                style={{
                    scale: x.to({
                        range: [0, 0.2, 0.4, 0.6, 0.8, 1],
                        output: [1, 0.9, 0.95, 1.05, 1],
                    }),
                }}
            >
                <Heart
                    data-cnstrc-btn="add_to_wishlist"
                    style={{ height: '17.5px', width: '20px' }}
                    onClick={onHeartClick}
                    fill={isFavorite ? colors.brandAccent : colors.white}
                    color={isFavorite ? colors.bonusPink : colors.brand}
                />
            </animated.div>
        </span>
    );
};
