export const focusOnFirstError = (form: HTMLElement, fieldsWithErrors: string[]) => {
    fieldsWithErrors.some((fieldWithError) => {
        const input: HTMLElement | null = form.querySelector(`[name="${fieldWithError}"]`);
        if (input) {
            input.focus();
            return true;
        }
        return false;
    });
};
