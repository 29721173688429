import { ContentfulContentTypeId } from 'common/api/config';
import { BrandLink } from 'common/api/store/models/BrandLink';
import { ExternalLink } from 'common/api/store/models/ExternalLink';
import { Entry } from 'contentful';
import { Routes, getPath } from 'routes';

export const getContentfulPath = (entry: Entry<unknown>, brandSlug: string | undefined) => {
    let path = '';
    switch (entry.sys.contentType.sys.id) {
        case ContentfulContentTypeId.ExternalLink: {
            const externalLinkEntry = entry as Entry<ExternalLink>;
            path = externalLinkEntry.fields.url;
            break;
        }
        case ContentfulContentTypeId.Brand: {
            const brandLinkEntry = entry as Entry<BrandLink>;
            const brandId = Number(brandLinkEntry.fields.brandId);
            path = getPath(Routes.BrandsDetails, { id: `${brandId.toString()}-${brandSlug}` });
            break;
        }
        default:
            break;
    }
    return { path, type: entry.sys.contentType.sys.id };
};
