/**
 * @function asTuple
 * This is a utility function to help with type inference.
 * Sometimes typescript will infer [a, b] as Array<typeof a | typeof b>,
 * but you might need it to infer it as a tuple type of [typeof a, typeof b].
 * @param {...any} params
 */
export function asTuple<T1, T2>(t1: T1, t2: T2): [T1, T2];
export function asTuple<T1, T2, T3>(t1: T1, t2: T2, t3: T3): [T1, T2, T3];
export function asTuple<T1, T2, T3, T4>(t1: T1, t2: T2, t3: T3, t4: T4): [T1, T2, T3, T4];
// ...add more variants as needed

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asTuple(...params: any[]) {
    return params;
}
