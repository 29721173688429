import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isObject } from 'lodash';

import { FieldStyles } from '../../../../components/fields/FieldStyles';
import { FieldWrapper, FieldWrapperProps } from '../../../../components/fields/FieldWrapper';

import { Select } from './Select';

export type SelectFieldProps = FieldWrapperProps & {
    name: string;
    items: { text: string; id: string }[];
    value?: string;
    className?: string;
    placeholder?: string;
    dataRecordingSensitive?: boolean;
};

export const SelectField = (props: SelectFieldProps) => {
    const {
        label,
        name,
        error,
        placeholder,
        className,
        items,
        dataRecordingSensitive = false,
    } = props;
    const ctx = useFormikContext();

    const values = isObject(ctx.values) ? Object(ctx.values) : null;
    const value = values[name] as string;

    return (
        <FieldWrapper label={label} id={name} error={error}>
            {() => (
                <Fragment>
                    <Select
                        items={items}
                        onChange={(item) => name && ctx.setFieldValue(name, item.id)}
                        value={value || ''}
                        placeholder={placeholder}
                        className={classNames(FieldStyles.field, className, {
                            error: Boolean(error),
                        })}
                        dataRecordingSensitive={dataRecordingSensitive}
                    />
                </Fragment>
            )}
        </FieldWrapper>
    );
};
