import { BrandSearchResponse } from './models/BrandSearchResponse';
import { toSearchResult } from './utils/toSearchResult';
import { defaultBrandSearchOptions } from './config';

import { SearchApiQueryParams, searchApiFetch } from './';

import { and, eq } from 'common/utils/searchFilters';

export const featuredBrandsFilter = eq('IsFeatured', true);

export const getFeaturedBrands = async (options: Partial<SearchApiQueryParams> = {}) =>
    toSearchResult(
        await searchApiFetch<BrandSearchResponse>({
            ...defaultBrandSearchOptions,
            ...options,
            filter: options.filter
                ? and(featuredBrandsFilter, options.filter)
                : featuredBrandsFilter,
        })
    );
