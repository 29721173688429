import React from 'react';

import {
    securityQuestion1Validator,
    securityQuestion2Validator,
    securityQuestionAnswerValidator,
} from 'common/modules/form-validation/validators';
import { SecurityQuestion } from 'common/api/users/models/SecurityQuestion';
import { FormikTextField } from 'components/fields/FormikTextField';
import { FormikSelectField } from 'features/coordinator/enroll/components/FormikSelectField';

export interface SecurityQuestionsProps {
    questions: SecurityQuestion[] | null;
    selections: {
        [key: string]: string;
    };
}

interface IToQuestionOptions extends SecurityQuestionsProps {
    index: number;
}

const toQuestionOptions = ({
    questions,
    selections,
    index,
}: Omit<IToQuestionOptions, 'refCaptcha'>) => {
    const questionSelections = [selections?.question1, selections?.question2];
    return (
        (questions &&
            questions
                .filter((q) =>
                    questionSelections.every((sel, j) => sel !== String(q.id) || j === index)
                )
                .map((question) => ({
                    text: question.questionText,
                    id: question.id.toString(),
                }))) ||
        []
    );
};

export const SecurityQuestions = ({ questions, selections }: SecurityQuestionsProps) => {
    const questionOneOptions = toQuestionOptions({ questions, selections, index: 0 });
    const questionTwoOptions = toQuestionOptions({ questions, selections, index: 1 });

    return (
        <>
            <div className="mb-6">
                <FormikSelectField
                    dataRecordingSensitive
                    label="Security Question 1"
                    name="question1"
                    validate={securityQuestion1Validator}
                    items={questionOneOptions}
                />
            </div>

            <div className="mb-6">
                <FormikTextField
                    data-recording-sensitive
                    label="Answer 1"
                    name="answer1"
                    validate={securityQuestionAnswerValidator}
                    autoComplete="off"
                />
            </div>

            <div className="mb-6">
                <FormikSelectField
                    dataRecordingSensitive
                    label="Security Question 2"
                    name="question2"
                    validate={securityQuestion2Validator}
                    items={questionTwoOptions}
                />
            </div>

            <div className="mb-6">
                <FormikTextField
                    data-recording-sensitive
                    label="Answer 2"
                    name="answer2"
                    validate={securityQuestionAnswerValidator}
                    autoComplete="off"
                />
            </div>
        </>
    );
};
