import React from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { BaseAlert, BaseAlertProps } from './BaseAlert';

interface InfoAlertProps extends BaseAlertProps {
    renderers?: { Title: React.ElementType };
    type: 'info' | 'error' | 'success';
}

export enum AlertType {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
}
const classes = {
    info: { containerClasses: 'border-brand', titleClasses: 'text-brand' },
    error: { containerClasses: 'border-error', titleClasses: 'text-error' },
    success: { containerClasses: 'border-success', titleClasses: 'text-success' },
};
export const Alert = ({
    type,
    title,
    children,
    className = '',
    renderers = { Title: Title },
}: InfoAlertProps) => {
    return (
        <BaseAlert
            className={classnames(classes[type].containerClasses, className)}
            title={
                <renderers.Title className={classes[type].titleClasses}>{title}</renderers.Title>
            }
        >
            {children}
        </BaseAlert>
    );
};

const Title = ({ className, children }: { className: string; children: React.ReactNode }) => {
    return (
        <div className={className}>
            <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="mr-2" />
            {children}
        </div>
    );
};
