import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { groupBy } from 'lodash';

import { MobileDialog } from './MobileDialog';
import { FilterByProps, FilterData, FilterByGroupOption, FilterByOption } from './FilterBy';

import { or, and } from 'common/utils/searchFilters';
import { createStyles, createStyle } from 'utils/createStyle';
import { defaultBrandFilterFacets } from 'common/api/search/getBrandsByCategoryName';
import { useRootSelector, FeaturesState } from 'common/features/featuresReducer';
import { BonusToggle } from 'components/bonus-toggle/BonusToggle';
import { brandsOnBonusFilter } from 'common/api/search/getBrandsOnBonus';
import { AThroughZMobileFilterCategory, getSearchTerm } from './AlphabetFilterUtils';
import { useDispatch } from 'react-redux';
import { applyTerm, updateSearch } from 'common/features/store/duck/search/duck';
import { filterByPrefix, IFilterByPrefix } from 'common/features/store/duck/search-generic/duck';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { ContentfulContext } from './ContentfulView';

interface FilterDialogContextProps {
    selections: FilterByOption[];
    onClick: (option: FilterByOption) => void;
}

const FilterDialogContext = React.createContext<FilterDialogContextProps>({
    selections: [],
    onClick: () => null,
});

const optionIsSelected = (option: FilterByOption, selectedOptions: FilterByOption[]) => {
    return selectedOptions.some((opt: FilterByOption) => option.value === opt.value);
};

const footerBtnStyle = createStyle(
    'mt-1 w-full py-1 px-10 py-3 rounded-3xl bg-brand text-white font-semibold',
    {
        maxWidth: 280,
    }
);

// NOTE: Remove once merged in with JC-70
interface ButtonProps {
    active: boolean;
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = React.memo(({ active = false, children, onClick }: ButtonProps) => {
    const btnStyles = createStyles({
        btnStyles: [
            `text-brand ${active ? 'bg-pale-blue font-semibold' : 'bg-white'}`,
            {
                margin: '5px',
                border: '1px solid #005E9D',
                borderSizing: 'border-box',
                borderRadius: '5px',
                width: '140px',
                height: '50px',
                fontSize: 12,
                ':focus': {
                    outline: 'none',
                    boxShadow: 'none',
                },
            },
        ],
    });

    return (
        <button className={btnStyles.btnStyles} onClick={onClick}>
            {children}
        </button>
    );
});

const CategoryOptionsRenderer = ({ categories }: { categories: FilterByOption[] }) => {
    const { selections, onClick } = useContext(FilterDialogContext);
    return (
        <div className="category-options-container grid grid-flow-rows justify-items-center grid-cols-2 mb-4">
            {categories.map((category: FilterByOption) => (
                <Button
                    key={category.value}
                    active={optionIsSelected(category, selections)}
                    onClick={() => onClick(category)}
                >
                    {category.label}
                </Button>
            ))}
        </div>
    );
};

const CategoryRenderer = ({ group }: { group: FilterByGroupOption }) => {
    return (
        <div>
            <p className="font-semibold text-center text-grey-1 text-xl">{group.title}</p>
            <CategoryOptionsRenderer categories={group.options} />
        </div>
    );
};

const CategoriesRenderer = ({ groups }: { groups: FilterByGroupOption[] }) => {
    return (
        <div>
            {groups.map((group: FilterByGroupOption) => (
                <CategoryRenderer key={group.key} group={group} />
            ))}
        </div>
    );
};

export const MobileFilterDialog = ({ groups, data, onChange, showAZFilter }: FilterByProps) => {
    const [filterData, setFilterData] = useState<FilterData>(data);
    const searchTerm = useRootSelector((s: FeaturesState) => s.store.search.term);
    const { filterList } = useContext(ContentfulContext);
    const globalFilters = useGlobalBrandFilters();
    const [showBonus, setShowBonus] = useState<boolean>(false);
    const dispatch = useDispatch<any>();

    const toggleBonusFilter = (checked: boolean) => {
        setFilterData({ ...filterData, onBonusOnly: checked });
        setShowBonus(checked);
    };

    useEffect(() => {
        setFilterData(data);
    }, [data, setFilterData]);

    const onApplyClicked = () => {
        onChange(filterData);
        dispatch(applyTerm(searchTerm));
    };

    useEffect(() => {
        (async () => {
            const filter = Object.values(
                groupBy<FilterByOption>(
                    filterData.selectedOptions,
                    ({ group }: FilterByOption) => group
                )
            ).map((categoryGroup: FilterByOption[]) =>
                or(...categoryGroup.map((option: FilterByOption) => option.value))
            );

            if (filterList) {
                filter.push(`search.in(Id, '${filterList.join()}')`);
            }

            if (showBonus) {
                filter.push(brandsOnBonusFilter);
            }
        })();
    }, [filterData, searchTerm, showBonus, filterList, globalFilters]);

    const onFilterClicked = useCallback((option: FilterByOption) => {
        setFilterData((data_: FilterData) => {
            if (optionIsSelected(option, data_.selectedOptions)) {
                // remove it from the set of filters
                return {
                    ...data_,
                    selectedOptions: data_.selectedOptions.filter(
                        (opt: FilterByOption) => opt.value !== option.value
                    ),
                };
            }

            // otherwise filter hasn't been selected and we should just add it to our selected filters
            return {
                ...data_,
                selectedOptions: [...data_.selectedOptions, option],
            };
        });
    }, []);

    const onAlphabetFilterClicked = (letter: string) => {
        dispatch(updateSearch({ term: letter }));
    };

    const clearAll = useCallback(() => {
        setFilterData({ onBonusOnly: false, selectedOptions: [] });
        dispatch(updateSearch({ term: '' }));
        setShowBonus(false);
    }, [dispatch]);

    const Header = useMemo(
        () => (
            <div>
                <span className="text-xs text-brand underline" onClick={clearAll}>
                    Clear All
                </span>
            </div>
        ),
        [clearAll]
    );

    return (
        <MobileDialog
            btnName="Filter"
            footer={(onClick: React.MouseEventHandler) => (
                <div className="flex flex-col">
                    <div className="mt-3 flex justify-center">
                        <BonusToggle toggleFunc={toggleBonusFilter} currentBonusState={showBonus} />
                    </div>
                    <button className={footerBtnStyle} onClick={onClick}>
                        Show Results
                    </button>
                </div>
            )}
            header={Header}
            styles={{ container: 'border-r-0 border-l-0' }}
            onApply={onApplyClicked}
        >
            <FilterDialogContext.Provider
                value={{
                    onClick: onFilterClicked,
                    selections: filterData.selectedOptions,
                }}
            >
                {showAZFilter && (
                    <AThroughZMobileFilterCategory
                        currentFilter={searchTerm}
                        onFilterClicked={onAlphabetFilterClicked}
                    />
                )}
                <CategoriesRenderer groups={groups} />
            </FilterDialogContext.Provider>
        </MobileDialog>
    );
};
