import { useFormikContext } from 'formik';
import React from 'react';

import ReactCodeInput from '../../components/code-input/index';

import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

import { createStyle } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { LinkBtn } from 'components/link-btn';

const styles = {
    codeInput: createStyle({
        '> input:first-child': {
            marginLeft: 0,
        },
        '> input': {
            background: '#d7d7d7',
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: 'bold',
            color: colors.brand,
            width: '40px',
            height: '40px',
            marginLeft: '10px',
            borderRadius: '2px',
        },
        '> input[data-hasvalue="true"]': {
            background: '#f7f7f7',
        },
    }),
};

export type CodeFieldProps = {
    onTryRequestingNewOne: () => void;
    hideRequestNewCode?: boolean;
    className?: string;
};

export const CodeField = ({
    label,
    id,
    error,
    onTryRequestingNewOne,
    hideRequestNewCode = false,
    className = styles.codeInput,
    ...props
}: FieldWrapperProps & CodeFieldProps) => {
    const ctx = useFormikContext();

    return (
        <FieldWrapper label={label} id={id} error={error} {...props}>
            {(_props) => {
                return (
                    <div className="inline-block">
                        <ReactCodeInput
                            type="number"
                            fields={6}
                            name={id}
                            inputMode="numeric"
                            autoComplete="no"
                            className={className}
                            onChange={(value: string) => {
                                if (_props.id) {
                                    ctx.setFieldValue(_props.id, value);
                                }
                            }}
                        />
                        {!hideRequestNewCode && (
                            <div className="flex">
                                <div className="ml-auto text-xs mt-1">
                                    <LinkBtn onClick={onTryRequestingNewOne}>
                                        Try requesting a new one.
                                    </LinkBtn>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
        </FieldWrapper>
    );
};
