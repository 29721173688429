import React, { ReactElement, useMemo } from 'react';

import { FieldStyles } from './FieldStyles';

import { numericId } from 'common/utils/id';
import { InvalidFeedback } from 'components/invalid-feedback/InvalidFeedback';

export interface FieldWrapperProps {
    label: React.ReactChild;
    error?: string | ReactElement;
    id?: string;
    wrapperStyles?: string;
}

interface FieldWrapperPropsWithChildren extends FieldWrapperProps {
    children: (formControlProps: React.HTMLAttributes<HTMLElement>) => React.ReactNode;
    errorClassName?: string;
}

export const FieldWrapper = ({
    wrapperStyles,
    label,
    children,
    error,
    errorClassName,
    ...props
}: FieldWrapperPropsWithChildren) => {
    const id = useMemo(() => props.id || `field_${numericId()}`, [props.id]);
    const errorId = `${id}_error`;

    return (
        <div className={wrapperStyles || ''} {...props}>
            <label htmlFor={id} className={FieldStyles.label}>
                {label}
            </label>
            <div className={FieldStyles.inputWrapper}>
                {children({
                    id,
                    'aria-describedby': error ? errorId : undefined,
                    'aria-invalid': Boolean(error),
                })}
            </div>
            {error && (
                <InvalidFeedback errorClassName={errorClassName} id={errorId}>
                    {error}
                </InvalidFeedback>
            )}
        </div>
    );
};
