import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getApp } from '@firebase/app';
import { useDispatch, useSelector } from 'react-redux';

import { ContentFullEnrollmentProvider } from './components/useContentfulEnrollment';
import OrganizationEnrollmentScreen from './enrollment/OrganizationEnrollmentScreen';
import LitePlusOrgEnrollScreen from './enroll/LitePlusOrgEnrollScreen';
import { ENROLLMENT_VERSION_1, ENROLLMENT_VERSION_2 } from './constants';
import {
    getOrganizationEnrollment,
    getEnrollmentEntryById,
} from 'common/api/contentful/enrollment';

import { RemoteConfigWeb } from 'config/RemoteConfigWeb';
import { Routes } from 'routes';
import {
    getOrganizationEnrollmentLog,
    updateEnrollmentProgress,
} from 'common/api/crm/organization/enrollment';
import { OrganizationEnrollmentLogEntry } from 'common/api/crm/models/OrganizationEnrollmentLogEntry';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { RootState } from 'rootStore';
import { delay } from 'common/utils';
import { DeferedEnrollmentLog } from './types';
import { addOrgEnrollmentReferralInfo } from 'common/api/crm/family/service';
import { getCookieAsObject } from 'utils/cookies';
import { UserAuthState } from 'common/features/store/duck/account/duck';
import { getV2ContinueRoute } from './enrollment-flow/v2/utils/GetV2ContinueRoute';
import { selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { getOrgAchAccountThunk } from 'common/features/store/duck/organization/duck';

/*
 * Enrollment Component Purpose
 *
 * To encompass the logic of the different versions of the enrollment flow.
 * Specifically the logic of redirecting the user to the correct enrollment flow
 * depending on firebase or user's enrollment log history.
 */

const enrollmentInProgress = (entry?: OrganizationEnrollmentLogEntry) => {
    return !(
        entry?.state === ProgressState.CheckImageUploaded ||
        entry?.state === ProgressState.PaperOnlySelected ||
        entry?.state === ProgressState.AccountLinkedWithPlaid
    );
};

export const Enrollments = () => {
    const history = useHistory();

    // NOTE: We default to enrollment V1 as light-plus enrollment was dropped.
    const [version, setVersion] = useState<number | null>(ENROLLMENT_VERSION_1);
    const [enrollmentLogs, setEnrollmentLogs] = useState<OrganizationEnrollmentLogEntry[] | null>(
        null
    );
    const userId = useSelector((state: RootState) => state.store.account.userId);
    const userAuthState = useSelector((state: RootState) => state.store.account.userAuthState);
    const isUserLoggedIn = UserAuthState.AUTHENTICATED === userAuthState;
    const userFailedToLogin = UserAuthState.NOTAUTHENTICATED === userAuthState;
    const [shouldRenderEnrollment, setShouldRenderEnrollment] = useState<boolean>(false);
    const orgId = useSelector(selectSelectedOrgId);
    const dispatch = useDispatch<any>();
    const { search } = useLocation();

    const startProgramRoutes = useMemo(
        () => Object.values(Routes).filter((r) => r.startsWith(Routes.StartProgram)),
        []
    );
    const enrollRoutes = useMemo(
        () =>
            Object.values(Routes).filter((r) => r.startsWith(Routes.Enroll) && r !== Routes.Enroll),
        []
    );

    /* [A].
     * Fetches the user's enrollment log IFF they are logged in.
     *
     * Enrollment log is used in this effect to redirect to the correct
       path(`/start-program/continue` or `/enroll/start-program/continue`) based on the user's
       enrollment log version.
    
       The reason for this case is that a user could have started an enrollment with version 1
       of the enrollment flow and returned at a later point to the enroll page but found themselves
       in the version 2 of the enrollment flow.  This could happen for a variety of reasons, one being:
    
       - User leaves in the middle of enrollment and comes back a (day | week | month) later
    
       As a result, when the user comes back and presses `continue enrollment` it should redirect them
       to the correct version of the enrollment flow.
    
       Another use case is for the plaid oauth flow, the current plaid config has the redirect_uri
       pointing to `/start-program/continue`. This works well for version 1 of  the enrollment flow but
       would not work version 2 which has different routes.
    
       Note: We're not using 2 version of enrollment flow as of jan/2022. It might be used at a later 
       point.
     */
    // NOTE: For now implementation seems good -- we just gotta test more when connection is slow
    //
    useEffect(() => {
        const loadUserEnrollmentLog = async () => {
            const getEnrollLog = async () => {
                const enrollLogResponse = await getOrganizationEnrollmentLog();
                // if an error happens then default the version of the form
                // based on what firebase config has
                if (enrollLogResponse.error) {
                    setEnrollmentLogs([]);
                    return false;
                }
                const sortedLogs = enrollLogResponse.data.sort(
                    (a: OrganizationEnrollmentLogEntry, b: OrganizationEnrollmentLogEntry) => {
                        const aDate = Date.parse(a.updateDate);
                        const bDate = Date.parse(b.updateDate);
                        return bDate - aDate;
                    }
                );

                if (sortedLogs[0]?.version === 2) {
                    const orgAchAccount =
                        (await getOrgAchAccountThunk(orgId || '')(dispatch)).data || null;
                    const v2Route = getV2ContinueRoute(sortedLogs[0], orgAchAccount);
                    history.replace(v2Route + search);
                    return false;
                }

                setEnrollmentLogs(sortedLogs);
                return true;
            };

            if (isUserLoggedIn) {
                const enrollQueue = JSON.parse(
                    localStorage.getItem('enrollmentLogQueue') || '[]'
                ) as DeferedEnrollmentLog[];

                const newQueue = [];

                const updateEnrollment = async (id: string, state: ProgressState) => {
                    await withRetries(updateEnrollmentProgress)({ state: state });
                    await withRetries(addOrgEnrollmentReferralInfo)(id, {
                        trackingCookie: getCookieAsObject(document.cookie),
                    });
                };

                for (const updateData of enrollQueue) {
                    const { id, state } = updateData;
                    if (userId === id) {
                        await updateEnrollment(id, state);
                    } else {
                        // if userId doesn't match the id then add the data back to the queue
                        newQueue.push({ id, state });
                    }
                }

                // we should now clear the queue -- the data in the queue should be short lived.
                localStorage.setItem('enrollmentLogQueue', JSON.stringify(newQueue));

                delay(1000).then(async () => {
                    const shouldRender = await getEnrollLog();
                    // we should now render the enrollment in order to let the
                    // user continue w/ their flow.
                    setShouldRenderEnrollment(shouldRender);
                });
            }

            if (userFailedToLogin) {
                // if user is not authenticated then we want to render enrollment still
                setShouldRenderEnrollment(true);
            }
        };
        loadUserEnrollmentLog();
    }, [isUserLoggedIn, userFailedToLogin, history, userId, search, orgId, dispatch]);

    /* [C].
     * Compute the correct version of the enrollment flow that the user should see
     *
     * Fetch from firebase the `lite_plus_enrollment_enabled` to set the user on
       the path of v1 or v2 enrollment.
     * Ignores `lite_plus_enrollment_enabled` from firebase IFF user has an in-progress
       enrollment and instead utilizes the user's last entry of their enrollment log to
       determine which version of the enrollment flow the user should return to.
     */
    useEffect(() => {
        const determineEnrollmentVersion = async () => {
            if (getApp()) {
                await RemoteConfigWeb.initialize();
                const isLitePlusEnabled = RemoteConfigWeb.getRemoteValue(
                    'lite_plus_enrollment_enabled'
                ).asBoolean();

                if (isUserLoggedIn && Boolean(enrollmentLogs?.length)) {
                    // if logs exists then check if user has an enrollment in-progress
                    const lastEntry = enrollmentLogs
                        ? enrollmentLogs[enrollmentLogs.length - 1]
                        : undefined;
                    if (lastEntry?.version && enrollmentInProgress(lastEntry)) {
                        setVersion(lastEntry?.version);
                        return;
                    } else {
                        // enrollment not in progress, default to what firebase says
                        setVersion(isLitePlusEnabled ? ENROLLMENT_VERSION_2 : ENROLLMENT_VERSION_1);
                        return;
                    }
                }

                if (!isUserLoggedIn) {
                    // if user is not logged in then we fallback to using firebase
                    setVersion(isLitePlusEnabled ? ENROLLMENT_VERSION_2 : ENROLLMENT_VERSION_1);
                }
            }
        };
        determineEnrollmentVersion();
    }, [isUserLoggedIn, enrollmentLogs]);

    return (
        <Switch>
            <Route path={startProgramRoutes} exact>
                <ContentFullEnrollmentProvider
                    enrollmentVersion="v1"
                    firebaseKey={'org_enrollment_contentful_heading_map'}
                    fetchContentfulContent={getEnrollmentEntryById}
                >
                    <OrganizationEnrollmentScreen shouldRender={shouldRenderEnrollment} />
                </ContentFullEnrollmentProvider>
            </Route>
            <Route path={enrollRoutes} exact>
                {version === ENROLLMENT_VERSION_2 && (
                    <ContentFullEnrollmentProvider
                        enrollmentVersion="v2"
                        firebaseKey={'org_enrollment_contentful_heading_map'}
                        fetchContentfulContent={getOrganizationEnrollment}
                    >
                        <LitePlusOrgEnrollScreen />
                    </ContentFullEnrollmentProvider>
                )}
            </Route>
        </Switch>
    );
};
