import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import debounce from 'lodash.debounce';

import { highlightMatchesHtml } from '../../utils';

import { applyTerm, updateSearch } from 'common/features/store/duck/search/duck';
import { FeaturesState } from 'common/features/featuresReducer';
import Select from 'react-select/dist/declarations/src/Select';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Routes } from 'routes';
import {
    GroupBase,
    InputActionMeta,
    OptionProps,
    StylesConfig,
} from 'react-select/dist/declarations/src';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { CustomMenuList } from './components/SearchBarMenuList';
import { suggestBrands } from 'common/api/search/suggest/getSuggestions';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { hasOnlySuggestions, useFormatGroupLabel } from './useFormatGroupLabel';
import { editFilter } from 'common/utils/shipToCoordinatorUtils';
import { selectIsShipToCoordinatorEnabled } from 'common/features/store/duck/organization/duck';
import { SearchBarProps } from './SearchBar';
import RemoteConfigWeb from 'config/RemoteConfigWeb';

const btnSearchClassName = classNames(
    'relative h-full w-20 outline-none center border-solid border-brand',
    'text-white bg-brand text-center hover:bg-brand-dark focus:bg-brand-dark hover:text-white focus:text-white'
);

const CustomOption = ({
    innerRef,
    innerProps,
    data,
    isFocused,
    label,
    ...props
}: OptionProps<Brand, false, GroupBase<Brand>>) => {
    // highligh matches
    const filter = props.selectProps.inputValue?.toLowerCase() as string;
    const highlighted = highlightMatchesHtml(label, filter);

    return (
        <div
            data-cnstrc-item-section="Products"
            data-cnstrc-item-name={label}
            {...innerProps}
            ref={innerRef}
            key={data.id || 0}
            className={`${
                isFocused
                    ? 'border-l-4 border-solid border-brand '
                    : 'border-l-4 border-solid border-white '
            }px-4 py-2 no-underline capitalize text-left text-brand hover:underline focus:underline grid`}
            style={{ gridTemplateColumns: 'max-content auto' }}
            title={label}
        >
            {data.imageUrl ? (
                <img src={data.imageUrl} className="inline-block w-16" />
            ) : (
                renderEmpty(data)
            )}
            <a
                href="#"
                className={'no-underline align-top ml-2 hover:underline leading-10 truncate w-full'}
                onClick={(e) => e.preventDefault()}
            >
                {highlighted}
            </a>
        </div>
    );
};

const renderEmpty = (data: Brand) => {
    if (Number(data.id) <= 0) {
        return <div />;
    }

    return <div className="inline-block w-16 bg-grey-3" />;
};

// Fix for opacity zero issue
// https://github.com/JedWatson/react-select/issues/3068#issuecomment-737531808
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = (props: any) => (
    <components.Input
        data-cnstrc-search-input
        data-testid="searchbar-input"
        {...props}
        isHidden={false}
    />
);

export const AzureSearchBar = ({
    onSearch,
    maxAutocompleteItems = 10,
    onItemSelected,
    className,
}: SearchBarProps) => {
    const dispatch = useDispatch<any>();
    const globalFilters = useGlobalBrandFilters();
    const isShipToCoordinatorEnabled = useSelector(selectIsShipToCoordinatorEnabled);
    const inputPlaceholder =
        RemoteConfigWeb.getRemoteValue('search_placeholder_text').asString() || 'Search for brand';

    const search = useSelector((state) => (state as FeaturesState).store.search);
    const { term } = search;

    const [checked, setChecked] = useState(false);
    const isBrandsRoute = useRouteMatch({
        path: Routes.Brands,
        sensitive: false,
        exact: true,
    });

    const formatGroupLabel = useFormatGroupLabel(term);

    const style: StylesConfig<Brand, boolean, GroupBase<Brand>> = {
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            minWidth: '65%',
            maxWidth: '65%',
            '@media (min-width: 1200px)': {
                minWidth: '80%',
                maxWidth: '80%',
            },
            '@media (max-width: 768px)': {
                minWidth: '72%',
                maxWidth: '72%',
            },
            '@media (max-width: 500px)': {
                minWidth: '85%',
                maxWidth: '85%',
            },
        }),
        valueContainer: (base) => ({
            ...base,
            paddingLeft: '0px',
            paddingRight: '0px',
            maxWidth: '92%',
            position: 'absolute',
            '@media (min-width: 400px and max-width: 768px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        }),
        indicatorsContainer: (base) => ({
            ...base,
            marginLeft: 'auto',
            marginRight: -10,
        }),
        noOptionsMessage: (base) => ({
            ...base,
            overflowWrap: 'anywhere',
        }),
        loadingMessage: (base) => ({
            ...base,
            overflowWrap: 'anywhere',
        }),
        menuPortal: (base) => ({
            ...base,
            width: '37.3%',
            zIndex: 2,
            '@media (max-width: 500px)': {
                width: '84%',
            },
        }),
        placeholder: (base) => ({
            ...base,
            width: '120%',
            '@media (max-width: 1000px)': {
                fontSize: '14px',
            },
        }),
    };

    const ref = useRef<Select<Brand, false, GroupBase<Brand>>>(null);

    const history = useHistory();

    const onClearClicked = useCallback(
        async (focus = true) => {
            ref?.current?.popValue();

            await setFocusedOption(undefined);
            await dispatch(updateSearch({ term: '', appliedTerm: '' }));

            if (isBrandsRoute) {
                onSearch('');
            }
            ref?.current?.blur();
            ref?.current?.focus();

            setTimeout(() => {
                ref?.current?.blur();
                ref?.current?.focus();
                focus && ref?.current?.openMenu('first');
                !focus && ref?.current?.blur();
            }, 500);
        },
        [dispatch, isBrandsRoute, onSearch]
    );

    useEffect(() => {
        //check the search bar when we enter the page only one time
        if (!checked && term) {
            if (isBrandsRoute) {
                history.action === 'PUSH' && onSearch(term);
            } else {
                onClearClicked(false);
            }
        }
        setChecked(true);
    }, [checked, history.action, isBrandsRoute, onClearClicked, onSearch, term]);

    const CustomClear = () =>
        term || focusedOption ? (
            <button
                className="flex items-center text-brand hover:text-brand-dark pl-2 mr-2"
                onClick={() => onClearClicked()}
                style={{ outline: 'none' }}
            >
                <FontAwesomeIcon icon={faTimesCircle} className="inline-block text-xl  " />
            </button>
        ) : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce((searchString: string, callback: Function) => {
            // using the store object to guarantee that we have the latest state.
            const promise = suggestBrands({
                search: `${searchString}`,
                top: maxAutocompleteItems,
                searchFields: 'Name,KeyWords,Suggestions',
                filter: editFilter(isShipToCoordinatorEnabled, globalFilters),
                select: '*',
            });

            // waits the request complete, and reducers to run to get the filteredResults from the store.
            promise.then((result) => {
                const value = result.data || [];
                const onlySuggestions = hasOnlySuggestions(term, value as unknown as Brand[]);
                const searchForTermLink = {
                    id: '',
                    name: `Search for '${searchString.toLowerCase()}'`,
                };

                if (value.length <= 0) {
                    return callback([]);
                }

                const results = onlySuggestions
                    ? [
                          {
                              label: 'search-for-term-link',
                              options: [searchForTermLink],
                          },
                          {
                              label: 'suggestions',
                              options: value,
                          },
                      ]
                    : [
                          {
                              label: '',
                              options: [searchForTermLink, ...value],
                          },
                      ];

                return callback(results);
            });
        }, 500),
        [isShipToCoordinatorEnabled]
    );

    const [focusedOption, setFocusedOption] = useState<Brand | undefined>();

    const onInputChange = (input: string, reason: InputActionMeta) => {
        // do not clear the filter when lose focus
        if (
            reason.action === 'set-value' ||
            reason.action === 'input-blur' ||
            reason.action === 'menu-close'
        ) {
            return term;
        }

        dispatch(updateSearch({ term: input }));

        if (input === '') {
            dispatch(applyTerm(''));
        }

        return input;
    };

    return (
        <div className={`${className} text-center `}>
            <div className="relative inline-block max-w-md sm:max-w-xl w-full rounded-full overflow-hidden border-solid border-brand border shadow-md">
                <AsyncSelect
                    data-testid="Search-Bar"
                    ref={ref}
                    getOptionValue={(item) => item.id}
                    getOptionLabel={(item) => item.name}
                    styles={style}
                    className="px-4 py-1 pr-2 w-full max-w-xs sm:max-w-lg text-left"
                    inputValue={term}
                    onFocus={(e) => {
                        // to keep the name and have the cursor at the end when editing
                        ref.current?.handleInputChange(e);
                        if (focusedOption?.id) {
                            dispatch(updateSearch({ term: focusedOption.name }));
                            dispatch(applyTerm(focusedOption.name));
                        }
                    }}
                    isClearable={false}
                    onKeyDown={(evt) => {
                        // allows the user to navigate filtering Input using Home/End
                        // instead of navigating the list of the autocomplete results.
                        const input = evt.target as HTMLInputElement;
                        switch (evt.key) {
                            case 'Home': {
                                if (evt.shiftKey) {
                                    input.selectionStart = 0;
                                } else {
                                    input.setSelectionRange(0, 0);
                                }
                                evt.preventDefault();
                                break;
                            }
                            case 'End': {
                                const len = input.value.length;
                                if (evt.shiftKey) {
                                    input.selectionEnd = len;
                                } else {
                                    input.setSelectionRange(len, len);
                                }
                                evt.preventDefault();
                                break;
                            }
                            case 'Enter': {
                                if (!focusedOption?.id) {
                                    if (term !== '') {
                                        dispatch(updateSearch({ term: term }));
                                        dispatch(applyTerm(term));
                                        onSearch(term);
                                    }
                                } else {
                                    onItemSelected(focusedOption);
                                }
                                evt.preventDefault();
                                ref?.current?.onMenuClose();
                                break;
                            }
                            case 'Backspace': {
                                const { value, selectionStart, selectionEnd } = input;
                                if (
                                    value.length === 1 ||
                                    (selectionStart === 0 && selectionEnd === value.length)
                                ) {
                                    ref?.current?.clearValue();
                                }
                                break;
                            }
                        }
                    }}
                    placeholder={inputPlaceholder}
                    noOptionsMessage={(_) =>
                        !_.inputValue
                            ? 'Type something to search for Brands.'
                            : `No brands were found with filter: ${_.inputValue}`
                    }
                    loadingMessage={(_) => `Searching brands: ${_.inputValue}...`}
                    loadOptions={loadOptions}
                    components={{
                        MenuList: CustomMenuList({ setFocusedOption }),
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Option: CustomOption,
                        Input,
                    }}
                    onInputChange={(newValue, actionMeta) =>
                        newValue.length <= 75 ? onInputChange(newValue, actionMeta) : newValue
                    }
                    // triggers the onSearch when the user clicks the Search button.
                    onChange={(item) => {
                        const itemSelected = item as Brand;
                        // send the user to the PDP
                        if (itemSelected?.id) {
                            onItemSelected(itemSelected);
                            setTimeout(() => {
                                ref.current?.blur();
                                ref.current?.focus();
                                ref.current?.blur();
                            }, 500);
                        }
                        // send the user to the search page
                        // if the Search for 'term' was clicked.
                        else if (itemSelected?.id === '') {
                            onSearch(term);
                        }
                    }}
                    // using fixed to avoid issues with overflow: hidden|auto etc..
                    menuPosition="fixed"
                    formatGroupLabel={formatGroupLabel}
                />
                <div className="absolute inset-y-0 right-0 center outline-none flex">
                    <CustomClear />
                    <button
                        data-cnstrc-search-submit-btn
                        className={btnSearchClassName}
                        onClick={() => term && onSearch(term)}
                        style={{ outline: 'none' }}
                    >
                        <FontAwesomeIcon icon={faSearch} className="inline-block text-2xl" />
                    </button>
                </div>
            </div>
        </div>
    );
};
