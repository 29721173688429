import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Formik, useFormikContext, FormikHelpers } from 'formik';
import MaskedInput from 'react-text-mask';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { AddPhoneRequest, DeliveryMethod } from 'common/api/users/twofactor/phone';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import {
    twoFactorLockoutError,
    twoFactorPhoneNumberValidator,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { Btn } from 'components/btn/Btn';
import { Radio } from 'components/radio/Radio';
import { useValidation } from 'modules/form-validation/useValidation';
import { FormikNumberField, FormikTextField } from 'components/fields/FormikTextField';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { createStyles } from 'utils/createStyle';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { ContentfulSubheading } from '../../components/ContentfulSubheading';
import {
    addPhoneThunk,
    selectProgressBarRef,
    setAccountComplete,
    setCurrentStep,
    setMaxStep,
    skipTwoFactorThunk,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'modules/alerts';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useHistory } from 'react-router';
import { Routes } from 'routes';
import { scrollTo } from '../../utils/ScrollTo';
import { selectHasSetUpTwoFactor } from 'common/features/store/duck/account/duck';

const styles = createStyles({
    container: `text-brand md:mb-2 border-b-2 border-pale-blue md:border-none rounded-none md:rounded-lg 
    bg-white px-4 md:px-10 pb-8 py-4 px-4 md:px-10 block w-full text-left focus:outline-none max-w-xl`,
    title: 'flex text-lg md:text-3xl font-bold text-brand-dark',
    phoneExtension: ['mt-5 sm:mt-0', { minWidth: '100px' }],
});

type FormValues = AddPhoneRequest;

const AddPhoneRequestField = typedFormikField<FormValues>()(FormikTextField);

const sendCodeOptions = [
    { value: DeliveryMethod.Text, text: 'Text Message' },
    { value: DeliveryMethod.Voice, text: 'Phone Call' },
];

export interface SendCodeInnerStepProps {}

export const SendCodeInnerStep = () => {
    const validateAndSubmit = useValidation<FormValues>();
    const alert = useAlert();
    const progressBarRef = useSelector(selectProgressBarRef);
    const hasSetUpTwoFactor = useSelector(selectHasSetUpTwoFactor);

    useScrollToTop();

    const history = useHistory();
    const dispatch = useDispatch<any>();

    // EDGE CASE GUARD
    // it's possible that the user logs out after completing account creation, but before 2FA
    // When they log back in, 2FA will be handled by identity server.
    // In that case we want to skip this 2FA screen and advance them to the next step.
    useEffect(() => {
        if (hasSetUpTwoFactor) {
            dispatch(skipTwoFactorThunk());
            history.replace(Routes.EnrollV2ChooseProgram);
        }
    }, [hasSetUpTwoFactor, dispatch, history]);

    useEffect(() => {
        dispatch(setMaxStep(3));
        dispatch(setCurrentStep(3));
    }, [history, dispatch]);

    useEffect(() => {
        dispatch(setAccountComplete());
    }, [dispatch]);

    useEffect(() => {
        scrollTo(progressBarRef);
    }, [progressBarRef]);

    const onSubmit = useCallback(
        async (values: FormValues, formikProps: FormikHelpers<FormValues>) => {
            const response = await addPhoneThunk(values)(dispatch);

            if (response.error) {
                formikProps.setSubmitting(false);
                alert({
                    message: hasErrorWithStatus(response, StatusCode.TooManyRequests)
                        ? twoFactorLockoutError
                        : `Failed to send verification code to ${values.phoneNumber}. Please try again.`,
                });
                return;
            }

            history.replace(Routes.EnrollV2SecureAccountVerify);
        },
        [alert, dispatch, history]
    );

    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const title = contentfulEntry
        ? (contentfulEntry as OrganizationEnrollmentV2).twoFactorSetupTitle
        : undefined;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <ContentfulSubheading attribute="twoFactorSetupSubtitle" />
            <Formik
                initialValues={{ phoneNumber: '', deliveryMethod: DeliveryMethod.Text }}
                onSubmit={onSubmit}
                validateOnMount={true}
            >
                {(formikProps) => (
                    <form noValidate className="" onSubmit={validateAndSubmit(formikProps)}>
                        <div className="text-sm font-weight text-grey-1 font-semibold mb-1">
                            Verify using:
                        </div>
                        <FieldDeliveryMethod />
                        <FieldSpacer />
                        <div className="flex flex-col sm:flex-row gap-2">
                            <Field2faPhoneNumber />
                            {formikProps.values.deliveryMethod === DeliveryMethod.Voice && (
                                <Field2faPhoneExtension />
                            )}
                        </div>
                        <FieldSpacer />
                        <div className="flex justify-center sm:block">
                            <Btn
                                type="submit"
                                className="whitespace-nowrap"
                                disabled={!formikProps.isValid || formikProps.isSubmitting}
                            >
                                {formikProps.values.deliveryMethod === DeliveryMethod.Text
                                    ? 'Send Text Message'
                                    : 'Call Me'}
                            </Btn>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

const Field2faPhoneNumber = () => {
    const { validateForm, submitForm, values } = useFormikContext<AddPhoneRequest>();

    const helpMessage = useMemo(() => {
        const isSms = values?.deliveryMethod === DeliveryMethod.Text;

        return `Enter the number for a phone that you have easy access to \
        — we'll provide a verification code within a couple of minutes. \
        ${isSms ? 'Carrier message and data rates may apply.' : ''}`;
    }, [values?.deliveryMethod]);

    return (
        <div>
            <MaskedInput
                mask={MASK_PHONE_US}
                guide={false}
                render={(ref, _props) => (
                    <>
                        <AddPhoneRequestField
                            {..._props}
                            inputRef={ref}
                            type="tel"
                            name="phoneNumber"
                            label="Phone Number"
                            data-testid="phoneNumber"
                            validate={twoFactorPhoneNumberValidator}
                            onKeyUp={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') {
                                    if (await validateForm()) {
                                        submitForm();
                                    }
                                }
                            }}
                            placeholder="Ex: (555) 555-5555"
                        />
                    </>
                )}
            />
            <div className="mt-1 text-sm text-grey-1">{helpMessage}</div>
        </div>
    );
};

const Field2faPhoneExtension = () => {
    return (
        <div className={styles.phoneExtension}>
            <FormikNumberField
                label="Phone Extension"
                name="phoneExtension"
                placeholder="Ex: 1337"
                data-testid="phoneExtension"
                maxLength={8}
                min={0}
                disableIncrement={true}
                acceptOnlyIntegerNumbers={true}
            />
        </div>
    );
};

const FieldDeliveryMethod = () => {
    const { values, setFieldValue } = useFormikContext<AddPhoneRequest>();

    return (
        <Radio
            options={sendCodeOptions}
            value={values.deliveryMethod}
            onChange={(value) => setFieldValue('deliveryMethod', value.value)}
            extraStyles="text-sm"
        />
    );
};
