import {
    // faHouse,
    faFolders,
    // faFileChartColumn, -- not in lib?
    faFileChartLine, // temporary substitute for faFileChartColumn
    faUser,
    faSparkles,
    faCreditCard,
    faHandHoldingHeart,
    faHeadset,
    faHomeLgAlt as faHouse,
} from '@fortawesome/pro-regular-svg-icons';
import { REACT_APP_LAUNCH_SCRIPWARE_URL } from 'common/env';

import { Routes } from 'routes';

export interface NavItem {
    title: string;
    route?: string;
    subItems?: NavItem[];
    collapsible?: boolean;
    icon?: typeof faHouse;
    startOpen?: boolean;
    openInNewTab?: boolean;
    external?: boolean;
}

export const navItems: NavItem[] = [
    {
        title: 'Home',
        route: Routes.CoordinatorDashboardHome,
        icon: faHouse,
    },
    {
        title: 'Orders',
        icon: faFolders,
        collapsible: true,
        startOpen: true,
        subItems: [
            {
                title: 'Enter Orders',
                route: Routes.CoordinatorDashboardEnterOrders,
            },
            {
                title: 'Order Queue',
                route: Routes.CoordinatorDashboardOrderQueue,
            },
            {
                title: 'PO Manager',
                route: Routes.CoordinatorDashboardPOManager,
            },
            {
                title: 'Participant Order Form',
                route: Routes.CoordinatorDashboardParticipantOrderForm,
            },
        ],
    },
    {
        title: 'Reports',
        collapsible: true,
        icon: faFileChartLine,
        subItems: [
            {
                title: 'Packing Slips',
                route: Routes.CoordinatorDashboardPackingSlips,
            },
            {
                title: 'All Reports',
                route: Routes.CoordinatorDashboardAllReports,
            },
            {
                title: 'Order History',
                route: Routes.CoordinatorDashboardOrderHistory, // Replace with the correct route for Order History
            },
        ],
    },
    {
        title: 'Participants',
        collapsible: true,
        icon: faUser,
        subItems: [
            {
                title: 'Accounts',
                route: Routes.CoordinatorDashboardParticipantAccounts,
            },
            {
                title: 'Invite Participants',
                route: Routes.CoordinatorDashboardInviteParticipants,
            },
        ],
    },
    {
        title: 'Organization',
        collapsible: true,
        icon: faSparkles,
        subItems: [
            {
                title: 'Profile',
                route: Routes.CoordinatorDashboardOrganizationProfile,
            },
            {
                title: 'Settings',
                route: Routes.CoordinatorDashboardOrganizationSettings,
            },
            {
                title: 'Coordinator Roles',
                route: Routes.CoordinatorDashboardCoordinatorRoles,
            },
            {
                title: 'PIN Reset',
                route: Routes.CoordinatorDashboardOrganizationPINReset,
            },
            {
                title: 'Local Products',
                route: Routes.CoordinatorDashboardLocalProducts,
            },
            {
                title: 'Local Suppliers',
                route: Routes.CoordinatorDashboardLocalSuppliers,
            },
            {
                title: 'Notifications',
                route: Routes.CoordinatorDashboardNotifications,
            },
            {
                title: 'SCRIPWARE',
                route: 'https://www.raiseright.com/resources/organization/scripware-inventory-management',
                openInNewTab: true,
                external: true,
            },
        ],
    },
    {
        title: 'Payments',
        collapsible: true,
        icon: faCreditCard,
        subItems: [
            {
                title: 'Payment Change Form',
                route: Routes.CoordinatorDashboardPaymentChangeForm,
            },
            {
                title: 'Payment Types',
                route: Routes.CoordinatorDashboardPaymentTypes,
            },
        ],
    },
];

export const scripwareNavItems: NavItem[] = [
    {
        title: 'Home',
        route: Routes.CoordinatorDashboardHome,
        icon: faHouse,
    },
    {
        title: 'Orders',
        icon: faFolders,
        collapsible: true,
        subItems: [
            {
                title: 'Participant Order Form',
                route: Routes.CoordinatorDashboardParticipantOrderForm,
            },
        ],
    },
    {
        title: 'Reports',
        collapsible: true,
        icon: faFileChartLine, // Change this to faFileChartColumn when available
        subItems: [
            {
                title: 'Order History',
                route: Routes.CoordinatorDashboardOrderHistory, // Replace with the correct route for Order History
            },
        ],
    },
    {
        title: 'Participants',
        collapsible: true,
        icon: faUser,
        subItems: [
            {
                title: 'Invite Participants',
                route: Routes.CoordinatorDashboardInviteParticipants,
            },
        ],
    },
    {
        title: 'Organization',
        collapsible: true,
        icon: faSparkles,
        subItems: [
            {
                title: 'Profile',
                route: Routes.CoordinatorDashboardOrganizationProfile,
            },
            {
                title: 'Settings',
                route: Routes.CoordinatorDashboardOrganizationSettings,
            },
            {
                title: 'Coordinator Roles',
                route: Routes.CoordinatorDashboardCoordinatorRoles,
            },
            {
                title: 'PIN Reset',
                route: Routes.CoordinatorDashboardOrganizationPINReset,
            },
            {
                title: 'SCRIPWARE',
                // env/c/account/scripware
                route: REACT_APP_LAUNCH_SCRIPWARE_URL,
                external: true,
            },
        ],
    },
    {
        title: 'Payments',
        collapsible: true,
        icon: faCreditCard,
        subItems: [
            {
                title: 'Payment Change Form',
                route: Routes.CoordinatorDashboardPaymentChangeForm,
            },
            {
                title: 'Payment Types',
                route: Routes.CoordinatorDashboardPaymentTypes,
            },
        ],
    },
];

export type SectionConfig = {
    title: string;
    items: NavItem[];
};

export const resourcesSection = {
    title: 'Resources',
    items: [
        {
            title: 'Resource Center',
            route: Routes.SupportOrganization,
            icon: faHandHoldingHeart,
            external: true,
        },
        {
            title: 'Customer Support',
            route: Routes.Contact,
            icon: faHeadset,
            external: true,
        },
    ],
};

export function getSections(isScripware: boolean): SectionConfig[] {
    return [
        { title: 'Main Menu', items: isScripware ? scripwareNavItems : navItems },
        resourcesSection,
    ];
}
