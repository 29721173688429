import { CanonicalSuggestionBrand } from '../models/CanonicalSuggestionBrand';
import { SuggestionBrand } from '../models/SuggestionBrand';
import { SuggestResponse } from '../models/SuggestResponse';
import { suggestApiFetch, SuggestApiParams } from '../';

import { propertyNormalizer } from 'common/utils/propertyNormalizer';
import { mapApiResponse } from '../mapApiResponse';

const toSuggestionBrand = mapApiResponse<
    SuggestResponse<CanonicalSuggestionBrand>,
    SuggestionBrand[]
>((response) => {
    const { data } = response;
    return { data: propertyNormalizer(data.value) };
});

export const suggestBrands = async (params: Omit<SuggestApiParams, 'suggesterName'>) =>
    toSuggestionBrand(
        await suggestApiFetch({
            suggesterName: 'BrandSuggester',
            select: 'Id,Name,IsLocalProduct,ProductIds',
            ...params,
        })
    );
