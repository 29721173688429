import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { UserContent } from 'components/user-content/UserContent';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import React, { useCallback, useContext, useState } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { ReactComponent as CardIcon } from '../../../../../media/images/card-icon.svg';
import { ReactComponent as BankIcon } from '../../../../../media/images/bank-icon.svg';
import { chooseProgramStyles as styles } from '../styles/ChooseProgramStyles';
import { Btn } from 'components/btn/Btn';
import { useDispatch, useSelector } from 'react-redux';
import {
    completePlaidSetup,
    OnlineType,
    setProgramType,
    updateCompletePlaidStatusRequest,
    updateEnrollmentLog,
    updateBillingSelection,
    plaidSetupFailed,
    selectProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { PlaidWrapper } from 'features/coordinator/enrollment/steps/PlaidButton/PlaidButton';
import useQueryParams from 'hooks/useQueryParams';
import { useRootSelector } from 'common/features/featuresReducer';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useHistory } from 'react-router';
import { Routes } from 'routes';
import { PlaidLinkError, PlaidLinkOnExitMetadata } from 'react-plaid-link';
import { AchVerificationStatus } from 'common/api/e-comm/ach/models/AchAccount';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { selectUserId } from 'common/features/store/duck/account/duck';
import { AchAccountBody } from 'common/api/e-comm/ach';
import { useDidUpdateEffect } from 'utils';
import { APIError } from 'common/api/models';
import { BillingSelection } from 'common/api/e-comm/payment-types';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { scrollTo } from '../utils/ScrollTo';

export const OnlineProgramBody = () => {
    useScrollToTop();

    const appInsights = useAppInsightsContext();

    const { contentfulEntry } = useContext(ContentFullEnrollmentContext) as unknown as {
        contentfulEntry: OrganizationEnrollmentV2;
    };
    const [onlineType, setOnlineType] = useState<OnlineType | null>(null);
    const dispatch = useDispatch<any>();
    const orgId = useSelector(selectSelectedOrgId);
    const progressBarRef = useSelector(selectProgressBarRef);

    const onClick = (value: OnlineType) => {
        setOnlineType(value);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        scrollTo(progressBarRef);

        if (!orgId) {
            throw new Error('invalid organizationId');
        }

        if (onlineType === OnlineType.VoidedCheck) {
            const success = await updateBillingSelection(
                orgId,
                BillingSelection.VoidedCheck
            )(dispatch);

            if (success) {
                dispatch(setProgramType(OnlineType.VoidedCheck));
            }
        }

        if (onlineType === OnlineType.Plaid) {
            dispatch(
                updateEnrollmentLog({
                    state: ProgressState.PlaidStarted,
                    version: 2,
                    organizationId: orgId,
                })
            );
        }
    };

    const history = useHistory();

    const params = useQueryParams();
    const [isOAuth, setIsOAuth] = useState(Boolean(params.get('oauth_state_id')));

    const onSuccess = async (payload: unknown) => {
        dispatch(completePlaidSetup(payload as AchAccountBody));
    };

    const completePlaidStatus = useRootSelector(
        (r) => r.store.ui.orgEnrollmentV2.completePlaidStatus
    );

    useDidUpdateEffect(() => {
        if (completePlaidStatus === null) {
            return;
        }

        // PendingManualVerification
        if (completePlaidStatus === AchVerificationStatus.PendingManualVerification) {
            history.replace(Routes.EnrollV2CompletedMicroDeposits);
            return;
        }

        // PendingAutomatedVerification
        if (completePlaidStatus === AchVerificationStatus.PendingAutomatedVerification) {
            history.replace(Routes.EnrollV2CompletedAutomatedDeposits);
            return;
        }

        // Verified
        history.replace(Routes.EnrollV2CompletedInstant);
    }, [completePlaidStatus]);

    const userId = useSelector(selectUserId);

    const onExit = (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
        setIsOAuth(false);

        if (error) {
            const errorData = {
                error,
                metadata,
                userId,
                orgId,
                version: 2, // enrollment version
            };

            appInsights.trackException({
                exception: new Error(JSON.stringify(errorData, null, 4)),
                severityLevel: SeverityLevel.Error,
            });
        }

        dispatch(plaidSetupFailed());
    };

    const onApiError = useCallback(
        (errorInfo?: APIError<null>) => {
            const error = errorInfo?.error;
            dispatch(
                updateCompletePlaidStatusRequest({
                    args: [],
                    error,
                    lastUpdate: Date.now(),
                    loading: false,
                })
            );
        },
        [dispatch]
    );

    return (
        <div data-testid="online_program_body">
            <UserContent
                className={styles.stepSubtitle}
                content={contentfulEntry?.onlineProgramSubtitle}
            />
            <div>
                <form onSubmit={onSubmit}>
                    <RadioGroup.Root>
                        <div
                            className={styles.radioContainer}
                            onClick={() => {
                                onClick(OnlineType.VoidedCheck);
                            }}
                        >
                            <label htmlFor="voidedCheck">
                                <div className={styles.radioContainerTag}>
                                    {contentfulEntry?.voidedCheckTag}
                                </div>
                                <div className="flex flex-row">
                                    <div className={styles.radioIcon}>
                                        <CardIcon />
                                    </div>
                                    <RadioGroup.Item
                                        id="voidedCheck"
                                        className={styles.StyledRadio}
                                        value={OnlineType.VoidedCheck}
                                    >
                                        <RadioGroup.Indicator className={styles.StyledIndicator} />
                                    </RadioGroup.Item>
                                    <div>
                                        <div className={styles.radioContainerTitle}>
                                            {contentfulEntry?.voidedCheckSelectionTitle}
                                        </div>
                                        <UserContent
                                            className={styles.radioContainerSubtitle}
                                            content={contentfulEntry?.voidedCheckSelectionSubtitle}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div
                            className={styles.radioContainer}
                            onClick={() => {
                                onClick(OnlineType.Plaid);
                            }}
                        >
                            <label htmlFor="plaid">
                                <div className={styles.radioContainerTag}>
                                    {contentfulEntry?.linkInstantlyTag}
                                </div>
                                <div className="flex flex-row">
                                    <div className={styles.radioIcon}>
                                        <BankIcon />
                                    </div>
                                    <RadioGroup.Item
                                        id="plaid"
                                        className={styles.StyledRadio}
                                        value={OnlineType.Plaid}
                                    >
                                        <RadioGroup.Indicator className={styles.StyledIndicator} />
                                    </RadioGroup.Item>
                                    <div>
                                        <div className={styles.radioContainerTitle}>
                                            {contentfulEntry?.linkInstantlySelectionTitle}
                                        </div>
                                        <UserContent
                                            className={styles.radioContainerSubtitle}
                                            content={
                                                contentfulEntry?.linkInstantlySelectionSubtitle
                                            }
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                    </RadioGroup.Root>
                    <div className={styles.buttonContainer}>
                        <PlaidWrapper
                            orgId={orgId || ''}
                            isOAuth={isOAuth}
                            onSuccess={onSuccess}
                            onExit={onExit}
                            onApiError={onApiError}
                        >
                            {(openPlaid, ready) => {
                                return (
                                    <Btn
                                        disabled={
                                            onlineType === null ||
                                            (onlineType === OnlineType.Plaid && !ready)
                                        }
                                        className={styles.button}
                                        onClick={
                                            onlineType === OnlineType.Plaid ? openPlaid : undefined
                                        }
                                    >
                                        <span>{contentfulEntry?.connectBankAccountCtaText}</span>
                                    </Btn>
                                );
                            }}
                        </PlaidWrapper>
                    </div>
                </form>
            </div>
        </div>
    );
};
