import React, { useRef } from 'react';
import classNames from 'classnames';

import { FieldStyles } from './FieldStyles';
import { FieldWrapperProps, FieldWrapper } from './FieldWrapper';

export type TextareaFieldProps = FieldWrapperProps &
    React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextareaFieldWithCharCount = (props: TextareaFieldProps) => {
    const { label, error, className, ...textareaProps } = props;
    const ref = useRef<HTMLTextAreaElement>(null);
    return (
        <FieldWrapper label={label} error={error}>
            {(_props) => (
                <>
                    <textarea
                        ref={ref}
                        className={classNames(FieldStyles.field, 'block', className, {
                            error: Boolean(error),
                        })}
                        {...textareaProps}
                        {..._props}
                    />
                    <span className="text-grey-1 text-xs mt-2 block w-full text-right">
                        {ref.current?.value?.length ?? 0}/140 characters
                    </span>
                </>
            )}
        </FieldWrapper>
    );
};
