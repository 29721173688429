import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { createStyles } from 'utils/createStyle';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';

interface BaseCardProps {
    children: React.ReactNode;
    containerClass?: string;
    isLoading: boolean;
    link: LinkProps['to'];
    earnType?: EarnTypeConstructorFilter;
}

const styles = createStyles({
    container: [
        'border border-solid bg-white m-auto mb-0 overflow-hidden',
        {
            boxSizing: 'border-box',
            borderRadius: '10px',
            textDecoration: 'none',
            margin: 0,
        },
    ],
});

const BaseCard = ({ children, containerClass = '', isLoading = false, link }: BaseCardProps) => {
    return (
        <Link
            to={link}
            className={`card-container ${styles.container} ${containerClass} 
                ${isLoading ? 'pointer-events-none' : 'pointer-events-auto'}`}
        >
            {children}
        </Link>
    );
};

export default BaseCard;
