import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { E_COMM_ORGS_URL, PAYMENT_TYPES_URL } from 'common/api/config';
import { PartialRecord } from 'common/definitions/PartialRecord';

export enum PaymentType {
    Check = 'Check',
    BankAccount = 'BankAccount',
    CreditCard = 'CreditCard',
}

export enum PaymentSubType {
    BankAccount = 'BankAccount',
    Debit = 'Debit',
    Credit = 'Credit',
}

export enum BillingMethod {
    Check = 'Check',
    None = 'None',
}

export enum BillingSelection {
    None = 'None',
    MicroA = 'MicroA',
    MicroM = 'MicroM',
    Instant = 'Instant',
    Upload = 'Upload',
    Check = 'Check',
    VoidedCheck = 'VoidedCheck',
    ECheck = 'eCheck',
}

export interface CheckPaymentMethod {
    paymentType: PaymentType.Check;
    paymentSubType?: null;
    achAccountId: null;
    creditCardId: null;
}

export interface ACHPaymentMethod {
    paymentType: PaymentType.BankAccount;
    paymentSubType: null;
    achAccountId: string;
    creditCardId: null;
}

export interface CreditCardPaymentMethod {
    paymentType: PaymentType.CreditCard;
    paymentSubType: PaymentSubType;
    achAccountId: null;
    creditCardId: number | null;
}

export type PaymentMethod = CheckPaymentMethod | ACHPaymentMethod | CreditCardPaymentMethod;

export const getDefaultPaymentMethod = () =>
    withTokensIfNeeded<PaymentMethod>(`${PAYMENT_TYPES_URL}/default`);
export const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) =>
    withTokensIfNeeded<null>(`${PAYMENT_TYPES_URL}/default`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...paymentMethod, paymentSubType: undefined }),
    });

export interface PaymentTypeFee {
    paymentType: PaymentType;
    paymentSubType: PaymentSubType | null;
    amount: number;
    isPercentage: boolean;
}

export const getPaymentTypesForOrganization = (organizationId: string) =>
    withTokensIfNeeded<PaymentType[]>(`${PAYMENT_TYPES_URL}/organization/${organizationId}`);

export const getPaymentTypeFees = () =>
    withTokensIfNeeded<PaymentTypeFee[]>(`${PAYMENT_TYPES_URL}/fee?api-version=2`);

export const getPaymentTypesForBuyNow = () => {
    return withTokensIfNeeded<PaymentMethod[]>(`${PAYMENT_TYPES_URL}/buy-now?api-version=1`);
};

export const setBillingMethodPaymentType = (orgId: string, billingMethod: BillingMethod) =>
    withTokensIfNeeded<null>(`${E_COMM_ORGS_URL}/${orgId}/payment-options`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ billingMethod }),
    });

export const setBillingSelection = (orgId: string, billingSelection: BillingSelection) => {
    return withTokensIfNeeded<null>(`${E_COMM_ORGS_URL}/${orgId}/billing-selection`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ billingSelection }),
    });
};

export const uploadVoidedCheckImage = (orgId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return withTokensIfNeeded<null, string>(
        `${E_COMM_ORGS_URL}/${orgId}/voided-check?api-version=1`,
        {
            method: 'PUT',
            body: formData,
        }
    );
};

// helper functions
export const getFeeForPaymentMethod = (
    paymentTypeFees: PartialRecord<PaymentSubType, PaymentTypeFee> | null,
    paymentMethod: PaymentMethod | null
) => {
    if (paymentTypeFees && paymentMethod) {
        if (paymentMethod.paymentType === PaymentType.BankAccount) {
            return paymentTypeFees[PaymentType.BankAccount];
        } else if (paymentMethod.paymentType === PaymentType.Check) {
            return null;
        } else {
            return paymentTypeFees[paymentMethod.paymentSubType!];
        }
    }
    return null;
};
