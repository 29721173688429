import { ProductBonus } from '../../models/ProductBonus';

export enum ProductQueryParam {
    EGiftCard = 'eGift',
    GiftCard = 'physical',
    Reload = 'reload',
}

export enum ProductType {
    EGiftCard = 'EGiftCard',
    GiftCard = 'GiftCard',
    Reload = 'Reload',
}

export interface ProductOption {
    id: number;
    label: string;
    values: string[];
}

export interface ProductVariantOption {
    value: string;
    optionId: number;
    optionDisplayName: string;
}

export interface ProductVariant {
    id: number;
    price: number;
    options: ProductVariantOption[];
    imageUrl: string;
    sku: string;
    isAvailable: boolean;
    isVisible: boolean;
    bonus: ProductBonus | null;
    rebate: number;
}

export interface Product {
    id: number;
    name: string;
    sku: string;
    supplierCode: string;
    brandId: number;
    price: number;
    rebate: number;
    bonus: ProductBonus | null;
    type: ProductType;
    categories: number[];
    imageUrl: string | null;
    isAvailable: boolean;
    isFeatured: boolean;
    isLocalProduct: boolean;
    isOvernightReload: boolean;
    isReloadable: boolean;
    options: ProductOption[];
    variants: ProductVariant[];
    canOverridePrice: boolean;
    minValue: number | null;
    maxValue: number | null;
    maxBalance: number | null;
    unitPrice: number | null; // null = $0.01 increments
    isVisible: boolean;
    isShipToHomeEligible: boolean;
    organization: string;
}

export const emptyProduct: Product = {
    id: 0,
    name: '',
    sku: '',
    supplierCode: '',
    brandId: 0,
    price: 0,
    rebate: 0,
    bonus: null,
    type: ProductType.EGiftCard,
    categories: [],
    imageUrl: null,
    isAvailable: false,
    isFeatured: false,
    isLocalProduct: false,
    isOvernightReload: false,
    isReloadable: false,
    isVisible: true,
    options: [],
    variants: [],
    canOverridePrice: false,
    minValue: null,
    maxValue: null,
    maxBalance: null,
    unitPrice: null,
    isShipToHomeEligible: false,
    organization: '',
};
