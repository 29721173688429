import React from 'react';
import { css } from 'glamor';

import { createStyles, createStyle } from '../../utils/createStyle';
import { colors } from '../../styles/settings';

export interface CarouselControlProps {
    prev?: () => void;
    next?: () => void;
    showDots: boolean;
    dots: React.ReactNode;
}

const dotsContainerStyles = css({
    position: 'unset !important',
    marginTop: '25px !important',
    fontSize: 20,
    marginBottom: '0px !important',
    '.slick-dots li button:before': {
        fontSize: 10,
    },
    '.slick-dots li.slick-active button:before': {
        color: colors.bonusPink,
    },
});

const style = createStyles({
    dotsContainer: dotsContainerStyles,
    dotsWrapper: createStyle('flex justify-center custom-dots'),
    dotsStyles: createStyle('dots m-0 flex items-center'),
});

export const CarouselControl = ({ prev, next, showDots, dots }: CarouselControlProps) => {
    return (
        <div className={`slick-dots ${style.dotsContainer}`}>
            <div className={style.dotsWrapper}>
                <button className="text-brand font-bold" onClick={() => prev && prev()}>
                    {'<'}
                </button>
                {showDots ? (
                    <ul className={style.dotsStyles}> {dots} </ul>
                ) : (
                    <p className="text-grey-1 text-sm my-auto mx-3">
                        Swipe for more featured brands
                    </p>
                )}
                <button className="text-brand font-bold" onClick={() => next && next()}>
                    {'>'}
                </button>
            </div>
        </div>
    );
};
