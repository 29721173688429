import { getBrandsBatched } from 'common/api/e-comm/brands';
import { uniq } from 'common/utils';

const createBrandLoader = () => {
    const loading = new Set();

    return {
        loadBrands: async (brandIds: number[]) => {
            const uniqueBrands = uniq(brandIds).filter((brandId) => !loading.has(brandId));

            uniqueBrands.forEach((brandId) => loading.add(brandId));

            const response = await getBrandsBatched(uniqueBrands);

            uniqueBrands.forEach((brandId) => loading.delete(brandId));

            return response;
        },
        clearLoading: () => loading.clear(),
    };
};

export const brandLoader = createBrandLoader();
