import React, { Fragment, useContext, useRef } from 'react';
import { useHistory } from 'react-router';

import { EnrollmentContext } from '../context';
import { ReactComponent as CheckMark } from '../../../../media/images/check-mark.svg';

import { ECheckPrimary } from './receive-earnings/ECheckBox';

import { h2 } from 'styles/typography';
import { createStyles } from 'utils/createStyle';
import { Btn } from 'components/btn/Btn';
import { withRetries } from 'common/utils/withRetries';
import { BillingSelection, setBillingSelection } from 'common/api/e-comm/payment-types';
import { closeLoader, LoadingOverlay, openLoader } from 'components/loading-overlay/LoadingOverlay';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { getPath, Routes } from 'routes';
import { useAlert } from 'modules/alerts';
import { LinkBtn } from 'components/link-btn';

const styles = createStyles({
    table: [
        'sm:grid',
        {
            gridTemplateColumns: '1fr 180px',
        },
    ],
    liteProgramHeaderColumn: ['text-white bg-brand p-2 sm:p-1 text-center rounded-t-md'],
    text: 'sm:border-b border-solid border-grey-3 py-2 sm:text-xs text-grey-1',
    checkContainer:
        'text-brand border-b border-solid border-grey-3 sm:py-2 text-center bg-pale-blue',
    check: 'inline-block',
    title: 'font-bold text-xl sm:text-2xl text-brand-dark mb-2',
});

const items = [
    { text: 'Free to setup and administer' },
    { text: 'Cards delivered directly to participants' },
    { text: '250+ brands available for eGift Cards and direct delivery' },
    { text: 'Participants order and pay using mobile app (iOS and Android)' },
    { text: 'Order eGift or physical cards on our website' },
];

export const LitePlusInfoStep = () => {
    const { organization } = useContext(EnrollmentContext);
    const history = useHistory();
    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();

    const navigateToNextStep = async (target: 'LiteProgram' | 'PaperOnly') => {
        const orgId = organization.id || '';
        openLoader(loader.current);
        const billing = target === 'PaperOnly' ? BillingSelection.Check : BillingSelection.ECheck;
        const result = await withRetries(setBillingSelection)(orgId, billing);
        await closeLoader(loader.current);

        if (result.error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }

        if (target === 'PaperOnly') {
            await withRetries(updateEnrollmentProgress)({
                state: ProgressState.PaperOnlySelected,
                organizationId: orgId,
                version: 2,
            });
        }

        history.replace(
            getPath(Routes.EnrollCompleted, { status: target === 'PaperOnly' ? 'paper-only' : '' })
        );
    };

    return (
        <div>
            <div className="max-w-2xl mx-auto px-4 sm:px-10">
                <div className="mb-4 sm:mb-8" />
                <h1 tabIndex={0} className={h2}>
                    Take a look at a Lite Program with eCheck
                </h1>
                <div className="mb-5" />
                <div className={styles.table}>
                    <div />
                    <div className={styles.liteProgramHeaderColumn}>Lite Program</div>
                    {items.map((it, i) => (
                        <Fragment key={i}>
                            <div className={styles.text}>{it.text}</div>
                            <div className={styles.checkContainer}>
                                <CheckMark className={'inline-block sm:hidden'} width={'18px'} />
                                <CheckMark className={'hidden sm:inline-block'} />
                            </div>
                        </Fragment>
                    ))}
                </div>
                <div className="mt-8">
                    <div className={styles.title}>Why choose this program</div>
                    <ul className="list-disc pl-5 text-grey-1">
                        <li>
                            Never handle a gift card. All your people get their gift cards delivered
                            electronically or shipped directly to them.
                        </li>
                    </ul>
                </div>
                <div className="mt-8">
                    <div className={styles.title}>
                        Your organization will receive funds via eCheck
                    </div>
                    <ECheckPrimary header="eCheck">
                        <div className="text-center w-full sm:w-auto">
                            <Btn
                                className="w-full sm:w-auto"
                                onClick={() => navigateToNextStep('LiteProgram')}
                            >
                                Select lite program &amp; eCheck
                            </Btn>
                        </div>
                    </ECheckPrimary>
                </div>
                <div className="text-xs text-center my-16">
                    <div className="text-grey-1">Does this look like it won’t work for you?</div>
                    <div>
                        <LinkBtn onClick={() => navigateToNextStep('PaperOnly')}>
                            Try Paper Only
                        </LinkBtn>
                    </div>
                </div>
            </div>
            <LoadingOverlay ref={loader} />
        </div>
    );
};
