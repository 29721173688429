import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { FieldStyles } from './FieldStyles';
import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';
import { Checkbox } from 'components/checkbox/Checkbox';
import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';

const styles = createStyles({
    hideArrows: [
        {
            MozAppearance: 'textfield',
            '::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
            },
            '::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
            },
        },
    ],
    passwordField: [
        'w-11/12',
        {
            padding: '4px 6px',
            margin: '2px 2px',
            outline: 'none',
        },
    ],
    passwordWrapper: [
        'flex w-full items-center',
        {
            padding: 0,
            outline: 'none',
            ':focus-within': {
                borderColor: colors.brandHighlight,
                boxShadow: `inset 0 0 5px 0 #ADC5DB, 0 0 0 1px ${colors.brandHighlight}`,
            },
        },
    ],
});

export type TextFieldProps = FieldWrapperProps &
    React.InputHTMLAttributes<HTMLInputElement> & {
        inputRef?: React.Ref<HTMLInputElement>;
        errorClassName?: string;
    };

export const TextField = (props: TextFieldProps) => {
    const { label, error, className, inputRef, wrapperStyles, errorClassName, ...inputProps } =
        props;
    const value = inputProps.value || '';

    return (
        <FieldWrapper
            label={label}
            errorClassName={errorClassName}
            error={error}
            wrapperStyles={wrapperStyles}
        >
            {(_props) => (
                <input
                    className={classNames(FieldStyles.field, className, { error: Boolean(error) })}
                    ref={inputRef}
                    {...inputProps}
                    {..._props}
                    value={value}
                />
            )}
        </FieldWrapper>
    );
};

export interface TextFieldSecuredProps extends TextFieldProps {}

export const TextFieldSecured = (props: TextFieldSecuredProps) => {
    const { label, error, className, inputRef, ...inputProps } = props;
    const [isSecure, setIsSecure] = useState<boolean | undefined>(true);

    return (
        <FieldWrapper label={label} error={error}>
            {(_props) => (
                <div
                    className={classNames(
                        FieldStyles.field,
                        styles.passwordWrapper,
                        className,
                        // 'toggle-space',
                        {
                            error: Boolean(error),
                        }
                    )}
                >
                    <input
                        className={styles.passwordField}
                        ref={inputRef}
                        type={isSecure ? 'password' : 'text'}
                        {...inputProps}
                        {..._props}
                    />
                    <button
                        aria-label={isSecure ? 'Password is hidden' : 'Password is shown'}
                        aria-pressed={!isSecure}
                        onClick={() => setIsSecure(!isSecure)}
                        className={FieldStyles.showHide}
                        type="button"
                    >
                        {isSecure ? 'SHOW' : 'HIDE'}
                    </button>
                </div>
            )}
        </FieldWrapper>
    );
};

type NumberFieldProps = TextFieldProps & {
    disableIncrement?: boolean;
    acceptOnlyIntegerNumbers: boolean;
};

const ignoreArrowUpAndDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const KEY_ARROW_UP = 'ArrowUp';
    const KEY_ARROW_DOWN = 'ArrowDown';

    if (e.key === KEY_ARROW_UP || e.key === KEY_ARROW_DOWN) {
        e.preventDefault();
        return;
    }
};

export const NumberField = (props: NumberFieldProps) => {
    const {
        label,
        error,
        className,
        inputRef,
        disableIncrement,
        acceptOnlyIntegerNumbers,
        ...inputProps
    } = props;
    const formikContext = useFormikContext();
    const fieldName = inputProps.name as string;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[a-zA-z]/g, '');
        const maxLength = inputProps.maxLength || 0;

        // ignore input if it goes past the maxLength
        if (maxLength && value.length > maxLength) {
            return;
        }

        // manually set the value
        formikContext.setFieldValue(fieldName, value);
    };

    const onInputPaste = useMemo(() => {
        if (!acceptOnlyIntegerNumbers) {
            return;
        }

        return (e: React.ClipboardEvent<HTMLInputElement>) => {
            const data = e?.clipboardData?.getData('text');
            if (data) {
                e.preventDefault();
                const numbers = data.replaceAll(/[^0-9]/gi, '');
                formikContext.setFieldValue(fieldName, numbers);
            }
        };
    }, [acceptOnlyIntegerNumbers, fieldName, formikContext]);

    const onInputKeydown = useMemo(() => {
        if (!disableIncrement && !acceptOnlyIntegerNumbers) {
            return;
        }

        return (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (disableIncrement) {
                ignoreArrowUpAndDown(e);
            }

            if (acceptOnlyIntegerNumbers) {
                if (
                    e.key === '+' ||
                    e.key === '-' ||
                    e.key === 'e' ||
                    e.key === 'E' ||
                    e.key === ',' ||
                    e.key === '.'
                ) {
                    e.preventDefault();
                    return;
                }
            }
        };
    }, [disableIncrement, acceptOnlyIntegerNumbers]);

    return (
        <FieldWrapper label={label} error={error}>
            {(_props) => (
                <input
                    ref={inputRef}
                    className={classNames(
                        FieldStyles.field,
                        className,
                        disableIncrement ? styles.hideArrows : '',
                        { error: Boolean(error) }
                    )}
                    {...inputProps}
                    {..._props}
                    onChange={onChange}
                    type="number"
                    onKeyDown={onInputKeydown}
                    onPaste={onInputPaste}
                />
            )}
        </FieldWrapper>
    );
};

interface CheckboxCustomProps {
    onStateChange?: (_: boolean) => void;
}

export const CheckboxField = (props: TextFieldProps & CheckboxCustomProps) => {
    const { label, error, className, name, id, checked: defaultChecked, onStateChange } = props;
    const [checked, setCheckedTo] = useState<boolean>(Boolean(defaultChecked));
    const formikContext = useFormikContext();

    const onChange = (value: boolean) => {
        setCheckedTo(value);
        formikContext.setFieldValue(name as string, value);

        if (onStateChange) {
            onStateChange(value);
        }
    };

    return (
        <FieldWrapper label={''} error={error}>
            {() => (
                <Checkbox
                    id={id}
                    labelClassName={className}
                    text={label as string}
                    onChange={onChange}
                    checked={checked}
                />
            )}
        </FieldWrapper>
    );
};
