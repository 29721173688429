import { BrandSearchResponse } from './models/BrandSearchResponse';
import { toSearchResult } from './utils/toSearchResult';
import { defaultBrandSearchOptions } from './config';

import { SearchApiQueryParams, searchApiFetch } from './';

import { and, facetPOST, includesEq } from 'common/utils/searchFilters';
import { FilterResponse } from 'common/api/search/models/FilterResponse';
import {
    BrandsSearchFacet,
    BrandsSearchFacets,
} from 'common/api/search/models/filters/BrandsSearchFacets';
import { normalizeResponse } from 'common/api/utils';
import { toPascalCase } from 'common/utils/strings';

export const brandsByCategoryNameFilter = (categoryName: string) =>
    includesEq(toPascalCase(BrandsSearchFacet.Categories), categoryName);

export const getBrandsByCategoryName = async (
    categoryName: string,
    options: Partial<SearchApiQueryParams> = {}
) => {
    const response = await searchApiFetch<BrandSearchResponse>({
        ...defaultBrandSearchOptions,
        ...options,
        filter: options.filter
            ? and(brandsByCategoryNameFilter(categoryName), options.filter)
            : brandsByCategoryNameFilter(categoryName),
    });

    return toSearchResult(response);
};

export const defaultBrandFilterFacets: string[] = [
    facetPOST('Categories,count:500'),
    facetPOST('Price', '20|50|200|250|500'),
    facetPOST('SupportedTypes'),
    facetPOST('IsOnBonus'),
    // facetPOST('EarnType', 'Gift Cards|Online'),
];

export const getBrandsFilters = async (options: Partial<SearchApiQueryParams> = {}) => {
    const response = await searchApiFetch<FilterResponse<BrandsSearchFacets>>(
        {
            facets: defaultBrandFilterFacets,
            count: true,
            top: 0,
            ...options,
        },
        true
    );

    return normalizeResponse(response);
};
