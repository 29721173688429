import React from 'react';
import { useHistory } from 'react-router-dom';

import { Btn } from 'components/btn/Btn';
import { createStyles } from 'utils/createStyle';
import { ShopScreen } from 'features/shop-base/ShopScreen';
import { Routes } from 'routes';
import useQueryParams from 'hooks/useQueryParams';

const styles = createStyles({
    masthead: 'shadow-lg flex justify-center pt-5 pb-3 sm:pt-6 sm:pb-4',
    logo: 'w-40 sm:w-60',
    imageBackground: 'max-w-6xl mx-auto pt-0 mb-2 bg-no-repeat bg-center bg-cover lg:bg-contain',
    contentContainer:
        'flex flex-col justify-center max-w-3xl mx-auto pb-20 md:pb-28 text-center px-6',
    title: [
        'text-brand text-8rem md:text-11rem',
        {
            background: 'linear-gradient(180deg, #F15A29 0%, #E6126A 100%)',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontWeight: 700,
        },
    ],
});

export const NotFoundScreen = () => {
    const history = useHistory();

    const queryParams = useQueryParams();
    const UrlStatusCode = queryParams.get('status_code');
    const statusCode = UrlStatusCode ? UrlStatusCode : '404';

    let statusText = 'Sorry, an error occurred.  Let us help you get to the right place.';

    switch (statusCode) {
        case '404':
            statusText = "Sorry, we can't find that page. Let us help you get to the right place.";
            break;

        default:
            break;
    }

    return (
        <ShopScreen>
            <main>
                <div className="lg:mt-14 md:mt-0 sm:mt-0 mt-0" />
                <div>
                    <div className={styles.contentContainer}>
                        <h1 className={styles.title}>{statusCode}</h1>
                        <div className="flex flex-col text-3xl md:text-5xl text-brand-dark mt-4">
                            {statusText}
                            <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-center mt-10 md:mt-20">
                                <div>
                                    <a
                                        href={
                                            process.env.REACT_APP_RAISE_RIGHT_APP_URL ||
                                            'https://www.raiseright.com'
                                        }
                                    >
                                        <Btn>RaiseRight Home</Btn>
                                    </a>
                                </div>
                                <div>
                                    <Btn
                                        onClick={() => history.push(Routes.Brands)}
                                        isSecondary={true}
                                    >
                                        Shop Gift Cards
                                    </Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ShopScreen>
    );
};
