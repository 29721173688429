import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';

export const styles = createStyles({
    header: [
        'text-2xl mt-10 mx-2 md:text-4xl md:mt-24 ',
        {
            fontWeight: 'normal',
            color: colors.brandDark,
        },
    ],
    subHeader: [
        'text-xl mt-2.5 mx-2 md:text-2xl',
        {
            fontWeight: 600,
            color: colors.grey1,
        },
    ],
});
