import React, { useRef, useCallback } from 'react';

import { Modal, ModalFooter, ModalPrimaryBtn, ModalSecondaryBtn } from 'components/modal/Modal';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { setBillingSelection, BillingSelection } from 'common/api/e-comm/payment-types';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { useAlert } from 'modules/alerts';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';

interface PaperOnlyModalProps {
    isOpen?: boolean;
    onClose: () => void;
    orgId: string;
    primaryBtnText: React.ReactNode;
    secondaryBtnText: React.ReactNode;
    onBtnPrimaryClick: () => void;
    onBtnSecondaryClick: () => void;
}

export const PaperOnlyModal = (props: PaperOnlyModalProps) => {
    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();
    const { onBtnPrimaryClick } = props;

    const onContinueClick = useCallback(async () => {
        openLoader(loader.current);
        const result = await withRetries(setBillingSelection)(props.orgId, BillingSelection.Check);
        await closeLoader(loader.current);
        if (result.error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }
        withRetries(updateEnrollmentProgress)({
            state: ProgressState.PaperOnlySelected,
            organizationId: props.orgId,
        });
        onBtnPrimaryClick();
    }, [alert, props.orgId, onBtnPrimaryClick]);

    return (
        <Modal title="Physical Mailed Check" isOpen={props.isOpen} onClose={props.onClose}>
            <div className="text-grey-1">
                <p className="mb-2">
                    This option limits the earning potential for your organization.
                </p>
                <ul className="list-disc text-sm ml-6 leading-6">
                    <li>
                        You collect payment from your participants and mail us a check for a
                        combined order
                    </li>
                    <li>
                        Your participants can still order online and in the app, their online orders
                        can be added to your combined order but will be delayed until your payment
                        is sent and processed
                    </li>
                    <li>
                        Good option if you can't link a bank account and you want to sell offline
                    </li>
                </ul>
            </div>
            <ModalFooter>
                <ModalPrimaryBtn onClick={onContinueClick}>
                    Continue with Physical Mailed Check
                </ModalPrimaryBtn>
                <ModalSecondaryBtn onClick={props.onClose}>
                    No, I can connect a bank account
                </ModalSecondaryBtn>
            </ModalFooter>
            <LoadingOverlay ref={loader} />
        </Modal>
    );
};
