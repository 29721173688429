import { useEffect, useState, useCallback } from 'react';

const CONSTRUCTOR_SESSION_COUNTER_KEY = 'constructor-session-counter';
const SESSION_MAX_DURATION = 30;

export const useConstructorSessionCounter = () => {
    const [inactivityStart, setInactivityStart] = useState<Date>();
    const [sessionCounter, setSessionCounter] = useState(1);

    const getCounterFromStorage = useCallback(() => {
        const counterStr = localStorage.getItem(CONSTRUCTOR_SESSION_COUNTER_KEY);
        return parseInt(counterStr || '1');
    }, []);

    const setCounterToStorage = useCallback((counter: number) => {
        localStorage.setItem(CONSTRUCTOR_SESSION_COUNTER_KEY, counter.toString());
    }, []);

    // initialize session counter (always 1 or greater)
    useEffect(() => {
        const counter = getCounterFromStorage();
        setSessionCounter(counter);
    }, []);

    // save session counter to storage
    useEffect(() => {
        if (sessionCounter <= 0) {
            return;
        }

        setCounterToStorage(sessionCounter);
    }, [sessionCounter]);

    const incrementCounter = useCallback(() => {
        if (sessionCounter === 0) {
            return;
        }
        setSessionCounter((prevCounter) => prevCounter + 1);
    }, [sessionCounter]);

    const startCounter = useCallback(() => {
        if (inactivityStart) {
            return;
        }

        setInactivityStart(new Date());
    }, [inactivityStart]);

    const getSessionExpired = useCallback(() => {
        if (!inactivityStart) {
            return false;
        }

        const now = new Date();
        const diff = now.getTime() - inactivityStart.getTime();
        const minutes = Math.floor(diff / 1000 / 60);

        return minutes >= SESSION_MAX_DURATION;
    }, [inactivityStart]);

    const stopCounter = useCallback(() => {
        const sessionExpired = getSessionExpired();

        if (sessionExpired) {
            setInactivityStart(undefined);
            incrementCounter();
        }
    }, [getSessionExpired, incrementCounter, inactivityStart]);

    const userActive = document.visibilityState === 'visible';

    useEffect(() => {
        if (userActive) {
            startCounter();
        } else {
            stopCounter();
        }
    }, [userActive, startCounter, stopCounter]);

    return sessionCounter;
};
