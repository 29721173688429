import React, { useContext } from 'react';
import { BaseStep } from '../BaseStep';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import { FamilyEnrollmentContext } from 'features/family-enrollment/context';
import { Document } from '@contentful/rich-text-types';
import {
    FormikTextField,
    FormikCheckboxField,
    FormikTextFieldSecured,
} from 'components/fields/FormikTextField';
import {
    orgEnrollEmailValidator,
    orgEnrollPasswordValidator,
    confirmPasswordValidator,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { useBaseStyles, ButtonControls } from 'features/family-enrollment/steps/utils';
import { FormikSetPasswordField } from 'components/fields/FormikSetPasswordField';
import { useAppConfig } from 'common/features/app-config/utils/useAppConfig';
import { UserContent } from 'components/user-content/UserContent';
import { useContentfulGlobalContent } from 'common/api/contentful/global';
import { RegistrationRequest } from 'common/api/auth/models/RegistrationRequest';
import { validateEmail } from 'common/api/users/email/validate';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { ContentfulGlobal } from 'common/api/contentful/models/ContentfulGlobal';
import { TermsAndConditions } from 'components/terms-and-conditions/TermsAndConditions';

interface SignInFormProps {
    email: string;
    marketingOptIn: boolean;
    password: string;
    confirmPassword: string;
}

const PasswordField = ({
    minAcceptableScore,
    globalContent,
}: {
    minAcceptableScore: number | null;
    globalContent: ContentfulGlobal | null;
}) => {
    return (
        <div>
            <FormikSetPasswordField
                name="password"
                label="Password (8+ characters)"
                maxLength={200}
                validate={orgEnrollPasswordValidator}
                minAcceptableScore={minAcceptableScore}
            />
            {globalContent && (
                <div className="text-xs text-grey-1 mt-3">
                    <UserContent content={globalContent?.setPasswordTooltip} />
                </div>
            )}
        </div>
    );
};

const SignInForm = () => {
    const { goToNextStep, updateUserAccountInfo, userAccountInfo, loader } =
        useContext(FamilyEnrollmentContext);
    const { appConfig } = useAppConfig();
    const baseStyles = useBaseStyles();
    const globalContent = useContentfulGlobalContent();

    const initialValues = {
        email: userAccountInfo?.email,
        marketingOptIn: userAccountInfo?.marketingOptIn,
        password: userAccountInfo?.password,
        confirmPassword: userAccountInfo?.confirmPassword,
    } as SignInFormProps;

    const onSubmit = async (values: SignInFormProps, formik: FormikHelpers<SignInFormProps>) => {
        const { email } = values;

        openLoader(loader?.current as LoadingOverlay);
        const response = await validateEmail(email);
        closeLoader(loader?.current as LoadingOverlay);

        // CASE: user puts an email that already exists
        if (hasErrorWithStatus(response, StatusCode.Conflict)) {
            formik.setFieldError(
                'email',
                'Looks like this email is already associated with an account.'
            );
            return;
        }

        // CASE: all the data validates we can continue with the flow
        updateUserAccountInfo((currentState: RegistrationRequest) => ({
            ...currentState,
            ...values,
        }));

        goToNextStep();
    };

    return (
        <div className={baseStyles.formContainer}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps: FormikProps<SignInFormProps>) => (
                    <Form>
                        <FormikTextField
                            type="email"
                            name="email"
                            label="Email"
                            validate={orgEnrollEmailValidator}
                        />
                        <FormikCheckboxField
                            name="marketingOptIn"
                            id="marketingOptIn"
                            label="Yes, send me emails when bonus earnings increase or when exclusive earning opportunities are available."
                            className="text-sm"
                            checked={initialValues.marketingOptIn}
                        />
                        <FieldSpacer />
                        <PasswordField
                            minAcceptableScore={appConfig.passwordPolicy.minimumZxcvbnScore}
                            globalContent={globalContent}
                        />
                        <FieldSpacer />
                        <FormikTextFieldSecured
                            name="confirmPassword"
                            label="Confirm Password"
                            maxLength={200}
                            validate={confirmPasswordValidator(formikProps.values.password)}
                        />
                        <ButtonControls />
                    </Form>
                )}
            </Formik>
            <TermsAndConditions {...globalContent} />
        </div>
    );
};

export const SignInInfoStep = () => {
    const { stepHeaders, organization } = useContext(FamilyEnrollmentContext);

    return (
        <BaseStep
            contentClassName="text-md sm:text-lg font-thin"
            heading={stepHeaders?.signInInfoHeading as string}
            subheading={stepHeaders?.sIgnInInfoBody as Document}
            subheadingData={{ OrganizationName: organization?.name }}
        >
            <SignInForm />
        </BaseStep>
    );
};
