import React, { useEffect, useState } from 'react';
import { useRootSelector } from 'rootStore';
import { requestRecommendedCategories } from 'common/features/store/duck/categories/duck';
import { useDispatch } from 'react-redux';
import { Btn } from 'components/btn/Btn';
import { ReactComponent as TodayBonusStars } from '../../../media/images/today-bonus-stars.svg';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import Skeleton from 'react-loading-skeleton';
import { createStyles } from 'utils/createStyle';

interface CategoryCardProps {
    name: string;
    iconUrl: string;
}

const style = createStyles({
    container: 'w-full p-6 flex items-center flex-col max-w-full sm:max-w-7xl ml-auto mr-auto py-10 md:py-14',
    titleWrapper: 'text-brand-dark text-3xl font-semibold flex items-center justify-center',
    title: 'ml-4 text-wrap w-min sm:w-auto',
    categoryWrapper: 'h-full w-full flex flex-wrap mb-7 flex-row items-center justify-center',
    categoryCardWrapper: 'flex flex-col justify-between w-36 items-center mx-2 sm:mx-6 mb-3',
    categoryImage: 'h-24 w-24',
    categoryNoImage: 'bg-grey-5 h-24 w-24 rounded-full',
    categoryName: 'mt-4 text-lg text-brand-accent text-wrap h-16 text-center',
});

// category card responsive
const CategoryCard = ({ name, iconUrl }: CategoryCardProps) => (
    <div className={style.categoryCardWrapper}>
        {iconUrl ? (
            <img className={style.categoryImage} src={iconUrl} alt={name} />
        ) : (
            <div className={style.categoryNoImage} />
        )}
        <p className={style.categoryName}>{name}</p>
    </div>
);

// card for loading state
const EmptyCategoryCard = () => (
    <div className={style.categoryCardWrapper}>
        <Skeleton className="mb-2" width={100} height={100} />
        <Skeleton width={100} height={10} />
    </div>
);

export const CategoriesSection = () => {
    const allCategories = useRootSelector(
        (state) => state.store.categories.recommendedCategory?.categories || []
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch<any>();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            await requestRecommendedCategories()(dispatch);
            setIsLoading(false);
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && location.hash === '#categories') {
            location.hash = '';
            location.hash = '#categories';
        }
    }, [isLoading]);

    const renderLoading = () =>
        [...Array(30)].map((_, i) => <EmptyCategoryCard key={`empty-card-${i}`} />);

    const renderAllCategories = () => {
        return allCategories.map((cat) => {
            const categoryUrl = Routes.ShopByCategory.replace(
                ':categoryId',
                `${cat.id}-${cat.name.toLowerCase().replace(/\s/g, '-')}`
            );
            return (
                <Link key={cat.id} to={`${categoryUrl}`}>
                    <CategoryCard {...cat} />
                </Link>
            );
        });
    };

    return (
        <div id="categories" className={`shop_featured_categories ${style.container}`}>
            <div className={style.titleWrapper}>
                <TodayBonusStars />
                <span className={style.title}>Recommended Categories</span>
            </div>
            <div className={style.categoryWrapper}>
                {isLoading ? renderLoading() : renderAllCategories()}
            </div>

            <Btn onClick={() => history.push(Routes.Brands)}>Shop All Categories</Btn>
        </div>
    );
};
