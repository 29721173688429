import { useRef, useEffect, useState } from 'react';

type UseMeasureReturnType = [
    React.MutableRefObject<null>,
    { left: number; top: number; width: number; height: number }
];

export const useMeasure = (): UseMeasureReturnType => {
    const ref = useRef(null);
    const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
    useEffect(() => {
        if (ref?.current) {
            ro.observe(ref.current);
        }
        return () => ro.disconnect();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
    return [ref, bounds];
};
