import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import {
    ContentFullEnrollmentProvider,
    ContentFullEnrollmentContext,
} from 'features/coordinator/components/useContentfulEnrollment';
import { getEnrollmentEntryById } from 'common/api/contentful/enrollment';
import { noop } from 'common/utils';
import { FamilyEnrollment } from 'common/api/contentful/models/Familyenrollment';
import { Routes } from 'routes';
import { useHistory } from 'react-router-dom';
import { LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { OrganizationDetail } from 'common/api/crm/models/OrganizationDetail';
import {
    RegistrationRequest,
    emptyRegistrationRequest,
} from 'common/api/auth/models/RegistrationRequest';
import { EnrollmentContentType } from 'features/coordinator/types';
import { Organization } from 'common/api/crm/organization/enrollment/models';

interface FamilyEnrollmentContextProps {
    goToStep: (_: string) => void;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    stepHeaders?: FamilyEnrollment;
    loader: React.RefObject<LoadingOverlay> | null;
    // NOTE: Update this interface to something more appropriate 1/2/3.
    // We need to accumulate the user info until step 3
    userAccountInfo: RegistrationRequest;
    updateUserAccountInfo: React.Dispatch<React.SetStateAction<RegistrationRequest>>;
    organization: Organization | null;
    setOrganizationTo: React.Dispatch<React.SetStateAction<Organization | null>>;
    phoneId: string;
    setPhoneId: React.Dispatch<React.SetStateAction<string>>;
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
}

const initialFamilyEnrollmentState = {
    goToStep: noop,
    goToNextStep: noop,
    goToPreviousStep: noop,
    stepHeaders: {} as FamilyEnrollment,
    userAccountInfo: emptyRegistrationRequest,
    updateUserAccountInfo: noop,
    loader: null,
    organization: null,
    setOrganizationTo: noop,
    phoneId: '',
    setPhoneId: noop,
    phoneNumber: '',
    setPhoneNumber: noop,
};

export const FamilyEnrollmentContext = React.createContext<FamilyEnrollmentContextProps>(
    initialFamilyEnrollmentState
);

const transitions: { [key: string]: string } = {
    [Routes.EnrollFamilyCreateAccount]: Routes.EnrollFamilySignInInfo,
    [Routes.EnrollFamilySignInInfo]: Routes.EnrollFamilySecurityQuestions,
    [Routes.EnrollFamilySecurityQuestions]: Routes.EnrollFamilySetupRecoveryPhone,
    [Routes.EnrollFamilySetupRecoveryPhone]: Routes.EnrollFamilyVerifyRecoveryPhone,
};

const FamilyEnrollmentProvider = ({ children }: React.PropsWithChildren) => {
    // NOTE: grab the contentful data and transform it to something we want to provide
    // to the steps
    const [stepHeaders, setStepHeadersTo] = useState<FamilyEnrollment>();
    const { contentfulEntry, isLoading } = useContext(ContentFullEnrollmentContext);
    const [userAccountInfo, setUserAccountInfoTo] =
        useState<RegistrationRequest>(emptyRegistrationRequest);
    const [organization, setOrganizationTo] = useState<Organization | null>({} as Organization);
    const loader = useRef<LoadingOverlay>(null);
    const history = useHistory();

    const goToNextStep = useCallback(() => {
        const path = history.location.pathname;
        const nextStep = transitions[path];

        history.push(nextStep);
    }, [history]);

    const goToPreviousStep = () => history.goBack();

    const goToStep = useCallback(
        (path: string) => {
            // NOTE: might not need it
            history.replace(path);
        },
        [history]
    );

    useEffect(() => {
        if (contentfulEntry) {
            setStepHeadersTo(contentfulEntry as FamilyEnrollment);
        }
    }, [contentfulEntry]);

    const [phoneId, setPhoneId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const context = {
        goToNextStep,
        goToPreviousStep,
        goToStep,
        stepHeaders: stepHeaders,
        loader,
        userAccountInfo,
        updateUserAccountInfo: setUserAccountInfoTo,
        organization,
        setOrganizationTo,
        phoneId,
        setPhoneId,
        phoneNumber,
        setPhoneNumber,
    };

    // make sure we only render IFF contentful has loaded
    if (isLoading) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <FamilyEnrollmentContext.Provider value={context}>
            {children}
            <LoadingOverlay ref={loader} />
        </FamilyEnrollmentContext.Provider>
    );
};

export const FamilyEnrollmentView = ({ children }: React.PropsWithChildren) => {
    return (
        <ContentFullEnrollmentProvider
            enrollmentVersion="v1"
            firebaseKey={'family_enrollment_contentful_heading_map'}
            fetchContentfulContent={getEnrollmentEntryById}
            contentType={EnrollmentContentType.FAMILY_ENROLLMENT}
        >
            <FamilyEnrollmentProvider>{children}</FamilyEnrollmentProvider>
        </ContentFullEnrollmentProvider>
    );
};
