import React, { ReactNode } from 'react';
import { useEffect } from 'react';
import { useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';
import { Value } from '@firebase/remote-config';

interface ZenDeskWidgetProps {
    children: ReactNode;
}

const toBoolean = (v: Value) => v.asBoolean();

export const ZenDeskWidget = ({ children }: ZenDeskWidgetProps) => {
    const useNewZendeskScript = useFirebaseConfigValues('enable_Zopim_chat', undefined, toBoolean);

    useEffect(() => {
        if (!useNewZendeskScript) {
            return;
        }

        const script = document.createElement('script');

        script.setAttribute('charset', 'utf-8');
        script.async = true;
        script.type = 'text/javascript';
        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY_V2}`;

        document.body.appendChild(script);
    }, [useNewZendeskScript]);

    return <>{children}</>;
};

export default ZenDeskWidget;
