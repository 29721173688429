import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ChevronDown } from 'react-feather';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';

import { FieldStyles } from './FieldStyles';
import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

export type SelectFieldProps = FieldWrapperProps &
    React.SelectHTMLAttributes<HTMLSelectElement> & {
        isLoading?: boolean;
    };

export const SelectField = (props: SelectFieldProps) => {
    const { isLoading = false, label, error, className, ...selectProps } = props;
    const [showSpinner, setShowSpinner] = useState<boolean>(isLoading);

    useEffect(() => {
        if (isLoading) {
            setShowSpinner(isLoading);
        } else {
            setTimeout(() => {
                setShowSpinner(isLoading);
            }, 1200);
        }
    }, [isLoading]);

    return (
        <FieldWrapper label={label} error={error}>
            {(_props) => (
                <Fragment>
                    <select
                        {...selectProps}
                        {..._props}
                        className={classNames(FieldStyles.field, className, {
                            error: Boolean(error),
                        })}
                        disabled={showSpinner}
                    >
                        {props.children}
                    </select>

                    <div
                        aria-hidden
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-brand"
                    >
                        {showSpinner && <ActivityIndicator width="20px" />}
                        {!showSpinner && <ChevronDown size={24} strokeWidth={3} />}
                    </div>
                </Fragment>
            )}
        </FieldWrapper>
    );
};
