import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { selectLoginState } from '../../account/duck';

import { selectHomeDetails, requestHomeDetails } from 'common/features/store/duck/home/duck';
import { FeaturesState } from 'common/features/featuresReducer';

export const useHomeDetails = () => {
    const dispatch = useDispatch<any>();
    const store = useStore<FeaturesState>();
    const homeDetails = useSelector(selectHomeDetails);
    const isUserLoggedIn = useSelector(selectLoginState);

    const refreshHomeDetails = useCallback(() => {
        const home = store.getState().store.home;
        const userId = store.getState().store.account.userId;

        if (!userId || home.homeDetails || home.homeDetailsAsyncState.loading) {
            return;
        }

        return isUserLoggedIn && dispatch(requestHomeDetails());
    }, [store, dispatch, isUserLoggedIn]);

    useEffect(() => {
        refreshHomeDetails();
    }, [refreshHomeDetails]);

    return homeDetails;
};
