import { useState, useEffect, useCallback } from 'react';
import { copyTextToClipboard } from 'utils';

export const useCopyToClipboard = (timeout = 2000): [boolean, (textToCopy: string) => void] => {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const copyText = useCallback((textToCopy: string) => {
        copyTextToClipboard(textToCopy);
        setIsCopied(true);
    }, []);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, timeout);
        }
    }, [isCopied, timeout]);

    return [isCopied, copyText];
};
