export const flattenObject = <T extends {}, U>(
    data: T,
    flattened: Record<string, U> = {},
    flatKey = ''
) => {
    Object.entries(data).forEach(([key, value]) => {
        const newFlatKey = `${flatKey}${key}`;
        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
            flattenObject(value, flattened, `${newFlatKey}.`);
        } else {
            flattened[newFlatKey] = value as U;
        }
    });
    return flattened;
};
