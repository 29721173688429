import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { UserContent } from 'components/user-content/UserContent';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import React, { useContext, useEffect } from 'react';
import { chooseProgramStyles as styles } from '../styles/ChooseProgramStyles';
import {
    choosePaperOnlyThunk,
    selectBillingSelection,
    selectBillingSelectionAsyncState,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch, useSelector } from 'react-redux';
import { FormBtn } from 'components/form-btn';
import { useHistory } from 'react-router';
import { selectSelectedOrgId } from 'common/features/store/duck/home/duck';
import { Routes } from 'routes';
import { useRootSelector } from 'rootStore';
import { BillingSelection } from 'common/api/e-comm/payment-types';

export const OfflineProgramBody = () => {
    const { contentfulEntry } = useContext(ContentFullEnrollmentContext) as unknown as {
        contentfulEntry: OrganizationEnrollmentV2;
    };
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const orgId = useSelector(selectSelectedOrgId);
    const billingSelection = useRootSelector(selectBillingSelection);
    const billingSelectionAsyncState = useRootSelector(selectBillingSelectionAsyncState);

    const onClick = async () => {
        if (!orgId) {
            throw new Error('invalid organizationId');
        }
        dispatch(choosePaperOnlyThunk(orgId));
    };

    useEffect(() => {
        if (billingSelection === BillingSelection.Check) {
            history.replace(Routes.EnrollV2CompletedPaperOnly);
        }
    }, [billingSelection, history]);

    return (
        <div data-testid="offline_program_body">
            <UserContent
                className={styles.stepSubtitle}
                content={contentfulEntry?.offlineProgramSubtitle}
            />
            <div className={styles.buttonContainer}>
                <FormBtn
                    onClick={onClick}
                    disabled={!orgId}
                    className={styles.button}
                    isSubmitting={billingSelectionAsyncState.loading}
                    data-testid="submit-btn"
                >
                    <span>{contentfulEntry?.offlineProgramCtaText}</span>
                </FormBtn>
            </div>
        </div>
    );
};
