import { AnyAction, Dispatch } from 'redux';

import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';

import { CategoryData } from 'common/api/categories/models/CategoryData';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';

export type Group = {
    group_id: string;
    display_name: string;
    count: number;
    data: object;
    // NOTE: We might not need to define these -- we don't care
    children: Array<any>;
    parents: Array<any>;
};

export type Category = {
    name: string;
    id: string;
    iconUrl: string;
    ecommId: number;
    earnType?: EarnTypeConstructorFilter;
    // NOTE: Extend type if needed
};

export const emptyCategory: Category = {
    name: '',
    id: '',
    iconUrl: '',
    ecommId: -1,
};

export type MetaData = {
    lastupdated: number;
};

export type ConstructorCategoryStateType = {
    categories: Category[];
    metadata: MetaData;
};

const ConstructorCategoryState = {
    categories: [] as Category[],
    metadata: { lastupdated: 0 },
};

const { reducer: categories, update } = createSlice(ConstructorCategoryState, 'DATA/CATEGORIES');

const getCategoriesThunk =
    (
        getCategories: () => Promise<
            { groups: Group[]; ecommCategories: CategoryData[] } | undefined
        >,
        force?: boolean
    ) =>
    async (dispatch: Dispatch, getState: () => FeaturesState) => {
        const ElapsedTime = 20 * 60 * 60;
        const data = getState().store.data.categories;

        if (data.metadata.lastupdated + ElapsedTime < new Date().getTime() || !!force) {
            const result = await getCategories();
            if (result && result.groups && result.ecommCategories) {
                dispatch(
                    update({
                        categories: groupToCategory(result.groups, result.ecommCategories),
                        metadata: { lastupdated: new Date().getTime() },
                    })
                );
            }
        }
    };

// selectors
const selectCategories = (s: FeaturesState) => s.store.data.categories.categories;

// util functions
const groupToCategory = (groups: Group[], eCommCategories: CategoryData[]): Category[] => {
    const categoryMap = eCommCategories.reduce((acc, category) => {
        const { name, ...rest } = category;

        return { ...acc, [name]: category };
    }, {} as Record<string, CategoryData>);

    return groups.map((group: Group) => ({
        name: group.display_name,
        id: group.group_id,
        iconUrl: categoryMap[group.display_name]?.iconUrl,
        ecommId: categoryMap[group.display_name]?.id,
    }));
};

export { categories, getCategoriesThunk, selectCategories };
