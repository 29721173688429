import React, { useEffect } from 'react';

import {
    useContinueOrgEnrollment,
    OrganizationEnrollmentProgress,
} from '../components/useContinueOrgEnrollment';

import { LoadingScreen } from 'components/loading-screen/LoadingScreen';
import { withAuth } from 'features/auth/components/withAuth';

interface OrgEnrollmentContinueProps {
    onProgressLoaded: (progress: OrganizationEnrollmentProgress) => void;
}

export const OrgEnrollmentContinue = withAuth()((props: OrgEnrollmentContinueProps) => {
    const { onProgressLoaded } = props;
    const { getEnrollmentProgress, getOrgApprovedAlertIfNeeded } = useContinueOrgEnrollment();
    useEffect(() => {
        const loadProgress = async () => {
            const progress = await getEnrollmentProgress();
            await getOrgApprovedAlertIfNeeded(progress);
            if (progress.version === 1) {
                onProgressLoaded(progress);
            }
        };
        loadProgress();
    }, [getEnrollmentProgress, onProgressLoaded, getOrgApprovedAlertIfNeeded]);

    return <LoadingScreen />;
});
