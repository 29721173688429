import React from 'react';

export interface RoundBoxStyles {
    container: string;
    header: string;
    background: string;
}

export interface RoundedBoxProps {
    styles: RoundBoxStyles;
    heading: React.ReactNode;
    children: React.ReactNode;
}

export const RoundedBox = ({ styles, heading, children }: RoundedBoxProps) => {
    return (
        <div className={`border border-1 rounded-lg overflow-hidden mb-8 ${styles.container}`}>
            <div className={`relative top-0 w-full text-center p-2 ${styles.header}`}>
                {heading}
            </div>
            <div className={`py-5 ${styles.background}`}>{children}</div>
        </div>
    );
};
