import React from 'react';

import { FilterByGroupOption, FilterData } from './FilterBy';
import { SortByOption } from './SortBy';
import { SortByDropdownMenu } from './SortByDropdownMenu';
import { MobileSortDialog } from './MobileSortDialog';
import { MobileFilterDialog } from './MobileFilterDialog';

import { createStyles } from 'utils/createStyle';
import { useRootSelector } from 'common/features/featuresReducer';
import ResponsiveContainer, {
    ContainerType,
} from 'components/responsive-containers/ResponsiveContainer';

// styles
const styles = createStyles({
    mainContainer: [
        'grid w-full mb-1',
        {
            gridTemplateColumns: 'auto max-content',
        },
    ],
    sortContainer: [
        'text-right',
        {
            minWidth: 300,
        },
    ],
});

interface FilterProps {
    categories: FilterByGroupOption[];
    currentFilter: FilterData;
    setFilters: (data: FilterData) => void;
}

interface SortProps {
    sortOptions: SortByOption[];
    sortedBy: SortByOption;
    setSortedBy: (data: SortByOption) => void;
}

export interface SortAndFilterProps extends FilterProps, SortProps {
    showAZFilter?: boolean;
}

export const DesktopSortAndHeaderSection = ({ sortOptions, setSortedBy, sortedBy }: SortProps) => {
    const total = useRootSelector((s) => s.store.searchGrid.total);
    return (
        <ResponsiveContainer For={ContainerType.desktop} defaultVisibility>
            <div className="mx-8 lg:mx-16 2xl:mx-28 hidden sm:block items-center">
                <div className="flex items-center justify-between">
                    <div className="results">
                        <span className="text-brand-dark font-semibold">
                            Filter & Sort <span className="font-normal">{total} Results</span>
                        </span>
                    </div>
                    <div className={styles.sortContainer}>
                        <SortByDropdownMenu
                            sortOptions={sortOptions}
                            setSortedBy={setSortedBy}
                            sortedBy={sortedBy}
                        />
                    </div>
                </div>
            </div>
        </ResponsiveContainer>
    );
};

export const BaseSortAndHeaderSection = ({ children }: React.PropsWithChildren) => {
    return <div className={styles.mainContainer}>{children}</div>;
};

export const SortAndHeaderSection = ({
    // props for filter
    categories,
    currentFilter,
    setFilters,

    // props for sort
    sortOptions,
    sortedBy,
    setSortedBy,
    showAZFilter = false,
}: SortAndFilterProps) => {
    return (
        <BaseSortAndHeaderSection>
            <ResponsiveContainer For={ContainerType.mobile}>
                <div className="block sm:hidden text-right w-full">
                    <div className="inline-block flex justify-evenly">
                        <MobileFilterDialog
                            showAZFilter={showAZFilter}
                            groups={categories}
                            data={currentFilter}
                            onChange={setFilters}
                        />
                        <MobileSortDialog
                            options={sortOptions}
                            sortedByField={sortedBy}
                            onChange={(sortByName) => {
                                setSortedBy(sortByName);
                            }}
                        />
                    </div>
                </div>
            </ResponsiveContainer>
            <DesktopSortAndHeaderSection
                sortOptions={sortOptions}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
            />
        </BaseSortAndHeaderSection>
    );
};
// get rid of border from dropdown sort
