export enum ProgressState {
    UserCreated = 'UserCreated',
    TwoFactorSetup = 'TwoFactorSetup',
    OrgCreated = 'OrgCreated',
    OrgUpdated = 'OrgUpdated',
    LiteProgramSelected = 'LiteProgramSelected',
    PlusProgramSelected = 'PlusProgramSelected',
    SwitchToLiteProgram = 'SwitchToLiteProgram',
    PayByCheckSelected = 'PayByCheckSelected',
    PaperOnlySelected = 'PaperOnlySelected',
    VoidedCheckSelected = 'VoidedCheckSelected',
    CheckImageUploaded = 'CheckImageUploaded',
    AccountLinkedWithPlaid = 'AccountLinkedWithPlaid',
    EnrollmentStartedExistingAccount = 'EnrollmentStartedExistingAccount',
    SavedForLater = 'SavedForLater',
    PlaidStarted = 'PlaidStarted',
    PlaidFailure = 'PlaidFailure',
    PlaidSuccess = 'PlaidSuccess',
    ResetProgramType = 'ResetProgramType',
    AccountSetupComplete = 'AccountSetupComplete',
    'Organization Created' = 'Organization Created',
}
