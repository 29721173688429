import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useScrollToTop = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        const unsubscribe = history.listen(() => window.scrollTo(0, 0));
        return () => unsubscribe();
    }, [history]);
};
