/* TODO:
 * - We should remove the check for `isUserLoggedIn` and just call `refreshFavorites`.
 *   The check can be moved inside the `getFavoritesThunk` and shortcircuit the call
 *   if user is not logged in.
 */
import { useEffect, useMemo } from 'react';
import { useStore, useDispatch } from 'react-redux';

import { getFavoritesThunk } from 'common/features/store/duck/brands/duck';
import {
    rootL,
    FeaturesState as RootState,
    useRootSelector,
} from 'common/features/featuresReducer';
import { callIfNeeded, needsData } from 'common/modules/async-actions/thunk';
import { selectLoginState } from 'common/features/store/duck/account/duck';

const favoritesL = rootL.k('store').k('brands').k('favorites');
const favoritesAsyncStateL = rootL.k('store').k('brands').k('favoritesAsyncState');

export const useFavorites = () => {
    const { getState } = useStore<RootState>();
    const dispatch = useDispatch<any>();
    const favorites = useRootSelector(favoritesL.get);
    const favoritesAsyncState = useRootSelector(favoritesAsyncStateL.get);
    const isUserLoggedIn = useRootSelector(selectLoginState);

    const refreshFavorites = useMemo(
        () =>
            callIfNeeded(
                getFavoritesThunk,
                needsData({ state: favoritesAsyncStateL.get(getState()), maxAge: 1000 * 60 * 10 })
            ),
        [getState]
    );

    useEffect(() => {
        // NOTE: Only fetch favorites if user is logged-in
        if (isUserLoggedIn) {
            dispatch(refreshFavorites());
        }
    }, [dispatch, refreshFavorites, isUserLoggedIn]);

    return { favorites, favoritesAsyncState };
};
