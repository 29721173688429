import React, { useCallback, useContext, useEffect } from 'react';
import { Formik } from 'formik';

import { StepWrapperProps, StepWrapper } from '../../enrollment/steps/StepWrapper';
import { orgNameValidator } from '../../enrollment/steps/validators';
import { EnrollmentContext } from '../context';

import { flattenObject } from 'common/utils/flattenObject';
import { Btn } from 'components/btn/Btn';
import { FormikTextField } from 'components/fields/FormikTextField';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { useValidation } from 'modules/form-validation/useValidation';
import { Organization } from 'common/api/crm/models/Organization';

export interface LitePlusOrganizationStepProps extends StepWrapperProps {}

export const LitePlusOrganizationStep = (props: LitePlusOrganizationStepProps) => {
    const { organization, setOrganization, goToNextStep } = useContext(EnrollmentContext);

    const validateAndSubmit = useValidation<Organization>((errors) =>
        Object.keys(flattenObject(errors))
    );

    const _onSubmit = useCallback(
        async (values: Organization) => {
            setOrganization({
                ...organization,
                name: values.name,
            });
            goToNextStep();
        },
        [organization, setOrganization, goToNextStep]
    );

    // focus first form field
    useEffect(() => document.querySelector('input')?.focus(), []);

    return (
        <StepWrapper {...props}>
            <Formik initialValues={organization} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-2xl mx-auto mt-8"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <div className="text-brand-dark text-2xl font-bold">
                            What is the name of your organization?
                        </div>
                        <FieldSpacer />
                        <FormikTextField
                            label="Organization Name"
                            name="name"
                            validate={orgNameValidator}
                        />
                        <FieldSpacer />
                        <div className="text-center">
                            <Btn type="submit" className="w-44">
                                Let's Begin
                            </Btn>
                        </div>
                    </form>
                )}
            </Formik>
        </StepWrapper>
    );
};
