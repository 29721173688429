import React, { createContext, useContext, useEffect, useState } from 'react';

export const RoktLauncherContext = createContext();

export function useRoktLauncher() {
    return useContext(RoktLauncherContext);
}

export function RoktLauncherContextProvider({ children, accountId, sandbox = false }) {
    const [launcher, setLauncher] = useState(null);

    useEffect(() => {
        (async () => {
            if (!accountId) {
                return;
            }
            // Guards against Rokt script being still loaded into the application when the context is created
            if (!launcher) {
                await new Promise((resolve) =>
                    window.Rokt
                        ? resolve()
                        : (document.getElementById('rokt-launcher').onload = resolve)
                );

                const launcherInstance = await window.Rokt.createLauncher({
                    accountId: accountId,
                    sandbox: sandbox,
                });
                setLauncher(launcherInstance);
            }
        })();

        return () => {
            if (launcher) {
                launcher.terminate();
            }
        };
    }, [accountId, sandbox]);

    if (!accountId) {
        return <>{children}</>;
    }

    return <RoktLauncherContext.Provider value={launcher}>{children}</RoktLauncherContext.Provider>;
}
