import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import FilterSection from '../../search/components/FilterSection';
import { createStyles } from 'utils/createStyle';
import { HeroSection } from 'features/homepage/sections';
import { HomepageContext, HomepageViewProps } from 'features/homepage/contexts/contentful';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'styles/settings';
import useMediaQueries from 'hooks/useMediaQueries';
import { useRemoteContent } from 'hooks/useRemoteContent';
import { LandingPageProps } from '../LandingPage';

const styles = createStyles({
    container: [
        'flex w-full',
        {
            minHeight: 30,
        },
    ],
    headerContainer: [
        'flex w-full',
        {
            flexDirection: 'column',
        },
    ],
    headerLink: [
        'flex, mx-8 lg:mx-16 2xl:mx-28',
        {
            marginTop: '20px',
        },
    ],
    searchGrid: ['mb-12 mx-4 lg:mx-16 2xl:mx-28'],
    footerLink: [
        'flex justify-center text-brand',
        {
            marginBottom: '56px',
            marginTop: '30px',
        },
    ],
    linkText: [
        'text-brand ',
        {
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: 600,
            textDecorationLine: 'underline',
        },
    ],
    arrowIcon: [
        {
            marginRight: '6px',
        },
    ],
    scrollToTop: [
        'cursor-pointer flex flex-col items-center justify-evenly bg-grey-accent text-brand font-semibold text-center',
        {
            width: '42px',
            height: '60px',
            borderRadius: '5px 0px 0px 5px',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 6px -1px rgb(0 0 0 / 10%)',
            position: 'fixed',
            bottom: '10%',
            fontSize: 12,
            right: 0,
        },
    ],
});

export const LandingPageHeader = ({
    landingPageType,
    returnRoute,
    renderBonusSwitch,
    fixedFilter,
    isFilterVisible = true,
}: LandingPageProps) => {
    const { heroes, loading: isRemoteContentLoading, heroId } = useRemoteContent(landingPageType);
    const [filterOrder, setFilterOrder] = useState<number>(2);
    const [returnLinkOrder, setReturnLinkOrder] = useState<number>(1);

    const headerContext: HomepageViewProps = {
        isAuthenticated: false,
        layout: [],
        heroes: heroes,
        componentMap: {},
        isLoading: isRemoteContentLoading,
    };

    const dynamicStyles = createStyles({
        filterBar: [
            '',
            {
                order: filterOrder,
            },
        ],
        returnLink: [
            '',
            {
                order: returnLinkOrder,
                marginBottom: '40px',
            },
        ],
    });

    const queries = useMemo(
        () => ({
            '(min-width: 640px) and (max-width: 1200px)': () => {
                setReturnLinkOrder(1);
                setFilterOrder(2);
            },
            '(max-width: 639px)': () => {
                setReturnLinkOrder(2);
                setFilterOrder(1);
            },
        }),
        []
    );

    useMediaQueries(queries);

    return (
        <HomepageContext.Provider value={headerContext}>
            {isRemoteContentLoading || !heroes[heroId] ? (
                <Skeleton className="h-80"></Skeleton>
            ) : (
                <HeroSection id={heroId}></HeroSection>
            )}

            <span className={styles.headerContainer}>
                <div className={styles.container} />

                <span className={dynamicStyles.filterBar}>
                    <FilterSection
                        visible={isFilterVisible}
                        renderBonusSwitch={renderBonusSwitch}
                        showAZFilter={true}
                        fixedFilter={fixedFilter}
                    />
                </span>
            </span>
        </HomepageContext.Provider>
    );
};
