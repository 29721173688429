import React, { createRef, useState, useCallback, Fragment, useRef } from 'react';
import { Heart, Lock, HelpCircle } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';

import { StepWrapperProps, StepWrapper } from './StepWrapper';
import { PlaidWrapper } from './PlaidButton/PlaidButton';

import { TiledButton } from 'components/tiled-button/TiledButton';
import { colors } from 'styles/settings';
import { Routes, getPath } from 'routes';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { Organization } from 'common/api/crm/models/Organization';
import { AchVerificationStatus, OrgAchAccount } from 'common/api/e-comm/ach/models/AchAccount';
import { Check } from 'components/custom-icons';
import { Btn } from 'components/btn/Btn';
import { PaperOnlyModal } from 'features/coordinator/components/PaperOnlyModal';
import { createStyles } from 'utils/createStyle';
import { openLoader, LoadingOverlay, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { setBillingSelection, BillingSelection } from 'common/api/e-comm/payment-types';
import { useAlert } from 'modules/alerts';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import useFeatureFlags, { FeatureFlagTypes } from 'common/hooks/useFeatureFlags';

interface ListItemConfig {
    icon?: React.ReactChild;
    text: string;
}

interface GetEarningsStepProps extends StepWrapperProps {
    organization: Organization;
}

const styles = createStyles({
    buttonContainer: [
        'flex flex-col items-center',
        'sm:flex-row sm:items-start sm:justify-between',
    ],
    conjunction: [
        'text-brand-dark text-xl font-semibold mb-6 flex items-center',
        'sm:h-44 sm:mr-6', // Match height and margin of tile button without description
    ],
});

export const GetEarningsStep = (props: GetEarningsStepProps) => {
    const { isEnabled } = useFeatureFlags();
    const buttonContainerRef = createRef<HTMLDivElement>();
    const params = new URLSearchParams(useLocation().search);
    const [isOAuth, setIsOAuth] = useState(Boolean(params.get('oauth_state_id')));
    const [modal, setModal] = useState<'upload-check' | 'paper-only' | null>(null);
    const {
        organization: { id: orgId = '' },
    } = props;

    const HeartIcon = (
        <div className="mr-2 -mt-0.5" aria-hidden="true">
            <Heart color={colors.brandAccent} size={32} />
        </div>
    );

    const listItems: ListItemConfig[] = [
        { icon: HeartIcon, text: 'Receive fundraising earnings via direct deposit' },
        {
            icon: HeartIcon,
            text: `Participants can pay with bank account,${
                isEnabled(FeatureFlagTypes.enable_debit_web) ? ' debit card,' : ''
            } credit card, or pay coordinator directly`,
        },
        { icon: HeartIcon, text: '95% of organizations choose to link a bank account' },
    ];

    const history = useHistory();
    const onAchAdded = useCallback(
        async <T,>(payload: T) => {
            withRetries(updateEnrollmentProgress)({
                state: ProgressState.AccountLinkedWithPlaid,
                organizationId: props.organization.id,
            });

            const { verificationStatus } = payload as unknown as OrgAchAccount;

            if (verificationStatus === AchVerificationStatus.PendingManualVerification) {
                history.replace(getPath(Routes.StartProgramCompleted, { status: 'deposits' }));
                return;
            }

            history.replace(getPath(Routes.StartProgramCompleted, {}));
        },
        [history, props.organization.id]
    );

    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();
    const onVoidedCheckClick = useCallback(async () => {
        openLoader(loader.current);
        const result = await withRetries(setBillingSelection)(orgId, BillingSelection.VoidedCheck);
        await closeLoader(loader.current);
        if (result.error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }
        withRetries(updateEnrollmentProgress)({
            state: ProgressState.VoidedCheckSelected,
            organizationId: orgId,
        });
        history.replace(Routes.StartProgramVoidedCheck);
    }, [alert, history, orgId]);

    const onPaperModalClose = () => setModal(null);
    const onPrimaryBtnClick = () =>
        history.replace(getPath(Routes.StartProgramCompleted, { status: 'paper-only' }));

    return (
        <Fragment>
            <StepWrapper heading={props.heading} subheading={props.subheading}>
                <h2 className={'text-2xl text-brand-dark font-semibold my-6'}>
                    Connecting your organization's bank account provides:
                </h2>

                <ul className={'text-brand-dark text-lg mb-6'}>
                    {listItems.map((listItem, i) => (
                        <li className="flex items-center mb-4" key={i}>
                            {listItem.icon && listItem.icon}
                            {listItem.text}
                        </li>
                    ))}
                </ul>

                <div ref={buttonContainerRef} className={styles.buttonContainer}>
                    <TiledButton
                        buttonClassName="justify-around o:w-56"
                        descriptionClassName="o:w-56"
                        buttonText={'Upload or email a voided check to connect a bank account'}
                        bannerText={'Most Popular'}
                        onClick={onVoidedCheckClick}
                        icon={<Check className={'mt-4 text-brand'} width={64} height={64} />}
                        trailingDescription="Best option if you need to get information from someone else in your organization, or prefer to share a voided check for direct deposit."
                    />
                    <div className={styles.conjunction}>or</div>
                    <PlaidWrapper
                        orgId={orgId}
                        onSuccess={onAchAdded}
                        isOAuth={isOAuth}
                        onExit={() => {
                            setIsOAuth(false);
                        }}
                    >
                        {(openPlaid, isPlaidReady) => (
                            <TiledButton
                                isSecondary
                                disabled={!isPlaidReady as boolean}
                                buttonClassName="o:w-56"
                                descriptionClassName="o:w-56"
                                buttonText={'Verify banking information online with Plaid'}
                                onClick={openPlaid}
                                icon={<Lock className={'mt-4 text-brand'} size={64} />}
                                bannerText={'Fastest'}
                                trailingDescription="We use Plaid for fast and secure direct deposit setup.  Verify with your bank login or account and routing number, protected by 256-bit encryption."
                            />
                        )}
                    </PlaidWrapper>
                </div>
            </StepWrapper>

            <hr className="border-grey-5 mb-8" />

            <div className="px-4 mb-12">
                <div className="flex justify-center mb-5 text-grey-2">
                    <div className="flex-shrink-0 mr-2">
                        <HelpCircle />
                    </div>
                    <h2>Is your organization unable to receive earnings through direct deposit?</h2>
                </div>

                <div className="text-center">
                    <Btn isSecondary onClick={() => setModal('paper-only')}>
                        Get information about another option
                    </Btn>
                </div>
            </div>

            <PaperOnlyModal
                orgId={orgId}
                isOpen={modal === 'paper-only'}
                onClose={onPaperModalClose}
                primaryBtnText="Continue with a Paper Only Program"
                onBtnPrimaryClick={onPrimaryBtnClick}
                secondaryBtnText="No, I can link a bank account"
                onBtnSecondaryClick={onPaperModalClose}
            />

            <LoadingOverlay ref={loader} />
        </Fragment>
    );
};
