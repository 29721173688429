import { Address, emptyAddress } from 'common/api/models/Address';

export type OrgPeople = 10 | 50 | 200 | 500 | 1000 | 1500;

export enum PlanType {
    Lite = 'Lite',
    Plus = 'Plus',
}
export interface Organization {
    id?: string;
    name: string;
    address: Address;
    phoneNumber: string;
    pin: string | null;
    organizationTypeId: string;
    numberOfFamilies: number | null;
    fundraisingPurpose: string | null;
    website: string | null;
    answers?: { [key: string]: boolean };
    people?: OrgPeople;
    planType?: PlanType;
    isP2PInviteEnabled?: boolean;
}

export const emptyOrganization: Organization = {
    name: '',
    address: emptyAddress,
    phoneNumber: '',
    pin: null,
    organizationTypeId: '',
    numberOfFamilies: null,
    fundraisingPurpose: null,
    website: null,
    isP2PInviteEnabled: false,
};
