import { getEndpointsForAppType } from './endpoints';

import { API_BASE, RAISE_RIGHT_APP_TYPE, OCP_APIM_SUBSCRIPTION_KEY } from 'common/env';

export const API_KEY = OCP_APIM_SUBSCRIPTION_KEY;

export const {
    API_URL,
    SECURED_API_URL,
    USERS_URL,
    SECURED_USERS_URL,
    LOGIN_URL,
    SECURED_LOGIN_URL,
    EMAIL_URL,
    TWO_FACTOR_URL,
    TWO_FACTOR_PHONE_URL,
    SECURED_TWO_FACTOR_PHONE_URL,
    ACCOUNT_URL,
    SECURED_ACCOUNT_URL,
    CRM_URL,
    SECURED_CRM_URL,
    ORGANIZATION_URL,
    SECURED_ORGANIZATION_URL,
    CRM_ORGS_URL,
    FAMILY_URL,
    FAMILY_ADDRESS_URL,
    FAMILY_ITERABLE_TOKEN_URL,
    WEB_ITERABLE_TOKEN_URL,
    FEATURE_FLAGS_URL,
    CRM_AFFILIATES_URL,
    E_COMM_URL,
    SECURED_E_COMM_URL,
    E_COMM_ORGS_URL,
    E_COMM_BRANDS_URL,
    E_COMM_PRODUCTS_URL,
    ALL_CATEGORIES_URL,
    PAYMENT_TYPES_URL,
    ACH_URL,
    ORDERS_URL,
    CREDIT_CARDS_URL,
    EARNINGS_URL,
    WALLET_URL,
    SECURED_WALLET_URL,
    WALLET_CARDS_URL,
    WALLET_CARDS_ARCHIVED_URL,
    SECURED_FULFILLMENT_URL,
    FULFILLMENT_ADDRESS_VALIDATION_URL,
    FULFILLMENT_SHIPPING_URL,
    SEARCH_URL,
} = getEndpointsForAppType(API_BASE, RAISE_RIGHT_APP_TYPE);

export enum ContentfulContentTypeId {
    PromotionLanding = 'promotionLanding',
    ExternalLink = 'externalLink',
    InternalLink = 'internalLink',
    BrandList = 'brandList',
    Brand = 'brand',
}

export enum StatusCode {
    Ok = 200,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    Conflict = 409,
    Gone = 410,
    PayloadTooLarge = 413,
    UnprocessableEntity = 422,
    TooManyRequests = 429,
    ServerError = 500,
}

export const CARD_AMOUNT_MAX = 9999.99;
export const CARD_NAME_MAX_LENGTH = 24;
