import React from 'react';
import { useSpring, animated, UseSpringProps } from 'react-spring';

import { useOutsideClick } from 'hooks/useOutsideClick';

export interface SlideComponentProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    backDropClasses?: string;
    contentClasses?: string;
    backDropSpringConfig?: UseSpringProps;
    contentSpringConfig?: UseSpringProps;
    direction: 'right' | 'left' | 'top' | 'bottom';
    children: React.ReactNode;
}

const translateFunctions = {
    right: (x: number) => `translate3d(${x}%, 0, 0)`,
    left: (x: number) => `translate3d(${-x}%, 0, 0)`,
    bottom: (x: number) => `translate3d(0, ${x}%, 0)`,
    top: (x: number) => `translate3d(0, ${-x}%, 0)`,
};

export const SlideComponent = ({
    open,
    setOpen,
    backDropClasses = '',
    contentClasses = '',
    backDropSpringConfig,
    contentSpringConfig,
    direction = 'right',
    children,
}: SlideComponentProps) => {
    const { x, ...props } = useSpring(contentSpringConfig as UseSpringProps);
    const { opacity: containerOpacity } = useSpring(backDropSpringConfig as UseSpringProps);

    const ref = useOutsideClick<HTMLDivElement>(() => setOpen(false));

    return (
        <div>
            <animated.div
                className={backDropClasses}
                style={{
                    opacity: containerOpacity,
                    pointerEvents: open ? 'initial' : 'none',
                }}
            />
            <div ref={ref}>
                <animated.div
                    className={contentClasses}
                    style={{
                        ...props,
                        transform: x.to(translateFunctions[direction]),
                        pointerEvents: open ? 'initial' : 'none',
                    }}
                >
                    {children}
                </animated.div>
            </div>
        </div>
    );
};
