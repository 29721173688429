import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTag } from '@fortawesome/pro-regular-svg-icons';

import { faGift } from '@fortawesome/pro-light-svg-icons';
import { createStyles } from 'utils/createStyle';
import { requestCartItems, resetCart, updateCart } from 'common/features/store/duck/cart/duck';
import { removeItemFromCart } from 'common/api/e-comm/cart/lineitem';
import { CartLineItem } from 'common/api/e-comm/models/CartLineItem';
import { ProductType } from 'common/api/e-comm/models/Product';
import { monetizeWithDecimal } from 'common/utils/strings';
import { useCardsFromWallet } from 'hooks/useCardsFromWallet';
import { getWalletCardDisplayName } from 'features/order-summary/utils';
import { FeatureFlag } from 'components/feature-flag';

const styles = createStyles({
    listItemContent: [
        'p-2 grid gap-2 w-full',
        {
            gridTemplateColumns: 'max-content auto max-content',
        },
    ],
});

export interface CartLineItemsProps {
    lineItems: CartLineItem[];
    loading: boolean;
}

const getDescriptionForProductType = (type: ProductType) => {
    switch (type) {
        case ProductType.EGiftCard:
            return 'eGift';
        case ProductType.GiftCard:
            return 'Physical';
        case ProductType.Reload:
            return 'Reload';
    }
};

export const CartLineItems = ({ lineItems, loading }: CartLineItemsProps) => {
    const dispatch = useDispatch<any>();
    const itemsInCart = lineItems.length > 0;
    const walletCards = useCardsFromWallet();

    const onRemoveItem = async (lineItem: CartLineItem) => {
        (async () => {
            const response = await removeItemFromCart(lineItem.id);
            if (response.response && response.response.ok) {
                if (response.data) {
                    dispatch(updateCart({ cart: response.data }));
                } else {
                    dispatch(resetCart());
                }
            }
            await requestCartItems()(dispatch);
        })();
    };

    if (loading && !itemsInCart) {
        return (
            <div>
                <ul>
                    <li className="mb-2">
                        <Skeleton className="w-full h-20" />
                    </li>
                    <li className="mb-2">
                        <Skeleton className="w-full h-20" />
                    </li>
                    <li className="mb-2">
                        <Skeleton className="w-full h-20" />
                    </li>
                </ul>
            </div>
        );
    }

    return (
        <div>
            <ul>
                {lineItems.map((lineItem) => {
                    return (
                        <li key={lineItem.id} className="mb-2">
                            <div className="relative">
                                <div
                                    className={styles.listItemContent}
                                    style={{ visibility: loading ? 'hidden' : 'visible' }}
                                >
                                    <div className="flex w-12 items-center justify-center">
                                        {lineItem.brand && (
                                            <img src={lineItem.brand.imageUrl} className="w-full" />
                                        )}
                                        {lineItem.product.isLocalProduct && (
                                            <FontAwesomeIcon
                                                className={'text-brand'}
                                                icon={faTag}
                                                size="lg"
                                            />
                                        )}
                                    </div>
                                    <div className="flex items-center justify-left truncate">
                                        <div className="block h-full w-full">
                                            <div className="flex">
                                                <div className=" w-full text-left text-xs align-middle text-brand-dark font-bold">
                                                    <span
                                                        className="inline-block align-middle truncate w-full"
                                                        style={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '105px',
                                                            minWidth: '100%',
                                                        }}
                                                    >
                                                        {lineItem.product.isLocalProduct
                                                            ? lineItem.product.name
                                                            : truncateName(
                                                                  lineItem.brand?.name || ''
                                                              )}
                                                        <FeatureFlag flagKey="enable_custom_card_names">
                                                            {getWalletCardDisplayName(
                                                                lineItem,
                                                                walletCards
                                                            )
                                                                ? ` - ${getWalletCardDisplayName(
                                                                      lineItem,
                                                                      walletCards
                                                                  )}`
                                                                : ''}
                                                        </FeatureFlag>
                                                    </span>
                                                </div>
                                                <div className=" w-auto text-right text-xs align-middle text-brand-dark font-bold">
                                                    <span className="inline-block align-middle truncate w-auto">
                                                        {monetizeWithDecimal(lineItem.subtotal)}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="text-xs text-left font-bold">
                                                <div className="text-grey-1">
                                                    <div className="inline-block">
                                                        {lineItem.giftDetail && (
                                                            <FontAwesomeIcon
                                                                icon={faGift}
                                                                style={{ fontSize: '12px' }}
                                                                className="mr-1"
                                                            />
                                                        )}
                                                        {getDescriptionForProductType(
                                                            lineItem.product.type
                                                        )}{' '}
                                                        -{' '}
                                                        <span>
                                                            {monetizeWithDecimal(lineItem.price)}
                                                        </span>{' '}
                                                        (x{lineItem.quantity})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-start justify-end">
                                        <button
                                            className=" text-sm text-grey-2 font-bold cursor-pointer"
                                            style={{ marginTop: '-0.5px' }}
                                            onClick={() => onRemoveItem(lineItem)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} size="1x" />
                                        </button>
                                    </div>
                                </div>
                                {loading && <Skeleton className="absolute inset-0" />}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const truncateName = (str: string, cutoff = 15): string => {
    if (str.length <= cutoff) {
        return str;
    }
    return str.substring(0, cutoff) + '...';
};
