import React, { useState, useEffect } from 'react';
import { useRootSelector } from 'rootStore';

import { EntryFields, Entry, Asset } from 'contentful';

import { selectUserId } from 'common/features/store/duck/account/duck';
import RemoteConfigWeb from 'config/RemoteConfigWeb';
import { contentfulApi } from 'common/api/utils/contentful';
import {
    HomePageLayout,
    HeroComponent,
    WebComponent,
    FullAd,
} from 'common/api/store/models/HomePageLayout';

export enum BackgroundThemes {}

export interface HeroAd {
    id: string;
    desktopImage?: string;
    mobileImage?: string;
    imageDescription?: EntryFields.Text;
    url?: EntryFields.Symbol;
    title?: string;
}

export interface Hero {
    id: string;
    title: string;
    heroVisualTheme: BackgroundThemes;
    heroLayout: 'ImageLeft' | 'ImageRight' | 'CenteredHeadline';
    headerText?: string;
    bodyText?: EntryFields.RichText;
    buttonText?: string;
    buttonUrl?: string;
    heroImage?: string;
}

export interface HomepageViewProps {
    isAuthenticated: boolean; // contains user's authenticated state
    layout: string[]; // contains the order that the components need to be rendererd in
    // used to provide contentful information to the different heros
    heroes: { [key: string]: Hero | HeroAd };
    // a mapping from contentful-id to component-name -- used by the HomepageRenderer
    componentMap: { [key: string]: string };
    // a global loading state for the entire page
    isLoading: boolean;
}

const initData: HomepageViewProps = {
    isAuthenticated: false,
    layout: [],
    heroes: {},
    componentMap: {},
    isLoading: true,
};

export const HomepageContext = React.createContext<HomepageViewProps>(initData);

interface LayoutConfig {
    authHomePageLayoutId: string;
    unAuthHomePageLayoutId: string;
}

/* The Homepage __REQUIRES__ the following values to be present in Firebase
 * - auth_web_homepage_entry_id :: contains the contentful-entry-id which will
 *   point to a Homepage layout config for authenticated users.
 *
 * - unauth_web_homepage_entry_id :: contains the contentful-entry-id which will
 *   point to a Homepage layout config for non-authenticated users.
 *
 * - web_component_mappings :: is a map that points to place-holder entries in the homepage config
 *   that renference the __place__ where our components(not coming from contentful) are to be rendered.
 *   For example:  Bonus, Featured, Categories and Favorite Sections.
 */
const useLoadConfigFromFirebase = () => {
    // fetch from firebase
    const [layoutConfig, setLayoutConfig] = useState<LayoutConfig | null>(null);
    const [componentMap, setComponentMap] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            await RemoteConfigWeb.initialize();

            const authHomePageLayoutId = RemoteConfigWeb.getRemoteValue(
                'auth_web_homepage_entry_id'
            ).asString();
            const unAuthHomePageLayoutId = RemoteConfigWeb.getRemoteValue(
                'unauth_web_homepage_entry_id'
            ).asString();
            const componentMappings =
                RemoteConfigWeb.getRemoteValue('web_component_mappings').asString();

            setLayoutConfig({ authHomePageLayoutId, unAuthHomePageLayoutId });

            if (componentMappings) {
                try {
                    setComponentMap(JSON.parse(componentMappings));
                } catch (e) {
                    console.error(
                        'DEBUG [web_component_mappings]: Error during parsing the value, check firebase'
                    );
                }
            }
            setLoading(false);

            // NOTE(Diego): Maybe also add an errors state -- incase some of these values are missing
        })();
    }, []);

    return { layoutConfig, componentMap, loading };
};

const isFullAd = (data: Entry<HeroComponent | FullAd>): data is Entry<FullAd> => {
    return (data as Entry<FullAd>).fields?.desktopImage !== undefined;
};

const parseFullAd = (data: Entry<FullAd>): HeroAd => {
    const { imageDescription, url, title } = data.fields;
    const desktopImage = (data.fields?.desktopImage as Asset).fields.file.url;
    const mobileImage = (data.fields?.mobileImage as Asset).fields?.file.url;
    return {
        id: data.sys.id,
        desktopImage,
        mobileImage,
        imageDescription,
        url,
        title,
    } as HeroAd;
};

// Data transformation from contentful interface to an easier interface
// for our components to use.
export const toHeroData = (data: Entry<HeroComponent | FullAd>) => {
    if (isFullAd(data)) {
        return parseFullAd(data);
    }

    const { heroImage, ...heroData } = (data as Entry<HeroComponent>).fields;
    const heroImage_ = (heroImage as Asset)?.fields?.file.url;
    return {
        id: data.sys.id,
        ...heroData,
        heroImage: heroImage_,
    };
};

/* Loads the appropriate contentful Entry based on the user's authenticated state.
 * It utilizes the `layoutConfig` to utilize the authHomePageLayoutId or unauthHomePageLayoutId.
 *
 * It _returns_ the `layout` needed by the HomePageRenderer to render the different sections in the
 * right order.
 * It _returns_ the `heroes` needed by the HeroRenderer to render the dynamic content coming
 * from Contentful.
 */
const useLoadContentfulLayoutConfigs = (
    isAuthenticated: boolean,
    layoutConfig: LayoutConfig | null,
    loading: boolean
) => {
    const [heroes, setHeroes] = useState<{ [key: string]: Hero | HeroAd }>({});
    const [layout, setLayout] = useState<string[]>([]);
    const [isContentfulLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // only call to contentful _after_ we have loaded our values from
        // firebase.
        if (!loading) {
            const config = isAuthenticated
                ? layoutConfig?.authHomePageLayoutId
                : layoutConfig?.unAuthHomePageLayoutId;

            contentfulApi
                .getEntry<HomePageLayout>(config as string)
                .then((response) => {
                    const blocks = response.data?.fields.block || [];
                    // transform contentful data to an interface that will be easy to use
                    const parsedSections = blocks?.map(
                        (block: Entry<WebComponent | HeroComponent | FullAd>) =>
                            toHeroData(block as Entry<HeroComponent | FullAd>)
                    ) as unknown as Hero[];

                    // remove sections that are of type WebComponent
                    const heroesList = parsedSections.filter(
                        (hero: Hero | HeroAd) =>
                            (hero as Hero).heroVisualTheme || (hero as HeroAd).url
                    );

                    // transform the data into heroN: Hero for later use in rendering
                    setHeroes(
                        heroesList.reduce(
                            (acc: { [key: string]: Hero | HeroAd }, hero: Hero | HeroAd) => {
                                acc[hero.id] = hero;
                                return acc;
                            },
                            {}
                        )
                    );

                    // parse the layout order -- used later during rendering
                    const layout_ = parsedSections?.map(({ id }: Hero | HeroAd) => id);

                    setLayout(layout_);
                })
                .finally(() => {
                    // set loading to false incase error happens or not
                    setLoading(false);
                });
        }
    }, [loading, isAuthenticated, layoutConfig]);

    return { heroes, layout, isLoading: isContentfulLoading };
};

const useLoadHomepageLayoutInfo = (isAuthenticated?: boolean) => {
    // load from firebase
    const { layoutConfig, loading, componentMap } = useLoadConfigFromFirebase();

    // fetch from contentful
    const { heroes, layout, isLoading } = useLoadContentfulLayoutConfigs(
        Boolean(isAuthenticated),
        layoutConfig,
        loading
    );

    return { layout, heroes, componentMap, isLoading };
};

export const HomepageView = ({ children }: React.PropsWithChildren) => {
    // NOTE: we should in theory hit the bff to check if session still valid?
    const isAuthenticated = Boolean(useRootSelector(selectUserId));
    const heroesAndlayout = useLoadHomepageLayoutInfo(isAuthenticated);
    const context = {
        isAuthenticated,
        ...heroesAndlayout,
    };

    return <HomepageContext.Provider value={context}>{children}</HomepageContext.Provider>;
};
