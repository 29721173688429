import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { SECURED_E_COMM_URL } from 'common/api/config';
import { CartResponse } from 'common/api/e-comm/cart/lineitem/models/CartItems';
import { CartLineItem } from 'common/api/e-comm/models/CartLineItem';
import { ReloadCartItem } from 'common/api/e-comm/models/ReloadCartItem';

export const getCartItems = () =>
    withTokensIfNeeded<CartResponse>(`${SECURED_E_COMM_URL}/cart`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const addReloadItemToCart = (item: ReloadCartItem) =>
    withTokensIfNeeded<CartResponse>(`${SECURED_E_COMM_URL}/Cart/reload`, {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF': '1',
        },
    });

export interface IFincen {
    accountId: number;
    address: string | null;
    address2: string | null;
    alias: string | null;
    city: string | null;
    dateOfBirthDt: string | null;
    driversLicense: string | null;
    firstName: string | null;
    id: number;
    isActive: boolean;
    lastName: string | null;
    licenseIssueState: string | null;
    phone: string | null;
    reason: string | null;
    state: string | null;
    streetName: string | null;
    submitDt: string | null;
    suffix: string | null;
    zip: string | null;
}

export interface IFincenResponse {
    fincenResponse: IFincen;
    placeOrderResult: string | null;
}

export const validateCart = () =>
    withTokensIfNeeded<IFincenResponse, IFincenResponse>(
        `${SECURED_E_COMM_URL}/Cart/validate?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF': '1',
            },
        }
    );

export enum UserCartType {
    DEFAULT = 'Default', // normal cart
    BUYNOW = 'BuyNow',
}

export const createTemporaryCartForBuyNow = () =>
    withTokensIfNeeded<CartResponse>(`${SECURED_E_COMM_URL}/Cart`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF': '1',
        },
        body: JSON.stringify({ userCartType: UserCartType.BUYNOW }),
    });

interface EditCartLineItem extends Pick<CartLineItem, 'id' | 'quantity'> {}

export const editCartLineItem = ({ id, quantity }: EditCartLineItem) =>
    withTokensIfNeeded<CartResponse>(`${SECURED_E_COMM_URL}/Cart/lineItem/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({ quantity }),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF': '1',
        },
    });
