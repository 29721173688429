import { temporaryStorage } from 'common/utils/persistentStorage';
import { SearchApiQueryParams } from '../search';
import { constructorInstance } from './constructor-instance';
import {
    convertConstructorFacetToAzureFacets,
    convertConstructorResultToBrandSearchResponse,
    getAllowedStates,
    getCategoriesFromFilters,
    getEarningTypesFromFilters,
    getFirstLetterFilter,
    getIdsFromFilters,
    getIsFeatured,
    getIsFeaturedFilters,
    getOnBonus,
    getOrganizationIds,
    getPriceFilters,
    getShipToHomeFilters,
    getSupportedTypesFilters,
    isVirtualOnlyRequestEnabled,
    getOnBonusFilterByEarnType,
} from './util';

const PAGINATION_LIMIT = 200;

interface ConstructorSearchFilters {
    AllowedStates?: string[];
    group_id?: string[]; // Categories
    FirstLetter?: string[];
    IsAvailableForVirtualOnly?: string[];
    IsFeatured?: string[];
    IsLocalProduct?: string[];
    Lists?: string[];
    IsOnBonus?: string[];
    OrganizationId?: string[];
    Price?: string[];
    IsShipToHomeEligible?: string[];
    SupportedTypes?: string[];
    IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus?: string[];
    IsAffiliateOfferOnBonus?: string[];
}

function getConstructorSearchObjectFromAzureSearchRequest(
    azureSearchParams: Partial<SearchApiQueryParams>
): ConstructorSearchFilters {
    const supportedFilters = getSupportedTypesFilters(azureSearchParams.filter);
    const priceFilters = getPriceFilters(azureSearchParams.filter);
    const allowedStates = getAllowedStates(azureSearchParams.filter);
    const earningsTypes = azureSearchParams.earnType
        ? [azureSearchParams.earnType]
        : getEarningTypesFromFilters(azureSearchParams.filter);

    const searchTerm = azureSearchParams.search;
    let firstLetterFilter;
    if (searchTerm) {
        firstLetterFilter = getFirstLetterFilter(searchTerm);
    }

    const shipToHomeFilters = getShipToHomeFilters(azureSearchParams.filter);

    const filters: ConstructorSearchFilters = {
        // IsFeatured: [],
        // investigate: ship to coordinator flag
        // IsLocalProduct: [],
        // Lists: [],

        OrganizationId: getOrganizationIds(azureSearchParams.filter),
        group_id: getCategoriesFromFilters(azureSearchParams.filter),
        ...(isVirtualOnlyRequestEnabled(azureSearchParams.filter)
            ? { IsAvailableForVirtualOnly: ['True'] }
            : {}),
        ...(allowedStates ? { AllowedStates: [...allowedStates] } : {}),
        ...(supportedFilters.length > 0 ? { SupportedTypes: [...supportedFilters] } : {}),
        ...(getOnBonus(azureSearchParams.filter) ? getOnBonusFilterByEarnType(earningsTypes) : {}),
        ...(shipToHomeFilters.length > 0 ? { IsShipToHomeEligible: [...shipToHomeFilters] } : {}),
        ...(priceFilters ? { Price: [...priceFilters] } : {}),
        ...(firstLetterFilter ? { FirstLetter: [...firstLetterFilter] } : {}),
        ...(getIsFeaturedFilters(azureSearchParams.filter) ? { IsFeatured: ['True'] } : {}),
        ...(earningsTypes ? { EarnType: [...earningsTypes] } : {}),
    };

    return filters;
}

const constructorSortFields = {
    MaxRebate: 'max_rebate',
    PopularityRank: 'relevance',
    Name: 'item_name',
};

const constructorSortDirections = {
    asc: 'ascending',
    desc: 'descending',
};

function getSortOrder(azureSortOption: string | undefined): { sortBy: string; sortOrder: string } {
    const [sortBy, sortOrder] = azureSortOption?.split(' ') || [];

    if (!sortBy) {
        return { sortBy: 'relevance', sortOrder: 'descending' };
    }

    const mappedSortBy =
        constructorSortFields[sortBy as keyof typeof constructorSortFields] || 'relevance';
    const mappedSortDirection =
        constructorSortDirections[sortOrder as keyof typeof constructorSortDirections] ||
        'descending';
    return { sortBy: mappedSortBy, sortOrder: mappedSortDirection };
}

export async function constructorSearchFacets(
    azureSearchParams: Partial<SearchApiQueryParams>
): Promise<any> {
    const response = await constructorInstance.browse.getBrowseResults(
        'group_id',
        'customerCategories',
        { resultsPerPage: 1 }
    );
    const mapped = convertConstructorFacetToAzureFacets(response);

    return { data: mapped };
}

export async function constructorSearch(azureSearchParams: Partial<SearchApiQueryParams>) {
    const filters = getConstructorSearchObjectFromAzureSearchRequest(azureSearchParams);
    const searchTerm = filters.FirstLetter || azureSearchParams.search;
    const { sortBy, sortOrder } = getSortOrder(azureSearchParams.orderby);

    let firstResult;
    const fullResults = [];
    try {
        let currentPage = 1;
        let totalResults = 0;

        do {
            const searchResult = await constructorInstance.search?.getSearchResults(
                (searchTerm as string) || 'All',
                { filters, resultsPerPage: PAGINATION_LIMIT, page: currentPage, sortBy, sortOrder }
            );
            if (!firstResult) {
                firstResult = searchResult;
            }
            fullResults.push(...searchResult.response.results);
            totalResults = searchResult?.response?.total_num_results || 0;
            currentPage++;
        } while (fullResults.length < totalResults);

        // FEC-1622: get the result_content for a search-term and store it for use
        // in the search-results page in web.
        process_refined_content(firstResult.response.refined_content);
    } catch (error) {
        console.error(error);
    }

    const mapped = convertConstructorResultToBrandSearchResponse({
        ...firstResult,
        response: fullResults,
    });

    // If the IsOnBonus filter is active then we should filter out results that are not
    // marked as onBonus. This could happen due to expiring bonuses and constructor index
    // not being up to date.
    if (filters.IsOnBonus) {
        mapped.value = mapped.value.filter((v) => v.IsOnBonus);
    }

    return {
        data: mapped,
        error: null,
    };
}

// FEC-1622: constructor refined-content
export type Refined_Content = {
    altText: string;
    assetUrl: string;
    body: string;
    ctaLink: string;
    ctaText: string;
    header: string;
};

type Refined_Content_Wrapper = { data: Refined_Content };
export const tempStorage = temporaryStorage<Refined_Content | undefined | null>(
    'refined_content',
    'v1',
    undefined,
    JSON.parse
);

const process_refined_content = (refined_content: Refined_Content_Wrapper[]) => {
    if (window && window.sessionStorage) {
        const content = refined_content.length === 0 ? null : refined_content[0].data;
        tempStorage.set(content);
    }
};
// FEC-1622: constructor refined-content

export async function constructorBrowse(azureSearchParams: Partial<SearchApiQueryParams>) {
    const isFeatured = getIsFeatured(azureSearchParams.filter);
    const constructorFilters =
        getConstructorSearchObjectFromAzureSearchRequest(azureSearchParams) || [];
    const filters: ConstructorSearchFilters = {
        ...constructorFilters,
        group_id: constructorFilters.group_id,
    };
    const { sortBy, sortOrder } = getSortOrder(azureSearchParams.orderby);
    const itemIds = getIdsFromFilters(azureSearchParams.filter);

    let filterValue: string;
    if (isFeatured) {
        filterValue = 'Filter-AllFeatured';
    } else if (constructorFilters.group_id?.length === 1) {
        filterValue = constructorFilters.group_id[0];
        filters.group_id = undefined;
    } else {
        filterValue = 'customerCategories';
    }

    let firstResult;
    const fullResults = [];
    try {
        let currentPage = 1;
        let totalResults = 0;

        do {
            let searchResult;

            const browseOptions = {
                resultsPerPage: PAGINATION_LIMIT,
                page: currentPage,
                sortBy,
                sortOrder,
                filters,
            };

            if (itemIds) {
                const chunkedItemIds = chunkArray(itemIds, 100);
                for (const chunk of chunkedItemIds) {
                    const chunkSearchResult =
                        await constructorInstance?.browse?.getBrowseResultsForItemIds(
                            chunk,
                            browseOptions
                        );
                    fullResults.push(...(chunkSearchResult.response?.results || []));
                }
            } else {
                // Check if the search filter is for featured items

                searchResult = await constructorInstance?.browse?.getBrowseResults(
                    'group_id',
                    filterValue,
                    browseOptions
                );
                fullResults.push(...(searchResult.response?.results || []));
            }

            if (!firstResult) {
                firstResult = searchResult;
            }

            totalResults = searchResult?.response?.total_num_results || 0;
            currentPage++;
        } while (fullResults.length < totalResults);
    } catch (error) {
        console.error(error);
    }

    const mapped = convertConstructorResultToBrandSearchResponse({
        ...firstResult,
        response: fullResults,
    });

    // If the IsOnBonus filter is active then we should filter out results that are not
    // marked as onBonus. This could happen due to expiring bonuses and constructor index
    // not being up to date.
    if (filters.IsOnBonus) {
        mapped.value = mapped.value.filter((v) => v.IsOnBonus);
    }

    if (filters.IsAffiliateOfferOnBonus) {
        mapped.value = mapped.value.filter((v) => v.IsAffiliateOfferOnBonus);
    }

    if (filters.IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus) {
        mapped.value = mapped.value.filter((v) => v.IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus);
    }

    return {
        data: mapped,
        error: null,
    };
}

function chunkArray(array: any[], size: number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        chunkedArray.push(chunk);
    }
    return chunkedArray;
}
