import React from 'react';
import classnames from 'classnames';
import { faChevronDown, faChevronUp, faHouse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { createStyles } from 'utils/createStyle';
import { NavItem } from '../nav-items';
import { colors } from 'styles/settings';
import type { Location } from 'history';
import { Routes } from 'routes';

export const collapsibleNavItemStyles = createStyles({
    parentNode: [
        'py-6',
        'px-2',
        'mx-2',
        {
            alignItems: 'center',
            color: colors.dashboardText,
            display: 'flex',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            height: '30px',
            cursor: 'pointer',
            width: '220px',

            '&:focus': {
                outline: 'none',
                backgroundColor: colors.lightGray,
            },
        },
    ],
    childNode: [
        'py-2',
        'relative',
        {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            color: colors.dashboardText,
            textDecoration: 'none',
        },
    ],
    childList: {
        paddingLeft: '56px',
    },
    boldOnHover: [
        {
            '&:hover': {
                fontWeight: 600,
            },
        },
    ],
    linkNoUnderline: {
        color: colors.dashboardText,
        textDecoration: 'none',
    },
    parentNodeSelected: {
        borderRadius: '16px',
    },
    parentHighlighted: {
        backgroundColor: colors.lightGray,
        borderRadius: '4px',
    },
    parentTitleSelected: {
        fontWeight: 600,
    },
    parentTitleUnSelected: {
        fontWeight: 400,
    },
    iconWrapper: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: colors.lightGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
    },
    icon: {
        color: colors.brand,
        height: '32px',
        width: '32px',
    },
    iconSelectedColor: {},
    parentNodeWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        '&:hover a, &:hover': {
            fontWeight: '600 !important',
        },
        '&:hover > span:last-child': {
            backgroundColor: `rgba(225, 241, 247, 0.4)`,
        },
    },
    titleWrapper: {
        flexGrow: 1,
        textAlign: 'left',
    },
    chevron: [
        {
            alignItems: 'center',
            borderRadius: '50%',
            display: 'flex',
            flexBasis: '32px',
            height: '32px',
            justifyContent: 'center',
            width: '32px',
        },
        {
            '&:hover': {
                backgroundColor: `rgba(225, 241, 247, 0.4)`,
            },
        },
    ],
    cheveronExpanded: {
        backgroundColor: colors.lightGray,
    },
    cheveronClosed: {},
    smallCircle: {
        backgroundColor: colors.brand,
        borderRadius: '50%',
        height: '8px',
        left: '-16px',
        position: 'absolute',
        top: '15px',
        width: '8px',
    },
    externalLinkIconWrapper: {
        backgroundColor: colors.dashboardBackground,
    },
    externalLinkIcon: {
        color: colors.link,
    },
});

const LinkVariation = ({
    route,
    title,
    openInNewTab,
    external,
}: {
    route: string;
    title: string;
    openInNewTab?: boolean;
    external?: boolean;
}) =>
    !external ? (
        <Link
            className={classnames([
                collapsibleNavItemStyles.linkNoUnderline,
                collapsibleNavItemStyles.boldOnHover,
            ])}
            to={route}
        >
            {title}
        </Link>
    ) : (
        <a
            className={classnames([
                collapsibleNavItemStyles.linkNoUnderline,
                collapsibleNavItemStyles.boldOnHover,
            ])}
            href={route}
            target={openInNewTab ? '_blank' : undefined}
        >
            {title}
        </a>
    );

export const SubItems = ({ subItems, location }: { subItems: NavItem[]; location: Location }) => (
    <ul className={classnames(collapsibleNavItemStyles.childList)}>
        {subItems.map((subItem, index) => {
            const isSelectedSubItem = location.pathname === subItem.route;

            return (
                <li
                    key={index + subItem.title}
                    className={classnames([
                        collapsibleNavItemStyles.childNode,
                        isSelectedSubItem ? 'font-semibold' : 'font-normal',
                    ])}
                >
                    {isSelectedSubItem && (
                        <span className={classnames(collapsibleNavItemStyles.smallCircle)}></span>
                    )}

                    {isSelectedSubItem ? (
                        subItem.title
                    ) : (
                        <LinkVariation
                            route={subItem.route || ''}
                            title={subItem.title}
                            openInNewTab={subItem.openInNewTab}
                            external={subItem.external}
                        />
                    )}
                </li>
            );
        })}
    </ul>
);

interface CollapsibleNavItemProps {
    title: string;
    icon?: typeof faHouse;
    route?: string;
    subItems?: NavItem[];
    expandedItems: Record<string, boolean>;
    setExpandedItems: (items: Record<string, boolean>) => void;
    startOpen?: boolean;
    external?: boolean;
}

export const CollapsibleNavItem = ({
    title,
    icon,
    route,
    subItems,
    expandedItems = {},
    setExpandedItems = () => {},
    external,
}: CollapsibleNavItemProps) => {
    const history = useHistory();
    const location = useLocation();
    const isSelected = route && location.pathname === route;
    const isExpanded = !route && expandedItems[title];

    const externalLink = route && external;
    const isResourceRoute =
        route && (Routes.SupportOrganization === route || Routes.Contact === route);
    const linkProps =
        externalLink || isResourceRoute
            ? { target: '_blank', rel: 'noopener noreferrer', href: route }
            : {};
    const CustomLinkOrAnchor = externalLink ? 'a' : Link;

    const hasActiveChild =
        subItems && subItems.some((subItem) => location.pathname === subItem.route);

    const toggleExpanded = () => {
        if (!route) {
            setExpandedItems({ ...expandedItems, [title]: !isExpanded });
        } else {
            setExpandedItems(expandedItems);
        }
    };

    return (
        <li className="relative" key={title}>
            <button
                onClick={toggleExpanded}
                tabIndex={0}
                className={classnames([
                    `${
                        isExpanded || hasActiveChild || isSelected
                            ? collapsibleNavItemStyles.parentTitleSelected
                            : collapsibleNavItemStyles.parentTitleUnSelected
                    }`,
                    collapsibleNavItemStyles.parentNode,
                    (isSelected || hasActiveChild) && collapsibleNavItemStyles.parentHighlighted,
                    isSelected && collapsibleNavItemStyles.parentNodeSelected,
                ])}
            >
                <div className={classnames([collapsibleNavItemStyles.parentNodeWrapper])}>
                    {icon && (
                        <span
                            className={classnames([
                                collapsibleNavItemStyles.iconWrapper,
                                (externalLink || isResourceRoute) &&
                                    collapsibleNavItemStyles.externalLinkIconWrapper,
                            ])}
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                className={classnames([
                                    collapsibleNavItemStyles.icon,
                                    isSelected && collapsibleNavItemStyles.iconSelectedColor,
                                    (externalLink || isResourceRoute) &&
                                        collapsibleNavItemStyles.externalLinkIcon,
                                ])}
                            />
                        </span>
                    )}

                    <div
                        onClick={() => (!externalLink && route ? history.push(route) : null)}
                        className={classnames(collapsibleNavItemStyles.titleWrapper)}
                    >
                        {isSelected || !route ? (
                            title
                        ) : (
                            // @ts-ignore
                            <CustomLinkOrAnchor
                                className={classnames([collapsibleNavItemStyles.linkNoUnderline])}
                                to={route}
                                {...linkProps}
                            >
                                {title}
                            </CustomLinkOrAnchor>
                        )}
                    </div>

                    {!route && (
                        <span
                            className={classnames([
                                isExpanded
                                    ? collapsibleNavItemStyles.cheveronExpanded
                                    : collapsibleNavItemStyles.cheveronClosed,
                                collapsibleNavItemStyles.chevron,
                            ])}
                        >
                            <FontAwesomeIcon
                                icon={isExpanded ? faChevronUp : faChevronDown}
                                className={classnames([collapsibleNavItemStyles.icon])}
                            />
                        </span>
                    )}
                </div>
            </button>

            {isExpanded && subItems && subItems.length && (
                <SubItems subItems={subItems as NavItem[]} location={location} />
            )}
        </li>
    );
};
