import React, { useState, useMemo } from 'react';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';

import { FormikTextField } from 'components/fields/FormikTextField';
import { FormikSelectField } from 'components/fields/FormikSelectField';
import {
    orgNameValidator,
    orgTypeValidator,
    orgEnrollEmailValidator,
} from 'common/modules/form-validation/validators';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { validateEmail } from 'common/api/users/email/validate';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { FormBtn } from 'components/form-btn';
import { getUrlToLoginPage } from 'features/auth/components/withAuth';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { HubspotContactInfo } from 'common/api/crm/organization/enrollment/models';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectOrganization,
    selectUserInformation,
    selectOrgTypeOptions,
    submitLeadOrgCaptureInfo,
    selectProgressBarRef,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOrganizationTypesLoading } from 'common/features/store/duck/organization/duck';
import { ContentfulSubheading } from '../components/ContentfulSubheading';
import { scrollTo } from '../utils/ScrollTo';
import { Routes } from 'routes';

export const LeadCaptureInfo = () => {
    const dispatch = useDispatch<any>();
    const organization = useRootSelector(selectOrganization);
    const userInformation = useRootSelector(selectUserInformation);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const progressBarRef = useSelector(selectProgressBarRef);

    const initialValues: HubspotContactInfo = {
        coordinatorEmailAddress: userInformation.email,
        organizationName: organization.name,
        organizationTypeId: organization.organizationTypeId,
    };

    const onSubmit = async (
        formData: HubspotContactInfo,
        formCtx: FormikHelpers<HubspotContactInfo>
    ) => {
        setSubmitting(true);
        // Validate the email first
        const res = await validateEmail(formData.coordinatorEmailAddress);

        if (hasErrorWithStatus(res, StatusCode.Conflict)) {
            formCtx.setFieldError('coordinatorEmailAddress', ' ');
            setSubmitting(false);
            return;
        }

        scrollTo(progressBarRef);
        await dispatch(submitLeadOrgCaptureInfo(formData));

        // QUESTION: What do we do if something error's out?
        // ANSWER: https://glscrip.atlassian.net/browse/FEC-795?focusedCommentId=60019
        setSubmitting(false);
    };

    return (
        <div>
            <ContentfulSubheading attribute="step1Subtitle" />
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <LeadCaptureForm submitting={submitting} />
            </Formik>
        </div>
    );
};

const LeadCaptureForm = ({ submitting }: { submitting: boolean }) => {
    const { isValid } = useFormikContext();
    return (
        <Form>
            <FormikTextField
                label="Organization Name"
                name="organizationName"
                placeholder="Ex: Spartan Athletics"
                validate={orgNameValidator}
            />
            <FieldSpacer className="pb-5" />
            <OrganizationTypeField />
            <FieldSpacer className="pb-5" />
            <OrgEmailField />
            <FieldSpacer className="pb-5" />
            <input name="gclid" type="hidden" />
            <div className="flex sm:place-content-start place-content-center">
                <FormBtn
                    className="org_enrollment_step_one "
                    disabled={!isValid}
                    isSubmitting={submitting}
                >
                    Continue
                </FormBtn>
            </div>
        </Form>
    );
};

const OrganizationTypeField = () => {
    const orgTypes = useRootSelector(selectOrgTypeOptions);
    const loading = useRootSelector(selectIsOrganizationTypesLoading);
    const formikProps = useFormikContext<HubspotContactInfo>();

    return (
        <FormikSelectField<HubspotContactInfo>
            name="organizationTypeId"
            label="Organization Type"
            validate={orgTypeValidator}
            isLoading={loading}
            className={formikProps.values.organizationTypeId ? 'text-grey-1' : 'text-grey-3'}
        >
            <option value="">Ex: Private School, Sports Team, etc.</option>
            {orgTypes?.map((orgType) => (
                <option key={orgType.id} value={orgType.id}>
                    {orgType.description}
                </option>
            ))}
        </FormikSelectField>
    );
};

const OrgEmailField = () => {
    return (
        <div>
            <FormikTextField
                name="coordinatorEmailAddress"
                type="email"
                label="Email Address"
                placeholder="Ex: joey@spartanathletics.com"
                validate={orgEnrollEmailValidator}
            />
            <HelpTextOrFieldErrorSection />
        </div>
    );
};

const HelpTextOrFieldErrorSection = () => {
    const { errors, submitCount } = useFormikContext<HubspotContactInfo>();
    const loginUrl = useMemo(() => getUrlToLoginPage(Routes.StartProgramContinue), []);

    return (
        <div>
            <FadeIn when={errors.coordinatorEmailAddress === ' ' && submitCount > 0}>
                <p className="text-error text-xs">
                    Looks like that email is already in use.{' '}
                    <a href={loginUrl} className="underline font-semibold">
                        Sign in here.
                    </a>
                </p>
            </FadeIn>
            <FadeIn when={!(submitCount > 0 && Boolean(errors.coordinatorEmailAddress))}>
                <p className="mt-1 text-xs text-grey-1">
                    This will be the email address that you use to log in to your account. Use an
                    email address that is associated with your organization, if possible.
                </p>
            </FadeIn>
        </div>
    );
};
