import { useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCoordinatorPendingOrdersByOrgThunk,
    selectCoordinatorMetaData,
} from 'common/features/store/duck/coordinator/duck';
import { selectHomeDetails } from 'common/features/store/duck/home/duck';
import { REACT_APP_LAUNCH_SCRIPWARE_URL } from 'common/env';
import { FeaturesState } from 'common/features/featuresReducer';

export interface Notifications {
    hasNotifications: boolean;
    pendingCoordinatorOrders: NotificationDataType[];
}

// FEC-192 - initial notification hook, add additional hooks for
//different types of notifications here.
const usePendingCoordinatorOrders = () => {
    const dispatch = useDispatch<any>();
    const homeDetails = useSelector(selectHomeDetails);
    const { pendingOrders, pendingPurchaseOrderCount } = useSelector(selectCoordinatorMetaData);
    const isCoordinator = useSelector((s: FeaturesState) => s.store.account.isCoordinator);
    const isLoading = useRef<boolean>(false);
    const orgId = homeDetails?.defaultOrganization?.id;

    useEffect(() => {
        if (orgId && isCoordinator && !isLoading.current) {
            isLoading.current = true;
            dispatch(getCoordinatorPendingOrdersByOrgThunk(orgId));
        }
    }, [orgId, dispatch, isCoordinator]);

    return { pendingOrders, pendingPurchaseOrderCount };
};

export interface NotificationDataType {
    title: string;
    subtitle: string;
    // currently leaving as `number` because our notifications are based off coordinator metadata
    data: number;
    // some action to take
    action: (...args: any) => void;
    // action name
    actionName: string;
}

interface ActionContext {
    isScripware: boolean;
}

export const useNotifications = () => {
    const notificationsData = usePendingCoordinatorOrders();

    const notifications = useMemo(
        () =>
            [
                {
                    title: 'Pending Family Orders',
                    subtitle: `There are ${notificationsData.pendingOrders} pending orders. Submit them for processing now`,
                    data: notificationsData.pendingOrders,
                    actionName: 'Launch',
                    action: ({ isScripware }: ActionContext) => {
                        if (isScripware) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (window as any).location.href = REACT_APP_LAUNCH_SCRIPWARE_URL;
                        } else {
                            window.location.href = '/c/Org/Order/OrderQueue';
                        }
                    },
                },
                {
                    title: 'Pending Purchase Orders',
                    subtitle: `There are ${notificationsData.pendingPurchaseOrderCount} pending purchase orders. Submit them for processing now`,
                    data: notificationsData.pendingPurchaseOrderCount,
                    actionName: 'Launch',
                    action: ({ isScripware }: ActionContext) => {
                        if (isScripware) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (window as any).location.href = REACT_APP_LAUNCH_SCRIPWARE_URL;
                        } else {
                            window.location.href = '/c/Org/Order/POManager';
                        }
                    },
                },
            ].filter(({ data }) => data > 0) as NotificationDataType[],
        [notificationsData]
    );

    return {
        hasNotifications: notifications.length > 0,
        notifications,
    };
};
