/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from 'redux';
import { shallowEqual } from 'react-redux';

export type AsyncFunction = (...args: any[]) => Promise<any>;
export type ResolveType<TPromise> = TPromise extends Promise<infer T> ? T : never;

export interface AsyncActionState<TArgs = any[], TError = unknown> {
    loading: boolean;
    error: TError | null;
    lastUpdate: number;
    args: TArgs | null;
}

export const initialAsyncActionState: AsyncActionState<any, any> = {
    loading: false,
    error: null,
    lastUpdate: 0,
    args: null,
};

export type HandleStateChange<F extends AsyncFunction, TResult, TError = Error> = (
    ...args: Parameters<F>
) => (
    state: AsyncActionState<Parameters<F>, TError>,
    result?: TResult,
    error?: TError
) => AnyAction | void;

export const createAsyncTracker = <F extends AsyncFunction, TResult, TError>(
    handleStateChange: HandleStateChange<F, TResult, TError>,
    args: Parameters<F>
) => {
    let state: AsyncActionState<Parameters<F>, TError> = { ...initialAsyncActionState, args };
    const boundHandleStateChange = handleStateChange(...args);

    const start = () => {
        state = { ...state, loading: true };
        return boundHandleStateChange(state);
    };
    const done = (result: TResult) => {
        state = { ...state, loading: false, lastUpdate: Date.now() };
        return boundHandleStateChange(state, result);
    };
    const fail = (error: TError) => {
        state = { ...state, loading: false, error };
        return boundHandleStateChange(state, undefined, error);
    };
    const getState = () => state;

    return { start, done, fail, getState };
};

export const isExpired = (state: AsyncActionState, maxAge: number): boolean => {
    return Boolean(state.lastUpdate) && Date.now() - state.lastUpdate > maxAge;
};

export const needsData = <TArgs extends any[]>(options: {
    state: AsyncActionState<TArgs>;
    maxAge?: number;
}) => {
    return (...args: TArgs): boolean => {
        if (!options.state.args || !shallowEqual(args, options.state.args)) {
            return true;
        }
        if (options.maxAge && isExpired(options.state, options.maxAge)) {
            return !options.state.loading;
        }
        return false;
    };
};
