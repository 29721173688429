import classNames from 'classnames';
import React from 'react';

import { createStyles } from 'utils/createStyle';

export interface ProgressIndicatorProps {
    currentStep: number;
    totalSteps: number;
    className?: string;
}

const styles = createStyles({
    stepsList: 'w-full flex flex-row items-center py-1 gap-2',
    stepStyle: [
        'w-full',
        {
            height: '0.5rem',
        },
    ],
    unselected: {
        background: '#d7d7d7',
    },
    container: 'w-full',
});

export const ProgressIndicator = ({
    currentStep,
    totalSteps,
    className,
}: ProgressIndicatorProps) => {
    const renderStep = (_: unknown, i: number) => {
        const isCompleted = i + 1 <= currentStep;
        return (
            <li
                className={classNames(
                    styles.stepStyle,
                    isCompleted ? 'bg-brand-accent' : styles.unselected
                )}
                key={i}
            />
        );
    };

    return (
        <div className={classNames(styles.container, className)}>
            <ul className={styles.stepsList}>{Array.from({ length: totalSteps }, renderStep)}</ul>
        </div>
    );
};
