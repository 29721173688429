import orgEnrollmentV2, { IOrgEnrollmentV2 } from './org-enrollment-v2';
import buyNow, { BuyNowFlow } from './buy-now-flow';
import remoteActivation, { RemoteActivationState } from './remote-activation';
import addGiftCard, { AddGiftCardState } from './add-gift-card-flow';
import { combineReducers } from 'redux';

export interface UIState {
    orgEnrollmentV2: IOrgEnrollmentV2;
    buyNow: BuyNowFlow;
    remoteActivation: RemoteActivationState;
    addGiftCard: AddGiftCardState;
}

export const uiStateReducer = combineReducers<UIState>({
    orgEnrollmentV2,
    buyNow,
    remoteActivation,
    addGiftCard,
});
