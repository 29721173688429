import React from 'react';
import { Organization } from 'common/api/crm/models/Organization';
import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { FieldSpacer } from '../../../../../components/fields/FieldSpacer';
import { Btn } from '../../../../../components/btn/Btn';
import { useValidation } from '../../../../../modules/form-validation/useValidation';
import { FormikSelectField } from 'components/fields/FormikSelectField';
import { FormikTextField } from 'components/fields/FormikTextField';
import {
    addressLine2Validator,
    cityValidator,
    twoFactorPhoneNumberValidator,
    stateValidator,
    organizationZipCodeValidator,
    addressLine1Validator,
} from 'common/modules/form-validation/validators';
import { stateOptions } from 'common/modules/form-validation/utils/stateOptions';
import { OrganizationWebsiteFieldV2 } from '../components/OrganizationWebsiteFieldV2';
import {
    selectOrganization,
    selectProgressBarRef,
    setOrganizationData,
    updateCurrentStepBy,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useRootSelector } from 'common/features/featuresReducer';
import { useDispatch, useSelector } from 'react-redux';
import { flattenObject } from 'common/utils/flattenObject';
import { ContentfulSubheading } from '../components/ContentfulSubheading';
import MaskedInput from 'react-text-mask';
import { MASK_PHONE_US, MASK_ZIP_CODE_SHORT } from 'common/modules/mask-text/masks';
import { scrollTo } from '../utils/ScrollTo';

export const EnrollOrganizationDetails = () => {
    const dispatch = useDispatch<any>();
    const organization = useRootSelector(selectOrganization);
    const progressBarRef = useSelector(selectProgressBarRef);

    const { address, phoneNumber, website } = organization;
    const validateAndSubmit = useValidation<Partial<Organization>>((errors) =>
        Object.keys(flattenObject(errors))
    );

    const updateOrgData = useCallback(
        async (values: Partial<Organization>, stepIncrement: number) => {
            dispatch(
                setOrganizationData({
                    address: values.address,
                    phoneNumber: values.phoneNumber,
                    website: values.website,
                })
            );
            scrollTo(progressBarRef);
            dispatch(updateCurrentStepBy(stepIncrement));
        },
        [dispatch, progressBarRef]
    );

    const _onSubmit = useCallback(
        async (values: Partial<Organization>) => {
            updateOrgData(values, 1);
        },
        [updateOrgData]
    );

    const onBackClicked = useCallback(
        async (values: Partial<Organization>) => {
            updateOrgData(values, -1);
        },
        [updateOrgData]
    );

    return (
        <div>
            <ContentfulSubheading attribute="step2Subtitle" />
            <Formik initialValues={{ address, phoneNumber, website }} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <Form onSubmit={validateAndSubmit(formikProps)}>
                        <FormikTextField
                            label="Organization Address"
                            name="address.address1"
                            placeholder={"Enter your organization's address"}
                            validate={addressLine1Validator}
                        />
                        <FieldSpacer />
                        <FormikTextField
                            label="Organization Address Line 2 (Optional)"
                            name="address.address2"
                            placeholder="Suite, unit, or attention"
                            validate={addressLine2Validator}
                        />
                        <FieldSpacer />
                        <div className="flex md:flex-row flex-col">
                            <div className="flex-1 md:mr-3">
                                <FormikTextField
                                    label="City"
                                    name="address.city"
                                    placeholder="Enter city"
                                    validate={cityValidator}
                                />
                            </div>
                            <div className="md:w-36 md:mr-3 mr-0 w-auto my-6 md:my-0">
                                <FormikSelectField
                                    label="State"
                                    name="address.state"
                                    // @ts-ignore
                                    placeholder="Select state"
                                    validate={stateValidator}
                                >
                                    <option value="">Select state</option>
                                    {stateOptions.map((o) => (
                                        <option key={o.value}>{o.value}</option>
                                    ))}
                                </FormikSelectField>
                            </div>
                            <div className="md:w-36 w-full">
                                <MaskedInput
                                    mask={MASK_ZIP_CODE_SHORT}
                                    guide={false}
                                    render={(ref, _props) => (
                                        <FormikTextField
                                            {..._props}
                                            inputRef={ref}
                                            label="Zip Code"
                                            name="address.zipCode"
                                            placeholder="Enter zip code"
                                            validate={organizationZipCodeValidator}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <FieldSpacer />
                        <div className="flex flex-col md:w-8/12 w-full">
                            <MaskedInput
                                mask={MASK_PHONE_US}
                                guide={false}
                                render={(ref, _props) => (
                                    <FormikTextField
                                        {..._props}
                                        name="phoneNumber"
                                        inputRef={ref}
                                        type="tel"
                                        placeholder="Ex: (555) 555-5555"
                                        label="Organization Phone Number"
                                        validate={twoFactorPhoneNumberValidator}
                                    />
                                )}
                            />
                            <p className="text-xs text-grey-1 pt-1">
                                Use a phone number that is associated with your organization, if
                                possible. Our administrators will contact your organization at this
                                number if further information is needed.
                            </p>
                        </div>

                        <FieldSpacer />
                        <span>
                            <OrganizationWebsiteFieldV2 label="Website or Social Media Page" />
                        </span>
                        <FieldSpacer />
                        <div className="flex">
                            <Btn
                                isSecondary
                                onClick={() => onBackClicked(formikProps.values)}
                                className="sm:w-1/3 w-1/2 mr-3"
                            >
                                Back
                            </Btn>
                            <Btn
                                type="submit"
                                className="sm:w-1/3 w-1/2"
                                disabled={!formikProps.isValid}
                            >
                                Continue
                            </Btn>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
