import { ProgressState } from '../../enums/ProgressState';
import { OrganizationEnrollmentLogEntry } from '../../models/OrganizationEnrollmentLogEntry';

import {
    Organization,
    HubspotContactInfo,
    HubspotFamilyContactInfo,
} from 'common/api/crm/organization/enrollment/models';
import { SECURED_CRM_URL, ORGANIZATION_URL, CRM_URL } from 'common/api/config';
import { apiFetch } from 'common/api/utils';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';

export const getOrganizationByEnrollmentCode = (enrollmentCode: string) =>
    apiFetch<Organization>(`${ORGANIZATION_URL}/enrollment/${enrollmentCode}`);

export interface UpdateEnrollmentProgressArgs {
    state: ProgressState;
    organizationId?: string;
    version?: number;
}

export const updateEnrollmentProgress = async ({
    state,
    organizationId,
    version,
}: UpdateEnrollmentProgressArgs) => {
    const bodyData = organizationId ? { organizationId, state, version } : { state, version };
    const result = withTokensIfNeeded<null>(
        `${SECURED_CRM_URL}/OrganizationEnrollmentLog?api-version=1`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData),
        }
    );
    return result;
};

export const getOrganizationEnrollmentLog = async () => {
    const result = await withTokensIfNeeded<OrganizationEnrollmentLogEntry[]>(
        `${SECURED_CRM_URL}/OrganizationEnrollmentLog?api-version=1`
    );
    return result;
};

export const getOrganizationFromEnrollmentCode = async (enrollmentCode: string) => {
    const res = await withTokensIfNeeded<Organization>(
        `${SECURED_CRM_URL}/Organization/enrollment/${enrollmentCode}?api-version=1`
    );
    return res;
};

// NOTE: For enrollment version-2
export const createContactInHubspot = async (payload: HubspotContactInfo) => {
    return await withTokensIfNeeded(`${CRM_URL}/Organizations/contact?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
};

export const createFamilyContactInHubspot = async (payload: HubspotFamilyContactInfo) => {
    return await withTokensIfNeeded(`${CRM_URL}/Family/contact?api-version=1`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
};
