import { PaymentSubType } from "../payment-types";

export enum CreditCardType {
    Mastercard = 'MC',
    Visa = 'VISA',
    Discover = 'DISC',
}

export interface SavedCreditCard {
    id: number;
    authToken: string;
    maskedCardNumber: string | null;
    creditCardType: CreditCardType;
    cardSubType: PaymentSubType;
}
