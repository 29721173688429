import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { SkeletonProps } from 'react-loading-skeleton';
import classnames from 'classnames';

import { colors } from 'styles/settings';
import styles from '../StickyHeaderMenuStyles';
import { useRootSelector } from 'common/features/featuresReducer';
import { selectCoordinatorMetaData } from 'common/features/store/duck/coordinator/duck';
import { Render } from 'components/loading-screen/Render';
import { useIsScripware } from 'hooks/useIsScripware';
import { getPendingOrdersUrl, getPendingPurchaseOrderCountUrl } from './url_constants';

export const Notifications = () => {
    const { pendingOrders, pendingPurchaseOrderCount, loading } =
        useRootSelector(selectCoordinatorMetaData);
    const noMessages = pendingOrders === 0 && pendingPurchaseOrderCount === 0;
    const isScripwareOrg = useIsScripware();

    const contentClasses = classnames(
        styles.menuDropdown,
        noMessages && !loading
            ? styles.notificationEmptyMenuContainer
            : styles.notificationMenuContainer
    );
    const notificationBellClasses = classnames(
        styles.notificationTrigger,
        noMessages || loading ? '' : styles.notificationTriggerDot
    );

    return (
        <div className={styles.menu}>
            <button className={notificationBellClasses}>
                <DropdownMenu.DropdownMenu modal={false}>
                    <DropdownMenu.Trigger className={styles.menuTrigger}>
                        <FontAwesomeIcon icon={faBell} />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                        align="end"
                        className={contentClasses}
                        style={{ marginRight: 10 }}
                    >
                        {!noMessages || loading ? (
                            <p className="text-sm text-grey-2 pb-2.5 text-left">Notifications</p>
                        ) : null}
                        <Render<SkeletonProps>
                            when={!loading}
                            loadingComponentProps={{ height: 80 }}
                        >
                            {noMessages && !loading ? (
                                <>
                                    <p className="text-center text-grey-darkest text-xl font-semibold">
                                        You're all caught up!
                                    </p>
                                    <p className="text-center text-grey-2 text-sm">
                                        Stay tuned for important messages.
                                    </p>
                                </>
                            ) : (
                                <>
                                    {pendingPurchaseOrderCount > 0 ? (
                                        <NotificationItem
                                            text={`${pendingPurchaseOrderCount} Purchase Orders Pending`}
                                            url={getPendingPurchaseOrderCountUrl(isScripwareOrg)}
                                        />
                                    ) : null}
                                    {pendingOrders > 0 ? (
                                        <NotificationItem
                                            text={`${pendingOrders} Participant Orders Pending`}
                                            url={getPendingOrdersUrl(isScripwareOrg)}
                                        />
                                    ) : null}
                                </>
                            )}
                        </Render>
                    </DropdownMenu.Content>
                </DropdownMenu.DropdownMenu>
            </button>
        </div>
    );
};

const NotificationItem = ({ text, url }: { text: string; url: string }) => {
    return (
        <DropdownMenu.Item className="border-b border-solid border-grey-line py-3 hover:bg-pale-blue hover:bg-opacity-40">
            <a className="no-underline items-center flex justify-between padding px-3" href={url}>
                <div className="text-base flex gap-2 items-center text-brand-dark font-semibold">
                    <FontAwesomeIcon
                        icon={faCircle}
                        className="pr-1 "
                        size="xs"
                        color={colors.bonusPink}
                    />
                    {text}
                </div>
                <FontAwesomeIcon icon={faAngleRight} color={colors.brandDark} />
            </a>
        </DropdownMenu.Item>
    );
};
