import { BrandSearchResponse } from './models/BrandSearchResponse';
import { toSearchResult } from './utils/toSearchResult';
import { defaultBrandSearchOptions } from './config';

import { SearchApiQueryParams, searchApiFetch } from './';

import { and, includesEq } from 'common/utils/searchFilters';

export const brandsInListFilter = (listId: string) => (listId ? includesEq('Lists', listId) : '');

export const getBrandsInList = async (
    listId: string,
    options: Partial<SearchApiQueryParams> = {}
) =>
    toSearchResult(
        await searchApiFetch<BrandSearchResponse>({
            ...defaultBrandSearchOptions,
            ...options,
            filter: options.filter
                ? and(brandsInListFilter(listId), options.filter)
                : brandsInListFilter(listId),
        })
    );
