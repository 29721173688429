import { ProgressState } from 'common/api/crm/enums/ProgressState';

export interface DeferedEnrollmentLog {
    id: string;
    state: ProgressState;
}

// NOTE: An enumeration of content-types that point to a contentful content.
// NOTE: Avoids having hardcoded strings
export enum EnrollmentContentType {
    ORG_ENROLLMENT = 'orgEnrollment',
    SCHEMA_ORG_ENROLLMENT_V2 = 'orgEnrollmentV2',
    FAMILY_ENROLLMENT = 'familyEnrollment',
}
