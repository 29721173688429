import { fromEntries } from 'common/utils';
import { asTuple } from 'common/utils/asTuple';
import { PartialRecord } from 'common/definitions/PartialRecord';

export const getCookieAsObject = (cookie: string): PartialRecord<string, string> => {
    if (cookie === '') {
        return {};
    }
    const entries = cookie.split(/\s*;\s*/).map((pairStr) => {
        const pair = pairStr.split(/\s*=\s*/);
        return asTuple(pair[0], pair.splice(1).join('='));
    });
    return fromEntries(entries);
};
