import React, { useEffect } from 'react';

import { useRootSelector } from 'rootStore';
import {
    selectUserAuthState,
    updateAccount,
    UserAuthState,
} from 'common/features/store/duck/account/duck';
import { Routes } from 'routes';
import { toQueryString } from 'common/utils/queryString';
import { getUserClaimsFromBff } from 'common/api/bff';
import { useDispatch } from 'react-redux';

export const withAuth = (returnPath?: string) => {
    return function <P>(WrappedComponent: React.ComponentType<P>) {
        const EnhancedComponent = (props: React.PropsWithChildren<P>) => {
            const userAuthState = useRootSelector(selectUserAuthState);
            const dispatch = useDispatch<any>();

            useEffect(() => {
                if (userAuthState === UserAuthState.NOTAUTHENTICATED) {
                    dispatch(updateAccount({ userAuthState: UserAuthState.INITIAL }));
                    sendToLoginPage(returnPath);
                }
            }, [userAuthState, dispatch]);

            return <WrappedComponent {...props} />;
        };
        return EnhancedComponent;
    };
};

export const getUrlToLoginPage = (returnPath = '', otac?: string) => {
    const path = returnPath ? returnPath : location.pathname;
    const returnUrl = path + location.search;

    return `${Routes.Login}?${toQueryString({ returnUrl, otac })}`;
};

export const sendToLoginPage = (returnPath = '', otac?: string) => {
    location.assign(getUrlToLoginPage(returnPath, otac));
};

export const openLoginPage = (returnPath = '', otac?: string) => {
    window.open(getUrlToLoginPage(returnPath, otac), 'login');
};

export const getLogoutUrl = async () => {
    const { data: userClaims, error } = await getUserClaimsFromBff();

    if (error || !userClaims) {
        return;
    }

    return userClaims.find((claim) => claim.type === 'bff:logout_url')?.value;
};

export const sendToLogoutPage = async (returnPath = '&returnUrl=%2Fshop') => {
    const logoutUrl = await getLogoutUrl();

    if (!logoutUrl) {
        return;
    }

    location.assign(logoutUrl + returnPath);
};
