import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/pro-light-svg-icons';

import { Btn } from 'components/btn/Btn';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { NumberedStep } from './NumberedStep';
import { UploadCheckModal } from './UploadCheckModal';

export const UploadVoidedCheck = () => {
    const [isOpen, toggle] = useModalControls();
    const [isCopied, copyText] = useCopyToClipboard();

    return (
        <NumberedStep number={2}>
            <Btn data-testid="upload-check-btn" onClick={toggle}>
                Upload Check
            </Btn>
            <p>
                <span className="text-grey-2 font-semibold mr-1">or</span>
                email a check to
                <a className="ml-1" href="mailto:fundraising@raiseright.com">
                    fundraising@raiseright.com
                </a>
                {!isCopied ? (
                    <FontAwesomeIcon
                        onClick={() => copyText('fundraising@raiseright.com')}
                        className="text-brand ml-1 cursor-pointer"
                        icon={faCopy}
                        size="xs"
                    />
                ) : (
                    <FontAwesomeIcon
                        className="text-brand ml-1 cursor-pointer"
                        icon={faCheck}
                        size="xs"
                    />
                )}
            </p>
            <UploadCheckModal isOpen={isOpen} onClose={toggle} />
        </NumberedStep>
    );
};

const useModalControls = (): [boolean, () => void] => {
    const [isOpen, onClose_] = useState<boolean>(false);
    const toggle = () => onClose_((value) => !value);
    return [isOpen, toggle];
};
