import { StatusCode } from '../config';
import { APIResponse } from '../models';

export const hasErrorWithStatus = <T, E>(result: APIResponse<T, E>, status: StatusCode) => {
    if (!result.error) {
        return false;
    }

    if (!result.error.response) {
        return false;
    }

    return result.error.response.status === status;
};
