import { getApps, initializeApp, getApp } from 'firebase/app';
import { initializeAnalytics, isSupported, Analytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export let firebaseAnalytics: Analytics;

export const initFirebase = () => {
    const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
    isSupported().then((supported) => {
        if (supported) {
            firebaseAnalytics = initializeAnalytics(app);
        }
    });
};
