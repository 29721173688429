import { LinkBtn } from 'components/link-btn';
import React, { useCallback, useContext, useEffect } from 'react';
import { createStyles } from 'utils/createStyle';
import { ContentFullEnrollmentContext } from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationEnrollmentV2 } from 'common/api/contentful/models/OrganizationEnrollment';
import { ContentfulSubheading } from '../components/ContentfulSubheading';
import { Alert, AlertType } from 'components/alerts/sticky/Alert';
import { Btn } from 'components/btn/Btn';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { FadeIn } from 'components/loading-screen/FadeIn';
import {
    completedForm,
    selectProgressBarRef,
    updateEnrollmentLog,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch, useSelector } from 'react-redux';
import { useRootSelector } from 'common/features/featuresReducer';
import { selectDefaultOrganization } from 'common/features/store/duck/home/duck';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { deleteUserAchAccountThunk } from 'common/features/store/duck/account/duck';
import { PlaidWrapper } from 'features/coordinator/enrollment/steps/PlaidButton/PlaidButton';
import { PlaidLinkOnExit } from 'react-plaid-link';
import { deleteOrgAchAccount } from 'common/api/e-comm/ach';
import { PlaidErrorCode } from 'common/utils/plaidVerification';
import { deleteOrgAchAccountThunk } from 'common/features/store/duck/organization/duck';
import { scrollTo } from '../utils/ScrollTo';

const styles = createStyles({
    container: `text-brand md:mb-2 border-b-2 border-pale-blue md:border-none rounded-none md:rounded-lg 
    bg-white px-4 md:px-10 pb-8 py-4 px-4 md:px-10 block w-full text-left focus:outline-none max-w-xl`,
    title: 'flex text-lg md:text-3xl font-bold text-brand-dark',
    subtitle: 'mt-2 mb-5 text-grey-1',
});

const ManualMicrodeposits = () => {
    const orgId = useRootSelector(selectDefaultOrganization)?.id || '';
    const history = useHistory();

    const onMicroDepositDone = useCallback(async () => {
        history.replace(Routes.EnrollV2CompletedInstant);
    }, [history]);

    const onPlaidExit: PlaidLinkOnExit = useCallback(
        async (error) => {
            if (orgId && error && error.error_code === PlaidErrorCode.TooManyVerificationAttempts) {
                await deleteOrgAchAccount(orgId);
                history.replace(Routes.EnrollV2ChooseProgram);
            }
        },
        [orgId, history]
    );

    return (
        <Alert
            type={AlertType.INFO}
            className="my-5 sm:mt-0 border-l"
            title="Check your bank account for deposits"
        >
            <div className={styles.subtitle}>
                A $0.01 deposit from Plaid should appear in your bank account after 1-2 business
                days. Return here to complete your setup by entering the code. (Hint: your code is
                the first 3 letters after the #.)
            </div>
            <div className="w-full text-center sm:text-left">
                <PlaidWrapper
                    orgId={orgId}
                    onSuccess={onMicroDepositDone}
                    onExit={onPlaidExit}
                    isResuming
                >
                    {(openPlaid, isPlaidReady) => (
                        <Btn isSecondary disabled={!isPlaidReady} onClick={openPlaid}>
                            Complete Setup
                        </Btn>
                    )}
                </PlaidWrapper>
            </div>
        </Alert>
    );
};

const AutomatedMicrodeposits = () => {
    return (
        <Alert
            type={AlertType.INFO}
            className="my-5 sm:mt-0 border-l"
            title="Your bank verification is pending"
        >
            <div className={styles.subtitle}>
                Your bank account information will be automatically verified in the next 1-2
                business days. You may see one small deposit in your bank account from Plaid used to
                verify your account.
            </div>

            <span className="mt-7 text-brand text-base sm:text-lg">
                Still pending after 2 business days?
            </span>
            <p className="mt-3 mb-3 text-grey-1 text-xs">
                Contact our New Program Advisors at 1-800-727-4715, Option 4
            </p>
        </Alert>
    );
};

const PaperOnly = () => {
    return (
        <Alert
            type={AlertType.INFO}
            className="my-5 sm:mt-0 border-l"
            title="We’ll be in touch soon"
        >
            <div className={styles.subtitle}>
                Organizations that choose to submit orders and pay by check are set up through our
                New Customer Support team. We will review your information to confirm your
                organization’s eligibility and contact you to complete your enrollment.
            </div>
        </Alert>
    );
};

export const SuccessStep = () => {
    const dispatch = useDispatch<any>();
    const orgId = useRootSelector(selectDefaultOrganization)?.id || '';
    const progressBarRef = useSelector(selectProgressBarRef);

    const { contentfulEntry } = useContext(ContentFullEnrollmentContext);
    const contentfulEntryOrgEnrollV2 = contentfulEntry as OrganizationEnrollmentV2;

    const title = contentfulEntryOrgEnrollV2
        ? contentfulEntryOrgEnrollV2.completedPageTitle
        : undefined;

    const completedPagePrimaryCtaText = contentfulEntryOrgEnrollV2
        ? contentfulEntryOrgEnrollV2.completedPagePrimaryCtaText
        : undefined;

    const completedPagePrimaryCtaLink = contentfulEntryOrgEnrollV2
        ? contentfulEntryOrgEnrollV2.completedPagePrimaryCtaLink
        : undefined;

    const changeProgrampaymentCtaText = contentfulEntryOrgEnrollV2
        ? contentfulEntryOrgEnrollV2.changeProgrampaymentCtaText
        : undefined;

    const changeProgrampaymentCtaLink = contentfulEntryOrgEnrollV2
        ? contentfulEntryOrgEnrollV2.changeProgrampaymentCtaLink
        : undefined;

    const resetType = async () => {
        await dispatch(
            updateEnrollmentLog({
                state: ProgressState.ResetProgramType,
                version: 2,
                organizationId: orgId,
            })
        );
        await dispatch(deleteUserAchAccountThunk);
        await dispatch(deleteOrgAchAccountThunk(orgId));
        window.location.href = changeProgrampaymentCtaLink || '';
    };

    useEffect(() => {
        scrollTo(progressBarRef);
        completedForm()(dispatch);
    }, [dispatch, orgId, progressBarRef]);

    return (
        <FadeIn when={Boolean(contentfulEntry)}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <ContentfulSubheading attribute="completedPageSubtitle" />

                <Switch>
                    <Route path={Routes.EnrollV2CompletedAutomatedDeposits} exact>
                        <AutomatedMicrodeposits />
                    </Route>
                    <Route path={Routes.EnrollV2CompletedMicroDeposits} exact>
                        <ManualMicrodeposits />
                    </Route>
                    <Route path={Routes.EnrollV2CompletedPaperOnly} exact>
                        <PaperOnly />
                    </Route>
                    <Route path={Routes.EnrollV2CompletedInstant} exact />
                </Switch>
                <div className="w-full text-center sm:text-left">
                    <Btn
                        className="mt-2 mb-4"
                        onClick={() => (window.location.href = completedPagePrimaryCtaLink || '')}
                    >
                        {completedPagePrimaryCtaText}
                    </Btn>
                </div>
                <div className="text-sm text-grey-1 text-center sm:text-left">
                    <p>Need to update your program type or bank information?</p>
                    <LinkBtn onClick={resetType}>{changeProgrampaymentCtaText}</LinkBtn>
                </div>
            </div>
        </FadeIn>
    );
};
