import { createSlice } from 'common/modules/create-slice';
import {
    createApiThunk,
    initialAsyncActionState,
    AsyncActionState,
} from 'common/modules/async-actions/thunk';
import { getPasswordPolicy } from 'common/api/users/password/policy';
import { PasswordPolicy } from 'common/api/users/models/PasswordPolicy';

interface AppConfigState {
    passwordPolicy: PasswordPolicy;
    passwordPolicyAsyncState: AsyncActionState;
}

const initialAppConfigState: AppConfigState = {
    passwordPolicy: {
        minimumZxcvbnScore: 2,
    },
    passwordPolicyAsyncState: initialAsyncActionState,
};

const { update, reducer } = createSlice(initialAppConfigState, 'APP_CONFIG');
export const appConfigReducer = reducer;

export const getPasswordPolicyThunk = createApiThunk(
    getPasswordPolicy,
    () => (passwordPolicyAsyncState, passwordPolicy) =>
        passwordPolicy
            ? update({ passwordPolicy, passwordPolicyAsyncState })
            : update({ passwordPolicyAsyncState })
);
