import React, { ReactNode, useContext } from 'react';

import { Value } from '@firebase/remote-config';
import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';

const getValue = (v: Value) => v;

type FeatureFlagContextInfo = Partial<Record<keyof typeof defaultConfigKeys, Value>>;

export const FeatureFlagContext = React.createContext<FeatureFlagContextInfo>({});

interface FeatureFlagProps {
    flagKey: keyof typeof defaultConfigKeys;
    children: ReactNode;
    displayOnValue?: boolean;
}

export const FeatureFlag = ({ flagKey, displayOnValue = true, children }: FeatureFlagProps) => {
    const value = useFirebaseConfigValues(flagKey, undefined, getValue);
    const parentContext = useContext(FeatureFlagContext);

    // the value wasn't loaded from Firebase yet.
    if (value === undefined) {
        return null;
    }

    // checks if should render the children.
    const shouldRenderChildren = value.asBoolean();

    return shouldRenderChildren === displayOnValue ? (
        <FeatureFlagContext.Provider
            value={{
                ...parentContext,
                [flagKey]: value,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    ) : null;
};
