import { and } from 'common/utils/searchFilters';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import {
    createStateCodeQuery,
    createLocalProductByOrganizationQuery,
} from 'common/features/store/duck/home/utils';
import { useShipToCoordinatorDisabledFilter } from './useShipToCoordinatorDisabledFilter';

export const useGlobalBrandFilters = () => {
    const homeDetails = useHomeDetails();
    // NOTE: restrict users from seeing certain products by state
    // as well as restrict local products to the org they belong to
    const userStateCodeQuery = createStateCodeQuery(homeDetails);
    const localProductQuery = createLocalProductByOrganizationQuery(homeDetails);

    const shipToCoordinatorDisabledFilter = useShipToCoordinatorDisabledFilter();
    const mainFilter = userStateCodeQuery
        ? and(userStateCodeQuery, localProductQuery)
        : localProductQuery;

    if (shipToCoordinatorDisabledFilter) {
        return and(shipToCoordinatorDisabledFilter, mainFilter);
    }

    return mainFilter;
};
