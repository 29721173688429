import { FSA } from 'common/modules/create-slice';
import { Dispatch } from 'react';
import { MiddlewareAPI } from 'redux';

export const debounceMiddleware = (_: MiddlewareAPI) => (next: Dispatch<FSA<any>>) => {
    const debouncedActions: Record<string, ReturnType<typeof setTimeout>> = {};
    return (action: FSA<any, Record<string, any> | undefined>) => {
        const { debounced = 0 } = action.meta || {};
        if (debounced) {
            if (debouncedActions[action.type]) {
                clearTimeout(debouncedActions[action.type]);
            }

            debouncedActions[action.type] = setTimeout(() => {
                delete debouncedActions[action.type];
                next(action);
            }, debounced);
        } else {
            return next(action);
        }
    };
};
