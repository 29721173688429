import { SECURED_E_COMM_URL, SECURED_ORGANIZATION_URL } from 'common/api/config';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { APIResponse } from 'common/api/models';

export interface CoordinatorData {
    firstName: string;
    lastName: string;
    //   phoneNumber: string | null,
    email: string;
}

export const emptyCoordinatorData: CoordinatorData = {
    firstName: '',
    lastName: '',
    // phoneNumber: '',
    email: '',
};

export const getCoordinatorInfoByOrganizationCode = (orgId: string) =>
    withTokensIfNeeded<CoordinatorData>(
        `${SECURED_ORGANIZATION_URL}/organization/${orgId}/coordinator?api-version=1`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
    );

export interface CoordinatorPendingOrders {
    organizationId: string;
    totalPendingOrders: number;
    pendingPurchaseOrderCount: number;
}

export const getCoordinatorPendingOrdersByOrg = async (
    orgId: string
): Promise<APIResponse<CoordinatorPendingOrders>> => {
    return await withTokensIfNeeded<CoordinatorPendingOrders>(
        `${SECURED_E_COMM_URL}/organizationorders/${orgId}/order-summary`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
    );
};
