import React, { useState, useEffect, CSSProperties, useCallback, useContext } from 'react';
import { useSpring, animated, useSpringRef, useTransition, AnimatedProps } from 'react-spring';

import { FilterByGroupOption, FilterByOption, MenuTabNames } from './FilterBy';
import { categoryIcons } from './utils';

import { createStyles, createStyle } from 'utils/createStyle';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { Btn } from 'components/btn/Btn';
import { FilterSectionContext } from './FilterSection';
import { LinkBtn } from 'components/link-btn';

const styles = createStyles({
    activeTabStyles: [
        'text-brand-dark font-semibold no-underline',
        { borderBottom: '3px solid #E6126A' },
    ],
    dropdownStyles: ['w-full flex items-center justify-around', { minHeight: 341, height: 'auto' }],
    menuContainer: [
        '',
        {
            padding: '10px 15px',
            borderTop: '1px solid black',
            borderBottom: '1px solid black',
        },
    ],
});

const animatedDropdownStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 'inherit',
};

const Menu = ({
    setPage,
    setToggle,
    isToggled,
    setActiveTab,
    activeTab,
    availableCategories = [],
    hideEarningType = false,
    hideCardTypeAndPrice = false,
}: {
    setPage: Function;
    setToggle: Function;
    isToggled: boolean;
    setActiveTab: Function;
    activeTab: string;
    availableCategories: MenuTabNames[];
    hideEarningType: boolean;
    hideCardTypeAndPrice: boolean;
}) => {
    const onTabClick = (page: MenuTabNames, tab: string) => {
        const isActiveTab = activeTab === tab;

        // do not open the dropdown IFF it's the `recommended` tab
        if (!isToggled && page) {
            setToggle(true);
        }

        // close the dropdown IFF user presses `recommended` tab
        if (isActiveTab && isToggled && page) {
            setToggle(false);
        }

        setPage(page);
        setActiveTab(tab);
    };

    const hasEarningsType = availableCategories.includes(MenuTabNames.earningsType);
    const hasCategories = availableCategories.includes(MenuTabNames.category);
    const hasCardTypes = availableCategories.includes(MenuTabNames.cardType);
    const hasPrice = availableCategories.includes(MenuTabNames.price);

    return (
        <div className="w-full flex justify-around">
            {!hideEarningType && hasEarningsType && (
                <LinkBtn
                    className={`cursor-pointer brands_earnings_type_filter ${
                        activeTab === 'earningsType' ? styles.activeTabStyles : ''
                    }`}
                    onClick={() => onTabClick(MenuTabNames.earningsType, 'earningsType')}
                >
                    Earnings Type
                </LinkBtn>
            )}
            {hasCategories && (
                <LinkBtn
                    className={`cursor-pointer brands_categories_filter ${
                        activeTab === 'categories' ? styles.activeTabStyles : ''
                    }`}
                    onClick={() => onTabClick(MenuTabNames.category, 'categories')}
                >
                    Categories
                </LinkBtn>
            )}
            {!hideCardTypeAndPrice && hasCardTypes && (
                <LinkBtn
                    className={`cursor-pointer brands_card_type_filter ${
                        activeTab === 'card' ? styles.activeTabStyles : ''
                    }`}
                    onClick={() => onTabClick(MenuTabNames.cardType, 'card')}
                >
                    Card Type
                </LinkBtn>
            )}
            {!hideCardTypeAndPrice && hasPrice && (
                <LinkBtn
                    className={`cursor-pointer brands_denomination_filter ${
                        activeTab === 'denomination' ? styles.activeTabStyles : ''
                    }`}
                    onClick={() => onTabClick(MenuTabNames.price, 'denomination')}
                >
                    Denomination
                </LinkBtn>
            )}
        </div>
    );
};

const FilterDropdown = ({
    categories,
    pills,
    earnType,
}: {
    categories: FilterByGroupOption[];
    pills: React.ReactNode;
    earnType?: string;
}) => {
    const { filter, applyFilter, clearAllFilters } = useContext(FilterSectionContext);
    const [isToggled, setToggle] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [page, setPage] = useState<MenuTabNames>();

    const containerStyles = useSpring({
        boxShadow: isToggled ? '0px 5px 14px 3px lightgrey' : '0px 0px 0px 0px transparent',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        background: 'white',
        padding: '10px 15px',
        zIndex: 99999,
        opacity: isToggled ? 1 : 0,
        delay: 100,
    });

    const transRef = useSpringRef();
    const transitions = useTransition(page, {
        ref: transRef,
        keys: null,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 100,
    });

    useEffect(() => {
        transRef.start();
    }, [page, transRef]);

    const closeDropdown = useCallback(() => {
        setToggle(false);
    }, []);

    const menu = (
        <Menu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setToggle={setToggle}
            setPage={setPage}
            isToggled={isToggled}
            availableCategories={categories.map((cat) => cat.key)}
            hideEarningType={Boolean(earnType)}
            hideCardTypeAndPrice={earnType === 'Online'}
        />
    );

    const ref = useOutsideClick<HTMLDivElement>(() => setToggle(false));

    const showResults = useCallback(() => {
        applyFilter(filter);
        closeDropdown();
    }, [applyFilter, filter, closeDropdown]);

    const onClearClicked = useCallback(() => {
        clearAllFilters();
        closeDropdown();
    }, [clearAllFilters, closeDropdown]);

    // NOTE: Bad part about this is that all this code runs :(
    // for sake of simplicity it works
    return (
        <div ref={ref} className="mx-8 lg:mx-16 2xl:mx-28 mb-4 relative hidden sm:block">
            <div className={styles.menuContainer}>{menu}</div>
            <animated.div
                style={{
                    ...containerStyles,
                    position: 'absolute',
                    boxSizing: 'border-box',
                    width: '100%',
                    margin: '0 auto',
                    top: '0px',
                    visibility: containerStyles.opacity.to((o) => (o === 0 ? 'hidden' : 'visible')),
                }}
            >
                {menu}
                <div className={styles.dropdownStyles}>
                    {transitions((style, tabName) => {
                        return page && page === tabName ? (
                            <AnimatedDropdown
                                style={{
                                    ...style,
                                    ...animatedDropdownStyles,
                                }}
                            >
                                <Categories
                                    categories={
                                        (categories || []).find((cat) => cat.key === tabName)
                                            ?.options || []
                                    }
                                    closeDropdown={closeDropdown}
                                />
                            </AnimatedDropdown>
                        ) : (
                            <p />
                        );
                    })}
                </div>
                <div className="flex justify-center gap-2">
                    <Btn isSecondary={true} onClick={onClearClicked}>
                        Cancel
                    </Btn>
                    <Btn onClick={showResults}>Show Results</Btn>
                </div>
                {pills}
            </animated.div>
        </div>
    );
};

const AnimatedDropdown = ({
    style,
    children,
}: {
    style: AnimatedProps<CSSProperties>;
    children: React.ReactElement;
}) => {
    return <animated.div style={style}>{children}</animated.div>;
};

interface CategoriesProps {
    categories: FilterByOption[];
    closeDropdown: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

interface ButtonProps {
    active: boolean;
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    hasIcon: boolean;
}

const Button = React.memo(({ active = false, children, onClick, hasIcon }: ButtonProps) => {
    const btnStyles = createStyles({
        btnStyles: [
            `${active ? 'bg-pale-blue font-semibold' : 'bg-white'} px-2 ${
                hasIcon ? 'text-left' : ''
            }`,
            {
                margin: '5px',
                border: '1px solid #005E9D',
                borderSizing: 'border-box',
                borderRadius: '5px',
                width: '220px',
                height: '50px',
                ':focus': {
                    outline: 'none',
                    boxShadow: 'none',
                },
                fontSize: 12,
            },
        ],
    });

    return (
        <button
            className={`brands_card_type_filter_details ${btnStyles.btnStyles}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
});

const btnContainer = createStyle(
    'grid text-brand-dark justify-items-center w-full 2xl:w-11/12 2xl:mx-auto mt-5',
    {
        gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 2fr))',
        height: 'min-content',
    }
);

const isNotSelected = (category: FilterByOption, selections: FilterByOption[]) => {
    return !selections.some(({ value }: FilterByOption) => value === category.value);
};

const Categories = ({ categories }: CategoriesProps) => {
    const { filter, setFilter } = useContext(FilterSectionContext);

    const isActive = (category: string) => {
        const value = filter.selectedOptions.some((cat: FilterByOption) => cat.label === category);
        return value;
    };

    const onClick = (category: FilterByOption) => {
        if (isNotSelected(category, filter.selectedOptions)) {
            setFilter({
                ...filter,
                selectedOptions: [...filter.selectedOptions, category] as FilterByOption[],
            });
            return;
        }
        setFilter({
            ...filter,
            selectedOptions: filter.selectedOptions.filter(
                (opt: FilterByOption) => opt.value !== category.value
            ),
        });
    };

    return (
        <div className="flex items-center min-h-inherit">
            <div className={btnContainer}>
                {categories.map((category: FilterByOption) => {
                    const Icon = categoryIcons[category.label];
                    return (
                        <Button
                            key={category.label}
                            active={isActive(category.label)}
                            onClick={() => onClick(category)}
                            hasIcon={Icon !== undefined}
                        >
                            {Icon && <Icon className="h-8 inline" />} {category.label}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

export default FilterDropdown;
