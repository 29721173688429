import emojiRegex from 'emoji-regex';

import { fromLocaleString } from 'common/utils/strings';

export const required = (value: string | null) => (value || '').trim().length > 0;
export const minLength = (min: number) => (value: string | null) =>
    (value || '').trim().length >= min;
export const maxLength = (max: number) => (value: string | null) =>
    (value || '').trim().length <= max;
export const length = (n: number) => (value: string) => value.trim().length === n;
export const hasLowercase = (value: string) => /[a-z]/.test(value);
export const hasUppercase = (value: string) => /[A-Z]/.test(value);
export const hasNumber = (value: string) => /\d/.test(value);
export const lettersOnly = (value: string) => /^[A-Za-z]+$/.test(value);
export const numbersOnly = (value: string) => /^[0-9]+$/.test(value);
export const isZipCode = (value: string) => /^(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)$/.test(value);
export const isZipCodeShort = (value: string) => /^\d{5}$/.test(value);
export const isEmail = (value: string) => /^[^\s@]+@[^\s@]+\.[-0-9a-zA-Z]+$/.test(value);
export const doesNotContainSlash = (value: string) => !/\//gi.test(value);
export const doesNotHave2aDomain = (value: string) => !/\.[aA-zZ]*\.2a$/gi.test(value);
export const isWithoutEmojis = (value: string | null) => !emojiRegex().test(value || '');
export const isPhoneNumber = (value: string) => /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value);
export const isURL = (value: string) =>
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.test(value);
export const isValidURL = (value: string) =>
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9_~]+([-.]{1}[-a-zA-Z0-9_~]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
    );
export const isSameAs = (base: string) => (value: string) => value.trim() === '' || base === value;
export const isNot = (base: string) => (value: string) => value.trim() === '' || base !== value;
export const isEmpty = (value: string) => value.length === 0;
export const min = (minValue: number) => (value: string) =>
    !value || fromLocaleString(value) >= minValue;
export const max = (maxValue: number) => (value: string) =>
    !value || fromLocaleString(value) <= maxValue;
export const hasNoSpecialChars = (value: string) => {
    const disallowedSpecialCharacters = /[@^*_[\]{};:"\\|<>?]/g;
    return !disallowedSpecialCharacters.test(value);
};

export const containsSpecialChar = (value: string) => {
    const allowedSpecialCharacters = /[ !@#$^*()_+\-[\]{};':"\\|,.<>/?]/g;
    const disallowedSpecialCharacters = /[&=%]/g;

    const containsValidSpecialChar = allowedSpecialCharacters.test(value);
    const doesNotContainIllegalChar = !disallowedSpecialCharacters.test(value);

    return containsValidSpecialChar && doesNotContainIllegalChar;
};

export const isAmexCard = (ccNumber: string) => ccNumber.match(/^3[47]/) === null;

export const isFutureExpDateDate = (date: string) => {
    const dateArr = date.split('/');
    const exYear = +dateArr[1] + 2000;
    const exMonth = +dateArr[0] - 1; // JS months start with 0
    const exDay = new Date(exYear, exMonth + 1, 0).getDate();
    const dateObj = new Date(exYear, exMonth, exDay - 2); // tz fix.
    const dateNow = new Date();
    if (+dateArr[0] > 12 || +dateArr[0] === 0) {
        return false;
    }
    return dateNow < dateObj;
};
