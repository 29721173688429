import {
    firstNameValidator,
    fundraisingGroupValidator,
    lastNameValidator,
    studentNameValidator,
    studentNameValidatorRequired,
} from 'common/modules/form-validation/validators';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import { FormikTextField } from 'components/fields/FormikTextField';
import { Form, Formik } from 'formik';
import React, { useCallback, useContext, useMemo } from 'react';
import { FamilyEnrollmentContext } from '../../../features/family-enrollment/context';
import { BaseStep } from './BaseStep';
import { ButtonControls } from './utils';
import { OrganizationType } from 'common/api/store/models/OrganizationType';

interface FamilyAccountRegistration {
    firstName: string;
    lastName: string;
    studentName: string;
    fundraisingGroup: string;
}

const RegistrationRequestField = typedFormikField<FamilyAccountRegistration>()(FormikTextField);

export const CreateFamilyAccount = () => {
    const { stepHeaders, organization, userAccountInfo, updateUserAccountInfo, goToNextStep } =
        useContext(FamilyEnrollmentContext);

    const initialValues: FamilyAccountRegistration = {
        firstName: userAccountInfo?.firstName || '',
        lastName: userAccountInfo?.lastName || '',
        studentName: userAccountInfo?.studentName || '',
        fundraisingGroup: userAccountInfo?.fundraisingGroup || '',
    };

    const _onSubmit = useCallback(
        async ({
            firstName,
            lastName,
            studentName,
            fundraisingGroup,
        }: FamilyAccountRegistration) => {
            updateUserAccountInfo((_) => ({
                ..._,
                firstName,
                lastName,
                studentName,
                fundraisingGroup,
            }));

            goToNextStep();
        },
        [updateUserAccountInfo, goToNextStep]
    );

    const enrollCode = useMemo(() => localStorage.getItem('RR_enrollmentCode') || '', []);
    const enrollCodeFromP2P =
        enrollCode.startsWith('RR') &&
        (OrganizationType.ParochialSchool === organization?.type ||
            OrganizationType.BandMusic === organization?.type ||
            OrganizationType.AthleticGroup === organization?.type);

    if (!stepHeaders) {
        return <ActivityIndicator />;
    }

    return (
        <BaseStep
            contentClassName="text-md sm:text-lg font-thin"
            heading={stepHeaders.createAccountHeading as string}
            subheading={stepHeaders.createAccountBody}
            subheadingData={{ OrganizationName: organization?.name }}
        >
            <Formik initialValues={initialValues} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <Form>
                        <div>
                            <section className="grid grid-cols-1 md:grid-cols-1 gap-x-6 gap-y-6 my-4 pt-2">
                                <RegistrationRequestField
                                    name="firstName"
                                    label="First Name"
                                    validate={firstNameValidator}
                                />
                                <RegistrationRequestField
                                    name="lastName"
                                    label="Last Name"
                                    validate={lastNameValidator}
                                />
                                <div className="mt-4">
                                    <div className="mb-6 text-brand-dark text-lg">
                                        Who are you earning for?
                                    </div>
                                    <RegistrationRequestField
                                        name="studentName"
                                        label={
                                            <label className="font-bold">
                                                Student's Name{' '}
                                                {!enrollCodeFromP2P ? (
                                                    <span className="text-grey-medium font-normal">
                                                        (optional)
                                                    </span>
                                                ) : null}
                                            </label>
                                        }
                                        validate={
                                            !enrollCodeFromP2P
                                                ? studentNameValidator
                                                : studentNameValidatorRequired
                                        }
                                    />
                                    {!formikProps.errors.studentName ||
                                    !formikProps.touched.studentName ? (
                                        <span className="mt-2 text-grey-medium text-xs">
                                            Enter the name of the person you are earning for; this
                                            could be an athlete, musician, etc.
                                        </span>
                                    ) : null}
                                </div>
                                <div>
                                    <RegistrationRequestField
                                        name="fundraisingGroup"
                                        label={
                                            <label className="font-bold">
                                                Group{' '}
                                                <span className="text-grey-medium font-normal">
                                                    (optional)
                                                </span>
                                            </label>
                                        }
                                        validate={fundraisingGroupValidator}
                                    />
                                    <span className="mt-2 text-grey-medium text-xs">
                                        Team name, classroom, etc.
                                    </span>
                                </div>
                            </section>
                        </div>
                        <ButtonControls />
                    </Form>
                )}
            </Formik>
        </BaseStep>
    );
};
