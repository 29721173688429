import React from 'react';
import classNames from 'classnames';

import { FieldStyles } from './FieldStyles';
import { FieldWrapperProps, FieldWrapper } from './FieldWrapper';

export type TextareaFieldProps = FieldWrapperProps &
    React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextareaField = (props: TextareaFieldProps) => {
    const { label, error, className, ...textareaProps } = props;
    return (
        <FieldWrapper label={label} error={error}>
            {(_props) => (
                <textarea
                    className={classNames(FieldStyles.field, 'block', className, {
                        error: Boolean(error),
                    })}
                    {...textareaProps}
                    {..._props}
                />
            )}
        </FieldWrapper>
    );
};
