// TODO: Fix linting & TS errors in this file. I'm not sure why some of these were ignored?
/* eslint-disable */
import {
    Asset,
    AssetCollection,
    ContentfulClientApi,
    ContentType,
    ContentTypeCollection,
    Entry,
    EntryCollection,
    Space,
    SyncCollection,
} from 'contentful';

// @ts-ignore
import { createClient } from 'contentful/dist/contentful.browser.min.js';

import { propertyNormalizer } from 'common/utils/propertyNormalizer';
import { APIResponse } from 'common/api/models';
import { createAPIError } from 'common/api/utils/createAPIError';
import { CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_SPACE, CONTENTFUL_ENVIRONMENT } from 'common/env';

type ContentfulApI<T> = (...params: any[]) => Promise<T>;

const _contentfulClientApi: ContentfulClientApi = createClient({
    accessToken: CONTENTFUL_ACCESS_TOKEN,
    space: CONTENTFUL_SPACE,
    environment: CONTENTFUL_ENVIRONMENT,
});

const _normalizeContentfulResponse = async <T, E = null>(
    api: ContentfulApI<T>,
    ...params: any[]
): Promise<APIResponse<T, E>> => {
    try {
        const response: T = await api(...params);

        return { data: propertyNormalizer(response) };
    } catch (e: Error | any) {
        return { error: createAPIError(null, e) };
    }
};

export const contentfulApi = {
    async getAsset<Q = null>(id: string, query?: Q): Promise<APIResponse<Asset>> {
        return _normalizeContentfulResponse<Asset>(_contentfulClientApi.getAsset, id, query);
    },
    async getAssets<Q = null>(query?: Q): Promise<APIResponse<AssetCollection>> {
        return _normalizeContentfulResponse<AssetCollection>(_contentfulClientApi.getAssets, query);
    },
    async getContentType(id: string): Promise<APIResponse<ContentType>> {
        return _normalizeContentfulResponse<ContentType>(_contentfulClientApi.getContentType, id);
    },
    async getContentTypes<Q = null>(query?: Q): Promise<APIResponse<ContentTypeCollection>> {
        return _normalizeContentfulResponse<ContentTypeCollection>(
            _contentfulClientApi.getContentTypes,
            query
        );
    },
    async getEntries<T, Q = null, E = null>(
        query?: Q
    ): Promise<APIResponse<EntryCollection<T>, E>> {
        return _normalizeContentfulResponse<EntryCollection<T>, E>(
            _contentfulClientApi.getEntries,
            query
        );
    },
    async getEntry<T, Q = null, E = null>(
        id: string,
        query?: Q
    ): Promise<APIResponse<Entry<T>, E>> {
        return _normalizeContentfulResponse<Entry<T>, E>(
            _contentfulClientApi.getEntry.bind(_contentfulClientApi),
            id,
            query
        );
    },
    async getSpace(): Promise<APIResponse<Space>> {
        return _normalizeContentfulResponse<Space>(_contentfulClientApi.getSpace);
    },
    async parseEntries<T, R, E = null>(raw: R): Promise<APIResponse<EntryCollection<T>, E>> {
        return _normalizeContentfulResponse<EntryCollection<T>, E>(
            _contentfulClientApi.parseEntries,
            raw
        );
    },
    async sync<Q>(query: Q): Promise<APIResponse<SyncCollection>> {
        return _normalizeContentfulResponse<SyncCollection>(_contentfulClientApi.sync, query);
    },
};
