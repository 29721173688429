import { BrandSearchResponse } from './models/BrandSearchResponse';
import { toSearchResult } from './utils/toSearchResult';
import { defaultBrandSearchOptions } from './config';

import { SearchApiQueryParams, searchApiFetch } from './';

import { and, eq } from 'common/utils/searchFilters';
import { toPascalCase } from 'common/utils/strings';
import { BrandsSearchFacet } from 'common/api/search/models/filters/BrandsSearchFacets';

export const brandsOnBonusFilter = eq(toPascalCase(BrandsSearchFacet.IsOnBonus), true);

export const getBrandsOnBonus = async (options: Partial<SearchApiQueryParams> = {}) =>
    toSearchResult(
        await searchApiFetch<BrandSearchResponse>({
            ...defaultBrandSearchOptions,
            ...options,
            filter: options.filter ? and(brandsOnBonusFilter, options.filter) : brandsOnBonusFilter,
        })
    );
