import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import groupBy from 'lodash.groupby';

import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';
import { Organization, emptyOrganization } from 'common/api/crm/models/Organization';
import {
    UnmaskedPhoneNumber,
    emptyUnmaskedPhoneNumber,
} from 'common/api/users/models/UnmaskedPhoneNumber';
import {
    RegistrationRequestWithoutOrg,
    emptyRegistrationRequestWithoutOrg,
} from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { HubspotContactInfo } from 'common/api/crm/organization/enrollment/models';
import {
    createContactInHubspot,
    getOrganizationEnrollmentLog,
    updateEnrollmentProgress,
    UpdateEnrollmentProgressArgs,
} from 'common/api/crm/organization/enrollment';
import { createSelector } from 'reselect';
import { OrganizationType } from 'common/api/crm/models/OrganizationType';
import { initialAsyncActionState, AsyncActionState } from 'common/modules/async-actions/core';
import { uploadVoidedCheckImage } from 'common/api/e-comm/payment-types';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { withRetries } from 'common/utils/withRetries';
import { BillingSelection, setBillingSelection } from 'common/api/e-comm/payment-types';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { addPhone, AddPhoneRequest } from 'common/api/users/twofactor/phone';
import { DeliveryMethod } from 'common/api/users/enums/DeliveryMethod';
import { confirmPhone } from 'common/api/users/twofactor/phone';
import { selectHomeDetails, selectSelectedOrgId } from '../../home/duck';
import { organizationTypeCodes } from 'common/utils/organizationTypeCodes';
import { AchVerificationStatus } from 'common/api/e-comm/ach/models/AchAccount';
import { AchAccountBody } from 'common/api/e-comm/ach';
import { RefObject } from 'react';
import { OrganizationEnrollmentLogEntry } from 'common/api/crm/models/OrganizationEnrollmentLogEntry';

type Dispatcher = ThunkDispatch<FeaturesState, undefined, AnyAction>;

export enum OnlineType {
    VoidedCheck = 'voidedCheck',
    Plaid = 'plaid',
}

export interface CheckUploadErrorState {
    message?: string;
}

export interface IOrgEnrollmentV2 {
    organization: Organization;
    twoFactorPhone: UnmaskedPhoneNumber;
    twoFactorPhoneExtension?: string;
    twoFactorPhoneVerified: boolean;
    userInformation: RegistrationRequestWithoutOrg;
    currentStep: number;
    maxStep: number;
    showAccountLoader: boolean;
    billingSelection: BillingSelection;
    programType: OnlineType | null;
    checkUploadAsync: AsyncActionState<unknown, CheckUploadErrorState>;
    completePlaidStatus: AchVerificationStatus | null;
    enrollmentLogEntries: { [key: number]: OrganizationEnrollmentLogEntry };
    enrollmentLogAsyncState: AsyncActionState;
    billingSelectionAsyncState: AsyncActionState;
    createContactInHubspotAsyncState: AsyncActionState;
    twoFactorPhoneVerifiedAsyncState: AsyncActionState;
    completePlaidStatusAsyncState: AsyncActionState;
    enrollmentLogEntriesAsyncState: AsyncActionState;
    progressBarRef: RefObject<HTMLDivElement> | null;
}

export const initialState: IOrgEnrollmentV2 = {
    organization: emptyOrganization,
    twoFactorPhone: emptyUnmaskedPhoneNumber,
    twoFactorPhoneVerified: false,
    userInformation: emptyRegistrationRequestWithoutOrg,
    currentStep: 0,
    maxStep: 0,
    showAccountLoader: false,
    billingSelection: BillingSelection.None,
    programType: null,
    completePlaidStatus: null,
    enrollmentLogEntries: {},
    checkUploadAsync: initialAsyncActionState,
    enrollmentLogAsyncState: initialAsyncActionState,
    billingSelectionAsyncState: initialAsyncActionState,
    createContactInHubspotAsyncState: initialAsyncActionState,
    twoFactorPhoneVerifiedAsyncState: initialAsyncActionState,
    completePlaidStatusAsyncState: initialAsyncActionState,
    enrollmentLogEntriesAsyncState: initialAsyncActionState,
    progressBarRef: null,
};

const orgEnrollmentV2 = createSlice(initialState, 'UI/OrgEnrollmentV2');

export default orgEnrollmentV2.reducer;

// selectors
export const selectOrganization = (s: FeaturesState) => s.store.ui.orgEnrollmentV2.organization;
export const selectTwoFactorPhone = (s: FeaturesState) => s.store.ui.orgEnrollmentV2.twoFactorPhone;
export const selectUserInformation = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.userInformation;
export const selectCurrentStep = (s: FeaturesState) => s.store.ui.orgEnrollmentV2.currentStep;
export const selectMaxStep = (s: FeaturesState) => s.store.ui.orgEnrollmentV2.maxStep;
export const selectEnrollmentFlowControls = createSelector(
    selectCurrentStep,
    selectMaxStep,
    (currentStep: number, maxStep: number) => ({ currentStep, maxStep })
);
export const selectOrgTypeOptions = createSelector(
    (s: FeaturesState) => s.store.organization.orgTypes,
    (orgTypes: OrganizationType[]) => {
        const types = orgTypes
            ?.filter((x) => x.isEnabled)
            .sort((a, b) => {
                return a.description.localeCompare(b.description);
            });
        const moveToEnd = ['Miscellaneous', 'Other'];
        const { start = [], end = [] } = groupBy(types, (x) =>
            moveToEnd.includes(x.description) ? 'end' : 'start'
        );
        return [...start, ...end];
    }
);
export const selectShowAccountLoader = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.showAccountLoader;
export const selectProgramType = (s: FeaturesState) => s.store.ui.orgEnrollmentV2.programType;
export const selectShowVoidedCheckScreen = (s: FeaturesState) => {
    const programType = selectProgramType(s);
    return programType === OnlineType.VoidedCheck;
};
export const selectShowChooseProgramScreen = (s: FeaturesState) => selectProgramType(s) === null;
export const selectCheckUploadAsyncState = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.checkUploadAsync;
export const selectEnrollmentLogError = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.enrollmentLogAsyncState.error;
export const selectBillingSelectionError = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.billingSelectionAsyncState.error;
export const selectCreateContactInHubspotError = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.createContactInHubspotAsyncState.error;
export const selectCompletePlaidStatusError = (s: FeaturesState) =>
    s.store.ui.orgEnrollmentV2.completePlaidStatusAsyncState.error;

// actions
export const setProgressBarRef = orgEnrollmentV2.configureAction(
    'SET_PROGRESS_BAR_REF',
    (ref: RefObject<HTMLDivElement>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        progressBarRef: ref,
    })
);

export const setOrganizationData = orgEnrollmentV2.configureAction(
    'SET_ORGANIZATION_DATA',
    (newData: Partial<Organization>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        organization: { ...state.organization, ...newData },
    })
);

export const setUserData = orgEnrollmentV2.configureAction(
    'SET_USER_DATA',
    (newData: Partial<RegistrationRequestWithoutOrg>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        userInformation: { ...state.userInformation, ...newData },
    })
);

export const updateCurrentStepBy = orgEnrollmentV2.configureAction(
    'UPDATE_CURRENT_STEP',
    (increment: number) => (state: IOrgEnrollmentV2) => ({
        ...state,
        currentStep: state.currentStep + increment,
    })
);

export const setProgramType = orgEnrollmentV2.configureAction(
    'SET_PROGRAM_TYPE',
    (programType: OnlineType | null) => (state: IOrgEnrollmentV2) => ({
        ...state,
        programType,
    })
);

export const setCurrentStep = orgEnrollmentV2.configureAction(
    'SET_CURRENT_STEP',
    (currentStep: number) => (state: IOrgEnrollmentV2) => {
        // user cannot jump to a step unless they have completed it
        if (state.maxStep < currentStep) {
            return state;
        }

        return {
            ...state,
            currentStep,
        };
    }
);

export const setMaxStep = orgEnrollmentV2.configureAction(
    'SET_MAX_STEP',
    (maxStep: number) => (state: IOrgEnrollmentV2) => ({
        ...state,
        maxStep,
    })
);

export const showAccountCreationLoader = orgEnrollmentV2.configureAction(
    'SHOW_ACCOUNT_CREATION_LOADER',
    (showAccountLoader: boolean) => (state: IOrgEnrollmentV2) => ({
        ...state,
        showAccountLoader,
    })
);
export const setCheckUploadAsyncState = orgEnrollmentV2.configureAction(
    'SET_CHECK_UPLOAD_ASYNC_STATE',
    (asyncData: Partial<AsyncActionState<unknown, CheckUploadErrorState>>) =>
        (state: IOrgEnrollmentV2) => ({
            ...state,
            checkUploadAsync: {
                ...state.checkUploadAsync,
                ...asyncData,
            },
        })
);

export const setOrganizationEnrollmentLog = orgEnrollmentV2.configureAction(
    'SET_ORGANIZATION_ENROLLMENT_LOG_ENTRIES',
    (newState: { [key: number]: OrganizationEnrollmentLogEntry }) => (state: IOrgEnrollmentV2) => ({
        ...state,
        enrollmentLogEntries: {
            ...state.enrollmentLogEntries,
            ...newState,
        },
    })
);

export const updateBillingSelectionRequest = orgEnrollmentV2.configureAction(
    'UPDATE_BILLING_SELECTION_REQUEST',
    (newState: Partial<AsyncActionState>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        billingSelectionAsyncState: {
            ...state.billingSelectionAsyncState,
            ...newState,
        },
    })
);

export const updateEnrollmentLogRequest = orgEnrollmentV2.configureAction(
    'UPDATE_ENROLLMENT_LOG_REQUEST',
    (newState: Partial<AsyncActionState>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        enrollmentLogAsyncState: {
            ...state.enrollmentLogAsyncState,
            ...newState,
        },
    })
);

export const updateCompletePlaidStatusRequest = orgEnrollmentV2.configureAction(
    'UPDATE_COMPLETE_PLAID_STATUS_REQUEST',
    (newState: Partial<AsyncActionState>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        completePlaidStatusAsyncState: {
            ...state.completePlaidStatusAsyncState,
            ...newState,
        },
    })
);

// thunks
export const submitLeadOrgCaptureInfo =
    (payload: HubspotContactInfo) => async (dispatch: Dispatch) => {
        await createContactInHubspot({
            ...payload,
            originalEnrollmentDate: new Date().toLocaleDateString(),
        });
        dispatch(
            setOrganizationData({
                organizationTypeId: payload.organizationTypeId,
                name: payload.organizationName,
            })
        );
        dispatch(
            setUserData({
                email: payload.coordinatorEmailAddress,
            })
        );
        dispatch(updateCurrentStepBy(1));
    };

export const uploadCheck = (orgId: string, image: File) => async (dispatch: Dispatch) => {
    dispatch(setCheckUploadAsyncState({ loading: true }));
    const response = await uploadVoidedCheckImage(orgId, image);
    let error;

    if (response.error) {
        if (hasErrorWithStatus(response, StatusCode.PayloadTooLarge)) {
            error = {
                message: response.error.body || 'File is too large. Please upload a smaller image.',
            };

            dispatch(setCheckUploadAsyncState({ error }));
        } else {
            dispatch(
                setCheckUploadAsyncState({
                    error: {
                        message:
                            'Please try again or use a different image. (Image must be one of the folowing file types: .jpeg, .bpm, .gif, .png, .tiff, or .pdf and smaller than 15mb.)',
                    },
                })
            );
        }
    } else {
        updateEnrollmentLog({
            state: ProgressState.CheckImageUploaded,
            organizationId: orgId,
            version: 2,
        });
    }
    dispatch(setCheckUploadAsyncState({ loading: false }));
    return Boolean(response.error);
};
export const updateEnrollmentLog =
    (payload: UpdateEnrollmentProgressArgs) => async (dispatch: Dispatch) => {
        dispatch(
            updateEnrollmentLogRequest({
                args: [payload],
                loading: true,
            })
        );

        const result = await withRetries(updateEnrollmentProgress)(payload);

        if (result.error) {
            dispatch(
                updateEnrollmentLogRequest({
                    loading: false,
                    lastUpdate: Date.now(),
                    error: result.error,
                })
            );
            return;
        }
        dispatch(
            updateEnrollmentLogRequest({
                loading: false,
                lastUpdate: Date.now(),
            })
        );
    };

export const updateContactInHubspotRequest = orgEnrollmentV2.configureAction(
    'UPDATE_CONTACT_IN_HUBSPOT_REQUEST',
    (newState: Partial<AsyncActionState>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        createContactInHubspotAsyncState: {
            ...state.createContactInHubspotAsyncState,
            ...newState,
        },
    })
);

export const updateOrganizationEnrollmentLogRequest = orgEnrollmentV2.configureAction(
    'UPDATE_ORGANIZATION_ENROLLMENT_LOG_REQUEST',
    (newState: Partial<AsyncActionState>) => (state: IOrgEnrollmentV2) => ({
        ...state,
        enrollmentLogEntriesAsyncState: {
            ...state.enrollmentLogEntriesAsyncState,
            ...newState,
        },
    })
);

export const updateBillingSelection =
    (orgId: string, billingSelection: BillingSelection) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        dispatch(
            updateBillingSelectionRequest({
                loading: true,
            })
        );

        const result = await withRetries(setBillingSelection)(orgId, billingSelection);

        dispatch(
            updateBillingSelectionRequest({
                loading: false,
                lastUpdate: Date.now(),
                error: result.error || null,
            })
        );

        if (!result.error) {
            // updates the billingSelection sent to the B/E
            dispatch(
                orgEnrollmentV2.update({
                    billingSelection,
                })
            );
        }

        return !result.error; //NOTE: signal it was successful/unsuccessful
    };

export const saveForLater = (orgId: string) => async (dispatch: Dispatch) => {
    updateEnrollmentLog({
        state: ProgressState.SavedForLater,
        organizationId: orgId,
        version: 2,
    })(dispatch);
};

export const completedForm = () => async (dispatch: Dispatch) => {
    dispatch(setMaxStep(5));
    dispatch(setCurrentStep(5));
};

export const addPhoneThunk = (request: AddPhoneRequest) => async (dispatch: Dispatch) => {
    const { phoneNumber, deliveryMethod, phoneExtension } = request;

    const addPhoneArgs = {
        phoneNumber,
        deliveryMethod,
        // add phoneExtension if DeliveryMethod is "Voice"
        ...(deliveryMethod === DeliveryMethod.Text ? {} : { phoneExtension }),
    };
    const response = await addPhone(addPhoneArgs);

    if (!response.error) {
        dispatch(
            orgEnrollmentV2.update({
                twoFactorPhone: {
                    id: response.data.phoneId,
                    phoneNumber: request.phoneNumber,
                },
                twoFactorPhoneExtension: request.phoneExtension,
                twoFactorPhoneVerified: false,
            })
        );
    }

    return response;
};

export const validateTwoFactorRequest =
    (code: string, phoneId: string, blackBoxValue: string) => async (dispatch: Dispatch) => {
        const response = await confirmPhone(phoneId, code, blackBoxValue ?? '');

        dispatch(
            orgEnrollmentV2.update({
                twoFactorPhoneVerifiedAsyncState: {
                    args: [code, phoneId, blackBoxValue],
                    error: response.error,
                    lastUpdate: response.error ? 0 : Date.now(),
                    loading: false,
                },
            })
        );

        return response;
    };

export const skipTwoFactorThunk = () => (dispatch: Dispatcher, getState: () => FeaturesState) => {
    const orgId = selectSelectedOrgId(getState()) || '';

    updateEnrollmentLog({
        state: ProgressState.TwoFactorSetup,
        organizationId: orgId,
        version: 2,
    })(dispatch);

    dispatch(
        orgEnrollmentV2.update({
            twoFactorPhoneVerified: true,
        })
    );
};

export const submitTwoFactorVerificationCodeThunk =
    (code: string, phoneId: string, blackBoxValue: string) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        const response = await validateTwoFactorRequest(
            code,
            phoneId,
            blackBoxValue ?? ''
        )(dispatch);
        const codeVerifiedSuccessfully = Boolean(!response.error);

        if (!codeVerifiedSuccessfully) {
            return;
        }

        dispatch(updateContactInHubspot);
        const failedUpdateContactInHubspot = selectCreateContactInHubspotError(getState());

        if (failedUpdateContactInHubspot) {
            return;
        }

        // Update enrollment log
        const orgId = selectSelectedOrgId(getState()) || '';

        updateEnrollmentLog({
            state: ProgressState.TwoFactorSetup,
            organizationId: orgId,
            version: 2,
        })(dispatch);

        // NOTE: Important to let this update here. If the contact is not
        // updated in Hubspot the user should see a error message and try again.
        dispatch(
            orgEnrollmentV2.update({
                twoFactorPhoneVerified: codeVerifiedSuccessfully,
            })
        );
    };

export const updateContactInHubspot = async (dispatch: Dispatch, getState: () => FeaturesState) => {
    const { ui } = getState().store;
    const { twoFactorPhone, twoFactorPhoneExtension } = ui.orgEnrollmentV2;
    const homeDetails = selectHomeDetails(getState());

    const orgType = String(homeDetails?.defaultOrganization?.type || '');
    const orgTypeId = organizationTypeCodes[orgType];

    const createContactInHubspotArgs: HubspotContactInfo = {
        coordinatorEmailAddress: homeDetails?.profile.email || '',
        organizationName: homeDetails?.defaultOrganization?.name || '',
        organizationTypeId: orgTypeId?.toString() || '',
        coordinatorPhoneNumber: twoFactorPhone.phoneNumber,
        coordinatorPhoneNumberExtension: twoFactorPhoneExtension,
    };

    dispatch(
        updateContactInHubspotRequest({
            loading: true,
        })
    );

    const createContactInHubspotResponse = await createContactInHubspot(createContactInHubspotArgs);

    dispatch(
        updateContactInHubspotRequest({
            loading: false,
            lastUpdate: Date.now(),
            error: createContactInHubspotResponse.error || null,
        })
    );
};

export const choosePaperOnlyThunk = (orgId: string) => async (dispatch: Dispatcher) => {
    dispatch(updateBillingSelection(orgId, BillingSelection.Check));

    dispatch(
        updateEnrollmentLog({
            state: ProgressState.PaperOnlySelected,
            organizationId: orgId,
            version: 2,
        })
    );
};

export const plaidSetupFailed =
    () => async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        const orgId = selectSelectedOrgId(getState()) || '';

        await updateEnrollmentLog({
            state: ProgressState.PlaidFailure,
            organizationId: orgId,
            version: 2,
        })(dispatch);
    };

export const completePlaidSetup =
    (payload: AchAccountBody) => async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        const orgId = selectSelectedOrgId(getState()) || '';

        await updateEnrollmentLog({
            state: ProgressState.PlaidSuccess,
            organizationId: orgId,
            version: 2,
        })(dispatch);

        const { verificationStatus } = payload;

        dispatch(
            orgEnrollmentV2.update({
                completePlaidStatus: verificationStatus,
                completePlaidStatusAsyncState: {
                    loading: false,
                    args: [],
                    error: null,
                    lastUpdate: Date.now(),
                },
            })
        );
    };

export const refreshEnrollmentLog =
    (cacheDuration = -1) =>
    async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        const asyncState = selectOrganizationEnrollmentLogEntriesAsyncState(getState());

        // if it is already loading then return
        if (asyncState.loading) {
            return;
        }

        // if there is a cached response and cacheDuration is defined and it is recent enough
        if (
            asyncState.lastUpdate > 0 &&
            cacheDuration > 0 &&
            Date.now() - asyncState.lastUpdate <= cacheDuration
        ) {
            return;
        }

        dispatch(
            updateOrganizationEnrollmentLogRequest({
                loading: true,
            })
        );

        const orgEnrollmentLog = await getOrganizationEnrollmentLog();
        const isSuccess = !orgEnrollmentLog.error;

        if (isSuccess) {
            dispatch(setOrganizationEnrollmentLog(orgEnrollmentLog.data || []));
        }

        dispatch(
            updateOrganizationEnrollmentLogRequest({
                loading: false,
                error: orgEnrollmentLog.error,
                lastUpdate: isSuccess ? Date.now() : 0,
            })
        );
    };

export const setAccountComplete =
    () => async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        dispatch(refreshEnrollmentLog(2000));

        const { error } = selectOrganizationEnrollmentLogEntriesAsyncState(getState());
        if (error) {
            return;
        }

        // checks if the user already completed the account setup (to avoid updating orgEnrollment incorrectly.)
        const orgEnrollmentLogEntries = Object.values(
            selectOrganizationEnrollmentLogEntries(getState())
        );
        if (
            orgEnrollmentLogEntries.some(
                (entry) => entry.state === ProgressState.AccountSetupComplete
            )
        ) {
            return;
        }

        const orgId = selectSelectedOrgId(getState());
        if (!orgId) {
            return;
        }

        await dispatch(
            updateEnrollmentLog({
                state: ProgressState.AccountSetupComplete,
                version: 2,
                organizationId: orgId,
            })
        );
    };

export const selectBillingSelection = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.billingSelection;
};

export const selectBillingSelectionAsyncState = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.billingSelectionAsyncState;
};

export const selectTwoFactorPhoneVerified = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.twoFactorPhoneVerified;
};

export const selectTwoFactorPhoneVerifiedAsyncState = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.twoFactorPhoneVerifiedAsyncState;
};

export const selectProgressBarRef = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.progressBarRef;
};

export const selectOrganizationEnrollmentLogEntries = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.enrollmentLogEntries;
};

export const selectOrganizationEnrollmentLogEntriesAsyncState = (state: FeaturesState) => {
    return state.store.ui.orgEnrollmentV2.enrollmentLogEntriesAsyncState;
};
