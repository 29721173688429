import React, { useContext, useState } from 'react';

import { YesNoQuestion } from '../components/YesNoQuestion';
import { EnrollmentContext } from '../context';
import { OrgProfileQuestionWrapper } from '../steps/OrgProfileQuestionWrapper';

import { InvalidFeedback } from 'components/invalid-feedback/InvalidFeedback';

interface YesNoOrgProfileQuestionProps {
    question: string;
    progressStep: number[];
}

export const YesNoOrgProfileQuestion = ({
    question,
    progressStep,
}: YesNoOrgProfileQuestionProps) => {
    const { goToNextStep, setOrganization, organization } = useContext(EnrollmentContext);
    const [answered, setAnswered] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const setAnswer = (answer: boolean | null) => {
        setOrganization({
            ...organization,
            answers: { ...organization?.answers, [question]: answer as boolean },
        });
        setAnswered(true);
    };

    const submit = () => {
        setSubmitted(true);
        if (answered) {
            goToNextStep();
        }
    };

    return (
        <OrgProfileQuestionWrapper stepProgress={progressStep}>
            <YesNoQuestion className="mt-32 sm:mt-36" question={question} setAnswer={setAnswer} />
            {!answered && submitted && (
                <InvalidFeedback className="text-error text-sm pt-1 text-center" id={question}>
                    Please choose one of the options
                </InvalidFeedback>
            )}
            <button
                onClick={submit}
                className="my-9 m-auto block font-semibold text-lg underline text-brand"
            >
                Next
            </button>
        </OrgProfileQuestionWrapper>
    );
};
