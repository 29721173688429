import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';

import { PillOption } from '../../../components/pills/Pills';

import PillSection from './PillSection';
import { SortByOption } from './SortBy';
import {
    convertFacetIntoGroupItem,
    convertFacetIntoRangeGroupItem,
    FilterByOption,
    FilterData,
    MenuTabNames,
} from './FilterBy';
import FilterDropdown from './FilterDropdown';
import { SortAndHeaderSection } from './SortAndHeaderSection';
import { ContentfulContext } from './ContentfulView';

import { FeaturesState } from 'common/features/featuresReducer';
import { brandsSearchByPrefix } from 'common/features/store/duck/search-generic/duck';
import {
    getBrandsFilters,
    defaultBrandFilterFacets,
} from 'common/api/search/getBrandsByCategoryName';
import {
    BrandsSearchFacets,
    BrandsSearchFacet,
} from 'common/api/search/models/filters/BrandsSearchFacets';
import { or, and, eq, facetPOST } from 'common/utils/searchFilters';
import ResponsiveContainer, {
    ContainerType,
} from 'components/responsive-containers/ResponsiveContainer';
import { toPascalCase } from 'common/utils/strings';
import { noop } from 'common/utils';
import { BonusToggle } from 'components/bonus-toggle/BonusToggle';
import { brandsOnBonusFilter } from 'common/api/search/getBrandsOnBonus';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';
import { AThroughZFilter, getSearchTerm } from './AlphabetFilterUtils';
import { EarnTypeConstructorFilter } from 'common/api/constructor/util';
import { SearchFacet } from 'common/api/search/models/facets/SearchFacet';
import { selectIsConstructorBrowseEnabledWeb } from 'common/features/store/duck/ffs';
import { useHistory } from 'react-router-dom';
import { temporaryStorage } from 'common/utils/persistentStorage';
import useQueryParams from 'hooks/useQueryParams';
import { Routes } from 'routes';

const sortOptions: SortByOption[] = [
    { text: 'Highest Earnings', value: 'MaxRebate desc', iconType: 'arrows' },
    { text: 'Most Popular', value: 'PopularityRank asc', iconType: 'list' },
    { text: 'Name (A-Z)', value: 'Name asc', iconType: 'a-z' },
    { text: 'Name (Z-A)', value: 'Name desc', iconType: 'a-z' },
];

export interface FilterSectionContextValues {
    filter: FilterData;
    setFilter: (filter: FilterData) => unknown;
    appliedFilter: FilterData;
    applyFilter: (filter: FilterData) => unknown;
    clearAllFilters: () => unknown;
}

const initialFilterState = { selectedOptions: [], onBonusOnly: false };

const filterParam = { giftcards: 'Gift Cards' };

export const FilterSectionContext = React.createContext<FilterSectionContextValues>({
    filter: initialFilterState,
    setFilter: noop,
    applyFilter: noop,
    appliedFilter: initialFilterState,
    clearAllFilters: noop,
});

const FilterSection = ({
    visible = true,
    renderBonusSwitch = true,
    showAZFilter = false,
    fixedFilter = '',
    hideCategories = [],
    earnType,
}: {
    visible?: boolean;
    renderBonusSwitch?: boolean;
    showAZFilter?: boolean;
    fixedFilter?: string;
    hideCategories?: MenuTabNames[];
    earnType?: EarnTypeConstructorFilter;
}) => {
    const appliedTerm = useSelector((state: FeaturesState) => state.store.search.appliedTerm);
    const [facets, setFacets] = useState<Partial<BrandsSearchFacets> | null>();
    const [showBonus, setShowBonus] = useState<boolean>(false);
    const globalFilters = useGlobalBrandFilters();

    // Contentful Related State:
    // used to determine if we need to contextualize the featured-brands with a brandListId that comes
    // from contentful
    const { isLoading: isLoadingPromotion, filterList } = useContext(ContentfulContext);

    const dispatch = useDispatch<any>();
    const [, setTerm] = useState('');
    const constructorSearchEnabled = useSelector(selectIsConstructorBrowseEnabledWeb);

    const [sortedBy, setSortedBy] = useState<SortByOption>(sortOptions[1]);
    const [appliedFilter, setAppliedFilter] = useState<FilterData>(initialFilterState);
    const [internalFilters, setInternalFilters] = useState<FilterData>(appliedFilter);

    const toggleBonusFilter = (checked: boolean) => {
        setShowBonus(checked);
    };
    const history = useHistory();
    const params = useQueryParams();

    useEffect(() => {
        const earningType = params.get('earning-type');
        if (constructorSearchEnabled && earningType === 'giftcards') {
            const filter: FilterData = {
                onBonusOnly: false,
                selectedOptions: [
                    {
                        group: MenuTabNames.earningsType,
                        label: filterParam.giftcards,
                        value: `(EarningsType/any(t: t eq '${filterParam.giftcards}'))`,
                    },
                ],
            };
            setAppliedFilter(filter);
            params && history.replace(Routes.OnBonus);
        } else {
            if (history.action === 'POP') {
                tempFilterStorage
                    .get()
                    .then((data) => data?.appliedFilter && setAppliedFilter(data?.appliedFilter));
            } else {
                tempFilterStorage.set({ appliedFilter: initialFilterState });
            }
        }
    }, [history, constructorSearchEnabled]);

    useEffect(() => {
        setInternalFilters(appliedFilter);
        if (window && window.sessionStorage) {
            tempFilterStorage.set({ appliedFilter });
        }
    }, [appliedFilter]);

    const applyFilter = useCallback(
        (filterData: FilterData) => {
            setAppliedFilter(filterData);
        },
        [setAppliedFilter]
    );

    const removeOptionFromFilter = useCallback(
        (option: PillOption) => {
            setInternalFilters((filter) => {
                return {
                    ...filter,
                    selectedOptions: filter.selectedOptions.filter(
                        (opt: FilterByOption) => opt.value !== option.value
                    ),
                };
            });
        },
        [setInternalFilters]
    );

    const removeOptionFromAppliedFilters = useCallback(
        (option: PillOption) => {
            setAppliedFilter((filter) => {
                return {
                    ...filter,
                    selectedOptions: filter.selectedOptions.filter(
                        (opt: FilterByOption) => opt.value !== option.value
                    ),
                };
            });
        },
        [setAppliedFilter]
    );

    const categories = useMemo(() => {
        if (!facets) {
            return [];
        }

        const supportedTypes = facets.supportedTypes || [];
        const productTypeGroup = convertFacetIntoGroupItem(
            MenuTabNames.cardType,
            'Product Type',
            supportedTypes
        );
        const isShipToHomeEligible = facets.isShipToHomeEligible || [];
        if (isShipToHomeEligible) {
            productTypeGroup.options.push({
                group: BrandsSearchFacet.IsShipToHomeEligible,
                label: 'Ship to Home',
                value: eq(toPascalCase(BrandsSearchFacet.IsShipToHomeEligible), true),
            });
        }

        const categoriesGroup = convertFacetIntoGroupItem(
            MenuTabNames.category,
            'Categories',
            facets && facets.categories ? facets.categories : []
        );

        const minDenominationGroup = convertFacetIntoRangeGroupItem(
            MenuTabNames.price,
            'Minimum Denomination',
            facets && facets?.price ? facets.price : []
        );

        if (!constructorSearchEnabled) {
            return (
                [categoriesGroup, productTypeGroup, minDenominationGroup]
                    // filters out the category if it is included into hideCategory array.
                    .filter((cat) => !hideCategories.includes(cat.key))
            );
        } else {
            const earnTypeGroup = convertFacetIntoGroupItem(
                MenuTabNames.earningsType,
                'Earnings Type',
                [
                    { count: 1, value: 'Gift Cards' },
                    { count: 1, value: 'Online' },
                ] as any[] as SearchFacet[]
            );

            return (
                [earnTypeGroup, categoriesGroup, productTypeGroup, minDenominationGroup]
                    // filters out the category if it is included into hideCategory array.
                    .filter((cat) => !hideCategories.includes(cat.key))
            );
        }
    }, [facets, hideCategories, constructorSearchEnabled]);

    const filter = useMemo(() => {
        // group the filters by their type
        const groupedFilters = groupBy(
            appliedFilter.selectedOptions,
            ({ group }: FilterByOption) => group
        );

        // generate a list of query string for each filter group. E.G: Categories/SupportedTypes/Prices
        const filters = Object.values(groupedFilters).map((categoryGroup: FilterByOption[]) =>
            or(...categoryGroup.map((option: FilterByOption) => option.value))
        );

        // if we want to display a subset of all brands on this page, we can use a list of brands as a filter
        // for example: brands on bonus, user favorites, etc.
        if (filterList && filterList.length > 0) {
            const listFilter = `search.in(Id, '${filterList.join()}')`;
            filters.push(listFilter);
        }

        if (showBonus || appliedFilter.onBonusOnly) {
            filters.push(brandsOnBonusFilter);
        }

        if (fixedFilter) {
            filters.push(fixedFilter);
        }

        // Concatenate the different queries w/ AND
        return and(...filters, globalFilters);
    }, [appliedFilter, filterList, showBonus, globalFilters, fixedFilter]);

    // SideEffect: fetch brands based off search, filter and sort
    useEffect(() => {
        // NOTE: ONLY load the brands AFTER we've loaded the contentful data.
        if (!isLoadingPromotion) {
            const [term, prefix] = getSearchTerm(appliedTerm);

            if (filter?.includes("search.in(Id, '')")) {
                return;
            }

            brandsSearchByPrefix
                .requestData({
                    // NOTE: not part of the query
                    prefix: prefix,

                    // NOTE: Cognitive search query fields
                    facets: ['SupportedTypes', 'Categories,count:30', 'Price,interval:5'],
                    filter,
                    search: term,
                    orderby: sortedBy.value,
                    searchFields: 'Name,KeyWords,Suggestions',
                    queryType: 'full',
                    earnType,
                })(dispatch)
                .then(() => {
                    // NOTE: used to control when the `term` should render in the empty-results page
                    // fixes issue where EmptyResults page would render the `appliedTerm` immediately
                    // rather than wait to see if the new search returned results or not
                    setTerm(appliedTerm);
                });
        }
    }, [dispatch, appliedTerm, filter, sortedBy, isLoadingPromotion, earnType]);

    const getPills = (filterData: FilterData) => {
        const pills = filterData.selectedOptions.map(({ label, value }: FilterByOption) => ({
            text: label,
            value,
        }));
        return pills.filter((pill) => pill) as PillOption[];
    };

    // resets both filters
    const clearAllFilters = useCallback(() => {
        setInternalFilters(initialFilterState);
        setAppliedFilter(initialFilterState);
    }, [setInternalFilters, setAppliedFilter]);

    // SideEffect: fetch all categories
    useEffect(() => {
        const defaultFacets = [
            ...defaultBrandFilterFacets,
            facetPOST(toPascalCase(BrandsSearchFacet.IsShipToHomeEligible)),
        ];

        getBrandsFilters({
            facets: defaultFacets,
        }).then((data) => {
            setFacets(data.data?.searchFacets);
        });
    }, [dispatch]);

    return visible ? (
        <FilterSectionContext.Provider
            value={{
                filter: internalFilters,
                setFilter: setInternalFilters,
                applyFilter,
                appliedFilter: appliedFilter,
                clearAllFilters,
            }}
        >
            <div>
                <SortAndHeaderSection
                    showAZFilter={showAZFilter}
                    categories={categories}
                    currentFilter={appliedFilter}
                    setFilters={(data: FilterData) => setAppliedFilter(data)}
                    sortOptions={sortOptions}
                    sortedBy={sortedBy}
                    setSortedBy={setSortedBy}
                />
                {showAZFilter && <AThroughZFilter />}
                <ResponsiveContainer For={ContainerType.desktop} defaultVisibility>
                    <FilterDropdown
                        categories={categories}
                        earnType={earnType}
                        pills={
                            <div className="my-4">
                                <PillSection
                                    clearFilters={clearAllFilters}
                                    options={getPills(internalFilters)}
                                    onRemove={removeOptionFromFilter}
                                />
                                {renderBonusSwitch && (
                                    <div className="flex justify-end">
                                        <BonusToggle
                                            toggleFunc={toggleBonusFilter}
                                            currentBonusState={showBonus}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                    />
                    <div className="mb-10">
                        <PillSection
                            clearFilters={clearAllFilters}
                            options={getPills(appliedFilter)}
                            onRemove={removeOptionFromAppliedFilters}
                        />
                        {renderBonusSwitch && (
                            <div className="flex justify-end mx-8 lg:mx-16 2xl:mx-28">
                                <BonusToggle
                                    toggleFunc={toggleBonusFilter}
                                    currentBonusState={showBonus}
                                />
                            </div>
                        )}
                    </div>
                </ResponsiveContainer>
            </div>
        </FilterSectionContext.Provider>
    ) : null;
};

export default FilterSection;

export type Filter_Content = {
    appliedFilter: FilterData;
};

export const tempFilterStorage = temporaryStorage<Filter_Content | undefined | null>(
    'filter_content',
    'v1',
    undefined,
    JSON.parse
);
