import { createStyles } from 'utils/createStyle';

export const StepperStyles = createStyles({
    wrapper:
        'flex items-center max-w-2xl mx-auto pt-4 sm:pt-10 px-4 sm:px-8 pb-2 border-grey-6 border-b sm:border-b-0',
    separator: 'hidden sm:flex flex-auto sm:mb-10 mt-0 sm:mx-1 border-t-2 border-brand',
    stepWrapper: 'flex items-center flex-row flex-1 sm:flex-col text-brand-dark w-16 outline-none',
    iconWrapper: 'h-16 w-16 p-2 mt-0 sm:h-14 sm:p-0 sm:w-12 sm:py-3',
    iconSelected: 'sm:transition-transform sm:transform sm:duration-200 sm:scale-150',
    stepMobileHeader: 'block text-sm font-bold text-brand sm:sr-only',
    stepTitle:
        'text-sm font-bold text-brand sm:font-light sm:text-center sm:text-md sm:text-brand-dark',
    stepNext: 'text-sm font-light text-brand-dark sm:sr-only',
    hiddenMobile: 'sr-only sm:not-sr-only',
});
