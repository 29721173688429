import React from 'react';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import * as RadioGroup from '@radix-ui/react-radio-group';
import * as Label from '@radix-ui/react-label';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'styles/settings';
import { createStyle, createStyles } from '../../utils/createStyle';

export const styles = createStyles({
    StyledRadio: createStyle('align-middle', {
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        borderRadius: '50%',
        boxShadow: `inset 0 0 0 2px ${colors.brand}`,
        width: 15,
        height: 15,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& ~ &': { marginLeft: 5 },

        '&:focus': {
            outline: 'none',
            boxShadow: `inset 0 0 0 1px ${colors.brand}, 0 0 0 1px ${colors.brand}`,
        },
    }),
    StyledIndicator: createStyle('bg-black text-brand', {
        width: 7,
        height: 7,
        borderRadius: '50%',
        backgroundColor: colors.brand,
    }),
    StyledDisabledIndicator: ['text-grey-3'],
    optionStyle: createStyle('mb-8 text-brand cursor-pointer align-middle', {}),
    optionDisabledStyle: createStyle('mb-8 text-grey-3 cursor-pointer align-middle', {}),
});

export interface RadioOption {
    value: string;
    text: string;
    disabled?: boolean;
}

export interface RadioProps {
    options: RadioOption[];
    value: string;
    onChange: (value: RadioOption) => void;
    extraStyles?: string;
}

export const Radio = ({ options, onChange, value, extraStyles }: RadioProps) => {
    const internalOnChange = (newValue: string) => {
        const selectedOption = options.filter((option) => option.value === newValue)[0];
        onChange(selectedOption);
    };

    return (
        <RadioGroup.Root value={value} onValueChange={(e) => internalOnChange(e.target.value)}>
            {options.map((option) => (
                <div key={option.value} className="mb-2 text-left">
                    <RadioGroup.Item
                        disabled={option.disabled}
                        value={option.value}
                        id={option.value}
                        className={styles.StyledRadio}
                        arial-label={`Sort by: ${option.text}`}
                    >
                        {!option.disabled && (
                            <RadioGroup.Indicator className={styles.StyledIndicator} />
                        )}
                        {option.disabled && (
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                className={styles.StyledDisabledIndicator}
                            />
                        )}
                    </RadioGroup.Item>
                    <Label.Root
                        className={classNames(
                            !option.disabled ? styles.optionStyle : styles.optionDisabledStyle,
                            'ml-2',
                            option.value === value ? 'font-bold' : '',
                            extraStyles
                        )}
                        htmlFor={option.value}
                    >
                        {option.text}
                    </Label.Root>
                </div>
            ))}
        </RadioGroup.Root>
    );
};
