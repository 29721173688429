import { AnyAction } from 'redux';

import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { createSlice } from 'common/modules/create-slice';

import { FeaturesState } from 'common/features/featuresReducer';
import { selectOrganization, selectUserInformation } from '../ui/org-enrollment-v2';
import { enrollmentV2Register, EnrollmentV2RegisterRequest } from 'common/api/bff';
import { ThunkDispatch } from 'redux-thunk';
import { EnrollSourceApplicationIds } from 'common/api/auth/models/RegistrationRequestWithoutOrg';

type Dispatcher = ThunkDispatch<LocalEndpointState, undefined, AnyAction>;

export interface LocalEndpointState {
    registerAsyncState: AsyncActionState;
    oneTimeCode: string;
}

const initialLocalEndpointState = {
    registerAsyncState: initialAsyncActionState,
    oneTimeCode: '',
};

const { update, reducer, configureAction } = createSlice(initialLocalEndpointState, 'LOCAL');
export const localReducer = reducer;
export const updateLocal = update;

export const submitEnrollmentRequest = createApiThunk(
    (payload: EnrollmentV2RegisterRequest) => enrollmentV2Register(payload),
    () => (state, result) => {
        return result
            ? update({
                  oneTimeCode: result.userRegistrationResponse.oneTimeCode,
                  registerAsyncState: state,
              })
            : update({ registerAsyncState: state });
    }
);

export const resetCreateAccountAsyncState = configureAction(
    'RESET_ASYNC_STATE',
    () => (state: LocalEndpointState) => ({
        ...state,
        registerAsyncState: initialAsyncActionState,
    })
);

export const enrollmentV2RegisterThunk =
    (
        password: string,
        blackBoxValues: { blackBoxValue: string | null; secondaryBlackBoxValue: string | null },
        captchaResponse: string
    ) =>
    async (dispatch: Dispatcher, getState: () => FeaturesState) => {
        const state = getState();
        const org = selectOrganization(state);
        const userInfo = selectUserInformation(state);

        const v2EnrollmentPayload = {
            account: {
                email: userInfo.email,
                password,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                captchaResponse: captchaResponse,
                enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
            },
            organization: {
                name: org.name,
                address: {
                    address1: org.address.address1,
                    address2: org.address.address2,
                    city: org.address.city,
                    state: org.address.state,
                    zipCode: org.address.zipCode,
                },
                organizationTypeId: parseInt(org.organizationTypeId, 10),
                phoneNumber: org.phoneNumber,
                webSite: org.website as string,
            },
            blackBoxValue: blackBoxValues.blackBoxValue as string,
            secondaryBlackBoxValue: blackBoxValues.secondaryBlackBoxValue as string,
        };

        dispatch(submitEnrollmentRequest(v2EnrollmentPayload));
    };

export const selectRegisterAsyncState = (state: FeaturesState) =>
    state.store.local.registerAsyncState;
export const selectOneTimeCode = (state: FeaturesState) => state.store.local.oneTimeCode;
