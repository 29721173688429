import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { FamilyEnrollmentView } from 'features/family-enrollment/context';
import { Routes } from 'routes';
import * as formSteps from 'features/family-enrollment/steps';
import { FamilyLanding } from './FamilyLanding';
import { Head } from 'components/head/Head';
import logo from 'media/images/RaiseRightLogo.svg';
import { createStyles } from 'utils/createStyle';
import {
    faUser as faUserSelected,
    faPenAlt as faPenAltSelected,
    faLockAlt as faLockAltSelected,
} from '@fortawesome/pro-solid-svg-icons';

import { faUser, faPenAlt, faLockAlt } from '@fortawesome/pro-light-svg-icons';
import {
    IStep,
    LitePlusStepper,
} from 'features/coordinator/enroll/steps/LitePlusStepper/LitePlusStepper';
import { Metadata } from 'components/metadata';
import { MetadataPage } from 'components/metadata/constants';

const styles = createStyles({
    masthead: 'shadow-lg flex justify-center pt-5 pb-3 sm:pt-6 sm:pb-4',
    logo: 'w-40 sm:w-60',
});

const EnrollmentFlow = () => {
    // NOTE: Replace the Dummy components with your own
    const steps: IStep[] = [
        {
            title: 'Create Account',
            icon: faUser,
            iconSelected: faUserSelected,
            routes: [Routes.EnrollFamilyCreateAccount],
            exact: true,
        },
        {
            title: 'Sign-in Information',
            icon: faPenAlt,
            iconSelected: faPenAltSelected,
            routes: [Routes.EnrollFamilySignInInfo],
            exact: true,
        },
        {
            title: 'Account Security',
            icon: faLockAlt,
            iconSelected: faLockAltSelected,
            routes: [
                Routes.EnrollFamilySecurityQuestions,
                Routes.EnrollFamilySetupRecoveryPhone,
                Routes.EnrollFamilyVerifyRecoveryPhone,
            ],
            exact: false,
        },
    ];

    return (
        <Switch>
            <Route exact path={Routes.Enroll}>
                <Metadata page={MetadataPage.enroll}>
                    <FamilyLanding />
                </Metadata>
            </Route>
            <>
                <Head title="Family Enrollment" />
                <header className={styles.masthead}>
                    <img className={styles.logo} src={logo} alt="RaiseRight" />
                </header>
                <main className="max-w-lg mx-auto">
                    <LitePlusStepper steps={steps} />
                    <Route path={Routes.EnrollFamilyCreateAccount}>
                        <formSteps.CreateFamilyAccount />
                    </Route>
                    <Route path={Routes.EnrollFamilySignInInfo}>
                        <formSteps.SignInInfoStep />
                    </Route>
                    <Route path={Routes.EnrollFamilySecurityQuestions}>
                        <formSteps.SecurityQuestionStep />
                    </Route>
                    <Route path={Routes.EnrollFamilySetupRecoveryPhone}>
                        <formSteps.Setup2FaStep />
                    </Route>
                    <Route path={Routes.EnrollFamilyVerifyRecoveryPhone}>
                        <formSteps.VerifyPhone />
                    </Route>
                </main>
            </>
        </Switch>
    );
};

export const FamilyEnrollment = () => {
    return (
        <FamilyEnrollmentView>
            <EnrollmentFlow />
        </FamilyEnrollmentView>
    );
};
