export const colors = {
    brand: '#005e9d',
    brandDark: '#143860',
    brandLight: '#d7eff7',
    brandAccent: '#ee2a7b',
    brandAccent2: '#ff6400',
    brandAccent3: '#F15A29',
    brandHighlight: '#0078D7',
    brandPaleBlue: '#5EB5D4',
    whiteFade: 'rgba(255, 255, 255, 0.8)',
    offWhite: '#FBFBFB',

    grey1: '#58595b',
    grey2: '#6d6e71',
    grey3: '#939598',
    grey4: '#aaaaaa',
    grey5: '#cccccc',
    grey6: '#dfdfdf',
    grey7: '#f0f0f0',
    grey8: '#667085',
    grey9: '#d9d9d9',
    grey50: '#F9FAFB',
    greyAccent: '#f7f7f7',
    white: '#ffffff',
    lightGrey: '#d7d7d7',
    lightGrey2: '#D0D5DD',
    lightGrey3: '#F2F4F7',
    lightGrey4: '#EAECF0',
    lineGrey: '#E9E9E9',
    hoverGrey: '#BABBBC',
    mediumGrey: '#6D6E71',
    darkestGrey: '#424344',
    midnightBlack: '#2C2D2E',
    shopGrey: '#939598',

    error: '#d60000',
    warning: '#daae0a',
    success: '#388200',
    alertGreen: '#388200',
    warningDark: '#DC6803',

    // blue
    paleBlue: '#E1F1F7',
    paleBlue40: 'rgba(225, 241, 247, 0.4)',
    paleBlue50: 'rgba(225, 241, 247, 0.5)',
    darkBlue: '#143860',
    lightBlueDark: '#1B95C5',
    primaryBlueLight: '#0083DA',

    // pink
    bonusPink: '#e6126a',
    lighterPink: '#F7A1C5',

    // purples
    purple1: '#7E3F97',

    // coordinator dashboard
    dashboardBackground: '#FEEAF6',
    dashboardText: '#143860',
    lightGray: '#F3F9FB',
    link: '#E6126A',
    orange: '#F15A29',
};

export const fontWeights = {
    normal: 'normal' as 'normal', // eslint-disable-line
    semiBold: 600,
};

// Copied from tailwindcss/defaultTheme
export const breakpoints = {
    sm: '@media (min-width: 640px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 1024px)',
    xl: '@media (min-width: 1280px)',
    '2xl': '@media (min-width: 1536px)',
};
