import React from 'react';

import BaseCard from '../bonus/BaseCard';
import { createStyles } from '../../utils/createStyle';
import { Routes } from 'routes';

const CardEmptyContent = () => (
    <div className="h-full w-full flex flex-col justify-center font-semibold text-xs">
        <div className="w-full flex justify-center">
            <span className="text-brand px-4 sm:px-7 text-center">+ Add more favorites now</span>
        </div>
    </div>
);

export const FavoriteEmptyCard = ({ styles }: { styles?: string }) => {
    const generalStyles = createStyles({
        cardContainer: [
            '',
            {
                height: 210,
                padding: 8,
                marginBottom: '18px',
            },
        ],
    });

    return (
        <BaseCard
            isLoading={false}
            containerClass={`border-dashed ${generalStyles.cardContainer} ${styles}`}
            link={Routes.Brands}
        >
            <CardEmptyContent />
        </BaseCard>
    );
};
