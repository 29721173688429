import React from 'react';
import classNames from 'classnames';

import { createStyle } from 'utils/createStyle';
import { getFocusOutline } from 'styles/accessibility';
import { colors } from 'styles/settings';

export interface BtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isSecondary?: boolean;
    isSmall?: boolean;
}

const btnClassName = (isSmall: boolean) =>
    classNames(
        'relative rounded-full bg-brand text-white font-semibold border-brand',
        `${isSmall ? 'text-xs py-1 px-3 border' : 'text-lg py-2 px-7 border-2'} `,
        'transition-colors transition-shadow',
        'active:bg-brand-dark',
        getFocusOutline({ borderRadius: 9999, offset: 4 }),
        createStyle({
            '&:hover': {
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.33)',
            },
            '[disabled]': {
                background: colors.lightGrey,
                boxShadow: 'none',
                border: `2px solid ${colors.lightGrey}`,
                color: colors.grey2,
                pointerEvents: 'none',
            },
            '&:active': {
                transition: 'none',
                boxShadow: 'none',
            },
        })
    );

const btnClassNameSecondary = (isSmall: boolean) =>
    classNames(
        'relative rounded-full bg-white text-brand  font-semibold',
        `${isSmall ? 'text-xs py-1 px-3 border' : 'text-lg py-2 px-7 border-2'} `,
        'transition-colors transition-shadow',
        'active:bg-brand-dark',
        getFocusOutline({ borderRadius: 9999, offset: 4 }),
        createStyle({
            '&:hover': {
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.33)',
            },
            '[disabled]': {
                borderColor: 'rgba(99, 99, 99, 0.4)',
                color: 'rgba(99, 99, 99, 0.6)',
                boxShadow: 'none',
                pointerEvents: 'none',
            },
            '&:active': {
                transition: 'none',
                boxShadow: 'none',
                color: 'white',
            },
        })
    );

export const Btn = (props: BtnProps) => {
    const { className, isSecondary, isSmall = false, ...btnProps } = props;
    return (
        <button
            className={classNames(
                isSecondary ? btnClassNameSecondary(isSmall) : btnClassName(isSmall),
                className
            )}
            {...btnProps}
        />
    );
};
