import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepperStyles } from './StepperStyles';

interface StepProps {
    title: string;
    icon: IconDefinition;
    iconSelected: IconDefinition;
    isLast: boolean;
    routes: string[];
    exact: boolean;
    stepId: number;
    setCurrent: Function;
    steps: IStep[];
}

export interface IStep {
    title: string;
    icon: IconDefinition;
    iconSelected: IconDefinition;
    routes: string[];
    exact: boolean;
}

export const Step = (props: StepProps) => {
    const isMatched = useRouteMatch({
        path: props.routes,
        sensitive: false,
        exact: props.exact,
    });

    const { stepId, title, setCurrent, icon, iconSelected } = props;
    const stepRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (isMatched) {
            setCurrent(stepId);
        }
    }, [stepId, isMatched, setCurrent]);

    return (
        <>
            <li
                tabIndex={isMatched ? 0 : -1}
                aria-current={isMatched ? 'step' : undefined}
                ref={stepRef}
                aria-label={`${title}. Step ${stepId} of ${props.steps.length}`}
                className={classNames(
                    StepperStyles.stepWrapper,
                    !isMatched && StepperStyles.hiddenMobile
                )}
            >
                <div
                    className={classNames(StepperStyles.iconWrapper, {
                        [StepperStyles.iconSelected]: isMatched,
                    })}
                >
                    <FontAwesomeIcon
                        icon={isMatched ? iconSelected : icon}
                        className={classNames('duration-100 transition-all text-brand', {
                            'text-brand-accent': isMatched,
                        })}
                        size="2x"
                    />
                </div>
                <div className="flex flex-col ml-2 sm:ml-0">
                    <div
                        className={StepperStyles.stepMobileHeader}
                    >{`Step ${stepId} of ${props.steps.length}`}</div>
                    <div className={StepperStyles.stepTitle}>{title}</div>
                </div>
            </li>
            {!props.isLast && <li aria-hidden className={StepperStyles.separator} />}
        </>
    );
};

export const LitePlusStepper = ({ steps }: { steps: IStep[] }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);

    return (
        <div role="group" aria-label="progress">
            <span className="sr-only">{`${steps[currentStep - 1].title}, step ${currentStep} of ${
                steps.length
            }`}</span>
            <ol
                className={StepperStyles.wrapper}
                data-current-step={currentStep}
                data-steps={steps.length}
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        stepId={index + 1}
                        {...step}
                        isLast={steps.length === index + 1}
                        setCurrent={setCurrentStep}
                        steps={steps}
                    />
                ))}
            </ol>
        </div>
    );
};
