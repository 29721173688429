import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { CheckCircle } from 'react-feather';

import { Check } from 'components/custom-icons';
import { UploadCheckImageModal } from 'features/coordinator/components/UploadCheckImageModal';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { getPath, Routes } from 'routes';
import { Organization } from 'common/api/crm/models/Organization';
import { createStyles } from 'utils/createStyle';
import { colors } from 'styles/settings';
import { LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { TiledButton } from 'components/tiled-button/TiledButton';
import { copyTextToClipboard } from 'utils';

interface VoidedCheckStepProps {
    organization: Organization;
    ProgramCompletedRoute: Routes.EnrollCompleted | Routes.StartProgramCompleted;
}

const styles = createStyles({
    wrapper: 'flex flex-col p-0 mx-0 max-w-full sm:mx-auto sm:max-w-screen-xl mt-0',
    subHeader:
        'w-full sm:max-w-full h-40 sm:h-40 relative flex flex-col word-wrap justify-center align-start bg-brand-accent text-3xl text-white px-4 lg:px-48',
    titleInfo:
        'w-full max-w-full mx-auto sm:max-w-3xl h-40 sm:h-40 relative flex flex-col word-wrap justify-center align-start text-3xl text-white px-4 lg:px-0',
    subTitle: 'text-3xl sm:text-5xl text-brand-dark font-light text-left',
    icon: 'mt-0 mb-0  sm:px-0 px-2 text-brand h-10 sm:h-16',
    contentWrapper: 'max-w-full relative sm:max-w-3xl mx-auto px-8 text-lg text-grey-1 lg:px-0',
    button: [
        'w-64 sm:w-64 mb-0 text-xl sm:flex-row-reverse max-h-16 sm:w-20 px-4 sm:px-6 text-left sm:text-center',
        {
            justifyContent: 'flex-end',
            flexDirection: 'row-reverse',
            borderColor: colors.grey5,
            marginBottom: -4,
            marginRight: 0,
        },
    ],
    emailButton:
        'flex justify-center items-center bg-brand-light text-brand text-xs font-semibold mt-2 sm:mt-0 w-28 py-2',
    emailButtonCopied: 'bg-grey-5 text-brand-dark px-1',
});

export const VoidedCheckStep = (props: VoidedCheckStepProps) => {
    const [modal, setModal] = useState<'upload-check' | null>(null);
    const { ProgramCompletedRoute, organization } = props;
    const orgId = organization.id || '';
    const history = useHistory();
    const onUploadCheckImageSuccess = useCallback(async () => {
        withRetries(updateEnrollmentProgress)({
            state: ProgressState.CheckImageUploaded,
            organizationId: orgId,
        });
        history.replace(getPath(ProgramCompletedRoute, {}));
    }, [history, orgId, ProgramCompletedRoute]);
    const loader = useRef<LoadingOverlay>(null);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const onCopyEmail = useCallback((text: string) => {
        copyTextToClipboard(text);
        setIsCopied(true);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <div className="mb-6 sm:mb-10" />
                <h1 tabIndex={0} className={styles.subTitle}>
                    Upload or email a voided check
                </h1>
                <br />
                <p>
                    Get your organization's bank account connected for direct deposit by following
                    the instructions below. This will allow your organization to get earning!
                </p>
                <br />
                <div className="mb-6 sm:mb-10" />
                <div className="flex align-center items-center">
                    <span className="text-4xl sm:text-5xl text-brand">1</span>
                    <span className="pl-3 sm:pl-8">Take a picture of a voided check</span>
                </div>
                <br />
                <div className="flex align-center items-center">
                    <span className="text-4xl sm:text-5xl text-brand">2</span>
                    <div className="pl-3 sm:pl-8 flex  sm:flex-row flex-col items-center">
                        <TiledButton
                            buttonClassName={styles.button}
                            buttonText="Upload the check"
                            buttonTextClassName="text-lg"
                            onClick={() => setModal('upload-check')}
                            icon={<Check className={'mr-3 text-brand'} width={36} height={36} />}
                        />
                        <strong className="text-brand-dark py-4 sm:py-0 px-6 w-full text-center text-2xl font-semibold">
                            or
                        </strong>
                        <div className="ml-0 flex flex-col ">
                            <span>Email the check</span>
                            <a className="text-sm" href="mailto:fundraising@raiseright.com">
                                fundraising@raiseright.com
                            </a>
                        </div>
                        <div className="ml-0 sm:ml-4">
                            <button
                                onClick={() => onCopyEmail('fundraising@raiseright.com')}
                                className={
                                    isCopied
                                        ? classNames(styles.emailButton, styles.emailButtonCopied)
                                        : styles.emailButton
                                }
                            >
                                {isCopied ? (
                                    <>
                                        <CheckCircle className="mr-2" width={16} height={16} />
                                        Email Copied
                                    </>
                                ) : (
                                    'Copy Email'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <div className="flex align-center items-center">
                    <span className="text-4xl sm:text-5xl text-brand">3</span>
                    <span className="pl-3 sm:pl-8">
                        We will review your organization's information to confirm eligibility, and
                        generally send confirmation in 1-2 business days. We will contact you if we
                        require any additional information to finalize your program set up.
                    </span>
                </div>
                <div className="mb-6 sm:mb-24" />

                <div className="flex  flex-col align-center items-center justify-center">
                    <p className="text-sm mt-2 mb-8 text-grey">
                        Need Support? We’re ready to help if you need any assistance.{' '}
                        <span className="font-semibold">(800) 727-4715 | Option 4</span>
                    </p>
                </div>
            </div>

            <LoadingOverlay ref={loader} />

            <UploadCheckImageModal
                orgId={orgId}
                isOpen={modal === 'upload-check'}
                onClose={() => setModal(null)}
                onSuccess={onUploadCheckImageSuccess}
            />
        </div>
    );
};
