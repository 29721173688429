import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import React, { memo } from 'react';
import { EntryFields } from 'contentful';
import classNames from 'classnames';

import { PartialRecord } from 'common/definitions/PartialRecord';
import { reactRecursiveMap } from 'utils/reactRecursiveMap';
import { formatString } from 'common/utils/formatString';
import { createStyle } from 'utils/createStyle';

export interface UserContentProps {
    content: Document | EntryFields.RichText;
    data?: PartialRecord<string, string>;
    className?: string;
    options?: Options;
}

const UserContentStyles = createStyle({
    '& ul': { listStyle: 'disc', paddingLeft: 24 },
});

export const UserContent = memo((props: UserContentProps) => {
    const { data } = props;
    const options = props.options || {
        renderText: (t) => (t.replace(/[\s]/g, '') === '' ? <br /> : t),
    };
    let content = documentToReactComponents(props.content as Document, options);

    if (data) {
        content = reactRecursiveMap(content, (child) => {
            if (typeof child === 'string') {
                return formatString(child, data);
            }
            return child;
        });
    }
    return <div className={classNames(UserContentStyles, props.className)}>{content}</div>;
});
