import { APIResponse } from '../models';
import { createAPIError } from '../utils/createAPIError';
import { EnrollSourceApplicationIds } from '../auth/models/RegistrationRequestWithoutOrg';

export interface Claim {
    type: string;
    value: string;
}

export interface UserRegistrationResponse {
    userId: string;
    result: string;
    token: string;
    refreshToken: string;
    inactivityTimeoutMinutes: number;
    oneTimeCode: string;
}

export interface OrganizationRegistrationResponse {
    id: string;
    name: string;
    address: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zipcode: string;
    };
    phoneNumber: string;
    organizationType: {
        id: number;
        description: string;
        isEnabled: boolean;
    };
    website: string;
}

export interface EnrollmentV2RegisterResponse {
    userRegistrationResponse: UserRegistrationResponse;
    organizationRegistrationResponse: OrganizationRegistrationResponse;
    userOrganizationRegistrationResult: string;
}

export interface EnrollmentV2RegisterRequest {
    account: {
        email: string;
        password: string;
        firstName: string | undefined;
        lastName: string | undefined;
        captchaResponse: string;
        enrollSourceApplicationId: EnrollSourceApplicationIds;
    };
    organization: {
        name: string;
        address: {
            address1: string;
            address2: string | undefined;
            city: string;
            state: string;
            zipCode: string;
        };
        organizationTypeId: number | undefined;
        phoneNumber: string | undefined;
        webSite: string | undefined;
    };
    blackBoxValue: string | undefined;
    secondaryBlackBoxValue: string | undefined;
}

export const getUserClaimsFromBff = async (): Promise<APIResponse<Claim[]>> => {
    const req = new Request('/bff/user', {
        headers: new Headers({
            'X-CSRF': '1',
        }),
    });

    const response = await fetch(req);

    if (response.ok) {
        const data = await response.json();
        return { data, response };
    }

    return { error: createAPIError(response, null), response };
};

export const enrollmentV2Register = async (
    payload: EnrollmentV2RegisterRequest
): Promise<APIResponse<EnrollmentV2RegisterResponse>> => {
    const req = new Request('/api/local/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-CSRF': '1' },
        body: JSON.stringify(payload),
    });

    const response = await fetch(req);

    if (response.ok) {
        const data = await response.json();
        return { data, response };
    }

    return { error: createAPIError(response, null), response };
};
