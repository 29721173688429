import React, { useState } from 'react';

import { Radio } from '../../../components/radio/Radio';

import { MobileDialog } from './MobileDialog';
import { SortByProps, SortByOption } from './SortBy';

import { createStyle } from 'utils/createStyle';

const btnStyle = createStyle(
    'mt-8 py-1 px-10 w-full rounded-3xl bg-brand text-white font-semibold',
    {
        maxWidth: 280,
    }
);

export const MobileSortDialog = ({ options, sortedByField, onChange }: SortByProps) => {
    const [selectedField, setSelectedField] = useState(sortedByField);

    const onApplyClicked = () => {
        if (selectedField?.value === sortedByField.value) {
            onChange(selectedField);
        } else {
            onChange(selectedField);
        }
    };

    const footer = (onClick: React.MouseEventHandler) => (
        <button className={btnStyle} onClick={onClick}>
            Sort
        </button>
    );

    return (
        <MobileDialog
            btnName="Sort"
            header="Sort"
            footer={footer}
            styles={{ container: 'border-r-0' }}
            onApply={onApplyClicked}
        >
            <Radio
                options={options}
                onChange={(option: SortByOption) => setSelectedField(option)}
                value={selectedField.value}
            />
        </MobileDialog>
    );
};
