import { HomeDetailsResponse } from 'common/api/crm/home/models/HomeDetailsResponse';
import { getAccount } from 'common/api/users/service';
import { includesEq, not, or, eq } from 'common/utils/searchFilters';

const MAX_ORG_CHANGE_ATTEMPTS = 5;
const ORG_CHANGE_WAIT_MS = 1000;

export const getState = ({ defaultOrganization }: HomeDetailsResponse) => {
    if (!defaultOrganization) {
        return '';
    }

    return defaultOrganization.address ? defaultOrganization.address.state : '';
};

export const createStateCodeQuery = (homeDetails: HomeDetailsResponse | null) =>
    homeDetails ? not(includesEq('BlacklistedStates', getState(homeDetails))) : '';

export const createLocalProductByOrganizationQuery = (homeDetails: HomeDetailsResponse | null) => {
    if (!homeDetails || !homeDetails.defaultOrganization) {
        return eq('OrganizationId', null);
    }

    return or(
        eq('OrganizationId', null),
        eq('OrganizationId', `'${homeDetails.defaultOrganization.id}'`)
    );
};

/**
 * This wraps our recursive waitForOrgUpdate function in a promise and injects the
 * promise resolve/reject callbacks into our success/failure callbacks so the function that's calling
 * this recursive function correctly awaits the full recursive execution to complete.
 */
export const recursiveWaitForOrgUpdate = async (
    newOrgId: string,
    successAction: Function,
    failureAction: Function,
    attempts = 0
) => {
    return new Promise((resolve) => {
        waitForOrgUpdate(
            newOrgId,
            () => {
                resolve(successAction());
            },
            async () => {
                resolve(await failureAction());
            },
            attempts
        );
    });
};

/**
 * This recursively calls getAccount() and checks to see if the orgId returned
 * from that endpoint matches the orgId provided as an argument.
 *
 * If a match is found it will call the successAction callback
 * (expected to be a Redux action dispatch to update state).
 *
 * If a match is not found before the maximum number of attempts have been made it
 * will call the failureAction callback
 * (expected to be a Redux action dispatch to update state).
 *
 * The context for this function is that when a user changes their default org,
 * that change is an async action in our API. So we need to wait some indeterminite
 * period of time for that action to complete. This function periodically checks if that action
 * has completed in our backend.
 */
export const waitForOrgUpdate = async (
    newOrgId: string,
    successAction: Function,
    failureAction: Function,
    attempts = 0
) => {
    if (attempts >= MAX_ORG_CHANGE_ATTEMPTS) {
        await failureAction();
        return;
    }

    const account = await getAccount();

    if (account.data?.organization.organizationId === newOrgId) {
        successAction();
        return;
    }

    setTimeout(() => {
        waitForOrgUpdate(newOrgId, successAction, failureAction, ++attempts);
    }, ORG_CHANGE_WAIT_MS);
};
