import React, { useCallback } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { X } from 'react-feather';

import { Btn, BtnProps } from 'components/btn/Btn';
import { ModalStyles } from 'styles/modals';
import { colors } from 'styles/settings';
import { AlertProvider, AlertOutput } from 'modules/alerts';

export enum ModalSize {
    // NOTE: this allows the modal to respect the content size
    CONTENT_WIDTH = '',
    EXTRA_SMALL = 'w-extra-small',
    SMALL = 'w-small',
    MEDIUM = 'w-medium',
    LARGE = 'w-large', // NOTE: w-large is currently not implemented in the tailwind.config
    // waiting for standardization of all the possible sizes.
}

export interface ModalProps {
    title: React.ReactNode;
    isOpen?: boolean;
    onClose: () => void;
    titleStyles?: string;
    showCloseButton?: boolean;
    escKeyCloseModalEnabled?: boolean;
    size?: [ModalSize, ModalSize];
}

const genModalSize = (overrides?: [ModalSize, ModalSize]): string => {
    if (overrides) {
        return overrides.join(' ');
    }
    // [mobile-view, any]
    return 'w-extral-small sm:w-medium';
};

export const Modal = (props: React.PropsWithChildren<ModalProps>) => {
    const { onClose, showCloseButton = true, escKeyCloseModalEnabled = true } = props;
    const onOpenChange = useCallback((isOpen: boolean) => !isOpen && onClose(), [onClose]);
    const size = genModalSize(props.size);

    const onEscapeKeyDown = (e: Event) => {
        if (!escKeyCloseModalEnabled) {
            e.preventDefault();
        }
    };

    return (
        <AlertProvider>
            {/* @ts-ignore: */}
            <Dialog.Root open={props.isOpen} onOpenChange={onOpenChange}>
                <Dialog.Overlay className={ModalStyles.overlay} />
                <Dialog.Content
                    className={ModalStyles.contentWrapper}
                    onEscapeKeyDown={onEscapeKeyDown}
                >
                    <>
                        <div className={classNames(ModalStyles.content, size)}>
                            <div className={ModalStyles.header}>
                                {typeof props.title !== 'string' ? (
                                    props.title
                                ) : (
                                    <h2
                                        tabIndex={0}
                                        className={classNames(ModalStyles.title, props.titleStyles)}
                                    >
                                        {props.title}
                                    </h2>
                                )}
                                {showCloseButton && (
                                    <button
                                        onClick={onClose}
                                        className={ModalStyles.closeButton}
                                        aria-label="Close"
                                    >
                                        <X color={colors.brand} size={28} />
                                    </button>
                                )}
                            </div>
                            {props.children}
                        </div>
                        <AlertOutput />
                    </>
                </Dialog.Content>
            </Dialog.Root>
        </AlertProvider>
    );
};

export const ModalFooter = (props: React.HTMLAttributes<HTMLDivElement>) => {
    return <div {...props} className={classNames(ModalStyles.buttonContainer, props.className)} />;
};

export const ModalPrimaryBtn = (props: BtnProps) => {
    return <Btn type="button" className={ModalStyles.primaryButton} {...props} />;
};

export const ModalSecondaryBtn = (props: BtnProps) => {
    return <button type="button" className={ModalStyles.secondaryButton} {...props} />;
};
