import React from 'react';

import { YesNoOrgProfileQuestion } from 'features/coordinator/enroll/components/YesNoOrgProfileQuestion';

export const gotStoreQuestion =
    'Does your organization sell things in person like at a store, kiosk, or pop-up?';

export const OrgProfileQuestion1 = () => (
    <YesNoOrgProfileQuestion question={gotStoreQuestion} progressStep={[1, 5]} />
);
