import { batchActions } from 'redux-batched-actions';
import { Dispatch } from 'redux';

import {
    HomeDetailsResponse,
    emptyHomeDetailsResponse,
} from 'common/api/crm/home/models/HomeDetailsResponse';
import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { getHomeDetails } from 'common/api/crm/home';
import { createSlice } from 'common/modules/create-slice';
import { StateRebateAdjustment, getStateRebateAdjustment } from 'common/api/e-comm/products/rebate';
import { PartialRecord } from 'common/definitions/PartialRecord';
import { makeLens } from 'common/modules/lens/makeLens';
import { FeaturesState } from 'common/features/featuresReducer';
import {
    addUserToOrg,
    setUserDefaultOrganization,
    updateFamily,
    removeUserFromOrg,
} from 'common/api/crm/family/service';
import { Family } from 'common/api/crm/models/Family';
import { getOrganizationFromEnrollmentCode } from 'common/api/crm/organization/enrollment';
import { createSelector } from 'reselect';
import { recursiveWaitForOrgUpdate } from './utils';
import { StatusCode } from 'common/api/config';

export const defaultStateRebateAdjustment: StateRebateAdjustment = {
    adjustment: 0,
};

interface OrgSwitchState {
    showOverlay: boolean;
    previousOrgId: string;
    redirectRoute: string;
    newOrgId: string;
}

const initialOrgSwitchState = {
    showOverlay: false,
    previousOrgId: '',
    redirectRoute: '',
    newOrgId: '',
};

export interface HomeState {
    homeDetails: HomeDetailsResponse | null;
    homeDetailsAsyncState: AsyncActionState;
    rebateAdjustmentByState: PartialRecord<string, StateRebateAdjustment>;
    stateRebateAdjustmentAsyncState: AsyncActionState;
    orgSwitchState: OrgSwitchState;
    orgSwitchAsyncState: AsyncActionState;
    orgJoinAsyncState: AsyncActionState;
}

export const initialHomeState: HomeState = {
    homeDetails: null,
    homeDetailsAsyncState: initialAsyncActionState,
    rebateAdjustmentByState: {},
    stateRebateAdjustmentAsyncState: initialAsyncActionState,
    orgSwitchState: initialOrgSwitchState,
    orgSwitchAsyncState: initialAsyncActionState,
    orgJoinAsyncState: initialAsyncActionState,
};

const { update, reducer, configureAction } = createSlice(initialHomeState, 'HOME');
export const homeReducer = reducer;
export const updateHome = update;

const lens = makeLens<HomeState>();

type UpsertStateRebateAdjustmentPayload = {
    stateCode: string;
    stateRebateAdjustment: StateRebateAdjustment;
};

export const upsertStateRebateAdjustment = configureAction<UpsertStateRebateAdjustmentPayload>(
    'UPSERT_STATE_REBATE_ADJUSTMENT',
    ({ stateCode, stateRebateAdjustment }) =>
        lens.k('rebateAdjustmentByState').set((sra) => ({
            ...sra,
            [stateCode]: stateRebateAdjustment,
        }))
);

export const updateUserProfile = configureAction<{ profile: Family }>(
    'UPDATE_USER_PROFILE',
    ({ profile }) => {
        return lens
            .k('homeDetails')
            .set((homeDetails) => ({ ...(homeDetails || emptyHomeDetailsResponse), profile }));
    }
);

export const updateSelectedOrganization = configureAction<{ orgId: string }>(
    'UPDATE_SELECTED_ORGANIZATION',
    ({ orgId }) => {
        return lens.k('homeDetails').set((homeDetails) => ({
            ...(homeDetails || emptyHomeDetailsResponse),
            selectedOrgId: orgId,
        }));
    }
);

export const removeOrganizationFromUser = configureAction<string>(
    'REMOVE_ORGANIZATION_FROM_USER',
    (orgId: string) => {
        return lens.k('homeDetails').set((homeDetails) => {
            if (homeDetails?.organizations && homeDetails.organizations[orgId]) {
                const organizations = { ...homeDetails.organizations };
                delete organizations[orgId];
                return {
                    ...(homeDetails || emptyHomeDetailsResponse),
                    organizations,
                };
            }
            return homeDetails;
        });
    }
);

export const startOrgSwitchFlow = configureAction<{
    orgId: string;
    previousOrgId: string;
    redirectRoute: string;
}>('START_UPDATE_ORG_SWITCH_FLOW', ({ orgId, previousOrgId, redirectRoute }) => {
    return lens.k('orgSwitchState').set((orgSwitchState) => ({
        ...orgSwitchState,
        newOrgId: orgId,
        previousOrgId: previousOrgId,
        redirectRoute: redirectRoute,
        showOverlay: true,
    }));
});

export const endOrgSwitchFlow = configureAction('END_UPDATE_ORG_SWITCH_FLOW', () => {
    return lens.k('orgSwitchState').set((orgSwitchState) => ({
        ...orgSwitchState,
        newOrgId: '',
        previousOrg: '',
        redirectRoute: '',
        showOverlay: false,
    }));
});

export const startUpdateDefaultOrganization = configureAction(
    'START_UPDATE_DEFAULT_ORGANIZATION',
    () => {
        return lens.k('orgSwitchAsyncState').set((orgSwitchAsyncState) => ({
            ...orgSwitchAsyncState,
            loading: true,
        }));
    }
);

export const endUpdateDefaultOrganization = configureAction(
    'END_UPDATE_DEFAULT_ORGANIZATION',
    () => {
        return lens.k('orgSwitchAsyncState').set((orgSwitchAsyncState) => ({
            ...orgSwitchAsyncState,
            loading: false,
            lastUpdate: Date.now(),
        }));
    }
);

export const errorUpdateDefaultOrganization = configureAction<{ error: string }>(
    'ERROR_UPDATE_DEFAULT_ORGANIZATION',
    ({ error }) => {
        return lens.k('orgSwitchAsyncState').set((orgSwitchAsyncState) => ({
            ...orgSwitchAsyncState,
            loading: false,
            lastUpdate: Date.now(),
            error: error,
        }));
    }
);

export const startOrgJoin = configureAction('START_JOIN_ORGANIZATION', () => {
    return lens.k('orgJoinAsyncState').set((orgJoinAsyncState) => ({
        ...orgJoinAsyncState,
        loading: true,
        lastUpdate: 0,
    }));
});

export const endOrgJoin = configureAction<{ newOrgName: string }>(
    'END_JOIN_ORGANIZATION',
    ({ newOrgName }) => {
        return lens.k('orgJoinAsyncState').set((orgJoinAsyncState) => ({
            ...orgJoinAsyncState,
            loading: false,
            lastUpdate: Date.now(),
            error: null,
            args: [newOrgName],
        }));
    }
);

export const resetOrgJoin = configureAction('RESET_JOIN_ORGANIZATION', () => {
    return lens.k('orgJoinAsyncState').set(() => initialAsyncActionState);
});

export const errorOrgJoin = configureAction<{ error: string }>(
    'ERROR_JOIN_ORGANIZATION',
    ({ error }) => {
        return lens.k('orgJoinAsyncState').set((orgJoinAsyncState) => ({
            ...orgJoinAsyncState,
            loading: false,
            lastUpdate: Date.now(),
            error: error,
        }));
    }
);

export const updateDefaultOrgThunk =
    (orgId: string, previousOrgId: string) => async (dispatch: Dispatch) => {
        dispatch(startUpdateDefaultOrganization(null));

        const result = await setUserDefaultOrganization(orgId);
        if (!result.error) {
            await recursiveWaitForOrgUpdate(
                orgId,
                () => {
                    dispatch(endUpdateDefaultOrganization(null));
                },
                async () => {
                    await setUserDefaultOrganization(previousOrgId);
                    dispatch(
                        errorUpdateDefaultOrganization({
                            error: 'an error occurred',
                        })
                    );
                }
            );
            return;
        }
        dispatch(
            errorUpdateDefaultOrganization({
                error: result?.error?.error?.message || 'an error occurred',
            })
        );
    };

export const requestHomeDetails = createApiThunk(
    getHomeDetails,
    () => (state, result) => {
        return result
            ? update({ homeDetails: result, homeDetailsAsyncState: state })
            : update({ homeDetailsAsyncState: state });
    },
    { joinParallelCalls: true }
);

export const setStateRebateAdjustment = createApiThunk(
    (stateCode: string) => getStateRebateAdjustment(stateCode),
    (stateCode) => (state, result) => {
        return result
            ? batchActions([
                  update({ stateRebateAdjustmentAsyncState: state }),
                  upsertStateRebateAdjustment({ stateCode, stateRebateAdjustment: result }),
              ])
            : update({ stateRebateAdjustmentAsyncState: state });
    }
);

export const updateUserThunk = (profile: Family) => async (dispatch: Dispatch) => {
    const result = await updateFamily(profile);
    if (!result.error) {
        dispatch(updateUserProfile({ profile }));
    }
    return result;
};

export const addUserToOrgThunk = (enrollmentCode: string) => async (dispatch: Dispatch) => {
    dispatch(startOrgJoin(null));

    const org = await getOrganizationFromEnrollmentCode(enrollmentCode);

    if (org?.data?.id) {
        await addUserToOrg(org.data.id);
        await requestHomeDetails()(dispatch);

        dispatch(endOrgJoin({ newOrgName: org?.data?.name }));
        return;
    }

    dispatch(errorOrgJoin({ error: 'Invalid code. Try again or contact your coordinator.' }));
};

export const removeUserFromOrgThunk = (orgId: string) => async (dispatch: Dispatch) => {
    const response = await removeUserFromOrg(orgId);

    // NOTE: On success lets remove that org from redux
    if (response.response?.status === StatusCode.Ok) {
        dispatch(removeOrganizationFromUser(orgId));
    }

    // NOTE: return the response to allow for error handling by the callee
    return response;
};

export const selectOrgJoinAsyncState = (state: FeaturesState) => state.store.home.orgJoinAsyncState;
export const selectOrgSwitchAsyncState = (state: FeaturesState) =>
    state.store.home.orgSwitchAsyncState;
export const selectOrgSwitchState = (state: FeaturesState) => state.store.home.orgSwitchState;
export const selectHomeDetails = (state: FeaturesState) => state.store.home.homeDetails;
export const selectHomeDetailsUserId = (state: FeaturesState) =>
    state.store.home.homeDetails?.profile.userId || '';
export const selectHomeDetailsProfileEmail = (state: FeaturesState) =>
    state.store.home.homeDetails?.profile.email || '';
export const selectHomeDetailsAsyncState = (state: FeaturesState) =>
    state.store.home.homeDetailsAsyncState;
export const selectedOrganization = (state: FeaturesState) => {
    const homeDetails = selectHomeDetails(state);

    if (!homeDetails || !homeDetails.selectedOrgId) {
        return null;
    }

    return homeDetails.organizations[homeDetails.selectedOrgId];
};
export const resetHomeDetails = () => update(initialHomeState);
export const selectDefaultOrganization = createSelector(
    selectHomeDetails,
    (homeDetails: HomeDetailsResponse | null) => {
        if (homeDetails) {
            return homeDetails.defaultOrganization;
        }
        return null;
    }
);
export const selectSelectedOrgId = (state: FeaturesState) => {
    const homeDetails = selectHomeDetails(state);

    if (!homeDetails || !homeDetails.selectedOrgId) {
        return null;
    }

    return homeDetails.selectedOrgId;
};
