import { createSelector } from 'reselect';
import { createSlice } from 'common/modules/create-slice';
import {
    AsyncActionState,
    createApiThunk,
    initialAsyncActionState,
} from 'common/modules/async-actions/thunk';
import { FeaturesState } from 'common/features/featuresReducer';

export interface AppsFlyerState {
    referrer: string;
    referrerAsyncState: AsyncActionState;
}

const initialAppsFlyerState: AppsFlyerState = {
    referrer: 'direct',
    referrerAsyncState: initialAsyncActionState,
};

const { update, reducer } = createSlice(initialAppsFlyerState, 'APPSFLYER');
export const appsFlyerReducer = reducer;
export const updateAppsFlyerState = update;

export const resetReferrer = () => update(initialAppsFlyerState);

export const selectReferrer = createSelector(
    (state: FeaturesState) => state.store.appsFlyer.referrer,
    (referrer) => referrer
);
