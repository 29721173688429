import { EarnTypeConstructorFilter } from 'common/api/constructor/util';
import { CardPropsBase, CardPropsEarn } from 'components/bonus/CardProps';
import React from 'react';

import { createStyles } from 'utils/createStyle';

export const CardContext = React.createContext<{ width: string }>({ width: '0' });

const initCardProps = {
    logoImage: '',
    showMarker: false,
    brandName: '',
    earn: {
        previous: 0,
        actual: 0,
        type: EarnTypeConstructorFilter.GiftCards,
        isOnBonus: false,
    },
    link: '',
    isLoading: true,
    id: '',
    categories: [],
    islocalProduct: false,
    isNew: false,
} as CardPropsEarn;

interface StylesContextProp {
    styles: ReturnType<typeof createStyles>;
    data: CardPropsBase;
}
export const StylesContext = React.createContext<StylesContextProp>({
    styles: {},
    data: initCardProps,
});
