import { Organization } from 'common/api/crm/organization/enrollment/models';
import { ACCOUNT_URL, USERS_URL } from 'common/api/config';
import { apiFetch } from 'common/api/utils';

export const validateEmail = async (email: string) => {
    const emailPromise = apiFetch<null>(`${ACCOUNT_URL}/email/validate?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
    });
    const usernamePromise = apiFetch<null>(`${ACCOUNT_URL}/username/validate?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email }),
    });

    const [emailResponse, usernameResponse] = await Promise.all([emailPromise, usernamePromise]);

    if (emailResponse.error) {
        return emailResponse;
    }

    if (usernameResponse.error) {
        return usernameResponse;
    }

    return {
        ...emailResponse,
        ...usernameResponse,
    };
};

export const validateEmailV2 = (email: string, captchaResponse?: string | null) =>
    apiFetch<Organization>(`${USERS_URL}/Account/email/validate?api-version=2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, captchaResponse }),
    });
