import React, { useRef, useState } from 'react';

import { CreateAccount, CreateAccountProps } from './CreateAccount';

import { StepWrapper, StepWrapperProps } from 'features/coordinator/enrollment/steps/StepWrapper';
import { LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';

interface YourAccountStepProps
    extends StepWrapperProps,
        Omit<CreateAccountProps, 'loader' | 'step' | 'toNextStep' | 'onCompleted'> {}

export const YourAccountStep = (props: YourAccountStepProps) => {
    const loader = useRef<LoadingOverlay>(null);
    const [stepProgress, setStepProgress] = useState<number>(0);

    const toNextStep = () => setStepProgress((step) => step + 1);

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <CreateAccount {...props} loader={loader} step={stepProgress} toNextStep={toNextStep} />
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
