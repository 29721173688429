import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart as faShoppingCartSolid } from '@fortawesome/pro-solid-svg-icons';
import { faShoppingCart, IconDefinition } from '@fortawesome/pro-light-svg-icons';

import { useRootSelector } from 'common/features/featuresReducer';
import { createStyles } from '../../utils/createStyle';

const styles = createStyles({
    cartItemsCounter: [
        `absolute border border-brand rounded-full text-brand-accent
w-4 h-4 bg-white font-bold align-middle text-xs`,
        {
            top: '-0.1rem',
            right: '0.7rem',
        },
    ],
});

const CartIcon = () => {
    const { cart } = useRootSelector((s) => s.store.cart);
    const cartCount = cart ? cart.productCount : 0;
    const cartIcon = cartCount > 0 ? faShoppingCartSolid : faShoppingCart;

    return (
        <React.Fragment>
            <FontAwesomeIcon icon={cartIcon} size="2x" />
            {cart && cart.productCount > 0 && (
                <span className={styles.cartItemsCounter}>{cartCount > 9 ? '9+' : cartCount}</span>
            )}
        </React.Fragment>
    );
};

export function determineButtonIcon(buttonName: string, buttonIcon: IconDefinition | undefined) {
    if (buttonName === 'cart') {
        return <CartIcon />;
    }

    return buttonIcon && <FontAwesomeIcon icon={buttonIcon} size="2x" />;
}
