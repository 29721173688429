import React, { useEffect } from 'react';
import { useFormikContext, FormikState } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import { SecurityQuestions } from 'features/coordinator/enrollment/steps/SecurityQuestions';
import { orgEnrollPasswordValidator } from 'common/modules/form-validation/validators';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import { RegistrationRequestWithoutOrg } from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { FormikSetPasswordField } from 'components/fields/FormikSetPasswordField';
import { useAppConfig } from 'common/features/app-config/utils/useAppConfig';
import { UserContent } from 'components/user-content/UserContent';
import { ContentfulGlobal } from 'common/api/contentful/models/ContentfulGlobal';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { useApi } from 'common/modules/async-actions/useApi';
import { getQuestions } from 'common/api/users/questions';
import { h5 } from 'styles/typography';

const RegistrationRequestSetPasswordField =
    typedFormikField<RegistrationRequestWithoutOrg>()(FormikSetPasswordField);

interface SecuritySubStepProps {
    globalContent: ContentfulGlobal | null;
    shouldRender: boolean;
    refCaptcha: React.RefObject<ReCAPTCHA>;
}

export const SecuritySubStep = ({
    globalContent,
    shouldRender,
    refCaptcha,
}: SecuritySubStepProps) => {
    const { appConfig } = useAppConfig();
    const { values } = useFormikContext<FormikState<RegistrationRequestWithoutOrg>>();

    const [loadQuestions, { loading }, questions] = useApi(getQuestions);

    useEffect(() => {
        if (!questions && !loading) {
            loadQuestions();
        }
    }, [loadQuestions, loading, questions]);

    // Case(Should Not Render): User has not filled in the first fields
    if (!shouldRender) {
        return null;
    }

    if (shouldRender && loading) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <>
            <h1 className={h5}>Account Security</h1>
            <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-5 md:gap-y-5 my-4">
                <SecurityQuestions
                    questions={questions?.data || null}
                    selections={values as unknown as { [key: string]: string }}
                />
                <div>
                    <RegistrationRequestSetPasswordField
                        type="password"
                        name="password"
                        label="Password (8+ characters)"
                        maxLength={200}
                        validate={orgEnrollPasswordValidator}
                        minAcceptableScore={appConfig.passwordPolicy.minimumZxcvbnScore}
                    />
                    {globalContent && (
                        <div className="text-xs text-grey-1 mt-3">
                            <UserContent content={globalContent.setPasswordTooltip} />
                        </div>
                    )}
                </div>
                <ReCAPTCHA
                    ref={refCaptcha}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                />
            </div>
        </>
    );
};
