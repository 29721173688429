// TODO: Why is this pragma used? Applying types here shouldn't be a problem?
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

export const API_BASE = process.env.REACT_APP_API_BASE || '';
export const OCP_APIM_SUBSCRIPTION_KEY = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY || '';
export const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '';
export const CONTENTFUL_SPACE = process.env.REACT_APP_CONTENTFUL_SPACE || '';
export const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || '';
export const CONTENTFUL_ENTRY_ID_PRODUCT_CATALOG =
    process.env.REACT_APP_CONTENTFUL_ENTRY_ID_PRODUCT_CATALOG || '';
export const CONTENTFUL_ENTRY_ID_PRODUCT_CATALOG_SHOP_ONLINE =
    process.env.CONTENTFUL_ENTRY_ID_PRODUCT_CATALOG_SHOP_ONLINE || '';
export const ENABLE_DASHBOARD = 'true';
export const RAISE_RIGHT_APP_TYPE: 'mobile' | 'web' = (
    process.env.REACT_APP_RAISE_RIGHT_APP_TYPE || 'mobile'
).toLowerCase();
// If the app is Web we will use Duende's BFF Security Framework (SSO) instead of JWT Tokens for API auth.
// For the Mobile app everything keeps the same.
export const DO_NOT_ADD_BEARER_TOKENS = RAISE_RIGHT_APP_TYPE === 'web';
export const RAISE_RIGHT_APP_URL = process.env.REACT_APP_RAISE_RIGHT_APP_URL;
export const REACT_APP_LAUNCH_SCRIPWARE_URL = process.env.REACT_APP_LAUNCH_SCRIPWARE_URL;
export const PRODUCT_IMAGES_URL = process.env.REACT_APP_PRODUCT_IMAGES_URL || '';

export const FLIPGIVE_ORDERHISTORY_URL =
    process.env.REACT_APP_RUNTIME_ENVIRONMENT === 'prod'
        ? 'https://cloud.flipgive.com/trips?token=<token>&webview=true'
        : 'https://cloud.flipgive-test.com/trips?token=<token>&webview=true';
