import React, { useRef, useEffect } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

import { ModalStyles } from 'styles/modals';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';

export const spin = (duration = 250) => new Promise((resolve) => setTimeout(resolve, duration));
export const openLoader = (loader: LoadingOverlay | null) => loader && loader.open();
export const closeLoader = (loader: LoadingOverlay | null) => loader && loader.close();

interface LoadingIndicatorProps {
    onMount: () => void;
    onUnmount: () => void;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { onMount, onUnmount } = props;
    useEffect(() => {
        const { activeElement } = document;
        // Without the spin(0), radix thinks focus is moving outside the focus trap and resets it
        spin(0).then(() => ref.current?.focus());
        onMount();
        return () => {
            spin(0).then(() => {
                (activeElement as HTMLElement | null)?.focus();
                onUnmount();
            });
        };
    }, [onMount, onUnmount]);

    return (
        <div ref={ref} tabIndex={0} className="outline-none" aria-live="assertive" aria-busy="true">
            <ActivityIndicator />
            <div className="sr-only">Loading</div>
        </div>
    );
};

interface LoadingOverlayState {
    isOpen: boolean;
}

export class LoadingOverlay extends React.Component<{}, LoadingOverlayState> {
    state = {
        isOpen: false,
    };

    openPromise: Promise<void> | null = null;
    closePromise: Promise<void> | null = null;
    _onMount = () => {};
    _onUnmount = () => {};

    onMount = () => {
        this._onMount();
    };

    onUnMount = () => {
        this._onUnmount();
    };

    open() {
        if (this.state.isOpen) {
            return this.openPromise;
        }
        this.openPromise = new Promise((resolve) => {
            this._onMount = resolve;
        });
        this.setState({ isOpen: true });
        return this.openPromise;
    }

    close() {
        if (!this.state.isOpen) {
            return this.closePromise;
        }
        this.closePromise = new Promise((resolve) => {
            this._onUnmount = resolve;
        });
        this.setState({ isOpen: false });
        return this.closePromise;
    }

    render() {
        return (
            <AlertDialog.Root open={this.state.isOpen}>
                <AlertDialog.Overlay className={ModalStyles.overlay} />
                <AlertDialog.Content className={ModalStyles.contentWrapper} role="alert">
                    <LoadingIndicator onMount={this.onMount} onUnmount={this.onUnMount} />
                </AlertDialog.Content>
            </AlertDialog.Root>
        );
    }
}
