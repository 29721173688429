import { SECURED_E_COMM_URL } from 'common/api/config';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';

export const getFavorites = () =>
    withTokensIfNeeded<number[]>(`${SECURED_E_COMM_URL}/brands/favorite`);

export const favoriteBrand = (brandId: number) =>
    withTokensIfNeeded(`${SECURED_E_COMM_URL}/Brands/${brandId}/favorite`, {
        method: 'POST',
    });

export const unfavoriteBrand = (brandId: number) =>
    withTokensIfNeeded(`${SECURED_E_COMM_URL}/Brands/${brandId}/favorite`, {
        method: 'DELETE',
    });
