import { useState, useEffect } from 'react';

import { Entry } from 'contentful';

import { Hero, toHeroData } from '../features/homepage/contexts/contentful';
import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import RemoteConfigWeb from '../config/RemoteConfigWeb';
import { contentfulApi } from 'common/api/utils/contentful';
import { HeroComponent } from 'common/api/store/models/HomePageLayout';

export const useRemoteContent = (landingPageType: keyof typeof defaultConfigKeys) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [heroes, setHeroes] = useState<{ [key: string]: Hero }>({});
    const [heroId, setHeroId] = useState<string>('');

    useEffect(() => {
        (async () => {
            await RemoteConfigWeb.initialize();

            const landingPageHeroId = RemoteConfigWeb.getRemoteValue(landingPageType).asString();

            setHeroId(landingPageHeroId);

            const contentfulResponse = await contentfulApi.getEntry<Hero>(landingPageHeroId);

            const convertedHero = toHeroData(
                contentfulResponse.data as unknown as Entry<HeroComponent>
            );

            setHeroes({
                [landingPageHeroId]: convertedHero as unknown as Hero,
            });

            setLoading(false);
        })();
    }, [landingPageType]);

    return { heroes, loading, heroId };
};
