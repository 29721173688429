import keyBy from 'lodash.keyby';

import { brandLoader } from './utils/brandLoader';

import { WALLET_CARDS_URL, WALLET_CARDS_ARCHIVED_URL, SECURED_WALLET_URL } from 'common/api/config';
import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import {
    Card,
    CardBalance,
    isCardReloadable,
    CardEligibility,
    CardThirdPartyViewerUrl,
} from 'common/api/e-comm/models/Card';
import { uniq, isDefined } from 'common/utils';
import { APIResponse } from 'common/api/models';
import { ECommBrand } from 'common/api/e-comm/models/ECommBrand';
import { Product } from 'common/api/e-comm/models/Product';
import { getReloadProduct } from 'common/api/e-comm/brands/reload';
import { RemoteCardDetails } from 'common/api/e-comm/models/RemoteCardDetails';

export const getWallet = () =>
    withTokensIfNeeded<Card[]>(`${WALLET_CARDS_URL}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getArchivedWallet = () =>
    withTokensIfNeeded<Card[]>(`${WALLET_CARDS_ARCHIVED_URL}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

interface UnarchiveCard {
    type: string;
    title: string;
    status: number;
    detail: string;
    instance: string;
}

export const unarchiveCard = (id: string) =>
    withTokensIfNeeded<UnarchiveCard>(`${WALLET_CARDS_ARCHIVED_URL}/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

type WalletWithAssociatedBrandsResponse = APIResponse<{
    brands: { [id: string]: ECommBrand };
    cards: { userId: string; cards: { [id: string]: Card } };
    reloadProducts: { [brandId: number]: Product };
}>;

export const getWalletAndAssociatedBrands = async (
    userId: string,
    brandIds?: number[]
): Promise<WalletWithAssociatedBrandsResponse> => {
    const wallet = await getWallet();

    if (wallet.error) {
        return wallet;
    }

    const brandIdsForReloads = uniq(wallet.data.filter(isCardReloadable).map((c) => c.brandId));
    const [batchedBrands, reloadProductsResult] = await Promise.all([
        brandLoader.loadBrands(brandIds || wallet.data.map((c) => c.brandId)),
        Promise.all(
            brandIdsForReloads
                .filter((id) => !brandIds || brandIds.includes(id))
                .map(getReloadProduct)
        ),
    ]);

    const brands = batchedBrands.reduce<ECommBrand[]>(
        (aggregateBrands, response) =>
            response.error ? aggregateBrands : aggregateBrands.concat(response.data),
        []
    );

    return {
        data: {
            brands: keyBy(brands, (brand) => brand.id),
            cards: { userId, cards: keyBy(wallet.data, (card) => card.id) },
            reloadProducts: keyBy(
                reloadProductsResult.map((r) => r.data).filter(isDefined),
                (p) => p.brandId
            ),
        },
    };
};

export const editCardDetails = (id: string, name: string, pin: string) =>
    withTokensIfNeeded(`${WALLET_CARDS_URL}/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ name, pin }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

export interface RegisterCardRequest {
    brandId: number;
    cardNumber: string;
    pin: string | null;
    name: string | null;
    supplierCode: string | null;
    productSku: string | null;
}

export const registerCard = (registerCardRequest: RegisterCardRequest) =>
    withTokensIfNeeded<Card>(WALLET_CARDS_URL, {
        method: 'POST',
        body: JSON.stringify(registerCardRequest),
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateBalance = (cardId: string, cardBalance: number) =>
    withTokensIfNeeded(`${WALLET_CARDS_URL}/${cardId}/balance`, {
        method: 'PUT',
        body: JSON.stringify({ cardBalance }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getCardBalance = (cardId: string) =>
    withTokensIfNeeded<CardBalance>(`${WALLET_CARDS_URL}/${cardId}/balance`);

export const deleteCard = (cardId: string) =>
    withTokensIfNeeded(`${WALLET_CARDS_URL}/${cardId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getCardBarcode = (cardId: string) =>
    withTokensIfNeeded<string>(`${WALLET_CARDS_URL}/${cardId}/barcode`);

export const setViewedDate = (cardId: string) =>
    withTokensIfNeeded(`${WALLET_CARDS_URL}/${cardId}/view`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getWalletCardById = (cardId: string) =>
    withTokensIfNeeded<Card>(`${WALLET_CARDS_URL}/${cardId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getThirdPartyViewUrl = async (id: string) => {
    const response = await withTokensIfNeeded<CardThirdPartyViewerUrl>(
        `${SECURED_WALLET_URL}/viewer/view-card/${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response;
};

export const getRemoteCardDetails = (cardId: string) =>
    withTokensIfNeeded<RemoteCardDetails>(`${WALLET_CARDS_URL}/${cardId}/remote`);

export const validateCard = (cardNumber: string, supplierCode: string) =>
    withTokensIfNeeded<CardEligibility>(`${WALLET_CARDS_URL}/validate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cardNumber, supplierCode }),
    });

export const isPinRequiredForReloadOrBalance = (supplierCode: string) =>
    withTokensIfNeeded<{ isPinRequired: boolean }>(
        `${WALLET_CARDS_URL}/IsPinRequiredForReloadOrBalance`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ supplierCode }),
        }
    );
