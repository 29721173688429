import { APIError } from '../models';

export const createAPIError = <T>(
    response: Response | null,
    error: Error | null = null,
    body?: T
): APIError<T> => ({
    response,
    error,
    body,
});
