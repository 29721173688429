import React, { useMemo, useEffect, useCallback, useRef, useContext } from 'react';
import { Formik } from 'formik';
import groupBy from 'lodash.groupby';

import { StepWrapperProps, StepWrapper } from '../../enrollment/steps/StepWrapper';
import { EnrollmentContext } from '../context';
import { FormikSelectField } from '../components/FormikSelectField';

import { Organization } from 'common/api/crm/models/Organization';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Btn } from 'components/btn/Btn';
import { LoadingOverlay } from 'components/loading-overlay/LoadingOverlay';
import { useValidation } from 'modules/form-validation/useValidation';
import { getOrganizationTypes } from 'common/api/crm/organization/service';
import { useApi } from 'common/modules/async-actions/useApi';
import { orgTypeValidator } from 'features/coordinator/enrollment/steps/validators';
import { OrganizationType } from 'common/api/crm/models/OrganizationType';

interface LitePlusOrganizationTypeStepProps extends StepWrapperProps {}

export const LitePlusOrganizationTypeStep = (props: LitePlusOrganizationTypeStepProps) => {
    const [loadOrgTypes, loadOrgTypesState, loadOrgTypesRes] = useApi(getOrganizationTypes);
    const { organization, setOrganization, goToNextStep } = useContext(EnrollmentContext);

    const orgTypes = useMemo(() => loadOrgTypesRes?.data || null, [loadOrgTypesRes]);

    const { name } = organization;
    const headingData = useMemo(() => ({ orgName: name }), [name]);
    const validateAndSubmit = useValidation<Organization>();

    const loader = useRef<LoadingOverlay>(null);

    const _onSubmit = useCallback(
        async (values: Organization) => {
            setOrganization({
                ...organization,
                organizationTypeId: values.organizationTypeId,
            });
            goToNextStep();
        },
        [goToNextStep, organization, setOrganization]
    );

    useEffect(() => {
        loadOrgTypes();
    }, [loadOrgTypes]);

    const filteredSortedOrgTypes = useMemo(() => {
        const types =
            orgTypes
                ?.filter((x) => x.isEnabled)
                .sort((a, b) => {
                    return a.description.localeCompare(b.description);
                }) || [];
        const moveToEnd = ['Miscellaneous', 'Other'];
        const { start = [], end = [] } = groupBy(types, ({ description }: OrganizationType) =>
            moveToEnd.includes(description) ? 'end' : 'start'
        );
        return [...start, ...end];
    }, [orgTypes]);

    const orgTypeItems = useMemo(
        () =>
            filteredSortedOrgTypes?.map((orgType) => ({
                id: orgType.id.toString(),
                text: orgType.description,
            })),
        [filteredSortedOrgTypes]
    );

    // focus first form field
    useEffect(() => document.querySelector('button')?.focus(), [loadOrgTypesState.loading]);

    if (loadOrgTypesState.loading || !orgTypes) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <StepWrapper {...props} headingData={headingData}>
            <Formik initialValues={organization} onSubmit={_onSubmit}>
                {(formikProps) => (
                    <form
                        noValidate
                        className="max-w-2xl mx-auto"
                        onSubmit={validateAndSubmit(formikProps)}
                    >
                        <div>
                            <div className="text-brand-dark text-2xl font-bold mb-2 my-6">
                                What type of group are you fundraising for?
                            </div>
                            <div className="max-w-xs mt-4">
                                <FormikSelectField<Organization>
                                    name="organizationTypeId"
                                    label={'Organization Type'}
                                    validate={orgTypeValidator}
                                    placeholder="Select your organization type"
                                    items={orgTypeItems}
                                    className={'w-full'}
                                />
                            </div>
                        </div>
                        <div className="text-center mt-12">
                            <Btn type="submit">Answer a Few Questions</Btn>
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
