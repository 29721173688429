import React, { useEffect, Fragment, useState, useCallback, useRef, useContext } from 'react';
import { Route, Switch, useRouteMatch, useHistory, matchPath, useLocation } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';

import {
    OrganizationEnrollmentProgress,
    getEnrollmentProgressFunction,
} from '../components/useContinueOrgEnrollment';
import { ContentFullEnrollmentContext } from '../components/useContentfulEnrollment';

import { GetStartedStep } from './steps/GetStartedStep';
import { YourAccountStep } from './steps/YourAccountStep';
import { SecurityQuestionsStep } from './steps/SecurityQuestionsStep';
import { TwoFactorStep } from './steps/TwoFactorStep';
import { TwoFactorVerifyStep } from './steps/TwoFactorVerifyStep';
import { OrganizationStep, OrganizationStepProps } from './steps/OrganizationStep';
import { Organization2Step } from './steps/Organization2Step';
import { GetEarningsStep } from './steps/GetEarningsStep';
import { VoidedCheckStep } from './steps/VoidedCheckStep';
import { OrgEnrollmentCompleted } from './OrgEnrollmentCompleted';
import Stepper from './steps/Stepper';
import { OrgEnrollmentContinue } from './OrgEnrollmentContinue';

import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Btn } from 'components/btn/Btn';
import { Routes } from 'routes';
import { createStyles } from 'utils/createStyle';
import logo from 'media/images/RaiseRightLogo.svg';
import { useApi } from 'common/modules/async-actions/useApi';
import { emptyRegistrationRequestWithoutOrg } from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import {
    UnmaskedPhoneNumber,
    emptyUnmaskedPhoneNumber,
} from 'common/api/users/models/UnmaskedPhoneNumber';
import { emptyOrganization } from 'common/api/crm/models/Organization';
import { getQuestions } from 'common/api/users/questions';
import { APIResponse } from 'common/api/models';
import { getOrganizationTypes } from 'common/api/crm/organization/service';
import { OrganizationDetail } from 'common/api/crm/models/OrganizationDetail';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useConfirmUnload } from 'hooks/useConfirmUnload';
import { Head } from 'components/head/Head';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { getCookieAsObject } from 'utils/cookies';
import { addOrgEnrollmentReferralInfo } from 'common/api/crm/family/service';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { RootState, useRootSelector } from 'rootStore';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import { selectUserId } from 'common/features/store/duck/account/duck';
import { OrganizationEnrollment } from 'common/api/contentful/models/OrganizationEnrollment';
import { Home, User, Shield, Heart, DollarSign } from 'react-feather';
import { Step } from './steps/Stepper/Stepper';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { OrgSelectDropdown } from 'components/search/components/OrgSelectDropdown';
import { selectOrgSwitchState } from 'common/features/store/duck/home/duck';
import { FullScreenOverlay } from 'features/shop-base/OrgChangeOverlay/FullScreenOverlay';

// enable checking lite plus remote config
const styles = createStyles({
    masthead: 'shadow-lg flex pt-5 pb-3 sm:pt-6 sm:pb-4',
    logo: 'w-40 sm:w-60 m-auto',
    logoContainer: 'text-center justify-center w-1/3',
});

interface OrgEnrollmentProps {
    shouldRender: boolean;
}

const allSteps: Step[] = [
    { title: 'Get Started', icon: Home, routes: [Routes.StartProgram], exact: true },
    { title: 'Your Account', icon: User, routes: [Routes.StartProgramAccount], exact: false },
    {
        title: 'Account Security',
        icon: Shield,
        routes: [Routes.StartProgramSecurity],
        exact: false,
    },
    {
        title: 'Your Organization',
        icon: Heart,
        routes: [Routes.StartProgramOrganization],
        exact: false,
    },
    {
        title: 'Get Earnings',
        icon: DollarSign,
        routes: [Routes.StartProgramEarnings, Routes.StartProgramVoidedCheck],
        exact: false,
    },
];

const OrganizationEnrollmentScreen = ({ shouldRender }: OrgEnrollmentProps) => {
    useScrollToTop();
    const { pathname, search } = useLocation();
    useConfirmUnload(
        useCallback(
            (nextLocation) => {
                const routesThatShouldNotPrompt = [
                    Routes.StartProgram,
                    Routes.StartProgramAccount,
                    Routes.StartProgramSecurity,
                    Routes.StartProgramCompleted,
                    Routes.StartProgramContinue,
                    Routes.StartProgramVoidedCheck,
                    Routes.StartProgramEarnings,
                    Routes.StartProgramOrganization,
                ];
                if (
                    routesThatShouldNotPrompt.some((path) =>
                        matchPath(pathname, { path, exact: true })
                    )
                ) {
                    return false;
                }
                if (nextLocation) {
                    const isExitingEnrollment = !matchPath(nextLocation.pathname, {
                        path: Routes.StartProgram,
                    });
                    return isExitingEnrollment;
                }
                return true;
            },
            [pathname]
        )
    );

    useHomeDetails();

    const isMounted = useRef(false);
    const history = useHistory();
    useEffect(() => {
        if (isMounted.current) {
            return;
        }
        isMounted.current = true;
        if (pathname === Routes.StartProgram) {
            return;
        }
        history.replace(Routes.StartProgramContinue + search);
    }, [history, pathname, search]);

    const { contentfulEntry, isLoading: isLoadingOrgEnrollment } = useContext(
        ContentFullEnrollmentContext
    );
    const orgEnrollment = contentfulEntry as OrganizationEnrollment;
    const { showOverlay } = useSelector(selectOrgSwitchState);

    const [loadQuestions, loadQuestionsState, loadQuestionsRes] = useApi(getQuestions);
    const loadQuestionsIfNeeded = useCallback(() => {
        if (!loadQuestionsRes && !loadQuestionsState.loading) {
            loadQuestions();
        }
    }, [loadQuestions, loadQuestionsState, loadQuestionsRes]);

    const [loadOrgTypes, loadOrgTypesState, loadOrgTypesRes] = useApi(getOrganizationTypes);
    const loadOrgTypesIfNeeded = useCallback(() => {
        if (!loadOrgTypesRes && !loadOrgTypesState.loading) {
            loadOrgTypes();
        }
    }, [loadOrgTypes, loadOrgTypesState, loadOrgTypesRes]);

    const [registrationRequest, setRegistrationRequest] = useState(
        emptyRegistrationRequestWithoutOrg
    );
    const [twoFactorPhone, setTwoFactorPhone] =
        useState<UnmaskedPhoneNumber>(emptyUnmaskedPhoneNumber);
    const [organization, setOrganization] = useState(emptyOrganization);
    const [orgCreationPromise, setOrgCreationPromise] =
        useState<Promise<APIResponse<OrganizationDetail>>>();

    const onOrgCreationSubmit: OrganizationStepProps['onSubmit'] = useCallback(
        (values, promise) => {
            setOrganization(values);
            setOrgCreationPromise(promise);
        },
        []
    );

    const store = useStore<RootState>();

    const onProgressLoaded = useCallback(
        async (progress: OrganizationEnrollmentProgress) => {
            const { pendingOrg } = progress;
            let { route } = progress;

            if (pendingOrg) {
                const organizationTypeId = pendingOrg.organizationType
                    ? String(pendingOrg.organizationType.id)
                    : '';
                setOrganization({ ...pendingOrg, organizationTypeId });
            }
            if (!progress.hasExistingEnrollment) {
                const userId = selectUserId(store.getState());
                addOrgEnrollmentReferralInfo(userId, {
                    trackingCookie: getCookieAsObject(document.cookie),
                });

                await updateEnrollmentProgress({
                    state: ProgressState.EnrollmentStartedExistingAccount,
                });
                const updatedProgress = await getEnrollmentProgressFunction();
                route = updatedProgress.route;
            }

            history.replace(route + search);
        },
        [history, store, search]
    );

    const isCompleted = useRouteMatch({
        path: Routes.StartProgramCompleted,
        sensitive: false,
        exact: true,
    });

    const isContinue = useRouteMatch({
        path: Routes.EnrollContinue,
        sensitive: false,
        exact: true,
    });

    const state = useRootSelector((s) => s.store);

    const renderStepper = () => <Stepper steps={allSteps} />;

    const renderBody = () => {
        // Show the loading indicator if contentful data is loading or if Parent component Enroll
        // is not ready to render.

        if (isLoadingOrgEnrollment || !shouldRender) {
            return (
                <div className="mt-12 justify-center flex">
                    <ActivityIndicator />
                </div>
            );
        }

        if (!orgEnrollment) {
            return;
        }

        return (
            <Switch>
                <Route path={Routes.StartProgram} exact>
                    <GetStartedStep
                        heading={orgEnrollment.landingPageHeading}
                        subheading={orgEnrollment.landingPageSubheading}
                    />
                </Route>
                <Route path={Routes.StartProgramContinue}>
                    <OrgEnrollmentContinue onProgressLoaded={onProgressLoaded} />
                </Route>
                <Route path={Routes.StartProgramAccount}>
                    <YourAccountStep
                        heading={orgEnrollment.createAccountHeading}
                        subheading={orgEnrollment.createAccountSubheading}
                        registrationRequest={registrationRequest}
                        loadDataForNextScreen={loadQuestionsIfNeeded}
                        onSubmit={setRegistrationRequest}
                    />
                </Route>
                <Route path={Routes.StartProgramSecurity} exact>
                    <SecurityQuestionsStep
                        heading={orgEnrollment.securityQuestionsHeading}
                        subheading={orgEnrollment.securityQuestionsSubheading}
                        registrationRequest={registrationRequest}
                        loadQuestions={loadQuestionsIfNeeded}
                        loadQuestionsState={loadQuestionsState}
                        questions={loadQuestionsRes?.data || null}
                    />
                </Route>
                <Route path={Routes.StartProgramTwoFactor}>
                    <TwoFactorStep
                        heading={orgEnrollment.twoFactorPhoneHeading}
                        subheading={orgEnrollment.twoFactorPhoneSubheading}
                        initialPhoneNumber={
                            twoFactorPhone.phoneNumber || registrationRequest.phoneNumber
                        }
                        onPhoneAdded={setTwoFactorPhone}
                    />
                </Route>
                <Route path={Routes.StartProgramTwoFactorVerify}>
                    <TwoFactorVerifyStep
                        heading={orgEnrollment.twoFactorPhoneVerifyHeading}
                        subheading={orgEnrollment.twoFactorPhoneVerifySubheading}
                        phone={twoFactorPhone}
                    />
                </Route>
                <Route path={Routes.StartProgramOrganization} exact>
                    <OrganizationStep
                        heading={orgEnrollment.organizationHeading}
                        subheading={orgEnrollment.organizationSubheading}
                        firstName={registrationRequest.firstName}
                        onSubmit={onOrgCreationSubmit}
                        loadDataForNextScreen={loadOrgTypesIfNeeded}
                    />
                </Route>
                <Route path={Routes.StartProgramOrganization2}>
                    <Organization2Step
                        heading={orgEnrollment.organization2Heading}
                        subheading={orgEnrollment.organization2Subheading}
                        organization={organization}
                        orgCreationPromise={orgCreationPromise}
                        loadOrgTypes={loadOrgTypesIfNeeded}
                        loadOrgTypesState={loadOrgTypesState}
                        orgTypes={loadOrgTypesRes?.data || null}
                        onSubmit={setOrganization}
                    />
                </Route>
                <Route path={Routes.StartProgramEarnings}>
                    <GetEarningsStep
                        heading={orgEnrollment.earningsHeading}
                        subheading={orgEnrollment.earningsSubheading}
                        organization={organization}
                    />
                </Route>
                <Route path={Routes.StartProgramVoidedCheck}>
                    <VoidedCheckStep
                        ProgramCompletedRoute={Routes.StartProgramCompleted}
                        organization={organization}
                    />
                </Route>
                <Route path={Routes.StartProgramCompleted}>
                    <OrgEnrollmentCompleted
                        organization={organization}
                        curatedArticles={orgEnrollment.curatedArticleButtons}
                    />
                </Route>
            </Switch>
        );
    };

    const authenticatedHeading = (
        <div className="h-full flex items-end">
            <p className="text-center ml-auto mr-auto">
                <OrgSelectDropdown
                    homeDetails={state.home.homeDetails}
                    redirectRoute={Routes.Shop}
                />
            </p>
        </div>
    );

    const callToActionLg = (
        <div className="w-full">
            <Btn isSmall className="hidden lg:inline" onClick={() => sendToLoginPage()}>
                Sign In
            </Btn>
        </div>
    );

    const callToActionSm = (
        <div className="my-2">
            <Btn isSmall className="float-right" onClick={() => sendToLoginPage()}>
                Sign In
            </Btn>
        </div>
    );

    return (
        <Fragment>
            <Head title="Program Enrollment" />

            <header className={styles.masthead}>
                <div className="hidden lg:block w-1/3"></div>
                <div className="hidden lg:block w-1/3 mt-2">
                    <img className={styles.logo} src={logo} alt="RaiseRight" />
                </div>
                <div className="hidden lg:block w-1/3">
                    {state.home.homeDetails?.profile.firstName
                        ? authenticatedHeading
                        : callToActionLg}
                </div>
                <div className="lg:hidden flex flex-row w-full px-4">
                    <div className="flex flex-col w-full">
                        <img className={styles.logo} src={logo} alt="RaiseRight" />
                        {state.home.homeDetails?.profile.firstName
                            ? authenticatedHeading
                            : callToActionSm}
                    </div>
                </div>
            </header>
            {showOverlay && <FullScreenOverlay />}
            <main className={!showOverlay ? 'inline' : 'hidden'}>
                {!isCompleted && !isContinue && renderStepper()}
                {renderBody()}
            </main>
        </Fragment>
    );
};

export default OrganizationEnrollmentScreen;
