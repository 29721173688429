import React, { useState, useEffect, useContext, useCallback } from 'react';

import { BottomContainer } from '../../../components/BottomContainer';
import { EnrollmentContext } from '../../context';
import { ReactComponent as AthleticImg } from '../../../../../media/images/Athletic.svg';

import { createOrganization } from 'common/api/crm/organization/service';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { withRetries } from 'common/utils/withRetries';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { Btn } from 'components/btn/Btn';
import { useAlert } from 'modules/alerts';
import { ENROLLMENT_VERSION_2 } from 'features/coordinator/constants';

export const GettingYourRecommendationReady = () => {
    const { organization, goToNextStep, setOrganization } = useContext(EnrollmentContext);

    const { retrieveBlackBox } = useBlackBoxValues();

    const [errors, setErrors] = useState(0);
    const [isCreatingOrganization, setIsCreatingOrganization] = useState(false);
    const alert = useAlert();

    const createOrg = useCallback(async () => {
        setIsCreatingOrganization(true);

        try {
            const blackBoxValues = await retrieveBlackBox();
            const response = await createOrganization({ ...organization, ...blackBoxValues });

            if (response.response?.ok) {
                await withRetries(updateEnrollmentProgress)({
                    state: ProgressState.OrgCreated,
                    organizationId: response.data?.id,
                    version: ENROLLMENT_VERSION_2,
                });
                // NOTE: the org-id might not be available at this time.
                // Check w/ backend to verify if this is true
                setOrganization({ ...organization, id: response.data?.id });
                goToNextStep();
            } else if (response.error?.error?.message) {
                alert({
                    title: 'Error',
                    message: response.error.error.message,
                });
                setErrors((_) => _ + 1);
            }
        } catch (ex) {
            const errMsg = (
                typeof ex === 'string' ? ex.toString() : (ex as Error)?.message || ''
            ).toString();
            setErrors((_) => _ + 1);
            alert({
                title: 'Error',
                message: errMsg,
            });
        } finally {
            setIsCreatingOrganization(false);
        }
    }, [
        retrieveBlackBox,
        setErrors,
        setIsCreatingOrganization,
        alert,
        goToNextStep,
        organization,
        setOrganization,
    ]);

    useEffect(() => {
        createOrg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const phone = '';

    const renderError = () => {
        return (
            <>
                {!navigator.onLine && <div className="text-error mb-2">Check your connection.</div>}
                {errors > 0 && (
                    <div className="text-center">
                        <div className="text-error mb-2">
                            Looks like we are having trouble completing this request. Please call
                            our Customer Support Team at (800) 727-4715 | Option 4
                        </div>
                        <Btn
                            disabled={isCreatingOrganization}
                            onClick={createOrg}
                            className="inline-block"
                        >
                            Try Again
                        </Btn>
                    </div>
                )}
                {phone && errors >= 3 && (
                    <div className="text-xs text-error mt-4">
                        For further assistance please contact our Customer Support Team at {phone}.
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <div className="mt-8 sm:mt-10 px-6 sm:px-10 max-w-2xl ml-auto mr-auto items-center flex flex-col max-h-full mb-56 overflow-auto">
                {isCreatingOrganization && (
                    <>
                        <h2 className="text-2xl sm:text-4xl text-center leading-tight font-normal text-brand-dark">
                            Getting your recommendation ready...
                        </h2>
                        <div className="my-8 w-full sm:w-auto">
                            <AthleticImg />
                        </div>
                    </>
                )}
                {!isCreatingOrganization && renderError()}
            </div>
            <BottomContainer />
        </>
    );
};
