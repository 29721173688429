import React from 'react';
import { RouteProps, Route } from 'react-router-dom';

const includeInternalRoutes = process.env.REACT_APP_INCLUDE_INTERNAL_ROUTES === 'true';

export const InternalRoute = (props: RouteProps) => {
    if (!includeInternalRoutes) {
        return null;
    }
    return <Route {...props} />;
};
