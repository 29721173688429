import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BaseLandingPage } from '../base/BaseLandingPage';
import { SearchGrid } from 'components/search-grid/SearchGrid';
import { TopPicksCard } from 'features/search/components/TopPicksCard';
import { useSelector } from 'react-redux';
import { FeaturesState } from 'common/features/featuresReducer';
import { useSearchGridStyles } from 'features/search/components/hooks';
import { getCategoryById } from 'common/api/e-comm/products/categories';
import { useParams, useHistory } from 'react-router';
import { CategoryData } from 'common/api/categories/models/CategoryData';
import { createStyles } from 'utils/createStyle';
import FilterSection from 'features/search/components/FilterSection';
import { LandingPageProps } from '../LandingPage';
import { MenuTabNames } from 'features/search/components/FilterBy';
import { useCategoryAd } from 'common/hooks/useCategoryAd';
import { Routes } from 'routes';
import { NavLink } from 'react-router-dom';
import { AdLinkFields } from 'common/api/contentful/models/AdLinkFields';
import { Entry } from 'contentful';
import { useRootSelector } from 'rootStore';
import { selectBrandId } from 'common/features/store/duck/brands/duck';
import { AdEntry } from 'common/api/contentful/models/AdEntry';
import { ECommBrand } from 'common/api/e-comm/models/ECommBrand';
import { logClickAd, logViewAd, PromotionSources } from 'config/analytics';
import { CreativeSlotTypes } from 'common/types/CreativeSlotTypes';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { getContentfulPath } from 'utils/contentful';
import { ContentfulContentTypeId } from 'common/api/config';

export interface CategoryScreenProps {
    categoryId: number;
}

export const CategoryScreen = () => {
    const { pageBrands, hasFetched } = useSelector(
        (state: FeaturesState) => state.store.searchGrid
    );
    const [searchGridStyles] = useSearchGridStyles<'325', '280'>('325', '280');
    const categoryId = parseInt(useParams<{ categoryId: string }>().categoryId, 10);
    const { categoryData, categoryName } = useCategory(Number(categoryId));
    const history = useHistory();
    const { advertisement: categoryAd } = useCategoryAd(categoryId);

    useScrollToTop();

    if (isNaN(categoryId)) {
        // NOTE: handle case where we have an invalid categoryId
        history.replace(`${Routes.Shop}#categories`);
    }

    const brandsById = useRootSelector(selectBrandId);
    const categoryAdBrand = useMemo(() => {
        const brandId = (categoryAd?.fields?.link as Entry<AdLinkFields> | undefined)?.fields
            ?.brandId;
        if (!brandId) {
            return;
        }
        return brandsById[brandId];
    }, [categoryAd, brandsById]);

    const viewAdRef = useRef(false);
    useEffect(() => {
        if (!categoryAd || viewAdRef.current) {
            return;
        }

        viewAdRef.current = true;

        logViewAd({
            promotionName: categoryAd?.fields?.title,
            index: 0,
            source: PromotionSources.Contentful,
            creativeSlot: CreativeSlotTypes.CategoryBanner,
        });
    }, [categoryAd]);

    return (
        <CategoryScreenContext.Provider
            value={{
                categoryId: Number(categoryId),
                categoryName: categoryName || '',
                categoryData,
                categoryAd,
                categoryAdBrand,
            }}
        >
            <BaseLandingPage
                firebaseConfigKey="featured_page_hero_id"
                renderers={{
                    header: CategoryHeader,
                }}
            >
                <SearchGrid
                    categoryName={categoryName}
                    ItemComponent={TopPicksCard}
                    items={pageBrands}
                    isLoading={!hasFetched}
                    containerStyles={searchGridStyles}
                />
            </BaseLandingPage>
        </CategoryScreenContext.Provider>
    );
};

const CategoryHeader = ({ renderBonusSwitch }: LandingPageProps) => {
    const { categoryData, categoryName, categoryAd, categoryAdBrand } =
        useContext(CategoryScreenContext);

    const dynamicStyles = createStyles({
        filterBar: [
            '',
            {
                order: 2,
            },
        ],
        returnLink: [
            '',
            {
                order: 1,
                marginBottom: '40px',
            },
        ],
    });

    return (
        <div>
            <div className="flex justify-center items-center bg-pale-blue text-2xl md:text-4.5xl p-12 text-brand-dark md:mb-2">
                <img
                    src={categoryData?.iconUrl}
                    style={{ maxHeight: '48px' }}
                    className="hidden md:block"
                />

                <img
                    src={categoryData?.iconUrl}
                    style={{ height: '32px' }}
                    className="block md:hidden"
                />

                <span className="ml-4">{categoryName}</span>
            </div>

            <div className="flex items-center justify-center p-4 rounded-sm hidden sm:block text-center">
                <Ad
                    ad={categoryAd}
                    brand={categoryAdBrand}
                    creativeSlot={CreativeSlotTypes.CategoryBanner}
                />
            </div>

            {categoryName ? (
                <span className={dynamicStyles.filterBar}>
                    <FilterSection
                        renderBonusSwitch={renderBonusSwitch}
                        showAZFilter={true}
                        fixedFilter={`Categories/any(c: c eq '${categoryName}')`}
                        hideCategories={[MenuTabNames.category]}
                    />
                </span>
            ) : null}

            <div className="flex items-center justify-center p-4 rounded-sm block sm:hidden">
                <Ad
                    ad={categoryAd}
                    brand={categoryAdBrand}
                    creativeSlot={CreativeSlotTypes.CategoryBanner}
                />
            </div>
        </div>
    );
};

const Ad = ({
    ad,
    brand,
    creativeSlot,
}: {
    ad: AdEntry | undefined;
    brand: ECommBrand | undefined;
    creativeSlot: string;
}) => {
    const imgSrc = useMemo(() => ad?.fields?.image?.fields?.file.url ?? '', [ad]);
    const contentfulLink = useMemo(() => {
        const brandSlug = brand?.urlSlug.replace(/\//g, '');

        const entry = ad?.fields?.link as Entry<AdLinkFields> | undefined;
        return entry ? getContentfulPath(entry, brandSlug) : { path: '', type: '' };
    }, [ad, brand]);

    const onClick = useCallback(() => {
        if (!ad) {
            return;
        }

        logClickAd({
            creativeSlot,
            promotionName: ad?.fields?.title,
            index: 0,
            source: PromotionSources.Contentful,
        });
    }, [ad, creativeSlot]);

    switch (contentfulLink.type) {
        case ContentfulContentTypeId.ExternalLink:
            return (
                <a
                    href={contentfulLink.path}
                    onClick={onClick}
                    className="inline-block"
                    target="_blank"
                >
                    <img src={imgSrc} className="rounded-md mx-auto" />
                </a>
            );
        case ContentfulContentTypeId.Brand:
            return (
                <NavLink to={contentfulLink.path} onClick={onClick} className="inline-block">
                    <img src={imgSrc} className="rounded-md mx-auto" />
                </NavLink>
            );
        default:
            return <img src={imgSrc} className="rounded-md mx-auto" />;
    }
};

const useCategory = (categoryId: number) => {
    const [categoryData, setCategoryData] = useState<CategoryData>();
    const categoryName = useMemo(() => categoryData?.name, [categoryData]);

    useEffect(() => {
        (async () => {
            // retrieves category data (icon and name)
            const categoryData = await getCategoryById(categoryId);

            if (categoryData.data) {
                setCategoryData(categoryData.data);
            } else {
                setCategoryData(undefined);
            }
        })();
    }, [categoryId]);

    return {
        categoryData,
        categoryName,
    };
};

interface CategoryScreenContextValue {
    categoryId: number;
    categoryName: string;
    categoryData?: CategoryData;
    categoryAd: AdEntry | undefined;
    categoryAdBrand: ECommBrand | undefined;
}

const CategoryScreenContext = React.createContext<CategoryScreenContextValue>({
    categoryId: -1,
    categoryName: '',
    categoryData: undefined,
    categoryAd: undefined,
    categoryAdBrand: undefined,
});
