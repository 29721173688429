export const organizationTypeCodes: { [key: string]: number } = {
    AthleticGroup: 0,
    BandMusic: 1,
    Church: 2,
    Employee: 3,
    Hospital: 4,
    Miscellaneous: 5,
    PublicSchool: 6,
    ParochialSchool: 7,
    Scouts: 8,
    CommunityAid: 9,
};
