import { getBrandsOnly } from 'common/api/search/getBrands';
import { CanonicalSearchResultBrand } from 'common/api/search/models/CanonicalSearchResultBrand';
import { FeaturesState } from 'common/features/featuresReducer';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { createSlice } from 'common/modules/create-slice';
import {
    removeFirst,
    getItemsFromCache,
    addItemsToCacheByKey,
    CircularCache,
    trimAndLowerCase,
} from 'common/utils';

export const RECENT_SEARCH_TERMS_MAX = 10;

export interface SearchState {
    term: string;
    appliedTerm: string;
    resultsAreVisible: boolean;
    recentSearchTerms: Partial<{ [userId: string]: string[] }>;
    recentSearchResultsCache: CircularCache<CanonicalSearchResultBrand>;
    filteredResults: CanonicalSearchResultBrand[];
    attributionSearch: string;
}

export const initialSearchState: SearchState = {
    term: '',
    appliedTerm: '',
    resultsAreVisible: false,
    recentSearchTerms: {},
    recentSearchResultsCache: { internalCache: {}, internalCacheKeys: [] },
    filteredResults: [],
    attributionSearch: '',
};

const { reducer, configureAction, update } = createSlice(initialSearchState, 'MAIN_SEARCH');
export const searchReducer = reducer;
export const updateSearch = update;

export const updateSearchTerm = configureAction<string>(
    'UPDATE_TERM',
    (term) => (s) => ({ ...s, term }),
    { debounced: 300 }
);

export const displayResults = configureAction<boolean>(
    'DISPLAY_RESULTS',
    (resultsAreVisible) => (s) => ({ ...s, resultsAreVisible })
);

export const addRecentSearchTerm = configureAction<{ userId: string; searchTerm: string }>(
    'ADD_RECENT_SEARCH_TERM',
    ({ userId, searchTerm }) =>
        (s) => {
            if (searchTerm === '') {
                return s;
            }

            const usersSearchTerms = [
                searchTerm,
                ...removeFirst(s.recentSearchTerms[userId] || [], searchTerm),
            ].slice(0, RECENT_SEARCH_TERMS_MAX);

            return {
                ...s,
                recentSearchTerms: { ...s.recentSearchTerms, [userId]: usersSearchTerms },
            };
        }
);

export const applyTerm = configureAction<string>('APPLY_TERM', (appliedTerm) => (state) => {
    return {
        ...state,
        appliedTerm,
        ...(appliedTerm === '' ? { attributionSearch: '' } : {}),
    };
});

export const applyAttributionSearch = configureAction<string>(
    'APPLY_ATTRIBUTION_SEARCH',
    (attributionSearch) => (state) => {
        return {
            ...state,
            attributionSearch,
        };
    },
    { debounced: 300 }
);

const filterItems = (items: CanonicalSearchResultBrand[], filter: string) => {
    const lowerCaseFilter = trimAndLowerCase(filter);
    return items.filter((it) => trimAndLowerCase(it.Name).indexOf(lowerCaseFilter) > -1);
};

const addSearchResultsToCache = addItemsToCacheByKey<CanonicalSearchResultBrand>((it) => it.Id);

// adds the recent results to the cache and update the filteredResults
export const addRecentSearchResults = configureAction<{ results: CanonicalSearchResultBrand[] }>(
    'ADD_RECENT_RESULTS',
    ({ results }) =>
        (state: SearchState) => {
            const updatedSearchResultsCache = addSearchResultsToCache(
                state.recentSearchResultsCache,
                results
            );
            const updatedFilteredResults = filterItems(
                getItemsFromCache<CanonicalSearchResultBrand>(updatedSearchResultsCache),
                state.term
            );

            return {
                ...state,
                recentSearchResultsCache: updatedSearchResultsCache,
                filteredResults: updatedFilteredResults,
            };
        }
);

export const requestBrandsSearch = createApiThunk(getBrandsOnly, () => (state, result: any) => {
    if (!state.loading && result?.value) {
        return addRecentSearchResults({ results: result?.value });
    }
});

// selectors
export const selectSearchAppliedTerm = (s: FeaturesState) => s.store.search.appliedTerm;
