import React, { useEffect, useState, useMemo } from 'react';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import Skeleton from 'react-loading-skeleton';
import { Btn } from 'components/btn/Btn';
import { createStyles } from 'utils/createStyle';
import useMediaQueries from 'hooks/useMediaQueries';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import { BrandCardSlim } from 'components/brand-card/BrandCardSlim';
import { convertBrandToCardProps } from 'components/bonus/CardProps';
import { useFeaturedBrands } from './useFeaturedBrands';

const RenderFeaturedBrands = ({ cards }: { cards: Brand[] }) => {
    const desktopCardWidth = 180;
    const [cardWidth, setCardWidth] = useState<number>(desktopCardWidth);

    const cardProps = useMemo(() => {
        return cards.map((card) => convertBrandToCardProps(card, undefined, false));
    }, [cards]);

    const cardStyle = createStyles({
        cardContainer: [
            {
                width: `${cardWidth}px`,
            },
        ],
    });

    const styles = createStyles({
        container: [
            'grid justify-center gap-4',
            {
                '@media (min-width: 780px)': {
                    gridTemplateColumns: `repeat(6, ${cardWidth}px)`,
                },
                '@media (max-width: 1160px)': {
                    gridTemplateColumns: `repeat(4, ${cardWidth}px)`,
                },
                '@media (max-width: 840px)': {
                    gridTemplateColumns: `repeat(3, ${cardWidth}px)`,
                },
                '@media (max-width: 570px)': {
                    gridTemplateColumns: `repeat(2, ${cardWidth}px)`,
                },
            },
        ],
    });

    const queries = useMemo(
        () => ({
            '(max-width: 570px)': () => {
                setCardWidth(150);
            },
            '(min-width: 571px)': () => {
                setCardWidth(180);
            },
        }),
        []
    );

    useMediaQueries(queries);

    return (
        <div>
            <div className="flex justify-center">
                <div className={styles.container}>
                    {cardProps.map((card) => (
                        <BrandCardSlim key={card.id} styles={cardStyle} data={card} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const FeaturedSection = () => {
    const { featuredBrands, totalResults, loading, error } = useFeaturedBrands();

    if (loading) {
        return <Skeleton className="h-80" />;
    }

    // Do not render anything if there are no featuredBrands fetched OR if there's an error
    if ((error || featuredBrands.length === 0) && !loading) {
        return null;
    }

    return (
        <FeaturedSectionRenderer results={totalResults}>
            <RenderFeaturedBrands cards={featuredBrands} />
        </FeaturedSectionRenderer>
    );
};

interface FeaturedSectionRendererProps {
    results: number;
}

const Title = ({ children }: React.PropsWithChildren) => {
    return (
        <div className="mb-10">
            <p className="text-brand-dark text-2xl md:text-3xl font-semibold">{children}</p>
        </div>
    );
};

const FeaturedSectionRenderer = ({
    results,
    children,
}: React.PropsWithChildren<FeaturedSectionRendererProps>) => {
    const history = useHistory();

    const navToFeatures = () => {
        history.push(`${Routes.Featured}`);
    };

    return (
        <div className="shop_featured_brands w-full py-10 md:py-14 flex items-center flex-col">
            <Title>Featured Brands</Title>
            {children}
            <Btn onClick={navToFeatures} className="mt-10">
                Shop All {results}
            </Btn>
        </div>
    );
};
