import React, { useState } from 'react';

import { Question } from './Question';

import { OutlinedButton } from 'components/outlined-btn/OutlinedButton';

interface YesNoQuestionProps {
    question: React.ReactNode;
    setAnswer: (value: boolean | null) => void;
    className?: string;
}

export const YesNoQuestion = ({ className, question, setAnswer }: YesNoQuestionProps) => {
    const [activeAnswer, setActiveAnswer] = useState<string | null>(null);

    const yes = () => {
        setActiveAnswer('yes');
        setAnswer(true);
    };

    const no = () => {
        setActiveAnswer('no');
        setAnswer(false);
    };

    return (
        <Question className={className} question={question}>
            <div className="flex justify-evenly sm:justify-between mt-8 md:block">
                <OutlinedButton className="sm:m-1" active={activeAnswer === 'yes'} onClick={yes}>
                    Yes
                </OutlinedButton>
                <OutlinedButton className="sm:m-1" active={activeAnswer === 'no'} onClick={no}>
                    No
                </OutlinedButton>
            </div>
        </Question>
    );
};
