import { noop } from 'common/utils';
import React, { useCallback } from 'react';

export interface LinkBtnProps
    extends Omit<
        React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
        'href'
    > {
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export const LinkBtn = ({ onClick, children, ...props }: LinkBtnProps) => {
    const onInternalClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClick) {
                if (onClick !== noop) {
                    e.preventDefault();
                }

                onClick(e);
            }
        },
        [onClick]
    );

    return (
        <a {...props} className={`cursor-pointer ${props.className}`} onClick={onInternalClick}>
            {children}
        </a>
    );
};
