import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useConfirmUnload = (shouldPrompt: (nextLocation?: Location) => boolean) => {
    const history = useHistory();
    useEffect(() => {
        // @ts-ignore
        const unregister = history.block((location) => {
            if (shouldPrompt(location)) {
                return 'Are you sure you want to leave?';
            }
        });
        return () => unregister();
    }, [history, shouldPrompt]);

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (shouldPrompt()) {
                e.preventDefault();
                e.returnValue = ''; // Chrome requires returnValue to be set
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => window.removeEventListener('beforeunload', onBeforeUnload);
    }, [shouldPrompt]);
};
