import { StatusCode } from '../config';

export const parseResponse = async (response: Response) => {
    if (response.status === StatusCode.NoContent) {
        return null;
    }
    const contentType = response.headers.get('Content-Type');
    if (!contentType) {
        return null;
    }
    if (contentType.includes('application/json')) {
        return await response.json();
    } else if (contentType.includes('text/plain')) {
        return await response.text();
    }
    return null;
};
