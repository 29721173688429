import ReactCodeInput from 'components/code-input/index';

import { TextField, TextFieldSecured, NumberField, CheckboxField } from './TextField';
import { createFormikField } from './utils/createFormikField';

export const FormikTextField = createFormikField(TextField);
export const FormikNumberField = createFormikField(NumberField);
export const FormikCodeInputField = createFormikField(ReactCodeInput);
export const FormikTextFieldSecured = createFormikField(TextFieldSecured);
export const FormikCheckboxField = createFormikField(CheckboxField);
