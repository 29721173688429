import React, { useState, useCallback } from 'react';
import { EntryFields } from 'contentful';

import { TermsAndConditionsStyles } from './TermsAndConditions.style';

import { UserContent } from 'components/user-content/UserContent';
import { Modal, ModalFooter, ModalPrimaryBtn } from 'components/modal/Modal';
import { link } from 'styles/typography';

interface TermsAndConditionsProps {
    termsAndConditions?: EntryFields.RichText;
    supporterTermsOfUse?: EntryFields.RichText;
}

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
    const { termsAndConditions, supporterTermsOfUse } = props;
    const [modal, setModal] = useState<'terms' | 'supporter-terms' | null>(null);
    const closeModal = useCallback(() => setModal(null), []);

    return (
        <React.Fragment>
            <p className={TermsAndConditionsStyles.content}>
                By proceeding, I acknowledge I have read and agree to RaiseRight{' '}
                <button type="button" className={link} onClick={() => setModal('terms')}>
                    Terms of Use
                </button>{' '}
                and the{' '}
                <button type="button" className={link} onClick={() => setModal('supporter-terms')}>
                    Supporter Terms of Use
                </button>
            </p>

            <Modal title="RaiseRight Terms of Use" isOpen={modal === 'terms'} onClose={closeModal}>
                {termsAndConditions && (
                    <UserContent
                        className={TermsAndConditionsStyles.termsContent}
                        content={termsAndConditions}
                    />
                )}
                <ModalFooter>
                    <ModalPrimaryBtn onClick={closeModal}>Done</ModalPrimaryBtn>
                </ModalFooter>
            </Modal>

            <Modal
                title="RaiseRight Supporter Terms of Use"
                isOpen={modal === 'supporter-terms'}
                onClose={closeModal}
            >
                {supporterTermsOfUse && (
                    <UserContent
                        className={TermsAndConditionsStyles.termsContent}
                        content={supporterTermsOfUse}
                    />
                )}
                <ModalFooter>
                    <ModalPrimaryBtn onClick={closeModal}>Done</ModalPrimaryBtn>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
