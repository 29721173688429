import React, { Fragment, useCallback, useState } from 'react';
import { useContext, useMemo } from 'react';
import { Hero, HomepageContext } from '../../contexts/contentful';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { createStyles, createStyle } from 'utils/createStyle';
import classNames from 'classnames';
import { BLOCKS, Document, MARKS, Node } from '@contentful/rich-text-types';
import { getFlatValues, replacePlaceholderValues } from 'common/utils/strings';
import useMediaQueries from 'hooks/useMediaQueries';
import { useSelector } from 'react-redux';
import { selectHomeDetails } from 'common/features/store/duck/home/duck';
import { logClickAd, PromotionSources } from 'config/analytics';
import { CreativeSlotTypes } from 'common/types/CreativeSlotTypes';

const styles = createStyles({
    btnLight:
        'text-lg bg-brand-dark text-white rounded-full px-4 py-1 hover:bg-white hover:text-brand-dark focus:bg-white focus:text-brand-dark',
    btnDark:
        'text-lg border border-brand bg-white text-brand-dark rounded-full px-4 py-1 hover:bg-brand-dark hover:text-white focus:bg-brand-dark focus:text-white',
    h1: { fontSize: '30px' },
    h2: { fontSize: '25px' },
    h3: { fontSize: '22px' },
    h4: { fontSize: '20px' },
    h5: { fontSize: '18px' },
    h6: { fontSize: '16px' },
    list: [
        'ml-6 mb-6',
        {
            '& > li > p': {
                marginBottom: 0,
            },
        },
    ],
    blockquote: 'border-l-4 pl-2 border-grey-6',
});

export const HeroSectionTextBlock = ({
    id,
    creativeSlot,
}: {
    id: string;
    creativeSlot?: CreativeSlotTypes;
}) => {
    const { heroes } = useContext(HomepageContext);
    const hero = useMemo(() => heroes[id] as Hero, [heroes, id]);
    const heroColor = heroColors(hero);
    const homeDetails = useSelector(selectHomeDetails);
    const textStyles = useWithStyles(hero as Hero);

    const valuesToReplace = useMemo(() => {
        const userData: { [key: string]: unknown } = (homeDetails?.profile as {}) || {};
        const orgData: { [key: string]: unknown } =
            (homeDetails &&
                homeDetails.selectedOrgId &&
                (homeDetails?.organizations[homeDetails.selectedOrgId] as {})) ||
            {};

        return {
            ...getFlatValues(userData),
            ...getFlatValues(orgData),
        };
    }, [homeDetails]);

    const onClickAd = useCallback(() => {
        logClickAd({
            promotionName: hero.title || hero.id,
            index: 0,
            source: PromotionSources.Contentful,
            creativeSlot: creativeSlot as string,
        });
    }, [hero, creativeSlot]);

    const headerBlockClass = useMemo(() => {
        let baseTextBlockContainerClasses = '';
        let baseTextBlockContainerStyles = {};
        if (hero.heroLayout === 'CenteredHeadline') {
            baseTextBlockContainerClasses = 'mx-auto text-center';
            baseTextBlockContainerStyles = { maxHeight: '380px' };
        } else if (hero.heroLayout === 'ImageLeft') {
            baseTextBlockContainerClasses = 'ml-auto text-left md:pr-16';
            baseTextBlockContainerStyles = { maxHeight: '540px' };
        } else {
            baseTextBlockContainerClasses = 'mr-auto text-left md:pl-16';
            baseTextBlockContainerStyles = { maxHeight: '540px' };
        }

        return createStyle(baseTextBlockContainerClasses, baseTextBlockContainerStyles);
    }, [hero]);

    const buttonContainerClass = useMemo(() => {
        let buttonContainerStyles = {};
        if (hero.heroLayout === 'CenteredHeadline') {
            buttonContainerStyles = 'flex justify-center';
        } else {
            buttonContainerStyles = 'flex';
        }

        return createStyle(buttonContainerStyles);
    }, [hero]);

    return (
        <div
            className={classNames(
                headerBlockClass,
                'w-full flex-1 px-6 md:px-0 pb-4 pt-4 lg:pt-10'
            )}
        >
            <div className={classNames(textStyles.headerText, heroColor?.header)}>
                {replacePlaceholderValues(hero.headerText || '', valuesToReplace)}
            </div>
            <div className={textStyles.bodyText}>
                {hero.bodyText && renderContent(hero.bodyText, valuesToReplace)}
            </div>
            <div className={buttonContainerClass}>
                {hero.buttonUrl && (
                    <button
                        className={`mx-auto md:m-0 ${
                            heroColor?.button === 'light' ? styles.btnLight : styles.btnDark
                        }`}
                        onClick={() => {
                            if (hero.buttonUrl) {
                                onClickAd();
                                window.location.href = hero.buttonUrl;
                            }
                        }}
                    >
                        {hero.buttonText}
                    </button>
                )}
            </div>
        </div>
    );
};

const renderChildren = (nodes: Node[], placeholderValues: { [key: string]: string } = {}) =>
    nodes.map((node, i) => <Fragment key={i}>{renderContent(node, placeholderValues)}</Fragment>);

export const renderContent = (
    textContent: Node,
    placeholderValues: { [key: string]: string } = {}
) => {
    const options: Options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node) => {
                return (
                    <div className={styles.h1}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HEADING_2]: (node) => {
                return (
                    <div className={styles.h2}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HEADING_3]: (node) => {
                return (
                    <div className={styles.h3}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HEADING_4]: (node) => {
                return (
                    <div className={styles.h4}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HEADING_5]: (node) => {
                return (
                    <div className={styles.h5}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HEADING_6]: (node) => {
                return (
                    <div className={styles.h6}>
                        {renderChildren(node.content, placeholderValues)}
                    </div>
                );
            },
            [BLOCKS.HR]: () => {
                return <hr />;
            },
            [BLOCKS.LIST_ITEM]: (node) => {
                return <li>{renderChildren(node.content, placeholderValues)}</li>;
            },
            [BLOCKS.OL_LIST]: (node) => {
                return (
                    <ol className={classNames('list-decimal', styles.list)}>
                        {renderChildren(node.content, placeholderValues)}
                    </ol>
                );
            },
            [BLOCKS.UL_LIST]: (node) => {
                return (
                    <ul className={classNames('list-disc', styles.list)}>
                        {renderChildren(node.content, placeholderValues)}
                    </ul>
                );
            },
            [BLOCKS.PARAGRAPH]: (node) => {
                return <p className="mb-4">{renderChildren(node.content, placeholderValues)}</p>;
            },
            [BLOCKS.QUOTE]: (node) => {
                return (
                    <blockquote className={styles.blockquote}>
                        {renderChildren(node.content, placeholderValues)}
                    </blockquote>
                );
            },
        },
        renderText: (text) =>
            text ? (
                <span>
                    {replacePlaceholderValues(text, placeholderValues) || <span>&nbsp;</span>}
                </span>
            ) : null, // adding a space to avoid ignoring the css margin
        renderMark: {
            [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
            [MARKS.ITALIC]: (text) => <span className="font-italic">{text}</span>,
            [MARKS.UNDERLINE]: (text) => <span className="font-underline">{text}</span>,
            [MARKS.CODE]: (text) => <code>{text}</code>,
        },
    };

    return documentToReactComponents(textContent as Document, options);
};

export const heroColors = (hero: Hero) => {
    if (hero.heroVisualTheme) {
        const key = (hero.heroVisualTheme ?? '').toString().replace(/-/gi, '');
        return heroColorSchemes[key];
    }
};

export const heroColorSchemes: {
    [key: string]: { background: string; header: string; text: string; button?: string };
} = {
    ActionBlue: { background: '#3EB5E5', header: '', text: 'text-dark-blue' },
    DarkBlue: { background: '#143860', header: '', text: 'text-white' },
    ImpactOrange: { background: '#F15A29', header: '', text: 'text-white' },
    OpportunityPink: { background: '#E6126A', header: '', text: 'text-white' },
    PaleBlue: { background: '#E1F1F7', header: '', text: 'text-dark-blue', button: 'light' },
    PotentialPurple: { background: '#7E3F97', header: '', text: 'text-white' },
    PrimaryBlue: { background: '#005E9D', header: '', text: 'text-white' },
    PurplePrimaryBlue: { background: '#7E3F97', header: '', text: 'text-white' }, // ??
    WhiteDarkBlueHeader: {
        background: '',
        header: 'text-brand-dark',
        text: 'text-grey-1',
        button: 'light',
    },
    WhitePinkHeader: {
        background: '',
        header: 'text-brand-accent',
        text: 'text-grey-1',
        button: 'light',
    },
    PrimaryBlueActionBlueGradient: {
        background: 'linear-gradient(180deg, #3EB5E5 0%, #005E9D 100%)',
        header: '',
        text: 'text-white',
    },
    DarkBluePrimaryBlueGradient: {
        background: 'linear-gradient(180deg, #005E9D 0%, #143860 100%)',
        header: '',
        text: 'text-white',
    },
    OpportunityPinkImpactOrangeGradient: {
        background: 'linear-gradient(180deg, #F15A29 0%, #E6126A 100%)',
        header: '',
        text: 'text-white',
    },
    PotentialPurpleOpportunityPinkGradient: {
        background: 'linear-gradient(180deg, #E6126A 0%, #7E3F97 100%)',
        header: '',
        text: 'text-white',
    },
    PotentialPurplePrimaryBlueGradient: {
        background: 'linear-gradient(180deg, #005E9D 0%, #7E3F97 100%)',
        header: '',
        text: 'text-white',
    },
};

interface IStyles {
    headerText: { [key: string]: string | number };
    bodyText: { [key: string]: string | number };
}

const defaultStyles = {
    mobile: {
        headerText: {
            fontSize: 28,
            marginBottom: 30,
        },
        bodyText: {
            fontSize: 16,
            marginBottom: '2rem',
        },
    },
    desktop: {
        headerText: {
            fontSize: 42,
            marginBottom: 30,
        },
        bodyText: {
            fontSize: 22,
            marginBottom: '2rem',
        },
    },
};

const useWithStyles = (hero: Hero) => {
    const [styles_, setStylesTo] = useState<IStyles>(defaultStyles.desktop);

    const queries = useMemo(
        () => ({
            '(max-width: 640px)': () => {
                setStylesTo(defaultStyles.mobile);
            },
            '(min-width: 641px)': () => {
                setStylesTo(defaultStyles.desktop);
            },
        }),
        []
    );

    useMediaQueries(queries);

    const styles = createStyles({
        headerText: ['flex-1 font-semibold', styles_.headerText],
        bodyText: [
            `w-fit ${hero.heroLayout === 'CenteredHeadline' ? 'mx-auto' : ''} max-w-screen-md`,
            styles_.bodyText,
        ],
    });

    return styles;
};
