import React from 'react';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectCurrentStep,
    selectShowAccountLoader,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useEnrollmentV2Steps } from 'features/coordinator/enrollment-flow/v2/EnrollStepWrapperV2';
import { AccordionForm } from '../accordion-form';
import { FadeIn } from 'components/loading-screen/FadeIn';
import { LoadingStep } from '../steps/loading-step/LoadingStep';

export const OrganizationScreen = () => {
    const currentStep = useRootSelector(selectCurrentStep);
    const showLoaderScreen = useRootSelector(selectShowAccountLoader);

    const stepsV2 = useEnrollmentV2Steps();

    return (
        <div className="w-full sm:w-max">
            <FadeIn when={showLoaderScreen}>
                <LoadingStep />
            </FadeIn>
            <FadeIn when={!showLoaderScreen}>
                {currentStep < 3 && <AccordionForm items={stepsV2.slice(0, 3)} />}
            </FadeIn>
        </div>
    );
};
