import { BrandSearchResponse } from './models/BrandSearchResponse';
import { toSearchResult } from './utils/toSearchResult';
import { defaultBrandSearchOptions } from './config';

import { searchApiFetch, SearchApiQueryParams } from './';

export const getBrands = async (options: Partial<SearchApiQueryParams> = {}) =>
    toSearchResult(
        await searchApiFetch<BrandSearchResponse>({
            ...defaultBrandSearchOptions,
            top: 1000,
            ...options,
        })
    );

export const getBrandsOnly = async (options: Partial<SearchApiQueryParams> = {}) =>
    await searchApiFetch<BrandSearchResponse>({
        ...defaultBrandSearchOptions,
        top: 1000,
        ...options,
    });
