import React, { useCallback } from 'react';
import { Field, FieldProps, FieldValidator } from 'formik';

import { FieldWrapperProps } from '../FieldWrapper';

import { noop } from 'common/utils';
import { getError } from 'common/modules/form-validation/utils';

export type FormikFieldProps<T> = FieldWrapperProps & {
    name: keyof T;
    validate?: FieldValidator;
};

type BaseProps = FieldWrapperProps & React.HTMLAttributes<HTMLElement>;

export const createFormikField = <P extends BaseProps>(Component: React.ComponentType<P>) => {
    const FormikField = (props: P & FieldProps & FormikFieldProps<unknown>) => {
        const { field, onBlur = noop, onChange = noop, ...inputProps } = props;
        const error = getError(props);

        const { onChange: fieldOnChange, onBlur: fieldOnBlur } = field;

        const _onChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e);
                fieldOnChange(e);
            },
            [onChange, fieldOnChange]
        );

        const _onBlur = useCallback(
            (e: React.FocusEvent<HTMLInputElement>) => {
                onBlur(e);
                fieldOnBlur(e);
            },
            [onBlur, fieldOnBlur]
        );

        // Workaround for technically correct but ignorable typescript error
        const _Component = Component as unknown as React.ComponentType<BaseProps>;

        return (
            <_Component
                {...field}
                {...inputProps}
                onChange={_onChange}
                onBlur={_onBlur}
                error={error}
            />
        );
    };

    return function GeneratedFormikField<T>(
        props: React.PropsWithChildren<FormikFieldProps<T> & P>
    ) {
        return <Field {...props} component={FormikField} />;
    };
};
