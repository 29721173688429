import React from 'react';

import { Btn } from 'components/btn/Btn';
import { h1 } from 'styles/typography';

export const ErrorFallback = () => {
    return (
        <div className="max-w-2xl mx-auto px-4 sm:px-10 mt-8 text-center">
            <div className={`${h1} mb-4`}>Oops!</div>
            <div>Something went wrong...</div>
            <div className="mb-4">We've reported the issue. Please try again.</div>
            <Btn onClick={() => window.location.reload()}>Reload</Btn>
        </div>
    );
};
