import { useState, useCallback, useMemo } from 'react';

import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import { APIResponse } from 'common/api/models';
import { SearchResult } from 'common/api/search/models/SearchResult';
import { useFavorites } from 'common/hooks/useFavorites';
import { SearchApiQueryParams } from 'common/api/search';
import { and } from 'common/utils/searchFilters';
import { useGlobalBrandFilters } from 'common/hooks/useGlobalBrandFilters';

type BrandListMapper<T> = (result: SearchResult) => T;
type SearchApi = (options: Partial<SearchApiQueryParams>) => Promise<APIResponse<SearchResult>>;

export function useBrandList<T = Brand>(mapper: BrandListMapper<T>, initialData: T | null = null) {
    useFavorites();
    const globalFilter = useGlobalBrandFilters();
    const [data, setData] = useState<T | null>(initialData);
    const _options = useMemo(() => ({ filter: globalFilter }), [globalFilter]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadData = useCallback(
        (api: SearchApi) => {
            return async (options: Partial<SearchApiQueryParams>) => {
                const combined = {
                    ...options,
                    filter:
                        options.filter && _options.filter
                            ? and(options.filter, _options.filter)
                            : _options.filter || options.filter,
                };

                setIsLoading(true);

                const response = await api(combined);

                if (response.error) {
                    return response;
                }

                setData(mapper(response.data));

                setIsLoading(false);
                return response;
            };
        },
        [mapper, _options]
    );

    return {
        data,
        loadData,
        isLoading,
    };
}
