import { useEffect, useState } from 'react';

import { contentfulApi } from 'common/api/utils/contentful';
import { ContentfulGlobal } from 'common/api/contentful/models/ContentfulGlobal';

const getContentfulGlobal = async (): Promise<ContentfulGlobal | null> => {
    // eslint-disable-next-line camelcase
    const entries = await contentfulApi.getEntries<ContentfulGlobal, { content_type: string }>({
        content_type: 'globalContent', // eslint-disable-line camelcase
    });

    if (!entries.data || !entries.data.items.length) {
        return null;
    }

    return entries.data.items[0].fields;
};

export const useContentfulGlobalContent = () => {
    const [globalContent, setGlobalContent] = useState<ContentfulGlobal | null>(null);
    useEffect(() => {
        const fetch = async () => {
            const response = await getContentfulGlobal();

            if (response) {
                setGlobalContent(response);
            }
        };

        fetch();
    }, []);

    return globalContent;
};
