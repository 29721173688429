import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-redux';

import {
    OrganizationEnrollmentProgress,
    getEnrollmentProgressFunction,
} from '../components/useContinueOrgEnrollment';

import { emptyOrganization, Organization, PlanType } from 'common/api/crm/models/Organization';
import {
    emptyUnmaskedPhoneNumber,
    UnmaskedPhoneNumber,
} from 'common/api/users/models/UnmaskedPhoneNumber';
import {
    emptyRegistrationRequestWithoutOrg,
    RegistrationRequestWithoutOrg,
} from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { noop } from 'common/utils';
import { getPath, Routes } from 'routes';
import { selectUserId } from 'common/features/store/duck/account/duck';
import { addOrgEnrollmentReferralInfo } from 'common/api/crm/family/service';
import { getCookieAsObject } from 'utils/cookies';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { ENROLLMENT_VERSION_1 } from 'features/coordinator/constants';
import { FeaturesState } from 'common/features/featuresReducer';

interface EnrollState {
    organization: Organization;
    twoFactorPhone: UnmaskedPhoneNumber;
    userInformation: RegistrationRequestWithoutOrg;
    setUserInformation: (value: RegistrationRequestWithoutOrg) => void;
    setOrganization: (value: Organization) => void;
    setTwoFactorPhone: (value: UnmaskedPhoneNumber) => void;
    goToNextStep: (plan?: PlanType) => void;
    onTryRequestingNewOne: () => void;
    onProgressLoaded: (progress: OrganizationEnrollmentProgress) => void;
    onLoginSuccess: (progress: OrganizationEnrollmentProgress) => void;
    goToCompletedEnrollmentStep: () => void;
}

const initialState: EnrollState = {
    organization: emptyOrganization,
    twoFactorPhone: emptyUnmaskedPhoneNumber,
    userInformation: emptyRegistrationRequestWithoutOrg,
    setUserInformation: noop,
    setOrganization: noop,
    setTwoFactorPhone: noop,
    goToNextStep: noop,
    onTryRequestingNewOne: noop,
    onProgressLoaded: noop,
    onLoginSuccess: noop,
    goToCompletedEnrollmentStep: noop,
};

export const EnrollmentContext = React.createContext<EnrollState>(initialState);

const transitions: { [key: string]: string | Function } = {
    [Routes.EnrollStartProgram]: Routes.EnrollCreateAccount,
    [Routes.EnrollCreateAccount]: Routes.EnrollSetupRecoveryPhone,
    [Routes.EnrollSetupRecoveryPhone]: Routes.EnrollVerifyRecoveryPhone,
    [Routes.EnrollVerifyRecoveryPhone]: Routes.EnrollUserCreateLoadingPage,
    [Routes.EnrollUserCreateLoadingPage]: Routes.EnrollOrgName,
    [Routes.EnrollOrgName]: Routes.EnrollOrgType,
    [Routes.EnrollOrgType]: Routes.EnrollOrgQuestion1,
    [Routes.EnrollOrgQuestion1]: Routes.EnrollOrgPeople,
    [Routes.EnrollOrgPeople]: Routes.EnrollOrgQuestion2,
    [Routes.EnrollOrgQuestion2]: Routes.EnrollOrgFundraisingReason,
    [Routes.EnrollOrgFundraisingReason]: Routes.EnrollOrgProfile,
    [Routes.EnrollOrgProfile]: Routes.EnrollOrgCreateLoadingPage,
    [Routes.EnrollOrgCreateLoadingPage]: Routes.EnrollChooseYourPlan,
    [Routes.EnrollChooseYourPlan]: (plan: PlanType) => {
        if (plan === PlanType.Lite) {
            return Routes.EnrollChooseEarningTypeLite;
        }
        return Routes.EnrollChooseEarningTypePlus;
    },
};

const useCreateYourAccount = () => {
    const [userInformation, setUserInformation] = useState<RegistrationRequestWithoutOrg>(
        emptyRegistrationRequestWithoutOrg
    );
    return {
        userInformation,
        setUserInformation,
    };
};

const useTwoFactor = () => {
    const history = useHistory();
    const [twoFactorPhone, setTwoFactorPhone] =
        useState<UnmaskedPhoneNumber>(emptyUnmaskedPhoneNumber);

    const onTryRequestingNewOne = () => {
        history.replace(Routes.EnrollSetupRecoveryPhone);
    };

    return {
        twoFactorPhone,
        setTwoFactorPhone,
        onTryRequestingNewOne,
    };
};

const useOrganizationSteps = () => {
    const [organization, setOrganization] = useState<Organization>(emptyOrganization);
    return {
        organization,
        setOrganization,
    };
};

export const EnrollmentFlow = ({ children }: { children: React.ReactNode }) => {
    const userInfo = useCreateYourAccount();
    const twoFactor = useTwoFactor();
    const org = useOrganizationSteps();

    const history = useHistory();
    const store = useStore();
    const {
        location: { search },
    } = history;

    const goToNextStep = useCallback(
        (value?: PlanType) => {
            const currentPath = history.location.pathname;

            const nextStep = transitions[currentPath];
            let nextRoute = nextStep; // assume it's a route

            if (typeof nextStep === 'function') {
                nextRoute = nextStep(value as PlanType);
            }

            history.replace(nextRoute as string);
        },
        [history]
    );

    const goToCompletedEnrollmentStep = useCallback(() => {
        history.replace(getPath(Routes.EnrollCompleted, {}));
    }, [history]);

    // used when user presses the `Continue Enrollment` button OR
    // when user tries to signup with an existing email and gets prompted to log-in
    // on a successful login then this gets called to setup the context
    const onProgressLoaded = useCallback(
        async (progress: OrganizationEnrollmentProgress) => {
            const { pendingOrg } = progress;
            let { route } = progress;

            if (pendingOrg) {
                const organizationTypeId = pendingOrg.organizationType
                    ? String(pendingOrg.organizationType.id)
                    : '';
                org.setOrganization({
                    ...pendingOrg,
                    organizationTypeId,
                });
            }

            // User chooses to create an org provided it has a VERIFIED ORG
            if (!progress.hasExistingEnrollment) {
                const userId = selectUserId(store.getState() as FeaturesState);
                addOrgEnrollmentReferralInfo(userId, {
                    trackingCookie: getCookieAsObject(document.cookie),
                });

                await updateEnrollmentProgress({
                    state: ProgressState.EnrollmentStartedExistingAccount,
                    version: 2,
                });

                const updatedProgress = await getEnrollmentProgressFunction();
                route = updatedProgress.route;
            }
            history.replace(route + search);
        },
        [history, store, search, org]
    );

    const onLoginSuccess = useCallback(
        async ({ version }: OrganizationEnrollmentProgress) => {
            history.replace(
                version === ENROLLMENT_VERSION_1
                    ? Routes.StartProgramContinue
                    : Routes.EnrollContinue
            );
        },
        [history]
    );

    // setup the values going into
    const values = {
        ...userInfo,
        ...twoFactor,
        ...org,
        goToNextStep,
        onProgressLoaded,
        goToCompletedEnrollmentStep,
        onLoginSuccess,
    };

    return <EnrollmentContext.Provider value={values}>{children}</EnrollmentContext.Provider>;
};
