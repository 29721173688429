import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Value } from 'firebase/remote-config';
import { Entry } from 'contentful';
import Skeleton from 'react-loading-skeleton';
import { Formik, Form } from 'formik';
import MaskedInput from 'react-text-mask';

import { StepWrapperProps, StepWrapper } from '../../enrollment/steps/StepWrapper';
import { EnrollmentContext } from '../context';

import { Btn } from 'components/btn/Btn';
import { orgAddressLine1Validator } from 'features/coordinator/enrollment/steps/validators';
import { RegistrationRequestWithoutOrg } from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { Organization } from 'common/api/crm/models/Organization';
import { useFirebaseConfigValues } from 'hooks/useFirebaseConfigValues';
import { createStyles } from 'utils/createStyle';
import EmptyMediaType from 'media/images/disabledheader.png';
import { contentfulApi } from 'common/api/utils/contentful';
import { OrganizationDefaultContent } from 'common/api/contentful/models/OrganizationDefaultContent';
import { APIResponse } from 'common/api/models';
import { useHomeDetails } from 'common/features/store/duck/home/utils/useHomeDetails';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import {
    cityValidator,
    addressLine2Validator,
    stateValidator,
    zipCodeValidator,
    phoneNumberValidator,
} from 'common/modules/form-validation/validators';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { FormikTextField, FormikNumberField } from 'components/fields/FormikTextField';
import { FormikSelectField } from 'features/coordinator/enroll/components/FormikSelectField';
import { stateOptions } from 'common/modules/form-validation/utils/stateOptions';
import { ProgressIndicator } from 'features/coordinator/components/ProgressIndicator';
import { OrganizationWebsiteField } from 'features/coordinator/components/OrganizationWebsiteField';

interface BaseSummarySection {
    user: RegistrationRequestWithoutOrg;
    organization: Organization;
}

/* eslint-disable camelcase */
interface OrgType {
    organization_type_id: number;
    organization_type_name: string;
    contentful_entry_id: string;
}

const emptyOrgType = {
    organization_type_id: -1,
    organization_type_name: '',
    contentful_entry_id: '',
};

const styles = createStyles({
    emptyMediaBox: ['w-full', { height: 150 }],
    orgMediaBox: ['w-full overflow-hidden bg-pale-blue rounded-lg', { height: 150 }],
});

interface SummarySectionWithOrgTypeProps extends BaseSummarySection {
    orgType: OrgType;
    image?: string;
}

const OrganizationTypeImage = (props: SummarySectionWithOrgTypeProps) => {
    // if no orgtype was found then just render the disabled header image
    // OR
    // if image from contentful was unable to get loaded then render the disabled header
    if (!props.orgType || (!props.image && props.orgType)) {
        return <img className={styles.emptyMediaBox} src={EmptyMediaType} />;
    }

    return (
        <div className={'w-full overflow-hidden bg-pale-blue rounded-lg h-44 md:h-40'}>
            <img className="m-auto" src={props.image} />
        </div>
    );
};

const UserAndOrgInformation = (props: SummarySectionWithOrgTypeProps) => {
    return (
        <div className="w-full mt-2 md:mt-0 md:px-4 text-left">
            <p className="text-brand-dark font-semibold text-xl py-1">
                {props.organization.name} My Organization Name
            </p>
            <p className="text-xs text-grey-3">Coordinator</p>
            <p className="text-brand-dark text-sm py-1">
                {props.user.firstName} {props.user.lastName}
            </p>
            {props.orgType.organization_type_id !== -1 && (
                <>
                    <p className="text-xs text-grey-3">Organization Type</p>
                    <p className="text-brand-dark text-sm py-1">
                        {props.orgType.organization_type_name}
                    </p>
                </>
            )}
        </div>
    );
};

const SummarySection = (props: BaseSummarySection) => {
    const { organizationTypeId } = props.organization;

    // Typecast `organizationTypeId` to integer in order to cross reference
    // the firebase org_type_contentful_mapping which holds a mapping between
    // `organizationTypeId` => contentful-entry-id
    const orgTypeId = useMemo(() => parseInt(organizationTypeId || '-1', 10), [organizationTypeId]);
    const transformF = useCallback(
        (FBValue: Value) => {
            const stringifiedValue = FBValue.asString();
            const orgTypes = JSON.parse(stringifiedValue) as OrgType[];
            const foundOrgType = orgTypes.find(
                ({ organization_type_id }: OrgType) => organization_type_id === orgTypeId
            );

            return foundOrgType || emptyOrgType;
        },
        [orgTypeId]
    );
    const orgType = useFirebaseConfigValues<OrgType>(
        'org_type_contentful_mapping',
        emptyOrgType,
        transformF
    );
    const contentfulEntryId = useMemo(() => {
        return orgType?.contentful_entry_id;
    }, [orgType]);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        // Only load the image header IFF organizationTypeId is defined
        // Only allow for 1 contentful API call to go through
        if (contentfulEntryId && !image) {
            contentfulApi
                .getEntry<OrganizationDefaultContent>(contentfulEntryId)
                .then((results: APIResponse<Entry<OrganizationDefaultContent>>) => {
                    const value = results.data?.fields?.splashLoadingImage?.fields?.file?.url ?? '';
                    setImage(value);
                });
        }
    }, [contentfulEntryId, image]);

    if (!orgType) {
        return <Skeleton className="h-72 md:h-44" />;
    }

    return (
        <div className="my-7 flex flex-col md:flex-row">
            <OrganizationTypeImage image={image} orgType={orgType as OrgType} {...props} />
            <UserAndOrgInformation orgType={orgType as OrgType} {...props} />
        </div>
    );
};

interface OrganizationWithState extends Organization {
    state: string;
}

const OrgAddressForm = () => {
    const { organization, setOrganization, goToNextStep } = useContext(EnrollmentContext);
    const _onSubmit = (values: OrganizationWithState) => {
        const { state, ...orgValues } = values;

        setOrganization({ ...orgValues, address: { ...orgValues.address, state } });
        goToNextStep();
    };
    const states = useMemo(() => {
        return stateOptions.map(({ label, value }) => ({ id: value, text: label }));
    }, []);
    const initialFormValues = useMemo(() => ({ ...organization, state: '' }), [organization]);

    return (
        <div>
            <Formik initialValues={initialFormValues} onSubmit={_onSubmit}>
                {() => (
                    <Form>
                        <p className="text-brand-dark font-semibold text-xl py-1">Address</p>
                        <div className="sm:flex">
                            <div className="flex-1">
                                <FormikTextField
                                    label={<span className="block">Organization Address</span>}
                                    name="address.address1"
                                    validate={orgAddressLine1Validator}
                                />
                                <FieldSpacer />
                            </div>
                            <div className="hidden sm:block w-4" />
                            <div className="w-full sm:flex-0 sm:w-1/2">
                                <FormikTextField
                                    label={
                                        <span aria-label="Suite/Apartment/Attention">
                                            Ste/Apt/Attn
                                        </span>
                                    }
                                    name="address.address2"
                                    validate={addressLine2Validator}
                                />
                                <FieldSpacer />
                            </div>
                        </div>
                        <FormikTextField
                            label="City"
                            name="address.city"
                            validate={cityValidator}
                        />
                        <FieldSpacer />
                        <div className="flex flex-col sm:flex-row">
                            <div className="flex-1">
                                <FormikSelectField<OrganizationWithState>
                                    label="State"
                                    name="state"
                                    validate={stateValidator}
                                    placeholder=""
                                    items={states}
                                />
                                <FieldSpacer />
                            </div>
                            <div className="w-4" />
                            <div className="flex-1">
                                <FormikNumberField
                                    label="Zip"
                                    name="address.zipCode"
                                    validate={zipCodeValidator}
                                    maxLength={5}
                                    acceptOnlyIntegerNumbers={false}
                                />
                                <FieldSpacer />
                            </div>
                        </div>
                        <p className="text-brand-dark font-semibold text-xl py-1">
                            Contact Information
                        </p>
                        <div className="flex flex-col sm:flex-row">
                            <div className="w-full sm:w-2/5">
                                <MaskedInput
                                    mask={MASK_PHONE_US}
                                    guide={false}
                                    render={(ref, _props) => (
                                        <FormikTextField
                                            {..._props}
                                            inputRef={ref}
                                            type="tel"
                                            name="phoneNumber"
                                            label={
                                                <span className="block">
                                                    Organization Phone Number
                                                </span>
                                            }
                                            validate={phoneNumberValidator}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-4 mb-7" />
                            <div className="flex-1 w-full">
                                <OrganizationWebsiteField label="Website/Social Media" />
                            </div>
                        </div>
                        <FieldSpacer />
                        <div className="text-center">
                            <Btn type="submit">Show Me My Program</Btn>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

interface OrgProfileQuestion7Props extends StepWrapperProps {}

export const OrgProfileQuestion7 = (props: OrgProfileQuestion7Props) => {
    const { userInformation, organization } = useContext(EnrollmentContext);

    // In the case where user refreshed page and is __continuing__ their org enrollment
    // As a result, userInformation will be cleared out
    const user = useHomeDetails();
    const firstName = (userInformation.firstName || user?.profile.firstName) as string;
    const lastName = (userInformation.lastName || user?.profile.lastName) as string;

    // focus first form field
    useEffect(() => document.querySelector('input')?.focus(), []);

    return (
        <StepWrapper subheadingData={{ firstName }} {...props}>
            <SummarySection
                user={{ ...userInformation, firstName, lastName }}
                organization={organization}
            />
            <OrgAddressForm />
            <div className="w-48 m-auto mt-8">
                <ProgressIndicator currentStep={5} totalSteps={5} />
            </div>
        </StepWrapper>
    );
};
