import React from 'react';
import { Switch } from 'components/switch/Switch';
import { createStyles } from 'utils/createStyle';

interface BonusToggleProps {
    toggleFunc: (checked: boolean) => void;
    currentBonusState: boolean;
}

export const BonusToggle = ({ currentBonusState, toggleFunc }: BonusToggleProps) => {
    const styles = createStyles({
        bonusToggle: [
            'flex text-grey-2 sm:mx-0 sm:mt-0 mb-4 items-center',
            {
                justifyContent: 'center',
                lineHeight: '22px',
                fontSize: '14px',
                fontWeight: 400,
            },
        ],
    });
    return (
        <div className={`brands_bonus_toggle ${styles.bonusToggle}`}>
            <Switch checked={currentBonusState} onChange={toggleFunc} /> View On Bonus Only
        </div>
    );
};
