import React from 'react';

interface NumberedStepProps {
    number: number;
    children: React.ReactNode;
}

export const NumberedStep = ({ number, children }: NumberedStepProps) => {
    return (
        <li className="flex flex-row items-start mb-5">
            <span className="pr-8 sm:pr-16 text-primary-blue-light font-semibold text-3xl">
                {number}
            </span>
            <div className="text-grey-1 text-lg">{children}</div>
        </li>
    );
};
