import { createStyles } from 'utils/createStyle';

export const StepperStyles = createStyles({
    wrapper: 'flex items-center max-w-2xl mx-auto pt-6 sm:pt-6 px-4 sm:px-8 pb-2',
    separator: 'hidden sm:flex flex-auto sm:-mb-2 mt-0 sm:mx-1 border-t-2 border-grey-5',
    stepWrapper: 'flex items-center flex-row flex-1 sm:flex-col text-brand-dark w-16 outline-none',
    iconWrapper:
        'flex items-center flex-col h-16 w-16 p-2 mt-0 sm:h-16 sm:p-0 sm:w-16 sm:pt-5 sm:-mb-1',
    iconSelected:
        'transition-transform transform duration-200 scale-150 sm:scale-100 -mb-5 sm:mb-0',
    stepMobileHeader: 'block text-xs font-normal text-grey-2 sm:sr-only',
    stepTitle:
        'text-md font-semibold text-brand-dark sm:font-normal sm:text-center sm:text-sm sm:text-grey-2 sm:-mt-1',
    stepNext: 'text-sm font-light text-brand-dark sm:sr-only',
    hiddenMobile: 'sr-only sm:not-sr-only',
});
