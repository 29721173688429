import { createSlice } from 'common/modules/create-slice';
import { FeaturesState } from 'common/features/featuresReducer';
import { Product } from 'common/api/e-comm/models/Product';

export type RemoteActivationState = {
    activationCardType: 'InstantActivate' | 'Reload' | null; // more types?
    selectedAmount: number;
    showBuyNowWithRemoteActivation: boolean;
    useRemoteActivationWorkflow: boolean;
    activationCardNumber: string | null;
    product: Product | null;
    isCardReloadable: boolean | null;
};

const initialState: RemoteActivationState = {
    activationCardType: null,
    selectedAmount: 0,
    showBuyNowWithRemoteActivation: false,
    useRemoteActivationWorkflow: false,
    activationCardNumber: '',
    product: null,
    isCardReloadable: null,
};

const slice = createSlice(initialState, 'UI/RemoteActivation');

export const remoteActivationFlow = {
    ...slice,
    setRemoteActivationWorkflow: slice.configureAction(
        'SetRemoteActivationWorkflow',
        (value: boolean) => (state) => {
            return { ...state, useRemoteActivationWorkflow: value };
        }
    ),
    setActivationCardType: slice.configureAction(
        'SetActivationCardType',
        (cardType: 'InstantActivate' | 'Reload' | null) => (state) => {
            return { ...state, activationCardType: cardType };
        }
    ),
    setCardIsEligible: slice.configureAction(
        'SetCardIsEligible',
        (isEligible: boolean) => (state) => {
            return { ...state, cardIsEligible: isEligible };
        }
    ),
    setCardDetails: slice.configureAction('SetCardDetails', (details: any) => (state) => {
        return { ...state, cardDetails: details };
    }),
    setBuyNowWithRemoteActivation: slice.configureAction(
        'SetBuyNowWithRemoteActivation',
        (value: boolean) => (state) => {
            return { ...state, showBuyNowWithRemoteActivation: value };
        }
    ),
    setActivationNumber: slice.configureAction(
        'SetActivationNumber',
        (value: RemoteActivationState['activationCardNumber']) => (state) => {
            return { ...state, activationCardNumber: value };
        }
    ),
    selectSelectedAmount: slice.configureAction(
        'SelectSelectedAmount',
        (value: number) => (state) => {
            return { ...state, selectedAmount: value };
        }
    ),
    resetState: slice.configureAction('ResetState', () => () => initialState),
    addAnotherCard: slice.configureAction('AddAnotherCard', () => () => {
        return { ...initialState, useRemoteActivationWorkflow: true };
    }),
};
export default remoteActivationFlow.reducer;

// Selectors
export const selectUseRemoteActivationWorkflow = (s: FeaturesState) =>
    s.store.ui.remoteActivation.useRemoteActivationWorkflow;

export const selectActivationCardType = (s: FeaturesState) =>
    s.store.ui.remoteActivation.activationCardType;

export const selectShowBuyNowWithRemoteActivation = (s: FeaturesState) =>
    s.store.ui.remoteActivation.showBuyNowWithRemoteActivation;

export const selectSelectedAmount = (s: FeaturesState) =>
    s.store.ui.remoteActivation.selectedAmount;

export const selectActivationNumber = (s: FeaturesState) =>
    s.store.ui.remoteActivation.activationCardNumber;

export const selectIsRemoteActivationsEligible = (s: FeaturesState) =>
    selectActivationCardType(s) === 'InstantActivate';

export const selectRemoteActivationsProduct = (s: FeaturesState) =>
    s.store.ui.remoteActivation.product;

export const selectRemoteActivationsIsCardReloadable = (s: FeaturesState) =>
    s.store.ui.remoteActivation.isCardReloadable;
