import { useEffect } from 'react';
import { removeConstructorRefinedContent } from 'third-party-integrations/constructor';

export const useSetupWindowListeners = () => {
    useEffect(() => {
        // FEC-1622: constructor refined_content
        window.addEventListener('unload', removeConstructorRefinedContent);
        return () => {
            window.removeEventListener('unload', removeConstructorRefinedContent);
        };
    }, []);
};
