import {
    AchAccount,
    AchPublicToken,
    AchVerificationStatus,
    AchUserLinkTokenResponse,
    AchUserLinkTokenRequest,
    OrgAchAccount,
} from './models/AchAccount';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import { ACH_URL, E_COMM_ORGS_URL } from 'common/api/config';
import { toQueryString } from 'common/utils/queryString';

export const getUserAchAccount = () => withTokensIfNeeded<AchAccount>(`${ACH_URL}`);

export interface AchAccountBody {
    blackBoxValue: string;
    secondaryBlackBoxValue?: string;
    publicToken: AchPublicToken;
    accountId: string;
    verificationStatus: AchVerificationStatus;
}

export interface SetUserAchAccountBody extends AchAccountBody {
    addressId: string;
}

export interface SetOrgAchAccountBody extends AchAccountBody {}

export const setUserAchAccount = (body: SetUserAchAccountBody) => {
    return withTokensIfNeeded<AchAccount>(`${ACH_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const setUserAchAccountStatus = (blackBoxValue: string) => {
    return withTokensIfNeeded(`${ACH_URL}/status`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ blackBoxValue }),
    });
};

export const getUserAchLinkToken = (request: AchUserLinkTokenRequest) => {
    return withTokensIfNeeded<AchUserLinkTokenResponse>(
        `${ACH_URL}/linktoken?${toQueryString(request)}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getOrgAchLinkToken = (orgID: string, request: AchUserLinkTokenRequest) => {
    return withTokensIfNeeded<AchUserLinkTokenResponse>(
        `${E_COMM_ORGS_URL}/${orgID}/plaid/linktoken?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }
    );
};

export const addPlaidAchToOrg = (orgID: string, body: SetOrgAchAccountBody) => {
    return withTokensIfNeeded<OrgAchAccount>(`${E_COMM_ORGS_URL}/${orgID}/plaid?api-version=1`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const deleteOrgAchAccount = (orgID: string) => {
    return withTokensIfNeeded(`${E_COMM_ORGS_URL}/${orgID}/achaccount?api-version=1`, {
        method: 'DELETE',
    });
};

export const verifyPlaidOrg = (orgID: string) => {
    return withTokensIfNeeded<OrgAchAccount>(
        `${E_COMM_ORGS_URL}/${orgID}/plaid/verify?api-version=1`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getOrgAchAccount = async (orgID: string) => {
    const res = await withTokensIfNeeded<OrgAchAccount>(
        `${E_COMM_ORGS_URL}/${orgID}/achAccount?api-version=1`
    );
    return res;
};

export const deleteUserAchAccount = () =>
    withTokensIfNeeded<null>(`${ACH_URL}`, {
        method: 'DELETE',
    });

export const cancelUserAchAccount = () =>
    withTokensIfNeeded<null>(`${ACH_URL}/cancel`, {
        method: 'DELETE',
    });
