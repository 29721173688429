export interface Family {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    marketingOptIn: boolean;
    studentName: string | null;
    fundraisingGroup: string | null;
    legacyId: number | null;
}

export const emptyFamily: Family = {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    marketingOptIn: false,
    studentName: null,
    fundraisingGroup: null,
    legacyId: null,
};
