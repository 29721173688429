import { SearchFacet } from 'common/api/search/models/facets/SearchFacet';
import { PriceSearchFacet } from 'common/api/search/models/facets/PriceSearchFacet';

export enum BrandsSearchFacet {
    Categories = 'categories',
    Price = 'price',
    SupportedTypes = 'supportedTypes',
    IsOnBonus = 'isOnBonus',
    IsShipToHomeEligible = 'isShipToHomeEligible',
    EarningsType = 'earningsType',
}

export interface BrandsSearchFacets {
    [BrandsSearchFacet.Categories]: SearchFacet[];
    [BrandsSearchFacet.Price]: PriceSearchFacet[];
    [BrandsSearchFacet.SupportedTypes]: SearchFacet[];
    [BrandsSearchFacet.IsOnBonus]: SearchFacet[];
    [BrandsSearchFacet.IsShipToHomeEligible]: SearchFacet[];
    [BrandsSearchFacet.EarningsType]?: SearchFacet[];
}
