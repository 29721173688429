import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import { createStyles } from '../../../utils/createStyle';

import { ShopNavOption } from './ShopNavOption';

import { ReactComponent as DiscoverIcon } from '../../../media/images/shop-discover.svg';
import { ReactComponent as BuyGiftCardsIcon } from '../../../media/images/shop-buy-gift-cards.svg';
import { ReactComponent as ShopOnlineIcon } from '../../../media/images/shop-shop-online.svg';
import { Routes } from 'routes';
import { useHistory } from 'react-router-dom';

const style = createStyles({
    listContainer: 'flex text-center text-brand',
    container: [
        'flex justify-center w-screen lg:w-auto',
        'border-t-2 border-solid  border-grey-light lg:border-none',
        'pt-2 mt-2 -mx-4 md:-mx-8 lg:pt-0 lg:mt-0 ',
    ],
});

enum NavOptions {
    DISCOVER = 'Discover',
    BUY_GIFT_CARDS = 'Buy Gift Cards',
    SHOP_ONLINE = 'Shop Online',
}

export interface ShopNavOptionsProps {
    selectedTab?: Routes.Shop | Routes.ShopOnline | Routes.ShopGiftCards | undefined;
}

export const ShopNavOptions = ({ selectedTab }: ShopNavOptionsProps) => {
    const history = useHistory();
    const onDiscoverClick = () => {
        history.push(Routes.Shop);
    };
    const onBuyGiftCardsClick = () => {
        history.push(Routes.ShopGiftCards);
    };
    const onShopOnlineClick = () => {
        history.push(Routes.ShopOnline);
    };

    return (
        <Tabs.Root className={style.container} value={selectedTab}>
            <Tabs.List className={style.listContainer}>
                <ShopNavOption
                    icon={<DiscoverIcon />}
                    title={NavOptions.DISCOVER}
                    onClick={onDiscoverClick}
                    selected={Routes.Shop === selectedTab}
                    tabId={NavOptions.DISCOVER}
                />
                <ShopNavOption
                    icon={<BuyGiftCardsIcon />}
                    title={NavOptions.BUY_GIFT_CARDS}
                    onClick={onBuyGiftCardsClick}
                    selected={Routes.ShopGiftCards === selectedTab}
                    tabId={NavOptions.BUY_GIFT_CARDS}
                />

                <ShopNavOption
                    icon={<ShopOnlineIcon />}
                    title={NavOptions.SHOP_ONLINE}
                    onClick={onShopOnlineClick}
                    selected={Routes.ShopOnline === selectedTab}
                    tabId={NavOptions.SHOP_ONLINE}
                    isNew={true}
                />
            </Tabs.List>
        </Tabs.Root>
    );
};
