import { EnrollSourceApplicationIds } from './RegistrationRequestWithoutOrg';

export interface RegistrationRequest {
    firstName: string;
    lastName: string;
    studentName: string;
    fundraisingGroup: string;
    email: string;
    password: string;
    confirmPassword: string;
    organizationId: string;
    securityQuestionAnswers: { [questionId: string]: string };
    marketingOptIn: boolean;
    blackBoxValue: string;
    username: string;
    createOneTimeCode?: boolean;
    enrollSourceApplicationId: EnrollSourceApplicationIds;
    enrollmentCode: string;
}

export const emptyRegistrationRequest: RegistrationRequest = {
    firstName: '',
    lastName: '',
    studentName: '',
    fundraisingGroup: '',
    email: '',
    password: '',
    confirmPassword: '',
    organizationId: '',
    securityQuestionAnswers: {},
    marketingOptIn: true,
    blackBoxValue: '',
    username: '',
    createOneTimeCode: false,
    enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
    enrollmentCode: '',
};
