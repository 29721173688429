import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { refreshAuthenticationStatusThunk, selectUserId } from '../../account/duck';

import { selectHomeDetails, requestHomeDetails } from 'common/features/store/duck/home/duck';
import { FeaturesState } from 'common/features/featuresReducer';

export const useHomeDetailsFromBff = () => {
    const dispatch = useDispatch<any>();
    const store = useStore<FeaturesState>();
    const homeDetails = useSelector(selectHomeDetails);
    const userId = useSelector(selectUserId);

    const refreshHomeDetails = useCallback(() => {
        return dispatch(requestHomeDetails());
    }, [dispatch]);

    useEffect(() => {
        refreshAuthenticationStatusThunk(dispatch, store.getState);
    }, [dispatch, store.getState]);

    useEffect(() => {
        if (userId) {
            refreshHomeDetails();
        }
    }, [userId, refreshHomeDetails]);

    return homeDetails;
};
