import React from 'react';
import { StepWrapper, StepWrapperProps } from 'features/coordinator/enrollment/steps/StepWrapper';

interface BaseStepProps extends StepWrapperProps {}

export const BaseStep = ({
    children,
    ...stepWrapperProps
}: React.PropsWithChildren<BaseStepProps>) => {
    return <StepWrapper {...stepWrapperProps}>{children}</StepWrapper>;
};
