import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { getOrgTypesThunk } from 'common/features/store/duck/organization/duck';
import { useRootSelector } from 'common/features/featuresReducer';
import { callIfNeeded, needsData } from 'common/modules/async-actions/thunk';

export const useOrgTypeOptions = () => {
    const dispatch = useDispatch<any>();
    const asyncState = useRootSelector((s) => s.store.organization.orgTypesAsyncState);

    // load the org types
    useEffect(() => {
        dispatch(callIfNeeded(getOrgTypesThunk, needsData({ state: asyncState }))());
    }, [dispatch, asyncState]);
};
