import { pluralize } from 'common/utils/strings';

export const MINUTE = 1000 * 60;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export const TimeUnitLabels = new Map([
    [MINUTE, 'minute'],
    [HOUR, 'hour'],
    [DAY, 'day'],
    [WEEK, 'week'],
]);

export type DateString = string;
export type DateLike = DateString | Date | number;

const normalizeDate = (dateLike: DateLike) => new Date(dateLike);

export const msUntil = (target: DateLike, from: DateLike = Date.now()): number => {
    return normalizeDate(target).getTime() - normalizeDate(from).getTime();
};

export const msToDays = (ms: number): number => {
    return ms / DAY;
};

export const formatDateObject = (date: Date): string => {
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};

export const formatDuration = (ms: number): string => {
    const numDays = ms / DAY;
    if (numDays > 2) {
        return `${Math.ceil(numDays)} days`;
    }

    const numHours = ms / HOUR;
    if (numHours >= 1) {
        const displayedHours = Math.floor(numHours);
        return `${displayedHours} ${pluralize('hour', displayedHours)}`;
    }
    return 'Less than 1 hour';
};

export const monthDiff = (dFinal: DateLike, dStart: DateLike): number => {
    const dFinalDate = normalizeDate(dFinal);
    const dStartDate = normalizeDate(dStart);
    const dFinalMonths = dFinalDate.getFullYear() * 12 + dFinalDate.getMonth();
    const dStartMonths = dStartDate.getFullYear() * 12 + dStartDate.getMonth();
    const floorer = dFinalDate.getDate() < dStartDate.getDate() ? -1 : 0;
    return dFinalMonths - dStartMonths + floorer;
};

export const getTimeUnit = (ms: number) => {
    return Array.from(TimeUnitLabels.entries())
        .reverse()
        .find(([k]) => ms >= k);
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return formatDateObject(date);
};

export const formatLongDate = (
    dateLike: string | undefined,
    options?: Intl.DateTimeFormatOptions
) => {
    if (!dateLike) {
        return;
    }
    const date = normalizeDate(dateLike);
    const defaults: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...options,
    };
    return new Intl.DateTimeFormat('en-US', defaults).format(date);
};

export const startOfDay = (dateLike: DateLike): string => {
    const date = normalizeDate(dateLike);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
};

export const endOfDay = (dateLike: DateLike): string => {
    const date = normalizeDate(dateLike);
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
    ).toISOString();
};

const dupDate = (d: Date) => {
    d = normalizeDate(d);
    return new Date(d.getTime());
};

export const daysFromNow = (offset: number) => {
    return daysFromDate(new Date(), offset);
};

export const daysFromDate = (d: Date, offset: number) => {
    d = dupDate(d);
    d.setDate(d.getDate() + offset);

    return d;
};
