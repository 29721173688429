import React, {
    useEffect,
    useState,
    useLayoutEffect,
    UIEventHandler,
    EventHandler,
    UIEvent,
} from 'react';
import { Switch, Route, Redirect, useHistory, useLocation, Link } from 'react-router-dom';
import { createStyles } from 'utils/createStyle';

import { Navigation } from './components/Navigation';
import { StickyHeader } from './components/StickyHeader';
import { Footer } from './components/Footer';
import { Routes } from 'routes';
import { useFeatureFlags, FeatureFlagTypes } from 'common/hooks/useFeatureFlags';
import { getSections, SectionConfig } from './nav-items';
import { useIsScripware } from 'hooks/useIsScripware';
import { ReactComponent as RaiseRightLogo } from '../../media/images/RaiseRightLogo.svg';
import { colors } from 'styles/settings';
import { FullScreenOverlay } from 'features/shop-base/OrgChangeOverlay/FullScreenOverlay';
import { useSelector } from 'react-redux';
import { selectOrgSwitchState } from 'common/features/store/duck/home/duck';
import { useRootSelector } from 'rootStore';
import { withAuth } from 'features/auth/components/withAuth';

const HomeScreen = React.lazy(() => import('./screens/HomeScreen'));
const PackingSlipsScreen = React.lazy(() => import('./screens/PackingSlipsScreen'));
const AllReportsScreen = React.lazy(() => import('./screens/AllReportsScreen'));
const ParticipantAccountsScreen = React.lazy(() => import('./screens/ParticipantAccountsScreen'));
const InviteParticipantsScreen = React.lazy(() => import('./screens/InviteParticipantsScreen'));
const OrganizationProfileScreen = React.lazy(() => import('./screens/OrganizationProfileScreen'));
const CoordinatorRolesScreen = React.lazy(() => import('./screens/CoordinatorRolesScreen'));
const OrganizationPINResetScreen = React.lazy(() => import('./screens/OrganizationPINResetScreen'));
const LocalProductsScreen = React.lazy(() => import('./screens/LocalProductsScreen'));
const LocalSuppliersScreen = React.lazy(() => import('./screens/LocalSuppliersScreen'));
const NotificationsScreen = React.lazy(() => import('./screens/NotificationsScreen'));
const SCRIPWAREScreen = React.lazy(() => import('./screens/SCRIPWAREScreen'));
const PaymentChangeFormScreen = React.lazy(() => import('./screens/PaymentChangeFormScreen'));
const PaymentTypesScreen = React.lazy(() => import('./screens/PaymentTypesScreen'));
const ResourceCenterScreen = React.lazy(() => import('./screens/ResourceCenterScreen'));
const CustomerSupportScreen = React.lazy(() => import('./screens/CustomerSupportScreen'));
const EnterOrdersScreen = React.lazy(() => import('./screens/EnterOrdersScreen'));
const OrderQueueScreen = React.lazy(() => import('./screens/OrderQueueScreen'));
const POManagerScreen = React.lazy(() => import('./screens/POManagerScreen'));
const ParticipantOrderFormScreen = React.lazy(() => import('./screens/ParticipantOrderFormScreen'));
const OrderHistoryScreen = React.lazy(() => import('./screens/OrderHistory'));
const OrganizationSettingsScreen = React.lazy(() => import('./screens/OrganizationSettingsScreen'));

interface Props {}

export const CoordinatorDashboard = withAuth('/shop/account/coordinator-dashboard-new/home')(() => {
    const homeDetails = useRootSelector((state) => state?.store?.home?.homeDetails);
    const { isEnabled, flags } = useFeatureFlags();
    const location = useLocation();
    const history = useHistory();
    const [sections, setSections] = useState<SectionConfig[] | null>(null);
    const isScripwareUser = useIsScripware();
    const [hasScrolled, setHasScrolled] = useState(false);

    useLayoutEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
        const scrollTop = (e.target as HTMLDivElement).scrollTop;
        setHasScrolled(scrollTop > 0);
    };

    useEffect(() => {
        const sections_ = getSections(isScripwareUser);
        setSections(sections_);
    }, [isScripwareUser]);

    const styleClasses = createStyles({
        mainContent: [
            'bg-white flex w-full',
            {
                minHeight: '80vh',
            },
        ],
        layout: {
            display: 'grid',
            gridTemplateColumns: '260px 1fr',
            gridTemplateRows: '61px 1fr',
            gridTemplateAreas: `
                "logoContainer headerContainer"
                "navigationContainer mainContentContainer"
                "navigationContainer stickyHeader"
            `,
            height: '100vh',
        },
        navigationContainer: [
            'px-1 pt-4 pb-2 border-r border-grey-line',
            {
                gridArea: 'navigationContainer',
                overflowY: 'auto',
                height: 'calc(100vh - 61px)',
            },
        ],
        logoContainer: [
            'pt-3 pl-6 border-r border-grey-line height-61',
            {
                gridArea: 'logoContainer',
                background: '#fff',
                height: '61px',
                width: '260px',
                zIndex: 2,
            },
            hasScrolled ? 'border-b' : '',
        ],
        headerContainer: {
            gridArea: 'headerContainer',
        },
        mainContentContainer: [
            'pb-0 overflow-auto',
            {
                gridArea: 'mainContentContainer',
                minHeight: 'calc(100vh - 61px)',
                width: '100%',
            },
        ],
        scrollView: {
            overflowY: 'auto',
            height: 'calc(100vh - 62px) !important',
        },
        footer: [
            'pt-4 pl-4',
            {
                borderTop: `1px solid ${colors.lineGrey}`,
            },
        ],
    });

    const isLoggedIn = useRootSelector((s) => !!s.store.account.userId);
    const isCoordinator = useRootSelector((s) => s.store.account.isCoordinator);

    useEffect(() => {
        if (!isLoggedIn) {
            history.push(Routes.Shop);
        }
    }, [isLoggedIn, isCoordinator]);

    useEffect(() => {
        if (Object.keys(flags).length === 0 || !homeDetails) {
            return;
        }

        if (!isEnabled(FeatureFlagTypes.enable_unified_coordinator_dashboard)) {
            if (isCoordinator) {
                history.push(Routes.ShopAccountSettingsCoordinatorTab);
            } else {
                history.push(Routes.ShopAccountSettingsDashboardTab);
            }
        } else {
            // CASE: You're not a coordinator to your currently selected org
            // _but_ you do have the feature flag enabled.
            if (!isCoordinator) {
                history.push(Routes.ShopAccountSettingsDashboardTab);
            }
        }
    }, [isEnabled, history, flags, isCoordinator, homeDetails]);

    const { showOverlay } = useSelector(selectOrgSwitchState);

    if (!sections) {
        return null;
    }

    if (showOverlay) {
        return <FullScreenOverlay />;
    }

    return (
        <div className="overflow-auto">
            <div className={styleClasses.layout}>
                <div className={styleClasses.logoContainer}>
                    <Link to={Routes.Shop}>
                        <RaiseRightLogo width="196" height="37" />
                    </Link>
                </div>

                <div className={styleClasses.navigationContainer} onScroll={handleScroll}>
                    <Navigation sections={sections} />
                </div>

                <div className={styleClasses.headerContainer}>
                    <StickyHeader />
                </div>

                <div className={styleClasses.mainContentContainer}>
                    <div className={styleClasses.scrollView}>
                        <div className={styleClasses.mainContent}>
                            <React.Suspense fallback={null}>
                                <Switch location={location}>
                                    <Route
                                        path={Routes.CoordinatorDashboardHome}
                                        component={HomeScreen}
                                    />

                                    <Route
                                        path={Routes.CoordinatorDashboardPackingSlips}
                                        component={PackingSlipsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardAllReports}
                                        component={AllReportsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardParticipantAccounts}
                                        component={ParticipantAccountsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardInviteParticipants}
                                        component={InviteParticipantsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardOrganizationProfile}
                                        component={OrganizationProfileScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardOrganizationSettings}
                                        component={OrganizationSettingsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardCoordinatorRoles}
                                        component={CoordinatorRolesScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardOrganizationPINReset}
                                        component={OrganizationPINResetScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardLocalProducts}
                                        component={LocalProductsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardLocalSuppliers}
                                        component={LocalSuppliersScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardNotifications}
                                        component={NotificationsScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardSCRIPWARE}
                                        component={SCRIPWAREScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardPaymentChangeForm}
                                        component={PaymentChangeFormScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardPaymentTypes}
                                        component={PaymentTypesScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardResourceCenter}
                                        component={ResourceCenterScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardCustomerSupport}
                                        component={CustomerSupportScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardEnterOrders}
                                        exact
                                        component={EnterOrdersScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardOrderQueue}
                                        exact
                                        component={OrderQueueScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardPOManager}
                                        exact
                                        component={POManagerScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardParticipantOrderForm}
                                        exact
                                        component={ParticipantOrderFormScreen}
                                    />
                                    <Route
                                        path={Routes.CoordinatorDashboardOrderHistory}
                                        exact
                                        component={OrderHistoryScreen}
                                    />

                                    <Redirect
                                        from={Routes.CoordinatorDashboardRoot}
                                        to={Routes.CoordinatorDashboardHome}
                                    />
                                </Switch>
                            </React.Suspense>
                        </div>

                        <div className={styleClasses.footer}>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
