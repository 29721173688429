import { useEffect, useState } from 'react';

const genClientId = (): string => {
    const client_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    return client_id;
};

const CONSTRUCTOR_CLIENT_ID = 'constructor-client-id';

export const useClientId = () => {
    const [clientId, setClientId] = useState<string>('');

    useEffect(() => {
        const fetchClientId = async () => {
            let storedClientId = localStorage.getItem(CONSTRUCTOR_CLIENT_ID);

            if (!storedClientId) {
                storedClientId = genClientId();
                localStorage.setItem(CONSTRUCTOR_CLIENT_ID, storedClientId);
            }

            setClientId(storedClientId);
        };

        fetchClientId();
    }, []);

    return clientId;
};
