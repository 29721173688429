import React from 'react';
import { useDispatch } from 'react-redux';
import { endOrgSwitchFlow } from 'common/features/store/duck/home/duck';
import { Btn } from 'components/btn/Btn';
import { useRootSelector } from 'common/features/featuresReducer';
import { ReactComponent as CartImage } from '../../../media/images/cart-circle.svg';
import { removeItemFromCart } from 'common/api/e-comm/cart/lineitem';
import { updateCart } from 'common/features/store/duck/cart/duck';
import { styles } from './styles';

export const useCartClearConfirmation = () => {
    const dispatch = useDispatch<any>();
    const { cart } = useRootSelector((s) => s.store.cart);

    const onClick = async () => {
        if (cart) {
            const ids = cart.lineItems.map((li) => li.id);

            for (let i = 0; i < ids.length; i++) {
                await removeItemFromCart(ids[i]);
            }

            dispatch(updateCart({ cart: null }));
        }
    };

    const onCancel = () => {
        dispatch(endOrgSwitchFlow(null));
    };

    return {
        onClick,
        onCancel,
    };
};

export const CartClearConfirmation = () => {
    const { onClick, onCancel } = useCartClearConfirmation();
    return (
        <div className="h-full flex justify-center flex-col text-center">
            <div className={styles.header}>Are you sure you want to change organizations?</div>
            <div className={styles.subHeader}>
                If so, items in your current cart will be cleared.
            </div>
            <div className="mt-10 mx-auto">
                <CartImage />
            </div>
            <div className="mt-10 md:mt-16">
                <Btn onClick={onClick}>Yes, Proceed </Btn>
            </div>
            <div className="mt-5 cursor-pointer">
                <a onClick={onCancel}>No, Stay in This Organization</a>
            </div>
        </div>
    );
};
