import React, {
    useEffect,
    useRef,
    useContext,
    useState,
    useLayoutEffect,
    useCallback,
    useMemo,
} from 'react';
import { ShopScreen } from 'features/shop-base/ShopScreen';
import { Route, Switch, useLocation, matchPath } from 'react-router';
import { Routes } from 'routes';

import { getEnrollmentEntryById } from 'common/api/contentful/enrollment';
import {
    ContentFullEnrollmentProvider,
    ContentFullEnrollmentContext,
} from 'features/coordinator/components/useContentfulEnrollment';
import { OrganizationScreen } from './screens/UserInfo';
import { useOrgTypeOptions } from './hooks/useOrgTypeOptions';
import { useRootSelector } from 'common/features/featuresReducer';
import {
    selectBillingSelectionError,
    selectEnrollmentFlowControls,
    selectEnrollmentLogError,
    setMaxStep,
    updateEnrollmentLogRequest,
    updateBillingSelectionRequest,
    selectCreateContactInHubspotError,
    updateContactInHubspotRequest,
    selectCompletePlaidStatusError,
    updateCompletePlaidStatusRequest,
} from 'common/features/store/duck/ui/org-enrollment-v2';
import { useDispatch } from 'react-redux';
import { EnrollmentContentType } from 'features/coordinator/types';
import { ChooseProgram } from './screens/ChooseProgram';
import { EnrollStepWrapperV2 } from './EnrollStepWrapperV2';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Render } from 'components/loading-screen/Render';
import { initialAsyncActionState } from 'common/modules/async-actions/core';
import { ErrorModal } from './components/ErrorModal';
import { SuccessStep } from './steps/SuccessStep';
import { UserAuthState } from 'common/features/store/duck/account/duck';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { ValidateCodeInnerStep } from './steps/SecureYourAccountScreen/VerifyCodeStep';
import { SendCodeInnerStep } from './steps/SecureYourAccountScreen/SendCodeStep';
import { useV2RouteChecking } from './utils/useContinueRoutes';
import { useHubspotTracking } from 'hooks/useHubspotTracking';

export const OrgEnrollmentV2 = () => {
    const dispatch = useDispatch<any>();
    const { currentStep, maxStep } = useRootSelector(selectEnrollmentFlowControls);

    useEffect(() => {
        if (maxStep < currentStep) {
            dispatch(setMaxStep(currentStep));
        }
    }, [currentStep, maxStep, dispatch]);

    return (
        <ContentFullEnrollmentProvider
            enrollmentVersion="v2"
            firebaseKey={'org_enrollment_contentful_heading_map'}
            fetchContentfulContent={getEnrollmentEntryById}
            contentType={EnrollmentContentType.SCHEMA_ORG_ENROLLMENT_V2}
        >
            <OrgEnrollmentV2Screen />
        </ContentFullEnrollmentProvider>
    );
};

const OrgEnrollmentV2Screen = () => {
    const completedRoutes = useMemo(
        () => [
            Routes.EnrollV2CompletedInstant,
            Routes.EnrollV2CompletedMicroDeposits,
            Routes.EnrollV2CompletedAutomatedDeposits,
            Routes.EnrollV2CompletedPaperOnly,
        ],
        []
    );
    useProtectedRoutes(completedRoutes);
    const footerRef = useRef<HTMLSpanElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<any>();
    const { isLoading, contentfulEntry, hasFailedLoading } = useContext(
        ContentFullEnrollmentContext
    );
    const [containerHeight, setContainerHeight] = useState<string>('auto');
    const enrollmentLogError = useRootSelector(selectEnrollmentLogError);
    const billingSelectionError = useRootSelector(selectBillingSelectionError);
    const createContactInHubspotError = useRootSelector(selectCreateContactInHubspotError);
    const completePlaidStatusError = useRootSelector(selectCompletePlaidStatusError);
    useOrgTypeOptions();

    useHubspotTracking();

    const shouldDisplayError =
        enrollmentLogError ||
        billingSelectionError ||
        createContactInHubspotError ||
        completePlaidStatusError;

    const onClose = useCallback(() => {
        dispatch(updateEnrollmentLogRequest(initialAsyncActionState));
        dispatch(updateBillingSelectionRequest(initialAsyncActionState));
        dispatch(updateContactInHubspotRequest(initialAsyncActionState));
        dispatch(updateCompletePlaidStatusRequest(initialAsyncActionState));
    }, [dispatch]);

    const { checkRoute, readyToCheckRoute } = useV2RouteChecking();
    const routeCheckedRef = useRef(false);

    useEffect(() => {
        if (routeCheckedRef.current) {
            return;
        }

        if (readyToCheckRoute) {
            routeCheckedRef.current = true;
            checkRoute();
        }
    }, [dispatch, readyToCheckRoute, checkRoute]);

    /*
       Side-Effect: compute the width that the content of the page should be
       in order to avoid having whitespace at the bottom of the page
       because of the lack of content.
     */
    useLayoutEffect(() => {
        if (isLoading && footerRef.current && containerRef.current) {
            const footerTop = footerRef.current.getBoundingClientRect().top;
            if (footerTop != 0 && footerTop < window.screen.height) {
                const computedHeight =
                    containerRef.current?.getBoundingClientRect().height +
                    (window.screen.height - footerTop) -
                    200; // NOTE: the 200 is just some offset
                setContainerHeight(`${computedHeight.toString()}px`);
            }
        } else {
            setContainerHeight('auto');
        }
    }, [isLoading]);

    return (
        <ShopScreen footerRef={footerRef}>
            <div
                ref={containerRef}
                style={{ height: containerHeight }}
                className="bg-pale-blue flex justify-center"
            >
                <Render<React.SVGProps<SVGSVGElement>>
                    when={Boolean(contentfulEntry) || hasFailedLoading}
                    loadingComponentProps={{ className: 'm-auto' }}
                    renderers={{ LoadingComponent: ActivityIndicator }}
                >
                    <EnrollStepWrapperV2>
                        <Switch>
                            <Route path={Routes.EnrollV2} exact>
                                <OrganizationScreen />
                            </Route>
                            <Route path={Routes.EnrollV2SecureAccount} exact>
                                <SendCodeInnerStep />
                            </Route>
                            <Route path={Routes.EnrollV2SecureAccountVerify} exact>
                                <ValidateCodeInnerStep />
                            </Route>
                            <Route path={Routes.EnrollV2ChooseProgram} exact>
                                <ChooseProgram />
                            </Route>
                            <Route path={[...completedRoutes]} exact>
                                <SuccessStep />
                            </Route>
                        </Switch>
                    </EnrollStepWrapperV2>
                </Render>
            </div>
            <ErrorModal isOpen={!!shouldDisplayError} onClose={onClose} />
        </ShopScreen>
    );
};

const useProtectedRoutes = (completedRoutes: string[]) => {
    const {
        userAuthState: authState,
        accountAsyncState: { loading },
    } = useRootSelector((state) => state.store.account);
    const routes = [...completedRoutes, Routes.EnrollV2ChooseProgram, Routes.EnrollV2SecureAccount];
    const { pathname } = useLocation();

    useEffect(() => {
        if (
            authState === UserAuthState.NOTAUTHENTICATED &&
            matchPath(pathname, routes) &&
            !loading
        ) {
            sendToLoginPage(pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, pathname, loading]);
};
