import { useEffect, useRef } from 'react';

export const usePreviousDifferent = <T>(value?: T): T | undefined => {
    const shortTermRef = useRef<T | undefined>(value);
    const longTermRef = useRef<T>();

    useEffect(() => {
        shortTermRef.current = value;
        return () => {
            longTermRef.current = value;
        };
    }, [value]);

    return shortTermRef.current === value ? longTermRef.current : shortTermRef.current;
};
