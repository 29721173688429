import { createSlice } from 'common/modules/create-slice';

import { createApiThunk, AsyncActionState } from 'common/modules/async-actions/thunk';
import {
    getCoordinatorPendingOrdersByOrg,
    CoordinatorPendingOrders,
} from 'common/api/crm/organization/coordinator';
import { FeaturesState } from 'common/features/featuresReducer';

export interface CoordinatorState {
    pendingOrders: number;
    pendingPurchaseOrderCount: number;
    loading: boolean;
}

const initialCoordinatorState: CoordinatorState = {
    pendingOrders: 0,
    pendingPurchaseOrderCount: 0,
    loading: true,
};

const { reducer, update } = createSlice(initialCoordinatorState, 'COORDINATOR');
export const coordinatorReducer = reducer;

export const getCoordinatorPendingOrdersByOrgThunk = createApiThunk(
    getCoordinatorPendingOrdersByOrg,
    () => (loadingState: AsyncActionState, data?: CoordinatorPendingOrders) => {
        return data
            ? update({
                  pendingOrders: data.totalPendingOrders,
                  pendingPurchaseOrderCount: data.pendingPurchaseOrderCount,
                  loading: false,
              })
            : update({ loading: loadingState.loading });
    }
);

export const selectCoordinatorMetaData = (state: FeaturesState) => state.store.coordinator;
