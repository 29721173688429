import { useCallback, useEffect, useState } from 'react';

export const useBrowserTabFocused = () => {
    const [focused, setFocused] = useState(document.visibilityState === 'visible');

    const monitorFocus = useCallback(() => {
        setFocused(document.visibilityState === 'visible');
    }, [setFocused]);

    useEffect(() => {
        window.addEventListener('visibilitychange', monitorFocus);

        return () => {
            window.removeEventListener('visibilitychange', monitorFocus);
        };
    }, [monitorFocus]);

    return focused;
};
