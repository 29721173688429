import {
    CanonicalSearchResultBrand,
    Bonus,
} from 'common/api/search/models/CanonicalSearchResultBrand';
import { ConstructorReturnObject } from './types';
import { getFacetsFromConfigObject, getSupportedTypes } from './filter-extract-utils';
import { BrandSearchResponse } from 'common/api/search/models/BrandSearchResponse';
import { propertyNormalizer } from 'common/utils/propertyNormalizer';

export function checkBonusExpiration(bonusArray: Bonus[]) {
    if (!bonusArray?.length) {
        return false;
    }

    const bonuses = bonusArray.map((bon) => ({
        Rebate: bon.rebate ?? bon.Rebate,
        Expiration: bon.expiration ?? bon.Expiration,
    }));

    // NOTE: Copied from `findBonusRebate` -- could not reuse because that function
    // expects the properties to be camelCased!  This function is called prior to the data
    // being normalized.  As a result we won't take the performance hit!
    const latestBonusRebate = bonuses.reduce((highest, next) => {
        if (highest.Rebate !== next.Rebate) {
            return highest.Rebate > next.Rebate ? highest : next;
        }

        return new Date(highest.Expiration) > new Date(next.Expiration) ? highest : next;
    });

    const lastBonus = new Date(latestBonusRebate.Expiration);
    const today = new Date();
    return lastBonus.getTime() > today.getTime();
}

function convertResultsToCanonicalSearchResultsBrand(
    results: ConstructorReturnObject[]
): CanonicalSearchResultBrand[] {
    return (results || []).map((result: ConstructorReturnObject): CanonicalSearchResultBrand => {
        const IsOnBonus = checkBonusExpiration(result.data.Bonus);
        const IsAffiliateOfferOnBonus = checkAffiliateBonusExpiration(
            result.data.AffiliateBonusStart,
            result.data.AffiliateBonusEnd
        );
        return {
            '@search.score': result.data.PopularityRank,
            Id: result.data.id,
            Name: result.value,
            NormalizedName: result.value,
            Categories: result.data.group_ids,
            Suggestions: [],
            Price: Infinity,
            Rebate: result.data.Rebate,
            IsOnBonus: IsOnBonus,
            IsLocalProduct: result.data.IsLocalProduct,
            Bonus: IsOnBonus ? result.data.Bonus : [],
            MaxRebate: result.data.MaxRebate,
            SupportedTypes: getSupportedTypes(result.data.facets),
            IsFeatured: result.data.IsFeatured,
            Lists: [],
            KeyWords: result.matched_terms,
            PopularityRank: result.data.PopularityRank,
            ProductIds: [],
            ImageUrl: result.data.image_url,
            OrganizationId: undefined,
            IsSponsored: result.is_slotted && result.labels?.is_sponsored,
            newBannerExpiration: result.data.NewBannerExpiration,
            AffiliateBonus: result.data.AffiliateBonus,
            AffiliateBonusEnd: result.data.AffiliateBonusEnd,
            AffiliateBonusStart: result.data.AffiliateBonusStart,
            AffiliateExclusionText: result.data.AffiliateExclusionText,
            AffiliateOfferEarnText: result.data.AffiliateOfferEarnText,
            AffiliateOfferTerms: result.data.AffiliateOfferTerms,
            AffiliateOfferUrl: result.data.AffiliateOfferUrl,
            AffiliateRebate: result.data.AffiliateRebate,
            AffiliateVariablePricingText: result.data.AffiliateVariablePricingText,
            CanDoubleDip: result.data.CanDoubleDip,
            ContainsAffiliateOffer: result.data.ContainsAffiliateOffer,
            ContainsVariableRebate: result.data.ContainsVariableRebate,
            IsAffiliateOfferOnBonus: IsAffiliateOfferOnBonus,
            IsRaiseRightBrand: result.data.IsRaiseRightBrand,
            IsRaiseRightGiftCardOnBonusOrAffiliateOnBonus: IsAffiliateOfferOnBonus || IsOnBonus,
        };
    });
}

export function convertConstructorResultToBrandSearchResponse(
    constructorResult: any
): BrandSearchResponse {
    const response: any = {};
    response['@odata.count'] = constructorResult.response?.length || 0;
    response['@odata.context'] = 'constructor';
    /* '@search.facets'?: { [key: string]: [{ count: number; value: string }] },*/
    response['@search.facets'] = {};
    response['value'] = convertResultsToCanonicalSearchResultsBrand(
        constructorResult.response || []
    );

    return response as BrandSearchResponse;
}

export function convertConstructorFacetToAzureFacets(facets: any): BrandSearchResponse {
    const response: any = {};
    response['@odata.context'] = 'constructor/facets';
    response['value'] = [];
    const mappedFacets = getFacetsFromConfigObject(facets);
    response['@search.facets'] = mappedFacets;

    return response as BrandSearchResponse;
}

export const processOnBonusBrands = (
    results: ConstructorReturnObject[],
    isOnBonusFilter: boolean,
    onlyAffiliate?: boolean
) => {
    if (isOnBonusFilter) {
        results = results.filter((v: ConstructorReturnObject) => {
            if (onlyAffiliate) {
                return v.data.IsAffiliateOfferOnBonus
                    ? checkAffiliateBonusExpiration(
                          v.data.AffiliateBonusStart,
                          v.data.AffiliateBonusEnd
                      )
                    : true;
            }
            return v.data.IsOnBonus ? checkBonusExpiration(v.data.Bonus) : true;
        });
    } else {
        for (let i = 0; i < results.length; i++) {
            const item = results[i];
            if (item.data.IsOnBonus || item.data.IsAffiliateOfferOnBonus) {
                const isOnBonus = checkBonusExpiration(item.data.Bonus);
                const isAffiliateOnBonus = checkAffiliateBonusExpiration(
                    item.data.AffiliateBonusStart,
                    item.data.AffiliateBonusEnd
                );
                item.data.IsOnBonus = isOnBonus;
                item.data.IsAffiliateOfferOnBonus = isAffiliateOnBonus;
                if (!isOnBonus) {
                    item.data.Bonus = [];
                }

                if (!isAffiliateOnBonus) {
                    item.data.AffiliateBonus = undefined;
                }
            }

            if (!item.data.IsRaiseRightBrand) {
                item.data.Bonus = [];
                item.data.Rebate = [];
            }
        }
    }
    return results;
};

export const checkAffiliateBonusExpiration = (startDate?: string, endDate?: string) => {
    if (!startDate || !endDate) {
        return false;
    }

    const today = new Date().getTime();
    const startDate_ = new Date(startDate.replace(' ', 'T')).getTime();
    const endDate_ = new Date(endDate.replace(' ', 'T')).getTime();

    return startDate_ < today && today < endDate_;
};

export const constructorToBrand = (data: ConstructorReturnObject[]) => {
    const brands = [];
    for (let i = 0; i < data.length; ++i) {
        const item = propertyNormalizer(data[i].data);
        item.categories = item.groupIds;
        item.name = data[i].value;
        item.isSponsored = data[i].is_slotted && data[i].labels?.is_sponsored;
        delete item.groupIds;
        brands.push(item);
    }
    return brands;
};
