import { apiFetch } from '../utils';
import { ACCOUNT_URL, SECURED_ACCOUNT_URL } from '../config';
import { RegistrationRequest } from '../auth/models/RegistrationRequest';
import { RegistrationRequestWithoutOrg } from '../auth/models/RegistrationRequestWithoutOrg';
import { withTokensIfNeeded } from '../utils/withTokensIfNeeded';

import { ValidationMethod } from './enums/ValidationMethod';
import { AuthResponse } from './models/AuthResponse';

interface RegistrationResponse extends AuthResponse {
    userId: string;
    oneTimeCode?: string;
}

export const register = (registrationRequest: RegistrationRequest) => {
    const registrationRequestWithUsername = {
        ...registrationRequest,
        username: registrationRequest.email,
    };

    return apiFetch<RegistrationResponse>(`${ACCOUNT_URL}?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registrationRequestWithUsername),
    });
};

export const registerWithoutOrg = (
    registrationRequest: Omit<
        RegistrationRequestWithoutOrg,
        'question1' | 'question2' | 'answer1' | 'answer2'
    >
) => {
    return apiFetch<RegistrationResponse>(`${ACCOUNT_URL}/without-org?api-version=1`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registrationRequest),
    });
};

export const getPasswordResetOptions = (userId: string) =>
    apiFetch<{ passwordResetOptions: ValidationMethod[] }>(
        `${ACCOUNT_URL}/${userId}/password/reset/options?api-version=1`
    );

export enum AccountRoles {
    FAMILY = 'Family',
    COORDINATOR = 'Coordinator',
    ASSISTANT_COORDINATOR = 'AssistantCoordinator',
}

export interface GetAccountResponse {
    username: string;
    email: string;
    lastPasswordUpdate: string | null;
    organization: {
        organizationId: string;
        roleId: AccountRoles;
        isActive: boolean;
    };
}

export const getAccount = () =>
    withTokensIfNeeded<GetAccountResponse>(`${SECURED_ACCOUNT_URL}?api-version=1`);

export const changePassword = (currentPassword: string, newPassword: string) =>
    withTokensIfNeeded(`${SECURED_ACCOUNT_URL}/password?api-version=1`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ currentPassword, newPassword }),
    });
