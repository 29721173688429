import React, { useEffect } from 'react';

import { GroupBase, MenuListProps } from 'react-select/dist/declarations/src';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';

interface CustomMenuListOptions {
    setFocusedOption: (option: Brand) => unknown;
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const CustomMenuList =
    ({ setFocusedOption }: CustomMenuListOptions) =>
    ({ children, focusedOption }: MenuListProps<Brand, false, GroupBase<Brand>>) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setFocusedOption(focusedOption);
        }, [focusedOption]);

        return (
            <div data-cnstrc-autosuggest className="p-4">
                {children}
            </div>
        );
    };
