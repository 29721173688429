import React from 'react';

import { ReactComponent as Facebook } from '../../media/images/facebook-circle.svg';
import { ReactComponent as Youtube } from '../../media/images/youtube-circle.svg';
import { ReactComponent as LinkedIn } from '../../media/images/linkedin-circle.svg';
import { ReactComponent as Instagram } from '../../media/images/instagram-circle.svg';
import imgBBB from '../../media/images/bbb.png';
import GooglePlaySrc from '../../media/images/GooglePlay.png';
import AppStoreSrc from '../../media/images/AppStore.png';

import { createStyles } from 'utils/createStyle';
import { Routes } from 'routes';

const styles = createStyles({
    extraSmallText: ['font-light text-white', { fontSize: 12, lineHeight: '16px' }],
});

const footerLinks = [
    {
        sectionName: 'Fundraising Info',
        items: [
            { value: 'Gift Card Fundraising', href: Routes.HowItWorks },
            { value: 'Shop Online', href: Routes.HowItWorksShopOnline },
            { value: 'Coordinator Resources', href: Routes.SupportOrganization },
            { value: 'Participant Resources', href: Routes.SupportParticipant },
            { value: 'Fundraising Ideas', href: Routes.FundraisingIdeas },
            { value: 'School Choice', href: Routes.SupportSchoolChoice },
            { value: 'Blog', href: Routes.Blog },
            { value: 'Impact Stories', href: Routes.ImpactStories },
        ],
    },
    {
        sectionName: 'Brands',
        items: [
            // NOTE: These ids point to production brand ids and not other environments
            // NOTE: Will need to discuss how to properly get these brands programmatically
            // in the future.
            { value: 'Amazon.com', href: '/brands/48-amazon' },
            { value: 'Best Buy', href: '/brands/81-best-buy' },
            { value: 'The Home Depot', href: '/brands/300-home-depot' },
            { value: 'Starbucks', href: '/brands/650-starbucks' },
            { value: 'Target', href: '/brands/684-target' },
            { value: 'Walmart', href: '/brands/707-walmart' },
            { value: 'See All Brands', href: Routes.Brands },
            { value: 'Shop By Category', href: Routes.Shop },
        ],
    },
    {
        sectionName: 'Company',
        items: [
            { value: 'About Us', href: Routes.AboutUs },
            { value: 'Who We Help', href: Routes.WhoWeHelp },
            { value: 'FAQ', href: Routes.SupportFaq },
            { value: 'Contact Us', href: Routes.Contact },
            { value: 'Careers', href: Routes.Careers },
            { value: 'Privacy Policy', href: Routes.PrivacyPolicy },
            { value: 'Terms of Use', href: Routes.TermsOfUse },
            { value: 'Sitemap', href: Routes.Sitemap },
        ],
    },
];

const Divider = () => (
    <div style={{ height: 5 }} className="my-2 bg-brand-accent w-10 rounded-sm m-auto lg:mx-0" />
);

export const SiteFooter = () => (
    <footer className="text-white bg-brand-dark p-10 lg:px-32">
        <div className="grid grid-cols-1 text-center md:grid-cols-2 lg:grid-cols-4 lg:text-left">
            {footerLinks.map((linkSection) => (
                <section className="flex flex-col" key={linkSection.sectionName}>
                    <h5 className="text-lg font-bold">{linkSection.sectionName}</h5>
                    <Divider />
                    {linkSection.items.map((item, idx) => {
                        const isLastChild = linkSection.items.length - 1 === idx;
                        const bottomMargin = isLastChild ? 'mb-10' : 'mb-1';
                        return (
                            <a
                                key={item.value}
                                className={`no-underline text-sm font-light text-white mt-1 ${bottomMargin} lg:mb-1`}
                                href={item.href}
                            >
                                {item.value}
                            </a>
                        );
                    })}
                </section>
            ))}
            <section className="flex flex-col justify-center lg:justify-start">
                <h5 className="text-lg font-bold">Shop On Our App</h5>
                <Divider />
                <a
                    className="mx-auto mt-1 mb-2 lg:items-start lg:mx-0"
                    href="https://apps.apple.com/us/app/raiseright-fundraising/id1498234012"
                    target="_blank"
                >
                    <img src={AppStoreSrc} />
                </a>
                <a
                    className="mx-auto lg:mx-0"
                    href="https://play.google.com/store/apps/details?id=com.shopwithscrip.app&hl=en_US&gl=US"
                    target="_blank"
                >
                    <img src={GooglePlaySrc} />
                </a>
                <div className="mt-5">
                    <a target="_blank" href="https://www.facebook.com/RaiseRightFundraising">
                        <Facebook className="inline-block mr-4" />
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/raiseright/">
                        <LinkedIn className="inline-block mr-4" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@raiseright">
                        <Youtube className="inline-block mr-4" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/raiserightfundraising/">
                        <Instagram className="inline-block" />
                    </a>
                </div>
            </section>
        </div>
        <div className="flex flex-col md:flex-row gap-6 mt-8">
            <div className="self-center" style={{ minWidth: '141px' }}>
                <a
                    target="_blank"
                    href="https://www.bbb.org/us/mi/grand-rapids/profile/fundraising-counselors-and-organizations/raiseright-llc-0372-13002078"
                >
                    <img src={imgBBB} />
                </a>
            </div>
            <div>
                <p className={`mb-2 ${styles.extraSmallText}`}>
                    COPYRIGHT © 1999 - {new Date().getFullYear()} RAISERIGHT. ALL RIGHTS RESERVED.
                </p>
                <p className={styles.extraSmallText}>
                    The merchants represented are not sponsors of RaiseRight or otherwise affiliated
                    with RaiseRight. The Logos and other identifying marks on this website are
                    trademarks of and owned by each represented company and/or its affiliates.
                    Please visit each company’s website for additional terms and conditions.
                </p>
            </div>
        </div>
    </footer>
);
