import React, { useRef, useContext } from 'react';

import { EnrollmentContext } from '../../context';

import { ECheckSecondary } from './ECheckBox';
import { LinkABankSection } from './LinkABankSection';

import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { StepWrapper, StepWrapperProps } from 'features/coordinator/enrollment/steps/StepWrapper';
import { setBillingSelection, BillingSelection } from 'common/api/e-comm/payment-types';
import { withRetries } from 'common/utils/withRetries';
import { SERVICE_UNAVAILABLE_MESSAGE } from 'config';
import { useAlert } from 'modules/alerts';

const Heading = ({ children }: { children: React.ReactNode }) => {
    return <p className="text-2xl text-brand-dark font-semibold my-5">{children}</p>;
};

const ECheckSection = ({ loader }: { loader: React.RefObject<LoadingOverlay> }) => {
    const { organization, goToCompletedEnrollmentStep } = useContext(EnrollmentContext);
    const alert = useAlert();

    // If user prefers to fund by eCheck then set their billing to eCheck and
    // redirect to the completed enrollment page
    const setBillingToECheck = async () => {
        openLoader(loader?.current as LoadingOverlay);
        const result = await withRetries(setBillingSelection)(
            organization.id as string,
            BillingSelection.ECheck
        );
        await closeLoader(loader?.current as LoadingOverlay);
        if (result.error) {
            alert({ message: SERVICE_UNAVAILABLE_MESSAGE });
            return;
        }
        goToCompletedEnrollmentStep();
    };
    return (
        <ECheckSecondary header="eCheck">
            <button
                className="text-gray-1 underline text-xs block m-auto"
                onClick={setBillingToECheck}
            >
                I prefer to receive funds by eCheck
            </button>
        </ECheckSecondary>
    );
};

interface LiteEarningsStep1Props extends StepWrapperProps {}

export const LiteEarningsStep1 = (props: LiteEarningsStep1Props) => {
    const loader = useRef<LoadingOverlay>(null);
    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <Heading>How will your organization receive funds?</Heading>
            <LinkABankSection loader={loader} />
            <ECheckSection loader={loader} />
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
