import { SearchApiQueryParams } from '.';

import { SEARCH_URL } from 'common/api/config';
import { BrandOrderByType } from 'common/api/search/models/OrderByType';

export const SEARCH_API_URL = `${SEARCH_URL}/indexes/brands/docs/search`;
export const SUGGEST_API_URL = `${SEARCH_URL}/indexes/brands/docs/suggest`;

export const defaultBrandSearchOptions: Partial<SearchApiQueryParams> = {
    orderby: BrandOrderByType.PopularityRank,
};
