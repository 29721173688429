import { ContentfulBrand } from '../models/ContentfulBrand';

import { contentfulApi } from 'common/api/utils/contentful';

export const getContentfulBrand = async (brandId: number): Promise<ContentfulBrand | null> => {
    const entries = await contentfulApi.getEntries<ContentfulBrand, unknown>({
        'fields.brandId': brandId,
        content_type: 'brand', // eslint-disable-line camelcase
    });
    if (!entries.data || !entries.data.items.length) {
        return null;
    }
    const brand = entries.data.items[0];
    return brand.fields;
};

export const getContentfulBrandApi = async (brandId: number) => {
    return await contentfulApi.getEntries<ContentfulBrand, unknown>({
        'fields.brandId': brandId,
        content_type: 'brand', // eslint-disable-line camelcase
    });
};
