import React from 'react';
import { keyframes } from 'glamor';
import classname from 'classnames';

import { createStyles } from 'utils/createStyle';

const duration = 1.4;

const rotator = keyframes({
    '100%': { transform: 'rotate(360deg)' },
});

const dash = keyframes({
    '0%': {
        strokeDasharray: '1, 200',
        strokeDashoffset: '0',
    },
    '50%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: '-35px',
    },
    '100%': {
        strokeDasharray: '89, 200',
        strokeDashoffset: '-124px',
    },
});

const colorAnim = keyframes({
    '0%': { stroke: '#E6126A' },
    '20%': { stroke: '#F15A29' },
    '40%': { stroke: '#7E3F97' },
    '60%': { stroke: '#57BFE9' },
    '80%': { stroke: '#005E9D' },
    '100%': { stroke: '#E6126A' },
});

const styles = createStyles({
    spinner: {
        animation: `${rotator} ${duration}s linear infinite`,
    },
    path: {
        strokeDasharray: '1, 200',
        strokeDashoffset: 0,
        transformOrigin: 'center',
        animation: [
            `${dash} ${duration}s ease-in-out infinite`,
            `${colorAnim} ${duration * 4}s ease-in-out infinite`,
        ].join(', '),
    },
});

export const ActivityIndicator = ({ width = '65px', ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...{ ...props, width }}
            className={classname(styles.spinner, props.className || '')}
            viewBox="25 25 50 50"
        >
            <circle
                className={styles.path}
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="4"
                strokeLinecap="round"
            />
        </svg>
    );
};
