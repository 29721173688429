import React, { useRef, useMemo, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { conformToMask } from 'react-text-mask';
import { useDispatch } from 'react-redux';

import { FormikCodeField } from '../../../../components/fields/FormikCodeField';
import { StepWrapperProps, StepWrapper } from '../../enrollment/steps/StepWrapper';
import { EnrollmentContext } from '../context';

import { Btn } from 'components/btn/Btn';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { confirmPhone } from 'common/api/users/twofactor/phone';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { useAlert } from 'modules/alerts';
import {
    verificationCodeError,
    verificationCodeValidator,
} from 'common/modules/form-validation/validators';
import { LoadingOverlay, closeLoader, openLoader } from 'components/loading-overlay/LoadingOverlay';
import { MASK_PHONE_US_LAST_4 } from 'common/modules/mask-text/masks';
import { updateEnrollmentProgress } from 'common/api/crm/organization/enrollment';
import { ProgressState } from 'common/api/crm/enums/ProgressState';
import { withRetries } from 'common/utils/withRetries';
import { useValidation } from 'modules/form-validation/useValidation';
import { updateAccount } from 'common/features/store/duck/account/duck';
import { ProgressIndicator } from 'features/coordinator/components/ProgressIndicator';

interface TwoFactorVerifyStepProps extends StepWrapperProps {}

interface VerifyCodeRequest {
    phoneId: string;
    code: string;
    blackBoxValue: string;
}

export const LitePlusTwoFactorVerifyStep = (props: TwoFactorVerifyStepProps) => {
    const { goToNextStep, onTryRequestingNewOne, twoFactorPhone } = useContext(EnrollmentContext);
    const { retrieveBlackBox } = useBlackBoxValues();
    const phoneId = twoFactorPhone.id;
    const alert = useAlert();

    const initialValues: VerifyCodeRequest = {
        phoneId,
        code: '',
        blackBoxValue: '',
    };

    const validateAndSubmit = useValidation<VerifyCodeRequest>();

    const loader = useRef<LoadingOverlay>(null);
    const dispatch = useDispatch<any>();
    const onSubmit = async (values: VerifyCodeRequest) => {
        openLoader(loader.current);
        const blackBoxValue = (await retrieveBlackBox()).blackBoxValue || '';
        const { code } = values;
        const result = await confirmPhone(phoneId, code, blackBoxValue);
        await closeLoader(loader.current);
        if (result.error) {
            alert({ message: verificationCodeError });
            return;
        }
        dispatch(updateAccount({ hasSetupTwoFactor: true }));
        withRetries(updateEnrollmentProgress)({ state: ProgressState.TwoFactorSetup, version: 2 });
        goToNextStep();
    };

    const subheadingData = useMemo(
        () => ({
            phoneNumber: conformToMask(
                twoFactorPhone.phoneNumber.slice(-4),
                MASK_PHONE_US_LAST_4,
                {}
            ).conformedValue,
        }),
        [twoFactorPhone]
    );

    // focus first form field
    useEffect(() => document.querySelector('input')?.focus(), []);

    return (
        <StepWrapper
            heading={props.heading}
            subheading={props.subheading}
            subheadingData={subheadingData}
        >
            <div className="mt-7 mb-4 text-2xl text-brand-dark font-bold">
                Enter Verification Code
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <form noValidate onSubmit={validateAndSubmit(formikProps)}>
                        <div className="max-w-sm">
                            <FormikCodeField
                                id="code"
                                name="code"
                                label={'6-Digit Verification Code'}
                                validate={verificationCodeValidator}
                                onTryRequestingNewOne={onTryRequestingNewOne}
                                hideRequestNewCode={false}
                                className=""
                            />
                            <FieldSpacer />
                        </div>
                        <div className="flex justify-center">
                            <Btn type="submit">Next: Your Organization</Btn>
                        </div>

                        <div className="w-44 m-auto mt-5">
                            <ProgressIndicator totalSteps={4} currentStep={4} />
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
