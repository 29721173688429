import { ConstructorReturnObject } from 'common/api/constructor/util';
import { findMaxRebate } from 'common/features/store/duck/brands/utils/rebates';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import lodash from 'lodash';

const camelCaseObj = (item: ConstructorReturnObject): Brand => {
    return {
        name: item['value'] || '',
        ...lodash.mapKeys(item['data'] as {}, (_, key) => {
            if (key === 'group_ids') {
                return 'suggestions';
            }
            return lodash.camelCase(key);
        }),
        isSponsored: item.is_slotted && item.labels?.is_sponsored,
    } as Brand;
};

export const convertConstructorItemToBrand = (item: ConstructorReturnObject) => {
    const camelCasedData = camelCaseObj(item);
    const rebate =
        (camelCasedData as unknown as { rebate: number[] }).rebate.length > 0
            ? findMaxRebate((camelCasedData as unknown as { rebate: number[] }).rebate)
            : 0;
    (camelCasedData as unknown as { rebate: number }).rebate = rebate;
    return camelCasedData as unknown as Brand;
};
