import { Dispatch } from 'redux';

import { ENABLE_DASHBOARD } from 'common/env';
import { createSlice } from 'common/modules/create-slice';
import { ECommFeatureFlags, getFeatureFlags } from 'common/api/e-comm/feature-flags';

export interface FeatureFlagsState {
    eComm: ECommFeatureFlags | null;
}

const initialFeatureFlagsState: FeatureFlagsState = {
    eComm: null,
};

const { update, reducer } = createSlice(initialFeatureFlagsState, 'FEATURE_FLAGS');
export const featureFlagsReducer = reducer;

export const getECommFeatureFlagsThunk = () => async (dispatch: Dispatch) => {
    const res = await getFeatureFlags();
    if (res.data) {
        dispatch(update({ eComm: res.data }));
    }
    return res;
};

export const selectEnableDashboard = (state: FeatureFlagsState): boolean | undefined => {
    return ENABLE_DASHBOARD === 'true' ? state.eComm?.enableDashboard : false;
};
