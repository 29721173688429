import { combineReducers } from 'redux';
import { categories, ConstructorCategoryStateType } from './categories/constructor/';

export type DataState = {
    categories: ConstructorCategoryStateType;
};

export const dataReducer = combineReducers<DataState>({
    categories,
});
