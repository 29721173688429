import { removeFirst } from 'common/utils';

export interface StorageStrategy {
    set(key: string, value: string): Promise<void> | void;
    get(key: string): Promise<string | null> | string | null;
    clear(key: string): Promise<void> | void;
}

export interface StorageService<T> {
    set(newValue: T): Promise<undefined | Error>;
    get(): Promise<T>;
    listen(listener: (newValue: T) => void): () => void;
}

export type Parser<T> = (response: string) => T;

export const createStorageService = (storage: StorageStrategy) => {
    return <T>(
        storageKey: string,
        version: string,
        initialValue: T,
        parse: Parser<T>
    ): StorageService<T> => {
        let listeners: Array<(newValue: T) => void> = [];
        let versionWasVerified = false;
        const versionKey = `${storageKey}.VERSION`;

        const clearIfOldVersion = async () => {
            if (versionWasVerified) {
                return;
            }
            const previouslyUsedVersion = await storage.get(versionKey);
            if (previouslyUsedVersion !== version) {
                await Promise.all([storage.clear(storageKey), storage.set(versionKey, version)]);
            }
            versionWasVerified = true;
        };

        return {
            async set(newValue: T): Promise<undefined | Error> {
                await clearIfOldVersion();

                try {
                    await storage.set(storageKey, JSON.stringify(newValue));
                    listeners.forEach((listener) => listener(newValue));
                } catch (e: Error | any) {
                    return e;
                }
            },
            async get(): Promise<T> {
                await clearIfOldVersion();

                try {
                    const response = await storage.get(storageKey);

                    if (!response) {
                        return initialValue;
                    }

                    return parse(response);
                } catch {
                    return initialValue;
                }
            },
            listen(listener: (newValue: T) => void) {
                listeners.push(listener);
                return () => {
                    listeners = removeFirst(listeners, listener);
                };
            },
        };
    };
};
