import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HomepageContext, HeroAd } from '../contexts/contentful';

import useMediaQueries from 'hooks/useMediaQueries';
import { createStyle } from 'utils/createStyle';
import { useIsInViewport } from 'hooks/useIsInViewport';
import { logClickAd, logViewAd, PromotionSources } from 'config/analytics';
import { CreativeSlotTypes } from 'common/types/CreativeSlotTypes';

const fullheroClasses = createStyle('w-full');

const FullHeroAdRenderer = ({
    hero,
    creativeSlot,
}: {
    hero: HeroAd;
    creativeSlot?: CreativeSlotTypes;
}) => {
    const [image, setImageTo] = useState<string>();
    const queries = useMemo(
        () => ({
            '(max-width: 767px)': () => {
                setImageTo(hero.mobileImage ?? hero.desktopImage);
            },
            '(min-width: 768px)': () => {
                setImageTo(hero.desktopImage);
            },
        }),
        [hero, setImageTo]
    );

    const adRef = useRef(null);
    const isAdRefInViewPort = useIsInViewport(adRef);

    useMediaQueries(queries);

    useEffect(() => {
        if (isAdRefInViewPort) {
            logViewAd({
                promotionName: hero.title || hero.id,
                index: 0,
                source: PromotionSources.Contentful,
                creativeSlot: creativeSlot as string,
            });
        }
    }, [isAdRefInViewPort, hero.title, hero.id, creativeSlot]);

    const onClickAd = useCallback(() => {
        logClickAd({
            promotionName: hero.title || hero.id,
            index: 0,
            source: PromotionSources.Contentful,
            creativeSlot: creativeSlot as string,
        });
    }, [hero, creativeSlot]);

    return (
        <div ref={adRef} className={fullheroClasses}>
            <a onClick={onClickAd} href={hero.url ?? '#'} className="block h-full">
                <img className="w-full" src={image} alt={hero.imageDescription ?? ''} />
            </a>
        </div>
    );
};

export const FullAdSection = ({
    id,
    creativeSlot,
}: {
    id: string;
    creativeSlot?: CreativeSlotTypes;
}) => {
    const { heroes } = useContext(HomepageContext);
    const hero = heroes[id] as HeroAd;

    return (
        <div>
            <FullHeroAdRenderer hero={hero} creativeSlot={creativeSlot} />
        </div>
    );
};
