import React, { useEffect, Fragment, useCallback, useRef, useContext } from 'react';
import { Route, Switch, useRouteMatch, useHistory, matchPath, useLocation } from 'react-router-dom';

import { ContentFullEnrollmentContext } from '../components/useContentfulEnrollment';

import LitePlusStepper from './steps/LitePlusStepper';
import { LitePlusTwoFactorStep } from './steps/LitePlusTwoFactorStep';
import { LitePlusTwoFactorVerifyStep } from './steps/LitePlusTwoFactorVerifyStep';
import { LitePlusOrganizationStep } from './steps/LitePlusOrganizationStep';
import { LitePlusOrganizationTypeStep } from './steps/LitePlusOrganizationTypeStep';
import { GettingYourRecommendationReady } from './steps/org-step/GettingYourRecommendationReady';
import { LitePlusInfoStep } from './steps/LitePlusInfoStep';
import { OrgEnrollCompleted } from './steps/OrgEnrollCompleted';
import { LitePlusGetStartedStep } from './steps/LitePlusGetStartedStep';

import { LiteEarningsStep1 } from 'features/coordinator/enroll/steps/receive-earnings/LiteEarningsStep1';
import { PlusEarningsStep1 } from 'features/coordinator/enroll/steps/receive-earnings/PlusEarningsStep1';
import { OrgProfileQuestion7 } from 'features/coordinator/enroll/steps/OrgProfileQuestion7';
import { OrgEnrollmentContinue } from 'features/coordinator/enroll/LitePlusOrgEnrollmentContinue';
import { YourAccountStep } from 'features/coordinator/enroll/steps/your-account-step';
import { OrgPeopleStep } from 'features/coordinator/enroll/steps/org-step/OrgPeopleStep';
import { OrgProfileQuestion1 } from 'features/coordinator/enroll/steps/OrgProfileQuestion1';
import { OrgProfileQuestion6 } from 'features/coordinator/enroll/steps/OrgProfileQuestion6';
import { OrgProfileQuestion2 } from 'features/coordinator/enroll/steps/OrgProfileQuestion2';
import { AccountCreated } from 'features/coordinator/enroll/steps/your-account-step/AccountCreated';
import { ChoosePlanStep } from 'features/coordinator/enroll/steps/choose-plan-step/ChoosePlanStep';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { Routes } from 'routes';
import { createStyles } from 'utils/createStyle';
import logo from 'media/images/ShopWithScrip_Logo.svg';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useConfirmUnload } from 'hooks/useConfirmUnload';
import { Head } from 'components/head/Head';
import { EnrollmentFlow } from 'features/coordinator/enroll/context';
import { VoidedCheckStep } from 'features/coordinator/enroll/steps/VoidedCheckStep';
import { OrganizationEnrollment } from 'common/api/contentful/models/OrganizationEnrollment';
import {
    faUser as faUser,
    faClipboardListCheck,
    faHeart,
    faDollarSign,
    faHome,
} from '@fortawesome/pro-light-svg-icons';
import {
    faUser as faUserSelected,
    faClipboardListCheck as faClipboardListCheckSelected,
    faHeart as faHeartSelected,
    faDollarSign as faDollarSignSelected,
    faHome as faHomeSelected,
} from '@fortawesome/pro-solid-svg-icons';
import { IStep } from './steps/LitePlusStepper/LitePlusStepper';

const styles = createStyles({
    masthead: 'shadow-lg flex justify-center pt-5 pb-3 sm:pt-6 sm:pb-4',
    logo: 'w-40 sm:w-60',
});

const personalAccountRoutes = [
    Routes.EnrollCreateAccount,
    Routes.EnrollSetupRecoveryPhone,
    Routes.EnrollVerifyRecoveryPhone,
];

const orgProfileRoutes = [
    Routes.EnrollOrgName,
    Routes.EnrollOrgType,
    Routes.EnrollOrgQuestion1,
    Routes.EnrollOrgPeople,
    Routes.EnrollOrgQuestion2,
    Routes.EnrollOrgFundraisingReason,
    Routes.EnrollOrgProfile,
    Routes.EnrollOrgCreateLoadingPage,
];

const receiveEarningsRoutes = [
    Routes.EnrollChooseEarningTypeLite,
    Routes.EnrollChooseEarningTypePlus,
    Routes.EnrollLiteProgramInfo,
    Routes.EnrollSendVoidedCheck,
    Routes.EnrollCompleted,
];

const steps: IStep[] = [
    {
        title: 'Get Started',
        icon: faHome,
        iconSelected: faHomeSelected,
        routes: [Routes.EnrollStartProgram],
        exact: true,
    },
    {
        title: 'Personal Account',
        icon: faUser,
        iconSelected: faUserSelected,
        routes: personalAccountRoutes,
        exact: false,
    },
    {
        title: 'Organization Profile',
        icon: faHeart,
        iconSelected: faHeartSelected,
        routes: orgProfileRoutes,
        exact: false,
    },
    {
        title: 'Program Type',
        icon: faClipboardListCheck,
        iconSelected: faClipboardListCheckSelected,
        routes: [Routes.EnrollChooseYourPlan],
        exact: false,
    },
    {
        title: 'Receive Earnings',
        icon: faDollarSign,
        iconSelected: faDollarSignSelected,
        routes: receiveEarningsRoutes,
        exact: false,
    },
];

const LitePlusOrgEnrollScreen = () => {
    useScrollToTop();
    const { pathname } = useLocation();
    useConfirmUnload(
        useCallback(
            (nextLocation) => {
                const routesThatShouldNotPrompt = [
                    Routes.Enroll,
                    Routes.EnrollStartProgram,
                    Routes.EnrollCreateAccount,
                    Routes.EnrollContinue,
                    Routes.EnrollCompleted,
                    Routes.EnrollSendVoidedCheck,
                    Routes.EnrollChooseEarningTypeLite,
                    Routes.EnrollChooseEarningTypePlus,
                ];
                if (
                    routesThatShouldNotPrompt.some((path) =>
                        matchPath(pathname, { path, exact: true })
                    )
                ) {
                    return false;
                }
                if (nextLocation) {
                    const isExitingEnrollment = !matchPath(nextLocation.pathname, {
                        path: Routes.EnrollStartProgram,
                    });
                    return isExitingEnrollment;
                }
                return true;
            },
            [pathname]
        )
    );

    const isMounted = useRef(false);
    const history = useHistory();

    useEffect(() => {
        if (isMounted.current) {
            return;
        }
        isMounted.current = true;
        if (pathname === Routes.EnrollStartProgram || pathname === Routes.EnrollContinue) {
            return;
        }

        history.replace(Routes.EnrollStartProgram);
    }, [history, pathname]);

    const { contentfulEntry, isLoading: isLoadingOrgEnrollment } = useContext(
        ContentFullEnrollmentContext
    );

    const orgEnrollment = contentfulEntry as OrganizationEnrollment;

    const isCompleted = useRouteMatch({
        path: Routes.EnrollCompleted,
        sensitive: false,
        exact: true,
    });

    const isVoidedCheck = useRouteMatch({
        path: Routes.EnrollSendVoidedCheck,
        sensitive: false,
        exact: true,
    });

    const renderBody = () => {
        if (isLoadingOrgEnrollment) {
            return (
                <div className="mt-12 justify-center flex">
                    <ActivityIndicator />
                </div>
            );
        }

        if (!orgEnrollment) {
            return;
        }

        return (
            <EnrollmentFlow>
                <Switch>
                    <Route path={Routes.EnrollStartProgram} exact>
                        <LitePlusGetStartedStep
                            heading={orgEnrollment.landingPageHeading}
                            subheading={orgEnrollment.landingPageSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollContinue}>
                        <OrgEnrollmentContinue />
                    </Route>
                    <Route path={Routes.EnrollCreateAccount} exact>
                        <YourAccountStep
                            heading={orgEnrollment.createAccountHeading}
                            subheading={orgEnrollment.createAccountSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollSetupRecoveryPhone}>
                        <LitePlusTwoFactorStep
                            heading={orgEnrollment.twoFactorPhoneHeading}
                            subheading={orgEnrollment.twoFactorPhoneSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollVerifyRecoveryPhone}>
                        <LitePlusTwoFactorVerifyStep
                            heading={orgEnrollment.twoFactorPhoneVerifyHeading}
                            subheading={orgEnrollment.twoFactorPhoneVerifySubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollUserCreateLoadingPage}>
                        <AccountCreated />
                    </Route>
                    <Route path={Routes.EnrollOrgName}>
                        <LitePlusOrganizationStep
                            heading={orgEnrollment.organizationHeading}
                            subheading={orgEnrollment.organizationSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollOrgType}>
                        <LitePlusOrganizationTypeStep
                            heading={orgEnrollment.organizationHeading}
                            subheading={orgEnrollment.organizationSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollOrgQuestion1}>
                        <OrgProfileQuestion1 />
                    </Route>
                    <Route path={Routes.EnrollOrgPeople}>
                        <OrgPeopleStep />
                    </Route>
                    <Route path={Routes.EnrollOrgQuestion2}>
                        <OrgProfileQuestion2 />
                    </Route>
                    <Route path={Routes.EnrollOrgFundraisingReason}>
                        <OrgProfileQuestion6 />
                    </Route>
                    <Route path={Routes.EnrollChooseYourPlan}>
                        <ChoosePlanStep />
                    </Route>
                    <Route path={Routes.EnrollOrgProfile}>
                        <OrgProfileQuestion7
                            heading={orgEnrollment.organization2Heading}
                            subheading={orgEnrollment.organization2Subheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollOrgCreateLoadingPage}>
                        <GettingYourRecommendationReady />
                    </Route>
                    <Route path={Routes.EnrollChooseEarningTypePlus}>
                        <PlusEarningsStep1
                            heading={orgEnrollment.earningsHeading}
                            subheading={orgEnrollment.earningsSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollChooseEarningTypeLite}>
                        <LiteEarningsStep1
                            heading={orgEnrollment.earningsHeading}
                            subheading={orgEnrollment.earningsSubheading}
                        />
                    </Route>
                    <Route path={Routes.EnrollLiteProgramInfo}>
                        <LitePlusInfoStep />
                    </Route>
                    <Route path={Routes.EnrollSendVoidedCheck}>
                        <VoidedCheckStep />
                    </Route>
                    <Route path={Routes.EnrollCompleted}>
                        <OrgEnrollCompleted curatedArticles={orgEnrollment.curatedArticleButtons} />
                    </Route>
                </Switch>
            </EnrollmentFlow>
        );
    };

    return (
        <Fragment>
            <Head title="Program Enrollment" />
            <header className={styles.masthead}>
                <img className={styles.logo} src={logo} alt="ShopWithScrip" />
            </header>
            <main>
                {!isCompleted && !isVoidedCheck && <LitePlusStepper steps={steps} />}
                {renderBody()}
            </main>
        </Fragment>
    );
};

export default LitePlusOrgEnrollScreen;
