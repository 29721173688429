import React from 'react';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { styles } from './styles';

export const OrgChangeLoader = () => {
    return (
        <div className="h-full flex justify-center flex-col text-center p-2">
            <div className={styles.header}>Just a moment while we change your organization.</div>
            <div className="mx-auto mt-16">
                <ActivityIndicator />
            </div>
        </div>
    );
};
