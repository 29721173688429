import { Dispatch } from 'redux';
import { ProductCatalog } from 'common/api/store/catalog/models/ProductCatalog';
import { AsyncActionState, initialAsyncActionState } from 'common/modules/async-actions/core';
import { createSlice } from 'common/modules/create-slice';
import { createApiThunk } from 'common/modules/async-actions/thunk';
import { getProductCatalog } from 'common/api/store/catalog';
import { FeaturesState } from 'common/features/featuresReducer';

export interface CatalogState {
    giftCardsTabProductCatalog: ProductCatalog | null;
    shopOnlineTabProductCatalog: ProductCatalog | null;
    productCatalogAsyncState: AsyncActionState;
}

export const initialCatalogState: CatalogState = {
    giftCardsTabProductCatalog: null,
    shopOnlineTabProductCatalog: null,
    productCatalogAsyncState: initialAsyncActionState,
};

const { update, reducer } = createSlice(initialCatalogState, 'CATALOG');
export const catalogReducer = reducer;

export const requestProductCatalog = createApiThunk(getProductCatalog, () => (state, result) => {
    return result
        ? update({ giftCardsTabProductCatalog: result.fields, productCatalogAsyncState: state })
        : update({ productCatalogAsyncState: state });
});

export const requestShopOnlineProductCatalog = (entryId: string) => async (dispatch: Dispatch) => {
    if (!entryId) {
        return;
    }

    const result = await getProductCatalog(entryId);
    if (result.data) {
        dispatch(
            update({
                shopOnlineTabProductCatalog: result.data.fields,
            })
        );
    }
};

export const selectProductCatalog = (state: FeaturesState) =>
    state.store.catalog.giftCardsTabProductCatalog;
export const selectProductCatalogAsyncState = (state: FeaturesState) =>
    state.store.catalog.productCatalogAsyncState;
export const selectShopOnlineTabProductCatalog = (state: FeaturesState) =>
    state.store.catalog.shopOnlineTabProductCatalog;
