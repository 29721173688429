import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { FormikTextField } from 'components/fields/FormikTextField';
import { Organization } from 'common/api/crm/models/Organization';
import { orgWebsiteValidator } from 'features/coordinator/enrollment/steps/validators';
import { Checkbox } from 'components/checkbox/Checkbox';

// NOTE: expand the interface as __needed__
interface OrganizationWebsiteFieldProps {
    label: React.ReactChild;
}

export const OrganizationWebsiteField = ({ label }: OrganizationWebsiteFieldProps) => {
    const { setFieldError } = useFormikContext();
    const [orgDoesntHaveWebsite, setOrgDoesntHaveWebsite] = useState<boolean>(false);
    const toggleCheckbox = () => {
        setOrgDoesntHaveWebsite((state: boolean) => !state);
        setFieldError('website', '');
    };

    return (
        <>
            <FormikTextField<Organization>
                name="website"
                label={label}
                validate={orgDoesntHaveWebsite ? undefined : orgWebsiteValidator}
                placeholder="www.organizationwebsite.com"
            />
            <Checkbox
                id="orgDoesNotHaveWebsite"
                checked={orgDoesntHaveWebsite}
                text="My organization doesn’t have a website"
                className="text-grey-1 font-bold mt-1 text-xs"
                onChange={toggleCheckbox}
            />
        </>
    );
};
