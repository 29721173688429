import React, { useState, useMemo } from 'react';

import useMediaQueries from 'hooks/useMediaQueries';

export enum ContainerType {
    mobile = 'mobile',
    desktop = 'desktop',
}

const ResponsiveContainer = ({
    For,
    defaultVisibility = false,
    children,
}: {
    For: ContainerType.mobile | ContainerType.desktop;
    defaultVisibility?: boolean;
    children: React.ReactNode;
}) => {
    const [isVisible, setIsVisible] = useState(defaultVisibility);

    const query = useMemo(
        () => ({
            '(max-width: 640px)': () => {
                setIsVisible(For === ContainerType.mobile);
            },
            '(min-width: 640px)': () => {
                setIsVisible(For === ContainerType.desktop);
            },
        }),
        [For]
    );

    useMediaQueries(query);

    return isVisible ? <>{children}</> : null;
};

export default ResponsiveContainer;
