import React from 'react';

import { RoundedBox, RoundBoxStyles } from './RoundedBox';

interface ECheckBoxProps {
    styles: RoundBoxStyles;
    heading: React.ReactNode;
    children: React.ReactNode;
}

export const ECheckBox = ({ styles, heading, children }: ECheckBoxProps) => {
    return (
        <RoundedBox styles={styles} heading={heading}>
            <ul className="px-5 sm:px-10 list-disc text-sm text-grey-1 ml-5 mb-5">
                <li>Get earnings via eCheck</li>
                <li>We email you a check, then you deposit into your bank</li>
                <li>Get paid every other month if earnings are over $20</li>
                <li>Good option if you can't link a bank account</li>
            </ul>
            <div className="px-2">{children}</div>
        </RoundedBox>
    );
};

interface ECheckImpl {
    header: React.ReactNode;
    children: React.ReactNode;
}

export const ECheckSecondary = ({ header, children }: ECheckImpl) => {
    const styles: RoundBoxStyles = {
        container: 'border-grey-1',
        header: 'bg-grey-6',
        background: 'bg-white',
    };
    const heading = <div className="font-semibold text-grey-2 text-xl">{header}</div>;
    return (
        <ECheckBox styles={styles} heading={heading}>
            {children}
        </ECheckBox>
    );
};

export const ECheckPrimary = ({ header, children }: ECheckImpl) => {
    const styles: RoundBoxStyles = {
        container: 'border-brand shadow-md',
        header: 'bg-brand',
        background: 'bg-pale-blue',
    };
    const heading = <div className="font-semibold text-white text-2xl">{header}</div>;
    return (
        <ECheckBox styles={styles} heading={heading}>
            {children}
        </ECheckBox>
    );
};
