import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { FormikTextField } from 'components/fields/FormikTextField';
import { Organization } from 'common/api/crm/models/Organization';
import { orgWebsiteValidatorV2 } from 'features/coordinator/enrollment/steps/validators';
import { Checkbox } from 'components/checkbox/Checkbox';
import { omit } from 'lodash';

// NOTE: expand the interface as __needed__
interface OrganizationWebsiteFieldProps {
    label: React.ReactChild;
}

export const OrganizationWebsiteFieldV2 = ({ label }: OrganizationWebsiteFieldProps) => {
    const { setErrors, setFieldValue, values, errors } = useFormikContext<Partial<Organization>>();
    const [orgDoesntHaveWebsite, setOrgDoesntHaveWebsite] = useState<boolean>(
        values?.website === '' || false
    );
    const toggleCheckbox = (isChecked: boolean) => {
        setOrgDoesntHaveWebsite(isChecked);

        if (!orgDoesntHaveWebsite) {
            const newErrors = omit(errors, 'website');
            setErrors(newErrors);
            setFieldValue('website', '', false);
            return;
        }
    };

    return (
        <>
            <FormikTextField<Organization>
                disabled={orgDoesntHaveWebsite}
                name="website"
                label={label}
                validate={orgDoesntHaveWebsite ? undefined : orgWebsiteValidatorV2}
                placeholder="Ex: https://www.spartanathletics.com"
            />
            <Checkbox
                id="orgDoesNotHaveWebsite"
                checked={orgDoesntHaveWebsite}
                text="My organization does not have a website or a social media page"
                className="text-brand-dark font-normal mt-5 text-sm"
                labelClassName="text-brand"
                onChange={toggleCheckbox}
            />
        </>
    );
};
