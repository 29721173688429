import { Organization } from '../models/Organization';
import { OrganizationType } from '../models/OrganizationType';
import { OrganizationDetail } from '../models/OrganizationDetail';

import { withTokensIfNeeded } from 'common/api/utils/withTokensIfNeeded';
import {
    CRM_ORGS_URL,
    FAMILY_URL,
    ORGANIZATION_URL,
    SECURED_ORGANIZATION_URL,
} from 'common/api/config';
import { apiFetch, normalizeResponse } from 'common/api/utils';
import { Checklist, ChecklistResponse, ToggleResponse } from './checklist/models';

export const getOrganizationTypes = async () => {
    const res = await apiFetch<OrganizationType[]>(`${ORGANIZATION_URL}/types?api-version=1`);
    return res;
};

type createOrganizationRequest = Organization & {
    blackBoxValue?: string | null;
    secondaryBlackBoxValue?: string | null;
};

export const createOrganization = async (organization: createOrganizationRequest) => {
    const res = await withTokensIfNeeded<OrganizationDetail>(
        `${SECURED_ORGANIZATION_URL}?api-version=1`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(organization),
        }
    );
    return res;
};

export const getOrganization = async (id: string) => {
    const res = await withTokensIfNeeded<Organization>(
        `${SECURED_ORGANIZATION_URL}/${id}?api-version=1`
    );
    return res;
};

export const getOrganizationDetail = async (id: string) => {
    const res = await withTokensIfNeeded<OrganizationDetail>(
        `${SECURED_ORGANIZATION_URL}/${id}/details?api-version=1`
    );
    return res;
};

export const updateOrganization = async (organization: Organization) => {
    const res = await withTokensIfNeeded<OrganizationDetail>(
        `${CRM_ORGS_URL}/${organization.id}?api-version=1`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(organization),
        }
    );
    return res;
};

export async function fetchChecklistItems(organizationId: string | number): Promise<Checklist[]> {
    const url = `${CRM_ORGS_URL}/${organizationId}/checklist?api-version=1`;

    const response = await withTokensIfNeeded<ChecklistResponse>(url, {
        method: 'GET',
        headers: {
            contentType: 'application/json',
        },
    });

    const normalizedResponse = normalizeResponse(response);
    if (normalizedResponse.error) {
        throw new Error(normalizedResponse.error as unknown as string);
    }

    return normalizedResponse.data as unknown as Checklist[];
}

export async function setCoordinatorChecklistVisibility(
    organizationId: string | number,
    showCoordinatorChecklist: boolean
): Promise<void> {
    const url = `${CRM_ORGS_URL}/${organizationId}/coordinator?api-version=1`;
    const body = {
        showCoordinatorChecklist: showCoordinatorChecklist,
    };

    const response = await withTokensIfNeeded<void>(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    const normalizedResponse = normalizeResponse(response);
    if (normalizedResponse.error) {
        throw new Error(normalizedResponse.error as unknown as string);
    }
}

export async function toggleCheckItem(item: Checklist): Promise<ToggleResponse> {
    const response = await withTokensIfNeeded<ToggleResponse>(
        `${FAMILY_URL}/checklist-item/${item.id}/toggle?api-version=1`,
        {
            method: 'PATCH',
        }
    );

    if (!response || !response.data) {
        throw new Error('No response');
    }

    if (response.data.result !== 'Success') {
        throw new Error(`Error toggling item: ${response.data.result}`);
    }

    return response.data;
}
