import React from 'react';

import { Btn, BtnProps } from 'components/btn/Btn';
import classNames from 'classnames';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';

type FormBtnProps = {
    isSubmitting: boolean;
} & BtnProps;

export const FormBtn = ({ children, isSubmitting, disabled = false, ...props }: FormBtnProps) => {
    return (
        <div className="inline-block relative">
            <div className={classNames('inline-block', !isSubmitting ? 'visible2' : 'invisible2')}>
                <Btn {...props} disabled={isSubmitting || disabled}>
                    <div className="flex items-center justify-center">
                        {isSubmitting && (
                            <span className="-ml-4 inline-block mr-2">
                                <ActivityIndicator width="20px" />
                            </span>
                        )}
                        <span>{children}</span>
                    </div>
                </Btn>
            </div>
        </div>
    );
};
