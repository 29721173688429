import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes } from 'routes';
import LandingPage, { LandingPageRenderers } from '../LandingPage';
import { defaultConfigKeys } from 'common/modules/remote-config/defaultConfigKeys';
import { useRootSelector } from 'rootStore';
import { brandsSearchByPrefix } from 'common/features/store/duck/search-generic/duck';

export interface BaseLandingPageProps {
    firebaseConfigKey: keyof typeof defaultConfigKeys;
    filter?: string;
    renderBonusSwitch?: boolean;
    renderers?: LandingPageRenderers;
    showEmptyResultsView?: boolean;
}

export const BaseLandingPage = ({
    firebaseConfigKey,
    children,
    renderBonusSwitch,
    filter,
    renderers,
    showEmptyResultsView = true,
}: React.PropsWithChildren<BaseLandingPageProps>) => {
    const { pageBrands, loading } = useRootSelector((state) => state.store.searchGrid);
    const dispatch = useDispatch<any>();
    useEffect(() => {
        return () => {
            dispatch(brandsSearchByPrefix.resetState(undefined));
        };
    }, []);
    return (
        <LandingPage
            returnRoute={Routes.Brands}
            landingPageType={firebaseConfigKey}
            renderBonusSwitch={renderBonusSwitch}
            renderers={renderers}
            fixedFilter={filter}
            isFilterVisible={showEmptyResultsView ? !loading : !loading && pageBrands.length > 0}
            showEmptyResultsView={showEmptyResultsView}
        >
            {children}
        </LandingPage>
    );
};
