import { constructorInstance } from 'common/api/constructor/constructor-instance';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from 'common/features/store/duck/account/duck';
import { useConstructorSessionCounter } from './useConstructorSessionCounter';
import { useClientId } from './useClientId';

export const ConstructorPersonalizationWatcher = () => {
    const userId = useSelector(selectUserId);
    const sessionId = useConstructorSessionCounter();
    const clientId = useClientId();

    useEffect(() => {
        (async () => {
            if (sessionId <= 0 || !clientId) {
                return;
            }

            const clientOptions = {
                clientId,
                userId: userId,
                sessionId: sessionId as unknown as string,
                sendTrackingEvents: true,
                apiKey: process.env.REACT_APP_CONSTRUCTOR_KEY || 'none',
            };

            constructorInstance.setClientOptions(clientOptions);

            // NOTE: Session start events are sent when a new session starts.
            constructorInstance.tracker.trackSessionStart();
        })();
    }, [userId, sessionId, clientId]);

    return null;
};
