import React from 'react';
import * as RuiDropdownMenu from '@radix-ui/react-dropdown-menu';

interface Props<T> {
    items: T[];
    onSelect: (itemClicked: T) => void;
    renderers?: { [key: string]: React.ElementType };
    children: React.ReactNode;
}

const ItemRenderer = <ObjectType extends { text: string }>({
    item,
    onSelect,
}: {
    item: ObjectType;
    onSelect: (itemClicked: ObjectType) => void;
}) => (
    <RuiDropdownMenu.Item
        key={item.text}
        className="block px-4 py-2 no-underline capitalize text-brand hover:underline focus:underline cursor-pointer"
        onSelect={() => onSelect(item)}
    >
        {item.text}
    </RuiDropdownMenu.Item>
);

const ItemsRenderer = <T,>({
    items = [],
    renderers,
    onSelect,
}: {
    items: T[];
    renderers: { [key: string]: React.ElementType };
    onSelect: (d: T) => void;
}) => {
    return (
        <div>
            {items.map((item: T, idx: number) => (
                <renderers.ItemRenderer key={idx} item={item} onSelect={onSelect} />
            ))}
        </div>
    );
};

const renderers_ = {
    ItemRenderer,
    ItemsRenderer,
};

export const DropdownMenu = <T,>({ items, onSelect, renderers = {}, children }: Props<T>) => {
    const components = { ...renderers_, ...renderers };

    return (
        <RuiDropdownMenu.Root>
            <RuiDropdownMenu.Trigger className="inline-block">{children}</RuiDropdownMenu.Trigger>
            <RuiDropdownMenu.Content
                className="bg-white shadow-xl border-solid border-1 rounded-lg border-brand z-10"
                align="start"
            >
                <components.ItemsRenderer
                    renderers={components}
                    items={items || []}
                    onSelect={onSelect}
                />
            </RuiDropdownMenu.Content>
        </RuiDropdownMenu.Root>
    );
};
