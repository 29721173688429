import React, { useCallback, useRef, useMemo, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';

import { StepWrapperProps, StepWrapper } from '../../enrollment/steps/StepWrapper';
import { EnrollmentContext } from '../context';

import { AddPhoneRequest, addPhone, DeliveryMethod } from 'common/api/users/twofactor/phone';
import { Btn } from 'components/btn/Btn';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { MASK_PHONE_US } from 'common/modules/mask-text/masks';
import { FormikTextField } from 'components/fields/FormikTextField';
import { typedFormikField } from 'components/fields/utils/typedFormikField';
import {
    twoFactorPhoneNumberValidator,
    twoFactorLockoutError,
} from 'common/modules/form-validation/validators';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { useAlert } from 'modules/alerts';
import { hasErrorWithStatus } from 'common/api/utils/hasErrorWithStatus';
import { StatusCode } from 'common/api/config';
import { useValidation } from 'modules/form-validation/useValidation';
import { ProgressIndicator } from 'features/coordinator/components/ProgressIndicator';

interface LitePlusTwoFactorStepProps extends StepWrapperProps {}

type FormValues = Omit<AddPhoneRequest, 'deliveryMethod'>;

const AddPhoneRequestField = typedFormikField<FormValues>()(FormikTextField);

export const LitePlusTwoFactorStep = (props: LitePlusTwoFactorStepProps) => {
    const { setTwoFactorPhone, goToNextStep, userInformation } = useContext(EnrollmentContext);

    const validateAndSubmit = useValidation<FormValues>();
    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();
    const deliveryMethodRef = useRef(DeliveryMethod.Text);

    const onSubmit = useCallback(
        async (values: FormValues) => {
            openLoader(loader.current);
            const result = await addPhone({ ...values, deliveryMethod: deliveryMethodRef.current });
            await closeLoader(loader.current);
            if (result.error) {
                alert({
                    message: hasErrorWithStatus(result, StatusCode.TooManyRequests)
                        ? twoFactorLockoutError
                        : `Failed to send verification code to ${values.phoneNumber}. Please try again.`,
                });
                return;
            }

            // save the two factor phone and transition to next step
            setTwoFactorPhone({ id: result.data.phoneId, phoneNumber: values.phoneNumber });
            goToNextStep();
        },
        [alert, goToNextStep, setTwoFactorPhone]
    );

    const initialValues: FormValues = useMemo(
        () => ({
            phoneNumber: userInformation.phoneNumber,
        }),
        [userInformation.phoneNumber]
    );

    // focus first form field
    useEffect(() => document.querySelector('input')?.select(), []);

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <div className="mt-7 text-2xl text-brand-dark font-bold">
                Set up your recovery phone
            </div>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <form noValidate onSubmit={validateAndSubmit(formikProps)}>
                        <div className="max-w-sm">
                            <FieldSpacer />
                            <MaskedInput
                                mask={MASK_PHONE_US}
                                guide={false}
                                render={(ref, _props) => (
                                    <AddPhoneRequestField
                                        {..._props}
                                        // @ts-ignore
                                        inputRef={ref}
                                        type="tel"
                                        name="phoneNumber"
                                        label="Phone Number (Mobile preferred)"
                                        validate={twoFactorPhoneNumberValidator}
                                    />
                                )}
                            />
                            <FieldSpacer />
                        </div>
                        <div className="flex justify-center items-center">
                            <Btn
                                type="button"
                                isSecondary={true}
                                className="whitespace-nowrap"
                                onClick={() => {
                                    deliveryMethodRef.current = DeliveryMethod.Voice;
                                    formikProps.submitForm();
                                }}
                            >
                                Call Me
                            </Btn>
                            <span className="text-brand-dark text-lg mx-3">Or</span>
                            <Btn
                                type="submit"
                                className="whitespace-nowrap"
                                onClick={() => {
                                    deliveryMethodRef.current = DeliveryMethod.Text;
                                }}
                            >
                                Text Me
                            </Btn>
                        </div>

                        <div className="w-44 m-auto mt-5">
                            <ProgressIndicator totalSteps={4} currentStep={3} />
                        </div>
                    </form>
                )}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
