import React from 'react';

import { ProgressIndicator } from 'features/coordinator/components/ProgressIndicator';
import { createStyle } from 'utils/createStyle';

const style = createStyle('max-w-2xl mx-auto px-4 sm:px-10 mt-4 sm:mt-8');

interface OrgProfileQuestionWrapperProps {
    children: React.ReactNode;
    stepProgress: number[];
}

export const OrgProfileQuestionWrapper = ({
    children,
    stepProgress,
}: OrgProfileQuestionWrapperProps) => {
    const [currentStep, totalSteps] = stepProgress;
    return (
        <div className={style}>
            {children}
            <div className="w-48 m-auto">
                <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />
            </div>
        </div>
    );
};
