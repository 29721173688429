import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import merge from 'lodash.merge';
import ReCAPTCHA from 'react-google-recaptcha';

import { StepWrapperProps, StepWrapper } from './StepWrapper';

import { Btn } from 'components/btn/Btn';
import {
    RegistrationRequestWithoutOrg,
    EnrollSourceApplicationIds,
} from 'common/api/auth/models/RegistrationRequestWithoutOrg';
import { ActivityIndicator } from 'components/activity-indicator/ActivityIndicator';
import { FormikSelectField } from 'components/fields/FormikSelectField';
import {
    securityQuestion1Validator,
    securityQuestion2Validator,
    securityQuestionAnswerValidator,
} from 'common/modules/form-validation/validators';
import { flattenObject } from 'common/utils/flattenObject';
import { FieldSpacer } from 'components/fields/FieldSpacer';
import { FormikTextField } from 'components/fields/FormikTextField';
import { fromEntries } from 'common/utils';
import { useBlackBoxValues } from 'modules/fraud-force/fraudForce';
import { LoadingOverlay, openLoader, closeLoader } from 'components/loading-overlay/LoadingOverlay';
import { registerWithoutOrg } from 'common/api/users/service';
import { useAlert } from 'modules/alerts';
import { Routes } from 'routes';
import { SecurityQuestion } from 'common/api/users/models/SecurityQuestion';
import { AsyncActionState } from 'common/modules/async-actions/core';

import { ProgressState } from 'common/api/crm/enums/ProgressState';

import { useValidation } from 'modules/form-validation/useValidation';
import { DISABLE_CAPTCHA_VALIDATION } from 'config';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { DeferedEnrollmentLog } from '../../types';
import { UserAuthState, updateAccount } from 'common/features/store/duck/account/duck';
import { useDispatch } from 'react-redux';

interface SecurityQuestionsStepProps extends StepWrapperProps {
    registrationRequest: RegistrationRequestWithoutOrg;
    loadQuestions: () => void;
    loadQuestionsState: AsyncActionState;
    questions: SecurityQuestion[] | null;
}

export const SecurityQuestionsStep = (props: SecurityQuestionsStepProps) => {
    const { loadQuestions, loadQuestionsState, questions } = props;
    const { retrieveBlackBox } = useBlackBoxValues();
    const validateAndSubmit = useValidation<{ entries: [string, string][] }>((errors) =>
        Object.keys(flattenObject(errors))
    );
    const loader = useRef<LoadingOverlay>(null);
    const alert = useAlert();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const dispatch = useDispatch<any>();

    const onSubmit = useCallback(
        async (values: { entries: [string, string][] }) => {
            const captchaResponse = DISABLE_CAPTCHA_VALIDATION
                ? 'no_captcha_validation'
                : await recaptchaRef.current?.executeAsync();
            openLoader(loader.current);
            const blackboxValues = await retrieveBlackBox();
            const result = await registerWithoutOrg({
                ...props.registrationRequest,
                ...blackboxValues,
                username: props.registrationRequest.email,
                securityQuestionAnswers: fromEntries(values.entries),
                captchaResponse,
                createOneTimeCode: true,
                enrollSourceApplicationId: EnrollSourceApplicationIds.V3Web,
            });

            if (result.error) {
                await closeLoader(loader.current);
                recaptchaRef.current?.reset();
                alert({
                    message: 'Unable to save security questions at this time. Please try again.',
                });
                return;
            }

            // Get the queue from local-storage
            const enrollQueue = JSON.parse(
                localStorage.getItem('enrollmentLogQueue') || '[]'
            ) as DeferedEnrollmentLog[];

            // defer the enrollment log update until we get redirected back to /start-program/continue
            enrollQueue.push({
                id: result.data.userId,
                state: ProgressState.UserCreated,
            } as DeferedEnrollmentLog);

            localStorage.setItem('enrollmentLogQueue', JSON.stringify(enrollQueue));

            // NOTE: For now only handle case where we do not have a current session active
            // OR if the authenticated user is creating an org for themselves.
            dispatch(updateAccount({ userAuthState: UserAuthState.INITIAL }));
            sendToLoginPage(Routes.StartProgramContinue, `${result.data.oneTimeCode ?? ''}`);

            // NOTE: close loader here incase the two `withRetries` calls take too long.
        },
        [props.registrationRequest, retrieveBlackBox, alert, dispatch]
    );

    const initialValues = useMemo(
        () => ({
            entries: merge(
                [
                    ['', ''],
                    ['', ''],
                ],
                Object.entries(props.registrationRequest.securityQuestionAnswers)
            ),
        }),
        [props.registrationRequest.securityQuestionAnswers]
    );

    useEffect(() => {
        loadQuestions();
    }, [loadQuestions]);

    if (loadQuestionsState.loading) {
        return (
            <div className="mt-12 justify-center flex">
                <ActivityIndicator />
            </div>
        );
    }

    if (!questions) {
        return null;
    }

    return (
        <StepWrapper heading={props.heading} subheading={props.subheading}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => {
                    const selections = formikProps.values.entries.map(([k]) => k);
                    return (
                        <form
                            noValidate
                            className="max-w-sm mx-auto"
                            onSubmit={validateAndSubmit(formikProps)}
                        >
                            <FieldSpacer />
                            <FormikSelectField
                                data-recording-sensitive
                                label="Security Question 1"
                                name="entries.0.0"
                                validate={securityQuestion1Validator}
                            >
                                <option value="" />
                                {questions
                                    .filter((q) =>
                                        selections.every(
                                            (sel, j) => sel !== String(q.id) || j === 0
                                        )
                                    )
                                    .map((question) => (
                                        <option key={question.id} value={question.id}>
                                            {question.questionText}
                                        </option>
                                    ))}
                            </FormikSelectField>
                            <FieldSpacer />
                            <FormikTextField
                                data-recording-sensitive
                                label="Answer 1"
                                name="entries.0.1"
                                validate={securityQuestionAnswerValidator}
                                autoComplete="off"
                            />
                            <FieldSpacer />
                            <FormikSelectField
                                data-recording-sensitive
                                label="Security Question 2"
                                name="entries.1.0"
                                validate={securityQuestion2Validator}
                            >
                                <option value="" />
                                {questions
                                    .filter((q) =>
                                        selections.every(
                                            (sel, j) => sel !== String(q.id) || j === 1
                                        )
                                    )
                                    .map((question) => (
                                        <option key={question.id} value={question.id}>
                                            {question.questionText}
                                        </option>
                                    ))}
                            </FormikSelectField>
                            <FieldSpacer />
                            <FormikTextField
                                data-recording-sensitive
                                label="Answer 2"
                                name="entries.1.1"
                                validate={securityQuestionAnswerValidator}
                                autoComplete="off"
                            />
                            <FieldSpacer />
                            <div className="text-center">
                                <Btn type="submit" className="w-44">
                                    Next
                                </Btn>
                            </div>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                            />
                        </form>
                    );
                }}
            </Formik>
            <LoadingOverlay ref={loader} />
        </StepWrapper>
    );
};
