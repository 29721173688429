import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    WIP_FEATURE_FLAGS,
    FeatureFlagTypes,
    fetchFeatureFlags,
    selectExperimentalFlags,
    selectExperimetnalFlagsLoading,
} from 'common/features/store/duck/ffs';
import { useRootSelector } from 'common/features/featuresReducer';

let environmentKey = '';
export function setReactNativeEnvironmentKey(key: string) {
    environmentKey = key;
}

const _featureFlags = {
    loadedCount: 0,
    userLoginStatusUpdated: false,
    userIsLoggedIn: false,
    isEnabled: (key: string | FeatureFlagTypes) => false,
    setReactNativeEnvironmentKey,
    environmentKey,
    setUserIsLoggedIn(isLoggedIn: boolean) {
        _featureFlags.userIsLoggedIn = isLoggedIn;
    },
};

export const getProceduralFeatureFlags = () => {
    return _featureFlags;
};

export const useFeatureFlagWatcher = () => {
    const dispatch = useDispatch();
    const state = useRootSelector((state) => state);
    const profile = useRootSelector((state) => state?.store?.home?.homeDetails?.profile);
    const flags = useSelector(selectExperimentalFlags);

    useEffect(() => {
        _featureFlags.loadedCount = Object.keys(flags)?.length || 0;

        _featureFlags.isEnabled = (key: FeatureFlagTypes | string) => {
            const keyInFlags = flags[key] || false;
            if (WIP_FEATURE_FLAGS.indexOf(key as FeatureFlagTypes) > -1) {
                return (environmentKey === 'test' || environmentKey === 'dev') && keyInFlags;
            }
            return keyInFlags;
        };
    }, [flags]);

    useEffect(() => {
        _featureFlags.userLoginStatusUpdated = true;
        _featureFlags.setUserIsLoggedIn(Boolean(profile && profile.userId));
    }, [profile]);

    useEffect(() => {
        if (!profile || !profile.userId) {
            return;
        }

        fetchFeatureFlags(dispatch, state);
    }, [dispatch, profile]);

    return null;
};

/**
 * @deprecated Use selectors instead.
 */
export const useFeatureFlags = () => {
    const flags = useSelector(selectExperimentalFlags);
    const loading = useSelector(selectExperimetnalFlagsLoading);
    const hasFlags = Object.keys(flags).length > 0;
    const [hasObservedLoadingComplete, setHasObservedLoadingComplete] = useState(hasFlags);

    useEffect(() => {
        if (!hasObservedLoadingComplete && !loading && hasFlags) {
            setHasObservedLoadingComplete(true);
        }
    }, [loading]);

    // return true if the flag is set. if the flag is in WIP feature flags, only return true if the environment is test or dev
    const isEnabled = useCallback(
        (key: FeatureFlagTypes | string) => {
            const keyInFlags = flags[key] || false;
            if (WIP_FEATURE_FLAGS.indexOf(key as FeatureFlagTypes) > -1) {
                return (environmentKey === 'test' || environmentKey === 'dev') && keyInFlags;
            }
            return keyInFlags;
        },
        [flags]
    );

    return {
        flags,
        isEnabled,
        loadingFlags: !hasObservedLoadingComplete || loading,
    };
};

export default useFeatureFlags;
export { FeatureFlagTypes } from 'common/features/store/duck/ffs';
