import React, { useEffect } from 'react';
import { sendToLoginPage } from 'features/auth/components/withAuth';
import { ReactComponent as SuccessImage } from '../../../media/images/success-image.svg';
import { styles } from './styles';
import { selectOrgSwitchState } from 'common/features/store/duck/home/duck';
import { useSelector } from 'react-redux';

export const SuccessMessage = () => {
    const { redirectRoute } = useSelector(selectOrgSwitchState);
    useEffect(() => {
        setTimeout(() => {
            sendToLoginPage(redirectRoute);
        }, 1000);
    });
    return (
        <div className="h-full flex justify-center flex-col text-center p-2">
            <div className={styles.header}>You've successfully changed your organization.</div>
            <div className="mx-auto mt-16">
                <SuccessImage />
            </div>
        </div>
    );
};
